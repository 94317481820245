import { ReactComponent as Alert } from 'images/ic-alert.svg';
import { ReactComponent as Check } from 'images/ic-check.svg';
import { ReactComponent as Error } from 'images/ic-error.svg';
import { ReactComponent as Info } from 'images/ic-info.svg';

const textButton = {
  success: 'Beleza',
  warning: 'Beleza',
  error: 'Beleza',
  info: 'Beleza'
};

const variantIcon = {
  success: Check,
  warning: Alert,
  error: Error,
  info: Info
};

export { variantIcon, textButton };
