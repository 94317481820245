import React from 'react';
import { Box, Typography, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';

import { groupByDemandRegionLabel } from '../demand-regions.utils';

const DecisionGrouped = ({ sortingContext }) => {
  const decisionsGroupedByDemandRegionLabel = groupByDemandRegionLabel(
    sortingContext
  );

  return (
    <Box mt={2} mb={2} data-testid="number-demand-regions">
      {Object.entries(decisionsGroupedByDemandRegionLabel).map(
        ([label, positions]) => (
          <Box key={label} data-testid={`label: ${label}`}>
            <Typography variant="body2">
              <Box component="span" fontWeight="fontWeightMedium">
                {label}
              </Box>
            </Typography>
            <Box mt={1} mb={4} display="flex" lexDirection="row">
              {positions.map(position => (
                <Box
                  key={position}
                  data-testid={`position: ${position}`}
                  mr={1}
                >
                  <Chip size="small" label={position} />
                </Box>
              ))}
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

DecisionGrouped.propTypes = {
  sortingContext: PropTypes.shape({
    licensePlate: PropTypes.string,
    lastUpdated: PropTypes.string,
    decisions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    )
  }).isRequired
};

export default DecisionGrouped;
