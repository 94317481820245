import React from 'react';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import AlertStatusComponent from '@loggi/components/src/arco/alert-status/alert-status.component';

import ErrorIcon from '@material-ui/icons/Error';

import TEXT from './impersonate-banner.constants';

export default function ImpersonateBanner() {
  const {
    state: { isImpersonation, authenticatedUser }
  } = useAmplifyAuth();

  return (
    isImpersonation && (
      <AlertStatusComponent
        colors="alertColorsLoggedImpersonate"
        customIcon={<ErrorIcon />}
      >
        {TEXT.BANNER} <strong>{authenticatedUser?.name}</strong> (
        <span>{authenticatedUser?.email}</span>).
      </AlertStatusComponent>
    )
  );
}
