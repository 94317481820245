import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Button,
  MenuList,
  MenuItem,
  Popper,
  Typography
} from '@material-ui/core';

import { useHistory, useLocation } from 'react-router-dom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getSelectedRoutingCode } from 'auth/login/login.service';
import { Can, CanFunction } from 'auth/access-control';
import { useStyles } from '../menu.styles';

function MenuGroup({ menuGroup }) {
  const [anchor, setAnchor] = useState();

  const { pathname } = useLocation();
  const history = useHistory();

  const classes = useStyles();

  if (menuGroup.length === 0) {
    return null;
  }

  const handleClose = () => {
    setAnchor(null);
  };

  const handleOpenMenu = event => {
    setAnchor(event.currentTarget);
  };

  const handleClickMenu = item => {
    handleClose();
    if (item.path) {
      const rc = getSelectedRoutingCode();
      history.push(`/${rc}${item.path}`, rc);
    }
  };

  let myTimer;
  const handleAwait = () => {
    myTimer = setTimeout(() => {
      handleClose();
    }, 50);
  };

  const isPopperOpen = Boolean(anchor);

  const selected = menuGroup.find(item => pathname.includes(item.path));
  const first = menuGroup[0];

  const selectedOrFirst = selected || first;
  return (
    <>
      <Box mr={2}>
        <Button
          aria-controls="simple-menu-tracking"
          aria-haspopup="true"
          onMouseEnter={handleOpenMenu}
          onMouseLeave={handleAwait}
          onClick={() => handleClickMenu(selectedOrFirst)}
          className={`
          ${classes.hover}
          ${classes.colorWhite}
          ${classes.noWrap}
          ${selected || isPopperOpen ? classes.styleButton : ''}
        `}
          data-testid="btn-menu-tracking"
          size="medium"
        >
          <em>{selectedOrFirst.title} </em>
          {menuGroup.length > 1 &&
            (isPopperOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
        </Button>
      </Box>
      <Popper
        data-testid="menu-group-popper"
        placement="bottom-start"
        anchorEl={anchor}
        open={isPopperOpen}
        onMouseEnter={() => clearTimeout(myTimer)}
        onMouseLeave={handleClose}
        onClose={handleClose}
        className={`
        ${classes.divDropDown}
        ${classes.ulDropDownLink}
        ${classes.liDropDownLink}
      `}
      >
        <Box className={classes.boxShadow}>
          <MenuList>
            {menuGroup
              .filter(item => selectedOrFirst.title !== item.title)
              .map(menuItem => (
                <Can includeFS={menuItem.includeFS} key={menuItem.title}>
                  <CanFunction
                    customDisabledTo={
                      menuItem?.fsCanFunction ? menuItem.fsCanFunction : null
                    }
                  >
                    <MenuItem
                      data-testid={menuItem.title}
                      onClick={() => handleClickMenu(menuItem)}
                      className={`${classes.hoverItemMenu}`}
                    >
                      <Typography variant="body2">
                        <em>{menuItem.title}</em>
                      </Typography>
                    </MenuItem>
                  </CanFunction>
                </Can>
              ))}
          </MenuList>
        </Box>
      </Popper>
    </>
  );
}

MenuGroup.propTypes = {
  menuGroup: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string.isRequired
    })
  )
};

MenuGroup.defaultProps = {
  menuGroup: []
};
export default MenuGroup;
