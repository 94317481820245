import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { DrawerContent, DrawerHeader, CustomDrawer } from 'shared/drawer';
import SelectComponent from 'shared/select';
import useStylesAvatar from 'shared/drawer/drawer-header.styles';
import DeltaArrivalTimeMinutes from 'tracking/arrivals/delta-arrival-time';
import {
  formatVehicle,
  sumDeltaTime
} from '../transfers/arrivals-transfers.configuration';
import arrivalsModel from '../transfers/arrivals-transfers.model';
import TEXT, {
  INTERNAL_SELECT_LABEL,
  SELECTED_LABEL
} from './drawer.constants';

export default function DrawerComponent({
  handleClosingDrawer,
  selectedUnitLoad,
  aggregationResponse,
  optionSelected,
  onSelectChange,
  loading,
  selectOptions
}) {
  const classes = useStylesAvatar();
  const listOfDrivers = selectedUnitLoad.drivers
    ? selectedUnitLoad.drivers.map(item => item.name)
    : [];

  const subtitle = `
      ${TEXT.SCHEDULED_TO} ${sumDeltaTime(
    selectedUnitLoad.expectedArrivalTime,
    selectedUnitLoad.deltaArrivalTimeMinutes
  )}
      \\n
      ${selectedUnitLoad.totalPackages} ${TEXT.PACKAGES}`;

  return (
    <CustomDrawer>
      <DrawerHeader
        title={`${TEXT.FROM} ${selectedUnitLoad.origin.name}`}
        status={{
          component: () => (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box pr={2}>
                <Typography variant="body2" color="textSecondary">
                  {TEXT.WILL_ARRIVE}
                </Typography>
              </Box>
              <DeltaArrivalTimeMinutes
                expectedArrivalTime={selectedUnitLoad.expectedArrivalTime}
                deltaArrivalTime={selectedUnitLoad.deltaArrivalTimeMinutes}
                toDrawer
              />
            </Box>
          ),
          type: 'component'
        }}
        subtitle={subtitle}
        showDivider
        handleClosingDrawer={handleClosingDrawer}
      />
      <DrawerContent showDivider>
        <Box
          pb={3}
          pt={3}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Box mr={1.5}>
            <AvatarGroup max={4}>
              {listOfDrivers.map((item, index) => {
                return (
                  <Avatar
                    key={item}
                    className={classes.avatar}
                    data-testid={`driverAvatar${index}`}
                  >
                    {item.charAt(0)}
                  </Avatar>
                );
              })}
            </AvatarGroup>
          </Box>
          <Box>
            <Box mb={1}>
              <Typography
                variant="body2"
                color="textPrimary"
                style={{ fontWeight: 700 }}
                data-testid="driverName"
              >
                {listOfDrivers.join(' e ')}
                {TEXT.FROM_TRANSPORT_COMPANY}
                {selectedUnitLoad.transportCompany.name}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="textSecondary">
                {TEXT.IS_BY}{' '}
                {formatVehicle(
                  selectedUnitLoad.vehicleType,
                  selectedUnitLoad.vehicleLicensePlate,
                  true
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DrawerContent>
      <DrawerContent>
        <Box mr={3.5} my={2.1}>
          <SelectComponent
            onSelectedChange={onSelectChange}
            selectOptions={selectOptions}
            selected={optionSelected}
            prefixLabel={SELECTED_LABEL}
            internalOptionsLabel={INTERNAL_SELECT_LABEL}
          />
        </Box>
        {!loading && (
          <List>
            {aggregationResponse.map(item => {
              return (
                <ListItem divider key={item.label}>
                  <Grid>
                    <ListItemText
                      primary={
                        <Typography variant="body2">{item.value}</Typography>
                      }
                    />

                    <ListItemText
                      primary={
                        <Typography variant="body2" color="textSecondary">
                          {item.label}
                        </Typography>
                      }
                    />
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        )}
        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress data-testid="loading" />
          </Box>
        )}
      </DrawerContent>
    </CustomDrawer>
  );
}

const selectOptionsShape = PropTypes.shape({
  header: PropTypes.string,
  sortBy: PropTypes.string
});

DrawerComponent.propTypes = {
  handleClosingDrawer: PropTypes.func.isRequired,
  selectedUnitLoad: PropTypes.shape(arrivalsModel).isRequired,
  aggregationResponse: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired,
  optionSelected: selectOptionsShape.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  selectOptions: PropTypes.arrayOf(selectOptionsShape).isRequired
};
