import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(({ spacing }) => ({
  item: {
    paddingLeft: spacing(3.5),
    paddingRight: 0,
    paddingTop: '20px',
    paddingBottom: '20px',
    margin: 0,
    display: 'flex',
    alignItems: 'center'
  },
  itemSelected: {
    borderLeft: `3px solid ${colors.blue[500]}`
  },
  itemNotSelected: {
    borderLeft: '3px solid transparent',
    '&:hover': {
      backgroundColor: colors.blue[100]
    }
  },
  flexGrowItem: {
    flexGrow: 1
  }
}));

export default useStyles;
