import React, { useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { ComplexAreasContext } from './complex-areas.provider';
import ComplexAreasComponent from './complex-areas.component';
import { getComplexAreas } from './complex-areas.service';
import { SNACKBAR_NOTIFICATION } from './complex-areas.constants';
import { enqueueNotification } from './drawer-create-update-complex-area.component';

export default function ComplexAreasContainer() {
  const { setComplexAreas } = useContext(ComplexAreasContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getComplexAreas(getSelectedRoutingCode())
      .then(response => {
        setComplexAreas(response.data.definedRegions || []);
      })
      .catch(() => {
        const message = SNACKBAR_NOTIFICATION.ERROR_MESSAGE;
        enqueueNotification({
          message,
          variant: 'error',
          withButton: false,
          enqueueSnackbar
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ComplexAreasComponent />;
}
