import api from 'infra/api/leve';
import qs from 'qs';
import { URL_API_V1_LAST_MILE_PREFIX } from 'infra/service/constants';
import { getCompanyType } from 'auth/access-control/access-control.service';

// eslint-disable-next-line import/prefer-default-export
export function fetchInStreetSummary({ filters }) {
  let companyType = getCompanyType();
  if (companyType === null || !companyType) {
    companyType = '';
  }

  const params = { ...filters, companyType };

  return api
    .get(`${URL_API_V1_LAST_MILE_PREFIX}/in-street/summary`, {
      params,
      paramsSerializer: _params => {
        return qs.stringify(_params, {
          indices: false
        });
      }
    })
    .then(_data => {
      return _data.data;
    });
}
