import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomDrawer, DrawerHeader, DrawerContent } from 'shared/drawer';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Collapse,
  Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons/';
import { TEXT } from './circles-drawer.constants';
import { CustomSwitch, useStyles } from './circles-drawer.styles';
import CirclesDeleteConfirmationDialog from './circles-delete-confirmation-dialog';

export const DRAWER_STATUS = edit => {
  return {
    type: 'component',
    component: () => {
      return (
        <Typography noWrap variant="body1" color="textSecondary">
          {edit ? TEXT.EDIT : TEXT.CREATE}
        </Typography>
      );
    }
  };
};

export const CityRow = ({
  circle,
  city,
  handleChangeCities,
  isInsideCollapsible
}) => {
  const dataTestId = isInsideCollapsible
    ? `custom-switch-inside-collapsible-checkbox-${city.value}`
    : `custom-switch-outside-collapsible-checkbox-${city.value}`;
  return (
    <Box key={city.value}>
      <Box py={2}>
        <Grid
          spacing={2}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          key={city.value}
        >
          <Grid item xs>
            <Typography variant="subtitle2">
              <em>{city.label}</em>
            </Typography>
          </Grid>
          <Grid item>
            <CustomSwitch
              name={city.value}
              checked={circle?.cities?.some(i => i === city.value)}
              data-testid={dataTestId}
              onChange={e => {
                handleChangeCities(e.target.name, e.target.checked);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider variant="middle" />
    </Box>
  );
};

CityRow.propTypes = {
  circle: PropTypes.shape({
    name: PropTypes.string,
    baseCityOperation: PropTypes.bool,
    servedCitiesOperation: PropTypes.bool,
    cities: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  city: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.number
  }).isRequired,
  handleChangeCities: PropTypes.func.isRequired,
  isInsideCollapsible: PropTypes.bool
};

CityRow.defaultProps = {
  isInsideCollapsible: false
};

export default function CirclesRegionDrawerComponent({
  submitCallback,
  changeNameCallback,
  changeCitiesCallback,
  closeCallback,
  deleteCallback,
  open,
  edit,
  loading,
  circle,
  cities
}) {
  const classes = useStyles();

  const [
    openCirclesDeleteConfirmationDialog,
    setOpenCirclesDeleteConfirmationDialog
  ] = useState(false);

  const [shouldShowAllCities, setShouldShowAllCities] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    submitCallback();
  };

  const [cityWordFilter, setCityWordFilter] = useState('');

  const handleChangeName = value => {
    changeNameCallback(value);
  };

  const handleChangeCities = (value, checked) => {
    changeCitiesCallback(value, checked);
  };

  const handleDelete = () => {
    setOpenCirclesDeleteConfirmationDialog(false);
    deleteCallback();
  };

  const filteredCities = cityWordFilter
    ? cities.filter(city =>
        city.label.toLowerCase().includes(cityWordFilter.toLowerCase())
      )
    : cities;

  const extraCitiesLength =
    filteredCities.length > 5 ? filteredCities.length - 5 : 0;

  let extraCitiesLabel = '';

  if (shouldShowAllCities) {
    extraCitiesLabel = TEXT.SHOW_LESS;
  } else if (extraCitiesLength === 1) {
    extraCitiesLabel = TEXT.SHOW_EXTRA_CITY;
  } else if (extraCitiesLength >= 1) {
    extraCitiesLabel = TEXT.SHOW_ALL_CITIES(extraCitiesLength);
  }

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <CustomDrawer open={open}>
          <DrawerHeader
            handleClosingDrawer={closeCallback}
            open={edit}
            showDivider
            title={TEXT.CIRCLE_TITLE}
            status={DRAWER_STATUS(edit)}
            detail={edit ? TEXT.CORE_SUBTITLE_EDIT : TEXT.CORE_SUBTITLE}
            variantTextDetail="subtitle1"
            colorText="textPrimary"
          >
            <Box pt={5}>
              <TextField
                disabled={loading}
                fullWidth
                inputProps={{ 'data-testid': 'name' }}
                name="name"
                label={TEXT.CREATE_CORE_NAME}
                onChange={e => {
                  handleChangeName(e.target.value);
                }}
                value={circle.name}
                variant="outlined"
              />
            </Box>
          </DrawerHeader>

          <DrawerContent>
            <Box py={2.5}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs>
                  <Typography variant="subtitle1">
                    <em>{TEXT.ACTING_REGION}</em>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box pt={1}>
              <TextField
                disabled={loading}
                fullWidth
                InputProps={{
                  'data-testid': 'search',
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  )
                }}
                name="cityWordFilter"
                label="Buscar cidade"
                onChange={e => setCityWordFilter(e.target.value)}
                value={cityWordFilter}
                variant="outlined"
              />

              {filteredCities.slice(0, 5).map(city => (
                <CityRow
                  circle={circle}
                  city={city}
                  handleChangeCities={handleChangeCities}
                />
              ))}
              <Collapse in={shouldShowAllCities}>
                {filteredCities.slice(5).map(city => (
                  <CityRow
                    circle={circle}
                    city={city}
                    handleChangeCities={handleChangeCities}
                    isInsideCollapsible
                  />
                ))}
              </Collapse>
              {extraCitiesLength !== 0 && (
                <Box pt={1}>
                  <Button
                    className={classes.collapseButton}
                    color="primary"
                    endIcon={
                      shouldShowAllCities ? (
                        <KeyboardArrowUp color="primary" />
                      ) : (
                        <KeyboardArrowDown color="primary" />
                      )
                    }
                  >
                    <Typography
                      variant="button"
                      color="primary"
                      onClick={() => {
                        setShouldShowAllCities(show => !show);
                      }}
                    >
                      <em>{extraCitiesLabel}</em>
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
          </DrawerContent>
          <DrawerContent>
            {edit && (
              <Grid container direction="row">
                <Grid item xs={12} sm={4}>
                  <Button fullWidth color="primary">
                    <Typography
                      color="primary"
                      data-testid="delete-circle-button"
                      onClick={() =>
                        setOpenCirclesDeleteConfirmationDialog(true)
                      }
                    >
                      <em>{TEXT.DELETE}</em>
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Button
                    fullWidth
                    color="primary"
                    data-testid="submit-region-page"
                    disabled={
                      loading || circle.name === undefined || circle.name === ''
                    }
                    type="submit"
                    variant="contained"
                  >
                    {loading ? TEXT.SUBMIT_LOADING : TEXT.SAVE}
                  </Button>
                </Grid>
              </Grid>
            )}
            {!edit && (
              <Button
                color="primary"
                data-testid="submit-region-page"
                disabled={
                  loading || circle.name === undefined || circle.name === ''
                }
                fullWidth
                type="submit"
                variant="outlined"
              >
                {loading ? TEXT.SUBMIT_LOADING : TEXT.SUBMIT_BUTTON}
              </Button>
            )}
          </DrawerContent>
        </CustomDrawer>
      </form>
      {openCirclesDeleteConfirmationDialog && (
        <CirclesDeleteConfirmationDialog
          open
          onConfirm={handleDelete}
          onCancel={() => setOpenCirclesDeleteConfirmationDialog(false)}
        />
      )}
    </>
  );
}

CirclesRegionDrawerComponent.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  changeNameCallback: PropTypes.func.isRequired,
  changeCitiesCallback: PropTypes.func.isRequired,
  closeCallback: PropTypes.func,
  deleteCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  edit: PropTypes.bool,
  circle: PropTypes.shape({
    name: PropTypes.string,
    baseCityOperation: PropTypes.bool,
    servedCitiesOperation: PropTypes.bool,
    cities: PropTypes.arrayOf(PropTypes.string)
  }),
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired
};

CirclesRegionDrawerComponent.defaultProps = {
  closeCallback: () => {},
  open: true,
  edit: true,
  circle: {}
};
