import React from 'react';

import Button from '@material-ui/core/Button';
import ConfirmDialogComponent from 'shared/confirm-dialog';
import { PropTypes } from 'prop-types';
import { AUTHORIZED_ROUTES } from 'view/constants';
import {
  FASE_1,
  FASE_2,
  TEXT_CANCEL,
  TEXT_OK,
  TEXT_TO_GROUP
} from './constants';

export default function DialogDistribuirRedirectComponent({
  openDialog,
  setOpenDialog,
  fsRealtimeEnableDistribuirRedirectFase1,
  fsRealtimeEnableDistribuirRedirectFase2,
  handleCancel,
  goToPage
}) {
  return fsRealtimeEnableDistribuirRedirectFase1 ||
    fsRealtimeEnableDistribuirRedirectFase2 ? (
    <ConfirmDialogComponent
      open={openDialog}
      title={
        fsRealtimeEnableDistribuirRedirectFase2 ? FASE_2.title : FASE_1.title
      }
      descriptionStylized={
        fsRealtimeEnableDistribuirRedirectFase2
          ? FASE_2.description
          : FASE_1.description
      }
    >
      {fsRealtimeEnableDistribuirRedirectFase2 ? (
        <>
          {handleCancel && (
            <Button
              onClick={() => {
                handleCancel();
                setOpenDialog(false);
              }}
              color="primary"
              data-testid="confirmation-dialog-cancel-btn"
            >
              {TEXT_CANCEL}
            </Button>
          )}
          <Button
            onClick={() =>
              goToPage(
                `${AUTHORIZED_ROUTES.INFORMATION.BASE}${
                  AUTHORIZED_ROUTES.INFORMATION.GROUPS
                }`
              )
            }
            color="primary"
            variant="contained"
            data-testid="confirmation-dialog-submit-btn"
          >
            {TEXT_TO_GROUP}
          </Button>
        </>
      ) : (
        <Button
          onClick={() => {
            if (handleCancel) {
              handleCancel();
            }
            setOpenDialog(false);
          }}
          color="primary"
          variant="contained"
          data-testid="confirmation-dialog-submit-btn-redirect-distribuir"
        >
          {TEXT_OK}
        </Button>
      )}
    </ConfirmDialogComponent>
  ) : null;
}

DialogDistribuirRedirectComponent.propTypes = {
  handleCancel: PropTypes.func,
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  fsRealtimeEnableDistribuirRedirectFase1: PropTypes.bool.isRequired,
  fsRealtimeEnableDistribuirRedirectFase2: PropTypes.bool.isRequired,
  goToPage: PropTypes.func.isRequired
};

DialogDistribuirRedirectComponent.defaultProps = {
  handleCancel: null
};
