import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TableCell, TableRow } from '@material-ui/core';
import { isPackageOfGroupDetached } from 'tracking/tracking.configuration';
import TEXT, { VIEWS_DRAWER } from './drawer-group/constants';
import DrawerGroupContainer from './drawer-group';

export default function TableRowWithButton({
  cssClickableRow,
  handleClick,
  dataRow,
  keysColumns,
  paramKey,
  openRow,
  _elementTable,
  columns,
  callFetchGroupByUnitLoad,
  showViewActionsDrawer
}) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataGroup, setDataGroup] = useState({});

  const onCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const _handleClick = () => {
    handleClick();
    setDataGroup({
      quantityPackages: dataRow.quantityPackages,
      licensePlate: dataRow.licensePlate,
      circleName: dataRow.circleName,
      circleId: dataRow.circleId,
      packages: [],
      packageGroupId: dataRow.packageGroupId,
      keywords: dataRow.packageGroupTags || []
    });
  };

  const showActionsGroup = () =>
    !isPackageOfGroupDetached(dataRow.licensePlate);

  return (
    <>
      {openDrawer && (
        <DrawerGroupContainer
          onCloseDrawer={onCloseDrawer}
          dataGroup={dataGroup}
          callFetchGroupByUnitLoad={callFetchGroupByUnitLoad}
          showViewActionsDrawer={showViewActionsDrawer}
          doFetchGroupIsAllocated
        />
      )}

      <TableRow
        className={cssClickableRow}
        hover
        data-testid={`${paramKey}-clickable-row`}
        onClick={_handleClick}
        id={`${paramKey}-clickable-row-ID`}
        name={`${paramKey}-clickable-row-NAME`}
      >
        {keysColumns.map((columnsName, index) => {
          const propsStyle = columns[columnsName].propsStyleBody;
          const keyConcat = `${paramKey}${index}`;

          return (
            <TableCell
              component="th"
              scope="row"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...propsStyle}
              key={keyConcat}
              data-testid={`${keyConcat}-collapsible`}
            >
              {columnsName === 'actionsBtnGroup'
                ? openRow &&
                  showActionsGroup() && (
                    <Button
                      onClick={event => {
                        setOpenDrawer(!openDrawer);
                        event.stopPropagation();
                        return false;
                      }}
                      variant="outlined"
                      color="primary"
                      data-testid={`${keyConcat}-clickable-action-button`}
                    >
                      {TEXT.BTN_ACTIONS_GROUP}
                    </Button>
                  )
                : _elementTable(dataRow, columnsName, openRow)}
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
}

TableRowWithButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  dataRow: PropTypes.shape({
    licensePlate: PropTypes.string,
    quantityPackages: PropTypes.number,
    city: PropTypes.string,
    circleName: PropTypes.string,
    circleId: PropTypes.string,
    packageGroupId: PropTypes.string,
    packageGroupTags: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  paramKey: PropTypes.string.isRequired,
  openRow: PropTypes.bool,
  columns: PropTypes.objectOf(
    PropTypes.shape({
      header: PropTypes.string,
      headAlign: PropTypes.string,
      propsStyleBody: PropTypes.shape,
      component: PropTypes.element
    })
  ).isRequired,
  _elementTable: PropTypes.func.isRequired,
  cssClickableRow: PropTypes.string.isRequired,
  keysColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  callFetchGroupByUnitLoad: PropTypes.func.isRequired,
  showViewActionsDrawer: PropTypes.string
};

TableRowWithButton.defaultProps = {
  openRow: false,
  showViewActionsDrawer: VIEWS_DRAWER.DETAIL
};
