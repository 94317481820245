import { Box, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const WarningContainer = ({ title, text, children }) => (
  <Box pt={3.5}>
    <Box p={2} display="flex" bgcolor="warning.light">
      <ErrorIcon
        style={{ color: useTheme().palette.warning.main }}
        fontSize="small"
      />
      <Box
        color={useTheme().palette.info.dark}
        pl={1.5}
        display="flex"
        flexDirection="column"
      >
        <Typography variant="subtitle2">
          <Box component="span" fontWeight="bold">
            {title}
          </Box>
        </Typography>
        <Typography variant="body1">
          <Box pt={1} component="span">
            {text}
          </Box>
        </Typography>
        {children}
      </Box>
    </Box>
  </Box>
);

WarningContainer.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node
};

WarningContainer.defaultProps = {
  text: null,
  children: null
};

export default WarningContainer;
