import React from 'react';
import PropTypes from 'prop-types';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { Box, Typography, Avatar, Divider } from '@material-ui/core';
import { TRANSPORT_TYPE } from 'users-management/drivers/drivers.constants';
import formatPhone from 'shared/formatters/phone/phone';
import { UpdateDisputeResponsible } from './disputes-update-responsible.container';
import { UPDATE_RESPONSIBLE_TEXTS } from './disputes.constants';

export const DisputesDriverComponent = ({
  driver: {
    fullName,
    mobileNumber,
    deliverer: { profilePictureThumbnailUrl, transportType }
  },
  dispute
}) => {
  const updateResponsiblebuttonFS = IsFSActiveForCurrentBase(
    'enableUpdateDisputeResponsibleButton'
  );
  const hasUpateResponsibleSituation =
    dispute?.situation === 'DISPUTE_SITUATION_TYPE_PENDING';

  return (
    <>
      <Box mx={3.5} my={3.5} data-testid="driver-info">
        <Box pt={1.5} component="span" display="flex">
          <Box mr={3}>
            <Avatar
              style={{ height: '3em', width: '3em' }}
              src={profilePictureThumbnailUrl}
            />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="subtitle2">
              <em>{fullName}</em>
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Está de {TRANSPORT_TYPE[transportType]} •{' '}
              {formatPhone(mobileNumber)}
            </Typography>
          </Box>
        </Box>
        {updateResponsiblebuttonFS && hasUpateResponsibleSituation && (
          <Box my={3}>
            <UpdateDisputeResponsible
              buttonText={UPDATE_RESPONSIBLE_TEXTS.buttonWithResponsible}
              dispute={dispute}
            />
          </Box>
        )}
      </Box>
      <Divider />
    </>
  );
};

DisputesDriverComponent.propTypes = {
  driver: PropTypes.shape({
    fullName: PropTypes.string,
    mobileNumber: PropTypes.string,
    deliverer: PropTypes.shape({
      transportType: PropTypes.string,
      profilePictureThumbnailUrl: PropTypes.string
    })
  }),
  dispute: PropTypes.shape().isRequired
};

DisputesDriverComponent.defaultProps = {
  driver: {
    fullName: null,
    mobileNumber: null,
    deliverer: {
      transportType: null,
      profilePictureThumbnailUrl: null
    }
  }
};

export default DisputesDriverComponent;
