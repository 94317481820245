import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import getStatusPlatformWrapper from './alert-status.service-wrapper';
import AlertStatus from './alert-status.component';
import {
  STATUS,
  TIME_BETWEEN_FETCH_STATUSPAGE
} from './alert-status.constants';

/**
 * This is an alert component that shows an information about the systems current status - operational, degraded_perfomance,
 * major_outage, partigal_outage
 *
 * @param {Element} customIcon This param is optional and receives an icon. If it's empty watcher icon is default;
 * @param {String} customColors This param is optional and receives a class. If it's empty defaultAlertColors is default;
 * @param {String} platform This param is required. It's necessary because we need know which platform should be monitoring;
 * @param {Function} messageStatusMapper This param is required. It's necessary because we need the messages and status to render only the message;
 * @param {Function} operationalStatusCallback This param is optional and receives a function to inform when the platform is operational again. e.g. a snackBar
 */
const AlertStatusContainer = props => {
  const {
    customIcon,
    customColors,
    platform,
    messageStatusMapper,
    operationalStatusCallback
  } = props;

  const [statusPlatform, setStatusPlatform] = useState(STATUS.OPERATIONAL);

  useEffect(() => {
    const isBackToOperational = response => {
      return (
        statusPlatform !== STATUS.OPERATIONAL &&
        response === STATUS.OPERATIONAL &&
        operationalStatusCallback
      );
    };

    const interval = setInterval(() => {
      getStatusPlatformWrapper(
        platform,
        isBackToOperational,
        operationalStatusCallback,
        setStatusPlatform
      );
    }, TIME_BETWEEN_FETCH_STATUSPAGE);

    return () => clearInterval(interval);
  }, [statusPlatform, platform, operationalStatusCallback]);

  const showAlertStatus = statusPlatform !== STATUS.OPERATIONAL;

  return showAlertStatus ? (
    <AlertStatus customIcon={customIcon} colors={customColors}>
      {messageStatusMapper(statusPlatform)}
    </AlertStatus>
  ) : null;
};

AlertStatusContainer.propTypes = {
  customIcon: PropTypes.element,
  customColors: PropTypes.string,
  platform: PropTypes.string.isRequired,
  messageStatusMapper: PropTypes.func.isRequired,
  operationalStatusCallback: PropTypes.func
};

AlertStatusContainer.defaultProps = {
  customIcon: <WatchLaterIcon fontSize="inherit" />,
  customColors: 'defaultAlertColors',
  operationalStatusCallback: null
};

export default AlertStatusContainer;
