import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Box, Button, Switch } from '@material-ui/core';
import { copyLink, displaySnackBar } from '../drivers.utils';
import { PLAY_STORE_URL, TEXTS } from './drivers-profile.constants';
import ProfileActivityType from './drivers-profile-activity-type.component';
import WarningContainer from './drivers-profile-warning.component';

const DriversProfilePickupComponent = ({
  hasDc,
  profile,
  onToggle,
  onMigrate
}) => {
  const { isDriver, availableForOffer, id, hasPackage, operationalStatus } =
    profile?.deliverer || {};
  const { enqueueSnackbar } = useSnackbar();
  const [isAvailableForOffer, setIsAvailableForOffer] = useState(
    Boolean(availableForOffer)
  );
  const validOperationalStatus = !['bloqueado', 'cadastro_incompleto'].includes(
    operationalStatus
  );

  useEffect(() => {
    setIsAvailableForOffer(Boolean(availableForOffer));
  }, [isAvailableForOffer, availableForOffer]);

  const toggleAvailableForOffer = () =>
    onToggle(id, !availableForOffer).then(() =>
      setIsAvailableForOffer(!availableForOffer)
    );

  const getLink = () =>
    copyLink(PLAY_STORE_URL).then(
      displaySnackBar({
        variant: 'success',
        enqueueSnackbar,
        message: TEXTS.SNACKBAR_MESSAGE
      })
    );

  const subtitleComponent = subtitleText => (
    <>
      {subtitleText[0]}
      <em>{subtitleText[1]}</em>
      {subtitleText[2]}
    </>
  );

  if (!isDriver)
    return (
      <Box>
        <ProfileActivityType title={TEXTS.PICKUP.TITLE} />
        <WarningContainer
          title={TEXTS.WARNING_TITLE}
          text={
            hasPackage
              ? TEXTS.PROFILE_OLD_MIGRATION_TEXT
              : TEXTS.PROFILE_OLD_WARNING_TEXT
          }
        >
          {!hasPackage && (
            <ul>
              {TEXTS.PROFILE_OLD_WARNING_DOCS.map(doc => (
                <li key={doc}>{doc}</li>
              ))}
            </ul>
          )}
        </WarningContainer>
        <Box display="flex" pt={3.5}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={onMigrate}
            disabled={hasPackage}
          >
            {TEXTS.COMPLETE_REGISTER_BUTTON}
          </Button>
        </Box>
      </Box>
    );

  const { TITLE, SUBTITLE } = hasDc ? TEXTS.PICKUP : TEXTS.ACTIVITY;

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <ProfileActivityType
          title={TITLE}
          subtitle={subtitleComponent(SUBTITLE)}
          buttonText={TEXTS.COPY_LINK_APP_STORE_BUTTON}
          disabled={!availableForOffer}
          onClick={getLink}
        />
        <Box display="flex" alignItems="center">
          <Switch
            value={availableForOffer}
            checked={availableForOffer}
            onChange={toggleAvailableForOffer}
            name="pickup-toggle"
          />
        </Box>
      </Box>
      {!validOperationalStatus && !availableForOffer && (
        <WarningContainer
          title={TEXTS.WARNING_TITLE}
          text={TEXTS.PENDING_STATUS_WARNING_TEXT}
        />
      )}
      {hasDc && availableForOffer && (
        <WarningContainer
          title={TEXTS.WARNING_TITLE}
          text={TEXTS.PICKUP_WARNING_TEXT}
        />
      )}
      {!hasDc && !availableForOffer && (
        <WarningContainer
          title={TEXTS.WARNING_TITLE}
          text={TEXTS.ACTIVITY_WARNING_TEXT}
        />
      )}
    </Box>
  );
};

DriversProfilePickupComponent.propTypes = {
  profile: PropTypes.shape({
    deliverer: PropTypes.shape({
      availableForOffer: PropTypes.bool,
      id: PropTypes.string,
      isDriver: PropTypes.bool,
      operationalStatus: PropTypes.string
    })
  }).isRequired,
  hasDc: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onMigrate: PropTypes.func.isRequired
};

export default DriversProfilePickupComponent;
