import { colors } from '@loggi/mar';

const SCHEDULED = 'Agendada';
const WAITING_ACCEPT = 'Aguardando aceite';
export const WAITING_PICKUP = 'Aguardando retirada';
export const PICKED_UP = 'Retirada';
export const FINISHED = 'Concluída';
export const CANCELLED = 'Cancelada';
export const DROPPED = 'Perdida';
export const PAUSED = 'Pausada';
export const COLLECTING = 'Coletando';

const ICON_SIZE = 18;

const STATUS_MAPPER = {
  [SCHEDULED]: {
    style: { color: colors.smoke[200], fontSize: ICON_SIZE },
    text: SCHEDULED,
    value: 'SCHEDULED'
  },
  [WAITING_ACCEPT]: {
    style: { color: colors.smoke[400], fontSize: ICON_SIZE },
    text: WAITING_ACCEPT,
    value: 'WAITING_ACCEPT'
  },
  [WAITING_PICKUP]: {
    style: { color: colors.smoke[400], fontSize: ICON_SIZE },
    text: WAITING_PICKUP,
    value: 'WAITING_PICKUP'
  },
  [PICKED_UP]: {
    style: { color: colors.blue[500], fontSize: ICON_SIZE },
    text: PICKED_UP,
    value: 'PICKED_UP'
  },
  [FINISHED]: {
    style: { color: colors.green[500], fontSize: ICON_SIZE },
    text: FINISHED,
    value: 'FINISHED'
  },
  [CANCELLED]: {
    style: { color: colors.smoke[900], fontSize: ICON_SIZE },
    text: CANCELLED,
    value: 'CANCELLED'
  },
  [DROPPED]: {
    style: { color: colors.smoke[900], fontSize: ICON_SIZE },
    text: DROPPED,
    value: 'DROPPED'
  },
  [PAUSED]: {
    style: { color: colors.smoke[400], fontSize: ICON_SIZE },
    text: PAUSED,
    value: 'PAUSED'
  },
  [COLLECTING]: {
    style: { color: colors.smoke[400], fontSize: ICON_SIZE },
    text: COLLECTING,
    value: 'COLLECTING'
  }
};

export default STATUS_MAPPER;
