import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import { ReactComponent as IconCloseLock } from 'images/ic-cadeado-fechado.svg';

import CardIcon from 'shared/card-icon';
import { formatValue, dateFormat } from 'finance/finance.configuration';

import { TEXT, BUTTON } from '../summary-values.constants';
import { InfoBlockLarge } from '../summary-values.component';

import {
  LABEL_TRANSHIPMENT,
  LABEL_PICKUP
} from '../../card-aggregation/card-aggregation.constants';

const InformationBar = ({ textLabel, moduleValue, totalValue }) => {
  const percent = `${((moduleValue / totalValue) * 100).toFixed()}%`;

  return (
    <Box height={80} width="100%">
      <Box mx={0.5}>
        <Typography color="textSecondary" variant="caption">
          <em>{textLabel}</em>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box
          borderRadius={15}
          height="8%"
          bgcolor="success.main"
          mx={0.5}
          p={0.5}
          width={percent}
        />
        <Typography variant="body2">
          <em>{formatValue(moduleValue)}</em>
        </Typography>
      </Box>
    </Box>
  );
};

const SummaryValuesClosedPeriod = ({
  closedPeriodDate,
  closedPeriodInfo,
  handleOpenDrawer,
  redirectClosed
}) => {
  return (
    <CardIcon variant="success" icon={IconCloseLock}>
      <Grid
        container
        justify="space-between"
        alignItems="flex-start"
        wrap="nowrap"
      >
        <Grid container item direction="column">
          <Grid item>
            <InfoBlockLarge
              header={TEXT.CLOSE}
              subHeader={`${dateFormat(closedPeriodDate.start)} à ${dateFormat(
                closedPeriodDate.end,
                true
              )}`}
            />
          </Grid>
          <Grid item>
            <Box my={5}>
              <Typography variant="h3">
                <em>{formatValue(closedPeriodInfo.periodTotalValue)}</em>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <em>{TEXT.CLOSED}</em>
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <InformationBar
              textLabel={TEXT.LOCAL_DELIVERY}
              moduleValue={closedPeriodInfo.localTotalValue}
              totalValue={closedPeriodInfo.periodTotalValue}
            />
            <InformationBar
              textLabel={TEXT.SERVED_DELIVERY}
              moduleValue={closedPeriodInfo.atendidaTotalValue}
              totalValue={closedPeriodInfo.periodTotalValue}
            />
            {closedPeriodInfo.pickupTotalValue > 0 && (
              <InformationBar
                textLabel={LABEL_PICKUP}
                moduleValue={closedPeriodInfo.pickupTotalValue}
                totalValue={closedPeriodInfo.periodTotalValue}
              />
            )}
            {closedPeriodInfo.transhipmentTotalValue > 0 && (
              <InformationBar
                textLabel={LABEL_TRANSHIPMENT}
                moduleValue={closedPeriodInfo.transhipmentTotalValue}
                totalValue={closedPeriodInfo.periodTotalValue}
              />
            )}
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              onClick={() => handleOpenDrawer()}
            >
              {BUTTON.ISSUE_NF}
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            size="medium"
            endIcon={<NavigateNextRoundedIcon fontSize="small" />}
            onClick={() => redirectClosed()}
          >
            {BUTTON.CLOSE}
          </Button>
        </Grid>
      </Grid>
    </CardIcon>
  );
};

const ClosedSkeleton = () => {
  return (
    <CardIcon variant="success" icon={IconCloseLock}>
      <Grid container item direction="column" data-testid="close-skeleton">
        <Grid item>
          <Skeleton variant="rect" height={30} width={66} />
          <br />
          <Skeleton variant="rect" height={21} width={106} />
        </Grid>
        <Grid item>
          <Box my={5}>
            <Skeleton variant="rect" height={53} width={226} />
            <br />
            <Skeleton variant="rect" height={21} width={49} />
          </Box>
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={18} width={99} />
          <Skeleton variant="rect" height={6} width={150} />
          <br />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={18} width={99} />
          <Skeleton variant="rect" height={6} width={150} />
          <br />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={18} width={99} />
          <Skeleton variant="rect" height={6} width={150} />
          <br />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={18} width={99} />
          <Skeleton variant="rect" height={6} width={150} />
          <br />
        </Grid>
        <Grid>
          <Skeleton variant="rect" height={56} width={230} />
        </Grid>
      </Grid>
    </CardIcon>
  );
};

InformationBar.propTypes = {
  textLabel: PropTypes.string.isRequired,
  moduleValue: PropTypes.number.isRequired,
  totalValue: PropTypes.number.isRequired
};

SummaryValuesClosedPeriod.propTypes = {
  closedPeriodDate: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }).isRequired,
  closedPeriodInfo: PropTypes.shape({
    periodTotalValue: PropTypes.number.isRequired,
    localTotalValue: PropTypes.number.isRequired,
    atendidaTotalValue: PropTypes.number.isRequired,
    deliveryTotalValue: PropTypes.number.isRequired,
    pickupTotalValue: PropTypes.number.isRequired,
    transhipmentTotalValue: PropTypes.number.isRequired
  }).isRequired,
  handleOpenDrawer: PropTypes.func.isRequired,
  redirectClosed: PropTypes.func.isRequired
};

export { SummaryValuesClosedPeriod, ClosedSkeleton };
