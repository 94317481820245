import api from 'infra/api/leve';
import {
  URL_PROXY_V1_PREFIX,
  MOVE_PACKAGES_URL
} from 'infra/service/constants';
import db from 'infra/storage/async-elasticsearch-database';
import { packageCacheModel } from 'infra/storage/async-elasticsearch-database.model';
import getPositionService from 'app/geolocation';
import movePackageList from 'app/move-package-list';
import { parseRequestError } from 'app/httpMessage';
import { playSuccess, playError } from 'tracking/sound';

export const CUSTOM_ERROR_MESSAGES = {
  404: 'Não foi possível encontrar o pacote no sistema. Tente de novo daqui a pouco ou atualize a página.',
  412: 'Esse pacote já foi entregue, por isso, não é possível fazer alterações.'
};

const drawerMovePackageService = ({
  process,
  destination,
  packageId,
  notes,
  statusCodeOverwrite
}) =>
  getPositionService().then(({ latitude, longitude }) =>
    movePackageList(
      process,
      destination,
      [
        {
          packageId,
          location: {
            lat: latitude,
            lng: longitude
          },
          notes
        }
      ],
      false,
      latitude,
      longitude,
      statusCodeOverwrite
    )
      .then(() => playSuccess())
      .catch(error => {
        playError();
        throw error;
      })
  );

export const drawerMovePackages = ({ packageBarcode, sortingContextLpn }) => {
  return api
    .post(`${URL_PROXY_V1_PREFIX}/base/sorting_context`, {
      licensePlate: sortingContextLpn
    })
    .then(respContext => {
      const unitLoadLpn =
        respContext.data.sortingContext.decisions[0].destination.licensePlate;
      return api
        .post(MOVE_PACKAGES_URL, {
          packageBarcode,
          unitLoadLpn,
          sortingContextLpn
        })
        .then(respMove => {
          const packageInfo = respMove.data.packagesInfo[0];
          db.savePackages([
            packageCacheModel(
              packageInfo.packageId,
              packageInfo.sortingRecordId,
              null,
              packageInfo.sortingContextLicensePlate
            )
          ]);
          return respMove;
        })
        .then(() => playSuccess());
    })
    .catch(parseRequestError(CUSTOM_ERROR_MESSAGES))
    .catch(error => {
      playError();
      throw error;
    });
};

export default drawerMovePackageService;
