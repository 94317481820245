import formatPhone from 'shared/formatters/phone/phone';
import CustomColumnName from './table/drivers-list-table.component';
import { TRANSPORT_TYPE } from '../drivers.constants';
import { COLUMN_TITLE, TEXTS } from './drivers-list.constants';

const id = {
  header: COLUMN_TITLE.ID,
  component: ({ deliverer }) => deliverer?.id
};

const name = {
  header: COLUMN_TITLE.NAME,
  component: CustomColumnName
};

const availability = hasDc => ({
  header: COLUMN_TITLE.AVAILABILITY,
  component: ({ deliverer: { availableForOffer, isDriver } }) => {
    if (hasDc) {
      return isDriver && availableForOffer
        ? TEXTS.PICKUP_AND_DELIVERY
        : TEXTS.DELIVERY;
    }
    return availableForOffer ? TEXTS.ALLOWED : TEXTS.NOT_ALLOWED;
  }
});

const transportTypeColumn = {
  header: COLUMN_TITLE.TRANSPORT_TYPE,
  component: ({ deliverer: { transportType } }) => TRANSPORT_TYPE[transportType]
};

const mobilePhone = {
  header: COLUMN_TITLE.MOBILE_NUMBER,
  component: ({ mobileNumber }) => formatPhone(mobileNumber)
};

const getColumns = hasDc => ({
  name,
  availability: availability(hasDc),
  transportType: transportTypeColumn,
  mobilePhone
});

export const getColumnsWithId = hasDc => ({
  id,
  name,
  availability: availability(hasDc),
  transportType: transportTypeColumn,
  mobilePhone
});

export default getColumns;
