import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Chip } from '@material-ui/core';
import { OPERATIONAL_STATUS_TYPE, TEXTS } from '../../drivers.constants';
import useStyles from '../../drivers.styles';

const CustomColumnName = props => {
  const {
    fullName,
    deliverer: { profilePictureThumbnailUrl, isDriver, operationalStatus }
  } = props;
  const styles = useStyles();
  return (
    <Box component="span" display="flex" alignItems="center">
      <Avatar
        className={styles.avatar}
        size="small"
        src={profilePictureThumbnailUrl}
      >
        {fullName[0].toUpperCase()}
      </Avatar>
      <Box display="flex" flexDirection="column" mr="auto" ml={2}>
        <Box component="span">{fullName}</Box>
        {!isDriver ? (
          <Chip
            label={TEXTS.INCOMPLETE_REGISTER}
            className={styles.incompleteRegister}
            size="small"
          />
        ) : (
          OPERATIONAL_STATUS_TYPE[operationalStatus] && (
            <Chip
              label={OPERATIONAL_STATUS_TYPE[operationalStatus]}
              className={styles.incompleteRegister}
              size="small"
            />
          )
        )}
      </Box>
    </Box>
  );
};

CustomColumnName.propTypes = {
  fullName: PropTypes.string.isRequired,
  deliverer: PropTypes.shape({
    profilePictureThumbnailUrl: PropTypes.string,
    isDriver: PropTypes.bool,
    operationalStatus: PropTypes.string
  })
};

CustomColumnName.defaultProps = {
  deliverer: {}
};

export default CustomColumnName;
