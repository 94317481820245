import Moment from 'moment';

export const CIRCLES_ACTIONS = {
  CREATE_CIRCLE: 'Criar círculo'
};

export const CIRCLES_CARD = {
  CITIES_ATTENDED: countCity => `${countCity} cidades atendidas`,
  DRIVER_PAIR: drivers =>
    `${drivers[0].name.split(' ')[0]} e ${drivers[1].name.split(' ')[0]}`,
  DRIVERS_LIST: drivers =>
    `${drivers[0].name.split(' ')[0]}, ${
      drivers[1].name.split(' ')[0]
    } e mais `,
  EXTRA_DRIVER: '1 pessoa',
  EXTRA_DRIVERS: countExtraDrivers => `${countExtraDrivers} pessoas`,
  LAST_UPDATE_TIME_FORMAT: '[Atualizado em] DD MMM[, às] HH:mm'
};

export const EMPTY_CIRCLES_PAGE = {
  NO_DATA: 'Crie seu primeiro círculo',
  NO_DATA_DESCRIPTION:
    'Forme um grupo de pessoas que operam ou entregam em uma ou mais cidades. Vai ficar mais fácil organizar e acompanhar muitos pacotes.'
};

export const SUMMARY_TITLE = {
  TITLE: 'Rotinas',
  SUBTITLE: 'Informações da sua operação'
};

export const ONBOARDING = {
  TITLE_FIRST_SCREEN: 'Conheça os círculos',
  TITLE_SECOND_SCREEN: 'Defina quem são as pessoas de um círculo',
  TITLE_THIRD_SCREEN: 'Conte com mais visibilidade e organização dos pacotes',
  DESCRIPTION_FIRST_SCREEN:
    'Uma nova forma de organizar sua operação e apoiar a gestão dos pacotes da sua base.',
  DESCRIPTION_SECOND_SCREEN:
    'Adicione pessoas que operam ou entregam pacotes em uma ou mais cidades.',
  DESCRIPTION_THIRD_SCREEN:
    'Saiba quem está com o pacote e onde ele está. Acompanhe o andamento das entregas em Na rua e filtre por círculos.',
  CONTINUE: 'Continuar',
  BACK: 'Voltar',
  CREATE_CIRCLE: 'Criar círculo',
  LETS_GO: 'Vamos lá!',
  SKIP: 'Pular',
  NOVELTY: 'Novidade',
  FIRST_SCREEN: 'first-screen',
  SECOND_SCREEN: 'second-screen',
  THIRD_SCREEN: 'third-screen'
};

export const CirclesDataMock = {
  circles: [
    {
      id: 1,
      name: 'cicle',
      title: 'Teste 1',
      drivers: [2],
      operators: ['123'],
      cities: []
    }
  ]
};

export const CirclesMock = [
  {
    title: 'Teste 1',
    drivers: [
      {
        id: 0,
        name: 'Driver 1',
        avatarSource: ''
      },
      {
        id: 1,
        name: 'Driver 2',
        avatarSource: ''
      }
    ],
    id: 'ba2baskb123lasndas',
    countExtraDrivers: 2,
    mainCity: 'Rio de Janeiro',
    countCity: 1,
    lastUpdateTime: Moment()
  },
  {
    title: 'Teste 2',
    drivers: [
      {
        id: 0,
        name: 'Driver 1',
        avatarSource: ''
      },
      {
        id: 1,
        name: 'SpecialDriver 2',
        avatarSource: ''
      }
    ],
    id: 'xtbaskb123lasndas',
    countExtraDrivers: 1,
    mainCity: 'Rio de Janeiro',
    countCity: 2,
    lastUpdateTime: Moment()
  },
  {
    id: '421cxcvb123lasndas',
    title: 'Teste 3',
    drivers: [
      {
        id: 0,
        name: 'SpecialDriver 1',
        avatarSource: ''
      },
      {
        id: 1,
        name: 'SpecialDriver 2',
        avatarSource: ''
      }
    ],
    countExtraDrivers: 0,
    mainCity: 'Rio de Janeiro',
    countCity: 2,
    lastUpdateTime: Moment()
  },
  {
    id: 'lasndas42scv23',
    title: 'Teste 4',
    drivers: [
      {
        id: 0,
        name: 'SpecialDriver 1',
        avatarSource: ''
      }
    ],
    countExtraDrivers: 0,
    mainCity: 'Rio de Janeiro',
    countCity: 2,
    lastUpdateTime: Moment()
  },
  {
    id: 'lmlifdfoh32kb2lndas',
    title: 'Teste 5',
    drivers: [
      {
        id: 0,
        name: 'Driver 1',
        avatarSource: ''
      },
      {
        id: 1,
        name: 'Driver 2',
        avatarSource: ''
      }
    ],
    operators: [
      {
        id: 0,
        name: 'Driver 1',
        avatarSource: ''
      },
      {
        id: 1,
        name: 'Driver 2',
        avatarSource: ''
      }
    ],
    countExtraDrivers: 2,
    mainCity: 'Rio de Janeiro',
    countCity: 1,
    lastUpdateTime: Moment()
  },
  {
    id: 'lmlifdfoh32kb2lndas',
    title: 'Teste 6',
    drivers: [],
    operators: [
      {
        id: 0,
        name: 'Driver 1',
        avatarSource: ''
      }
    ],
    countExtraDrivers: 2,
    mainCity: 'Rio de Janeiro',
    countCity: 1,
    lastUpdateTime: Moment()
  }
];
