import getStatusPlatform from './alert-status.service';

const getStatusPlatformWrapper = (
  platform,
  isBackToOperational,
  operationalStatusCallback,
  setStatusPlatform
) => {
  return getStatusPlatform(platform)
    .then(resp => {
      if (isBackToOperational(resp)) {
        operationalStatusCallback();
      }
      setStatusPlatform(resp);
    })
    .catch(error => {
      if (error?.response?.status) {
        throw error;
      }
    });
};

export default getStatusPlatformWrapper;
