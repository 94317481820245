import api from 'infra/api/loggi-web';

import { parseRequestError } from 'app/httpMessage';
import { INVALID_LINK_MESSAGE } from './signup.constants';

const CUSTOM_MESSAGE = {
  400: INVALID_LINK_MESSAGE,
  409: 'CPF já está em uso',
  412: 'CPF inválido'
};

const signupService = params =>
  api
    .post('last-mile/v1/signup', params)
    .catch(parseRequestError(CUSTOM_MESSAGE));

export default signupService;
