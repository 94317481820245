export const SUMMARY = {
  EMPTY_PACKAGE_DEFAULT:
    'Ops... Não foi possível buscar as informações. Por favor tente mais tarde.'
};

export const TEXT = {
  BASE: 'Na base',
  GROUP: 'Agrupar',
  ORDER_BY: 'ordenar por'
};

export const TITLE_TOGGLE = {
  VALUE_GROUPS: 'groups',
  VALUE_PACKAGES: 'packages',
  TITLE_GROUPS: 'Grupos',
  TITLE_PACKAGES: 'Pacotes'
};
