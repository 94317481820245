import { Auth } from 'aws-amplify';

import { getSelectedBase } from 'profile/profile.service';

import { BASE_ANALYST } from './access-control.rules';

export const SHORT_LABEL_COMPANY_TYPE = {
  AGENCY: 'AGENCY',
  CARRIER: 'CARRIER',
  LEVE: 'LEVE'
};

export const getCompanyType = (getDefaultConstant = false) => {
  const { AGENCY, CARRIER, LEVE } = SHORT_LABEL_COMPANY_TYPE;

  const base = getSelectedBase();
  if (!base) {
    return null;
  }

  const { last_mile_company: lastMileCompany } = base;
  if (lastMileCompany) {
    const { company_type: companyType } = lastMileCompany;
    if (getDefaultConstant) return companyType;
    if (companyType === 'LAST_MILE_COMPANY_TYPE_CARRIER') {
      return CARRIER;
    }
    return companyType === 'LAST_MILE_COMPANY_TYPE_LEVE' ? LEVE : AGENCY;
  }

  return null;
};

export const getRole = async (lmc, dc) => {
  const userSession = await Auth.currentSession().catch(() => null);
  if (userSession) {
    const { payload } = userSession.idToken;
    const group = payload['cognito:groups']?.find(
      role => role === BASE_ANALYST
    );

    if (group) {
      return group;
    }

    const customAccess = JSON.parse(
      Object.prototype.hasOwnProperty.call(payload, 'custom:access')
        ? payload['custom:access']
        : '{}'
    );

    if (customAccess.lastMile) {
      // todo: remove lmc_dc validation
      const found = customAccess.lastMile.find(
        lastMile =>
          lastMile.lmc_dc === `${lmc}:${dc || 0}` ||
          lastMile.id === `${lmc}:${dc || 0}`
      );
      return found ? found.role : null;
    }
  } else {
    window.location.reload();
  }

  return null;
};
