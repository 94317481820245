import React from 'react';
import PropTypes from 'prop-types';

import AlertStatusHeaderComponent from './alert-status-header.component';
import isPlatformStatusDegraded from './alert-status-header.service';

function AlertStatusHeader({ textPrimary, textSecondary, isDeprecated }) {
  return (
    <>
      {/* This code was deprecated, because loggi does not use statuspage.io. */}
      {!isDeprecated && isPlatformStatusDegraded() && (
        <AlertStatusHeaderComponent
          textPrimary={textPrimary}
          textSecondary={textSecondary}
        />
      )}
    </>
  );
}

AlertStatusHeader.propTypes = {
  textPrimary: PropTypes.string,
  textSecondary: PropTypes.string,
  isDeprecated: PropTypes.bool
};

AlertStatusHeader.defaultProps = {
  textPrimary: '',
  textSecondary: '',
  isDeprecated: true
};

export default AlertStatusHeader;
