import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { SummaryContext } from 'tracking/template/summary.context';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Pagination, { usePagination } from 'shared/pagination';
import ConfigurableTable from 'shared/configurable-table';
import { Skeleton } from '@material-ui/lab';
import FilterComponent from 'shared/filter';
import { AUTHORIZED_ROUTES } from 'view/constants';
import ConfigurableTableError from 'shared/configurable-table/configurable-table-error.component';
import { FILTERS } from 'shared/filter/filter.constants';
import { Can } from 'auth/access-control';
import SelectComponent from 'shared/select';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import { formatNumber } from 'shared/formatters';
import DownloadCsvContainer from 'in-street/collection/deliverers/download';
import { fetchPackages } from './service';
import {
  VIEW_CONFIG,
  TITLE_VIEW,
  PACOTES,
  PACOTES_ATRASADOS,
  PACOTES_INSUCESSOS,
  PACOTES_PARA_HOJE,
  CONFIGURABLE_TABLE_CONFIGURATION_NA_RUA,
  ORDER_BY_VIEW_LIST_PACKAGES
} from './configuration';

function FilterElements({
  dataForFilter,
  setFilters,
  checkedItemsBase,
  setCheckedItemsBase
}) {
  const onApplyChanges = (items, resetBaseListData) => {
    if (items.remove || Object.values(items)[0] === undefined) {
      setFilters({});
      setCheckedItemsBase({});
    } else {
      setFilters(currentState => {
        return { ...currentState, ...items };
      });
    }

    if (resetBaseListData) {
      resetBaseListData();
    }
  };

  return (
    <Box>
      {dataForFilter && (
        <Box data-testid="filter-elemets">
          <FilterComponent
            btnFilter="Entregador"
            onApplyChanges={onApplyChanges}
            whichFilter={FILTERS.IN_STREET_DELIVERER}
            dataForFilter={dataForFilter}
            checkedItemsBase={checkedItemsBase}
            setCheckedItemsBase={setCheckedItemsBase}
            preselectedItems={checkedItemsBase.delivererCodes}
          />

          <FilterComponent
            btnFilter="Status"
            onApplyChanges={onApplyChanges}
            whichFilter={FILTERS.IN_STREET_STATUS}
            dataForFilter={dataForFilter}
            checkedItemsBase={checkedItemsBase}
            setCheckedItemsBase={setCheckedItemsBase}
            preselectedItems={checkedItemsBase.statusIds}
          />

          {/* LEVE */}
          <Can actions={[ACTIONS.IN_STREET_LEVE]}>
            <FilterComponent
              btnFilter="Faltas"
              onApplyChanges={onApplyChanges}
              whichFilter={FILTERS.IN_STREET_HAS_FAULT}
              dataForFilter={dataForFilter}
              checkedItemsBase={checkedItemsBase}
              setCheckedItemsBase={setCheckedItemsBase}
              preselectedItems={checkedItemsBase.hasFaults}
            />
            <FilterComponent
              btnFilter="Cidades"
              onApplyChanges={onApplyChanges}
              whichFilter={FILTERS.IN_STREET_CITY}
              dataForFilter={dataForFilter}
              checkedItemsBase={checkedItemsBase}
              setCheckedItemsBase={setCheckedItemsBase}
              preselectedItems={checkedItemsBase.cities}
            />
            <FilterComponent
              btnFilter="Circulos"
              onApplyChanges={onApplyChanges}
              whichFilter={FILTERS.IN_STREET_CIRCLE}
              dataForFilter={dataForFilter}
              checkedItemsBase={checkedItemsBase}
              setCheckedItemsBase={setCheckedItemsBase}
              preselectedItems={checkedItemsBase.circleIds}
            />
          </Can>
        </Box>
      )}
    </Box>
  );
}

FilterElements.propTypes = {
  dataForFilter: PropTypes.shape({
    senders: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  checkedItemsBase: PropTypes.shape.isRequired,
  setCheckedItemsBase: PropTypes.func.isRequired
};

export default function InStreetPackagesListContainer() {
  const { setSummary } = useContext(SummaryContext);
  const [loading, setLoading] = useState(true);
  const [totalPkgs, setTotalPkgs] = useState(0);
  const [errorFetch, setErrorFetch] = useState(false);
  const [filters, setFilters] = useState({});
  const [viewConfig, setViewConfig] = useState(VIEW_CONFIG[PACOTES]);
  const [dataForFilter, setDataForFilter] = useState();
  const [packages, setPackages] = useState([]);
  const [checkedItemsBase, setCheckedItemsBase] = useState({});

  const [orderBy, setOrderBy] = useState(ORDER_BY_VIEW_LIST_PACKAGES[0]);

  const history = useHistory();
  const location = useLocation();

  const [
    pagination,
    changePage,
    setNumberOfItems,
    setCurrentPage,
    resetPagination
  ] = usePagination();

  const changePagination = page => {
    changePage(page);
    setLoading(true);
  };

  useEffect(() => {
    if (location.pathname) {
      setViewConfig(
        VIEW_CONFIG[location.pathname.split('na-rua/colecoes/')[1]]
      );
    }
  }, [location]);

  const [filterView, setFilterView] = useState(null);
  useEffect(() => {
    if (location.pathname.includes(`na-rua/colecoes/${PACOTES_ATRASADOS}`)) {
      setFilterView({ delayedPackages: true });
    } else if (
      location.pathname.includes(`na-rua/colecoes/${PACOTES_INSUCESSOS}`)
    ) {
      setFilterView({ unsuccessfulPackages: true });
    } else if (
      location.pathname.includes(`na-rua/colecoes/${PACOTES_PARA_HOJE}`)
    ) {
      setFilterView({ forTodayPackages: true });
    } else {
      setFilterView({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchPackages({
        page: pagination.currentPage,
        filterView,
        filters,
        orderBy: orderBy?.orderBy
      })
        .then(response => {
          setPackages(response?.packages);
          setTotalPkgs(response?.totalPackages);
          setNumberOfItems(response?.totalPackages);
          setDataForFilter(response?.filterOptions);
          setLoading(false);
        })
        .catch(() => {
          setErrorFetch(true);
          setLoading(false);
        });
    };
    if (filterView) {
      setLoading(true);
      setErrorFetch(false);
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, filters, orderBy, filterView]);

  useEffect(() => {
    setSummary(
      <Grid
        container
        direction="row"
        alignItems="center"
        display="flex"
        justify="space-between"
      >
        <Grid item xs>
          <Box display="flex" alignItems="center">
            {loading ? (
              <Skeleton height={60} width="40%" animation="wave" />
            ) : (
              <>
                <Box display="flex" alignItems="center" mr={1}>
                  <IconButton
                    size="small"
                    edge="start"
                    onClick={() => {
                      const rc = getSelectedRoutingCode();
                      const path = `/${rc}/na-rua${
                        AUTHORIZED_ROUTES.INFORMATION.COLLECTION
                      }`;
                      history.push(path);
                    }}
                  >
                    <Box
                      color="primary.contrastText"
                      display="flex"
                      alignItems="center"
                    >
                      <ArrowBackRoundedIcon data-testid="back-base-button" />
                    </Box>
                  </IconButton>
                </Box>
                <Typography variant="h4">{TITLE_VIEW}</Typography>
                <Box ml={1}>
                  <Typography variant="h4">
                    <strong>{` / ${viewConfig.subView}`}</strong>
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          {loading ? (
            <Skeleton height={60} width="20%" animation="wave" />
          ) : (
            !errorFetch && (
              <Box mt={2}>
                <Typography variant="h4">
                  {formatNumber(totalPkgs)} {PACOTES}
                </Typography>
              </Box>
            )
          )}
        </Grid>
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewConfig, totalPkgs, loading, errorFetch]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const onOrderChange = item => {
    setOrderBy(item);
    resetPagination();
    setLoading(true);
  };

  return (
    <Paper component={Box} elevation={10} flexGrow={1} mb={2} minHeight={44}>
      <Box px={isSmallScreen ? 0 : 3} py={3}>
        <Box
          mb={2}
          data-testid="in-street-collection"
          display="flex"
          justifyContent="space-between"
        >
          {loading ? (
            <Skeleton variant="rect" height={44} width={140} animation="wave" />
          ) : (
            <FilterElements
              loading={loading}
              setFilters={setFilters}
              dataForFilter={dataForFilter}
              checkedItemsBase={checkedItemsBase}
              setCheckedItemsBase={setCheckedItemsBase}
            />
          )}
          {loading ? (
            <Skeleton variant="rect" height={34} width={100} animation="wave" />
          ) : (
            <Box display="flex">
              <Box display="flex" mr={1}>
                <DownloadCsvContainer filterView={filterView} />
              </Box>
              <SelectComponent
                onSelectedChange={onOrderChange}
                selected={orderBy}
                selectOptions={ORDER_BY_VIEW_LIST_PACKAGES}
                prefixLabel="ordenar por"
              />
            </Box>
          )}
        </Box>
        {loading ? (
          <Skeleton variant="rect" height={240} animation="wave" />
        ) : (
          !errorFetch &&
          packages &&
          packages.length > 0 &&
          totalPkgs > 0 && (
            <ConfigurableTable
              data={packages}
              columns={CONFIGURABLE_TABLE_CONFIGURATION_NA_RUA}
              withPackageDrawer
            />
          )
        )}
      </Box>

      {errorFetch && <ConfigurableTableError />}

      <Box pb={1} mr={3}>
        {loading ? (
          <Skeleton variant="rect" height={8} animation="wave" />
        ) : (
          !errorFetch && (
            <Pagination
              onPageChange={changePagination}
              initialPage={pagination.currentPage}
              numberOfPages={pagination.numberOfPages}
              setCurrentPage={setCurrentPage}
            />
          )
        )}
      </Box>
    </Paper>
  );
}
