import Dexie from 'dexie';

import { getSelectedBase } from 'profile/profile.service';

import handleDexieErrors from './handle-dexie-errors';

export const databaseName = 'packages';
const db = new Dexie('async-elasticsearch-v3');

Dexie.delete('async-elasticsearch');

db.version(1).stores({
  [databaseName]:
    'packageId, distribuitionCenter, licensePlate, sortingRecordId, timestamp, sortingContextLicensePlate, taskId, destinationId'
});

/** @returns {Promise<Array<PackageMutation>>} */
const getPackageList = async () => db.table(databaseName).toArray();

/** @returns undefined */
const deletePackage = async id => db.table(databaseName).delete(id);

/**
 * @param {Array<Package>} packages
 * @returns {Promise<Array<PackageMutation>>} */
const savePackageList = async packages => {
  return db.table(databaseName).bulkPut(packages, null, { allKeys: true });
};

/**
 * @param {Array<String<LicensePlate>>} licensePlates
 * @param {Array<String<sortingContextLicensePlate>>} sortingContextLicensePlate
 * @returns {Promise<Int>} */
const countPackages = async (licensePlates, sortingContextLicensePlate) => {
  let query = db[databaseName];
  if (licensePlates.length > 0) {
    query = query.where('licensePlate').equalsIgnoreCase(licensePlates[0]);
    licensePlates.splice(0, 1); // remove first already validated
    licensePlates.forEach(item => {
      query = query.or('licensePlate').equalsIgnoreCase(item);
    });
  }

  if (sortingContextLicensePlate.length > 0) {
    sortingContextLicensePlate.forEach(item => {
      query = query.or('sortingContextLicensePlate').equalsIgnoreCase(item);
    });
  }

  const { distribution_center: distributionCenter } = getSelectedBase();
  if (distributionCenter?.id) {
    query.filter(item => {
      return (
        item.distributionCenter.toString() === distributionCenter.id.toString()
      );
    });
  }

  return query.count();
};

export default {
  savePackages: handleDexieErrors(databaseName, savePackageList),
  countPackages: handleDexieErrors(databaseName, countPackages),
  dbtable: db.table(databaseName),
  getPackages: handleDexieErrors(databaseName, getPackageList),
  deletePackage: handleDexieErrors(databaseName, deletePackage)
};
