import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ typography, spacing }) => ({
  popover: {
    '& .MuiPopover-paper': {
      borderRadius: typography.pxToRem(12),
      marginTop: spacing(1)
    }
  }
}));

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
};

export const transformOrigin = {
  vertical: 'top',
  horizontal: 'left'
};
