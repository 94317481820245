import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, Button, Box, Divider } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Skeleton } from '@material-ui/lab';
import { ReactComponent as IconOffer } from 'images/ic-offer.svg';
import CardIcon from 'shared/card-icon';

import { TEXT_CARD } from 'offer/offer.constants';

import useStyles from './offer-today-card.style';

function SubCard({ title, children }) {
  const classes = useStyles();
  return (
    <Grid item>
      <Typography className={classes.smoke} variant="body1">
        <em>{title}</em>
      </Typography>
      <br />
      <Grid container spacing={7} alignItems="center">
        {children}
      </Grid>
    </Grid>
  );
}

SubCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

SubCard.SkeletonVersion = ({ children }) => {
  return (
    <Grid item>
      <Skeleton variant="rect" height={24} width={120} />
      <br />
      <Grid container spacing={7} alignItems="center">
        {children}
      </Grid>
    </Grid>
  );
};

SubCard.SkeletonVersion.propTypes = {
  children: PropTypes.node.isRequired
};

function LargeNumber({ number, 'data-testid': dataTestId }) {
  return (
    <Grid item>
      <Typography variant="h2" data-testid={dataTestId}>
        {number}
      </Typography>
    </Grid>
  );
}

LargeNumber.propTypes = {
  number: PropTypes.number.isRequired,
  'data-testid': PropTypes.string
};

LargeNumber.defaultProps = {
  'data-testid': null
};

LargeNumber.SkeletonVersion = () => {
  return (
    <Grid item>
      <Skeleton variant="rect" height={64} width={64} />
    </Grid>
  );
};

function SmallNumber({ number, description, 'data-testid': dataTestId }) {
  return (
    <Grid item>
      <Typography variant="subtitle2" data-testid={dataTestId}>
        {number}
      </Typography>
      <Typography color="textSecondary" variant="body2">
        <em>{description}</em>
      </Typography>
    </Grid>
  );
}

SmallNumber.propTypes = {
  number: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  'data-testid': PropTypes.string
};

SmallNumber.defaultProps = {
  'data-testid': null
};

SmallNumber.SkeletonVersion = () => {
  return (
    <Grid item>
      <Box mb={1}>
        <Skeleton variant="rect" height={24} width={24} />
      </Box>
      <Skeleton variant="rect" height={24} width={100} />
    </Grid>
  );
};

export default function OfferTodayCardComponent({
  goToOfferViewPressed,
  scheduleTotal,
  lateTotal,
  forNowTotal,
  activeTotal,
  waitingAcceptanceTotal,
  waitingPickupTotal
}) {
  const classes = useStyles();

  return (
    <CardIcon variant="primary" icon={IconOffer}>
      <Grid container>
        <Grid item>
          <Typography variant="h6">
            <em>{TEXT_CARD.CARD_TITLE}</em>
          </Typography>
        </Grid>
        <Grid item xs>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              color="primary"
              size="medium"
              className={classes.buttomPosTop}
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => goToOfferViewPressed()}
            >
              {TEXT_CARD.CARD_LINK}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={7}>
        <SubCard title={TEXT_CARD.SCHEDULES}>
          <LargeNumber number={scheduleTotal} data-testid="schedule-total" />
          <SmallNumber
            number={lateTotal}
            description={TEXT_CARD.SCHEDULING_LATE}
            data-testid="late-total"
          />
          <SmallNumber
            number={forNowTotal}
            description={TEXT_CARD.SCHEDULING_FOR_NOW}
            data-testid="for-now-total"
          />
        </SubCard>
        <Grid item xs={1}>
          <Divider orientation="vertical" />
        </Grid>
        <SubCard title={TEXT_CARD.ACTIVE}>
          <LargeNumber number={activeTotal} data-testid="active-total" />
          <SmallNumber
            number={waitingAcceptanceTotal}
            description={TEXT_CARD.ACTIVE_WAITING_ACCEPTANCE}
            data-testid="waiting-acceptance-total"
          />
          <SmallNumber
            number={waitingPickupTotal}
            description={TEXT_CARD.ACTIVE_WAITING_PICKUP}
            data-testid="waiting-pickup-total"
          />
        </SubCard>
      </Grid>
    </CardIcon>
  );
}

OfferTodayCardComponent.propTypes = {
  goToOfferViewPressed: PropTypes.func.isRequired,
  scheduleTotal: PropTypes.number.isRequired,
  lateTotal: PropTypes.number.isRequired,
  forNowTotal: PropTypes.number.isRequired,
  activeTotal: PropTypes.number.isRequired,
  waitingAcceptanceTotal: PropTypes.number.isRequired,
  waitingPickupTotal: PropTypes.number.isRequired
};

const SkeletonVersion = ({ goToOfferViewPressed }) => {
  const classes = useStyles();
  return (
    <CardIcon variant="primary" icon={IconOffer}>
      <Grid container>
        <Grid item>
          <Typography variant="h6">
            <em>{TEXT_CARD.CARD_TITLE}</em>
          </Typography>
        </Grid>
        <Grid item xs>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              color="primary"
              size="medium"
              className={classes.buttomPosTop}
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => goToOfferViewPressed()}
            >
              {TEXT_CARD.CARD_LINK}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={7}>
        <SubCard.SkeletonVersion>
          <LargeNumber.SkeletonVersion />
          <SmallNumber.SkeletonVersion />
          <SmallNumber.SkeletonVersion />
        </SubCard.SkeletonVersion>
        <Grid item xs={1}>
          <Divider orientation="vertical" />
        </Grid>
        <SubCard.SkeletonVersion>
          <LargeNumber.SkeletonVersion />
          <SmallNumber.SkeletonVersion />
          <SmallNumber.SkeletonVersion />
        </SubCard.SkeletonVersion>
      </Grid>
    </CardIcon>
  );
};

SkeletonVersion.propTypes = {
  goToOfferViewPressed: PropTypes.func.isRequired
};

OfferTodayCardComponent.SkeletonVersion = SkeletonVersion;
