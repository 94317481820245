import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import showSnackbar from 'shared/snackbar';

import CircleDetailDrawerComponent from './circles-detail-drawer.component';
import CirclesRegionDrawerComponent from './circles-region-drawer.component';
import CirclesUsersDrawerComponent from './circles-users-drawer.component';
import { TEXT } from './circles-drawer.constants';
import { createCircle, deleteCircle, updateCircle } from '../circles.service';

const EMPTY_DRAWER_DATA = {
  name: '',
  servedCitiesOperation: false,
  cities: [],
  selectedDrivers: [],
  selectedOperators: []
};

export default function CirclesDrawerContainer({
  open,
  circle,
  cities,
  handleClosingDrawer,
  handleSubmitDrawer,
  drivers,
  operators
}) {
  const REGION = 'region';
  const USERS = 'users';
  const EDIT = 'edit';

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState('');
  const [shouldEdit, setShouldEdit] = useState(false);
  const [drawerData, setDrawerData] = useState({
    ...EMPTY_DRAWER_DATA
  });
  const { enqueueSnackbar } = useSnackbar();

  const onChangeCitiesData = (value, checked) => {
    setDrawerData(_form => {
      return {
        ..._form,
        cities: checked
          ? [...drawerData.cities, value]
          : drawerData.cities.filter(i => i !== value)
      };
    });
  };

  const onChangeNameRegionData = value => {
    setDrawerData(_form => {
      return {
        ..._form,
        name: value
      };
    });
  };

  const onChangeUsersData = (wasChecked, userId, userType) => {
    const field =
      userType === 'driver' ? 'selectedDrivers' : 'selectedOperators';
    const newUserIdSet = wasChecked
      ? [...drawerData[field], userId]
      : drawerData[field].filter(id => id !== userId);
    setDrawerData(_form => {
      return {
        ..._form,
        [field]: newUserIdSet
      };
    });
  };

  const onCloseDrawer = () => {
    setDrawerData({ ...EMPTY_DRAWER_DATA });
    handleClosingDrawer();
  };

  const onCloseRegionDrawer = () => {
    if (shouldEdit) setState(EDIT);
    else onCloseDrawer();
  };

  const onCloseUsersDrawer = () => {
    if (shouldEdit) setState(EDIT);
    else setState(REGION);
  };

  const onDeleteData = () => {
    setLoading(true);
    return deleteCircle(circle.id)
      .then(
        showSnackbar({
          withButton: false,
          message: TEXT.DELETE_SUCCESS_MESSAGE,
          variant: 'success',
          enqueueSnackbar
        })
      )
      .then(() => {
        setState('');
        onCloseDrawer();
        handleSubmitDrawer();
      })
      .catch(error => {
        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmitData = () => {
    setLoading(true);
    return (shouldEdit
      ? updateCircle(circle.id, drawerData)
      : createCircle(drawerData)
    )
      .then(() => {
        showSnackbar({
          withButton: false,
          message: shouldEdit
            ? TEXT.EDIT_SUCCESS_MESSAGE(drawerData.name)
            : TEXT.CREATE_SUCCESS_MESSAGE(drawerData.name),
          variant: 'success',
          enqueueSnackbar
        });
      })
      .then(() => {
        setState('');
        onCloseDrawer();
        handleSubmitDrawer();
      })
      .catch(error => {
        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmitRegionData = () => {
    if (shouldEdit) onSubmitData();
    else setState(USERS);
  };

  const onEditCircleRegion = () => {
    setState(REGION);
  };

  const onEditCircleDrivers = () => {
    setState(USERS);
  };

  useEffect(() => {
    if (!circle) {
      setShouldEdit(false);
      setState(REGION);
    } else {
      setState(EDIT);

      setDrawerData({
        name: circle.name,
        servedCitiesOperation: false,
        selectedDrivers: circle.drivers,
        selectedOperators: circle.operators,
        cities: circle.cities
      });
    }
  }, [circle, enqueueSnackbar, shouldEdit]);

  useEffect(() => {
    if (open) setShouldEdit(true);
  }, [open]);

  return (
    <>
      <CircleDetailDrawerComponent
        closeCallback={onCloseDrawer}
        editRegionCallback={onEditCircleRegion}
        editDriversCallback={onEditCircleDrivers}
        circle={circle}
        open={open && state === EDIT}
      />
      <CirclesRegionDrawerComponent
        submitCallback={onSubmitRegionData}
        changeNameCallback={onChangeNameRegionData}
        changeCitiesCallback={onChangeCitiesData}
        closeCallback={onCloseRegionDrawer}
        deleteCallback={onDeleteData}
        loading={loading}
        open={open && state === REGION}
        edit={shouldEdit}
        circle={drawerData}
        cities={cities}
      />
      <CirclesUsersDrawerComponent
        submitCallback={onSubmitData}
        changeCallback={onChangeUsersData}
        closeCallback={onCloseUsersDrawer}
        loading={loading}
        open={open && state === USERS}
        selectedDrivers={drawerData.selectedDrivers}
        selectedOperators={drawerData.selectedOperators}
        users={{ operators, drivers }}
      />
    </>
  );
}
CirclesDrawerContainer.propTypes = {
  handleClosingDrawer: PropTypes.func,
  handleSubmitDrawer: PropTypes.func,
  open: PropTypes.bool,
  circle: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    cities: PropTypes.arrayOf(PropTypes.string),
    drivers: PropTypes.arrayOf(PropTypes.number),
    operators: PropTypes.arrayOf(PropTypes.string)
  }),
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired,
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      deliverer: PropTypes.shape({
        id: PropTypes.string,
        profilePictureThumbnailUrl: PropTypes.string
      }),
      fullName: PropTypes.string,
      avatarSource: PropTypes.string
    })
  ),
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      lastMileOperator: PropTypes.shape({
        userId: PropTypes.string,
        role: PropTypes.string
      })
    })
  )
};
CirclesDrawerContainer.defaultProps = {
  handleClosingDrawer: () => {},
  handleSubmitDrawer: () => {},
  open: true,
  circle: undefined,
  drivers: [],
  operators: []
};
