import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableRow
} from '@material-ui/core';
import Pagination, {
  usePagination,
  ITEMS_PER_PAGE_THIRTY
} from 'shared/pagination';
import ConfigurableTableSingle from 'shared/configurable-table';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import useStyles from './configurable-table.styles';
import { VIEWS_DRAWER } from './drawer-group/constants';
import RowBodyCollapsible from './row-body-collapsible.component';
import TableHeadMaker from './table-head-maker.component';
import TableRowWithButton from './table-row-with-button.component';

export default function ConfigurableTable(props) {
  const {
    renderHeader,
    data,
    columns,
    columnsChildren,
    callbackNested,
    callFetchGroupByUnitLoad,
    showViewActionsDrawer,
    itensPerPageCollapsible
  } = props;

  const keysColumns = Object.keys(columns);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const TableBodyMaker = () => {
    const _elementTable = (dataRow, columnsName, open) => {
      if (columnsName in dataRow) {
        return dataRow[columnsName];
      }
      if (columnsName === 'collapsible') {
        return columns[columnsName].component(open);
      }
      if ('component' in columns[columnsName]) {
        return columns[columnsName].component(dataRow);
      }
      return '-';
    };

    const GetTableRows = dataRow => {
      const [open, setOpen] = useState(false);
      const [nestedData, setNestedData] = useState(null);
      const key = dataRow?.licensePlate.replace(/\s/g, '_');
      const [
        pagination,
        changePage,
        setNumberOfItems,
        setCurrentPage
      ] = usePagination(itensPerPageCollapsible);
      const [loadingNestedData, setLoadingNestedData] = useState(false);

      const handleClick = () => {
        if (!open) setLoadingNestedData(_loading => !_loading);
        setOpen(_open => !_open);
        changePage(1);
      };

      const changePagination = page => {
        setLoadingNestedData(true);
        changePage(page);
      };

      useEffect(() => {
        async function fetchNested() {
          if (loadingNestedData) {
            const returnCallback = await callbackNested(
              dataRow.licensePlate,
              pagination.currentPage
            );
            setLoadingNestedData(false);
            setNumberOfItems(returnCallback.total.value);
            setNestedData(returnCallback.hits);
          }
        }
        fetchNested().catch(error => {
          showSnackbar({
            message: error.message,
            variant: 'error',
            enqueueSnackbar
          });
        });
      }, [
        pagination.currentPage,
        dataRow.licensePlate,
        loadingNestedData,
        setNumberOfItems
      ]);

      const cssClickableRow = open ? 'expanded' : '';

      return (
        <React.Fragment key={key}>
          <TableRowWithButton
            cssClickableRow={cssClickableRow}
            hover
            data-testid={`${key}-clickable-row`}
            onClick={handleClick}
            keysColumns={keysColumns}
            paramKey={key}
            columns={columns}
            openRow={open}
            _elementTable={_elementTable}
            dataRow={dataRow}
            handleClick={handleClick}
            callFetchGroupByUnitLoad={callFetchGroupByUnitLoad}
            showViewActionsDrawer={showViewActionsDrawer}
          />
          <RowBodyCollapsible
            nestedData={nestedData}
            paramKey={key}
            open={open}
            columns={columns}
          >
            {nestedData && !loadingNestedData && (
              <ConfigurableTableSingle
                data={nestedData}
                columns={columnsChildren}
                withPackageDrawer
              />
            )}
            {loadingNestedData && (
              <Box
                py={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress justify="center" />
              </Box>
            )}
            <Pagination
              onPageChange={changePagination}
              initialPage={pagination.currentPage}
              numberOfPages={pagination.numberOfPages}
              setCurrentPage={setCurrentPage}
            />
          </RowBodyCollapsible>
        </React.Fragment>
      );
    };

    const tableBodyRows = data.map(item => {
      return GetTableRows(item);
    });

    return (
      <TableBody>
        {tableBodyRows}
        {/* Add an empty row below, to force to always show a border on the bottom of the table */}
        <TableRow />
      </TableBody>
    );
  };

  return (
    <Table size="small" className={classes.root}>
      {renderHeader && <TableHeadMaker columns={columns} />}
      <TableBodyMaker />
    </Table>
  );
}

ConfigurableTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  columns: PropTypes.objectOf(
    PropTypes.shape({
      header: PropTypes.string,
      headAlign: PropTypes.string,
      propsStyleBody: PropTypes.shape,
      component: PropTypes.element
    })
  ).isRequired,
  callbackNested: PropTypes.func,
  columnsChildren: PropTypes.objectOf(
    PropTypes.shape({
      header: PropTypes.string,
      headAlign: PropTypes.string,
      propsStyleBody: PropTypes.shape,
      component: PropTypes.element
    })
  ).isRequired,
  renderHeader: PropTypes.bool,
  callFetchGroupByUnitLoad: PropTypes.func.isRequired,
  showViewActionsDrawer: PropTypes.string,
  itensPerPageCollapsible: PropTypes.number
};

ConfigurableTable.defaultProps = {
  renderHeader: true,
  callbackNested: () => {},
  showViewActionsDrawer: VIEWS_DRAWER.DETAIL,
  itensPerPageCollapsible: ITEMS_PER_PAGE_THIRTY
};

ConfigurableTable.displayName = 'ConfigurableTable';
