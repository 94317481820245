import React from 'react';
import PropTypes from 'prop-types';
import { interpret, Interpreter } from 'xstate';
import { useService } from '@xstate/react';
import {
  Box,
  Divider,
  Typography,
  List,
  ListItem,
  TextField,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import drawerMachine from '../drawer.machine';
import { useStyles } from './drawer-refuse.styles';
import {
  DRAWER_REFUSE,
  DRAWER_REFUSE_LIST_OPTIONS,
  TEXT_PACKAGE_NOT_FOUND
} from './drawer-refuse.constants';
import refuseMachine, { ACTIONS, STATES } from './drawer-refuse.machine';

export default function DrawerRefuseComponent({
  refuseMachineRef,
  fsRealtimeDisableRefusePackageNaoVeio
}) {
  const [current, send] = useService(refuseMachineRef);
  const { barcode, isExpectNotes, notes, selectedItem } = current.context;

  const isFlowSelecting = current.value === STATES.SELECTING;
  const isFlowSubmitting = current.value === STATES.SUBMITTING;

  const handleListOption = item => {
    send(ACTIONS.SELECT_OPTION, { value: item });
  };

  const onChangeNotes = event =>
    send(ACTIONS.CHANGE_NOTES, {
      value: event.target.value
    });

  const classes = useStyles();

  const DRAWER_REFUSE_LIST_OPTIONS_FILTERED = DRAWER_REFUSE_LIST_OPTIONS.filter(
    item => {
      if (
        item.text === TEXT_PACKAGE_NOT_FOUND &&
        fsRealtimeDisableRefusePackageNaoVeio
      ) {
        return false;
      }
      return item;
    }
  );

  return (
    <Box className={classes.root} data-testid="drawer-refuse-component">
      <Box mx={3.5} my={5}>
        <Typography variant="h4" className={classes.bold}>
          {DRAWER_REFUSE.title}
        </Typography>
        <Box mt={1.5}>
          <Typography variant="h5">{`#${barcode}`}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box mx={3.5} mb={2.5} mt={5}>
        <Typography variant="h5">{DRAWER_REFUSE.subtitle}</Typography>
      </Box>
      <Box my={5}>
        <Box>
          <List disablePadding>
            {DRAWER_REFUSE_LIST_OPTIONS_FILTERED.map(item => (
              <ListItem
                button
                divider
                selected={selectedItem === item.text}
                key={item.text}
                onClick={() => handleListOption(item)}
                className={`
                  ${classes.item}
                  ${
                    selectedItem === item.text
                      ? classes.itemSelected
                      : classes.itemNotSelected
                  }
                  `}
              >
                <Grid container alignItems="center" justify="space-between">
                  <Typography variant="body1" className={classes.regular400}>
                    {item.text}
                  </Typography>
                  <Box p={0} mr={3.5} hidden={selectedItem !== item.text}>
                    <CheckIcon id={item.text} color="primary" />
                  </Box>
                </Grid>
              </ListItem>
            ))}
          </List>
          <Box mx={3.5} mt={3.5} hidden={!isExpectNotes}>
            <TextField
              required
              id="textfield-notes"
              type="text"
              placeholder={DRAWER_REFUSE.placeHolderNotes}
              multiline
              rows={5}
              fullWidth
              variant="outlined"
              inputProps={{ 'data-testid': 'textfield-notes' }}
              value={notes}
              onChange={onChangeNotes}
            />
          </Box>
        </Box>
        <Box mx={3.5} my={3.5}>
          {isFlowSelecting && (
            <Button
              fullWidth
              size="large"
              variant="outlined"
              color="primary"
              disabled={!selectedItem || (isExpectNotes && !notes)}
              data-testid="btn-recusar"
              onClick={() => send(ACTIONS.SUBMIT)}
            >
              {DRAWER_REFUSE.confirm}
            </Button>
          )}
          {isFlowSubmitting && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress justify="center" />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

// this function is use at storybook and tests only
export const makeRefuseMachineRef = (context = {}) => {
  const service = interpret(refuseMachine, {
    context: refuseMachine.withContext({
      ...refuseMachine.context,
      ...context
    }),
    parent: interpret(drawerMachine).start()
  });
  service.start();
  return service;
};

DrawerRefuseComponent.propTypes = {
  refuseMachineRef: PropTypes.instanceOf(Interpreter).isRequired,
  fsRealtimeDisableRefusePackageNaoVeio: PropTypes.bool
};

DrawerRefuseComponent.defaultProps = {
  fsRealtimeDisableRefusePackageNaoVeio: false
};
