import browserGeolocation from 'infra/browser/geolocation';

const defaultCoords = {
  latitude: '0',
  longitude: '0'
};

/**
 * Returns the lat,lng of the device as a Promise. If there is no permission to use
 * geolocation, or if there's an error when obtaining it, coords (0, 0) will be returned.
 * Use it like this:
 *
 *  getPosition().then(({latitude, longitude}) => {...})
 *
 */
const getPosition = () =>
  new Promise(resolve => {
    try {
      browserGeolocation.getCurrentPosition(
        position => resolve(position.coords),
        () => resolve(defaultCoords)
      );
    } catch (error) {
      resolve(defaultCoords);
    }
  });

export default getPosition;
