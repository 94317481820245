import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { colors } from '@loggi/mar';

const expandedBorder = `1px solid ${colors.smoke[300]}`;

export const Accordion = withStyles({
  root: {
    '&.Mui-disabled': {
      backgroundColor: colors.root[0],
      '&:hover': {
        background: colors.blue[50]
      }
    },
    borderTop: `1px solid ${colors.smoke[100]}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

export const AccordionSummary = withStyles(({ spacing }) => ({
  root: {
    '&.Mui-disabled': {
      opacity: 1
    },
    '&:hover': {
      background: colors.blue[50]
    },
    marginBottom: -1,
    marginTop: -1,
    minHeight: 64,
    '&$expanded': {
      borderRight: expandedBorder,
      borderLeft: expandedBorder,
      borderTop: expandedBorder,
      borderRadius: spacing(2, 2, 0, 0),
      minHeight: 64
    }
  },
  expanded: {}
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles(({ spacing }) => ({
  root: {
    borderRight: expandedBorder,
    borderLeft: expandedBorder,
    borderBottom: expandedBorder,
    borderRadius: spacing(0, 0, 2, 2)
  }
}))(MuiAccordionDetails);

export const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: colors.smoke[50],
    color: colors.smoke[700]
  },
  headerDetails: {
    backgroundColor: colors.smoke[50],
    color: colors.smoke[700],
    alignItems: 'center',
    padding: '0px'
  },
  details: {
    alignItems: 'center',
    padding: '0px 16px'
  },
  column: {
    // When adding or removing columns, it may be necessary to adjust
    // this percentage. Approximately, the calculation is 100%/n,
    // where n is the total number of columns.
    flexBasis: '25%',
    cursor: 'pointer',
    display: 'block'
  }
}));
