import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';

import Pagination from 'shared/pagination';
import FilterContext from 'shared/filter/filter.context';
import useFilterChecked from 'shared/filter/filter.hook';
import FilterComponent from 'shared/filter/filter.component';
import FilterGroupComponent from 'shared/filter/group/group.component';
import ConfigurableTable from 'view/molecules/configurable-table';

import { TEXTS, ORDER_STATUS } from './drivers-tracking.constants';
import columns from './list/drivers-tracking.configuration';

export default function DriversTrackingListComponent(props) {
  const { itineraries, pagination, onFilter, onPageChange } = props;

  const filterValue = useFilterChecked({});
  const filterGroup = {
    title: TEXTS.FILTER_TITLE,
    key: 'status',
    items: Object.values(ORDER_STATUS)
  };

  return (
    <FilterContext.Provider value={filterValue}>
      <Paper
        component={Box}
        elevation={10}
        flexGrow={1}
        mb={6}
        minHeight={44}
        p={5.5}
      >
        <FilterComponent
          onOpen={() => {}}
          onApplyChanges={onFilter}
          btnFilter="Status"
        >
          <FilterGroupComponent group={filterGroup} />
        </FilterComponent>
        <Box mt={3} data-testid="drivers-tracking">
          <ConfigurableTable columns={columns} data={itineraries} />
        </Box>
        <Box mt={5} data-testid="drivers-tracking-pagination">
          <Pagination
            onPageChange={onPageChange}
            initialPage={parseInt(pagination.page, 10)}
            numberOfPages={parseInt(pagination.numberPages, 10)}
          />
        </Box>
      </Paper>
    </FilterContext.Provider>
  );
}

DriversTrackingListComponent.propTypes = {
  itineraries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({
    numberPages: PropTypes.number,
    numberItems: PropTypes.number,
    page: PropTypes.number,
    itemsPerPage: PropTypes.number
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired
};
