import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography,
  Divider,
  Grid
} from '@material-ui/core';

import { CustomDrawer, DrawerHeader, DrawerContent } from 'shared/drawer';
import {
  emailIsValid,
  nameIsValid,
  isCpfValid,
  phoneIsValid
} from 'shared/validators';
import MaskedInput, { CPF, PHONE } from 'shared/masked-input';
import { getCompanyType } from 'auth/access-control/access-control.service';

import {
  ROLES,
  ROLE_OWNER,
  ROLE_ADMIN,
  ROLE_REGULAR,
  ROLE_OPERATOR
} from 'users-management/operators/operators.constants';

import { CustomSwitch } from 'information/routines-management/circles/drawer/circles-drawer.styles';
import OperatorModel from './operators.model';
import TEXT from './operators-form.constants';

const EMPTY_FORM = {
  id: '',
  role: '',
  fullName: '',
  cpf: '',
  email: '',
  mobilePhone: ''
};
export default function OperatorFormComponent({
  callback,
  loading,
  user,
  open,
  handleClosingDrawer
}) {
  const roles = [ROLE_REGULAR.VALUE, ROLE_ADMIN.VALUE, ROLE_OWNER.VALUE];
  const isEdit = !!user.id;
  const CUSTOM_TEXT = isEdit ? TEXT.UPDATE : TEXT.CREATE;
  const hasCirclesEnabled = getCompanyType() === 'LEVE';

  if (hasCirclesEnabled) roles.unshift(ROLE_OPERATOR.VALUE);

  const [formData, setFormData] = useState({
    ...EMPTY_FORM,
    ...user
  });

  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = e => {
    e.preventDefault();
    callback({
      ...formData,
      mobilePhone: formData.mobilePhone.replace(/\D/g, ''),
      cpf: formData.cpf.replace(/\D/g, '')
    }).then(() => {
      setFormData({ ...EMPTY_FORM });
    });
  };

  useEffect(() => {
    setFormData({
      ...EMPTY_FORM,
      ...user
    });
    setFormErrors({});
  }, [user]);

  const handleTyping = (value, field, checked = false) => {
    const validNestedSwitch = () => {
      if (!checked && field === 'role') {
        if (value === ROLE_ADMIN.VALUE) return ROLE_REGULAR.VALUE;
        return '';
      }
      return value;
    };

    setFormData(_form => {
      return {
        ..._form,
        [field]: validNestedSwitch()
      };
    });
  };

  const validateField = (validation, field) => {
    setFormErrors(_errors => {
      return {
        ..._errors,
        [field]: !validation(formData[field])
      };
    });
  };

  // form validation
  const formKeysErrors = Object.keys(formErrors);
  const hasErrors =
    formKeysErrors.length > 0 &&
    formKeysErrors
      .map(key => formErrors[key])
      .reduce((accumulator, current) => accumulator || current);

  // check form is filled
  const isAnyFieldEmpty = () => {
    /*
     Verify if some field form is empyt.
    */

    const keys = ['role', 'fullName', 'email', 'mobilePhone', 'cpf'].filter(
      item => item !== 'cpf'
    );
    const reducer = (accumulator, currentValue) => accumulator || currentValue;
    return keys.map(key => formData[key] === '').reduce(reducer);
  };

  const hasError = field => !!formData[field] && !!formErrors[field];

  return (
    <form noValidate onSubmit={handleSubmit}>
      <CustomDrawer open={open}>
        <DrawerHeader
          handleClosingDrawer={handleClosingDrawer}
          detail={CUSTOM_TEXT.DETAIL}
          showDivider={false}
          title={CUSTOM_TEXT.TITLE}
        />
        <DrawerContent>
          <Box>
            <TextField
              margin="normal"
              disabled={loading || (isEdit && !!user.cpf)}
              error={hasError('fullName')}
              fullWidth
              helperText={
                formData.fullName && formErrors.fullName && TEXT.FULLNAME_ERROR
              }
              inputProps={{ 'data-testid': 'full-name' }}
              name="fullName"
              label={TEXT.FULLNAME_LABEL}
              onBlur={() => validateField(nameIsValid, 'fullName')}
              onChange={e => {
                handleTyping(e.target.value, 'fullName');
              }}
              value={formData.fullName}
              variant="outlined"
            />
            <MaskedInput
              disabled={loading || (isEdit && !!user.cpf)}
              isValid={!hasError('cpf')}
              onBlur={() => validateField(isCpfValid, 'cpf')}
              onChange={e => {
                handleTyping(e.target.value, 'cpf');
              }}
              value={formData.cpf}
              helperText={CPF.ERROR}
              label={CPF.LABEL}
              mask={CPF.MASK}
              testId="cpf-input"
            />
            <TextField
              margin="normal"
              disabled={loading || isEdit}
              error={hasError('email')}
              fullWidth
              helperText={
                formData.email && formErrors.email && TEXT.EMAIL_ERROR
              }
              inputProps={{ 'data-testid': 'email' }}
              label={TEXT.EMAIL_LABEL}
              name="email"
              onBlur={() => validateField(emailIsValid, 'email')}
              onChange={e => {
                handleTyping(e.target.value, 'email');
              }}
              type="email"
              value={formData.email}
              variant="outlined"
            />
            <MaskedInput
              disabled={loading || isEdit}
              isValid={!hasError('mobilePhone')}
              onBlur={() => validateField(phoneIsValid, 'mobilePhone')}
              onChange={e => {
                handleTyping(e.target.value, 'mobilePhone');
              }}
              value={formData.mobilePhone}
              testId="phone-input"
              label={PHONE.LABEL}
              helperText={PHONE.ERROR}
              mask={PHONE.MASK}
            />
          </Box>
        </DrawerContent>
        <DrawerContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography variant="subtitle1" color="textPrimary">
                <em>{TEXT.ROLE_LABEL}</em>
              </Typography>
            </FormLabel>

            {roles.map(role => (
              <Box pt={2.5} key={role}>
                {ROLES[role].VALUE === ROLE_OWNER.VALUE && (
                  <Box mb={2}>
                    <Divider />
                  </Box>
                )}
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs>
                    <Box>
                      <Typography
                        variant={
                          ROLES[role].VALUE === ROLE_ADMIN.VALUE
                            ? 'subtitle2'
                            : 'subtitle1'
                        }
                      >
                        {ROLES[role].LABEL}
                      </Typography>
                      <Typography color="textSecondary">
                        {ROLES[role].DESCRIPTION}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item>
                    <CustomSwitch
                      data-testid={ROLES[role].VALUE}
                      checked={
                        formData.role === ROLES[role].VALUE ||
                        (ROLES[role].VALUE === ROLE_REGULAR.VALUE &&
                          formData.role === ROLE_ADMIN.VALUE)
                      }
                      disabled={
                        ROLES[role].VALUE === ROLE_ADMIN.VALUE &&
                        [ROLE_OPERATOR.VALUE, ROLE_OWNER.VALUE, ''].includes(
                          formData.role
                        )
                      }
                      onChange={e => {
                        handleTyping(
                          ROLES[role].VALUE,
                          'role',
                          e.target.checked
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                {ROLES[role].VALUE === ROLE_OPERATOR.VALUE && (
                  <Box mt={3}>
                    <Divider />
                  </Box>
                )}
              </Box>
            ))}
          </FormControl>
        </DrawerContent>
        <DrawerContent>
          <Button
            color="primary"
            data-testid="form-submit"
            disabled={loading || hasErrors || isAnyFieldEmpty()}
            fullWidth
            type="submit"
            variant="contained"
          >
            {loading ? CUSTOM_TEXT.SUBMIT_LOADING : CUSTOM_TEXT.SUBMIT_BUTTON}
          </Button>
        </DrawerContent>
      </CustomDrawer>
    </form>
  );
}

OperatorFormComponent.propTypes = {
  callback: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.shape(OperatorModel),
  open: PropTypes.bool,
  handleClosingDrawer: PropTypes.func
};

OperatorFormComponent.defaultProps = {
  user: {},
  open: true,
  handleClosingDrawer: () => {}
};
