export const COLUMN_TITLE = {
  NAME: 'Nome',
  ACTIVITY: 'Atividade',
  DATE: 'Data',
  BEGIN: 'Início',
  END: 'Fim',
  EXPECTED: 'Previstos',
  DELIVERED: 'Sucesso',
  STATUS: 'Status'
};

export const ITINERARY_STATUS_ONGOING = [
  'waiting_slo',
  'allocating',
  'accepted',
  'started',
  'waiting_pick_up',
  'requires_verification',
  'awaiting_completion'
];

export const ITINERARY_STATUS_CANCELLED = [
  'dropped',
  'droppedThenCancelled',
  'cancelledWithCharge',
  'error',
  'merged'
];

export const ITINERARY_STATUS_MAPPER = {
  // Main status
  cancelled: 'cancelled',
  finished: 'finished',
  ongoing: 'ongoing',
  // Ongoing status
  ...ITINERARY_STATUS_ONGOING.reduce(
    (acc, value) => ({
      ...acc,
      [value]: 'ongoing'
    }),
    {}
  ),
  // Cancelled status
  ...ITINERARY_STATUS_CANCELLED.reduce(
    (acc, value) => ({
      ...acc,
      [value]: 'cancelled'
    }),
    {}
  )
};

export const TEXTS = {
  SUMMARY_TITLE: 'Coletas e entregas',
  SUMMARY_SUBTITLE: 'Entre os dias',
  PACKAGE: 'pacote',
  PACKAGES: 'pacotes',
  FILTER_TITLE: 'Status',
  ITINERARY_STATUS: {
    cancelled: 'Cancelada',
    finished: 'Concluída',
    ongoing: 'Ativa'
  },
  ACTIVITY_MAPPER: {
    TYPE_OF_ITINERARY_DELIVERY: 'Entrega',
    TYPE_OF_ITINERARY_PICKUP: 'Coleta'
  }
};

export const ORDER_STATUS = {
  ONGOING: {
    value: 'ongoing',
    text: 'Em andamento',
    key: 'ongoing'
  },
  FINISHED: {
    value: 'finished',
    text: 'Finalizada',
    key: 'finished'
  },
  CANCELLED: {
    value: 'cancelled',
    text: 'Cancelada',
    key: 'cancelled'
  }
};
