import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  createDriverService,
  getTransportTypesService
} from '../drivers.service';

import DriversFormComponent from './drivers-form.component';
import CltsFormComponent from './drivers-form-clt.component';
import { displaySnackBar, displayDialogOrSnackBar } from '../drivers.utils';
import DriversFormDialogComponent, {
  useDialog
} from './drivers-form-dialog.component';

const DriversFormContainer = ({
  profile,
  setProfile,
  onClose,
  open,
  onSubmit,
  cltForm,
  setCltForm,
  setOpenForm
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [transportTypes, setTransportTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    showDialogWithName,
    closeDialog,
    dialogOpen,
    name,
    dialogText
  } = useDialog();

  React.useEffect(() => {
    getTransportTypesService().then(setTransportTypes);
  }, [setTransportTypes]);

  const handleSubmit = formData => {
    setLoading(true);
    const oldProfileId =
      !profile?.deliverer || profile?.deliverer?.isDriver
        ? null
        : profile.deliverer.id;

    return createDriverService(formData, oldProfileId)
      .then(
        displayDialogOrSnackBar({
          formData,
          enqueueSnackbar,
          showDialogWithName
        })
      )
      .then(onSubmit)
      .then(onClose)
      .catch(
        displaySnackBar({
          enqueueSnackbar
        })
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {cltForm ? (
        <CltsFormComponent
          loading={loading}
          cltForm={cltForm}
          setCltForm={setCltForm}
          setOpenForm={setOpenForm}
          setProfile={setProfile}
          profile={profile}
        />
      ) : (
        <DriversFormComponent
          loading={loading}
          onSubmit={handleSubmit}
          profile={profile}
          onClose={onClose}
          open={open}
          transportTypes={transportTypes}
        />
      )}
      <DriversFormDialogComponent
        open={dialogOpen}
        name={name}
        dialogText={dialogText}
        onClose={closeDialog}
      />
    </>
  );
};

DriversFormContainer.propTypes = {
  profile: PropTypes.shape().isRequired,
  setProfile: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cltForm: PropTypes.bool.isRequired,
  setCltForm: PropTypes.func.isRequired,
  setOpenForm: PropTypes.func.isRequired
};

export default DriversFormContainer;
