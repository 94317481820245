import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import TRANSPORT_TYPE_MAPPER from '../transport-type-cell/transport-type.constants';

function TransportTypeDetailCell({ pickupOffer }) {
  function formatTransportType() {
    return pickupOffer.driverTransportType &&
      pickupOffer.driverTransportType !== 'TRANSPORT_TYPE_INVALID' ? (
      <Box>
        <Typography noWrap variant="body2">
          {TRANSPORT_TYPE_MAPPER[
            pickupOffer.driverTransportType
          ]?.text?.replace(/\b\w/g, l => l.toUpperCase())}
        </Typography>
      </Box>
    ) : (
      <Box>
        <Typography noWrap variant="caption">
          disponível para{' '}
          {TRANSPORT_TYPE_MAPPER[pickupOffer.transportType]?.text}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {formatTransportType()}
    </Box>
  );
}

TransportTypeDetailCell.propTypes = {
  pickupOffer: PropTypes.shape({
    driverTransportType: PropTypes.string,
    transportType: PropTypes.string
  }).isRequired
};

export default TransportTypeDetailCell;
