import { useEffect, useReducer } from 'react';
import firebase from 'firebase/app';
import api from 'infra/api/leve';
import REMOTE_CONFIG_DEFAULT from './firebase.constants';

const toCamel = s => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const remoteConfigDict = {};

Object.keys(REMOTE_CONFIG_DEFAULT).forEach(remoteConfigKey => {
  remoteConfigDict[toCamel(remoteConfigKey)] =
    REMOTE_CONFIG_DEFAULT[remoteConfigKey];
});

export const initialState = {
  isLoading: true,
  hasError: false,
  value: remoteConfigDict
};

/**
 * Firebase reducer to be used with useReducer hook
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @returns {Object} Updated state
 */
/* eslint-disable consistent-return, default-case */
const remoteConfigReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_DONE':
      return {
        ...state,
        isLoading: false,
        value: action.payload
      };
  }
};
/* eslint-enable consistent-return, default-case */

/**
 * Custom Hook
 * Get Firebase Remote Config parameter value
 *
 * @returns {Object}
 *
 * Usage example:
 *   const { value, isLoading } = useRemoteConfig();
 */
const useRemoteConfig = () => {
  const [state, dispatch] = useReducer(remoteConfigReducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      const { remoteConfig } = firebase;
      const remoteValues = await remoteConfig()
        .fetchAndActivate()
        .then(() => {
          // 'asString' is needed because Firebase outputs a structured object.
          // Using 'asString' here normalizes the output.
          // We are mapping all keys and returning a dict
          const remoteValuesDict = {};

          Object.keys(REMOTE_CONFIG_DEFAULT).forEach(remoteConfigKey => {
            const remoteValue = remoteConfig()
              .getValue(remoteConfigKey)
              .asString();

            let parsedRemoteValue;

            try {
              parsedRemoteValue = JSON.parse(remoteValue);
            } catch {
              parsedRemoteValue = remoteValue;
            }

            remoteValuesDict[toCamel(remoteConfigKey)] = parsedRemoteValue;
          });
          return remoteValuesDict;
        });

      dispatch({ type: 'FETCH_DONE', payload: remoteValues });
    };

    fetchData();
  }, []);

  return { ...state };
};

/**
 * This method is responsible for reaching the subscribeTokenFCMNotifications API
 * and registering the device's token FCM "Firebase Cloud Messaging".
 * @param {string} tokenFCM
 * @returns {Promise<[]>}
 */
export const notificationSubscriptionService = async tokenFCM => {
  return api
    .post(
      '/api/v1/last_mile/user_notification/subscribe-token-fcm-notifications',
      {
        tokenFCM
      }
    )
    .then(response => response);
};

export default useRemoteConfig;
