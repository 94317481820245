import React, { useContext } from 'react';

import { Box, Fab, Hidden, Typography } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

import { useStyles } from 'menu/menu.styles';
import { colors } from '@loggi/mar';
import { Icon } from '@loggi/loggi-icons';

import NotificationsContext from '../notifications.context';
import DrawerNotificationsComponent from '../drawer.component';

export default function ButtonNotificationsComponent() {
  const classes = useStyles();

  const {
    notifications,
    openDrawerNotifications,
    setOpenDrawerNotifications,
    handleGetNotifications
  } = useContext(NotificationsContext);

  const unseenNotificationsLength = notifications?.filter(
    ({ seenAt }) => !seenAt
  ).length;

  return (
    <>
      {openDrawerNotifications && (
        <DrawerNotificationsComponent
          notifications={notifications}
          handleCloseDrawerNotifications={() =>
            setOpenDrawerNotifications(false)
          }
          handleGetNotifications={handleGetNotifications}
        />
      )}
      <Hidden smUp>
        <Box
          item
          data-testid="grid-item-notifications"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mr={2}
          onClick={() => setOpenDrawerNotifications(true)}
        >
          <Icon name="NotificationBell" size="small" color={colors.root[0]} />
        </Box>
      </Hidden>
      <Hidden xsDown data-testid="should-show-in-desktop-profile-component">
        <Fab
          position="relative"
          className={classes.roundButton}
          onClick={() => setOpenDrawerNotifications(true)}
          data-testid="fab-button-notifications"
        >
          <NotificationsNoneIcon />
          {unseenNotificationsLength > 0 && (
            <Box
              position="absolute"
              left={28}
              bottom={31}
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="12px"
              width={28}
              height={22}
              borderRadius={12}
              bgcolor={colors.smoke[900]}
              data-testid="box-count-notifications"
            >
              <Typography variant="inherit" component="p">
                {unseenNotificationsLength > 9
                  ? '9+'
                  : unseenNotificationsLength}
              </Typography>
            </Box>
          )}
        </Fab>
      </Hidden>
    </>
  );
}
