import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/locale/pt-br';

import { Box, CardActionArea, Typography } from '@material-ui/core';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

import { hexToRgb } from '@loggi/mar/src/utils';

import { colors } from '@loggi/mar';
import { useHistory } from 'react-router';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { AUTHORIZED_ROUTES } from 'view/constants';
import {
  DRAWER,
  INTENTION
} from 'information/notifications/notifications.constants';
import { DemandRegionsContext } from 'information/routines-management/routines/demand-regions/demand-regions.provider';
import NotificationTypeBox from './notification-type-box.component';

export default function NotificationComponent({
  notification,
  handleMarkNotificationsAsRead
}) {
  const { intention, body, priority, createdAt, readAt } = notification;
  const history = useHistory();

  const rc = getSelectedRoutingCode();

  const { setOpenSimulationDrawerDemandRegions } = useContext(
    DemandRegionsContext
  );

  const goToOfferPage = () => {
    history.push(`/${rc}${AUTHORIZED_ROUTES.OFFER.LIST}`);
  };

  const goToRoutinesPage = () => {
    setOpenSimulationDrawerDemandRegions(true);
    history.push(`/${rc}${AUTHORIZED_ROUTES.ROUTINES.DEMAND_REGIONS}`);
  };

  const handleClick = () => {
    handleMarkNotificationsAsRead([notification]);
    if (intention === INTENTION.ROUTINES) {
      goToRoutinesPage();
    } else {
      goToOfferPage();
    }
  };

  const getFormattedBody = () => {
    const separator = '*';
    const separateBodyList = body.split(separator).filter(part => part !== '');
    let nextIsBold = body.slice(0, separator.length) === separator;

    return separateBodyList.map(part => {
      const result = nextIsBold ? <strong key={part}>{part}</strong> : part;

      nextIsBold = !nextIsBold;

      return result;
    });
  };

  return (
    <CardActionArea onClick={handleClick}>
      <Box
        display="flex"
        position="relative"
        paddingY={2}
        paddingLeft={4}
        paddingRight={3}
        data-testid={`box-notification${readAt ? '-read' : ''}`}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgcolor={readAt ? hexToRgb(colors.root[0], 0.5).rgba : ''}
        />
        <NotificationTypeBox priority={priority} />
        <Box>
          <Box
            display="flex"
            alignItems="center"
            marginBottom={1.2}
            lineHeight={1.5}
          >
            <Typography variant="caption">
              <Box component="span" fontWeight="fontWeightMedium">
                {DRAWER.NOTIFICATION_TITLE[intention]}
              </Box>
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <Box
                component="span"
                marginLeft={1}
                marginRight={0.8}
                fontWeight="fontWeightMedium"
              >
                {moment(createdAt).fromNow()}
              </Box>
            </Typography>
            {!readAt && (
              <Box fontSize={9}>
                <FiberManualRecordRoundedIcon
                  color="primary"
                  fontSize="inherit"
                  data-testid="rounded-icon"
                />
              </Box>
            )}
          </Box>
          <Typography variant="body2">{getFormattedBody()}</Typography>
        </Box>
      </Box>
    </CardActionArea>
  );
}

NotificationComponent.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    userId: PropTypes.string,
    intention: PropTypes.string,
    body: PropTypes.string,
    priority: PropTypes.string,
    createdAt: PropTypes.string,
    receivedAt: PropTypes.string,
    readAt: PropTypes.string
  }).isRequired,
  handleMarkNotificationsAsRead: PropTypes.func.isRequired
};
