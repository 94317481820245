import beepFile from './beep.mp3';
import errorFile from './error.mp3';
import STATUS from './sound.constants';

export const playBeep = status => {
  let audioElement = new Audio(status);
  switch (status) {
    case STATUS.SUCCESS:
      audioElement = new Audio(beepFile);
      break;
    case STATUS.ERROR:
      audioElement = new Audio(errorFile);
      break;
    default:
      throw new Error(
        `function play do not support ${status}, try with success or error`
      );
  }
  audioElement.load();
  audioElement.addEventListener('loadeddata', () => {
    audioElement.play().catch(() => {});
  });
  return audioElement;
};

export const playSuccess = () => playBeep(STATUS.SUCCESS);
export const playError = () => playBeep(STATUS.ERROR);
