import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    maxWidth: '550px'
  },
  messageBox: {
    maxWidth: '270px'
  }
}));

export default useStyles;
