import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, List, ListSubheader, Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useStyles from './select.styles';
import SelectItemComponent from './select-item.component';

function SelectComponent({
  isViewSelect,
  selectOptions,
  selected,
  onSelectedChange,
  prefixLabel,
  internalOptionsLabel
}) {
  const [anchor, setAnchor] = useState(null);

  const classes = useStyles();

  const handleClose = () => {
    setAnchor(null);
  };

  const handleClick = event => {
    event.preventDefault();
    setAnchor(event.currentTarget);
  };

  const chooseOption = option => {
    setAnchor(null);
    onSelectedChange(option);
  };

  const open = Boolean(anchor);

  const selectedLabel = isViewSelect ? selected?.label : selected?.header;
  const textLabel = `${prefixLabel} ${selectedLabel || ''}`.toLowerCase();

  return (
    <Box component="span">
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClick}
        data-testid={`select${isViewSelect ? '-view' : ''}-button`}
        size="small"
      >
        {textLabel}
        {!open && <ArrowDropDownIcon />}
        {open && <ArrowDropUpIcon />}
      </Button>
      <Popover
        data-testid="popover-wrapper-selectOptions"
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        className={classes.popover}
      >
        <List className={classes.list}>
          {internalOptionsLabel && (
            <ListSubheader disableSticky>{internalOptionsLabel}</ListSubheader>
          )}
          {selectOptions.map(item => (
            <SelectItemComponent
              key={item.header || item.label}
              isViewSelect={isViewSelect}
              item={item}
              chooseOption={chooseOption}
              selected={selected}
            />
          ))}
        </List>
      </Popover>
    </Box>
  );
}

const itemSelectViewModel = PropTypes.shape({
  label: PropTypes.string,
  custody: PropTypes.string,
  header: PropTypes.string
});

SelectComponent.propTypes = {
  isViewSelect: PropTypes.bool,
  onSelectedChange: PropTypes.func.isRequired,
  selectOptions: PropTypes.arrayOf(itemSelectViewModel).isRequired,
  selected: itemSelectViewModel.isRequired,
  prefixLabel: PropTypes.string,
  internalOptionsLabel: PropTypes.string
};

SelectComponent.defaultProps = {
  isViewSelect: false,
  internalOptionsLabel: null,
  prefixLabel: ''
};

export default SelectComponent;
