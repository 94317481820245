import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import showSnackbar from 'shared/snackbar';
import { fetchPackageAggregatedData } from 'tracking/card-aggregation/card-aggregation.service';
import { VIEWS, AGG_FIELDS } from 'tracking/tracking.constants';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { RETURN_TO_BASE_CONTEXT } from 'view/molecules/package-drawer/drawer.constants';
import { isPlatformStatusDegraded } from 'shared/alert-status-header';

import CardReceivePackage from './card-receive-package.component';
import {
  SUCCESS_BAG,
  SUCCESS_PACKAGE,
  BARCODE_NOT_FOUND,
  BARCODE_REFERENCE_PACKAGE
} from './card-receive-package.constants';
import { cardUnifiedReceivePackageService } from './card-receive-package.service';
import { playSuccess, playError } from '../sound/index';

function CardReceivePackageContainer(props) {
  const { onBarCodeRead, filters, externalLoading } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [packageCountLoading, setPackageCountLoading] = useState(0);
  const [isLoadingNaBase, setLoadingNaBase] = useState(true);
  const [dataNaBase, setDataNaBase] = useState({ data: null });

  const showNotification = ({ message, error }) => {
    onBarCodeRead(false);
    setPackageCountLoading(_currentState =>
      _currentState > 0 ? _currentState - 1 : 0
    );
    showSnackbar({
      message,
      variant: error ? 'error' : 'success',
      enqueueSnackbar
    });
  };

  const onRead = async barcode => {
    playSuccess();
    setPackageCountLoading(_currentState => _currentState + 1);
    try {
      onBarCodeRead(true);
      const selectedRoutingCode = getSelectedRoutingCode();

      const {
        barcodeRead,
        barcodeReference
      } = await cardUnifiedReceivePackageService({
        barcode,
        sortingContextLpn: `${selectedRoutingCode} ${RETURN_TO_BASE_CONTEXT}`
      });

      const message =
        barcodeReference === BARCODE_REFERENCE_PACKAGE
          ? SUCCESS_PACKAGE(barcodeRead)
          : SUCCESS_BAG(barcodeRead);

      showNotification({
        message,
        error: false
      });
    } catch (err) {
      const errorMessage =
        err.code === 404 ? BARCODE_NOT_FOUND(err.barcodeRead) : err.message;

      playError();
      showNotification({
        message: errorMessage,
        error: true
      });
    }
  };

  useEffect(() => {
    async function fetchNaBase() {
      setDataNaBase(
        await fetchPackageAggregatedData({
          aggregatedField: AGG_FIELDS.PACKAGE_STATE,
          view: VIEWS.NA_BASE,
          filters
        })
      );
      setLoadingNaBase(false);
    }

    if (externalLoading) {
      fetchNaBase();
    }
  }, [filters, externalLoading]);

  return (
    <CardReceivePackage
      loading={packageCountLoading > 0}
      onRead={onRead}
      data={dataNaBase.data}
      loadingData={isLoadingNaBase || externalLoading}
      systemUnstable={isPlatformStatusDegraded()}
    />
  );
}

CardReceivePackageContainer.defaultName = 'CardReceivePackageContainer';

CardReceivePackageContainer.propTypes = {
  onBarCodeRead: PropTypes.func,
  filters: PropTypes.shape({}),
  externalLoading: PropTypes.bool
};

CardReceivePackageContainer.defaultProps = {
  onBarCodeRead: () => {
    // Do nothing
  },
  filters: {},
  externalLoading: false
};

export default CardReceivePackageContainer;
