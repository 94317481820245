import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@material-ui/core';

import FilterContext from '../filter.context';
import { LIST_STATUS } from '../filter.constants';
import useStyles from '../filter.styles';

function FilterGroup({ group, checkedItemsBase, setCheckedItemsBase }) {
  const classes = useStyles();

  const { checkedItems, toogleCheckedItem } = useContext(FilterContext);
  const checkedItemsList = Object.values(checkedItemsBase).length
    ? checkedItemsBase
    : checkedItems;
  const _currentChecked =
    (checkedItemsList && checkedItemsList[group.key]) || [];

  const filterItem = item => () => {
    toogleCheckedItem(
      group.key,
      item.value,
      setCheckedItemsBase,
      checkedItemsBase
    );
  };

  return (
    <List className={classes.list}>
      <ListSubheader disableSticky>{group?.title}</ListSubheader>
      {group.items.map(item => {
        return (
          <ListItem
            key={item.value}
            dense
            button
            divider
            onClick={filterItem(item)}
            selected={_currentChecked.indexOf(item.value) !== -1}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={_currentChecked.indexOf(item.value) !== -1}
                tabIndex={-1}
                disableRipple
                color="primary"
                value={item.value}
                data-testid={`checked-${group.key}-${item.value}`}
              />
            </ListItemIcon>
            {item.icon && (
              <ListItemIcon
                data-testid={`icon-list-item-${group.key}-${item.value}`}
              >
                {item.icon}
              </ListItemIcon>
            )}
            {item.style && (
              <ListItemIcon
                data-testid={`icon-list-item-${group.key}-${item.value}`}
              >
                <FiberManualRecordRoundedIcon
                  style={item.style}
                  fontSize="small"
                />
              </ListItemIcon>
            )}
            <ListItemText primary={item.text} />
          </ListItem>
        );
      })}
    </List>
  );
}

FilterGroup.propTypes = {
  group: PropTypes.shape({
    title: PropTypes.string,
    key: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        key: PropTypes.string
      })
    )
  }),
  checkedItemsBase: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  setCheckedItemsBase: PropTypes.func
};

FilterGroup.defaultProps = {
  group: {
    title: 'Status',
    key: 'status',
    items: LIST_STATUS
  },
  checkedItemsBase: [],
  setCheckedItemsBase: null
};

export default FilterGroup;
