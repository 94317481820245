import React, { useState, useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  TextField,
  Snackbar,
  SnackbarContent,
  Chip
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { CancelRounded, CheckCircleRounded } from '@material-ui/icons';
import { DrawerHeader, DrawerContent } from 'shared/drawer';
import ConfirmDialogComponent from 'shared/confirm-dialog';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { ComplexAreasContext } from './complex-areas.provider';
import {
  SNACKBAR_NOTIFICATION,
  DRAWER_CREATE_OR_UPDATE,
  DIALOG_CREATE_COMPLEX_AREA,
  DIALOG_DELETE_COMPLEX_AREA,
  DIALOG_UPDATE_COMPLEX_AREA,
  DIALOG_RETURN_COMPLEX_AREA,
  CATEGORIES_DESCRIPTION,
  CONVERT_CATEGORIES_TO_NUMBER,
  CONVERT_NUMBER_TO_CATEGORIES,
  CATEGORIES_NAMES
} from './complex-areas.constants';
import { updateComplexArea } from './complex-areas.service';
import useStyles from './complex-areas.style';

export const enqueueNotification = ({ message, variant, enqueueSnackbar }) => {
  enqueueSnackbar(message, {
    content: (key, msg) => (
      <NotificationComponent open message={msg} variant={variant} />
    )
  });
};

const NotificationComponent = forwardRef((props, ref) => {
  const { open, message, variant } = props;

  const classes = useStyles();
  const variantMap = {
    success: (
      <CheckCircleRounded fontSize="small" style={{ color: '#00D494' }} />
    ),
    error: <CancelRounded color="error" fontSize="small" />
  };

  return (
    <Snackbar
      className={classes.root}
      ref={ref}
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <SnackbarContent
        message={
          <Box display="flex" alignItems="center">
            {variantMap[variant]}
            <Box className={classes.messageBox} ml={2}>
              {message}
            </Box>
          </Box>
        }
      />
    </Snackbar>
  );
});

NotificationComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired
};

const DrawerCreateUpdateComplexAreaComponent = () => {
  const {
    currentComplexArea,
    complexAreas,
    setComplexAreas,
    setCurrentComplexArea,
    setOpenCreateUpdateDrawerComplexAreas,
    setCancelAction,
    polygons,
    areaName,
    setAreaName
  } = useContext(ComplexAreasContext);

  const [dialogCreateComplexAreaOpen, setDialogCreateComplexAreOpen] = useState(
    false
  );
  const [dialogDeleteComplexAreaOpen, setDialogDeleteComplexAreOpen] = useState(
    false
  );

  const [dialogUpdateComplexAreaOpen, setDialogUpdateComplexAreOpen] = useState(
    false
  );

  const [dialogReturnComplexAreaOpen, setDialogReturnComplexAreOpen] = useState(
    false
  );

  const [nameEmptyError, setNameEmptyError] = useState(false);

  const [currentCategory, setCurrentCategory] = useState(
    currentComplexArea?.category ? currentComplexArea?.category : 0
  );

  const { enqueueSnackbar } = useSnackbar();

  const showAlertError = message => {
    enqueueNotification({
      message,
      variant: 'error',
      enqueueSnackbar
    });
    setDialogCreateComplexAreOpen(false);
    setDialogUpdateComplexAreOpen(false);
    setDialogDeleteComplexAreOpen(false);
  };

  const showAlerSuccess = () => {
    const message = SNACKBAR_NOTIFICATION.SUCCESS_MESSAGE;
    enqueueNotification({
      message,
      variant: 'success',
      enqueueSnackbar
    });
    setDialogCreateComplexAreOpen(false);
    setDialogUpdateComplexAreOpen(false);
    setDialogDeleteComplexAreOpen(false);
  };

  const validateInputs = () => {
    if (areaName.trim() === '') {
      setNameEmptyError(true);
      return false;
    }

    const polygonData = polygons.filter(polygon => polygon.name === '');
    if (!polygonData.length) {
      showAlertError(SNACKBAR_NOTIFICATION.COMPLEX_AREA_EMPTY_ERROR);
      return false;
    }

    return true;
  };

  const handleDeleteComplexArea = () => {
    const data = complexAreas.filter(
      area => currentComplexArea.label !== area.label
    );

    updateComplexArea(data, getSelectedRoutingCode())
      .then(() => {
        const index = polygons.findIndex(
          area => currentComplexArea.label === area.name
        );

        polygons[index].polygonProps.setMap(null);
        polygons.splice(index);
        setComplexAreas(data);
        showAlerSuccess();
        setOpenCreateUpdateDrawerComplexAreas(false);
        setCurrentComplexArea(null);
      })
      .catch(() => showAlertError(SNACKBAR_NOTIFICATION.ERROR_MESSAGE));
  };

  const handleCreateComplexArea = () => {
    const polygonData = polygons.filter(polygon => polygon.name === '');
    const data = [
      ...complexAreas,
      {
        label: areaName,
        category: currentCategory,
        consensusPoints: polygonData[0]?.polygonProps
          ?.getPath()
          .getArray()
          .map(coordinates => {
            return {
              lat: coordinates.lat(),
              lng: coordinates.lng()
            };
          })
      }
    ];
    updateComplexArea(data, getSelectedRoutingCode())
      .then(() => {
        setComplexAreas(data);
        showAlerSuccess();
        setDialogCreateComplexAreOpen(false);
        setOpenCreateUpdateDrawerComplexAreas(false);
        setCurrentComplexArea(null);
      })
      .catch(() => showAlertError(SNACKBAR_NOTIFICATION.ERROR_MESSAGE));
  };

  const handleUpdateComplexArea = () => {
    if (areaName.trim() === '') {
      setNameEmptyError(true);
    } else {
      const data = polygons.map(polygon => {
        const label =
          polygon.name === currentComplexArea.label && areaName.trim() !== ''
            ? areaName.trim()
            : polygon.name;

        const categoryPolygon =
          polygon.name === currentComplexArea.label && areaName.trim() !== ''
            ? currentCategory
            : polygon.category;

        return {
          label,
          category: categoryPolygon,
          consensusPoints: polygon?.polygonProps
            ?.getPath()
            .getArray()
            .map(coordinates => {
              return {
                lat: coordinates.lat(),
                lng: coordinates.lng()
              };
            })
        };
      });
      updateComplexArea(data, getSelectedRoutingCode())
        .then(() => {
          setComplexAreas(data);
          showAlerSuccess();
          setDialogUpdateComplexAreOpen(false);
          setOpenCreateUpdateDrawerComplexAreas(false);
          setCurrentComplexArea(null);
        })
        .catch(() => showAlertError(SNACKBAR_NOTIFICATION.ERROR_MESSAGE));
    }
  };

  const handleSelectedCategory = key => {
    if (CONVERT_NUMBER_TO_CATEGORIES[currentCategory] === key) {
      setCurrentCategory(0);
    } else {
      setCurrentCategory(CONVERT_CATEGORIES_TO_NUMBER[key]);
    }
  };

  const classes = useStyles();

  return (
    <>
      <DrawerHeader
        open
        titleVariant="h5"
        title={
          currentComplexArea
            ? currentComplexArea.label
            : DRAWER_CREATE_OR_UPDATE.TITLE
        }
        detail={currentComplexArea ? '' : DRAWER_CREATE_OR_UPDATE.DETAIL}
        colorText="textSecondary"
        showDivider
        handleClosingDrawer={() => {
          setDialogReturnComplexAreOpen(true);
        }}
        variantTextDetail="body1"
      />

      <DrawerContent showDivider>
        <Typography color="textSecondary" variant="body2">
          <Box fontWeight="fontWeightMedium">
            {DRAWER_CREATE_OR_UPDATE.NAME}
          </Box>
        </Typography>
        <Box mt={1} data-testid="create-update-complex-area">
          <Box mt={2.5} mb={3.5}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              defaultValue={currentComplexArea?.label}
              inputProps={{
                'data-testid': 'label-complex-area',
                maxLength: 30,
                minLength: 3
              }}
              placeholder={DRAWER_CREATE_OR_UPDATE.NAME_AREA_PLACEHOLDER}
              label={nameEmptyError && DRAWER_CREATE_OR_UPDATE.LABEL_INPUT}
              onChange={event => {
                const { value } = event.target;
                setAreaName(value);
              }}
              error={nameEmptyError}
              helperText={
                nameEmptyError && DRAWER_CREATE_OR_UPDATE.NAME_EMPTY_ERROR
              }
            />
          </Box>
        </Box>
        <Typography color="textSecondary" variant="body2">
          <Box fontWeight="fontWeightMedium">
            {DRAWER_CREATE_OR_UPDATE.CATEGORY}
          </Box>
        </Typography>
        <Box
          mt={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          data-testid="select-categories"
        >
          {Object.entries(CATEGORIES_NAMES).map(([key, value]) => (
            <Chip
              className={classes.chipCustom}
              data-testid={`select-categories-${value}`}
              key={key}
              color={
                CONVERT_NUMBER_TO_CATEGORIES[currentCategory] === key
                  ? 'primary'
                  : 'default'
              }
              onClick={() => {
                handleSelectedCategory(key);
              }}
              size="string"
              label={value.toLowerCase()}
            />
          ))}
        </Box>
      </DrawerContent>
      <DrawerContent showDivider>
        {dialogCreateComplexAreaOpen && (
          <DialogComplexAreaComponent
            title={DIALOG_CREATE_COMPLEX_AREA.TITLE}
            subtitle={DIALOG_CREATE_COMPLEX_AREA.SUBTITLE}
            messageButtonRight={DIALOG_CREATE_COMPLEX_AREA.ALL_RIGHT}
            messageButtonLeft={DIALOG_CREATE_COMPLEX_AREA.CHECK}
            onRightDialog={() => handleCreateComplexArea()}
            onLeftDialog={() => setDialogCreateComplexAreOpen(false)}
          />
        )}
        {dialogDeleteComplexAreaOpen && (
          <DialogComplexAreaComponent
            title={DIALOG_DELETE_COMPLEX_AREA.TITLE}
            subtitle={DIALOG_DELETE_COMPLEX_AREA.SUBTITLE}
            messageButtonRight={DIALOG_DELETE_COMPLEX_AREA.DELETE}
            messageButtonLeft={DIALOG_DELETE_COMPLEX_AREA.CANCEL}
            onRightDialog={() => handleDeleteComplexArea()}
            onLeftDialog={() => setDialogDeleteComplexAreOpen(false)}
          />
        )}
        {dialogUpdateComplexAreaOpen && (
          <DialogComplexAreaComponent
            title={DIALOG_UPDATE_COMPLEX_AREA.TITLE}
            subtitle={DIALOG_UPDATE_COMPLEX_AREA.SUBTITLE}
            messageButtonLeft={DIALOG_UPDATE_COMPLEX_AREA.CHECK}
            messageButtonRight={DIALOG_UPDATE_COMPLEX_AREA.ALL_RIGHT}
            onRightDialog={() => handleUpdateComplexArea()}
            onLeftDialog={() => setDialogUpdateComplexAreOpen(false)}
          />
        )}
        {dialogReturnComplexAreaOpen && (
          <DialogComplexAreaComponent
            title={DIALOG_RETURN_COMPLEX_AREA.TITLE}
            subtitle={DIALOG_RETURN_COMPLEX_AREA.SUBTITLE}
            messageButtonRight={DIALOG_RETURN_COMPLEX_AREA.RETURN}
            messageButtonLeft={DIALOG_RETURN_COMPLEX_AREA.CONTINUE}
            onRightDialog={() => {
              setOpenCreateUpdateDrawerComplexAreas(false);
              setCurrentComplexArea(null);
              setCancelAction(true);
            }}
            onLeftDialog={() => setDialogReturnComplexAreOpen(false)}
          />
        )}
        <Box mt={1} mb={2}>
          <Typography color="textSecondary" variant="body2">
            <Box fontWeight="fontWeightMedium">
              {DRAWER_CREATE_OR_UPDATE.CATEGORIES}
            </Box>
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            style={{ overflow: 'hidden', overflowX: 'scroll' }}
            maxHeight="200px"
            minWidth="400px"
            mb={2}
          >
            {Object.entries(CATEGORIES_DESCRIPTION).map(([key, value]) => (
              <Paper
                component={Box}
                minHeight="143px"
                minWidth="300px"
                p={2.5}
                border={1}
                mt={2}
                mr={2}
                mb={4}
                borderColor="grey.100"
                style={{ borderRadius: 16 }}
                key={key}
              >
                <Typography variant="subtitle2">
                  <em>{key}</em>
                </Typography>
                <Box pt={1}>
                  <Typography variant="body2">{value}</Typography>
                </Box>
              </Paper>
            ))}
          </Box>
        </Box>
      </DrawerContent>
      <DrawerContent>
        <Box>
          {currentComplexArea ? (
            <Box mt={4} display="flex">
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={() => setDialogDeleteComplexAreOpen(true)}
                    data-testid="delete-complex-area"
                  >
                    {DRAWER_CREATE_OR_UPDATE.DELETE_AREA}
                  </Button>
                </Grid>
                <Grid item xs={7}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => setDialogUpdateComplexAreOpen(true)}
                    data-testid="save-complex-area"
                  >
                    {DRAWER_CREATE_OR_UPDATE.SAVE_AREA}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                if (validateInputs()) setDialogCreateComplexAreOpen(true);
              }}
              data-testid="create-complex-area"
            >
              {DRAWER_CREATE_OR_UPDATE.CREATE_AREA}
            </Button>
          )}
        </Box>
      </DrawerContent>
    </>
  );
};

const DialogComplexAreaComponent = ({
  title,
  subtitle,
  messageButtonLeft,
  messageButtonRight,
  onLeftDialog,
  onRightDialog
}) => {
  return (
    <ConfirmDialogComponent open title={title} description={subtitle}>
      <Button
        onClick={onLeftDialog}
        color="primary"
        data-testid="confirmation-dialog-cancel-btn"
      >
        {messageButtonLeft}
      </Button>
      <Button
        onClick={onRightDialog}
        color="primary"
        variant="contained"
        data-testid="confirmation-dialog-submit-btn"
      >
        {messageButtonRight}
      </Button>
    </ConfirmDialogComponent>
  );
};

DialogComplexAreaComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  messageButtonLeft: PropTypes.string.isRequired,
  messageButtonRight: PropTypes.string.isRequired,
  onLeftDialog: PropTypes.func.isRequired,
  onRightDialog: PropTypes.func.isRequired
};

DrawerCreateUpdateComplexAreaComponent.propTypes = {};

export default DrawerCreateUpdateComplexAreaComponent;
