import React from 'react';
import PropTypes from 'prop-types';

import CheckIcon from '@material-ui/icons/Check';
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';

function SelectItemComponent({ isViewSelect, item, chooseOption, selected }) {
  const viewOption = {
    text: item.label,
    selectedText: selected.label,
    onSelectOption: () => chooseOption(item.custody)
  };

  const orderByOption = {
    text: item.header,
    selectedText: selected.header,
    onSelectOption: () => chooseOption(item)
  };

  const option = isViewSelect ? viewOption : orderByOption;

  return (
    <ListItem
      dense
      button
      divider
      onClick={option.onSelectOption}
      selected={option.selectedText === option.text}
      data-testid={`select-option-${option.text.toLowerCase()}`}
    >
      <ListItemText primary={option.text} />
      {option.selectedText === option.text && (
        <ListItemSecondaryAction>
          <CheckIcon color="primary" fontSize="small" />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

const itemSelectViewModel = PropTypes.shape({
  label: PropTypes.string,
  custody: PropTypes.string,
  header: PropTypes.string
});

SelectItemComponent.propTypes = {
  isViewSelect: PropTypes.bool.isRequired,
  chooseOption: PropTypes.func.isRequired,
  selected: itemSelectViewModel.isRequired,
  item: itemSelectViewModel.isRequired
};

export default SelectItemComponent;
