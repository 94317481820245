import { colors } from '@loggi/mar';

const borderWidth = 0.375; // 3px
const padding = 3.5; // 28px
const useStyles = ({ typography, palette, spacing }) => {
  return {
    padding: 0,
    // TODO: extract this style of ListItem to mar
    // Used on FilterComponent and SelectComponent

    // ListSubheader
    '& .MuiListSubheader-root': {
      padding: `${spacing(1.5)}px ${spacing(padding)}px`,
      lineHeight: 1,
      fontSize: typography.pxToRem(12),
      fontWeight: 700,
      color: palette.text.disabled
    },

    // ListItem
    '& .MuiListItem-root': {
      '&.MuiListItem-gutters': {
        paddingLeft: spacing(padding),
        paddingRight: spacing(padding)
      },

      // ListItemText -> primary text
      '& .MuiListItemText-primary': {
        fontSize: typography.pxToRem(14),
        fontWeight: 400,
        color: colors.smoke[900]
      },

      // selector for divider (bottom border)
      '&.MuiListItem-divider': {
        borderColor: colors.smoke[50],
        '&:last-child ': {
          borderBottomWidth: 0
        }
      },

      // selector when has ListItemSecondaryAction
      '&.MuiListItem-secondaryAction': {
        paddingRight: spacing(padding * 2),
        '& + .MuiListItemSecondaryAction-root': {
          right: spacing(padding)
        }
      },

      // selector for ListItemIcon
      '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: spacing(2)
      },

      // selector for selected ListItem
      '&.Mui-selected': {
        backgroundColor: colors.blue[50],
        borderLeftColor: palette.primary.dark,
        borderLeftWidth: spacing(borderWidth),
        borderLeftStyle: 'solid',
        paddingLeft: spacing(padding - borderWidth),

        '&.MuiListItem-divider': {
          borderBottomColor: colors.blue[100]
        }
      }
    }
  };
};

export default useStyles;
