import api from 'infra/api/leve';
import { URL_PROXY_V1_PREFIX } from 'infra/service/constants';

const getDistributionCenterInfo = async () => {
  return api
    .get(`${URL_PROXY_V1_PREFIX}/distribution_center/info`)
    .then(response => response.data);
};

export default getDistributionCenterInfo;
