import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Paper, Tabs, Tab } from '@material-ui/core';
import LoadingCard from 'shared/loading/loading-card.component';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

import { AUTHORIZED_ROUTES } from 'view/constants';
import { getCompanyType } from 'auth/access-control/access-control.service';
import { CanFunction } from 'auth/access-control';
import { RoutinesManagementContext } from './routines-management.context';

const TAB = {
  BASE: 0,
  CIRCLES: 1
};

const useStyles = makeStyles({
  root: {
    borderBottom: `1px solid ${colors.smoke[200]}`
  }
});

export default function RoutinesManagementProvider({ children }) {
  const { url } = useRouteMatch();
  const history = useHistory();
  const style = useStyles();
  const hasCirclesEnabled = getCompanyType() === 'LEVE';
  const [value, setValue] = useState(
    history.location.pathname.indexOf(
      AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.CIRCLES
    ) >= 0
      ? TAB.CIRCLES
      : TAB.BASE
  );
  const [cardContent, setCardContent] = useState(<LoadingCard />);

  const tabs =
    CanFunction({ customDisabledTo: 'loggiPonto' }) && hasCirclesEnabled
      ? ['Base', 'Círculo']
      : ['Base'];

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === TAB.CIRCLES) {
      history.push(`${url}${AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.CIRCLES}`);
    } else {
      history.push(`${url}${AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.BASE}`);
    }
  };

  useEffect(() => {
    if (
      history.location.pathname.endsWith(
        AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.CIRCLES
      ) ||
      history.location.pathname.endsWith(
        AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.CREATE_CIRCLES
      )
    )
      setValue(TAB.CIRCLES);
  }, [history.location.pathname]);

  return (
    <RoutinesManagementContext.Provider value={{ setCardContent }}>
      <Paper component={Box} elevation={10} flexGrow={1} mb={6} minHeight={44}>
        <Box pt={1} px={5.5}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={value}
            onChange={handleChange}
            className={style.root}
          >
            {tabs.map(tab => (
              <Tab label={tab} key={tab} />
            ))}
          </Tabs>
        </Box>
        <Box px={3.5} pt={4.5} pb={4.5}>
          {cardContent}
        </Box>
      </Paper>
      <>{children}</>
    </RoutinesManagementContext.Provider>
  );
}

RoutinesManagementProvider.propTypes = {
  children: PropTypes.node.isRequired
};
