import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  input: {
    width: '350px',
    height: '56px',
    padding: '0px 14px'
  }
}));

export default useStyles;
