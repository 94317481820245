import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export default function LoadingComponent() {
  return (
    <Box display="flex" mt={10} justifyContent="center">
      <CircularProgress justify="center" />
    </Box>
  );
}
