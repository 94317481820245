import createNewPassword from '@loggi/authentication/src/locales/pt-br/create-new-password.json';

export default {
  ...createNewPassword,
  header: {
    message: 'Crie uma nova senha'
  },
  subHeader: {
    message:
      'Você vai usar essa senha em todos os acessos, por isso, é importante que você lembre dela.'
  },
  success: { message: 'Pronto, tudo certo! Sua senha foi alterada.' }
};
