import React from 'react';
import { Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ERROR_LABEL } from './card-aggregation.constants';

const CardAggregationError = ({ onReload }) => {
  return (
    <Typography>
      {ERROR_LABEL.TEXT}
      <Button onClick={() => onReload()} data-testid="retry-button">
        {ERROR_LABEL.BUTTON}
      </Button>
    </Typography>
  );
};

CardAggregationError.propTypes = {
  onReload: PropTypes.func.isRequired
};

export default CardAggregationError;
