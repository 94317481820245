import showSnackbar from 'shared/snackbar';
import { TEXTS } from './form/drivers-form.constants';

export const displaySnackBar = ({
  enqueueSnackbar,
  message,
  variant = 'error'
}) => error =>
  showSnackbar({
    withButton: false,
    variant,
    message: message || error.message,
    enqueueSnackbar
  });

export const getSuccessMessage = (message = '') => {
  const fieldsMap = {
    email: 'e-mail',
    mobile_1: 'telefone',
    full_name: 'nome'
  };

  const fields = message
    ? Object.entries(fieldsMap).reduce((acc, [field, transField]) => {
        if (message.includes(field)) acc.push(transField);
        return acc;
      }, [])
    : [];

  if (!fields.length) return TEXTS.DRIVER_CREATED;

  const isPlural = fields.length > 1;
  const lastError = fields.pop();
  const textFields = isPlural
    ? `${fields.join(', ')} e ${lastError}`
    : lastError;

  const text = isPlural ? 'vão ficar iguais' : 'vai ficar igual';

  return `Pronto, sucesso no cadastro! Mas o ${textFields} ${text} ao cadastro anterior.`;
};

export const displayDialogOrSnackBar = ({
  formData,
  enqueueSnackbar,
  showDialogWithName
}) => ({ status, data }) => {
  const message =
    data?.message === undefined && data?.driverId === undefined
      ? data
      : data.message;
  return status === 202
    ? showDialogWithName(formData.fullName, message)
    : showSnackbar({
        withButton: false,
        message: getSuccessMessage(message),
        variant: 'success',
        enqueueSnackbar
      });
};

export const copyLink = url => navigator.clipboard.writeText(url);
