import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0
  }
}));
