import React from 'react';

import moment from 'moment';
import 'moment/locale/pt-br';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Box, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';

import { DEMAND_TYPE, FILTERS } from 'shared/filter/filter.constants';
import StatusCell from './cells-component/status-cell';
import DeadlineCell from './cells-component/deadline-cell';
import TransportTypeCell from './cells-component/transport-type-cell';
import TransportTypeDetailCell from './cells-component/transport-type-detail-cell';
import { moneyFormat } from './offer.utils';
import { TEXT_NO_DRIVER_ACCEPTED } from './offer.constants';

export const startEndDateTime = {
  header: 'Prazo',
  component: item => {
    const statusList = item.pickupOfferList.map(order => order.statusDisplay);
    return (
      <DeadlineCell
        startDateTime={item?.startDateTime}
        endDateTime={item?.endDateTime}
        statusList={statusList}
      />
    );
  }
};

export const shipperName = {
  header: 'Coletar com',
  component: item => item.shipperName || '-'
};

export const pickupOriginName = {
  header: 'Retirar com',
  component: item => item.pickupOriginName || '-'
};

export const shipperVicinity = {
  header: 'Bairro',
  component: item => item.shipperVicinity || '-'
};

export const pickupOriginVicinity = {
  header: 'Bairro',
  component: item => item.pickupOriginVicinity || '-'
};

export const transportType = {
  header: 'Pacotes',
  component: item => {
    const isPickup =
      item.pickupOfferList?.[0]?.demandType === DEMAND_TYPE.PICKUP;

    const pkgCount = isPickup
      ? item.pickupOfferList?.reduce(
          (sum, offer) => sum + Number.parseInt(offer.packageCount, 10),
          0
        )
      : item.volumesCount;

    const pkg = pkgCount ? `${pkgCount} • ` : '';
    const PREFIX = `${pkg}Bom para`;
    const transportTypes = item.pickupOfferList.map(
      order => order.transportType
    );

    return (
      <>
        <TransportTypeCell transportTypes={transportTypes}>
          {PREFIX}
        </TransportTypeCell>
      </>
    );
  }
};

export const transportTypeDetail = {
  header: 'Veículo',
  component: item => {
    const { offer } = item || {};
    return <TransportTypeDetailCell pickupOffer={offer} />;
  }
};

export const driverName = {
  header: 'Entregador',
  component: item => {
    const { offer } = item || {};
    return (
      <>
        {!!offer.driverName && (
          <Typography variant="body2">{offer.driverName}</Typography>
        )}
        {!offer.driverName && (
          <Typography variant="caption">{TEXT_NO_DRIVER_ACCEPTED}</Typography>
        )}
      </>
    );
  }
};

export const statusLastUpdateTime = {
  header: 'Atualização',
  component: item => {
    const { offer } = item || {};
    const momentStatusLastUpdateTime = moment(offer.lastModifiedTime).locale(
      'pt-BR'
    );
    return momentStatusLastUpdateTime.format('DD MMM, HH:mm');
  }
};

export const offerId = {
  header: 'ID',
  component: item => {
    const { pickup, offer } = item || {};
    return (
      <Typography noWrap variant="caption">
        <Box component="span" style={{ color: colors.smoke[700] }}>
          #{pickup?.bagSeal || offer?.id}
        </Box>
      </Typography>
    );
  }
};

export const offerStatus = {
  header: 'Situação',
  component: item => {
    const statusList = item.pickupOfferList.map(order => order.statusDisplay);
    return <StatusCell status={statusList} />;
  }
};

export const offerStatusDetail = {
  header: 'Situação',
  component: item => {
    const { offer } = item || {};
    const statusList = [offer?.statusDisplay];
    return <StatusCell status={statusList} />;
  }
};

const collapsible = {
  header: '',
  cellStyle: { flexBasis: '1%' },
  component: open => {
    return (
      <>
        {open ? (
          <KeyboardArrowDownIcon color="disabled" />
        ) : (
          <ChevronRightIcon color="disabled" />
        )}
      </>
    );
  }
};

export const countPackageAnticipated = {
  header: 'Pacotes',
  cellStyle: { flexBasis: '80%' },
  component: item => {
    const pkgCount = () => {
      return 'Sem previsão de pacotes';
    };

    const pkg = pkgCount() ? `${pkgCount()} • ` : '';
    const PREFIX = `${pkg}Bom para`;
    const transportTypes = item.pickupOfferList.map(
      order => order.transportType
    );

    return (
      <>
        <TransportTypeCell transportTypes={transportTypes}>
          {PREFIX}
        </TransportTypeCell>
      </>
    );
  }
};

export const anticipatedOfferStatus = {
  header: 'Situação',
  component: item => {
    const statusList = item.pickupOfferList.map(order => order.statusDisplay);
    return <StatusCell status={statusList} />;
  }
};

export const deliveryTimestamp = {
  header: 'Oferta disponível há',
  component: item => moment(item.offerTime).fromNow(true) || '-'
};

export const deliveryForToday = {
  header: 'Para hoje',
  component: item => item.volumesCountToday || '-'
};

export const deliveryStops = {
  header: 'Paradas',
  component: item => item.legsCount || '-'
};

/*
  Round function always round without any decimal number,
  so we need to multiply by 100 and after divide by 100.
  So, the firstLegDistance is in meters and we need to divide by 1000.
  The formula bellow is equal to:
  round(firstLegDistance / 1000 * 100) / 100
*/
export const deliveryFirstLegDistance = {
  header: 'Primeira partida',
  component: item => {
    const { firstLegDistance } = item || {};
    if (!firstLegDistance || Number.isNaN(firstLegDistance)) {
      return '-';
    }

    return `${Math.round(firstLegDistance / 10) / 100} km`;
  }
};

export const routeValue = {
  header: 'Valor da rota',
  component: item => {
    if (!item || !item.compensationValue) {
      return '-';
    }

    return moneyFormat(item.compensationValue);
  }
};

export const region = {
  header: 'Região',
  component: item => item.pickupOfferList[0].regionName
};

export const ACCORDION_CONFIG_OFFER = (demandType, valueTab) => {
  if (demandType === DEMAND_TYPE.DELIVERY) {
    if (valueTab === FILTERS.ANTICIPATED) {
      return {
        collapsible,
        startEndDateTime,
        countPackageAnticipated,
        region,
        anticipatedOfferStatus
      };
    }

    return {
      collapsible,
      deliveryTimestamp,
      transportType,
      deliveryForToday,
      deliveryStops,
      deliveryFirstLegDistance,
      routeValue,
      offerStatus
    };
  }

  return {
    collapsible,
    startEndDateTime,
    pickupOriginName,
    pickupOriginVicinity,
    transportType,
    offerStatus
  };
};

export const ACCORDION_CONFIG_DETAILS_OFFER = {
  transportTypeDetail,
  driverName,
  statusLastUpdateTime,
  offerId,
  offerStatusDetail
};
