import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper } from '@material-ui/core';

import Pagination from 'shared/pagination';
import ConfigurableTable from 'view/molecules/configurable-table';

import { SHORT_LABEL_COMPANY_TYPE } from 'auth/access-control/access-control.service';
import EmptyState from './empty-state/drivers-list-empty-state.component';
import getColumns, { getColumnsWithId } from './drivers-list.configuration';

export default function DriversListComponent(props) {
  const {
    drivers,
    pagination,
    hasDc,
    onChangePage,
    onSelectDriver,
    companyType
  } = props;

  const columns =
    companyType === SHORT_LABEL_COMPANY_TYPE.CARRIER
      ? getColumnsWithId(hasDc)
      : getColumns(hasDc);

  return (
    <Box data-testid="drivers-list">
      <Paper
        component={Box}
        elevation={10}
        flexGrow={1}
        mb={6}
        minHeight={44}
        p={5.5}
      >
        {drivers.length === 0 ? (
          <EmptyState />
        ) : (
          <>
            <ConfigurableTable
              columns={columns}
              data={drivers}
              onClickRow={onSelectDriver}
            />
            <Box data-testid="drivers-list-pagination" mt={5}>
              <Pagination
                onPageChange={onChangePage}
                initialPage={pagination.currentPage}
                numberOfPages={pagination.numberOfPages}
              />
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
}

DriversListComponent.propTypes = {
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      mobileNumber: PropTypes.string,
      email: PropTypes.string,
      deliverer: PropTypes.shape({
        id: PropTypes.string,
        cpf: PropTypes.string,
        transportType: PropTypes.string,
        isDriver: PropTypes.bool,
        apiKey: PropTypes.string,
        availableForOffer: PropTypes.bool,
        profilePictureThumbnailUrl: PropTypes.string,
        operationalStatus: PropTypes.string
      })
    })
  ).isRequired,
  hasDc: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    numberOfPages: PropTypes.number
  }).isRequired,
  onChangePage: PropTypes.func.isRequired,
  onSelectDriver: PropTypes.func.isRequired,
  companyType: PropTypes.string.isRequired
};
