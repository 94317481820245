/* eslint-disable camelcase */

import { getSelectedBase } from 'profile/profile.service';

export const CUSTOM_MESSAGES = {
  401: 'Você digitou o e-mail ou a senha errada. Tente de novo.',
  400: 'Você digitou o e-mail ou a senha errada. Tente de novo.'
};
export const VALIDATION_ERROR_MESSAGE = 'Digite um e-mail válido.';

export const getLoggedDCId = () => getSelectedBase()?.distribution_center?.id;

export const getLoggedLMCId = () =>
  getSelectedBase()?.last_mile_company?.identification;

export const getLoggedLMCCompanyType = () =>
  getSelectedBase()?.last_mile_company?.company_type;

export const getSelectedRoutingCode = () => {
  const DCRoutingCode = getSelectedBase()?.distribution_center?.routing_code;
  const LMCIdentification = getSelectedBase()?.last_mile_company
    ?.identification;

  return DCRoutingCode || LMCIdentification;
};
