import React, { useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  DialogActions,
  IconButton,
  Drawer
} from '@material-ui/core';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import { AUTHORIZED_ROUTES } from 'view/constants';

import { colors } from '@loggi/mar';

import { ReactComponent as CloseIcon } from 'images/close-icon.svg';
import { ReactComponent as OnboardingDummy } from 'images/onboarding-dummy.svg';
import { ReactComponent as OnboardingBipe } from 'images/onboarding-bipe.svg';
import { ReactComponent as OnboardingCaution } from 'images/onboarding-caution.svg';
import HeaderDialog from 'shared/onboarding-dialog/header-dialog.component';
import SkipHeader from 'shared/onboarding-dialog/skip-header.component';
import ContentDialog from 'shared/onboarding-dialog/content-dialog.component';
import { ONBOARDING } from './complex-areas.constants';

export default function ComplexAreasOnBoardingDialog({ open, onClose }) {
  const [dialogOpen, setDialogOpen] = useState(ONBOARDING.FIRST_SCREEN);
  const history = useHistory();
  const handleGoComplexAreas = () => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${AUTHORIZED_ROUTES.ROUTINES.COMPLEX_AREAS}`);
  };

  return (
    <Drawer
      open={open}
      anchor="right"
      variant="persistent"
      data-testid="onboarding-complex-areas"
    >
      <Box component="div" display="flex" height="100%">
        <Box
          maxWidth={480}
          width={480}
          minWidth={480}
          role="presentation"
          component="div"
          mr={2}
          ml={2}
        >
          {dialogOpen === ONBOARDING.FIRST_SCREEN && (
            <>
              <HeaderDialog>
                <IconButton onClick={onClose}>
                  <CloseIcon data-testid="back-routines" color="default" />
                </IconButton>
              </HeaderDialog>
              <Box component="div" display="flex" flexDirection="column">
                <ContentDialog
                  title={ONBOARDING.TITLE_FIRST_SCREEN}
                  subtitle={ONBOARDING.NOVELTY}
                  description={ONBOARDING.DESCRIPTION_FIRST_SCREEN}
                >
                  <OnboardingDummy />
                </ContentDialog>
                <Box
                  mt={11}
                  display="flex"
                  ml={3}
                  flexDirection="column"
                  py={3}
                  data-testid="onboarding-first-screen"
                >
                  <DialogActions>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={() => setDialogOpen(ONBOARDING.SECOND_SCREEN)}
                      fullWidth
                      data-testid="go-second-screen"
                    >
                      {ONBOARDING.LETS_GO}
                    </Button>
                  </DialogActions>
                </Box>
              </Box>
            </>
          )}
          {dialogOpen === ONBOARDING.SECOND_SCREEN && (
            <>
              <HeaderDialog>
                <SkipHeader
                  title={ONBOARDING.SKIP}
                  handleClick={handleGoComplexAreas}
                >
                  <Box fontSize={8}>
                    <FiberManualRecordRoundedIcon
                      color="primary"
                      fontSize="inherit"
                    />
                    <FiberManualRecordRoundedIcon
                      style={{ color: colors.smoke[100] }}
                      fontSize="inherit"
                    />
                  </Box>
                </SkipHeader>
              </HeaderDialog>
              <Box component="div" display="flex" flexDirection="column">
                <Box mb={3}>
                  <ContentDialog
                    title={ONBOARDING.TITLE_SECOND_SCREEN}
                    description={ONBOARDING.DESCRIPTION_SECOND_SCREEN}
                  >
                    <Box mb={5}>
                      <OnboardingCaution />
                    </Box>
                  </ContentDialog>
                </Box>
                <Box
                  mt={10}
                  display="flex"
                  ml={3}
                  flexDirection="column"
                  py={5}
                  data-testid="onboarding-second-screen"
                >
                  <DialogActions>
                    <Button
                      color="primary"
                      size="medium"
                      onClick={() => setDialogOpen(ONBOARDING.FIRST_SCREEN)}
                      data-testid="back-first-screen"
                    >
                      {ONBOARDING.BACK}
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="large"
                      onClick={() => setDialogOpen(ONBOARDING.THIRD_SCREEN)}
                      fullWidth
                      data-testid="go-third-screen"
                    >
                      {ONBOARDING.CONTINUE}
                    </Button>
                  </DialogActions>
                </Box>
              </Box>
            </>
          )}
          {dialogOpen === ONBOARDING.THIRD_SCREEN && (
            <>
              <HeaderDialog>
                <SkipHeader
                  title={ONBOARDING.SKIP}
                  handleClick={handleGoComplexAreas}
                >
                  <Box fontSize={8}>
                    <FiberManualRecordRoundedIcon
                      style={{ color: colors.smoke[100] }}
                      fontSize="inherit"
                    />
                    <FiberManualRecordRoundedIcon
                      color="primary"
                      fontSize="inherit"
                    />
                  </Box>
                </SkipHeader>
              </HeaderDialog>
              <Box component="div" display="flex" flexDirection="column">
                <Box mb={3}>
                  <ContentDialog
                    title={ONBOARDING.TITLE_THIRD_SCREEN}
                    description={ONBOARDING.DESCRIPTION_THIRD_SCREEN}
                  >
                    <Box mb={5}>
                      <OnboardingBipe />
                    </Box>
                  </ContentDialog>
                </Box>
                <Box
                  mt={10}
                  display="flex"
                  ml={3}
                  flexDirection="column"
                  py={5}
                  data-testid="onboarding-third-screen"
                >
                  <DialogActions>
                    <Button
                      color="primary"
                      size="large"
                      onClick={() => setDialogOpen(ONBOARDING.SECOND_SCREEN)}
                      data-testid="back-second-screen"
                    >
                      {ONBOARDING.BACK}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      fullWidth
                      data-testid="go-create-complex-areas"
                      onClick={handleGoComplexAreas}
                    >
                      {ONBOARDING.CREATE_AREA}
                    </Button>
                  </DialogActions>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}
ComplexAreasOnBoardingDialog.defaultProps = {
  open: false
};

ComplexAreasOnBoardingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};
