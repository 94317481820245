import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Box, Drawer } from '@material-ui/core';
import showSnackbar from 'shared/snackbar';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { getSortingContext } from '../demand-regions.service';
import { DemandRegionsContext } from '../demand-regions.provider';
import { getNumberDecisions } from '../demand-regions.utils';
import InitialDrawer from './demand-regions-initial.drawer';

const DemandRegionsDrawer = ({ open, onClose }) => {
  const { sortingContext, setSortingContext } = useContext(
    DemandRegionsContext
  );
  const { enqueueSnackbar } = useSnackbar();
  const [numberDecisions, setNumberDecisions] = useState(null);

  useEffect(() => {
    getSortingContext(getSelectedRoutingCode())
      .then(response => {
        setSortingContext(response?.data?.sortingContext || null);
      })
      .catch(() => {
        showSnackbar({
          message: 'Error',
          enqueueSnackbar,
          withButton: true,
          variant: 'error'
        });
      });

    setNumberDecisions(getNumberDecisions(sortingContext));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSortingContext, enqueueSnackbar]);

  return (
    <Drawer
      open={open}
      anchor="right"
      variant="persistent"
      data-testid="demand-regions-drawer"
    >
      <Box
        width={480}
        role="presentation"
        mr={2}
        ml={2}
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >
        <InitialDrawer
          numPositions={numberDecisions}
          lastUpdated={sortingContext?.lastUpdated}
          onClose={onClose}
        />
      </Box>
    </Drawer>
  );
};

DemandRegionsDrawer.defaultProps = {
  open: false
};

DemandRegionsDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default DemandRegionsDrawer;
