import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Template from './template.component';
import Content from './content.component';
import Summary from './summary.component';

import { SummaryContext } from './summary.context';
import useStyles from './template.styles';

function TemplateContainer({ children }) {
  const classes = useStyles();

  const [summary, setSummary] = useState(<></>);
  return (
    <SummaryContext.Provider value={{ setSummary }}>
      <Template className={classes.root}>
        <Summary>{summary}</Summary>
        <Content>{children}</Content>
      </Template>
    </SummaryContext.Provider>
  );
}

TemplateContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default TemplateContainer;
