import { AUTHORIZED_ROUTES } from 'view/constants';

export const TEXT = {
  TODAY: 'Para hoje',
  DELAYED: 'Com atraso',
  TOMORROW: 'Para amanhã',
  AFTER_TOMORROW: 'Para depois',
  TITLE_PACKAGES_BY_DEADLINE: 'Por prazo',
  TITLE_TO_PAY_ATTENTION: 'Para prestar atenção',
  TITLE_PACKAGES_BY_POSITION: 'Por estação',
  WITH_FAULTS: 'Com falta',
  LOCATION_PROBLEM: 'Problema na localização',
  NO_DEADLINE: 'Sem prazo',
  AT_RISK: 'Chance de extravio',
  AWAITING_RESOLUTION: 'Aguardando tratativa',
  RECEIVE: 'Recebimento',
  BASE: 'Na base',
  PACKAGE: 'pacote',
  CARD_FRAME_BUTTON: 'Ver pacotes',
  NONE_PACKAGE: 'Nenhum pacote',
  EMPTY_BASE:
    'Assim que chegarem, bipe as unidades no recebimento para aparecer aqui.',
  EMPTY_DETAIL_COLLECTION:
    'Tudo tranquilo! Sem pacotes nesta coleção por enquanto.'
};

export const TEXT_DEADLINE = {
  delayed: TEXT.DELAYED,
  today: TEXT.TODAY,
  tomorrow: TEXT.TOMORROW,
  afterTomorrow: TEXT.AFTER_TOMORROW
};

export const TEXT_ATTENTION = {
  missedPackage: TEXT.WITH_FAULTS,
  suspiciousGeoCoding: TEXT.LOCATION_PROBLEM,
  noDeadline: TEXT.NO_DEADLINE,
  awaitingResolution: TEXT.AWAITING_RESOLUTION,
  atRisk: TEXT.AT_RISK
};

export const TEXT_POSITION = {
  receive: TEXT.RECEIVE,
  awaitingResolution: TEXT.AWAITING_RESOLUTION
};

export const QUERY_FIELD = {
  LEVE:
    'last_last_mile_promised_date_annotation.annotation.last_mile_promised_date.promised_date.promised_date',
  AGENCIA: 'last_promised_date.promised_date'
};

export const DEADLINE_LABEL_TO_ROUTE = {
  delayed: AUTHORIZED_ROUTES.TRACKING.IN_BASE_DELAYED,
  today: AUTHORIZED_ROUTES.TRACKING.IN_BASE_TODAY,
  tomorrow: AUTHORIZED_ROUTES.TRACKING.IN_BASE_TOMORROW,
  afterTomorrow: AUTHORIZED_ROUTES.TRACKING.IN_BASE_AFTER_TOMORROW
};

export const HIDDEN_DEADLINE_LABEL_TO_ROUTE = {
  delayed: `/hidden${AUTHORIZED_ROUTES.TRACKING.IN_BASE_DELAYED}`,
  today: `/hidden${AUTHORIZED_ROUTES.TRACKING.IN_BASE_TODAY}`,
  tomorrow: `/hidden${AUTHORIZED_ROUTES.TRACKING.IN_BASE_TOMORROW}`,
  afterTomorrow: `/hidden${AUTHORIZED_ROUTES.TRACKING.IN_BASE_AFTER_TOMORROW}`
};

export const ATTENTION_LABEL_TO_ROUTE = {
  missedPackage: AUTHORIZED_ROUTES.TRACKING.IN_BASE_FAULTED,
  suspiciousGeoCoding: AUTHORIZED_ROUTES.TRACKING.IN_BASE_SUSPICIOUS_GEOCODING,
  noDeadline: AUTHORIZED_ROUTES.TRACKING.IN_BASE_NO_DEADLINE,
  awaitingResolution: AUTHORIZED_ROUTES.TRACKING.IN_BASE_AWAITING_RESOLUTION,
  atRisk: AUTHORIZED_ROUTES.TRACKING.IN_BASE_AT_RISK
};

export const HIDDEN_ATTENTION_LABEL_TO_ROUTE = {
  missedPackage: `/hidden${AUTHORIZED_ROUTES.TRACKING.IN_BASE_FAULTED}`,
  suspiciousGeoCoding: `/hidden${
    AUTHORIZED_ROUTES.TRACKING.IN_BASE_SUSPICIOUS_GEOCODING
  }`,
  noDeadline: `/hidden${AUTHORIZED_ROUTES.TRACKING.IN_BASE_NO_DEADLINE}`,
  awaitingResolution: `/hidden${
    AUTHORIZED_ROUTES.TRACKING.IN_BASE_AWAITING_RESOLUTION
  }`,
  atRisk: `/hidden${AUTHORIZED_ROUTES.TRACKING.IN_BASE_AT_RISK}`
};

export const POSITION_LABEL_TO_ROUTE = {
  receive: AUTHORIZED_ROUTES.TRACKING.IN_BASE_RECEIVE,
  awaitingResolution: AUTHORIZED_ROUTES.TRACKING.IN_BASE_AWAITING_RESOLUTION
};

export const HIDDEN_POSITION_LABEL_TO_ROUTE = {
  receive: `/hidden${AUTHORIZED_ROUTES.TRACKING.IN_BASE_RECEIVE}`,
  awaitingResolution: `/hidden${
    AUTHORIZED_ROUTES.TRACKING.IN_BASE_AWAITING_RESOLUTION
  }`
};
