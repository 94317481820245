export const TEXT = {
  WELCOME: {
    TITLE: 'Olá! 👋',
    CONTAINER:
      'Você foi escolhido para ser um dos Leves que vão conectar o Brasil junto com a Loggi.'
  },
  TITLE_FORM: 'Solicite o seu acesso ao Arco',
  ALREADY_REGISTERED: 'Já é cadastrado?',
  IN_HERE: 'Entre aqui',
  NO_RECEIVED_EMAIL: 'Não recebeu o email?',
  TRY_AGAIN: 'Solicite o acesso novamente',
  VERIFY_EMAIL: 'Acesso solicitado!',
  INFO_START: 'Em instantes',
  INFO_END:
    'receberá um email com as informações para finalizar o cadastro. É bem simples!',
  INVALID_PHONE: 'Digite um número de celular válido.',
  FORM_INPUT_PHONE: 'Celular',
  INVALID_NAME: 'Digite seu nome completo.',
  INVALID_EMAIL: 'Digite um e-mail válido.',
  FORM_INCOMPLETE: 'Opa! Preencha todos os campos antes de continuar.'
};

export const INVALID_LINK_MESSAGE =
  'Link de cadastrado inválido. Caso ainda não tenha feito o cadastro, por favor solicite um novo link à Loggi';

export const BUTTON = {
  SUBMITTING: 'Solicitando...',
  SUBMIT: 'Solicitar acesso',
  GO_LOGIN: 'Ir para Login'
};

export const PLACE_HOLDER = {
  FULL_NAME: 'Nome completo',
  EMAIL: 'E-mail',
  PHONE_NUMBER: 'Número de celular'
};

export const LINK_URL_ARCO = 'https://arco.loggi.com';

export const MASK = {
  PHONE_MASK: '(99) 99999-9999'
};

export const REGEX = {
  CLEAN_NON_NUMERIC: /\D/g
};
