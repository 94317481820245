import api from 'infra/api/leve';

export const getDC = () => {
  return api
    .get('/arco/api/v1/distribution_center')
    .then(response => response.data);
};

export const setSelectedBase = base => {
  localStorage.setItem('KEY_SELECTED_BASE', JSON.stringify(base));
};

export const removeSelectedBase = () => {
  localStorage.removeItem('KEY_SELECTED_BASE');
};

export const getSelectedBase = () => {
  if (localStorage.getItem('KEY_SELECTED_BASE')) {
    return JSON.parse(localStorage.getItem('KEY_SELECTED_BASE'));
  }
  return null;
};

function sortBases(a, b) {
  const _a =
    a.distribution_center?.name.toLocaleLowerCase() ||
    a.last_mile_company.name.toLocaleLowerCase();
  const _b =
    b.distribution_center?.name.toLocaleLowerCase() ||
    b.last_mile_company.name.toLocaleLowerCase();
  if (_a < _b) {
    return -1;
  }
  if (_a > _b) {
    return 1;
  }
  return 0;
}

export const fetchAndForceOrSelectFirst = async forceBase => {
  const dataResponse = await getDC();

  if (dataResponse.lastmilecompanyDistributioncenter?.length > 0 && forceBase) {
    const base = dataResponse.lastmilecompanyDistributioncenter.find(
      item =>
        // eslint-disable-next-line camelcase
        item.distribution_center?.routing_code === forceBase ||
        item.last_mile_company?.identification === forceBase
    );
    if (base) {
      setSelectedBase(base);
    } else {
      removeSelectedBase();
    }
  }

  if (dataResponse.lastmilecompanyDistributioncenter) {
    dataResponse.lastmilecompanyDistributioncenter.sort(sortBases);
  }
  // If there is at least one result and no previous selection exists
  // we should force the first select
  if (
    dataResponse.lastmilecompanyDistributioncenter?.length > 0 &&
    !getSelectedBase()
  ) {
    setSelectedBase(dataResponse.lastmilecompanyDistributioncenter[0]);
  }

  return dataResponse;
};
