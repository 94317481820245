const getAddressCoordinates = address => {
  return {
    latitude: address?.coordinates?.latitude,
    longitude: address?.coordinates?.longitude
  };
};

const getZipCode = address => {
  return address?.postalAddress?.postalCode || address?.correiosAddress?.cep;
};

const groupVolumesByCoordinates = volumes => {
  const volumesMap = {};
  volumes.forEach(volume => {
    const { latitude, longitude } = getAddressCoordinates(volume.address);
    const zipCode = getZipCode(volume.address);
    const key = `${latitude}${longitude}${zipCode.replace(/\D/g, '')}`;

    if (!volumesMap[key]) {
      volumesMap[key] = [];
    }
    volumesMap[key].push(volume);
  });
  return volumesMap;
};

const mapVolumesByExternalIds = volumes => {
  const volumesMap = {};
  volumes.forEach(volume => {
    const key = `${volume.externalKey}`;
    volumesMap[key] = volume;
  });
  return volumesMap;
};

export {
  getAddressCoordinates,
  getZipCode,
  groupVolumesByCoordinates,
  mapVolumesByExternalIds
};
