import React from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default () => {
  return (
    <>
      <Box mb={0.5}>
        <Skeleton
          variant="rect"
          width="100%"
          style={{ borderRadius: 8 }}
          height="44px"
        />
      </Box>
      <Box mb={0.5}>
        <Skeleton
          variant="rect"
          width="100%"
          style={{ borderRadius: 8 }}
          height="60px"
        />
      </Box>
      <Box mb={0.5}>
        <Skeleton
          variant="rect"
          width="100%"
          style={{ borderRadius: 8 }}
          height="60px"
        />
      </Box>
      <Box mb={0.5}>
        <Skeleton
          variant="rect"
          width="100%"
          style={{ borderRadius: 8 }}
          height="60px"
        />
      </Box>
    </>
  );
};
