import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { useMachine } from '@xstate/react';
import {
  Drawer,
  Box,
  Fab,
  CircularProgress,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import showSnackbar from 'shared/snackbar';
import { useStateValue } from 'shared/contexts';
import { SWITCHES, SWITCHES_REALTIME } from 'view/constants';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import DrawerDetail from 'view/molecules/package-drawer/drawer-detail/index';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';
import DrawerPackageHistory from 'view/molecules/package-drawer/drawer-history/drawer-history.component';
import DialogDistribuirRedirectContainer from 'shared/confirm-dialog/distribuir-redirect';
import GoogleMapComponent from './drawer-update-address/google-map';
import DrawerRefuseComponent from './drawer-refuse/drawer-refuse.component';
import DrawerChangeDeliverer from './drawer-change-deliverer';
import DrawerUpdateStatusComponent from './drawer-update-status';
import DrawerUpdateAddress from './drawer-update-address';
import DrawerError from './drawer-error/drawer-error.component';
import useStyles from './drawer.style';
import drawerMachine, { STATES, ACTIONS } from './drawer.machine';
import DrawerProofOfDelivery from './drawer-delivery-evidence/drawer-delivery-evidence.component';
import DrawerDisputeProtocolContainer from './drawer-dispute-protocol/drawer-dispute-protocol.container';

export function HeaderDrawer({
  hideIcon,
  isEnableClose,
  isEnableGoBack,
  onGoBack,
  onClose,
  isLoading
}) {
  const classes = useStyles();
  return (
    <>
      {!hideIcon && (
        <Box mx={3.5} pt={5} pb={1.5}>
          {isEnableClose && (
            <Fab
              className={classes.root}
              size="small"
              data-testid="closed"
              onClick={onClose}
            >
              <CloseRoundedIcon color="primary" />
            </Fab>
          )}
          {isEnableGoBack && (
            <Fab
              className={classes.root}
              size="small"
              data-testid="arrow-back"
              onClick={onGoBack}
            >
              <ArrowBackRoundedIcon color="primary" />
            </Fab>
          )}
        </Box>
      )}
      <Box>
        {isLoading && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress justify="center" />
          </Box>
        )}
      </Box>
    </>
  );
}

export function PackageDrawer({ setOpen, selectedPackage }) {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const [state] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();
  const { packageId, disputeId, loggiKey } = selectedPackage;

  const [drawerFullScreen, setDrawerFullScreen] = useState(false);

  const fsRealtimeEnableDistribuirRedirectFase1 = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsRealtimeEnableDistribuirRedirectFase1
  );
  const fsRealtimeEnableDistribuirRedirectFase2 = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsRealtimeEnableDistribuirRedirectFase2
  );

  const [
    clickChangeDelivererShowDialog,
    setClickChangeDelivererShowDialog
  ] = useState(false);

  const enablePackageDrawerPacktrackNewFs = IsFSActiveForCurrentBase(
    SWITCHES.enablePackageDrawerPacktrackNewFs
  );

  const [current, sendEvent] = useMachine(
    drawerMachine.withContext({
      ...drawerMachine.context,
      packageId,
      loggiKey,
      userEmail: authenticatedUser?.email,
      usePacktrack: enablePackageDrawerPacktrackNewFs
    })
  );

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    packageInfo,
    reloadPage,
    showNotification,
    notificationMessage,
    error,
    hideIcon,
    closeDrawer,
    areQualityFlagsRemoved
  } = current.context;

  const isFlowDetail = current.value === STATES.detail;
  const isFlowPackageHistory = current.value === STATES.history;
  const isFlowLoading = current.value === STATES.syncing;
  const isFlowRefuse = current.value === STATES.refuse;
  const isFlowChangeDeliverer = current.value === STATES.changeDeliverer;
  const isError = current.value === STATES.failure;
  const isFlowUpdateStatus = current.value === STATES.updateStatus;
  const isFlowChangeAddress = current.value === STATES.changeAddress;
  const isFlowProofOfDelivery = current.value === STATES.proofOfDelivery;
  const isFlowDisputeProtocol = current.value === STATES.disputeProtocol;

  const isEnableGoBack =
    isFlowPackageHistory ||
    isFlowRefuse ||
    isFlowChangeDeliverer ||
    isFlowUpdateStatus ||
    isFlowChangeAddress ||
    isFlowProofOfDelivery ||
    isFlowDisputeProtocol;
  const isEnableClose = isFlowDetail || isError;

  const fsRealtimeDisableRefusePackageNaoVeio = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsRealtimeDisableRefusePackageNaoVeio
  );

  const reloadDrawer = () => sendEvent(ACTIONS.RETRY);

  const showPackageHistory = () => {
    sendEvent(ACTIONS.HISTORY);
  };

  const showProofOfDelivery = () => {
    sendEvent(ACTIONS.PROOF_OF_DELIVERY);
  };

  const showDisputeProtocol = () => {
    sendEvent(ACTIONS.DISPUTE_PROTOCOL);
  };

  const showRefuse = () => {
    sendEvent(ACTIONS.REFUSE);
  };

  const showChangeDeliverer = () => {
    sendEvent(ACTIONS.CHANGE_DELIVERER);
  };

  const handleFSEnableRedirect = () => {
    setClickChangeDelivererShowDialog(true);
    showChangeDeliverer();
  };

  const updatePackageInfoOnTheMachine = () => {
    sendEvent(ACTIONS.REMOVE_QUALITY_FLAGS);
  };

  const showUpdateStatus = () => {
    sendEvent(ACTIONS.UPDATE_STATUS);
  };

  const showChangeAddress = ({ withMapOpen }) => {
    sendEvent(ACTIONS.CHANGE_ADDRESS);
    if (withMapOpen) setDrawerFullScreen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    setDrawerFullScreen(false);
  };

  const handleGoBack = () => {
    sendEvent(ACTIONS.BACK);
    setDrawerFullScreen(false);
  };

  useEffect(() => {
    if (!isFlowLoading) {
      sendEvent(ACTIONS.RELOAD, { value: packageId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageId, sendEvent]);

  useEffect(() => {
    if (reloadPage) {
      state.setLoading(true);
      setOpen(false);
    }
  }, [reloadPage, setOpen, state]);

  useEffect(() => {
    if (closeDrawer) {
      setOpen(!closeDrawer);
    }
  }, [closeDrawer, setOpen]);

  useEffect(() => {
    if (showNotification) {
      showSnackbar({
        variant: error ? 'error' : 'success',
        message: notificationMessage,
        enqueueSnackbar
      });
      sendEvent(ACTIONS.CLOSED_SNACKBAR);
    }
  }, [
    enqueueSnackbar,
    error,
    notificationMessage,
    sendEvent,
    showNotification
  ]);

  return (
    <Drawer
      anchor="right"
      open
      data-testid="drawer"
      variant={drawerFullScreen ? 'temporary' : 'persistent'}
      className={`${drawerFullScreen ? classes.divDrawer : ''}`}
    >
      <Box component="div" overflow="auto" display="flex">
        <Box
          maxWidth={480}
          style={{ width: isMobile ? '100%' : 480 }}
          role="presentation"
          data-testid="backgound-drawer"
          component="div"
          overflow="auto"
        >
          <HeaderDrawer
            isEnableClose={isEnableClose}
            hideIcon={hideIcon}
            isLoading={isFlowLoading}
            isEnableGoBack={isEnableGoBack}
            onClose={handleCloseDrawer}
            onGoBack={handleGoBack}
          />
          {isError && <DrawerError reloadDrawer={reloadDrawer} />}
          {isFlowDetail && (
            <DrawerDetail
              packageInfo={packageInfo}
              packageChargeDisplayInfo={selectedPackage}
              packageHistory={showPackageHistory}
              showRefuse={showRefuse}
              reloadDrawer={reloadDrawer}
              showChangeDeliverer={
                fsRealtimeEnableDistribuirRedirectFase2
                  ? false
                  : () =>
                      fsRealtimeEnableDistribuirRedirectFase1
                        ? handleFSEnableRedirect()
                        : showChangeDeliverer()
              }
              showUpdateStatus={showUpdateStatus}
              showChangeAddress={showChangeAddress}
              updatePackageInfoOnTheMachine={updatePackageInfoOnTheMachine}
              areQualityFlagsRemoved={areQualityFlagsRemoved}
              disputeId={disputeId}
              proofOfDelivery={showProofOfDelivery}
              disputeProtocol={showDisputeProtocol}
            />
          )}
          {clickChangeDelivererShowDialog && (
            <DialogDistribuirRedirectContainer
              handleCancel={() => {
                setClickChangeDelivererShowDialog(false);
              }}
            />
          )}
          {isFlowPackageHistory && (
            <DrawerPackageHistory
              history={packageInfo.history}
              missedPromisedDateEvents={packageInfo.missedPromisedDateEvents}
              destinationChangeHistory={packageInfo.destinationChangeHistory}
              lastMilePackageChangeAddress={
                packageInfo.lastMilePackageChangeAddress
              }
            />
          )}
          {isFlowRefuse && (
            <DrawerRefuseComponent
              refuseMachineRef={current.context.refuseMachineRef}
              fsRealtimeDisableRefusePackageNaoVeio={
                fsRealtimeDisableRefusePackageNaoVeio
              }
            />
          )}
          {isFlowChangeDeliverer && (
            <DrawerChangeDeliverer
              changeDelivererMachineRef={
                current.context.changeDelivererMachineRef
              }
            />
          )}
          {isFlowUpdateStatus && (
            <DrawerUpdateStatusComponent
              updateStatusMachineRef={current.context.updateStatusMachineRef}
            />
          )}
          {isFlowChangeAddress && (
            <DrawerUpdateAddress
              updateAddressMachineRef={current.context.updateAddressMachineRef}
              setDrawerFullScreen={setDrawerFullScreen}
              drawerFullScreen={drawerFullScreen}
            />
          )}
          {isFlowProofOfDelivery && (
            <DrawerProofOfDelivery
              proofOfDelivery={packageInfo.proofOfDeliveryContent}
            />
          )}
          {isFlowDisputeProtocol && (
            <DrawerDisputeProtocolContainer disputeId={disputeId} />
          )}
        </Box>
        {drawerFullScreen && (
          <Box
            width="100%"
            height="100%"
            data-testid="component-google-map-react"
          >
            <GoogleMapComponent
              updateAddressMachineRef={current.context.updateAddressMachineRef}
            />
          </Box>
        )}
      </Box>
    </Drawer>
  );
}

HeaderDrawer.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
  isEnableClose: PropTypes.bool.isRequired,
  isEnableGoBack: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

PackageDrawer.propTypes = {
  setOpen: PropTypes.func.isRequired,
  selectedPackage: PropTypes.shape().isRequired
};
