import React, { useEffect, useState } from 'react';
import { Button, Box } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { Icon } from '@loggi/loggi-icons';
import { SWITCHES } from 'view/constants';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { generateLevePath } from 'infra/api/leve';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { URL_API_V2_LAST_MILE_PREFIX } from 'infra/service/constants';

const generateCsvLink = async ({ filterView, delivererCode }) => {
  let viewFil = {};

  if (Object.keys(filterView).length > 0) {
    viewFil = {
      ...filterView
    };
  }

  const viewParams = Object.keys(viewFil)
    .map(
      key => `${encodeURIComponent(key)}=${encodeURIComponent(viewFil[key])}`
    )
    .join('&');

  const timezone = moment().format('ZZ');
  const path = `${URL_API_V2_LAST_MILE_PREFIX}/packages/report?timezone=${timezone}${
    viewParams ? `&${viewParams}` : ''
  }&view=rua${delivererCode ? `&deliverer_code=${delivererCode}` : ''}`;

  return generateLevePath(path);
};

export default function DonwloadCsvContainer({ filterView, delivererCode }) {
  const [downloadCsvLink, setDownloadCsvLink] = useState('');

  const enableNewInStreetNewV2DownloadCsv = IsFSActiveForCurrentBase(
    SWITCHES.enableNewInStreetNewV2DownloadCsv
  );

  useEffect(() => {
    (async () => {
      const csvLink = await generateCsvLink({ filterView, delivererCode });
      setDownloadCsvLink(csvLink);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return enableNewInStreetNewV2DownloadCsv ? (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      target="_blank"
      href={downloadCsvLink}
    >
      <Box mr={1}>
        <Icon name="ArrowDownload" size="small" color={colors.blue[500]} />
      </Box>
      Baixar relatório
    </Button>
  ) : null;
}

DonwloadCsvContainer.propTypes = {
  filterView: PropTypes.shape(),
  delivererCode: PropTypes.number
};

DonwloadCsvContainer.defaultProps = {
  filterView: {},
  delivererCode: null
};
