import React, { useContext } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import { DIALOG } from '../notifications.constants';
import NotificationsContext from '../notifications.context';

export default function DialogNotificationsComponent() {
  const { showNotificationDialog, onAcceptDialog, onDenyDialog } = useContext(
    NotificationsContext
  );

  return (
    showNotificationDialog && (
      <Dialog
        open
        PaperProps={{ elevation: 11 }}
        fullWidth
        data-testid="notification-dialog"
      >
        <DialogContent>
          <Typography variant="h6">
            <strong>{DIALOG.TITLE}</strong>
          </Typography>
          <Box marginTop={1.6} marginBottom={1}>
            <Typography variant="body1">{DIALOG.CONTENT}</Typography>
          </Box>
        </DialogContent>
        <Box marginBottom={1}>
          <DialogActions>
            <Button
              color="primary"
              onClick={onDenyDialog}
              data-testid="deny-button"
            >
              {DIALOG.BUTTON.DENY}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={onAcceptDialog}
              data-testid="accept-button"
            >
              {DIALOG.BUTTON.ACCEPT}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    )
  );
}
