import React, { useState, useEffect } from 'react';

import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import db from 'infra/storage/async-elasticsearch-database';
import { useStateValue } from 'shared/contexts';
import PropTypes from 'prop-types';

import AsyncPackages from './async-packages.component';
import { useCustodyMap } from '../tracking.configuration';
import { KEY_CUSTODY } from '../tracking.constants';
import { fetchPackagesByBarcode } from '../tracking.service';

export function RefreshRoute() {
  const match = useRouteMatch();
  // This component exist purely to be tracked by Google Analytics
  const history = useHistory();
  // This timeout was added to remove from the URL the /refresh part, to detect multiple consecutives clicks on the update button
  setTimeout(() => {
    const urlSplit = match.url.split('/');
    history.push(`${urlSplit.splice(0, urlSplit.length - 1).join('/')}`);
  }, 300);

  return <></>;
}

function AsyncPackagesContainer({ custodyParam }) {
  const match = useRouteMatch();
  const history = useHistory();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [state] = useStateValue();

  const { sortingContextLicensePlate, licensePlate, custody } = useCustodyMap(
    custodyParam
  );

  const countPackagesFromCache = async () => {
    const cpLicensePlate = [...licensePlate.map(o => o.replace(/\*/g, ':'))];
    const cpSortingContextLicensePlate = [...sortingContextLicensePlate];

    setCount(
      await db.countPackages(cpLicensePlate, cpSortingContextLicensePlate)
    );
  };

  const onUpdate = async () => {
    setLoading(true);
    history.push(`${match.url}/refresh`);
    await fetchPackagesByBarcode();
    await countPackagesFromCache();
    setLoading(false);
    state.setLoading(true);
  };

  useEffect(() => {
    async function asyncCall() {
      await countPackagesFromCache();
    }
    asyncCall();
  });

  return (
    <>
      {/* In order to track how many times a leve user would press the update button, */}
      {/* it was decided to track it on Google Analytics using the url itself */}
      {/* The nested route below, guarantees that all pages that has this component will have a nested route with <current_url>/refresh */}
      <Route path={`${match.url}/refresh`} component={RefreshRoute} />
      {count > 0 && custody !== KEY_CUSTODY.RESULTADO && (
        <AsyncPackages count={count} loading={loading} onUpdate={onUpdate} />
      )}
    </>
  );
}

AsyncPackagesContainer.propTypes = {
  custodyParam: PropTypes.string
};

AsyncPackagesContainer.defaultProps = {
  custodyParam: ''
};

export default AsyncPackagesContainer;
