import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import useStyles from 'information/cards/cards.style';
import { listMapper } from 'offer/offer.constants';
import TRANSPORT_TYPE_MAPPER from './transport-type.constants';

function TransportTypeCell({ transportTypes, children }) {
  const mappedTransports = listMapper(transportTypes, TRANSPORT_TYPE_MAPPER);

  const classes = useStyles();

  const boxFormatter = _children => (
    <Box pr={0.5}>
      <Typography className={classes.smoke} noWrap variant="caption">
        {_children}
      </Typography>
    </Box>
  );

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {children && boxFormatter(children)}
      {mappedTransports.map((item, index) => {
        const totalText = item.total === 1 ? `${item.text}` : `${item.text}s`;
        const concatText = index + 1 === mappedTransports.length ? '' : 'e';
        return (
          item.text && (
            <React.Fragment key={item.text}>
              {boxFormatter(`${item.total} ${totalText} ${concatText}`)}
            </React.Fragment>
          )
        );
      })}
    </Box>
  );
}

TransportTypeCell.propTypes = {
  children: PropTypes.string,
  transportTypes: PropTypes.arrayOf(PropTypes.string)
};

TransportTypeCell.defaultProps = {
  transportTypes: [],
  children: ''
};

export default TransportTypeCell;
