import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Grid, Button } from '@material-ui/core';
import { ReactComponent as DrawerErrorIcon } from 'view/atoms/illustrations/drawer-error/drawer-error.svg';
import { TEXT } from '../drawer.constants';

function DrawerError({ reloadDrawer }) {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: 'calc(100vh - 38px)' }}
    >
      <Box width={310}>
        <Box align="center">
          <DrawerErrorIcon />
        </Box>
        <Box pt={3.5} color="text.disabled">
          <Typography align="center" variant="body2">
            {TEXT.LOADING_ERROR}
          </Typography>
        </Box>
        <Box pt={3.5} align="center">
          <Button
            data-testid="btn-retry"
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => reloadDrawer()}
          >
            {TEXT.RETRY}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}

DrawerError.propTypes = {
  reloadDrawer: PropTypes.func.isRequired
};

export default DrawerError;
