import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography, Box } from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';
import { ReactComponent as IconCloseLockWhite } from 'images/ic-cadeado-fechado-white.svg';

// todo: move EmptyList to shared.
import EmptyList from 'shared/empty-list';

import { TEXT } from './summary-values.constants';

const SummaryValuesHeader = () => (
  <>
    <Typography variant="h3">
      <strong>
        {TEXT.TITLE}
        {`  `}
      </strong>
      <Tooltip title={TEXT.TOOLTIP} placement="top" arrow data-testid="tooltip">
        <InfoIcon fontSize="small" />
      </Tooltip>
    </Typography>
    <Typography variant="h4">
      <strong>{TEXT.SUBTITLE}</strong>
    </Typography>
  </>
);

const InfoBlockLarge = ({ header, subHeader }) => (
  <>
    <Box pt={0.5}>
      <Typography variant="h6">
        <em>{header}</em>
      </Typography>
    </Box>
    <Box pt={1}>
      <Typography color="textSecondary" variant="body2">
        <em>{subHeader}</em>
      </Typography>
    </Box>
  </>
);

const InfoBlockMedium = ({ header, subHeader }) => (
  <>
    <Box>
      <Typography variant="subtitle1">
        <em>{header}</em>
      </Typography>
    </Box>
    <Box pt={1}>
      <Typography color="textSecondary" variant="body2">
        <em>{subHeader}</em>
      </Typography>
    </Box>
  </>
);

const ErrorSummary = () => (
  <EmptyList text={TEXT.ERROR}>
    <IconCloseLockWhite />
  </EmptyList>
);

InfoBlockLarge.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string
};
InfoBlockLarge.defaultProps = {
  subHeader: ''
};

InfoBlockMedium.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired
};

export { InfoBlockLarge, InfoBlockMedium, SummaryValuesHeader, ErrorSummary };
