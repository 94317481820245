import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

export default function RowBodyCollapsible({
  open,
  paramKey,
  children,
  columns
}) {
  const rowCss = open ? 'rowCollapsible' : 'rowCollapsible closed';
  return (
    <TableRow className={rowCss} data-testid={`${paramKey}-row-collapsible`}>
      <TableCell colSpan={Object.keys(columns).length}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </TableCell>
    </TableRow>
  );
}

RowBodyCollapsible.propTypes = {
  open: PropTypes.bool.isRequired,
  paramKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  columns: PropTypes.objectOf(
    PropTypes.shape({
      header: PropTypes.string,
      headAlign: PropTypes.string,
      propsStyleBody: PropTypes.shape,
      component: PropTypes.element
    })
  ).isRequired
};
