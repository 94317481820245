import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { formatNumber } from 'shared/formatters';
import { TEXT_CARD } from 'information/today/today.constants';
import { NOT_UPDATED_IN_LAST_HOUR } from './card-aggregation.constants';
import { useStyles } from './card-aggregation.styles';

import { KEY_CUSTODY } from '../tracking.constants';

const CardAggregation = ({
  values,
  children,
  isVariant,
  notUpdatedLastHourInfo,
  valuesLsd,
  isLoading
}) => {
  const classes = useStyles();
  const { custody } = useParams();

  const loadingSkeleton = () => {
    return (
      <Grid container item spacing={5} xs={10}>
        {custody === KEY_CUSTODY.NA_RUA && (
          <>
            <Grid item>
              <Skeleton variant="rect" height={48} width={250} />
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
          </>
        )}
        <Grid item>
          <Skeleton variant="rect" height={48} width={65} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={48} width={65} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={48} width={65} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={48} width={65} />
        </Grid>
        {custody === KEY_CUSTODY.FINALIZADOS && (
          <>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" height={48} width={250} />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return (
    <Grid container spacing={6} data-testid="card-aggregation-content">
      <Grid item>
        <Box
          className={`${classes.wrapper} ${
            isVariant ? classes.wrapperVariant : ''
          }`}
        >
          {children}
        </Box>
      </Grid>

      {!isLoading && (
        <>
          {custody === KEY_CUSTODY.NA_RUA && (
            <>
              <Grid item>
                <Typography>
                  <strong>{formatNumber(notUpdatedLastHourInfo)}</strong>
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  <em>{NOT_UPDATED_IN_LAST_HOUR}</em>
                </Typography>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
            </>
          )}
          {custody === KEY_CUSTODY.FINALIZADOS && (
            <Grid item key="total">
              <Typography>
                <strong>{formatNumber(values.total)}</strong>
              </Typography>
              <Typography color="textSecondary" variant="body2">
                <em>{TEXT_CARD.FINALIZADOS.CARD_TOTAL}</em>
              </Typography>
            </Grid>
          )}
          {values.data?.map(({ value, label, id }) => (
            <Grid item key={id}>
              <Typography>
                <strong>{formatNumber(value)}</strong>
              </Typography>
              <Typography color="textSecondary" variant="body2">
                <em>{TEXT_CARD.ENTREGUE.CARD_BODY[id] || label}</em>
              </Typography>
            </Grid>
          ))}
          {valuesLsd.data?.map(({ value, label, id }) => (
            <React.Fragment key={id}>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography>
                  <strong>{formatNumber(value)}</strong>
                </Typography>

                <Typography color="textSecondary" variant="body2">
                  <em>{TEXT_CARD.FINALIZADOS.CARD_BODY[label] || label}</em>
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </>
      )}

      {isLoading && loadingSkeleton()}
    </Grid>
  );
};

CardAggregation.propTypes = {
  children: PropTypes.node.isRequired,
  values: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        count: PropTypes.number,
        label: PropTypes.string,
        key: PropTypes.string
      })
    )
  }),
  isVariant: PropTypes.bool,
  notUpdatedLastHourInfo: PropTypes.number,
  valuesLsd: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        count: PropTypes.number,
        label: PropTypes.string,
        key: PropTypes.string
      })
    )
  }),
  isLoading: PropTypes.bool
};

CardAggregation.defaultProps = {
  values: {
    total: 0,
    data: []
  },
  isVariant: false,
  notUpdatedLastHourInfo: null,
  valuesLsd: {
    total: 0,
    data: []
  },
  isLoading: false
};

export default CardAggregation;
