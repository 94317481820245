import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import { ReactComponent as IconOpenLock } from 'images/ic-cadeado-aberto.svg';

import CardIcon from 'shared/card-icon';

import { formatValue, dateFormat } from 'finance/finance.configuration';

import { TEXT, BUTTON } from '../summary-values.constants';
import { InfoBlockLarge, InfoBlockMedium } from '../summary-values.component';

import {
  LABEL_TRANSHIPMENT,
  LABEL_PICKUP
} from '../../card-aggregation/card-aggregation.constants';

const SummaryValuesOpenPeriod = ({
  openStartDate,
  openPeriodInfo,
  redirectOpen
}) => {
  return (
    <CardIcon variant="primary" icon={IconOpenLock}>
      <Grid
        container
        justify="space-between"
        alignItems="flex-start"
        wrap="nowrap"
      >
        <Grid item container spacing={5}>
          <Grid item>
            <InfoBlockLarge
              header={TEXT.OPEN}
              subHeader={`${dateFormat(openStartDate)}${TEXT.UNTIL_YESTERDAY}`}
            />
          </Grid>
          <Grid item container spacing={10}>
            <Grid item>
              <InfoBlockMedium
                header={formatValue(openPeriodInfo.localTotalValue)}
                subHeader={TEXT.LOCAL_DELIVERY}
              />
            </Grid>
            <Grid item>
              <InfoBlockMedium
                header={formatValue(openPeriodInfo.atendidaTotalValue)}
                subHeader={TEXT.SERVED_DELIVERY}
              />
            </Grid>
            {(openPeriodInfo.pickupTotalValue > 0 ||
              openPeriodInfo.transhipmentTotalValue > 0) && (
              <Grid item xs={1}>
                <Divider orientation="vertical" />
              </Grid>
            )}
            {openPeriodInfo.pickupTotalValue > 0 && (
              <Grid item>
                <InfoBlockMedium
                  header={formatValue(openPeriodInfo.pickupTotalValue)}
                  subHeader={LABEL_PICKUP}
                />
              </Grid>
            )}
            {openPeriodInfo.transhipmentTotalValue > 0 && (
              <Grid item>
                <InfoBlockMedium
                  header={formatValue(openPeriodInfo.transhipmentTotalValue)}
                  subHeader={LABEL_TRANSHIPMENT}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            size="medium"
            endIcon={<NavigateNextRoundedIcon fontSize="small" />}
            onClick={() => redirectOpen()}
          >
            {BUTTON.OPEN}
          </Button>
        </Grid>
      </Grid>
    </CardIcon>
  );
};

const OpenSkeleton = () => {
  return (
    <CardIcon variant="primary" icon={IconOpenLock}>
      <Grid item container spacing={5} data-testid="open-skeleton">
        <Grid item>
          <Skeleton variant="rect" height={30} width={66} />
          <br />
          <Skeleton variant="rect" height={21} width={106} />
        </Grid>
        <Grid item container spacing={10}>
          <Grid item>
            <Skeleton variant="rect" height={27} width={83} />
            <br />
            <Skeleton variant="rect" height={21} width={68} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" height={27} width={83} />
            <br />
            <Skeleton variant="rect" height={21} width={68} />
          </Grid>
          <Grid item xs={1}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" height={27} width={83} />
            <br />
            <Skeleton variant="rect" height={21} width={68} />
          </Grid>
          <Grid item>
            <Skeleton variant="rect" height={27} width={83} />
            <br />
            <Skeleton variant="rect" height={21} width={68} />
          </Grid>
        </Grid>
      </Grid>
    </CardIcon>
  );
};

SummaryValuesOpenPeriod.propTypes = {
  openStartDate: PropTypes.string.isRequired,
  openPeriodInfo: PropTypes.shape({
    localTotalValue: PropTypes.number.isRequired,
    atendidaTotalValue: PropTypes.number.isRequired,
    deliveryTotalValue: PropTypes.number.isRequired,
    pickupTotalValue: PropTypes.number.isRequired,
    transhipmentTotalValue: PropTypes.number.isRequired
  }).isRequired,
  redirectOpen: PropTypes.func.isRequired
};

export { SummaryValuesOpenPeriod, OpenSkeleton };
