import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles({
  customAvatar: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    marginRight: '-4px',
    width: 24,
    height: 24,
    border: `2px solid white`,
    color: colors.blue[300],
    backgroundColor: colors.blue[50]
  },
  pointer: {
    cursor: 'pointer'
  }
});

export default useStyles;
