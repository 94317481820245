import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import showSnackbar from 'shared/snackbar';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import {
  PACKAGE_STATUS_CODE,
  PACKAGE_DIRECTION_RETURN
} from 'view/molecules/package-drawer/drawer.constants';
import { playError } from 'tracking/sound';
import CardPackageInfo from './card-package-info.component';
import { CONTENT_TEXT, TEXT } from '../drawer-group-packages.constants';
import { getPackageSimplified } from '../drawer-group-packages.service';

export default function CardPackageInfoContainer({
  packageId,
  newPackagesInfo,
  setNewPackagesInfo,
  loading,
  setLoading,
  currentPackageInfo
}) {
  const [packageInfo, setPackageInfo] = useState(null);
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const { enqueueSnackbar } = useSnackbar();

  const removePackageFromList = id => {
    setLoading(true);
    const newData = { ...newPackagesInfo };
    delete newData[id];
    setNewPackagesInfo(newData);
    setLoading(false);
  };

  const getPackageInfo = useCallback(async () => {
    if (currentPackageInfo) {
      setPackageInfo(currentPackageInfo);
      return;
    }

    await getPackageSimplified(packageId, authenticatedUser?.email)
      .then(response => {
        if (response.status.code === PACKAGE_STATUS_CODE.AWAITING_RESOLUTION) {
          showSnackbar({
            message: TEXT.AWAITING_RESOLUTION_ALERT,
            variant: 'warning',
            enqueueSnackbar
          });
        }
        if (response.status.code === PACKAGE_STATUS_CODE.DELIVERED) {
          showSnackbar({
            message: TEXT.DELIVERED_ALERT(response.integrationInfo.barcode),
            variant: 'warning',
            enqueueSnackbar
          });

          playError();
          removePackageFromList(packageId);
          return;
        }
        const updatedPackagesInfo = { ...newPackagesInfo };

        updatedPackagesInfo[packageId] = {
          ...updatedPackagesInfo[packageId],
          ...response
        };

        setNewPackagesInfo(updatedPackagesInfo);
        setPackageInfo(response);
      })
      .catch(error => {
        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageId]);

  const handleGetPackageInfo = async () => {
    setLoading(true);
    await getPackageInfo();
    setLoading(false);
  };

  useEffect(() => {
    (async function onPackageChargeUpdate() {
      await handleGetPackageInfo();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageId]);

  const isAwaitingResolution =
    packageInfo?.status.code === PACKAGE_STATUS_CODE.AWAITING_RESOLUTION;

  const isReturnDirection =
    packageInfo?.direction &&
    packageInfo.direction === PACKAGE_DIRECTION_RETURN;

  const chooseTextPromisedDate = () => {
    return isAwaitingResolution
      ? CONTENT_TEXT.wait_text
      : CONTENT_TEXT.delivery_text;
  };

  // TODO: Condense this logic in a shareable function, since in drawer-detail.component.js
  // we need the same logic.
  const choosePromisedDate = () => {
    if (isAwaitingResolution) {
      return packageInfo.awaitingResolutionPromisedDate.promisedDate
        ?.promisedDate;
    }
    // Considering that the "Para transferir" section will only be released for LEVES.
    return packageInfo.lastMilePromisedDate?.promisedDate?.promisedDate;
  };

  const chooseAddressInfo = () => {
    if (isReturnDirection) {
      const { postalAddress } = packageInfo.loggiAddress || {};
      return postalAddress.locality;
    }
    return packageInfo.destination.city;
  };

  return (
    <Box>
      {packageInfo && !loading && (
        <CardPackageInfo
          packageInfo={packageInfo}
          addressInfo={chooseAddressInfo}
          promisedDate={choosePromisedDate}
          promisedDateText={chooseTextPromisedDate}
          removePackageFromList={removePackageFromList}
          hideTrashButton={currentPackageInfo !== null}
        />
      )}
    </Box>
  );
}

CardPackageInfoContainer.propTypes = {
  packageId: PropTypes.string.isRequired,
  newPackagesInfo: PropTypes.objectOf(
    PropTypes.shape({
      packageId: PropTypes.string,
      barcode: PropTypes.string
    })
  ).isRequired,
  setNewPackagesInfo: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  currentPackageInfo: PropTypes.shape()
};

CardPackageInfoContainer.defaultProps = {
  currentPackageInfo: null
};
