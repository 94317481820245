import React from 'react';
import PropTypes from 'prop-types';
import ProfileActivityType from './drivers-profile-activity-type.component';
import { TEXTS } from './drivers-profile.constants';

const DriversProfileDeliveryComponent = ({ profile, onClick }) => {
  const handleClick = () => onClick(profile);

  const subtitle = (
    <>
      {TEXTS.DELIVERY_SUBTITLE[0]}
      <em>{TEXTS.DELIVERY_SUBTITLE[1]}</em>
      {TEXTS.DELIVERY_SUBTITLE[2]}
    </>
  );

  return (
    <ProfileActivityType
      title={TEXTS.DELIVERY_TITLE}
      subtitle={subtitle}
      onClick={handleClick}
      buttonText={TEXTS.DELIVERY_BUTTON}
    />
  );
};

DriversProfileDeliveryComponent.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired
};

export default DriversProfileDeliveryComponent;
