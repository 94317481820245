import React, { useContext, useEffect, useState } from 'react';
import { SummaryContext } from 'tracking/template/summary.context';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Pagination, { usePagination } from 'shared/pagination';
import ConfigurableTable from 'shared/configurable-table';
import { Skeleton } from '@material-ui/lab';
import { AUTHORIZED_ROUTES } from 'view/constants';
import ConfigurableTableError from 'shared/configurable-table/configurable-table-error.component';

import { fetchDeliverers } from './service';
import {
  VIEW_CONFIG,
  TITLE_VIEW,
  DELIVERERS_HEADER,
  DELIVERERS,
  PACOTES_ATRASADOS,
  PACOTES_INSUCESSOS,
  PACOTES_PARA_HOJE,
  CONFIGURABLE_TABLE_CONFIGURATION_NA_RUA_DELIVERERS
} from './configuration';
import FilterElements from './filters/deliverers-filter-elements.container';
import DownloadCsvContainer from './download';

export default function InStreetDeliverersListContainer() {
  const { setSummary } = useContext(SummaryContext);
  const [loading, setLoading] = useState(true);
  const [totalPkgs, setTotalDeliverers] = useState(0);
  const [errorFetch, setErrorFetch] = useState(false);
  const [viewConfig, setViewConfig] = useState(VIEW_CONFIG[DELIVERERS]);
  const [deliverers, setDeliverers] = useState([]);

  const [filters, setFilters] = useState({});
  const [dataForFilter, setDataForFilter] = useState([]);
  const [checkedItemsBase, setCheckedItemsBase] = useState({});

  const [subView, setSubView] = useState('');

  const history = useHistory();
  const location = useLocation();

  const handleNextPage = row => {
    const rc = getSelectedRoutingCode();
    const _subView = subView ? `?subView=${subView}` : '';
    const path = `/${rc}/na-rua/entregador/${row.delivererCode ||
      1}${_subView}`;
    history.push(path);
  };

  const [
    pagination,
    changePage,
    setNumberOfItems,
    setCurrentPage
  ] = usePagination();

  const changePagination = page => {
    changePage(page);
    setLoading(true);
  };

  useEffect(() => {
    if (location.pathname) {
      setViewConfig(
        VIEW_CONFIG[location.pathname.split('na-rua/colecoes/')[1]]
      );
    }
  }, [location]);

  const [filterView, setFilterView] = useState(null);
  useEffect(() => {
    if (location.pathname.includes(`na-rua/colecoes/${PACOTES_ATRASADOS}`)) {
      setFilterView({ delayedPackages: true });
      setSubView('atrasados');
    } else if (
      location.pathname.includes(`na-rua/colecoes/${PACOTES_INSUCESSOS}`)
    ) {
      setFilterView({ unsuccessfulPackages: true });
      setSubView('insucessos');
    } else if (
      location.pathname.includes(`na-rua/colecoes/${PACOTES_PARA_HOJE}`)
    ) {
      setFilterView({ forTodayPackages: true });
      setSubView('para-hoje');
    } else {
      setFilterView({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchDeliverers({
        page: pagination.currentPage,
        filterView,
        filters
      })
        .then(response => {
          setDeliverers(response?.delivererList);
          setTotalDeliverers(response?.totalDeliverers);
          setNumberOfItems(response?.totalDeliverers);
          setDataForFilter(response?.filterOptions);
          setLoading(false);
        })
        .catch(() => {
          setErrorFetch(true);
          setLoading(false);
        });
    };

    if (filterView) {
      setLoading(true);
      setErrorFetch(false);
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, filters, filterView]);

  useEffect(() => {
    setSummary(
      <Grid
        container
        direction="row"
        alignItems="center"
        display="flex"
        justify="space-between"
      >
        <Grid item xs>
          <Box display="flex" alignItems="center">
            {loading ? (
              <Skeleton height={60} width="40%" animation="wave" />
            ) : (
              <>
                <Box display="flex" alignItems="center" mr={1}>
                  <IconButton
                    size="small"
                    edge="start"
                    onClick={() => {
                      const rc = getSelectedRoutingCode();
                      const path = `/${rc}/na-rua${
                        AUTHORIZED_ROUTES.INFORMATION.COLLECTION
                      }`;
                      history.push(path);
                    }}
                  >
                    <Box
                      color="primary.contrastText"
                      display="flex"
                      alignItems="center"
                    >
                      <ArrowBackRoundedIcon data-testid="back-base-button" />
                    </Box>
                  </IconButton>
                </Box>
                <Typography variant="h4">{TITLE_VIEW}</Typography>
                <Box ml={1}>
                  <Typography variant="h4">
                    <strong>{`/ ${DELIVERERS_HEADER}`}</strong>
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          {loading ? (
            <Skeleton height={60} width="20%" animation="wave" />
          ) : (
            !errorFetch && (
              <Box mt={2}>
                <Typography variant="h4">
                  {totalPkgs} {` ${viewConfig.subView}`}
                </Typography>
              </Box>
            )
          )}
        </Grid>
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewConfig, totalPkgs, loading, errorFetch]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Paper
      px={1}
      component={Box}
      elevation={10}
      flexGrow={1}
      mb={2}
      minHeight={44}
    >
      <Box
        px={3}
        pt={4}
        data-testid="in-street-collection"
        display="flex"
        justifyContent="space-between"
      >
        {loading ? (
          <Skeleton variant="rect" height={34} width={140} animation="wave" />
        ) : (
          <FilterElements
            loading={loading}
            setFilters={setFilters}
            dataForFilter={dataForFilter}
            checkedItemsBase={checkedItemsBase}
            setCheckedItemsBase={setCheckedItemsBase}
          />
        )}
        {loading ? (
          <Skeleton variant="rect" height={34} width={100} animation="wave" />
        ) : (
          <DownloadCsvContainer filterView={filterView} />
        )}
      </Box>

      <Box px={isSmallScreen ? 0 : 3} py={3}>
        {loading ? (
          <Skeleton variant="rect" height={240} animation="wave" />
        ) : (
          !errorFetch &&
          deliverers &&
          deliverers.length > 0 &&
          totalPkgs > 0 && (
            <ConfigurableTable
              data={deliverers}
              columns={CONFIGURABLE_TABLE_CONFIGURATION_NA_RUA_DELIVERERS}
              onRowSelected={handleNextPage}
            />
          )
        )}
      </Box>

      {errorFetch && <ConfigurableTableError />}

      <Box pb={1} mr={3}>
        {loading ? (
          <Skeleton variant="rect" height={8} animation="wave" />
        ) : (
          !errorFetch && (
            <Pagination
              onPageChange={changePagination}
              initialPage={pagination.currentPage}
              numberOfPages={pagination.numberOfPages}
              setCurrentPage={setCurrentPage}
            />
          )
        )}
      </Box>
    </Paper>
  );
}
