import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSnackbar } from 'notistack';
import showSnackbar from 'shared/snackbar';
import { userService } from 'users-management/operators/operators.service';
import getAggregationsCities from 'app/get-cities-attend';

import CirclesComponent from './circles.component';
import { getAllDriversService, getCirclesInformation } from './circles.service';
import CirclesDrawerContainer from './drawer/circles-drawer.container';

export default function CirclesContainer({ shouldOpenDrawer }) {
  const [state, setState] = useState();
  const [circles, setCircles] = useState([]);
  const [circleToEdit, setCircleToEdit] = useState(undefined);
  const [drivers, setDrivers] = useState([]);
  const [operators, setOperators] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingCircles, setLoadingCircles] = useState(true);
  const [cities, setCities] = useState([]);

  const DRAWER = 'drawer';
  const { enqueueSnackbar } = useSnackbar();

  const fetchCircles = useCallback(async () => {
    if (loadingUsers) return;
    setLoadingCircles(true);
    const fetchDrivers = driverIds => {
      return drivers
        .filter(driver => driverIds.indexOf(Number(driver.deliverer.id)) >= 0)
        .map(filteredDriver => {
          return {
            id: Number(filteredDriver.deliverer.id),
            name: filteredDriver.fullName,
            avatarSource:
              filteredDriver.deliverer.profilePictureThumbnailUrl || ''
          };
        });
    };

    const fetchOperators = operatorsIds => {
      return operators
        .filter(
          operator =>
            operatorsIds.indexOf(operator.lastMileOperator.cognitoSub) >= 0
        )
        .map(filteredOperator => {
          return {
            id: filteredOperator.lastMileOperator.cognitoSub,
            name: filteredOperator.fullName,
            avatarSource: ''
          };
        });
    };

    await getAggregationsCities().then(response => {
      setCities(
        response.map(o => {
          return {
            value: o.id,
            label: o.label
          };
        })
      );
    });

    getCirclesInformation()
      .then(result => {
        if (!result.circles) return;
        setCircles(
          result.circles.map(circle => {
            return {
              ...circle,
              driversData: fetchDrivers(circle.drivers),
              operatorsData: fetchOperators(circle.operators)
            };
          })
        );
      })
      .catch(error => {
        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      })
      .finally(() => setLoadingCircles(false));
  }, [drivers, enqueueSnackbar, loadingUsers, operators]);

  const onOpenCircleCreation = () => {
    setState(DRAWER);
  };

  const onCloseDrawerCallback = () => {
    setCircleToEdit(undefined);
    setState('');
  };

  const onSubmitDrawerCallback = () => {
    fetchCircles();
  };

  const onEditCircleCallback = circle => {
    setCircleToEdit(circle);
    setState(DRAWER);
  };

  useEffect(() => {
    setLoadingUsers(true);
    const fetchUserData = [getAllDriversService(), userService()];
    Promise.all(fetchUserData)
      .then(result => {
        setDrivers(result[0] || []);
        setOperators(result[1].users || []);
      })
      .catch(error => {
        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      })
      .finally(() => setLoadingUsers(false));
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchCircles();
  }, [fetchCircles]);

  useEffect(() => {
    if (shouldOpenDrawer) onOpenCircleCreation();
  }, [shouldOpenDrawer]);

  return (
    <>
      <CirclesComponent
        circles={circles}
        cities={cities}
        loading={loadingCircles}
        onOpenCircleCreation={onOpenCircleCreation}
        onEditCircleCallback={onEditCircleCallback}
      />
      {state === DRAWER && (
        <CirclesDrawerContainer
          open={state === DRAWER}
          circle={circleToEdit}
          cities={cities}
          handleClosingDrawer={onCloseDrawerCallback}
          handleSubmitDrawer={onSubmitDrawerCallback}
          drivers={drivers}
          operators={operators}
        />
      )}
    </>
  );
}

CirclesContainer.propTypes = {
  shouldOpenDrawer: PropTypes.bool
};

CirclesContainer.defaultProps = {
  shouldOpenDrawer: false
};
