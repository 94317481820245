import api from 'infra/api/leve';
import { URL_API_V1_LAST_MILE_PREFIX } from 'infra/service/constants';
import { getSelectedBase } from 'profile/profile.service';

/**
 Service to request a list of available drivers
 * @param {String} lmc_identification
   Only drivers related to this lmc will be retrieved.
 * @param {String} relation_restriction
   Only drivers related to relation restriction will be retrieved.
   CONTRACTOR = 1, 'Contractor'
   COLLABORATOR = 2, 'Collaborator'
   STAFF = 3, 'Staff'
 * @param {Boolean} only_drivers_without_route
   Only drivers without current route will be retrieved.
 * @param {Boolean} drivers_with_additional_social_information
   If true the drivers will be retrieved with additional social information.
   
 * @returns {Object} List with available drivers
*/
export const getDriversList = async () => {
  const LMCDC = getSelectedBase();
  const lmcIdentification = LMCDC.last_mile_company.identification;
  const COLLABORATOR = 2;
  const STAFF = 3;
  const relationRestriction =
    LMCDC.last_mile_company.company_type === 'LAST_MILE_COMPANY_TYPE_LEVE' ||
    LMCDC.last_mile_company.company_type === 'LAST_MILE_COMPANY_TYPE_CARRIER'
      ? COLLABORATOR
      : STAFF;

  return api.post(`${URL_API_V1_LAST_MILE_PREFIX}/offers/drivers/list`, {
    lmc_identification: lmcIdentification,
    relation_restriction: relationRestriction,
    only_drivers_without_route: false,
    drivers_with_additional_social_information: true
  });
};

/**
 Service to assign a driver to a offer
 * @param {Object} driver
   Driver information that will be assigned for the pickup
 * @param {Int64} pickupId
   Pickup Order ID

 * @returns {String} Response success message
*/
export const assignDriverPickup = async (driver, pickupId) => {
  return api.put(`${URL_API_V1_LAST_MILE_PREFIX}/offers/drivers/assign`, {
    pickupId: parseInt(pickupId, 10),
    driver
  });
};
