import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Typography } from '@material-ui/core';
import { CustomDrawer, DrawerHeader, DrawerContent } from 'shared/drawer';
import { colors } from '@loggi/mar';
import { BUTTON, TEXT } from './drawer-packages-out-of-coverage.constants';

const DrawerPackagesOutOfCoverage = ({ open, onClose, packagesInfo }) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <CustomDrawer open={open}>
      <DrawerHeader
        titleVariant="h4"
        title={
          packagesInfo?.length === 1
            ? TEXT.PACKAGE_IS_OUT_OF_COVERAGE.title
            : TEXT.PACKAGES_ARE_OUT_OF_COVERAGE.title
        }
        detail={
          packagesInfo?.length === 1
            ? TEXT.PACKAGE_IS_OUT_OF_COVERAGE.subtitle
            : TEXT.PACKAGES_ARE_OUT_OF_COVERAGE.subtitle
        }
        colorText="textPrimary"
        showDivider
        handleClosingDrawer={handleClose}
      />
      {packagesInfo?.map(packageInfo => (
        <DrawerContent showDivider key={packageInfo.package_id}>
          <Box mb={1} mt={2}>
            <Typography>
              <Box fontWeight="fontWeightMedium">{packageInfo.barcode}</Box>
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography style={{ color: colors.smoke[700] }} variant="body2">
              {packageInfo.company_name}
            </Typography>
            <Typography style={{ color: colors.smoke[700] }} variant="body2">
              {packageInfo.recipient_name}
            </Typography>
          </Box>
        </DrawerContent>
      ))}
      <DrawerContent>
        <Box mt={2} mb={2}>
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            fullWidth
            onClick={handleClose}
          >
            {BUTTON.CONFIRM_PACKAGES_OUT_OF_COVERAGE}
          </Button>
        </Box>
      </DrawerContent>
    </CustomDrawer>
  );
};

DrawerPackagesOutOfCoverage.defaultProps = {};

DrawerPackagesOutOfCoverage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  packagesInfo: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired
};

export default DrawerPackagesOutOfCoverage;
