import api from 'infra/api/leve';
import { URL_PROXY_V1_PREFIX } from 'infra/service/constants';

// eslint-disable-next-line import/prefer-default-export
export function fetchTripsCheck(licensePlate) {
  return api
    .get(`${URL_PROXY_V1_PREFIX}/trips/check?license_plate=${licensePlate}`)
    .then(_data => {
      return _data.data;
    });
}

export function fetchTripsCancelAllocated(licensePlate) {
  return api
    .post(
      `${URL_PROXY_V1_PREFIX}/trips/cancel-allocated-packages?license_plate=${licensePlate}`
    )
    .then(_data => {
      return _data.data;
    });
}
