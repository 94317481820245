import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { Can } from 'auth/access-control';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import { SWITCHES } from 'view/constants';
import { SUMMARY } from 'tracking/tracking.constants';
import ToggleButton from 'tracking/toggle-button/toggle-button.component';
import { TITLE_TOGGLE } from '../base-list/base-list.constants';

const DRAWERS = {
  GROUPING: 'GROUPING'
};

export default function BaseHeaderButtons({
  downloadCsvLink,
  setCurrentDrawer,
  showToggleButton,
  isCollapsitable,
  setIsCollapsitable,
  setLoading
}) {
  return (
    <Grid container direction="row" spacing={2}>
      <Can
        actions={[ACTIONS.CIRCLES_WRITE]}
        includeFS={SWITCHES.enableBaseGroupingButton}
      >
        <Grid item>
          <Button
            color="inherit"
            size="medium"
            target="_blank"
            onClick={() => setCurrentDrawer(DRAWERS.GROUPING)}
            data-testid="grouping-button-others"
          >
            {SUMMARY.GROUPING}
          </Button>
        </Grid>
      </Can>
      <Can
        actions={[ACTIONS.CIRCLES_WRITE_FOR_OPERATOR]}
        includeFS={SWITCHES.enableBaseGroupingButtonForOperator}
      >
        <Grid item>
          <Button
            color="inherit"
            size="medium"
            target="_blank"
            onClick={() => setCurrentDrawer(DRAWERS.GROUPING)}
            data-testid="grouping-button-operator"
          >
            {SUMMARY.GROUPING}
          </Button>
        </Grid>
      </Can>
      <Can excludeFS="disableButtonDownloadReportForDcs">
        <Grid item>
          <Button
            variant="outlined"
            color="default"
            size="medium"
            target="_blank"
            href={downloadCsvLink}
          >
            {SUMMARY.DOWNLOAD_REPORT}
          </Button>
        </Grid>
      </Can>
      {showToggleButton && (
        <Can actions={[ACTIONS.TOGGLE_NEW_BASE]}>
          <Grid item data-testid="new-base-toogle">
            <ToggleButton
              handleAlignment={() => {
                setIsCollapsitable(!isCollapsitable);
                setLoading(true);
              }}
              withLargeUnitload={isCollapsitable}
              titleGroup={TITLE_TOGGLE.TITLE_GROUPS}
              titlePackages={TITLE_TOGGLE.TITLE_PACKAGES}
              valueGroup={TITLE_TOGGLE.VALUE_GROUPS}
              valuePackages={TITLE_TOGGLE.VALUE_PACKAGES}
              blueBackground
            />
          </Grid>
        </Can>
      )}
    </Grid>
  );
}

BaseHeaderButtons.propTypes = {
  downloadCsvLink: PropTypes.string.isRequired,
  setCurrentDrawer: PropTypes.func.isRequired,
  showToggleButton: PropTypes.bool,
  isCollapsitable: PropTypes.bool,
  setIsCollapsitable: PropTypes.func,
  setLoading: PropTypes.func
};

BaseHeaderButtons.defaultProps = {
  showToggleButton: false,
  isCollapsitable: false,
  setIsCollapsitable: () => {},
  setLoading: () => {}
};
