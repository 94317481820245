import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(({ spacing }) => ({
  grid: {
    minHeight: '100vh'
  },
  root: {
    backgroundImage: colors.gradients.sanches,
    color: 'white',
    position: 'relative'
  },
  alignLogo: {
    position: 'absolute',
    height: '200px'
  },
  textfield: {
    width: '100%'
  },
  button: {
    width: '100%'
  },
  boxSpan: {
    borderTop: '1px solid',
    borderColor: colors.smoke[100],
    paddingTop: spacing(5)
  },
  span: {
    color: colors.smoke[700]
  },
  link: {
    paddingLeft: spacing(1),
    color: colors.blue[500],
    cursor: 'pointer'
  },
  errors: {
    color: colors.red[500],
    marginTop: spacing(1),
    marginLeft: spacing(1.5)
  }
}));

export default useStyles;
