import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, Paper } from '@material-ui/core';
import { ReactComponent as IconRua } from 'images/ic-rua-sm.svg';
import { ReactComponent as IconEntregue } from 'images/ic-entregue-sm.svg';

import AlertStatusHeader from 'shared/alert-status-header';
import {
  DATE_FILTER,
  TEXT_ALERT_STATUS_HEADER
} from 'tracking/tracking.constants';
import CardAggregation from './card-aggregation.component';
import CardAggregationError from './card-aggregation.error.component';
import { fetchPackageAggregatedData } from './card-aggregation.service';

import { KEY_CUSTODY, AGG_FIELDS, VIEWS } from '../tracking.constants';
import CUSTODY_MAP, { getLayout } from '../tracking.configuration';

import { useStyles } from '../tracking.styles';

function CardAggregationContainer({ filters, externalLoading, date }) {
  const [loading, setLoading] = useState(true);
  const [notUpdatedLastHour, setNotUpdatedLastHour] = useState(null);
  const [data, setData] = useState({});
  const [dataLsd, setDataLsd] = useState({});
  const [error, setError] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isLsdVisible, setIsLsdVisible] = useState(false);

  const [startDate, endDate] = date;

  const { custody } = useParams();
  const renderState = getLayout(custody);
  const classes = useStyles();

  let textAlertStatusHeader;
  if (renderState === KEY_CUSTODY.FINALIZADOS)
    textAlertStatusHeader = TEXT_ALERT_STATUS_HEADER.AGGREGATION_FINISHED;
  else if (renderState === KEY_CUSTODY.NA_RUA)
    textAlertStatusHeader = TEXT_ALERT_STATUS_HEADER.AGGREGATION_IN_STREET;

  const onReload = () => {
    setLoading(true);
  };

  useEffect(() => {
    if (externalLoading) {
      setLoading(true);
    }
  }, [externalLoading]);

  useEffect(() => {
    if (custody === KEY_CUSTODY.NA_BASE || custody === KEY_CUSTODY.RESULTADO) {
      setLoading(false);
    } else if (loading) {
      setIsVisible(true);
      fetchPackageAggregatedData({
        filters,
        aggregatedField:
          custody === KEY_CUSTODY.NA_RUA
            ? AGG_FIELDS.STATUS_CODE_IN_DELIVERY
            : AGG_FIELDS.PERIOD,
        startDate,
        endDate,
        view: custody === KEY_CUSTODY.NA_RUA ? VIEWS.NA_RUA : VIEWS.ENTREGUES,
        dateFilter: CUSTODY_MAP[custody].dateFilter
      })
        .then(cleanedData => {
          setIsVisible(
            custody === KEY_CUSTODY.NA_RUA ||
              (cleanedData && cleanedData.total > 0)
          );
          setLoading(false);
          setData(cleanedData);
          setError(false);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }, [loading, custody, filters, startDate, endDate]);

  useEffect(() => {
    if (custody === KEY_CUSTODY.FINALIZADOS && loading) {
      setIsLsdVisible(true);
      fetchPackageAggregatedData({
        filters,
        aggregatedField: AGG_FIELDS.PERIOD_LSD,
        startDate,
        endDate,
        view: VIEWS.FINALIZADOS,
        dateFilter: CUSTODY_MAP[custody].dateFilter
      })
        .then(cleanedData => {
          setIsLsdVisible(cleanedData && cleanedData.total > 0);
          setLoading(false);
          setDataLsd(cleanedData);
          setError(false);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }, [loading, custody, filters, startDate, endDate]);

  useEffect(() => {
    if (custody === KEY_CUSTODY.NA_RUA && loading) {
      fetchPackageAggregatedData({
        filters,
        aggregatedField: AGG_FIELDS.STATUS_CODE,
        startDate: moment()
          .subtract(60, 'days')
          .format(),
        endDate: moment()
          .subtract(1, 'hour')
          .format(),
        dateFilter: DATE_FILTER.SORTING_RECORD_CREATED,
        view: VIEWS.NA_RUA
      })
        .then(dataLastHour => setNotUpdatedLastHour(dataLastHour.total))
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }, [custody, filters, loading]);

  return (
    (isVisible || isLsdVisible) &&
    custody !== KEY_CUSTODY.NA_BASE &&
    custody !== KEY_CUSTODY.RESULTADO && (
      <Paper
        className={classes.paper}
        component={Box}
        elevation={10}
        flexGrow={1}
        marginBottom={5}
      >
        {textAlertStatusHeader && (
          <AlertStatusHeader
            textPrimary={textAlertStatusHeader.PRIMARY}
            textSecondary={textAlertStatusHeader.SECONDARY}
          />
        )}
        <Box minHeight={44} p={5.5}>
          {!error && (
            <CardAggregation
              values={data}
              isVariant={renderState === KEY_CUSTODY.FINALIZADOS}
              notUpdatedLastHourInfo={notUpdatedLastHour}
              valuesLsd={dataLsd}
              isLoading={loading || externalLoading}
            >
              {renderState === KEY_CUSTODY.FINALIZADOS && <IconEntregue />}
              {renderState !== KEY_CUSTODY.FINALIZADOS && <IconRua />}
            </CardAggregation>
          )}
          {error && <CardAggregationError onReload={onReload} />}
        </Box>
      </Paper>
    )
  );
}

CardAggregationContainer.propTypes = {
  filters: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string),
    deliverers: PropTypes.arrayOf(PropTypes.string)
  }),
  externalLoading: PropTypes.bool,
  date: PropTypes.arrayOf(PropTypes.string)
};

CardAggregationContainer.defaultProps = {
  filters: null,
  externalLoading: false,
  date: [null, null]
};

export default CardAggregationContainer;
