import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useAmplifyAuth } from '@loggi/authentication-lib';

import { SIGN_IN_ERRORS } from '@loggi/authentication/src/screens/signin/constants';
import showSnackbar from 'shared/snackbar';
import { PUBLIC_ROUTES } from 'view/constants';

import { CredentialsContext } from './credentials.context';
import Login from './login.component';
import {
  WAS_LOGGED_OUT,
  TEXT,
  NOT_AUTHORIZED_SHOULD_SIGN_IN_WITH_GOOGLE_ACCOUNT
} from './login.constants';

const USER_NOT_AUTHENTICATED_ERROR = 'No current user';

function LoginContainer() {
  const { setCredentials, setLoading } = useContext(CredentialsContext);

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const {
    federatedSignIn,
    signIn,
    state: { error: stateError }
  } = useAmplifyAuth();

  const { NotAuthorizedException } = SIGN_IN_ERRORS;

  if (localStorage.getItem(WAS_LOGGED_OUT.KEY)) {
    localStorage.removeItem(WAS_LOGGED_OUT.KEY);
    showSnackbar({
      message: WAS_LOGGED_OUT.MESSAGE,
      variant: 'error',
      enqueueSnackbar
    });
  }

  const onErrorLogin = useCallback(
    ({ message }) => {
      // 4- If neither cognito or micronaut signIn worked, show message error
      showSnackbar({ message, variant: 'error', enqueueSnackbar });
      setLoading(false);
    },
    [enqueueSnackbar, setLoading]
  );

  const forgotPassword = () => {
    history.push(PUBLIC_ROUTES.FORGOT_PASSWORD_ROUTE);
  };

  useEffect(() => {
    // Suggestion login with socialLogin Google
    if (
      stateError?.message?.code === NotAuthorizedException.code &&
      stateError?.message?.message ===
        NOT_AUTHORIZED_SHOULD_SIGN_IN_WITH_GOOGLE_ACCOUNT
    ) {
      setLoading(false);
      showSnackbar({
        message: TEXT.SUGGESTION_LOGIN_WITH_GOOGLE,
        variant: 'error',
        enqueueSnackbar
      });
    }
    // 2- useEffect handler for error on signIn with cognito
    else if (stateError && stateError !== USER_NOT_AUTHENTICATED_ERROR) {
      // if error should login with micronaut
      onErrorLogin({
        message: 'Você digitou o e-mail ou a senha errada. Tente de novo.'
      });
    }
  }, [
    stateError,
    onErrorLogin,
    NotAuthorizedException.code,
    NotAuthorizedException,
    enqueueSnackbar,
    setLoading
  ]);

  const handleLogin = (username, password) => {
    // 1- First we try signIn with cognito
    setLoading(true);
    setCredentials({ username, password });

    // We dont want to trigger the migration flow for Arco users,
    // since our authorization isnt based on CorporateUser.
    // To bypass the migration, we need to pass extra validationData to the signIn method
    // in this case { BypassMigration: 'True' }
    // see:
    // https://github.com/loggi/py/blob/master/apps/auth/src/lambdas/UserMigration/main.py
    // @aws-amplify/auth/src/Auth.ts::signIn
    signIn({
      username,
      password,
      validationData: { BypassMigration: 'True' }
    });
  };

  return (
    <Login
      handleLogin={handleLogin}
      federatedSignIn={federatedSignIn}
      forgotPassword={forgotPassword}
    />
  );
}

export default LoginContainer;
