import updateDelivererService from 'infra/service/update-deliverer';
import { translateStatusCode, DEFAULT_MESSAGE } from 'app/httpMessage';

export const CUSTOM_ERROR_MESSAGES = {
  500: 'Eita, algo deu errado. Tente de novo daqui a pouco ou atualize a página. [004-Error-500]',
  404: 'Eita, não encontramos ninguém. Tente de novo.'
};

/**
 * Service to request the createDeliverer
 * @param {Object} updateDeliverer - The new deliverer data to be associate
 * @param {String} updateDeliverer.full_name
 * @param {String} updateDeliverer.mobile_number
 * @param {String} updateDeliverer.status
 * @param {String} updateDeliverer.user_id
 *
 * @return {Object} lastMileDeliverer - Object last mile deliverer
 * {
 *   `data` is the response that was provided by the server
 *    data: {
 *     full_name: String,
 *     mobile_number: String,
 *     user_id: String
 *    }
 *
 *   `status` is the HTTP status code from the server response
 *    status: 200,
 *
 *   `request` is the request that generated this response
 *   request: {
 *     full_name: String,
 *     mobile_number: String,
 *     status: String,
 *     user_id: String
 *    }
 * }
 */

export default async function updateDeliverer(editDeliverer) {
  return updateDelivererService(editDeliverer)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error.response
        ? translateStatusCode(error.response.status, CUSTOM_ERROR_MESSAGES)
        : DEFAULT_MESSAGE;
    });
}
