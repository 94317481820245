import React, { useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import showSnackbar from 'shared/snackbar';
import { DemandRegionsContext } from './demand-regions.provider';
import { getSortingContext } from './demand-regions.service';
import DemandRegionsComponent from './demand-regions.component';
import { getSimulationContext } from './demand-regions.utils';

export default function DemandRegionsContainer() {
  const {
    openSimulationDrawerDemandRegions,
    setSortingContext,
    setSimulationSortingContext
  } = useContext(DemandRegionsContext);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const rc = getSelectedRoutingCode();
    if (openSimulationDrawerDemandRegions) {
      getSimulationContext(rc).then(response => {
        setSimulationSortingContext(response || null);
      });
    } else {
      getSortingContext(rc)
        .then(response => {
          setSortingContext(response?.data?.sortingContext || null);
        })
        .catch(() => {
          showSnackbar({
            message: 'Error',
            enqueueSnackbar,
            withButton: false,
            variant: 'error'
          });
        });
    }
  }, [
    enqueueSnackbar,
    openSimulationDrawerDemandRegions,
    setSortingContext,
    setSimulationSortingContext
  ]);

  return <DemandRegionsComponent />;
}
