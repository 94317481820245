import PropTypes from 'prop-types';

const model = {
  fullName: PropTypes.string,
  mobilePhone: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  id: PropTypes.string
};

export default model;
