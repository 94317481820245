import { getSelectedBase } from 'profile/profile.service';

export const packageCacheModel = (
  packageId,
  sortingRecordId,
  licensePlate,
  sortingContextLicensePlate,
  taskId,
  destinationId
) => {
  const { distribution_center: distributionCenter } = getSelectedBase();
  return {
    packageId,
    sortingRecordId: Number(sortingRecordId) || NaN,
    licensePlate: licensePlate
      ? `:${licensePlate
          .split(':')
          .slice(3, 5)
          .join(':')}`
      : '', // there is no "LIKE" on indexedDB, thats why we are caching only the process:destination
    timestamp: new Date(),
    distributionCenter: distributionCenter?.id || null,
    sortingContextLicensePlate: sortingContextLicensePlate
      ? `${sortingContextLicensePlate
          .split(' ')
          .slice(-1)
          .join()}` // this treteament just get last word
      : '',
    taskId: Number(taskId) || null,
    destinationId: Number(destinationId) || null
  };
};
export default packageCacheModel;
