import React from 'react';
import { Skeleton } from '@material-ui/lab';

import { getCompanyType } from 'auth/access-control/access-control.service';
import {
  CONFIGURABLE_TABLE_CONFIGURATION_NA_BASE,
  unit,
  statusV2,
  deliveryDeadlineNew
} from 'tracking/tracking.configuration';
import { KEY_CUSTODY } from 'tracking/tracking.constants';

import { TEXT } from 'tracking/in-base/base-collection/base-collection.constants';

const {
  missedEventsBox,
  deliveryDeadline,
  bag,
  from,
  to,
  city,
  zipcode
} = CONFIGURABLE_TABLE_CONFIGURATION_NA_BASE;

const deliveryDate = {
  ...deliveryDeadline,
  header: 'Entrega'
};

const sendBy = {
  ...from,
  header: 'Quem enviou'
};

export const ORDER_BY_LIST = [deliveryDate, city, zipcode, statusV2];

export const ORDER_BY_LIST_DELIVERY_DEADLINE_NEW = [
  deliveryDeadlineNew,
  city,
  zipcode,
  statusV2
];

export const VIEW_TYPES = {
  deadline: [
    {
      label: TEXT.TODAY,
      custody: KEY_CUSTODY.NA_BASE_HOJE
    },
    {
      label: TEXT.DELAYED,
      custody: KEY_CUSTODY.NA_BASE_ATRASADO
    },
    {
      label: TEXT.TOMORROW,
      custody: KEY_CUSTODY.NA_BASE_AMANHA
    },
    {
      label: TEXT.AFTER_TOMORROW,
      custody: KEY_CUSTODY.NA_BASE_DEPOIS
    }
  ],
  attention: [
    {
      label: TEXT.WITH_FAULTS,
      custody: KEY_CUSTODY.NA_BASE_COM_FALTA
    },
    {
      label: TEXT.LOCATION_PROBLEM,
      custody: KEY_CUSTODY.NA_BASE_PROBLEMA_LOCALIZACAO
    },
    {
      label: TEXT.NO_DEADLINE,
      custody: KEY_CUSTODY.NA_BASE_SEM_PRAZO
    },
    {
      label: TEXT.AWAITING_RESOLUTION,
      custody: KEY_CUSTODY.NA_BASE_AGUARDANDO_TRATATIVA
    },
    {
      label: TEXT.AT_RISK,
      custody: KEY_CUSTODY.NA_BASE_EM_RISCO
    }
  ],
  position: [
    {
      label: TEXT.RECEIVE,
      custody: KEY_CUSTODY.NA_BASE_RECEBIMENTO
    },
    {
      label: TEXT.AWAITING_RESOLUTION,
      custody: KEY_CUSTODY.NA_BASE_AGUARDANDO_TRATATIVA
    }
  ]
};

export const getBaseListColumns = () => ({
  missedEvents: { ...missedEventsBox },
  deliveryDate: { ...deliveryDate },
  unitBag: getCompanyType() === 'AGENCY' ? { ...unit } : { ...bag },
  sendBy: { ...sendBy },
  to: { ...to },
  city: { ...city },
  zipcode: { ...zipcode },
  statusV2: { ...statusV2 }
});

export const getBaseListColumnsDeliveryDeadlineNew = () => ({
  missedEvents: { ...missedEventsBox },
  deliveryDate: { ...deliveryDeadlineNew },
  unitBag: getCompanyType() === 'AGENCY' ? { ...unit } : { ...bag },
  sendBy: { ...sendBy },
  to: { ...to },
  city: { ...city },
  zipcode: { ...zipcode },
  statusV2: { ...statusV2 }
});

export const getBaseListColumnsLoading = () => {
  const columns = {
    missedEvents: { ...missedEventsBox },
    deliveryDate: { ...deliveryDate },
    unitBag: getCompanyType() === 'AGENCY' ? { ...unit } : { ...bag },
    sendBy: { ...sendBy },
    to: { ...to },
    city: { ...city },
    zipcode: { ...zipcode },
    statusV2: { ...statusV2 }
  };
  Object.keys(columns).forEach(column => {
    columns[column].component = () => (
      <Skeleton variant="rect" width="30px" height="30px" />
    );
  });

  columns.deliveryDate.component = () => (
    <Skeleton variant="rect" width="70px" height="30px" />
  );
  columns.unitBag.component = () => (
    <Skeleton variant="rect" width="100px" height="30px" />
  );
  columns.sendBy.component = () => (
    <Skeleton variant="rect" width="100px" height="30px" />
  );
  columns.to.component = () => (
    <Skeleton variant="rect" width="100px" height="30px" />
  );
  columns.city.component = () => (
    <Skeleton variant="rect" width="100px" height="30px" />
  );
  columns.zipcode.component = () => (
    <Skeleton variant="rect" width="70px" height="30px" />
  );
  columns.statusV2.component = () => <Skeleton variant="rect" height="30px" />;
  return columns;
};

export const getBaseListColumnsLoadingDeliveryDeadlineNew = () => {
  const columns = { ...getBaseListColumnsLoading() };

  columns.deliveryDate = { ...deliveryDeadlineNew };

  return columns;
};
