import CPF from 'cpf';

const formatCpf = value => {
  try {
    return CPF.format(value);
  } catch (_) {
    return value;
  }
};

export default formatCpf;
