import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, SnackbarContent, Button, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { variantIcon, textButton } from './snackbar.constants';
import { useStyles } from './snackbar.styles';

const SnackbarMessage = forwardRef((props, ref) => {
  const { id, open, message, variant, withButton, link, messageButton } = props;
  const { closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const Icon = variantIcon[variant];
  const ButtonClose = textButton[variant];

  const buttonMsg = link ? messageButton : ButtonClose;

  const handleButtonClick = () => {
    if (link) {
      window.open(link, '_blank');
    }
    closeSnackbar(id);
  };

  return (
    <Snackbar
      className={classes.root}
      ref={ref}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={7000}
      onClose={() => closeSnackbar(id)}
    >
      <SnackbarContent
        message={
          <Box display="flex" alignItems="center">
            <Icon />
            <Box className={classes.messageBox} ml={2}>
              {message}
            </Box>
          </Box>
        }
        action={
          withButton && [
            <Button
              variant="outlined"
              key="close"
              aria-label="close"
              color="primary"
              onClick={() => handleButtonClick()}
              size="small"
            >
              {buttonMsg}
            </Button>
          ]
        }
      />
    </Snackbar>
  );
});

SnackbarMessage.propTypes = {
  id: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  variant: PropTypes.string,
  withButton: PropTypes.bool,
  link: PropTypes.string,
  messageButton: PropTypes.string
};

SnackbarMessage.defaultProps = {
  variant: 'error',
  withButton: true,
  link: '',
  messageButton: ''
};

export default SnackbarMessage;
