import { makeStyles } from '@material-ui/core/styles';

import { ORDERBY_COLUMN } from 'view/constants';

export const useStyles = makeStyles({
  button: {
    borderColor: '#00baff',
    color: '#00baff',
    marginBottom: '10px',
    marginTop: '10px',
    flexDirection: 'column'
  }
});

export const TEXT_TEMPLATE = {
  SUB_TITLE: 'Pacotes adicionados',
  DESCRIPTION: 'Bipe os pacotes para adicionar na lista de ',
  SHARE_LIST: 'Voltar',
  BARCODE: 'Código de barras',
  NO_PACKAGES: 'Sem pacotes na lista!'
};

export function columnsConfigPackages() {
  return {
    recipientName: {
      header: 'Recebedor',
      headAlign: 'left',
      reorder: ORDERBY_COLUMN.RECIPIENT_NAME,
      propsStyleBody: { align: 'left' },
      component: item => item.recipient?.name
    },
    barcode: {
      header: 'Número do pacote',
      headAlign: 'left',
      reorder: ORDERBY_COLUMN.BARCODE,
      propsStyleBody: { align: 'left' }
    },
    destinationZipCode: {
      header: 'CEP',
      headAlign: 'left',
      reorder: ORDERBY_COLUMN.DESTINATION.ZIPCODE,
      propsStyleBody: { align: 'left' },
      component: item => item.destination?.zipCode
    }
  };
}
