import React, { useState, useEffect, useContext } from 'react';

import firebase from 'firebase/app';
import 'firebase/messaging';
import * as Sentry from '@sentry/browser';

import NotificationsContext from 'information/notifications/notifications.context';
import { PERMISSION } from 'information/notifications/notifications.constants';
import { notificationSubscriptionService } from './firebase.service';
import { sentryMessage } from '../api/leve';
import { TOKEN_FCM } from './firebase.constants';

export default function FirebaseCloudMessagingContainer() {
  const [fetchToken, setFetchToken] = useState(false);
  const [tokenFCM, setTokenFCM] = useState('');

  const { handleGetNotifications, notificationPermission } = useContext(
    NotificationsContext
  );

  const messagingIsSupported = firebase.messaging.isSupported();

  const handleNotificationSubscriptionError = error => {
    Sentry.withScope(
      sentryMessage(
        error,
        `Axios request - FCM - ${error.config?.url || 'no-url'}`,
        [
          error.config?.url || 'no-url',
          String(error.response?.status || 0),
          Sentry.Severity.Error
        ]
      )
    );
  };

  const handleGetToken = () => {
    const messaging = firebase.messaging();

    messaging.getToken().then(token => {
      setTokenFCM(token);
      setFetchToken(false);
      const savedTokenFCM = localStorage.getItem(TOKEN_FCM);
      if (token !== savedTokenFCM) {
        notificationSubscriptionService(token)
          .then(localStorage.setItem(TOKEN_FCM, token))
          .catch(error => handleNotificationSubscriptionError(error));
      }

      handleGetNotifications();
    });
  };

  // Messaging listener foreground
  if (messagingIsSupported) {
    const messaging = firebase.messaging();
    messaging.onMessage(handleGetNotifications);
  }

  useEffect(() => {
    if (!tokenFCM && !fetchToken) {
      setFetchToken(true);

      if (
        messagingIsSupported &&
        notificationPermission === PERMISSION.GRANTED
      ) {
        handleGetToken();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchToken, tokenFCM, setTokenFCM]);

  return <></>;
}
