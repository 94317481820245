import api from 'infra/api/leve';
import db from 'infra/storage/async-elasticsearch-database';
import { packageCacheModel } from 'infra/storage/async-elasticsearch-database.model';
import { URL_PROXY_V1_PREFIX } from 'infra/service/constants';

const updatePackageAddress = async (
  packageId,
  newAddress,
  isFalsePositiveForSuspiciousGeocoding = false,
  unitLoadLpn,
  sortingContextLpn
) => {
  const response = await api.put(
    `${URL_PROXY_V1_PREFIX}/package/${packageId}/address`,
    {
      newAddress,
      isFalsePositiveForSuspiciousGeocoding
    }
  );
  const { data } = response;

  db.savePackages([
    packageCacheModel(
      data.packageId,
      null,
      unitLoadLpn,
      sortingContextLpn,
      null,
      data.newDestinationId
    )
  ]);
  return response;
};

export default updatePackageAddress;
