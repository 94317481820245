import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Box, Button, Typography, IconButton } from '@material-ui/core';
import { colors } from '@loggi/mar';

import { ReactComponent as ExternalLinkIcon } from 'images/external-link.svg';
import { DrawerHeader, DrawerContent } from 'shared/drawer';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { getStorageValueWithTtl } from 'app/utils/storage-value-with-ttl';
import { formatDate } from 'finance/finance.configuration';
import { AUTHORIZED_ROUTES } from 'view/constants';
import SimulationInProgress from './demand-regions-simulation-in-progress';
import { DemandRegionsContext } from '../demand-regions.provider';
import { DEMAND_REGIONS_INITIAL_DRAWER } from '../demand-regions.constants';
import { getSimulationContext } from '../demand-regions.utils';

const InitialDrawer = ({ numPositions, onClose, lastUpdated }) => {
  const simulationInProgress = getStorageValueWithTtl('SIMULATION');

  const {
    simulationSortingContext,
    setSimulationSortingContext,
    setOpenSimulationDrawerDemandRegions
  } = useContext(DemandRegionsContext);

  useEffect(() => {
    getSimulationContext(getSelectedRoutingCode()).then(response => {
      setSimulationSortingContext(response || null);
    });
  }, [setSimulationSortingContext]);

  const history = useHistory();
  const handleOpenDemandRegionsPage = ({ openSimulation = false }) => {
    setOpenSimulationDrawerDemandRegions(openSimulation);
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${AUTHORIZED_ROUTES.ROUTINES.DEMAND_REGIONS}`);
  };

  return (
    <>
      <DrawerHeader
        titleVariant="h5"
        title={DEMAND_REGIONS_INITIAL_DRAWER.TITLE}
        detail={DEMAND_REGIONS_INITIAL_DRAWER.DETAIL}
        colorText="textSecondary"
        showDivider
        variantTextDetail="body1"
        handleClosingDrawer={onClose}
      >
        <Box mt={2}>
          {simulationInProgress ? (
            <SimulationInProgress />
          ) : (
            <>
              {simulationSortingContext && (
                <Box
                  borderRadius={8}
                  p={2}
                  data-testid="simulation-sorting-context"
                  bgcolor={colors.blue[50]}
                  mb={2}
                >
                  <Box fontWeight={700} mb={2}>
                    {DEMAND_REGIONS_INITIAL_DRAWER.SIMULATION_AVAILABLE_TITLE}
                  </Box>

                  <Box mb={2}>
                    {
                      DEMAND_REGIONS_INITIAL_DRAWER.SIMULATION_AVAILABLE_SUBTITLE
                    }
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    data-testid="view-simulation"
                    onClick={() => {
                      handleOpenDemandRegionsPage({ openSimulation: true });
                    }}
                  >
                    {DEMAND_REGIONS_INITIAL_DRAWER.VIEW_SIMULATION_BUTTON}
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </DrawerHeader>
      <DrawerContent showDivider>
        <Box mt={2} mb={2} data-testid="number-demand-regions">
          <Typography color="textPrimary" variant="subtitle1">
            {numPositions} {DEMAND_REGIONS_INITIAL_DRAWER.NUMBER_REGIONS}
          </Typography>
          <Box mt={1}>
            <Typography color="textSecondary" variant="subtitle2">
              {DEMAND_REGIONS_INITIAL_DRAWER.SAME_POSITIONS}
              {formatDate(lastUpdated, 'DD MMM').toLowerCase()}
            </Typography>
          </Box>
          <Button
            style={{ paddingLeft: '0px' }}
            variant="text"
            color="primary"
            size="medium"
            data-testid="view-details"
            endIcon={<ChevronRightIcon color="primary" />}
            onClick={() => {
              handleOpenDemandRegionsPage({});
            }}
          >
            {DEMAND_REGIONS_INITIAL_DRAWER.VIEW_DETAILS}
          </Button>
        </Box>
      </DrawerContent>
      <DrawerContent>
        <Box data-testid="adjust-layout">
          <Box mb={1} mt={2}>
            <Box mb={1} mt={2} />
            <Typography color="textPrimary" variant="body1">
              <em>{DEMAND_REGIONS_INITIAL_DRAWER.ADJUST_LAYOUT_FIRST_PART}</em>{' '}
              {DEMAND_REGIONS_INITIAL_DRAWER.ADJUST_LAYOUT_SECOND_PART}
            </Typography>
          </Box>
          <Button
            style={{ paddingLeft: '0px' }}
            variant="text"
            color="primary"
            size="medium"
            data-testid="button-adjust-layout"
            endIcon={
              <IconButton>
                <ExternalLinkIcon color="primary" />
              </IconButton>
            }
            onClick={() => {
              window.open(
                DEMAND_REGIONS_INITIAL_DRAWER.ADJUST_LAYOUT_LINK,
                '_blank'
              );
            }}
          >
            {DEMAND_REGIONS_INITIAL_DRAWER.ADJUST_LAYOUT_BUTTON}
          </Button>
        </Box>
      </DrawerContent>
    </>
  );
};

InitialDrawer.defaultProps = {
  numPositions: 0
};

InitialDrawer.propTypes = {
  numPositions: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string.isRequired
};

export default InitialDrawer;
