import React from 'react';
import PropTypes from 'prop-types';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Can } from 'auth/access-control';

import { useStyles } from './drawer-actions.styles';

export default function DrawerActions({ canActions, showDivider, actions }) {
  const classess = useStyles();

  return (
    <Can actions={canActions}>
      <List className={classess.root}>
        {actions.map(action => {
          return (
            <React.Fragment key={action.label}>
              <ListItem
                button
                className={classess.listItem}
                onClick={action.callback}
              >
                <ListItemText primary={action.label} />
                <ArrowForwardIosIcon
                  className={classess.listItemIcon}
                  color="primary"
                  fontSize="small"
                />
              </ListItem>
              {showDivider && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
    </Can>
  );
}

DrawerActions.propTypes = {
  showDivider: PropTypes.bool,
  canActions: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      callback: PropTypes.func
    })
  ).isRequired
};

DrawerActions.defaultProps = {
  showDivider: false,
  canActions: []
};
