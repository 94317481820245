import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    divDrawer: {
      '& .MuiDrawer-paper': {
        width: '100%'
      }
    }
  })
);

export default useStyles;
