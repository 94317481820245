export const TEXT = {
  NF: 'Notas de cobrança',
  VALUE_DOLLAR: 'Valor',
  DATA_FOR_EMISSION: 'Dados para emissão da nota',
  SEND_FOR: 'Envie para ',
  HAVE_QUESTIONS: 'Tem duvidas? Entenda a sua fatura?',
  EMAIL_LEVE: 'leve.financeiro@loggi.com',
  MORE_ABOUT: 'Saiba mais',
  ARR_INFO_LOGGI: [
    'L4B LOGÍSTICA LTDA',
    'Avenida Ribeirão dos Cristais(G Preto), 800 - Galpão 100 Bloco Q e R',
    'Empresarial Paineira (Jordanésia), Cajamar - SP',
    'CEP: 07.775-240',
    'CNPJ: 24.217.653/0006-08',
    'Insc. Est: 241.111.449.111',
    'CCM: 16211'
  ],
  HAVE_QUESTIONS_FOOTER: 'Tem dúvidas? ',
  UNDERSTAND_FOOTER: 'Entenda a sua fatura.',
  HOW_TO_SEND_YOUR_NF: 'Como enviar as notas',
  DATA_TO_SEND_NOTES: 'Dados para emitir as notas',
  TEXT_TO_HELP_SEND_NOTE:
    'Acesse a lista e consulte os dados do endereço mais próximo da sua base.',
  REPORT_PROBLEM: 'Informar problema',
  CONSULT_LIST: 'Consultar dados',
  CONSULT_LIST_LINK:
    'https://atom-snout-28f.notion.site/ceef3cb4200e4a58aeb6e854d48227fb?v=1bc0c18e70d34432b5917fc07e4be65a',
  HELP_LINK:
    'https://ajuda.loggi.com/hc/pt-br/articles/360043094912-Veja-as-datas-dos-seus-pr%C3%B3ximos-per%C3%ADodos-de-ganhos-',
  REPORT_PROBLEM_LINK:
    'https://ajuda.loggi.com/hc/pt-br/requests/new?ticket_form_id=360000959932',
  LINK_PIPEFY: 'https://app.pipefy.com/public/form/b5JFtDCQ',
  TEXT_WITH_SUBJECT: ' e escreva no assunto do e-mail: "Valores fechados: ',
  TEXT_SUBJECT_EMAIL: ' Valores fechados: Base ',
  TEXT_SUBJECT_EMAIL_WITHOUT_DC: "'Nome da sua base'",
  TEXT_HELP_CENTER:
    'Acesse o formulário e preenche todos os campos. Se tiver dúvidas, ',
  TEXT_CONSULTE: 'consulte as instruções de preenchimento.',
  TEXT_SPEAK_WITH_US: 'aqui',
  INFO_BOX_TITLE: 'Importante',
  INFO_BOX_TEXT:
    'É necessário emitir notas separadas com o valor de cada serviço.',
  INFO_BOX_TEXT_UPDATED:
    'Calculado com base nos serviços realizados no período. Esse valor pode sofrer descontos no pagamento.',
  DELIVERY: 'Entrega',
  PICKUP: 'Coleta',
  TRANSHIPMENT: 'Processamento',
  COUNTER: 'Balcão',
  TOTAL: 'Total'
};

export default TEXT;
