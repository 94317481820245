import moment from 'moment';
import 'moment/locale/pt-br';

export const dateFormatter = (fullDate, format = 'DD MMM') => {
  return moment(fullDate)
    .locale('pt-BR')
    .format(format)
    .toLowerCase();
};

export default dateFormatter;
