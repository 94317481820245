import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { getDriversService } from 'users-management/drivers/drivers.service';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import { DisputeDriversList } from './disputes-drivers-list.component';
import { updateDisputeResponsible } from './disputes.service';
import { UPDATE_RESPONSIBLE_TEXTS } from './disputes.constants';

export const UpdateDisputeResponsible = ({ buttonText, dispute }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [{ users = [] }, setData] = useState({});
  const [getDrivers, setgetDrivers] = useState(true);
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  const [selectedDriver, setSelectedDriver] = useState();

  const handleClick = event => {
    event.preventDefault();
    setAnchor(event.currentTarget);
  };

  const handleSave = () => {
    updateDisputeResponsible(dispute.disputeId, selectedDriver)
      .then(() => {
        showSnackbar({
          message: UPDATE_RESPONSIBLE_TEXTS.labelSuccessDriverAllocation,
          variant: 'success',
          enqueueSnackbar
        });
      })
      .catch(error => {
        if (error?.response?.status) {
          showSnackbar({
            message: `${UPDATE_RESPONSIBLE_TEXTS.labelErrorDriverAllocation} (${
              error.message
            })`,
            variant: 'error',
            enqueueSnackbar
          });
        }
      });
  };

  const handleSelectDriver = driverId => {
    setSelectedDriver(driverId);
  };

  useEffect(() => {
    if (getDrivers) {
      getDriversService(0)
        .then(response => {
          setData(response);
          setgetDrivers(false);
        })
        .catch(error => {
          showSnackbar({
            message: `${UPDATE_RESPONSIBLE_TEXTS.labelErrorGetDrivers} (${
              error.message
            })`,
            variant: 'error',
            enqueueSnackbar
          });
        });
    }
  }, [getDrivers, enqueueSnackbar]);

  const driversList = (
    <DisputeDriversList users={users} handleSelectDriver={handleSelectDriver} />
  );

  return (
    <Box mr={2} component="span">
      <Button
        data-testid="button-update-dispute-responsible"
        size="small"
        color="primary"
        variant="outlined"
        onClick={handleClick}
      >
        {buttonText}
        {!open && <ArrowDropDownIcon />}
        {open && <ArrowDropUpIcon />}
      </Button>
      <Popover
        anchorEl={anchor}
        open={open}
        onClose={() => setAnchor(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            width: 400
          }
        }}
      >
        <Box maxWidth="400px" maxHeight="300px" style={{ overflowX: 'hidden' }}>
          {driversList}
        </Box>

        <footer>
          <Box m={3}>
            <Grid container>
              <Grid item xs={4}>
                <Button
                  data-testid="cancel-button"
                  color="primary"
                  fullWidth
                  onClick={() => setAnchor(null)}
                >
                  {UPDATE_RESPONSIBLE_TEXTS.cancelButton}
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button
                  fullWidth
                  data-testid="confirm-button"
                  variant="outlined"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    handleSave();
                    setAnchor(null);
                  }}
                >
                  {UPDATE_RESPONSIBLE_TEXTS.confirmButton}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </footer>
      </Popover>
    </Box>
  );
};

UpdateDisputeResponsible.propTypes = {
  buttonText: PropTypes.string.isRequired,
  dispute: PropTypes.shape().isRequired
};

export default UpdateDisputeResponsible;
