import React from 'react';
import { Chip } from '@material-ui/core';
import { get } from 'tracking/tracking.configuration';
import { formatDate } from 'finance/finance.configuration';
import { MAPPER_STATUS } from './disputes.constants';

const deadline = {
  header: 'Prazo',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => `até ${formatDate(item?.deadline, 'DD MMM [às] HH:mm')}`
};

const deliverer = {
  header: 'Entregador',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, 'deliverer', '-')
};

const disputeResponsible = {
  header: 'Quem vai resolver',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => {
    const disputeResponsibleName = get(item, 'disputeResponsible', false);
    // eslint-disable-next-line no-unneeded-ternary
    return disputeResponsibleName ? disputeResponsibleName : 'ninguém alocado';
  }
};

const packageBarcode = {
  header: 'Pacote',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, 'packageBarcode', '-')
};

const translatedStatus = {
  header: 'Resposta',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => {
    const status = get(item, 'translatedStatus', '-');
    const statusMap = MAPPER_STATUS[status];

    return statusMap ? (
      <Chip style={statusMap.style} size="small" label={statusMap.text} />
    ) : (
      <Chip size="small" label="-" />
    );
  }
};

export default {
  deadline,
  deliverer,
  disputeResponsible,
  packageBarcode,
  translatedStatus
};
