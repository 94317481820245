import { useContext } from 'react';

import { FeatureSwitchContext } from 'infra/firebase/feature-switch-provider';
import { getSelectedBase } from 'profile/profile.service';
import camelCase from 'lodash/camelCase';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { useLocation } from 'react-router-dom';
import { SWITCHES } from 'view/constants';

// Keep the FSM in local storage so it is not lost when reloading the page
const saveToLocalStorage = newValues => {
  try {
    // Get existing values from Local Storage
    const existingValuesString = localStorage.getItem('overrideValues');
    const existingValues = existingValuesString
      ? JSON.parse(existingValuesString)
      : {};

    const combinedValues = { ...existingValues, ...newValues };

    // Save the combination to Local Storage
    localStorage.setItem('overrideValues', JSON.stringify(combinedValues));
  } catch (e) {
    // Intentionally do nothing
  }

  const combinedValues = JSON.parse(localStorage.getItem('overrideValues'));
  return combinedValues;
};

function OverrideValues() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const overrideValues = {};
  Array.from(searchParams.entries()).forEach(([searchParam, searchValue]) => {
    try {
      overrideValues[searchParam] = JSON.parse(searchValue);
    } catch {
      overrideValues[searchParam] = searchValue;
    }
  });

  return saveToLocalStorage(overrideValues);
}

const ISFSActiveForUserEmail = fsValues => {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  return (
    fsValues &&
    fsValues.length > 0 &&
    // eslint-disable-next-line camelcase
    fsValues.indexOf(authenticatedUser?.email) >= 0
  );
};

export const isDCInLmcList = (dcsList, LMCDC) =>
  dcsList &&
  dcsList.length > 0 &&
  dcsList.indexOf(
    `${
      // eslint-disable-next-line camelcase
      LMCDC?.last_mile_company.identification
    }:${
      // eslint-disable-next-line camelcase
      LMCDC?.distribution_center?.id || 0
    }`
  ) >= 0;

const IsFSActiveForCompanyType = (fsValues, LMCDC) =>
  fsValues &&
  fsValues.length > 0 &&
  // eslint-disable-next-line camelcase
  fsValues.indexOf(LMCDC?.last_mile_company?.company_type) >= 0;

const IsFSActive = fsValues => {
  const LMCDC = getSelectedBase();

  return (
    // Releases FS to all bases
    fsValues === true ||
    fsValues === 'true' ||
    // Releases FS for bases specify ['lmc:dc']
    isDCInLmcList(fsValues, LMCDC) ||
    // Release FS by base type ['COMPANY_TYPE']
    IsFSActiveForCompanyType(fsValues, LMCDC) ||
    // Release FS by user email
    ISFSActiveForUserEmail(fsValues)
  );
};

export const IsFSActiveWithoutEmail = fsValues => {
  const LMCDC = getSelectedBase();

  return (
    // Releases FS to all bases
    fsValues === true ||
    fsValues === 'true' ||
    // Releases FS for bases specify ['lmc:dc']
    isDCInLmcList(fsValues, LMCDC) ||
    // Release FS by base type ['COMPANY_TYPE']
    IsFSActiveForCompanyType(fsValues, LMCDC)
  );
};

/**
 * This function should be able to handle
 * with different paramenters on a single fs
 * be the FS:
 *
 * - Array['lmc:dc']:
 *  ex:  enableFeatureX = ['loggi_tower_0:1000']
 *
 * - Array['COMPANY_TYPE']:
 *  ex:  enableFeatureX = ['LAST_MILE_COMPANY_TYPE_LEVE']
 *
 * - Array['lmc:dc','COMPANY_TYPE']:
 *  ex:  enableFeatureX = ['loggi_tower_0:1000', 'LAST_MILE_COMPANY_TYPE_LEVE']
 *
 * - Boolean:
 *  ex:  enableFeatureX = true
 *
 * With this facilitating the rollout, the feature can begin for specific
 * bases enableFeatureX = ['loggi_tower_0:1000'], then if you want,
 * you can evolve the rollout to release by base type
 * enableFeatureX = ['loggi_tower_0:1000', 'LAST_MILE_COMPANY_TYPE_LEVE']
 * and at last you may wish to evolve to release to all bases
 * enableFeatureX = true
 *
 * @param FS: String
 * @returns Boolean
 */
export const IsFSActiveForCurrentBase = FS => {
  let FSValues = useContext(FeatureSwitchContext);

  let overrideValuesFromLocalStorage = {};

  if (
    IsFSActive(FSValues[camelCase(SWITCHES.enableOverrideFs)]) ||
    process.env.REACT_APP_ARCO_VERSION === 'dev'
  ) {
    overrideValuesFromLocalStorage = OverrideValues();

    FSValues = { ...FSValues, ...overrideValuesFromLocalStorage };
  }

  const fsValues = FSValues[camelCase(FS)];

  return IsFSActive(fsValues);
};
