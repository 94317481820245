export const TEXTS = {
  NEW_REGISTER: {
    TITLE: 'Novo cadastro',
    SUBTITLE: 'Informe os dados de quem vai fazer entregas.',
    BUTTON: 'Cadastrar'
  },
  COMPLETE_REGISTER: {
    TITLE: 'Completar cadastro',
    SUBTITLE:
      'Escreva abaixo as informações que faltam de quem vai fazer as coletas e entregas.',
    BUTTON: 'Completar cadastro'
  },
  NAME: {
    error: 'Faltou o nome completo. Informe para continuar.',
    label: 'Nome completo'
  },
  CPF: {
    error: 'Esse CPF é inválido. Informe os números certos para continuar.',
    label: 'CPF'
  },
  EMAIL: {
    error: 'Esse e-mail é inválido. Informe o e-mail para continuar.',
    label: 'E-mail'
  },
  MOBILE_PHONE: {
    error: 'Faltou o telefone. Informe o número para continuar.',
    label: 'Celular'
  },
  TRANSPORT_TYPE: {
    label: 'Veículo'
  },
  TERMS: ['Estou ciente do ', ' e quero continuar.'],
  TERMS_LINK:
    'contrato que tenho com a Loggi e a minha relação com esta pessoa',
  DRIVER_CREATED:
    'Beleza, cadastro feito. Para fazer coletas, utilize o app Loggi para Entregadores.',
  DIALOG: {
    title: 'Agora é só esperar a confirmação.',
    description_email:
      'já tinha algumas informações cadastradas na base e precisa confirmar as informações no e-mail que enviamos.',
    description_sms:
      'já recebeu o link de confirmação que enviamos por SMS. Aguarde mais um pouco até a sua confirmação.',
    ok: 'Entendi'
  },
  EMAIL_SEARCH: 'Pesquisar por email...',
  NO_OPTIONS_TEXT: 'Nenhum resultado encontrado.',
  LOADING_TEXT: 'Buscando...',
  CONTINUE: 'Continuar'
};

export const LOGGI_EMAIL_DOMAIN = '@loggi.com';

export default TEXTS;
