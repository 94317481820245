import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, DialogContent } from '@material-ui/core';

export default function ContentDialog({
  title,
  subtitle,
  description,
  children
}) {
  return (
    <DialogContent>
      <Box mt={10} ml={3} display="flex" flexDirection="column">
        <Typography variant="body2">{children}</Typography>
        {subtitle && (
          <Typography
            style={{ marginTop: '80px' }}
            color="textSecondary"
            variant="body1"
          >
            {subtitle}
          </Typography>
        )}
        <Box mt={1}>
          <Typography variant="h5">
            <em>{title}</em>
          </Typography>
        </Box>
        <Box mt={2} mb={10} variant="body1">
          {description}
        </Box>
      </Box>
    </DialogContent>
  );
}

ContentDialog.defaultProps = {
  subtitle: ''
};

ContentDialog.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};
