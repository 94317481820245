import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AUTHORIZED_ROUTES, SWITCHES } from 'view/constants';
import { Box, Paper, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import { CanFunction, IsFSActiveForCurrentBase } from 'auth/access-control';
import { getCompanyType } from 'auth/access-control/access-control.service';

import BaseContext from './base.context';
import {
  getAggregationsAttention,
  getAggregationsDeadline,
  getAggregationsPosition
} from './base-collection/base-collection.service';
import { VIEW_TYPES } from './base-list/base-list.configuration';

const TAB_COLLECTION = 'Coleções';
const TAB_GROUP = 'Grupos';
const TAB_PACKAGE = 'Pacotes';

const useStyles = makeStyles({
  root: {
    borderBottom: `1px solid ${colors.smoke[200]}`
  }
});

export default function BaseContainerProvider({ children }) {
  const [filters, setFilters] = useState({});
  const [checkedItemsBase, setCheckedItemsBase] = useState({});
  const [aggregationDeadline, setAggregationDeadline] = useState(null);
  const [aggregationPosition, setAggregationPosition] = useState(null);
  const [aggregationAttention, setAggregationAttention] = useState(null);
  const [loadingAggDeadLine, setLoadingAggDeadLine] = useState(true);
  const [loadingAggPosition, setLoadingAggPosition] = useState(true);
  const [loadingAggAttention, setLoadingAggAttention] = useState(true);
  const [totalPackages, setTotalPackages] = useState(0);

  const [backBaseListDeadline, setBackBaseListDeadline] = useState(false);
  const [backBaseListAttention, setBackBaseListAttention] = useState(false);
  const [backBaseListPosition, setBackBaseListPosition] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { INFORMATION } = AUTHORIZED_ROUTES;
  const ROUTE_COLLECTION = `${INFORMATION.BASE}${INFORMATION.COLLECTION}`;

  const tabMapping = url => {
    if (url.endsWith(INFORMATION.COLLECTION)) return TAB_COLLECTION;
    if (url.endsWith(INFORMATION.GROUPS)) return TAB_GROUP;
    if (url.endsWith(INFORMATION.PACKAGES)) return TAB_PACKAGE;

    // when loggiPonto, show only packages tab
    // this prevents warnings in the browser caused by the menu navigation page
    if (!CanFunction({ customDisabledTo: 'loggiPonto' })) {
      return TAB_PACKAGE;
    }
    // this is prevent warning in browser, becouse navigation menu page
    if (!CanFunction({ customDisabledTo: 'loggiPonto' })) {
      return TAB_PACKAGE;
    }

    return TAB_COLLECTION;
  };

  const resetAggregationData = () => {
    setAggregationDeadline(null);
    setAggregationAttention(null);
    setAggregationPosition(null);
    setTotalPackages(0);
  };

  const onApplyChanges = (items, resetBaseListData) => {
    if (items.remove || Object.values(items)[0] === undefined) {
      setFilters({});
      setCheckedItemsBase({});
    } else {
      setFilters(currentState => {
        return { ...currentState, ...items };
      });
    }

    if (resetBaseListData) {
      resetBaseListData();
      return;
    }

    resetAggregationData();
  };

  useEffect(() => {
    setTotalPackages(0);
    setLoadingAggAttention(true);
    setLoadingAggPosition(true);
    setLoadingAggDeadLine(true);
  }, [filters]);

  const { url } = useRouteMatch();
  const history = useHistory();
  const style = useStyles();
  const [currentTab, setCurrentTab] = useState(
    tabMapping(history.location.pathname)
  );
  const [alertContent, setAlertContent] = useState(<></>);
  const [
    showActionsInConfigurableTableCollapsible,
    setShowActionsInConfigurableTableCollapsible
  ] = useState(false);

  let enabledTabs = [TAB_COLLECTION];
  const hasGroupTabEnabled = IsFSActiveForCurrentBase(
    SWITCHES.enableBaseGroupTabView
  );

  if (hasGroupTabEnabled) {
    enabledTabs.push(TAB_GROUP);
    enabledTabs.push(TAB_PACKAGE);
  } else {
    enabledTabs.push(TAB_PACKAGE);
  }

  // when loggiPonto, show only packages tab
  if (!CanFunction({ customDisabledTo: 'loggiPonto' })) {
    enabledTabs = [TAB_PACKAGE];
  }

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);

    if (newTab === TAB_COLLECTION) {
      setTotalPackages(0);
      setLoadingAggAttention(true);
      setLoadingAggPosition(true);
      setLoadingAggDeadLine(true);
      history.push(`${url}${AUTHORIZED_ROUTES.INFORMATION.COLLECTION}`);
    } else if (newTab === TAB_GROUP)
      history.push(`${url}${AUTHORIZED_ROUTES.INFORMATION.GROUPS}`);
    else if (newTab === TAB_PACKAGE)
      history.push(`${url}${AUTHORIZED_ROUTES.INFORMATION.PACKAGES}`);
    else history.push(`${url}${AUTHORIZED_ROUTES.INFORMATION.BASE}`);
  };

  useEffect(() => {
    if (currentTab === TAB_GROUP && getCompanyType() === 'LEVE')
      setShowActionsInConfigurableTableCollapsible(true);
    else setShowActionsInConfigurableTableCollapsible(false);
  }, [currentTab]);

  useEffect(() => {
    if (loadingAggDeadLine || (!aggregationDeadline && backBaseListDeadline)) {
      setLoadingAggDeadLine(false);
      if (
        history.location.pathname.endsWith(ROUTE_COLLECTION) ||
        VIEW_TYPES.deadline.some(i =>
          history.location.pathname.endsWith(i.custody)
        )
      ) {
        getAggregationsDeadline(filters)
          .then(aggregationsData => {
            setAggregationDeadline(aggregationsData.data);
            setTotalPackages(oldTotal => oldTotal + aggregationsData.total);
          })
          .catch(error => {
            showSnackbar({
              message: error.message,
              enqueueSnackbar,
              variant: 'error'
            });
          });
        setBackBaseListDeadline(false);
      }
    }
  }, [
    filters,
    ROUTE_COLLECTION,
    history,
    history.location.pathname,
    aggregationDeadline,
    loadingAggDeadLine,
    backBaseListDeadline,
    enqueueSnackbar
  ]);

  useEffect(() => {
    if (
      loadingAggAttention ||
      (!aggregationAttention && backBaseListAttention)
    ) {
      setLoadingAggAttention(false);

      if (
        history.location.pathname.endsWith(ROUTE_COLLECTION) ||
        VIEW_TYPES.attention.some(i =>
          history.location.pathname.endsWith(i.custody)
        )
      ) {
        getAggregationsAttention(filters)
          .then(aggregationsData => {
            setAggregationAttention(aggregationsData.data);

            const noDeadline = aggregationsData.data.find(
              agg => agg.label === 'noDeadline'
            );

            if (noDeadline) {
              setTotalPackages(oldTotal => oldTotal + noDeadline.value);
            }

            const awaitingResolution = aggregationsData.data.find(
              agg => agg.label === 'awaitingResolution'
            );

            if (awaitingResolution) {
              setTotalPackages(oldTotal => oldTotal + awaitingResolution.value);
            }
          })
          .catch(error => {
            showSnackbar({
              message: error.message,
              enqueueSnackbar,
              variant: 'error'
            });
          });
        setBackBaseListAttention(false);
      }
    }
  }, [
    filters,
    ROUTE_COLLECTION,
    history,
    history.location.pathname,
    aggregationAttention,
    loadingAggAttention,
    backBaseListAttention,
    enqueueSnackbar
  ]);

  useEffect(() => {
    if (loadingAggPosition || (!aggregationPosition && backBaseListPosition)) {
      setLoadingAggPosition(false);

      if (
        history.location.pathname.endsWith(ROUTE_COLLECTION) ||
        VIEW_TYPES.position.some(i =>
          history.location.pathname.endsWith(i.custody)
        )
      ) {
        getAggregationsPosition(filters)
          .then(aggregationsData => {
            setAggregationPosition(aggregationsData.data);
            const awaitingResolution = aggregationsData.data.find(
              agg => agg.label === 'awaitingResolution'
            );
            if (awaitingResolution) {
              setTotalPackages(oldTotal => oldTotal + awaitingResolution.value);
            }
          })
          .catch(error => {
            showSnackbar({
              message: error.message,
              enqueueSnackbar,
              variant: 'error'
            });
          });

        setBackBaseListPosition(false);
      }
    }
  }, [
    filters,
    ROUTE_COLLECTION,
    history,
    history.location.pathname,
    aggregationPosition,
    loadingAggPosition,
    backBaseListPosition,
    enqueueSnackbar
  ]);

  // Don't show tabs when a collection is selected
  const showTabs = !(
    history.location.pathname.includes('colecao') &&
    !history.location.pathname.endsWith('colecoes')
  );

  return (
    <BaseContext.Provider
      value={{
        onApplyChanges,
        filters,
        checkedItemsBase,
        setCheckedItemsBase,
        aggregationDeadline,
        aggregationAttention,
        aggregationPosition,
        totalPackages,
        showActionsInConfigurableTableCollapsible,
        setFilters,
        setBackBaseListDeadline,
        setBackBaseListPosition,
        setBackBaseListAttention,
        setAlertContent
      }}
    >
      <Paper component={Box} elevation={10} flexGrow={1} mb={6} minHeight={44}>
        {alertContent}
        <Box px={5.5}>
          {showTabs && (
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={currentTab}
              onChange={handleTabChange}
              className={style.root}
            >
              {enabledTabs.map(tab => (
                <Tab label={tab} key={tab} value={tab} />
              ))}
            </Tabs>
          )}
        </Box>
        <Box px={4.5} py={1}>
          {children}
        </Box>
      </Paper>
    </BaseContext.Provider>
  );
}

BaseContainerProvider.propTypes = {
  children: PropTypes.node.isRequired
};
