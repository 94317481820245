import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { Box, Typography } from '@material-ui/core';
import useStyles from 'view/molecules/package-drawer/drawer.style';
import disputesModel from 'tracking/disputes/disputes.model';
import { dateFormatter } from 'shared/formatters/date';
import { HIGHLIGHT_TEXTS } from './disputes.constants';

export const DisputeHighlight = ({ disputeData }) => {
  const formatedDisputeDeadline = dateFormatter(
    disputeData.deadline,
    'DD MMM [às] HH:mm'
  );
  const getDisputesDataPhrases = () => {
    if (disputeData.situation === 'DISPUTE_SITUATION_TYPE_EXPIRED') {
      return {
        title: HIGHLIGHT_TEXTS.expired.title,
        subtitle: HIGHLIGHT_TEXTS.expired.subtitle.replace(
          '$deadline',
          formatedDisputeDeadline
        ),
        deadline: ''
      };
    }
    if (
      disputeData.situation === 'DISPUTE_SITUATION_TYPE_PENDING' ||
      disputeData.situation === 'DISPUTE_SITUATION_INVALID' ||
      disputeData.situation === 'DISPUTE_SITUATION_TYPE_UNASSIGNED'
    ) {
      if (disputeData.disputeResponsible) {
        return {
          title: HIGHLIGHT_TEXTS.pendingWithResponsible.title,
          subtitle: HIGHLIGHT_TEXTS.pendingWithResponsible.subtitle.replace(
            '$responsible',
            disputeData.disputeResponsible
          ),
          deadline: HIGHLIGHT_TEXTS.pendingWithResponsible.deadline.replace(
            '$deadline',
            formatedDisputeDeadline
          )
        };
      }
      return {
        title: HIGHLIGHT_TEXTS.pedingWithoutResponsible.title,
        subtitle: HIGHLIGHT_TEXTS.pedingWithoutResponsible.subtitle,
        deadline: HIGHLIGHT_TEXTS.pedingWithoutResponsible.deadline.replace(
          '$deadline',
          formatedDisputeDeadline
        )
      };
    }
    return null;
  };

  const phrases = getDisputesDataPhrases();
  const classes = useStyles();

  return (
    <>
      <Box
        borderRadius={8}
        p={3}
        bgcolor={colors.blue[50]}
        data-testid="dispute-highlight-info"
      >
        <Typography className={classes.smallTitle} variant="subtitle2">
          {phrases.title}
        </Typography>
        <br />
        <Typography>
          {phrases.subtitle}
          <strong>{phrases.deadline}</strong>
        </Typography>
      </Box>
    </>
  );
};
DisputeHighlight.defaultProps = {
  disputeData: null
};
DisputeHighlight.propTypes = {
  disputeData: PropTypes.shape(disputesModel)
};

export default DisputeHighlight;
