export const TEXT = {
  WELCOME: {
    TITLE: 'Que bom ter você aqui!👋',
    CONTAINER:
      'Essa é a plataforma que dá visibilidade em tempo real e ferramentas para você administrar uma equipe de entregas.'
  },
  TITLE_FORM: 'Acesse o Arco',
  LABEL_EMAIL: 'E-mail',
  LABEL_PASSWORD: 'Senha',
  OR: 'ou',
  NOT_REGISTERED: 'Quer ser uma transportadora Leve?',
  SCHELDULE_CHAT: 'Agende uma conversa',
  INVALID_EMAIL: 'Digite um e-mail válido.',
  FORGOT_PASSWORD: 'Esqueceu sua senha?',
  SUGGESTION_LOGIN_WITH_GOOGLE:
    'Você já tem uma conta com esse e-mail. Faça login clicando em "Entrar com o Google".'
};

export const NOT_AUTHORIZED_SHOULD_SIGN_IN_WITH_GOOGLE_ACCOUNT =
  'User is not authorized to get auth details.';

export const BUTTON = {
  SUBMIT: 'Entrar',
  LOADING: 'Entrando...',
  GOOGLE: 'Entrar com o Google'
};

export const WAS_LOGGED_OUT = {
  KEY: 'WAS_LOGGED_OUT',
  MESSAGE: 'Opa! Este e-mail não tem acesso à plataforma.'
};
