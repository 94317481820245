import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Typography,
  List,
  ListItem,
  TextField,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  CircularProgress,
  ListSubheader
} from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ConfirmDialog from 'shared/confirm-dialog/confirm-dialog.component';
import { ReactComponent as FetchDeliverersIcon } from 'images/ic-entregador-empty.svg';
import TEXT from './drawer-change-deliverer.constants';
import { useStyles } from './drawer-change-deliverer.style';

export const isScrolled = reference => {
  return reference.current.scrollTop !== 0;
};

export function ChangeDelivererSelecting({
  deliverersDisplayed,
  barcode,
  selectedDelivererId,
  searchValue,
  onChangeInputSearch,
  onSelectDeliverer,
  onRestoreList,
  onConfirmChange
}) {
  const classes = useStyles();

  const [shouldShowShadow, setShouldShowShadow] = useState(false);
  const ref = React.createRef();
  const handleScroll = () => {
    // this externalization was needed to test the scroll effect
    setShouldShowShadow(isScrolled(ref));
  };

  const filteredDeliverers = deliverersDisplayed.filter(
    deliverer => deliverer.isDriver
  );

  return (
    <Box>
      <Box
        ref={ref}
        onScroll={handleScroll}
        maxHeight="calc(100vh - 191px)"
        className={classes.list}
      >
        <Box px={3.5} py={5}>
          <Typography variant="h4">
            <Box fontWeight={700}>{TEXT.TITLE}</Box>
          </Typography>
          <Box pt={1.5}>
            <Typography variant="h5">
              {TEXT.SUBTITLE}
              {barcode}
            </Typography>
          </Box>
        </Box>
        <List disablePadding>
          <Divider />
          <ListSubheader className={classes.listSubHeader}>
            <Box p={3.5}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={TEXT.SEARCH_DELIVERER}
                value={searchValue || ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRoundedIcon color="disabled" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <Box hidden={!searchValue}>
                      <InputAdornment position="end">
                        <IconButton onClick={onRestoreList}>
                          <CancelRoundedIcon
                            color="primary"
                            data-testid="icon-restore-search"
                          />
                        </IconButton>
                      </InputAdornment>
                    </Box>
                  )
                }}
                onChange={onChangeInputSearch}
              />
            </Box>
          </ListSubheader>
          {filteredDeliverers.map(deliverer => (
            <ListItem
              button
              divider
              selected={selectedDelivererId === deliverer.id}
              key={deliverer.id}
              onClick={() => onSelectDeliverer(deliverer)}
              className={`
              ${classes.item}
              ${
                selectedDelivererId === deliverer.id
                  ? classes.itemSelected
                  : classes.itemNotSelected
              }`}
            >
              <Grid container alignItems="center" justify="space-between">
                <Typography variant="body1">{deliverer.fullName}</Typography>
                <Box mr={3.5} hidden={selectedDelivererId !== deliverer.id}>
                  <CheckRoundedIcon id={deliverer.id} color="primary" />
                </Box>
              </Grid>
            </ListItem>
          ))}
        </List>
        {filteredDeliverers.length === 0 && (
          <Box
            px={3.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box py={3.5}>
              <FetchDeliverersIcon />
            </Box>
            <Box pb={3.5} px={6}>
              <Typography variant="body2" align="center" color="textSecondary">
                {TEXT.ERROR_MESSAGE_SEARCH}
                <Box component="span" fontWeight={700}>
                  {searchValue}
                </Box>
                {TEXT.ERROR_MESSAGE_SEARCH_WHERE}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box maxWidth={480} width={480}>
        <Box
          p={2}
          width={480}
          className={`${classes.footer} ${
            shouldShowShadow ? classes.footerShadow : ''
          }`}
        >
          <Button
            fullWidth
            size="large"
            variant="outlined"
            color="primary"
            disabled={!selectedDelivererId}
            onClick={onConfirmChange}
            data-testid="continue-button"
          >
            {TEXT.BUTTON_CONTINUE}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export const ChangeDelivererFetching = () => (
  <Box py={4} display="flex" justifyContent="center" alignItems="center">
    <CircularProgress justify="center" />
  </Box>
);

export function ChangeDelivererFetchFailure({ reloadFetch }) {
  return (
    <Grid container justify="center" alignItems="center">
      <Box
        px={3.5}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        minHeight="calc(100vh - 68px)"
      >
        <Box pb={3.5}>
          <FetchDeliverersIcon />
        </Box>
        <Box pb={3.5} px={6}>
          <Typography variant="body2" align="center" color="textSecondary">
            {TEXT.ERROR_MESSAGE_DELIVERERS}
          </Typography>
        </Box>
        <Button onClick={reloadFetch} variant="outlined" color="primary">
          {TEXT.BUTTON_TRY_AGAIN}
        </Button>
      </Box>
    </Grid>
  );
}

export function ChangeDelivererDialog({
  dialogSeeAtStreet,
  dialogUnderstood,
  delivererFullName,
  openDialog
}) {
  return (
    <ConfirmDialog
      open={openDialog}
      title={`${TEXT.IN_STREET_WITH}${delivererFullName}`}
      description={TEXT.SUBTITLE_DIALOG}
    >
      <Button
        variant="outlined"
        size="large"
        color="primary"
        onClick={dialogSeeAtStreet}
        data-testid="button-see-at-street"
      >
        {TEXT.SEE_AT_STREET}
      </Button>
      <Button
        variant="outlined"
        size="large"
        color="primary"
        onClick={dialogUnderstood}
        data-testid="button-understood"
      >
        {TEXT.UNDERSTOOD}
      </Button>
    </ConfirmDialog>
  );
}

ChangeDelivererSelecting.propTypes = {
  deliverersDisplayed: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired
    })
  ).isRequired,
  barcode: PropTypes.string,
  selectedDelivererId: PropTypes.string,
  searchValue: PropTypes.string,
  onChangeInputSearch: PropTypes.func.isRequired,
  onSelectDeliverer: PropTypes.func.isRequired,
  onRestoreList: PropTypes.func.isRequired,
  onConfirmChange: PropTypes.func.isRequired
};

ChangeDelivererSelecting.defaultProps = {
  selectedDelivererId: null,
  searchValue: '',
  barcode: null
};

ChangeDelivererFetchFailure.propTypes = {
  reloadFetch: PropTypes.func.isRequired
};

ChangeDelivererDialog.propTypes = {
  dialogSeeAtStreet: PropTypes.func.isRequired,
  dialogUnderstood: PropTypes.func.isRequired,
  delivererFullName: PropTypes.string.isRequired,
  openDialog: PropTypes.bool.isRequired
};
