import React from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  ADDRESS_TYPES,
  DRAWER_UPDATE_ADDRESS
} from '../drawer-update-address.constants';

export default function CoordinatesAddressForm({
  onChangeCoordinates,
  coordinates
}) {
  const {
    [ADDRESS_TYPES.coordinates]: { fields, title }
  } = DRAWER_UPDATE_ADDRESS.address_types;

  return (
    <Box mx={3.5} mt={3.5} data-testid="coordinates-form">
      <Typography variant="subtitle2">{title}</Typography>
      <Box display="flex" flexDirection="row" gridGap={16} mt={2}>
        <TextField
          fullWidth
          label={fields.latitude.text}
          variant="outlined"
          inputProps={{ 'data-testid': 'coordinates-latitude' }}
          value={coordinates.latitude}
          onChange={event =>
            onChangeCoordinates(fields.latitude.key, event.target.value)
          }
        />
        <TextField
          fullWidth
          label={fields.longitude.text}
          variant="outlined"
          inputProps={{ 'data-testid': 'coordinates-longitude' }}
          value={coordinates.longitude}
          onChange={event =>
            onChangeCoordinates(fields.longitude.key, event.target.value)
          }
        />
      </Box>
    </Box>
  );
}

CoordinatesAddressForm.propTypes = {
  onChangeCoordinates: PropTypes.func.isRequired,
  coordinates: PropTypes.shape().isRequired
};
