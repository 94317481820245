const TEXT = {
  TITLE: 'Distribuir para entregador',
  SUBTITLE: 'no pacote #',
  SEARCH_DELIVERER: 'Busque o entregador',
  BUTTON_CONTINUE: 'Continuar',
  ERROR_MESSAGE_DELIVERERS:
    'Eita! Deu ruim aqui na hora de carregar os entregadores.',
  BUTTON_TRY_AGAIN: 'Tentar de novo',
  ERROR_MESSAGE_SEARCH: 'Eita! não encontramos nenhum ',
  ERROR_MESSAGE_SEARCH_WHERE: ' na sua lista de entregadores',
  IN_STREET_WITH: 'Na rua com ',
  SUBTITLE_DIALOG: 'As informações do pacote foram alteradas com sucesso. 🤙',
  SEE_AT_STREET: 'Ir para na rua',
  UNDERSTOOD: 'Beleza, entendi'
};

export const DELIVERY_ASSIGNMENT = 'deliverer_assignment';

export const URL_GTM_GA_CHANGE_DELIVERER_DONE = '/alterar-entregador/sucesso';

export default TEXT;
