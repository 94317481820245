import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ typography, palette }) => ({
  missEventsBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.error.contrastText,
    backgroundColor: palette.error.main,
    borderRadius: typography.pxToRem(4),
    lineHeight: 1,
    letterSpacing: 0
  },
  square: {
    fontWeight: 700,
    width: typography.pxToRem(24),
    height: typography.pxToRem(24)
  },
  rectangle: {
    fontWeight: 600,
    width: typography.pxToRem(28),
    height: typography.pxToRem(24)
  }
}));

export default useStyles;
