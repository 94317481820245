import React from 'react';
import PropTypes from 'prop-types';

import { Button, Grid, Typography } from '@material-ui/core';

import { ReactComponent as IconClock } from 'images/ic-clock.svg';
import { formatNumber } from 'shared/formatters';
import CardIcon from 'shared/card-icon';
import {
  PACKAGES_LABEL,
  PACKAGES_LOADING_BTN,
  PACKAGES_BTN,
  PACKAGES_MESSAGE
} from './async-packages.constants';

function AsyncPackages({ count, loading, onUpdate }) {
  return (
    <CardIcon variant="secondary" icon={IconClock}>
      <Grid
        container
        spacing={6}
        data-testid="card-aggregation-content"
        direction="row"
        alignItems="center"
      >
        <Grid item xs>
          <Typography>
            <strong>{formatNumber(count)}</strong>
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <em>{PACKAGES_LABEL}</em>
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textSecondary" variant="body2">
            <em>{PACKAGES_MESSAGE}</em>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            data-testid="async-refresh-btn"
            color="primary"
            variant="outlined"
            onClick={onUpdate}
            disabled={loading}
          >
            {loading ? PACKAGES_LOADING_BTN : PACKAGES_BTN}
          </Button>
        </Grid>
      </Grid>
    </CardIcon>
  );
}

AsyncPackages.propTypes = {
  count: PropTypes.number.isRequired,
  onUpdate: PropTypes.func,
  loading: PropTypes.bool
};

AsyncPackages.defaultProps = {
  onUpdate: () => {},
  loading: false
};

export default AsyncPackages;
