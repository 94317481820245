import React from 'react';

import { useRouteMatch, Redirect, Switch, Route } from 'react-router-dom';

import { AUTHORIZED_ROUTES } from 'view/constants';

import { OperatorList } from 'users-management/operators';
import DriversManagement from 'users-management/drivers';

import UsersManagementProvider from './users-management.provider';

export default function UsersManagementContainer() {
  const { path, url } = useRouteMatch();

  return (
    <UsersManagementProvider>
      <Switch>
        <Route
          path={`${path}${AUTHORIZED_ROUTES.USERS_MANAGEMENT.OPERATORS}`}
          component={OperatorList}
        />
        <Route
          path={`${path}${AUTHORIZED_ROUTES.USERS_MANAGEMENT.DRIVERS}`}
          component={DriversManagement}
        />
        <Redirect
          to={{
            pathname: `${url}${AUTHORIZED_ROUTES.USERS_MANAGEMENT.OPERATORS}`
          }}
        />
      </Switch>
    </UsersManagementProvider>
  );
}
