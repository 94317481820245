import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import { ReactComponent as IconCloseLockGrey } from 'images/ic-cadeado-fechado-grey.svg';

import CardIcon from 'shared/card-icon';

import { formatValue, dateFormat } from 'finance/finance.configuration';

import { TEXT, BUTTON } from '../summary-values.constants';
import { InfoBlockLarge, InfoBlockMedium } from '../summary-values.component';

const SummaryValuesPreviousPeriods = ({
  firstPreviousDate,
  firstPreviousInfo,
  secondPreviousDate,
  secondPreviousInfo,
  sinceBeginningInfo,
  redirectPrevious
}) => (
  <CardIcon variant="secondary" icon={IconCloseLockGrey}>
    <Grid
      container
      justify="space-between"
      alignItems="flex-start"
      wrap="nowrap"
    >
      <Grid container spacing={5}>
        <Grid item>
          <InfoBlockLarge header={TEXT.PREVIOUSLY} />
        </Grid>
        <Grid item container spacing={10}>
          <Grid item>
            <InfoBlockMedium
              header={formatValue(firstPreviousInfo)}
              subHeader={`${dateFormat(firstPreviousDate.start)} à ${dateFormat(
                firstPreviousDate.end,
                true
              )}`}
            />
          </Grid>
          <Grid item>
            <InfoBlockMedium
              header={formatValue(secondPreviousInfo)}
              subHeader={`${dateFormat(
                secondPreviousDate.start
              )} à ${dateFormat(secondPreviousDate.end, true)}`}
            />
          </Grid>
          <Grid item xs={1}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <InfoBlockMedium
              header={formatValue(sinceBeginningInfo)}
              subHeader={TEXT.THIS_YEAR}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          size="medium"
          endIcon={<NavigateNextRoundedIcon fontSize="small" />}
          onClick={() => redirectPrevious()}
        >
          {BUTTON.PREVIOUSLY}
        </Button>
      </Grid>
    </Grid>
  </CardIcon>
);

const PreviousSkeleton = () => (
  <CardIcon variant="secondary" icon={IconCloseLockGrey}>
    <Grid item container spacing={5} data-testid="previous-skeleton">
      <Grid item>
        <Skeleton variant="rect" height={30} width={66} />
      </Grid>
      <Grid item container spacing={10}>
        <Grid item>
          <Skeleton variant="rect" height={27} width={83} />
          <br />
          <Skeleton variant="rect" height={21} width={68} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={27} width={83} />
          <br />
          <Skeleton variant="rect" height={21} width={68} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={27} width={83} />
          <br />
          <Skeleton variant="rect" height={21} width={68} />
        </Grid>
      </Grid>
    </Grid>
  </CardIcon>
);

SummaryValuesPreviousPeriods.propTypes = {
  firstPreviousDate: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }).isRequired,
  firstPreviousInfo: PropTypes.number.isRequired,
  secondPreviousDate: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }).isRequired,
  secondPreviousInfo: PropTypes.number.isRequired,
  sinceBeginningInfo: PropTypes.number.isRequired,
  redirectPrevious: PropTypes.func.isRequired
};

export { SummaryValuesPreviousPeriods, PreviousSkeleton };
