import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@material-ui/core';
import { colors } from '@loggi/mar';

import useStyles from 'shared/filter/filter.styles';
import { ReactComponent as BlueCheckIcon } from 'images/ic-check-blue.svg';

export default function AvatarListComponent({ handleChangeDriver, drivers }) {
  const classes = useStyles();
  const [currentDriver, setCurrentDriver] = useState(null);
  const handleChange = event => {
    setCurrentDriver(event.target.value);
    handleChangeDriver(event.target.value);
  };

  const parseDriverFullName = driverName => {
    return driverName.length > 30
      ? `${driverName.substring(0, 30)}...`
      : `${driverName}`;
  };

  return (
    <List className={classes.list} data-testid="avatar-list-form">
      <FormControl component="fieldset" fullWidth>
        <RadioGroup
          aria-label="driver-allocation"
          name="driver-allocation"
          onChange={handleChange}
          value={`${currentDriver}`}
        >
          {drivers.map(driver => {
            const isCurrentDriver = currentDriver === driver.driverOfferInfo.id;
            return (
              <ListItem
                key={`list-item-${driver.driverOfferInfo.id}`}
                button
                onClick={() => {
                  setCurrentDriver(driver.driverOfferInfo.id);
                  handleChangeDriver(driver.driverOfferInfo.id);
                }}
                style={{
                  borderLeft: `4px ${
                    isCurrentDriver ? colors.blue[500] : colors.root[0]
                  } solid`,
                  backgroundColor: isCurrentDriver
                    ? colors.blue[50]
                    : colors.root[0]
                }}
              >
                <ListItemIcon>
                  <FormControlLabel
                    value={`${driver.driverOfferInfo.id}`}
                    control={
                      <Radio
                        inputProps={{
                          'data-testid': `radio-button-${
                            driver.driverOfferInfo.id
                          }`
                        }}
                        checkedIcon={<BlueCheckIcon />}
                      />
                    }
                  />
                </ListItemIcon>
                <ListItemIcon key={`list-avatar-${driver.driverOfferInfo.id}`}>
                  <Avatar
                    alt={`Driver n°${driver.driverOfferInfo.id}`}
                    src={driver.driverPhoto}
                  />
                </ListItemIcon>
                <ListItemText
                  id={`label-${driver.driverOfferInfo.id}`}
                  primary={parseDriverFullName(driver.driverName)}
                />
              </ListItem>
            );
          })}
        </RadioGroup>
      </FormControl>
    </List>
  );
}

AvatarListComponent.propTypes = {
  handleChangeDriver: PropTypes.func.isRequired,
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      driverOfferInfo: PropTypes.shape({
        id: PropTypes.string,
        positionGeoPoint: {
          lat: PropTypes.number,
          lon: PropTypes.number
        },
        citySlug: PropTypes.string,
        availability: PropTypes.string,
        transportType: PropTypes.string,
        productVersions: PropTypes.arrayOf(PropTypes.string),
        hasPay: PropTypes.bool,
        hasBag: PropTypes.bool,
        hasGrandTrunk: PropTypes.bool,
        h3Cell: PropTypes.string,
        lmcIdentification: PropTypes.string,
        lmcRelation: PropTypes.string,
        availableForOffer: PropTypes.bool,
        relation: PropTypes.string,
        mobileToken: PropTypes.string,
        routeProduct: PropTypes.string
      }),
      driverName: PropTypes.string,
      driverPhoto: PropTypes.string
    })
  ).isRequired
};
