import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import formatPhone from 'shared/formatters/phone/phone';
import {
  CustomDrawer,
  DrawerHeader,
  DrawerActions,
  DrawerContent
} from 'shared/drawer';

import { ACTIONS } from 'auth/access-control/access-control.rules';
import OPERATOR_MODEL from 'users-management/operators/operators.model';

import {
  ROLES,
  STATUS_MAP,
  USER_STATUS_PENDING,
  INCOMPLETE_REGISTER
} from 'users-management/operators/operators.constants';

import TEXT from './operators-detail.constants';
import { constantToRole } from '../list/operators.configuration';

export default function OperatorDetailComponent({
  callbackEdit,
  callbackResend,
  callbackRevoke,
  detail,
  closeDrawer,
  isOpen
}) {
  const situation = () => {
    if (detail?.lastMileOperator?.userStatus === USER_STATUS_PENDING) {
      return 'PENDING';
    }
    if (detail?.lastMileOperator?.cpf) {
      return 'COMPLETE';
    }
    return 'INCOMPLETE';
  };
  const actions = [];

  if (situation() === 'PENDING') {
    actions.push({
      label: TEXT.RESEND_EMAIL,
      callback: () => {
        callbackResend(detail);
      }
    });
  }

  actions.push({
    label: TEXT.EDIT,
    callback: () => {
      callbackEdit(detail);
    }
  });

  actions.push({
    label: TEXT.REVOKE_ACCESS,
    callback: () => {
      callbackRevoke(detail);
    }
  });

  const statusMap = {
    PENDING: {
      type: 'chip',
      label: STATUS_MAP[(detail?.lastMileOperator?.userStatus)]
    },
    INCOMPLETE: {
      type: 'chip',
      label: INCOMPLETE_REGISTER
    }
  };

  return (
    <CustomDrawer open={isOpen}>
      <DrawerHeader
        detail={`${detail?.email} • ${formatPhone(detail?.mobileNumber)}`}
        showDivider
        status={statusMap[situation()] || {}}
        title={detail?.fullName}
        handleClosingDrawer={closeDrawer}
        colorText="textPrimary"
        avatar
        variantTextDetail="body2"
      />

      <DrawerContent showDivider>
        <Box pt={4} mb={1}>
          <Typography>{`${TEXT.TEXT_HAVE_ACCESS_TO} ${constantToRole(
            detail?.lastMileOperator?.role
          ).toLowerCase()}`}</Typography>
        </Box>
        <Box pb={4}>
          <Typography color="textSecondary" variant="body2">
            {ROLES[(detail?.lastMileOperator?.role)]?.DESCRIPTION}
          </Typography>
        </Box>
      </DrawerContent>
      <DrawerActions
        actions={actions}
        canActions={[ACTIONS.USER_MANAGEMENT_BASE_WRITE]}
        showDivider
      />
    </CustomDrawer>
  );
}

OperatorDetailComponent.propTypes = {
  callbackEdit: PropTypes.func.isRequired,
  callbackResend: PropTypes.func.isRequired,
  callbackRevoke: PropTypes.func.isRequired,
  detail: OPERATOR_MODEL.isRequired,
  closeDrawer: PropTypes.func,
  isOpen: PropTypes.bool
};

OperatorDetailComponent.defaultProps = {
  closeDrawer: () => {},
  isOpen: true
};
