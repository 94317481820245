import React from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import CardComponent from './card.component';
import CardAggregationComponent from './card-aggregation.component';
import useStyles from './card-aggregation.styles';
import {
  dataLabelsPackages,
  dataLabelsDeliveres,
  PATH_AGGREGATION_DELIVERER
} from './constants';

export default function CardAggregationContainer({
  dataResponse,
  configGroupPackages,
  configPackages
}) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const handleNextPage = path => {
    if (configGroupPackages) {
      history.push(`${location.pathname}/${path}`);
    } else {
      const rc = getSelectedRoutingCode();
      const _path = `/${rc}/${PATH_AGGREGATION_DELIVERER}`;
      history.push(_path);
    }
  };

  const newDataResponse = configPackages
    ? dataResponse
    : {
        totalInStreetPackages: dataResponse?.totalInStreetDrivers || 0,
        totalUnsuccessfulPackages:
          dataResponse?.totalDeliverersUnsuccessfulPackages || 0,
        totalDelayedPackages: dataResponse?.totalDeliverersDelayedPackages || 0,
        totalForTodayPackages:
          dataResponse?.totalDeliverersForTodayPackages || 0
      };

  return (
    <>
      {configGroupPackages ? (
        <CardAggregationComponent
          dataResponse={newDataResponse}
          handleNextPage={handleNextPage}
          configPackages={configPackages}
          dataLabels={configPackages ? dataLabelsPackages : dataLabelsDeliveres}
        />
      ) : (
        <CardComponent
          handleNextPage={handleNextPage}
          countPackages={dataResponse.totalInStreetDrivers}
          classes={classes}
        />
      )}
    </>
  );
}

CardAggregationContainer.propTypes = {
  dataResponse: PropTypes.shape({
    title: PropTypes.string,
    totalInStreetPackages: PropTypes.number,
    totalWithoutTriesPackages: PropTypes.number,
    totalAheadOfSchedulePackages: PropTypes.number,
    totalDelayedPackages: PropTypes.number,
    totalUnsuccessfulPackages: PropTypes.number,
    totalForTodayPackages: PropTypes.number,
    totalInStreetDrivers: PropTypes.number,
    // Count Summary reference Delivery
    totalDeliverersUnsuccessfulPackages: PropTypes.number,
    totalDeliverersDelayedPackages: PropTypes.number,
    totalDeliverersForTodayPackages: PropTypes.number
  }).isRequired,
  configGroupPackages: PropTypes.bool,
  configPackages: PropTypes.bool
};

CardAggregationContainer.defaultProps = {
  configGroupPackages: false,
  configPackages: true
};
