import * as Sentry from '@sentry/browser';

const dsn = process.env.REACT_APP_DSN_SENTRY;
const env = process.env.REACT_APP_SENTRY_ENV;

// Function that looks at navigator userAgent and use a regex to identify the os/model of the device being used
const setCurrentDevice = () => {
  const device = 'Unknown';

  // this block is commented for now because on Windows this step is breaking the application, we are investigating how
  // we can fix this and we will open a new PR uncommenting this step and fixing it.

  // if (navigator && navigator.userAgent) {
  //   /*
  //     The regex just fetches the first match between () as far as I understood about userAgent this is the reference to os/model
  //    */

  //   [, device] = navigator.userAgent.match(/\((\w+;.*?)\)\s/);
  // }

  Sentry.configureScope(scope => {
    scope.setTag('custom_device', device);
  });
};

if (env) {
  Sentry.init({
    dsn,
    environment: env,
    release: `arco-v${process.env.REACT_APP_ARCO_VERSION}`,
    integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })]
  });

  setCurrentDevice();
}
