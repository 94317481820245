import { STATUS } from '@loggi/components/src/arco/alert-status';
import { STATUS_PLATFORM_LOCAL_STORAGE_KEY } from 'view/constants';

const isPlatformStatusDegraded = () => {
  return (
    localStorage.getItem(STATUS_PLATFORM_LOCAL_STORAGE_KEY) ===
    STATUS.DEGRADED_PERFORMANCE
  );
};

export default isPlatformStatusDegraded;
