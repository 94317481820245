import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import useStyles from './configurable-table.styles';
import { get } from '../finance.configuration';
import { PackageDrawer } from '../../view/molecules/package-drawer/drawer.container';
import { FeatureSwitchContext } from '../../infra/firebase/feature-switch-provider';
import DrawerContainer from '../drawer/activity-info/drawer.container';

export default function ConfigurableTable(props) {
  const { data, columns } = props;

  const hasMissedEvent =
    data
      .map(item => {
        return get(item, 'missedEvent', null);
      })
      .filter(Boolean).length > 0;

  const newColumns = { ...columns };
  if (!hasMissedEvent) {
    delete newColumns.missedEventsBox;
  }

  const keysColumns = Object.keys(newColumns);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const classes = useStyles();
  const [styleSelectRow, setStyleSelectRow] = useState(-1);
  const { enableActivityDrawer } = useContext(FeatureSwitchContext);

  const TableHeadMaker = () => {
    const headerList = Object.entries(newColumns).map(
      ([key, { header, headAlign }]) => {
        return (
          <TableCell
            align={headAlign}
            key={key}
            data-testid={`table-header-${key}`}
          >
            <Typography variant="body2">{header}</Typography>
          </TableCell>
        );
      }
    );
    return (
      <TableHead className={classes.tableHead}>
        <TableRow>{headerList}</TableRow>
      </TableHead>
    );
  };

  const TableBodyMaker = () => {
    const _elementTable = (dataRow, columnsName) => {
      if (columnsName === 'regionName') {
        return columns[columnsName].component(dataRow).split(':')[0];
      }
      if (columnsName in dataRow) {
        return dataRow[columnsName];
      }
      if ('component' in columns[columnsName]) {
        return columns[columnsName].component(dataRow);
      }
      return '-';
    };
    const _handleOnClickRow = dataRow => {
      const newData = {
        ...dataRow,
        packageId: dataRow.package.id,
        loggiKey: dataRow.package.loggiKey
      };
      setSelectedPackage(newData);
      setOpenDrawer(true);
      setStyleSelectRow(newData.packageCharge.id);
    };

    const getTableRows = dataRow => {
      const key = dataRow.id || dataRow.packageCharge.id;
      return (
        <TableRow key={key} hover>
          {keysColumns.map((columnsName, index) => {
            const propsStyle = columns[columnsName].propsStyleBody;
            const keyConcat = `${key}${index}`;
            return (
              <TableCell
                className={`
                  ${classes.dataRow}
                  ${
                    key === styleSelectRow && openDrawer
                      ? classes.rowSelected
                      : ''
                  }
                `}
                component="th"
                scope="row"
                onClick={() => _handleOnClickRow(dataRow)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...propsStyle}
                key={keyConcat}
                data-testid={keyConcat}
              >
                {_elementTable(dataRow, columnsName)}
              </TableCell>
            );
          })}
        </TableRow>
      );
    };

    const tableBodyRows = data.map(item => {
      return getTableRows(item);
    });

    return <TableBody>{tableBodyRows}</TableBody>;
  };

  return (
    <>
      {openDrawer && !enableActivityDrawer && (
        <PackageDrawer
          setOpen={setOpenDrawer}
          selectedPackage={selectedPackage}
        />
      )}
      {openDrawer && enableActivityDrawer && (
        <DrawerContainer
          handleOpenDrawer={setOpenDrawer}
          packageCharge={selectedPackage}
        />
      )}
      <Table size="small">
        <TableHeadMaker />
        <TableBodyMaker />
      </Table>
    </>
  );
}

ConfigurableTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  columns: PropTypes.objectOf(
    PropTypes.shape({
      header: PropTypes.string,
      headAlign: PropTypes.string,
      propsStyleBody: PropTypes.shape,
      component: PropTypes.element
    })
  ).isRequired
};

ConfigurableTable.displayName = 'ConfigurableTable';
