import React from 'react';
import { Box, Typography, IconButton, Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { Skeleton } from '@material-ui/lab';
import { AUTHORIZED_ROUTES } from 'view/constants';
import { HEADER } from './constants';

export default function HeaderSummaryComponent({
  loading,
  history,
  data,
  errorFetchDelivererInfo,
  subView
}) {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      display="flex"
      justify="space-between"
    >
      <Grid item xs>
        <Box display="flex" alignItems="center">
          {loading ? (
            <Skeleton height={60} width="40%" animation="wave" />
          ) : (
            <>
              <Box display="flex" alignItems="center" mr={1}>
                <IconButton
                  size="small"
                  edge="start"
                  onClick={() => {
                    const rc = getSelectedRoutingCode();
                    const path = `/${rc}/na-rua${
                      AUTHORIZED_ROUTES.INFORMATION.COLLECTION
                    }/entregadores${subView ? `/${subView}` : ''}`;
                    history.push(path);
                  }}
                >
                  <Box
                    color="primary.contrastText"
                    display="flex"
                    alignItems="center"
                  >
                    <ArrowBackRoundedIcon data-testid="back-base-button" />
                  </Box>
                </IconButton>
              </Box>
              <Box ml={1}>
                <Typography variant="h4">
                  <strong>{HEADER.PKGS_WITH_DELIVERER}</strong>
                </Typography>
              </Box>
            </>
          )}
        </Box>
        {loading ? (
          <Skeleton height={60} width="20%" animation="wave" />
        ) : (
          <Box mt={2}>
            {!errorFetchDelivererInfo && (
              <Typography variant="h4">{HEADER.subHeader(data)}</Typography>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

HeaderSummaryComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  errorFetchDelivererInfo: PropTypes.bool.isRequired,
  subView: PropTypes.string.isRequired
};
