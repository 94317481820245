export const TEXT = {
  TITLE: 'Distribuir',
  STAFF: 'Sua equipe',
  DESCRIPTION: 'Escolha um entregador para criar uma lista de entregas.',
  NO_DELIVERERS: 'Não há entregadores cadastrados.',
  BUTTON_TEXT: 'Adicionar Pacote'
};

export function ColumnsConfig(buttonFunc, mobileNumberFunc) {
  const columns = {
    fullName: {
      header: 'Nome',
      headAlign: 'left',
      propsStyleBody: { align: 'left' }
    },
    mobile: {
      header: 'Celular',
      headAlign: 'center',
      propsStyleBody: { align: 'center' },
      component: mobileNumberFunc
    },
    packageCount: {
      header: 'Pacotes na Lista',
      headAlign: 'center',
      propsStyleBody: { align: 'center' }
    },
    button: {
      header: ' ',
      headAlign: 'center',
      propsStyleBody: { align: 'center' },
      component: buttonFunc
    }
  };
  return columns;
}
