import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from 'images/logo.svg';
import MaskedInput, { PHONE, CPF } from 'shared/masked-input';

import {
  emailIsValid,
  nameIsValid,
  isCpfValid,
  phoneIsValid
} from 'shared/validators';
import {
  TEXT,
  BUTTON,
  PLACE_HOLDER,
  LINK_URL_ARCO,
  REGEX
} from './signup.constants';
import { useStyles } from './signup.styles';

export default function SignupComponent({ onSignup, loading }) {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    mobile_phone: '',
    cpf: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const validateField = (validation, field) => {
    setFormErrors(_errors => {
      return {
        ..._errors,
        [field]: !validation(formData[field])
      };
    });
  };

  const handleTyping = (value, field) => {
    setFormData(_formData => {
      const valueReplace = value.replace(REGEX.CLEAN_NON_NUMERIC, '');

      const shouldCleanValue = ['mobile_phone', 'cpf'].indexOf(field) >= 0;

      return {
        ..._formData,
        [field]: shouldCleanValue ? valueReplace : value
      };
    });
  };

  const onSubmitForm = event => {
    if (event) event.preventDefault();
    if (
      nameIsValid(formData.full_name) &&
      emailIsValid(formData.email) &&
      phoneIsValid(formData.mobile_phone) &&
      isCpfValid(formData.cpf)
    ) {
      onSignup(formData);
    } else {
      showSnackbar({
        message: TEXT.FORM_INCOMPLETE,
        variant: 'error',
        enqueueSnackbar
      });
    }
  };

  const hasAnyErrors = () => {
    return !!(
      formErrors.full_name ||
      formErrors.email ||
      formErrors.phone ||
      formErrors.cpf
    );
  };

  return (
    <Grid container className={classes.grid} data-testid="signupform">
      <Grid item xs={12} sm={12} md={6} lg={6} className={classes.root}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.alignLogo}
        >
          <Link
            href={LINK_URL_ARCO}
            target="_blank"
            color="inherit"
            style={{ width: '430px', textAlign: 'left' }}
          >
            <Logo style={{ width: '100px', height: '100px' }} />
          </Link>
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.grid}
        >
          <Box style={{ maxWidth: '430px' }}>
            <Box marginBottom={3}>
              <Typography variant="h2" component="span">
                {TEXT.WELCOME.TITLE}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" component="span">
                {TEXT.WELCOME.CONTAINER}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid style={{ maxWidth: '430px' }}>
            <Box marginBottom={3.5}>
              <Typography variant="h4" component="h1">
                {TEXT.TITLE_FORM}
              </Typography>
            </Box>
            <Box>
              <form>
                <Box>
                  <TextField
                    margin="normal"
                    disabled={loading}
                    onBlur={() => validateField(nameIsValid, 'full_name')}
                    className={classes.textfield}
                    variant="outlined"
                    label={PLACE_HOLDER.FULL_NAME}
                    onChange={e => handleTyping(e.target.value, 'full_name')}
                    defaultValue={formData.full_name}
                    inputProps={{ 'data-testid': 'full_name_Input' }}
                    error={formErrors.full_name}
                    helperText={formErrors.full_name && TEXT.INVALID_NAME}
                  />
                  <MaskedInput
                    disabled={loading}
                    isValid={!formErrors.cpf}
                    onBlur={() => validateField(isCpfValid, 'cpf')}
                    onChange={e => {
                      handleTyping(e.target.value, 'cpf');
                    }}
                    value={formData.cpf}
                    helperText={CPF.ERROR}
                    label={CPF.LABEL}
                    mask={CPF.MASK}
                    testId="cpf"
                  />
                  <MaskedInput
                    disabled={loading}
                    isValid={!formErrors.mobile_phone}
                    onBlur={() => validateField(phoneIsValid, 'mobile_phone')}
                    onChange={e => {
                      handleTyping(e.target.value, 'mobile_phone');
                    }}
                    value={formData.mobile_phone}
                    helperText={PHONE.ERROR}
                    label={PHONE.LABEL}
                    mask={PHONE.MASK}
                    testId="signup_mobile_phoneInput"
                  />
                  <TextField
                    margin="normal"
                    className={classes.textfield}
                    disabled={loading}
                    variant="outlined"
                    onBlur={() => validateField(emailIsValid, 'email')}
                    label={PLACE_HOLDER.EMAIL}
                    onChange={e => handleTyping(e.target.value, 'email')}
                    defaultValue={formData?.email}
                    inputProps={{ 'data-testid': 'signup_emailInput' }}
                    error={formErrors.email}
                    helperText={formErrors.email && TEXT.INVALID_EMAIL}
                  />
                </Box>
                <Box mt={3.5} textAlign="center">
                  <Button
                    className={classes.button}
                    disabled={loading || hasAnyErrors()}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={e => onSubmitForm(e)}
                    type="submit"
                    data-testid="btn_submit"
                  >
                    {loading ? BUTTON.SUBMITTING : BUTTON.SUBMIT}
                  </Button>
                </Box>
              </form>
            </Box>
            <Box marginTop={5} className={classes.boxSpan}>
              <Typography
                variant="body2"
                component="span"
                align="center"
                className={classes.span}
              >
                {TEXT.ALREADY_REGISTERED}
                <Link
                  href={LINK_URL_ARCO}
                  variant="body2"
                  className={classes.link}
                >
                  {TEXT.IN_HERE}
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SignupComponent.propTypes = {
  onSignup: PropTypes.func,
  loading: PropTypes.bool.isRequired
};
SignupComponent.defaultProps = {
  onSignup: () => {}
};
