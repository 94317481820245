import formatPhone from 'shared/formatters/phone/phone';
import { noWrapDateRelativeTime } from 'shared/configurable-table/configuration/leve-v2';

// https://github.com/loggi/xproto/blob/master/loggi/loggi_web/v1/transport_type.proto#L17
export const TransportType = {
  1: 'Moto',
  2: 'Bicicleta',
  3: 'Van',
  4: 'Carro'
};

// Header Summary.component
export const HEADER = {
  PKGS_WITH_DELIVERER: 'Pacotes com o entregador',
  subHeader: data =>
    `${data?.percentageProgress}% • Entregou ${data?.deliveredPackages} de ${
      data?.totalPackages
    }`
};

// Deliverer Info.component
export const INFOS = {
  UPDATE_LABEL: 'Atualização',
  updateFormat: data =>
    noWrapDateRelativeTime(data, 'lastUpdatedPackageDateTime'),
  modal: data =>
    `Está de ${TransportType[data.modal] || 'modal.empty'} • ${formatPhone(
      data?.phoneNumber
    )}`,
  // LEVE
  CREATED_LABEL: 'Atua desde',
  CIRCLES_LABEL: 'Círculos',
  createdFormat: data => data.created,
  circlesFormat: data => data.map(i => i.name).join(', '),
  // AGENCY
  ROUTES_FINISHED: 'Rotas concluídas'
};
