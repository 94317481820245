import 'moment/locale/pt-br';

export const KEY_CUSTODY = {
  TRANSFERIR: 'returning',
  NA_RUA: 'na-rua',
  NA_BASE: 'na-base',
  NA_BASE_GRUPOS: 'na-base/grupos',
  NA_BASE_PACOTES: 'na-base/pacotes',
  NA_BASE_HOJE: 'na-base/colecao/hoje',
  NA_BASE_AMANHA: 'na-base/colecao/amanha',
  NA_BASE_ATRASADO: 'na-base/colecao/atrasado',
  NA_BASE_DEPOIS: 'na-base/colecao/depois',
  NA_BASE_COM_FALTA: 'na-base/colecao/com-falta',
  NA_BASE_PROBLEMA_LOCALIZACAO: 'na-base/colecao/problema-localizacao',
  NA_BASE_RECEBIMENTO: 'na-base/colecao/recebimento',
  NA_BASE_SEM_PRAZO: 'na-base/colecao/sem-prazo',
  NA_BASE_AGUARDANDO_TRATATIVA: 'na-base/colecao/aguardando-tratativa',
  NA_BASE_EM_RISCO: 'na-base/colecao/chance-de-extravio',
  RESULTADO: 'resultado',
  FINALIZADOS: 'finalizados',
  TO_TRANSFER: 'to-transfer'
};

export const SEP_CONTEXT = 'SEP';

export const TOGGLE = {
  IN_GROUPS: 'Em grupos',
  ALL_PACKAGES: 'Todos os pacotes',
  VALUE_GROUPS: 'groups',
  VALUE_PACKAGES: 'packages',
  TITLE_GROUPS: {
    [KEY_CUSTODY.NA_BASE]: 'Grupos',
    [KEY_CUSTODY.NA_RUA]: {
      LAST_MILE_COMPANY_TYPE_LEVE: 'Seus entregadores',
      LAST_MILE_COMPANY_TYPE_LOGGI: 'Entregadores'
    }
  },
  TITLE_PACKAGES: 'Pacotes'
};

export const VIEWS = {
  TRANSFERIR: 'returning',
  NA_BASE: 'base',
  NA_BASE_HOJE: 'base_today',
  NA_BASE_AMANHA: 'base_tomorrow',
  NA_BASE_ATRASADO: 'base_delayed',
  NA_BASE_DEPOIS: 'base_after_tomorrow',
  NA_BASE_COM_FALTA: 'base_faulted',
  NA_BASE_PROBLEMA_LOCALIZACAO: 'base_suspicious_geocoding',
  NA_BASE_SEM_PRAZO: 'base_no_deadline',
  NA_BASE_EM_RISCO: 'base_at_risk',
  NA_BASE_AGUARDANDO_TRATATIVA: 'base_awaiting_resolution',
  NA_BASE_RECEBIMENTO: 'base_receivement',
  NA_RUA: 'delivery',
  ENTREGUES: 'delivered',
  FINALIZADOS: 'finished',
  PICKUP: 'pickup',
  TO_TRANSFER: 'to_transfer'
};

export const AGG_FIELDS = {
  PACKAGE_STATE: 'package_state',
  PERIOD: 'by_period',
  PERIOD_LSD: 'by_period_lsd',
  STATUS_CODE: 'status_code',
  STATUS_CODE_IN_DELIVERY: 'status_in_delivery',
  STATUS: 'status',
  MISSED_EVENTS: 'total_missed_events',
  DELIVERER: 'deliverer',
  PICKUP_VIEW: 'pickup_view'
};

export const DATE_FILTER = {
  SORTING_RECORD_CREATED: 'last_breadcrumbs_sorting_record.created'
};

export const SUMMARY = {
  PACKAGE: 'pacote',
  NONE: 'Nenhum',
  RANGE_DATE: 'Entre os dias',
  RESULT: 'resultado',
  FOUND: 'encontrado',
  DOWNLOAD_REPORT: 'Baixar relatório',
  GROUPING: 'Agrupar',
  GO_TO_BASE: 'Ir para base',
  GO_TO_TODAY: 'Ir para hoje',
  EMPTY_PACKAGE_DEFAULT:
    'Ops... Não foi possível buscar as informações. Por favor tente mais tarde.',
  WITH_PENDING: 'Com pendência'
};

export const DEFAULT_OPTION_PAGE = {
  numberPages: 1,
  totalItems: 1,
  paginationParams: {
    itemsPerPage: 15,
    page: 1
  }
};

export const TEXT_ALERT_STATUS_HEADER = {
  LIST_PACKAGES: {
    PRIMARY: 'Algumas informações da lista podem estar desatualizadas',
    SECONDARY: 'Continue usando a plataforma. Vamos atualizar em breve.'
  },
  AGGREGATION_IN_STREET: {
    PRIMARY: 'Indicadores temporariamente indisponíveis',
    SECONDARY:
      'Continue distribuindo os pacotes normalmente. Vamos atualizar em breve.'
  },
  AGGREGATION_NEW_BASE: {
    PRIMARY: 'Algumas informações podem estar desatualizadas.',
    SECONDARY: 'Continue usando a plataforma. Vamos atualizar em breve.',
    WITH_READ_PRIMARY:
      'Algumas informações podem estar desatualizadas. Continue recebendo os pacotes normalmente.',
    WITH_READ_SECONDARY: 'Vamos atualizar em breve.'
  },
  AGGREGATION_FINISHED: {
    PRIMARY: 'Indicadores temporariamente indisponíveis',
    SECONDARY:
      'As entregas podem continuar normalmente. Vamos atualizar em breve.'
  }
};
