import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { HEADER_TEXT } from '../drawer-group-packages.constants';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
    borderRadius: '100px solid #F5F8FA',
    border: '1px solid #F5F8FA',
    display: 'flex',
    alignItems: 'center',
    height: '2.5rem',
    color: theme.palette.text.primary,
    fontWeight: '600'
  },
  deleteIcon: {
    color: theme.palette.text.primary
  }
}));

export default function KeywordInput({ keywords, setKeywords }) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');

  const handleKeyPress = (event, onBlur) => {
    if (event.key === 'Enter' || event.key === ',' || onBlur) {
      const inputValueTrimmed = event.target.value.trim().replace(/,$/, '');

      const newKeywordsArray = [...keywords];
      const duplicatedValues = newKeywordsArray.indexOf(inputValueTrimmed);

      if (duplicatedValues !== -1 || !inputValueTrimmed.length) {
        setInputValue('');
        return;
      }

      setInputValue('');
      newKeywordsArray.push(inputValueTrimmed);
      setKeywords(newKeywordsArray);
    }
    if (keywords.length && !inputValue.length && event.key === 'Backspace') {
      setKeywords(keywords.slice(0, keywords.length - 1));
    }
  };

  const handleBlur = event => {
    handleKeyPress(event, true);
  };

  const handleDelete = item => {
    setKeywords(keywords.filter(keyword => keyword !== item));
  };

  const handleInputChange = event => {
    if (event.target.value.length <= 16) {
      setInputValue(event.target.value);
    }
  };

  return (
    <Box>
      <TextField
        value={inputValue}
        variant="outlined"
        placeholder={HEADER_TEXT.keywords_placeholder}
        fullWidth
        label={HEADER_TEXT.keywords}
        onChange={handleInputChange}
        onKeyPress={event => handleKeyPress(event, false)}
        onBlur={handleBlur}
        disabled={keywords.length >= 2}
        InputProps={{
          label: HEADER_TEXT.keywords,
          maxLength: 16,
          startAdornment: keywords.map(item => (
            <Chip
              key={item}
              tabIndex={-1}
              label={item}
              className={classes.chip}
              onDelete={() => handleDelete(item)}
              deleteIcon={
                <CancelIcon
                  data-testid="delete-icon"
                  className={classes.deleteIcon}
                />
              }
            />
          ))
        }}
      />
    </Box>
  );
}

KeywordInput.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  setKeywords: PropTypes.func.isRequired
};
