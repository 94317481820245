import api from 'infra/api/leve';
import { URL_API_V1_DISPUTES_PREFIX } from 'infra/service/constants';

const getDisputeProtocol = async disputeId => {
  return api
    .get(`${URL_API_V1_DISPUTES_PREFIX}/protocol/${disputeId}`)
    .then(response => response.data);
};

export default getDisputeProtocol;
