import api from 'infra/api/leve';
import db from 'infra/storage/async-elasticsearch-database';
import { packageCacheModel } from 'infra/storage/async-elasticsearch-database.model';
import { URL_PROXY_V2_PREFIX } from 'infra/service/constants';
import { parseRequestError } from 'app/httpMessage';

export const CUSTOM_ERROR_MESSAGES = {
  400: 'Algo deu errado e não foi possível alterar o status. Tente de novo daqui a pouco ou atualize a página.',
  403: 'Eita, você não consegue fazer essa alteração de status. Fale com o administrador para ter acesso.',
  404: 'Não foi possível encontrar o pacote no sistema para atualizar o status. Tente de novo daqui a pouco ou atualize a página.'
};

const updateStatusPackageService = data => {
  // the backend receveis an array of packageids, but this view only send a one id
  const body = {
    packageIds: [data.packageId],
    newPackageStatus: data.newPackageStatus,
    longitude: data.longitude,
    latitude: data.latitude,
    recipientName: data.recipientName,
    recipientDocument: data.recipientDocument
  };
  return api
    .post(`${URL_PROXY_V2_PREFIX}/packages/status-update`, body)
    .then(response => {
      const {
        packageId,
        sortingRecordId,
        licensePlate,
        sortingContextLicensePlate,
        taskId
      } = response.data.packagesInfo[0];
      db.savePackages([
        packageCacheModel(
          packageId,
          sortingRecordId,
          licensePlate,
          sortingContextLicensePlate === '' || !sortingContextLicensePlate
            ? 'DELIVERY'
            : sortingContextLicensePlate,
          taskId
        )
      ]);
      return response;
    })
    .catch(parseRequestError(CUSTOM_ERROR_MESSAGES));
};

export default updateStatusPackageService;
