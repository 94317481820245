import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import ConfigurableTable from 'view/molecules/configurable-table';
import BarcodeReader from 'view/atoms/barcode-reader';
import NotFound404 from 'view/pages/not-found-404';
import { TEXT_TEMPLATE, columnsConfigPackages, useStyles } from './constants';

export default function DelivererPackageAssociation(props) {
  const {
    loading,
    deliverer,
    filteredPackages,
    onMovePackageList,
    finishListShare,
    pagination,
    changePagination,
    changeReorder
  } = props;

  const { button } = useStyles();

  const { page, itemsPerPage } = pagination;

  if (!deliverer.isDriver) {
    return <NotFound404 />;
  }

  return (
    <Container>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Box p={2}>
            <Typography variant="h3">{deliverer.fullName}</Typography>
          </Box>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            <Box p={2}>
              <Typography variant="h5" gutterBottom>
                {pagination.numberItems} {TEXT_TEMPLATE.SUB_TITLE}
              </Typography>
              <Typography color="textSecondary">
                {TEXT_TEMPLATE.DESCRIPTION}
                {deliverer.fullName}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box p={2}>
              <BarcodeReader
                onRead={onMovePackageList}
                placeholder={TEXT_TEMPLATE.BARCODE}
              />
            </Box>
          </Grid>
        </Grid>
        {loading && (
          <Grid container justify="center">
            <Box p={2}>
              <CircularProgress data-testid="loading" justify="center" />
            </Box>
          </Grid>
        )}
        {!loading && filteredPackages.length === 0 && (
          <Grid item xs={12}>
            <Box textAlign="center">
              <Typography fontStyle="italic">
                {TEXT_TEMPLATE.NO_PACKAGES}
              </Typography>
            </Box>
          </Grid>
        )}

        {!loading && filteredPackages.length > 0 && (
          <Grid item xs={12}>
            <ConfigurableTable
              data-testid="table-deliverer"
              data={filteredPackages}
              columns={columnsConfigPackages()}
              renderFooter
              pagination={pagination}
              paginationParams={{ page, itemsPerPage }}
              onChangePage={(eventClick, pageParms) =>
                changePagination(pageParms)
              }
              onReorder={changeReorder}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            onClick={finishListShare}
            className={button}
          >
            {TEXT_TEMPLATE.SHARE_LIST}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

DelivererPackageAssociation.defaultProps = {
  changeReorder: () => {
    // avoiding codesmell
  }
};

DelivererPackageAssociation.propTypes = {
  loading: PropTypes.bool.isRequired,
  deliverer: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    isDriver: PropTypes.bool
  }).isRequired,
  filteredPackages: PropTypes.arrayOf(
    PropTypes.shape({
      packageId: PropTypes.string,
      seal: PropTypes.string,
      barcode: PropTypes.string,
      assignedTo: PropTypes.string,
      status: {
        code: PropTypes.number,
        description: PropTypes.string,
        updated: PropTypes.string
      },
      companyName: PropTypes.string,
      recipient: {
        name: PropTypes.string,
        phone: PropTypes.string
      },
      destination: {
        addressStreet: PropTypes.string,
        addressNumber: PropTypes.string,
        addressComplement: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        vicinity: PropTypes.string,
        zipCode: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number
      }
    })
  ).isRequired,
  onMovePackageList: PropTypes.func.isRequired,
  finishListShare: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    numberPages: PropTypes.number.isRequired,
    numberItems: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired
  }).isRequired,
  changePagination: PropTypes.func.isRequired,
  changeReorder: PropTypes.func
};
