import {
  TEXT,
  HIERARCHICAL_FALLBACK_LEVEL_UNUSED_VALUE
} from 'view/molecules/package-drawer/drawer.constants';
import { assign, Machine, sendParent } from 'xstate';
import { translateStatusCode } from 'app/httpMessage';
import {
  ADDRESS_TYPES,
  DIALOG,
  DIALOG_CASES,
  CUSTOM_MESSAGE
} from './drawer-update-address.constants';
import updatePackageAddressService from './drawer-update-address.service';

export const STATES = {
  EDITING: 'editing',
  CONFIRMING: 'confirming',
  SUBMITTING: 'submittingUpdatedAddress',
  DIALOG_CONFIRM_WITHOUT_CHANGE_MAP: 'dialogConfirmWithouChangeMap',
  DIALOG_CONFIRM_WITHOUT_CHANGE_POSTAL_ADDRESS:
    'DialogConfirmWithouChangePostalAddress'
};

export const ACTIONS = {
  SELECT_ADDRESS_TYPE: 'selectAddressType',
  EDIT_POSTAL_ADDRESS: 'editPostalAddress',
  EDIT_COORDINATES_ADDRESS: 'editCoordinatesAddress',
  BACK: 'backToDetail',
  SET_NOTIFICATION: 'setNotification',
  RELOAD_PAGE: 'reloadPage',
  SUBMIT: 'submit',
  CONFIRM: 'confirm',
  CONFIRM_BACK: 'confirmationBackToDetail',
  CANCEL: 'cancel',
  PARENT_TRY_CANCEL: 'parentTryCancel'
};

const compareArrays = (arrayBase = [], arrayToCompare = []) =>
  Array.isArray(arrayBase) &&
  Array.isArray(arrayToCompare) &&
  arrayBase.length === arrayToCompare.length &&
  arrayBase.every((item, index) => item === arrayToCompare[index]);

function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.reduce((result, currentKey) => {
    const value1 = object1[currentKey];
    const value2 = object2[currentKey];

    const isArrayEqual = compareArrays(value1, value2);
    const isPrimitiveEqual = String(value1) === String(value2);

    return result && (isArrayEqual || isPrimitiveEqual);
  }, true);
}

const isLoggiAddressChanged = (loggiAddress, newLoggiAddress) =>
  !shallowEqual(loggiAddress.postalAddress, newLoggiAddress.postalAddress) ||
  !shallowEqual(loggiAddress.coordinates, newLoggiAddress.coordinates);

const updateAddressMachine = Machine({
  id: 'updateAddress',
  initial: STATES.EDITING,
  context: {
    selectedAddressType: ADDRESS_TYPES.loggi,
    isEnableSubmit: false
  },
  states: {
    [STATES.EDITING]: {
      on: {
        [ACTIONS.EDIT_COORDINATES_ADDRESS]: {
          actions: assign({
            coordinates: (_, event) => event.value,
            isEnableSubmit: (
              { packageInfo: { loggiAddress }, postalAddress },
              event
            ) =>
              isLoggiAddressChanged(loggiAddress, {
                ...loggiAddress,
                postalAddress: postalAddress ?? loggiAddress.postalAddress,
                coordinates: event.value
              })
          })
        },
        [ACTIONS.EDIT_POSTAL_ADDRESS]: {
          actions: assign({
            postalAddress: (_, event) => event.value,
            isEnableSubmit: (
              { packageInfo: { loggiAddress }, coordinates },
              event
            ) =>
              isLoggiAddressChanged(loggiAddress, {
                ...loggiAddress,
                postalAddress: event.value,
                coordinates: coordinates ?? loggiAddress.coordinates
              })
          })
        },
        [ACTIONS.SELECT_ADDRESS_TYPE]: {
          actions: assign({
            selectedAddressType: (_, event) => event.value
          })
        },
        [ACTIONS.SUBMIT]: [
          {
            target: STATES.DIALOG_CONFIRM_WITHOUT_CHANGE_MAP,
            cond: ({ packageInfo: { loggiAddress }, coordinates }) =>
              shallowEqual(
                loggiAddress.coordinates,
                coordinates ?? loggiAddress.coordinates
              )
          },
          {
            target: STATES.DIALOG_CONFIRM_WITHOUT_CHANGE_POSTAL_ADDRESS,
            cond: ({ packageInfo: { loggiAddress }, postalAddress }) =>
              shallowEqual(
                loggiAddress.postalAddress,
                postalAddress ?? loggiAddress.postalAddress
              )
          },
          {
            target: STATES.SUBMITTING,
            cond: () => true
          }
        ],
        [ACTIONS.PARENT_TRY_CANCEL]: {
          actions: assign({
            isCancel: true,
            dialogTitle: DIALOG[DIALOG_CASES.CANCEL].TITLE,
            dialogDescription: ({
              packageInfo: { loggiAddress },
              postalAddress,
              coordinates
            }) => {
              const diffCoordinates = shallowEqual(
                loggiAddress.coordinates,
                coordinates ?? loggiAddress.coordinates
              );
              const diffPostalAddress = shallowEqual(
                loggiAddress.postalAddress,
                postalAddress ?? loggiAddress.postalAddress
              );

              if (diffPostalAddress && !diffCoordinates)
                return DIALOG[DIALOG_CASES.CANCEL_COORDINATES].DESCRIPTION;

              if (!diffPostalAddress && diffCoordinates)
                return DIALOG[DIALOG_CASES.CANCEL_POSTAL_ADDRESS].DESCRIPTION;

              return DIALOG[DIALOG_CASES.CANCEL].DESCRIPTION;
            }
          })
        },
        [ACTIONS.BACK]: {
          actions: sendParent(ACTIONS.BACK, { to: 'drawer' })
        },
        [ACTIONS.CANCEL]: {
          actions: assign({ isCancel: false })
        },
        [ACTIONS.CONFIRM]: {
          actions: [
            sendParent(() => ({
              type: ACTIONS.CONFIRM_BACK,
              to: 'drawer'
            }))
          ]
        }
      }
    },
    [STATES.DIALOG_CONFIRM_WITHOUT_CHANGE_MAP]: {
      entry: assign({
        dialogTitle: DIALOG[DIALOG_CASES.CHANGE_ONLY_FORM].TITLE,
        dialogDescription: DIALOG[DIALOG_CASES.CHANGE_ONLY_FORM].DESCRIPTION
      }),
      on: {
        [ACTIONS.CONFIRM]: STATES.SUBMITTING,
        [ACTIONS.CANCEL]: STATES.EDITING
      }
    },
    [STATES.DIALOG_CONFIRM_WITHOUT_CHANGE_POSTAL_ADDRESS]: {
      entry: assign({
        dialogTitle: DIALOG[DIALOG_CASES.CHANGE_ONLY_MAP].TITLE,
        dialogDescription: DIALOG[DIALOG_CASES.CHANGE_ONLY_MAP].DESCRIPTION
      }),
      on: {
        [ACTIONS.CONFIRM]: STATES.SUBMITTING,
        [ACTIONS.CANCEL]: STATES.EDITING
      }
    },
    [STATES.CONFIRMING]: {
      on: {
        [ACTIONS.CONFIRM]: STATES.SUBMITTING,
        [ACTIONS.CANCEL]: STATES.EDITING
      }
    },
    [STATES.SUBMITTING]: {
      invoke: {
        id: 'updateLoggiAddress',
        src: ({
          packageInfo: { loggiAddress, unitLoad, sortingContext },
          postalAddress,
          coordinates,
          packageId
        }) => {
          const qualityFlags = {
            suspiciousGeocoding: false,
            hierarchicalFallbackLevel:
              loggiAddress.qualityFlags.hierarchicalFallbackLevel
          };
          qualityFlags.hierarchicalFallbackLevel = HIERARCHICAL_FALLBACK_LEVEL_UNUSED_VALUE;

          const newLoggiAddress = {
            ...loggiAddress,
            postalAddress: postalAddress ?? loggiAddress.postalAddress,
            coordinates: coordinates ?? loggiAddress.coordinates,
            qualityFlags
          };
          return updatePackageAddressService(
            packageId,
            newLoggiAddress,
            false,
            // eslint-disable-next-line camelcase
            unitLoad?.license_plate ?? '',
            // eslint-disable-next-line camelcase
            sortingContext?.license_plate ?? ''
          );
        },
        onDone: {
          actions: [
            sendParent({
              type: ACTIONS.SET_NOTIFICATION,
              data: {
                message: TEXT.UPDATE_ADDRESS_SUCCESS,
                error: false
              },
              to: 'drawer'
            }),
            sendParent(ACTIONS.RELOAD_PAGE, { to: 'drawer' })
          ]
        },
        onError: {
          target: STATES.EDITING,
          actions: [
            sendParent((_, event) => {
              const {
                data: { response }
              } = event;
              const translateMessage = CUSTOM_MESSAGE[(response?.data)];

              const customErros = {
                422: translateMessage
              };

              const translateStatusAtMessage = translateStatusCode(
                response.status,
                customErros
              );

              return {
                type: ACTIONS.SET_NOTIFICATION,
                data: {
                  message: translateStatusAtMessage,
                  error: true
                },
                to: 'drawer'
              };
            })
          ]
        }
      }
    }
  }
});

export default updateAddressMachine;
