import api from 'infra/api/leve';
import { URL_PROXY_V2_PREFIX } from './constants';

/**
 * Wrapped POST request to Leve's API for create deliverer
 *
 * @returns {Promise} Axios response object containing the following information
 * {
 *   `data` is the response that was provided by the server
 *    data: {
 *     statusCode: Int,
 *     statusMessage: String
 *    }
 *
 *   `status` is the HTTP status code from the server response
 *    status: 200,
 *
 *   `statusText` is the HTTP status message from the server response
 *    statusText: 'OK',
 *
 *   `headers` the headers that the server responded with
 *    headers: {},
 *
 *   `config` is the config that was provided to `axios` for the request
 *    config: {},
 *
 *   `request` is the request that generated this response
 *   request: {}
 * }
 *
 */
export default ({ userId }) => {
  return api.delete(`${URL_PROXY_V2_PREFIX}/drivers-access/${userId}`);
};
