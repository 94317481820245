import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

function BarcodeReader(props) {
  const { onRead, placeholder } = props;
  const [barcode, setBarcode] = useState('');
  const [scanned, setScanned] = useState(false);
  const [isMobile] = useState(window.navigator.userAgent.includes('Mobile'));

  const _onRead = event => {
    event.preventDefault();
    if (barcode === '') {
      return;
    }
    onRead(barcode, isMobile ? scanned : false);
    setBarcode('');
    setScanned(false);
  };

  return (
    <form onSubmit={_onRead}>
      <TextField
        placeholder={placeholder}
        onChange={event => setBarcode(event.target.value)}
        value={barcode}
        inputProps={{ minLength: 3 }}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            return;
          }
          setScanned(true);
          setBarcode(event.target.value);
        }}
        autoFocus
        required
      />
    </form>
  );
}

BarcodeReader.defaultName = 'BarcodeReader';

BarcodeReader.propTypes = {
  onRead: PropTypes.func,
  placeholder: PropTypes.string
};

BarcodeReader.defaultProps = {
  onRead: () => {},
  placeholder: ''
};

export default BarcodeReader;
