import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import SummaryContext from 'tracking/template/summary.context';
import { usePagination } from 'shared/pagination';
import { useSnackbar } from 'notistack';

import { UsersManagementContext } from 'users-management/users-management.context';

import { getCompanyType } from 'auth/access-control/access-control.service';
import DriversListComponent from './drivers-list.component';
import { getDriversService } from '../drivers.service';
import { displaySnackBar } from '../drivers.utils';
import { TEXTS } from './drivers-list.constants';

const DriversListContainer = ({
  hasDc,
  onSelectDriver,
  onNewRegister,
  shouldUpdate,
  shouldEdit
}) => {
  const [loading, setLoading] = useState(true);
  const [toggleUpdate, setToggleUpdate] = useState(shouldUpdate);
  const [{ users = [], pagination = {} }, setData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { setSummary } = useContext(SummaryContext);
  const { setCardContent } = useContext(UsersManagementContext);
  const [
    { currentPage, numberOfPages },
    changePage,
    setNumberOfItems
  ] = usePagination();

  const companyType = getCompanyType();

  useEffect(() => {
    if (toggleUpdate !== shouldUpdate) {
      setToggleUpdate(!toggleUpdate);
      // When toggle is changed, we need to go to the first page
      changePage(1);
    }
  }, [shouldUpdate, toggleUpdate, currentPage, changePage, shouldEdit]);

  useEffect(() => {
    setNumberOfItems(pagination.numberItems);
  }, [setNumberOfItems, pagination.numberItems, shouldEdit]);

  useEffect(() => {
    getDriversService(currentPage)
      .then(setData)
      .catch(
        displaySnackBar({
          enqueueSnackbar
        })
      )
      .finally(() => setLoading(false));
  }, [currentPage, toggleUpdate, setData, enqueueSnackbar, shouldEdit]);

  useEffect(() => {
    setSummary(() => (
      <Typography variant="h4">
        <Box fontWeight="fontWeightBold">{TEXTS.SUMMARY_TITLE}</Box>
        {!loading && (
          <Box>{TEXTS.SUMMARY_SUBTITLE(pagination.numberItems)}</Box>
        )}
        {loading && (
          <Box pt={0.5} pb={0.5}>
            <Skeleton variant="rect" width="150px" height="34px" />
          </Box>
        )}
      </Typography>
    ));
  }, [setSummary, pagination.numberItems, loading, shouldEdit]);

  useEffect(() => {
    setCardContent(
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">
          <em>{TEXTS.NEW_REGISTER_TITLE}</em>
        </Typography>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={onNewRegister}
          data-testid="new-register-button"
        >
          {TEXTS.NEW_REGISTER_BUTTON}
        </Button>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCardContent, shouldEdit]);

  if (loading) {
    return (
      <Grid container justify="center" data-testid="driver-list-loader">
        <CircularProgress justify="center" data-testid="circle-progress" />
      </Grid>
    );
  }

  return (
    <DriversListComponent
      drivers={users}
      hasDc={hasDc}
      onSelectDriver={onSelectDriver}
      onChangePage={changePage}
      pagination={{ currentPage, numberOfPages }}
      companyType={companyType}
    />
  );
};

DriversListContainer.propTypes = {
  hasDc: PropTypes.bool.isRequired,
  shouldUpdate: PropTypes.bool.isRequired,
  onSelectDriver: PropTypes.func.isRequired,
  onNewRegister: PropTypes.func.isRequired,
  shouldEdit: PropTypes.bool.isRequired
};

export default DriversListContainer;
