export const PERMISSION_DENIED_MESSAGE =
  'Você precisa falar com o administrador para acessar esse serviço.';
export const NOT_FOUND_MESSAGE =
  'Não encontramos esse endereço. Tente de novo.';
export const DEFAULT_MESSAGE =
  'Eita, algo deu errado. Tente de novo daqui a pouco ou atualize a página. [006-Error-500]';

export const PRECONDITION_REQUIRED =
  'Este pacote deve ser recebido no aplicativo de tarefas.';

export const RESPONSE_MESSAGES_BY_CODE = {
  401: `${DEFAULT_MESSAGE}[401x]`,
  403: `${PERMISSION_DENIED_MESSAGE}[403x]`,
  404: `${NOT_FOUND_MESSAGE}[404x]`,
  428: `${PRECONDITION_REQUIRED}[428x]`,
  500: `${DEFAULT_MESSAGE}[500x]`,
  502: `${DEFAULT_MESSAGE}[502x]`,
  504: `${DEFAULT_MESSAGE}[504x]`
};

/**
 * This helper intends to translate generic
 * HTTP error codes to user friendly messages.
 *
 * @param {int} statusCode HTTP status code
 * @param {object} customMessages Custom message mapping
 */
export const translateStatusCode = (statusCode, customMessages = {}) => {
  // Merges messages objects into default one, avoiding manipulating original objects
  const responseMessage = {
    ...RESPONSE_MESSAGES_BY_CODE,
    ...customMessages
  };
  return responseMessage[statusCode] || DEFAULT_MESSAGE;
};

/**
 * High order function that translates request error into status code
 *
 * @param {Object.<number, string>} [customErrors={}] - contains custom HTTP status codes
 * @returns {function} - returns a rejected promise
 *
 * @param {Object} error
 * @param {Object} error.response
 * @param {number} error.response.status
 */
export const parseRequestError = (customErrors = {}) => error => {
  // get status code from response error
  const statusCode = ((error || {}).response || {}).status;
  const errorMessage = translateStatusCode(statusCode, customErrors);

  return Promise.reject(new Error(errorMessage));
};
