import api from 'infra/api/leve';
import { GET_DRIVER_POSITION } from './constants';

/**
 * Wrapped GET request o Leve's API for driver position
 * Get driver last position
 *
 * @returns {Promisse} Axios response object containing the following information
 * {
 *   // `data` is the response that was provided by the server.
 *   // GetDriverPositionResponse
 *   delivererList: [{
 *            driverId: String,
 *            gps_info: {
 *                position_geo_point: {
 *                      lat, lon
 *                 }
 *             }
 *    }],
 *
 *   // `status` is the HTTP status code from the server response
 *   status: 200,
 *
 *   // `statusText` is the HTTP status message from the server response
 *   statusText: 'OK',
 * }
 *
 */

export default driverId => {
  return api.get(`${GET_DRIVER_POSITION}${driverId}`);
};
