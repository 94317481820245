export const CANCEL_DIALOG = {
  UNDO_DISTRIBUTION: 'Desfazer distribuição',
  CANCEL_BTN: 'Voltar',
  CONTINUE_BTN: 'Continuar',
  TITLE: 'Você está cancelando essa distribuição',
  DESCRIPTION:
    'Ao cancelar a distribuição o entregador será avisado que está rota não está mais disponível para retirada.'
};

export const BLOCK_PACKAGE_IS_ROUTED = {
  error: 'Package is already routed or pending in Nuvem',
  message:
    'Este pacote está atribuído a um entregador. Para movimentá-lo, primeiro você precisa recebê-lo na base.'
};
