export const TEXTS = {
  SUMMARY_TITLE: 'Pessoas para rua',
  SUMMARY_SUBTITLE: (driversCount = 0) =>
    ({
      0: 'Nenhuma cadastrada',
      1: '1 cadastrada'
    }[driversCount] || `${driversCount} cadastradas`),
  NEW_REGISTER_TITLE:
    'Cadastre e gerencie pessoas para fazer coletas e entregas nos aplicativos da Loggi.',
  NEW_REGISTER_BUTTON: 'Cadastrar',
  EMPTY_STATE: 'Aqui é onde vai ficar a lista de pessoas cadastradas.',
  PICKUP_AND_DELIVERY: 'Coleta e Entrega',
  DELIVERY: 'Entrega',
  ALLOWED: 'Permitidas',
  NOT_ALLOWED: 'Sem permissão'
};

export const COLUMN_TITLE = {
  NAME: 'Nome',
  ID: 'ID',
  AVAILABILITY: 'Atividade',
  TRANSPORT_TYPE: 'Veículo',
  MOBILE_NUMBER: 'Celular'
};
