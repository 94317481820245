import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';

import { getSelectedRoutingCode } from 'auth/login/login.service';
import { SWITCHES_REALTIME } from 'view/constants';
import DialogDistribuirRedirectComponent from './confirm-dialog.component';

export default function DialogDistribuirRedirectContainer({ handleCancel }) {
  const [openDialog, setOpenDialog] = useState(true);

  const fsRealtimeEnableDistribuirRedirectFase1 = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsRealtimeEnableDistribuirRedirectFase1
  );
  const fsRealtimeEnableDistribuirRedirectFase2 = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsRealtimeEnableDistribuirRedirectFase2
  );

  const history = useHistory();
  const goToPage = url => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${url}`);
  };

  return (
    <DialogDistribuirRedirectComponent
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      fsRealtimeEnableDistribuirRedirectFase1={
        fsRealtimeEnableDistribuirRedirectFase1
      }
      fsRealtimeEnableDistribuirRedirectFase2={
        fsRealtimeEnableDistribuirRedirectFase2
      }
      handleCancel={handleCancel}
      goToPage={goToPage}
    />
  );
}

DialogDistribuirRedirectContainer.propTypes = {
  handleCancel: PropTypes.func
};

DialogDistribuirRedirectContainer.defaultProps = {
  handleCancel: null
};
