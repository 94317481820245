import React from 'react';
import PropTypes from 'prop-types';

import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';

export default function MaskedInput(props) {
  const {
    disabled,
    helperText,
    isValid,
    label,
    mask,
    onBlur,
    onChange,
    testId,
    value
  } = props;

  return (
    <InputMask
      disabled={disabled}
      mask={mask}
      maskChar=" "
      onBlur={onBlur}
      onChange={onChange}
      value={value}
    >
      {() => {
        return (
          <TextField
            disabled={disabled}
            error={!isValid}
            fullWidth
            helperText={!isValid && helperText}
            label={label}
            margin="normal"
            name="phone"
            type="text"
            variant="outlined"
            inputProps={{ 'data-testid': testId }}
          />
        );
      }}
    </InputMask>
  );
}

MaskedInput.propTypes = {
  disabled: PropTypes.bool,
  helperText: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  testId: PropTypes.string.isRequired,
  value: PropTypes.string
};

MaskedInput.defaultProps = {
  disabled: false,
  isValid: true,
  onBlur: () => {},
  onChange: () => {},
  value: null
};
