import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Grid, Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import FilterContext from './filter.context';
import useStyles from './filter.styles';
import { TEXT } from './filter.constants';

export const isScrolled = reference => {
  return reference.current.scrollTop !== 0;
};

export default function FilterComponent({
  children,
  onApplyChanges,
  onOpen,
  btnFilter,
  baseFilters
}) {
  const [anchor, setAnchor] = useState(null);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);

  const btnFilterForId = btnFilter.replace(' ', '-');

  const {
    checkedItems,
    resetCheckedItems,
    updateSavedCheckedCount,
    getSavedCheckedCount
  } = useContext(FilterContext);

  const classes = useStyles();

  const calcCheckedItens = arrItems => {
    const itemChose = Object.values(arrItems).reduce(
      (mem, a) => a.length + mem,
      0
    );
    updateSavedCheckedCount(itemChose);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleClick = event => {
    event.preventDefault();
    setAnchor(event.currentTarget);
    onOpen();
  };

  const handleSave = () => {
    handleClose();
    calcCheckedItens(checkedItems);
    onApplyChanges(checkedItems);
  };

  const handleClean = () => {
    handleClose();
    resetCheckedItems();
    calcCheckedItens({});
    const remove = Object.keys(checkedItems)[0];
    onApplyChanges({ remove });
  };

  const ref = React.createRef();

  const handleScroll = () => {
    // this externalization was needed to test the scroll effect
    setShouldShowShadow(isScrolled(ref));
  };

  const open = Boolean(anchor);

  const getMarkedFiltersCount = () => {
    const countFilters = Object.values(baseFilters.senders || []).length
      ? Object.values(baseFilters.senders).length
      : 0;
    if (countFilters !== 0) {
      return ` • ${countFilters}`;
    }
    return getSavedCheckedCount() ? ` • ${getSavedCheckedCount()}` : '';
  };

  return (
    <Box mr={2} component="span">
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClick}
        data-testid={`btn-filter-${btnFilterForId}`}
        className={getSavedCheckedCount() ? classes.activeFilter : ''}
        size="small"
      >
        {btnFilter}
        {getMarkedFiltersCount()}
        {!open && <ArrowDropDownIcon />}
        {open && <ArrowDropUpIcon />}
      </Button>
      <Popover
        data-testid="popover-wrapper"
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        className={classes.popover}
      >
        <Box
          className={classes.filterWrapper}
          ref={ref}
          onScroll={handleScroll}
          data-testid="filter-content-wrapper"
        >
          {children}
        </Box>
        <footer
          className={`${classes.footer} ${
            shouldShowShadow ? classes.footerShadow : ''
          }`}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Button fullWidth onClick={handleClean} color="default">
                {TEXT.BTN_RESET_FILTER}
              </Button>
            </Grid>
            <Grid item xs={9}>
              <Button
                data-testid={`apply-filter-${btnFilterForId}`}
                type="submit"
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleSave}
              >
                {TEXT.BTN_APPLY_FILTER}
              </Button>
            </Grid>
          </Grid>
        </footer>
      </Popover>
    </Box>
  );
}

FilterComponent.propTypes = {
  onApplyChanges: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  btnFilter: PropTypes.string,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  baseFilters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
};

FilterComponent.defaultProps = {
  btnFilter: TEXT.BTN_FILTER,
  baseFilters: {}
};
