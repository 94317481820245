import React from 'react';
import PropTypes from 'prop-types';

import useRemoteConfig from './firebase.service';

export const FeatureSwitchContext = React.createContext();

/**
 * All keys are extracted from REMOTE_CONFIG_DEFAULT
 * so use the following convention:
 *
 *  Remote configs in snakeCase in firebase.
 *  Provider values in camelCase in the app.
 *
 *  Example:
 *   Given the remote config in firebase:
 *   enable_some_example_feature
 *
 *   Use the provider values like this:
 *   const { enableSomeExampleFeature } = React.useContext(FeatureSwitchContext);
 */
export function FeatureSwitchProvider({ children }) {
  /**
   * 'value' will contain a dictionary
   *  with the keys and values extracted from firebase
   *
   * {
   *    enableSomeExampleFeature: true
   * }
   *
   * While isLoading === true, values will reflect the default config.
   * After isLoading === false they will use the value fetched from firebase.
   */
  const { value, isLoading } = useRemoteConfig();

  return (
    <FeatureSwitchContext.Provider
      value={{
        ...value,
        isLoading
      }}
    >
      {children}
    </FeatureSwitchContext.Provider>
  );
}

FeatureSwitchProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export function TestFeatureSwitchProvider({
  children,
  enablePerformanceInfo,
  disableButtonDownloadReportForDcs,
  isLoading,
  enableChangePackageAddress,
  enableCancelOffer,
  createCapacityReserve,
  enableAllocateDriver,
  enableNewDataCreateInvoice,
  enableNotifications,
  enableAwaitingResolutionOptionByLmc,
  enableActivityDrawer,
  enableFilterPackageSuspiciousLocation2,
  enableFirebaseCloudMessaging,
  enableLimitComplexAreasByContext,
  enableAgenciesWithMoreComplexAreas,
  enableComplexAreas,
  enableDriverPosition,
  enableDemandRegions,
  enableReceivePackageInViewCollectionForLeve,
  enableCreateRecomendationMessageForDrawer,
  enableShowDisputesPage,
  enableUpdateDisputeResponsibleButton,
  enableDiscountInformationOnTheList,
  enableShowDropOffValueInfo,
  switches
}) {
  return (
    <FeatureSwitchContext.Provider
      value={{
        enablePerformanceInfo,
        disableButtonDownloadReportForDcs,
        isLoading,
        enableChangePackageAddress,
        enableCancelOffer,
        createCapacityReserve,
        enableAllocateDriver,
        enableNewDataCreateInvoice,
        enableNotifications,
        enableAwaitingResolutionOptionByLmc,
        enableActivityDrawer,
        enableFilterPackageSuspiciousLocation2,
        enableFirebaseCloudMessaging,
        enableLimitComplexAreasByContext,
        enableAgenciesWithMoreComplexAreas,
        enableComplexAreas,
        enableDriverPosition,
        enableDemandRegions,
        enableReceivePackageInViewCollectionForLeve,
        enableCreateRecomendationMessageForDrawer,
        enableShowDisputesPage,
        enableUpdateDisputeResponsibleButton,
        enableDiscountInformationOnTheList,
        enableShowDropOffValueInfo,
        ...switches
      }}
    >
      {children}
    </FeatureSwitchContext.Provider>
  );
}

TestFeatureSwitchProvider.propTypes = {
  children: PropTypes.element,
  enablePerformanceInfo: PropTypes.bool,
  disableButtonDownloadReportForDcs: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.bool
  ]),
  enableReceivePackageInViewCollectionForLeve: PropTypes.arrayOf(
    PropTypes.string
  ),
  isLoading: PropTypes.bool,
  enableChangePackageAddress: PropTypes.arrayOf(PropTypes.string),
  enableCancelOffer: PropTypes.arrayOf(PropTypes.string),
  createCapacityReserve: PropTypes.arrayOf(PropTypes.string),
  enableAllocateDriver: PropTypes.arrayOf(PropTypes.string),
  enableNewDataCreateInvoice: PropTypes.bool,
  enableNotifications: PropTypes.arrayOf(PropTypes.string),
  enableAwaitingResolutionOptionByLmc: PropTypes.arrayOf(PropTypes.string),
  enableActivityDrawer: PropTypes.bool,
  enableFilterPackageSuspiciousLocation2: PropTypes.arrayOf(PropTypes.string),
  enableFirebaseCloudMessaging: PropTypes.bool,
  enableLimitComplexAreasByContext: PropTypes.number,
  enableAgenciesWithMoreComplexAreas: PropTypes.arrayOf(PropTypes.string),
  enableComplexAreas: PropTypes.bool,
  enableDriverPosition: PropTypes.bool,
  enableDemandRegions: PropTypes.bool,
  enableCreateRecomendationMessageForDrawer: PropTypes.arrayOf(
    PropTypes.string
  ),
  enableShowDisputesPage: PropTypes.arrayOf(PropTypes.string),
  enableUpdateDisputeResponsibleButton: PropTypes.arrayOf(PropTypes.string),
  enableDiscountInformationOnTheList: PropTypes.bool,
  enableShowDropOffValueInfo: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types,
  switches: PropTypes.object.isRequired
};

TestFeatureSwitchProvider.defaultProps = {
  children: null,
  enablePerformanceInfo: false,
  disableButtonDownloadReportForDcs: [],
  isLoading: false,
  enableChangePackageAddress: [],
  enableCancelOffer: [],
  createCapacityReserve: [],
  enableAllocateDriver: [],
  enableNewDataCreateInvoice: false,
  enableNotifications: [],
  enableAwaitingResolutionOptionByLmc: [],
  enableActivityDrawer: false,
  enableFilterPackageSuspiciousLocation2: [],
  enableFirebaseCloudMessaging: false,
  enableLimitComplexAreasByContext: null,
  enableAgenciesWithMoreComplexAreas: null,
  enableComplexAreas: false,
  enableDriverPosition: false,
  enableDemandRegions: false,
  enableReceivePackageInViewCollectionForLeve: [],
  enableCreateRecomendationMessageForDrawer: [],
  enableShowDisputesPage: [],
  enableUpdateDisputeResponsibleButton: [],
  enableDiscountInformationOnTheList: false,
  enableShowDropOffValueInfo: false
};
