import React from 'react';
import SnackbarMessage from './snackbar.component';

const showSnackbar = ({
  message,
  withButton,
  variant,
  enqueueSnackbar,
  link,
  messageButton
}) => {
  enqueueSnackbar(message, {
    content: (key, msg) => (
      <SnackbarMessage
        open
        id={key}
        message={msg}
        variant={variant}
        withButton={withButton}
        link={link}
        messageButton={messageButton}
      />
    )
  });
};

export default showSnackbar;
