import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import {
  Box,
  Button,
  Typography,
  Paper,
  InputBase,
  IconButton
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import showSnackbar from 'shared/snackbar';
import { AUTHORIZED_ROUTES } from 'view/constants';
import { ReactComponent as BikeIcon } from 'images/icon-bike.svg';
import { ReactComponent as CarIcon } from 'images/icon-car.svg';
import { DrawerHeader, DrawerContent } from 'shared/drawer';

import { getSelectedRoutingCode } from 'auth/login/login.service';
import { setStorageValueWithTtl } from 'app/utils/storage-value-with-ttl';
import { simulateDemandRegions } from '../demand-regions.service';
import { DEMAND_REGIONS_SIMULATION_DRAWER } from '../demand-regions.constants';

const InitialSimulationDrawer = ({ onBack, numPositions, enqueueSnackbar }) => {
  const [numPositionsToSimulate, setNumPositionsToSimulate] = useState(
    numPositions
  );

  const cashTime = 90 * 1000; // 1m 30 s in ms

  const history = useHistory();

  const handleGoRoutinesPage = () => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.PATH}`);
  };

  const handleSendSimulation = () => {
    simulateDemandRegions(
      getSelectedRoutingCode(),
      numPositionsToSimulate,
      numPositionsToSimulate
    )
      .then(() => {
        showSnackbar({
          message:
            DEMAND_REGIONS_SIMULATION_DRAWER.NOTIFICATION_SEND_SIMULATION,
          enqueueSnackbar,
          withButton: false,
          variant: 'success'
        });
        setStorageValueWithTtl('SIMULATION', 'true', cashTime);
      })
      .catch(() => {
        showSnackbar({
          message: 'Error',
          enqueueSnackbar,
          withButton: false,
          variant: 'error'
        });
      });

    handleGoRoutinesPage();
  };

  return (
    <>
      <DrawerHeader
        titleVariant="h5"
        title={DEMAND_REGIONS_SIMULATION_DRAWER.TITLE}
        detail={DEMAND_REGIONS_SIMULATION_DRAWER.DETAIL}
        colorText="textSecondary"
        variantTextDetail="body1"
        showDivider={false}
        handleClosingDrawer={onBack}
        open
      />
      <DrawerContent>
        <Box mb={5} data-testid="simulation-number-regions">
          <Typography color="textPrimary" variant="subtitle1">
            <Box fontWeight="fontWeightMedium">
              {DEMAND_REGIONS_SIMULATION_DRAWER.NUMBER_REGIONS}
            </Box>
          </Typography>
          <Box mt={2}>
            <Typography color="textSecondary" variant="subtitle2">
              {DEMAND_REGIONS_SIMULATION_DRAWER.NUMBER_REGIONS_DETAIL}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Paper
            mr={2}
            component={Box}
            height={56}
            width={120}
            border={1}
            borderColor={colors.smoke[100]}
          >
            <Box display="flex" mt={1} justifyContent="space-between">
              <Box mr={2}>
                <IconButton disabled>
                  <CarIcon color="primary" />
                </IconButton>
              </Box>
              <InputBase
                fullWidth
                data-testid="simulation-input-car"
                onChange={event =>
                  setNumPositionsToSimulate(event.target.value)
                }
                inputProps={{ type: 'number' }}
                color="info"
                value={numPositionsToSimulate}
              />
            </Box>
          </Paper>
          <Paper
            component={Box}
            height={56}
            width={120}
            border={1}
            borderColor={colors.smoke[100]}
          >
            <Box display="flex" mt={1} justifyContent="space-between">
              <Box mr={2}>
                <IconButton disabled>
                  <BikeIcon color="primary" />
                </IconButton>
              </Box>
              <InputBase
                fullWidth
                data-testid="simulation-input-bike"
                onChange={event =>
                  setNumPositionsToSimulate(event.target.value)
                }
                color="info"
                value={numPositionsToSimulate}
              />
            </Box>
          </Paper>
        </Box>
      </DrawerContent>

      <DrawerContent>
        <Box
          mb={4}
          style={{
            width: '400px',
            position: 'absolute',
            bottom: 0
          }}
        >
          <Button
            color="primary"
            data-testid="form-submit"
            fullWidth
            type="submit"
            variant="contained"
            onClick={handleSendSimulation}
          >
            {DEMAND_REGIONS_SIMULATION_DRAWER.SIMULATION_BUTTON}
          </Button>
        </Box>
      </DrawerContent>
    </>
  );
};

InitialSimulationDrawer.defaultProps = {
  numPositions: 0
};

InitialSimulationDrawer.propTypes = {
  numPositions: PropTypes.number,
  onBack: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default InitialSimulationDrawer;
