import { Link, Typography } from '@material-ui/core';
import React from 'react';
import AgruparGif from './Agrupar.gif';

export const FASE_1 = {
  title: 'Atenção, a distribuição vai mudar!',
  description: (
    <Typography>
      Em breve a forma de distribuir pacotes para seus entregadores vai mudar.
      <br />A tela <em>Distribuir</em> deixará de existir e os pacotes vão
      precisar ser bipados em Grupos antes de serem distribuídos.
      <br />
      <em>Não</em> será mais possível distribuir <em>pacotes</em> de{' '}
      <em>forma unitária</em>.
      <br />
      <br />
      <Link
        href="https://youtu.be/OewTcVRh-mQ?feature=shared"
        download="Distribuir vai mudar"
        target="_blank"
        rel="noreferrer"
      >
        <strong>Clique aqui </strong>
      </Link>
      para saber mais sobre Grupos no Arco.
    </Typography>
  )
};

export const FASE_2 = {
  title: 'Agrupe pacotes para distribuir',
  description: (
    <>
      <img src={AgruparGif} alt="Agrupar pacotes" style={{ width: '100%' }} />
      <Typography>
        Para distribuir pacotes ao entregador, você deve agrupá-los em uma rota
        de entrega. Para criar e editar um grupo de pacotes, acesse Grupos.
      </Typography>
    </>
  )
};

export const TEXT_CANCEL = 'Cancelar';
export const TEXT_TO_GROUP = 'Ir para grupos';
export const TEXT_OK = 'Entendi';
