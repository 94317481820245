import { contextMapper } from 'view/molecules/package-drawer/drawer-detail/drawer-detail.configuration';
import { getCompanyType } from 'auth/access-control/access-control.service';

export const PACKAGE_STATUS_CODE = {
  DELIVERED: 2,
  DELIVERY_IN_PROGRESS: 3,
  GOING_TO_DELIVER: 5,
  RETURNED: 9, // devolvido
  LOST: 15,
  SUSPENDED: 17, // pacote cancelado
  DAMAGED: 14,
  STOLEN: 20,
  AWAITING_RESOLUTION: 28,
  READY_TO_RESEND: 29,
  READY_TO_RETURN: 30, // aguardando devolucao
  TRANSFER_COMPLETED: 89,
  INTEGRATED: 91,
  DEFAULT: 0
};

const translateStatusCode = {
  [PACKAGE_STATUS_CODE.DELIVERED]: 'Entregue',
  [PACKAGE_STATUS_CODE.LOST]: 'Perda',
  [PACKAGE_STATUS_CODE.STOLEN]: 'Roubo/Furto',
  [PACKAGE_STATUS_CODE.DAMAGED]: 'Danificado',
  [PACKAGE_STATUS_CODE.DEFAULT]: 'Finalizador'
};

export const TEXT = {
  CHANGE_STATUS: 'Alterar status',
  CHANGE_ADDRESS: 'Ajustar endereço',
  CTE_LABEL: 'Imprimir CTE para devolução',
  REFUSED_PACKAGE: 'Recusar pacote',
  UNAVAILABLE_CHANGE_STATUS: 'Não é possível alterar esse status.',
  UNAVAILABLE_CHANGE_ADDRESS_FOR_STATUS_DEVOLUTION:
    'Não é possível ajustar o endereço de um pacote com status de devolução.',
  UNAVAILABLE_CHANGE_ADDRESS_FOR_STATUS_FINISHED: (status = 0) =>
    `Não é possível ajustar o endereço de um pacote com status ${
      translateStatusCode[status]
    }`,
  UNAVAILABLE_CHANGE_ADDRESS_FOR_STATUS_DELIVERY_IN_PROGRESS:
    'Não é possível ajustar o endereço de um pacote em rota de entrega.',
  PACKAGE_WITHOUT_CTE: 'Esse pacote não tem CTE',
  PACKAGE_HISTORY: 'Histórico do pacote',
  LOADING_ERROR:
    'Eita! Deu algum ruim aqui na hora de carregar as informações do pacote.',
  RETRY: 'Tentar de novo',
  CHECKED_BY: 'Alterado por ',
  VIEW_HISTORY_PACKAGE: 'Ver histórico do pacote',
  VIEW_PROOF_OF_DELIVERY: 'Ver comprovante de entrega',
  VIEW_DISPUTE_PROTOCOL: 'Ver comprovante da acareação',
  ARRIVED: 'Chegou ',
  DELIVER_BY: 'Entregar até ',
  AWAITING_BY: 'Aguardar até ',
  DELIVERED: 'Entregou ',
  FINISHED: 'Finalizou ',
  DELIVERY_CONFIRMED: ' • Entrega confirmada em ',
  FALTA: 'Falta',
  REFUSED_SUCCESS: 'Pronto, tudo certo! Esse pacote foi recusado.',
  STATUS_UPDATED: 'Pronto, tudo certo! O status foi atualizado.',
  UPDATE_ADDRESS_SUCCESS: 'Tudo certo! O endereço foi ajustado.',
  CHANGE_DELIVERER: 'Distribuir para entregador',
  DELIVERER_LABEL: 'Será entregue por',
  MESSAGE_NOTFOUND_PKG_LSD: 'Os dados desse pacote não estão disponíveis.',
  ORIGINAL_ADDRESS: 'Ver endereço original',
  RECIPIENT_ADDRESS: 'Ver endereço do destinatário',
  ADDRESS_ADJUSTMENT_OLD: 'Ajuste de Endereço',
  ADDRESS_ADJUSTMENT: 'Ajustado por Loggi',
  USER_ADDRESS_ADJUSTMENT: 'Ajustado por',
  CALCULATE_DEADLINE: 'Calcular prazo',
  SUCCESS_FORCE_CREATE_PROMISED_DATE:
    'Prazo sendo calculado! Você pode demorar um pouco para ver a lista atualizada.',
  PACKAGE_DIRECTION_DELIVERING: 'Indo para o endereço do destinatário',
  PACKAGE_DIRECTION_RETURNING: 'Indo para o endereço do remetente',
  BTN_CONFER_LOCATION: 'Conferir localização',
  BTN_LOCATION_IS_RIGHT: 'Está tudo certo',
  ALERT_DESCRIBE_CONFER_LOCATION:
    'Confira a localização no mapa para garantir o sucesso das entregas.',
  ALERT_TITLE_CONFER_LOCATION_SUSPICIOUS_GEOCODING:
    'Parece que a localização não corresponde ao CEP',
  ALERT_FLAG_REMOVE_SUCESS:
    'Vamos desconsiderar o problema. Obrigada por avisar.',
  ALERT_TITLE_CONFER_LOCATION_HIERARCHICAL_FALLBACK_LEVEL:
    'A localização parece um pouco distante do endereço',
  ALERT_PACKAGE_AT_RISK: 'Pacote parado há muitos dias',
  ALERT_PACKAGE_AT_RISK_NOT_FOUND: 'Não encontrei',
  ALERT_PACKAGE_AT_RISK_FIND_PACKAGE:
    'Encontre o pacote e siga com o processo de entrega ou devolução para evitar o extravio.',
  SHORT_DESCRIPTION_STATUS_AWAITING_RESOLUTION:
    'Aguarde a finalização da tratativa para movimentar o pacote.',
  BTN_MORE_ABOUT_DISCOUNT: 'Saiba mais',
  ALERT_DISCOUNT: 'Esta entrega teve um desconto',
  DETAILS_DISCOUNT:
    'A entrega teve um problema por não seguir o processo indicado. Por isso, reduzimos o valor do repasse. Se tiver dúvidas, acesse a ',
  CENTRAL_DE_AJUDA: 'Central de Ajuda.'
};

export const statusCodeDescription = {
  [PACKAGE_STATUS_CODE.AWAITING_RESOLUTION]:
    TEXT.SHORT_DESCRIPTION_STATUS_AWAITING_RESOLUTION
};

// refuse package constants
export const PROCESS_RECEIVING = 'receiving';
export const DESTINATION_REFUSED = 'rejected';
export const PACKAGE_STATUS_CODE_DAMAGED = 14;
export const RETURN_TO_BASE_CONTEXT = 'COL';
export const PACKAGE_RECEIVED = 'receiving:accepted';
export const GOING_TO_BASE_CONTEXT = 'GOING_TO_BASE';

export const PACKAGE_STATUS_CODE_UNAVAILABLE_TO_CHANGE = [
  PACKAGE_STATUS_CODE.DELIVERED,
  PACKAGE_STATUS_CODE.LOST,
  PACKAGE_STATUS_CODE.STOLEN,
  PACKAGE_STATUS_CODE.DAMAGED,
  PACKAGE_STATUS_CODE.RETURNED
];

export const WHERE_IS_THE_PACKAGE = {
  RECEIVED: 'Na base',
  TRANSFER: 'Transferência, pra Loggi',
  DELIVERY: 'Na rua',
  DELIVERED: 'Entregue por ',
  FINISHED: 'Finalizado por '
};

const baseLabelWithStorage = unitLoadName => {
  if (getCompanyType() === 'LEVE') {
    return WHERE_IS_THE_PACKAGE.RECEIVED;
  }
  const splitName =
    unitLoadName.split(' ').length >= 3
      ? unitLoadName.split(' ').slice(1)
      : unitLoadName.split(' ');
  const unitLoadNameWithouRoutingCode = splitName.reduce(
    (initialString, name) => initialString.concat(name, ' '),
    ''
  );

  return unitLoadNameWithouRoutingCode === ' '
    ? `${WHERE_IS_THE_PACKAGE.RECEIVED}`
    : `${unitLoadNameWithouRoutingCode} • ${WHERE_IS_THE_PACKAGE.RECEIVED}`;
};

export const unitLoadMapper = (packageInfo, isAtFinishedScreen) => {
  // eslint-disable-next-line camelcase
  const name = packageInfo?.custody?.full_name || 'Loggi';

  // When a package went to status of Lost, Stolen or Damaged, it was simply
  // removed from any UnitLoad, without any context to guide.
  // So, we are forcing these cases to display 'Finalizado por' by looking at
  // the status codes.
  if (
    packageInfo.status.code === PACKAGE_STATUS_CODE.LOST ||
    packageInfo.status.code === PACKAGE_STATUS_CODE.STOLEN ||
    packageInfo.status.code === PACKAGE_STATUS_CODE.DAMAGED
  ) {
    return `${WHERE_IS_THE_PACKAGE.FINISHED}${name}`;
  }

  // eslint-disable-next-line camelcase
  const { license_plate } = packageInfo.sortingContext ||
    packageInfo.unitLoad || { license_plate: '' };

  const processDestinationOrContext = license_plate.includes(' ')
    ? contextMapper(license_plate)
    : license_plate
        .split(':')
        .slice(-2)
        .join(':');

  const unitLoadName = packageInfo?.unitLoad?.name || '';

  return (
    {
      'receiving:rejected': WHERE_IS_THE_PACKAGE.TRANSFER,
      'receiving:accepted': WHERE_IS_THE_PACKAGE.RECEIVED,
      'return:leve': WHERE_IS_THE_PACKAGE.RECEIVED,
      'return:loggi': WHERE_IS_THE_PACKAGE.TRANSFER,
      'delivery:in_progress': `${WHERE_IS_THE_PACKAGE.DELIVERY}`,
      'delivery:recipient_refused': `${WHERE_IS_THE_PACKAGE.DELIVERY}`,
      'delivery:invalid_destination': `${WHERE_IS_THE_PACKAGE.DELIVERY}`,
      'delivery:recipient_unavailable': `${WHERE_IS_THE_PACKAGE.DELIVERY}`,
      'delivery:delivered': isAtFinishedScreen
        ? `${WHERE_IS_THE_PACKAGE.FINISHED}${name}`
        : `${WHERE_IS_THE_PACKAGE.DELIVERED}${name}`,
      REC: baseLabelWithStorage(unitLoadName),
      COL: baseLabelWithStorage(unitLoadName),
      DEVO: baseLabelWithStorage(unitLoadName),
      CARRO: baseLabelWithStorage(unitLoadName),
      MOTO: baseLabelWithStorage(unitLoadName),
      VAN: baseLabelWithStorage(unitLoadName),
      DELIVERY: `${WHERE_IS_THE_PACKAGE.DELIVERY}`,
      DELIVERED: isAtFinishedScreen
        ? `${WHERE_IS_THE_PACKAGE.FINISHED}${name}`
        : `${WHERE_IS_THE_PACKAGE.DELIVERED}${name}`,
      DEFAULT: WHERE_IS_THE_PACKAGE.RECEIVED
    }[processDestinationOrContext] || WHERE_IS_THE_PACKAGE.RECEIVED
  );
};

export const formatPostalCode = (postalCode = '') =>
  postalCode.search('-') === -1 && postalCode.length >= 8
    ? `${postalCode.slice(0, 5)}-${postalCode.slice(5)}`
    : postalCode;

export const addAddressField = (address, fieldPrefix, field = '') => {
  const fieldWithPrefix = `${fieldPrefix}${field}`;
  return `${address}${address && field ? fieldWithPrefix : field}`;
};

export const mountDestinationInfo = ({
  address_st: street,
  address_number: addressNumber,
  address_complement: complement,
  vicinity,
  zip
}) => {
  const postalCode = formatPostalCode(zip);

  let destination = street ?? '';
  destination = addAddressField(destination, ', ', addressNumber);
  destination = addAddressField(destination, ', ', complement);
  destination = addAddressField(destination, ' • ', vicinity);
  destination = addAddressField(destination, ' • ', postalCode);

  return destination;
};

export const mountAddressInfo = (addressFields = []) =>
  addressFields.reduce(
    (address, field) => addAddressField(address, ' • ', field),
    ''
  );

export const PACKAGE_DIRECTION_RETURN = 'PACKAGE_DIRECTION_RETURN';
export const HIERARCHICAL_FALLBACK_LEVEL_INVALID =
  'HIERARCHICAL_FALLBACK_LEVEL_INVALID';
export const HIERARCHICAL_FALLBACK_LEVEL_UNUSED =
  'HIERARCHICAL_FALLBACK_LEVEL_UNUSED';
export const HIERARCHICAL_FALLBACK_LEVEL_POSTAL_CODE =
  'HIERARCHICAL_FALLBACK_LEVEL_POSTAL_CODE';
export const HIERARCHICAL_FALLBACK_LEVEL_NUMBER_STREET =
  'HIERARCHICAL_FALLBACK_LEVEL_NUMBER_STREET';
export const HIERARCHICAL_FALLBACK_LEVEL_NEIGHBORHOOD =
  'HIERARCHICAL_FALLBACK_LEVEL_NEIGHBORHOOD';
export const HIERARCHICAL_FALLBACK_LEVEL_UNUSED_VALUE = 1;
