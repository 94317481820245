import { MAPPER_STATUS } from 'shared/status-color-description/status-color-description.constants';

export const LIST_STATUS = Object.values(MAPPER_STATUS);

export const TEXT = {
  BTN_FILTER: 'Filtrar',
  BTN_RESET_FILTER: 'Ver todos',
  BTN_APPLY_FILTER: 'Aplicar filtros'
};

export const FILTERS = {
  CIRCLES: 'Círculos',
  DELIVERER_INCLUDING_INACTIVE: 'Entregador_incluindo_inativos',
  DELIVERER: 'Entregador',
  STATUS: 'Status',
  STATUS_OFFER: 'Situação',
  UPDATE: 'Atualização',
  REGION: 'Região',
  LOCATION: 'Localização',
  RANGE_OFFER: 'Prazo',
  FAULTS: 'Faltas',
  ALERTS: 'Alertas',
  SENDER: 'quem enviou',
  CHARGE_TYPE: 'Serviço',
  TO_DELIVERY: 'Para entregar',
  TO_PICKUP: 'Para coletar',
  INSTANTANEOUS: 'Padrão',
  ANTICIPATED: 'Programada',
  CITIES: 'Cidades',
  STATION: 'Estação',
  REGION_OFFER: 'Região da oferta',
  WITH_ALERT: 'Com alerta',
  IN_STREET_DELIVERER: 'deliverers',
  IN_STREET_STATUS: 'status',
  IN_STREET_HAS_FAULT: 'faltas',
  IN_STREET_CITY: 'cities',
  IN_STREET_CIRCLE: 'circles',
  IN_STREET_SENDER: 'senders'
};

export const DELIVERER_ADDITIONAL_INFO = {
  NEW: 'Novo',
  OLD: 'Antigo'
};

export const DEMAND_TYPE = {
  PICKUP: 'DEMAND_TYPE_PICKUP',
  DELIVERY: 'DEMAND_TYPE_DELIVERY'
};
