import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER } from './demand-regions.constants';

const ConfirmationApplicationSimulationDialog = ({
  open,
  onConfirm,
  onCancel
}) => {
  return (
    <Dialog open={open} data-testid="dialog-title">
      <DialogTitle>
        <Box pt={4}>
          <Typography variant="h6">
            <em>{DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER.DIALOG_TITLE}</em>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          data-testid="dialog-subtitle"
        >
          <Typography variant="body1">
            {DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER.DIALOG_SUBTITLE}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row-reverse' }}
          my={1.5}
        >
          <Box>
            <Button
              fullWidth
              data-testid="dialog-confirm"
              size="large"
              variant="contained"
              color="primary"
              onClick={onConfirm}
            >
              {DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER.DIALOG_CONFIRM}
            </Button>
          </Box>
          <Box pt={{ xs: 2.5, sm: 0 }} mr={{ xs: 0, sm: 1 }}>
            <Button
              fullWidth
              data-testid="dialog-back"
              size="large"
              variant="outlined"
              color="primary"
              onClick={onCancel}
            >
              {DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER.DIALOG_BACK}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationApplicationSimulationDialog.defaultProps = {
  open: true
};

ConfirmationApplicationSimulationDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ConfirmationApplicationSimulationDialog;
