import axios from 'axios';

function createApi() {
  const baseURL = `${process.env.REACT_APP_LW_URL}`;
  const axiosConfig = {
    baseURL
  };
  return axios.create(axiosConfig);
}

const api = createApi();

const addVersion = async config => {
  const _config = { ...config };
  _config.headers.appVersion = process.env.REACT_APP_ARCO_VERSION;
  return _config;
};

api.interceptors.request.use(addVersion);

export { addVersion };
export default api;
