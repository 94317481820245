import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Box, Button } from '@material-ui/core';
import ConfigurableTable, {
  ConfigurableTableSkeleton
} from 'shared/configurable-table';
import Pagination from 'shared/pagination';
import AlertStatusHeader, {
  isPlatformStatusDegraded
} from 'shared/alert-status-header';
import FilterComponent from 'shared/filter';
import { FILTERS } from 'shared/filter/filter.constants';
import { colors } from '@loggi/mar';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import configurableTableData from './disputes.configuration';
import disputesModel from './disputes.model';
import { TEXT_ALERT_STATUS_HEADER } from '../tracking.constants';
import {
  DISPUTE_DELIVERER_FILTER,
  DISPUTE_BUTTON_FILTERS
} from './disputes.constants';

const GroupFilterButton = ({ buttonFilters, handleButtonFilters }) => {
  const [clickedId, setClickedId] = useState();
  const [hasAnOptionSelected, setSelected] = useState(false);
  const activeColor = colors.blue[500];
  const inactiveColor = colors.smoke[500];

  const handleClick = (keyFilter, keyValues, id) => {
    const wasSelected = id === clickedId ? !hasAnOptionSelected : true;
    let key = keyFilter;
    let values = keyValues;
    if (wasSelected === false) {
      key = '';
      values = [];
    }

    handleButtonFilters(key, values);
    setSelected(wasSelected);
    setClickedId(id);
  };

  return buttonFilters.map(filter => (
    <Box mr={1} key={filter.id}>
      <Button
        className="button"
        variant="outlined"
        onClick={() => {
          handleClick(filter.keyFilter, filter.keyValues, filter.id);
        }}
        style={{
          height: '44px',
          borderColor:
            hasAnOptionSelected && clickedId === filter.id
              ? activeColor
              : inactiveColor,
          color:
            hasAnOptionSelected && clickedId === filter.id
              ? activeColor
              : inactiveColor
        }}
        size="small"
        data-testid={`btn-filter-${filter.id}`}
      >
        {filter.label}
      </Button>
    </Box>
  ));
};

GroupFilterButton.propTypes = {
  buttonFilters: PropTypes.shape().isRequired,
  handleButtonFilters: PropTypes.func.isRequired
};

function DisputesComponent({
  disputesData,
  loadingTable,
  onPageChange,
  pagination,
  handleDelivererFilter,
  handleButtonFilters
}) {
  const enableDisputesFilters = IsFSActiveForCurrentBase(
    'enableDisputesFilters'
  );

  return (
    <>
      <Paper
        component={Box}
        elevation={10}
        flexGrow={1}
        marginBottom={3}
        style={{ borderRadius: 10 }}
      >
        <AlertStatusHeader
          textPrimary={TEXT_ALERT_STATUS_HEADER.LIST_PACKAGES.PRIMARY}
          textSecondary={TEXT_ALERT_STATUS_HEADER.LIST_PACKAGES.SECONDARY}
        />
        <Box p={5.5} minHeight={44}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            {enableDisputesFilters && (
              <>
                <Box display="flex" flexWrap="wrap">
                  <FilterComponent
                    key={DISPUTE_DELIVERER_FILTER}
                    btnFilter={DISPUTE_DELIVERER_FILTER}
                    whichFilter={FILTERS.DELIVERER_INCLUDING_INACTIVE}
                    onApplyChanges={handleDelivererFilter}
                  />
                </Box>
                <Box display="flex" flexWrap="wrap">
                  <GroupFilterButton
                    buttonFilters={DISPUTE_BUTTON_FILTERS}
                    handleButtonFilters={handleButtonFilters}
                  />
                </Box>
              </>
            )}
          </Box>
          {!loadingTable && (
            <ConfigurableTable
              data={disputesData}
              columns={configurableTableData}
              systemUnstable={isPlatformStatusDegraded()}
              withPackageDrawer
            />
          )}
          {loadingTable && <ConfigurableTableSkeleton />}
        </Box>
      </Paper>
      <Box data-testid="disputes-pagination">
        <Pagination
          onPageChange={onPageChange}
          initialPage={parseInt(pagination.page, 10)}
          numberOfPages={parseInt(pagination.numberPages, 10)}
        />
      </Box>
    </>
  );
}

DisputesComponent.propTypes = {
  disputesData: PropTypes.arrayOf(PropTypes.shape(disputesModel)).isRequired,
  loadingTable: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    numberDisputesOnPage: PropTypes.number,
    numberPages: PropTypes.number,
    numberDisputesAllPages: PropTypes.number
  }).isRequired,
  handleDelivererFilter: PropTypes.func.isRequired,
  handleButtonFilters: PropTypes.func.isRequired
};

export default DisputesComponent;
