import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(({ palette }) => ({
  alertContainer: {
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'center'
  },
  defaultAlertColors: {
    backgroundColor: colors.smoke[900],
    color: colors.smoke[200]
  },
  alertColorsLoggedWithLma: {
    backgroundColor: palette.common.white,
    color: palette.common.black
  },
  alertColorsLoggedImpersonate: {
    backgroundColor: palette.warning.main,
    color: palette.common.black
  }
}));

export default useStyles;
