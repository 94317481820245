import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  ForgotPassword,
  ValidateRecoveryPassword
} from '@loggi/authentication/src/screens';

import LoginContainer from 'auth/login';
import Signup from 'auth/signup';

import { PUBLIC_ROUTES } from './constants';

export default function PublicRoutes() {
  return (
    <Switch>
      <Route exact path={PUBLIC_ROUTES.LOGIN_ROUTE}>
        <LoginContainer nextPage="/" />
      </Route>
      <Route
        exact
        path={`${PUBLIC_ROUTES.SIGNUP_ROUTE}/:token`}
        component={Signup}
      />
      <Route
        path={PUBLIC_ROUTES.FORGOT_PASSWORD_ROUTE}
        component={ForgotPassword}
      />
      <Route
        path={PUBLIC_ROUTES.VALIDATE_PASSWORD_RECOVERY}
        component={ValidateRecoveryPassword}
      />
      {/* Handles redirect after ValidateRecoveryPassword */}
      <Redirect to={PUBLIC_ROUTES.LOGIN_ROUTE} />
    </Switch>
  );
}
