import recoveryPasswordForm from '@loggi/authentication/src/locales/pt-br/recovery-password-form.json';

export default {
  ...recoveryPasswordForm,
  headerContent: {
    title: 'Recupere sua senha',
    subTitle: 'Enviaremos um código de confirmação para o seu e-mail.'
  },
  emailInput: {
    label: 'Digite seu e-mail cadastrado na Loggi.'
  },
  successMessage: 'Pronto! O código foi enviado para o seu e-mail.'
};
