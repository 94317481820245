import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  logo: {
    width: spacing(4),
    height: spacing(4),
    minWidth: spacing(4),
    minHeight: spacing(4)
  }
}));

export default useStyles;
