import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { FILTERS, DEMAND_TYPE } from 'shared/filter/filter.constants';
import { ReactComponent as OfertaProgramaEmpty } from 'images/agencia2_v2.svg';
import { ReactComponent as OfertaPadraoEmpty } from 'images/pessoa-banco.svg';
import { getSelectedRoutingCode } from 'auth/login/login.service';

function OfferEmptyComponent({ valueTab, programmingOffer, demandType }) {
  const isDefault =
    valueTab === FILTERS.INSTANTANEOUS || demandType === DEMAND_TYPE.PICKUP;

  const history = useHistory();

  const icon = isDefault ? <OfertaPadraoEmpty /> : <OfertaProgramaEmpty />;
  const title = isDefault
    ? 'Nenhuma oferta padrão'
    : 'Sem ofertas programadas por enquanto';
  const description = isDefault
    ? 'Tá tudo tranquilo, nenhuma oferta de entrega padrão foi criada por enquanto.'
    : 'Defina a data e hora, região de entrega e veículos necessários para programar ofertas com antecedência, considerando sua demanda.';
  const buttonText = isDefault ? 'Voltar para home' : 'Programar oferta';

  const onGoHomeClick = () => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}`);
  };

  const onClick = () => {
    if (isDefault) {
      return onGoHomeClick();
    }
    return programmingOffer();
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box maxWidth={480} py={5}>
        <Box mb={5}>{icon}</Box>

        <Typography variant="h5">
          <Box fontWeight="bold">{title}</Box>
        </Typography>
        <Box mb={5}>
          <Typography variant="body1" align="left">
            {description}
          </Typography>
        </Box>
        <Button
          variant={isDefault ? 'outlined' : 'contained'}
          color="primary"
          size="large"
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
}
OfferEmptyComponent.propTypes = {
  valueTab: PropTypes.string.isRequired,
  demandType: PropTypes.string.isRequired,
  programmingOffer: PropTypes.func.isRequired
};

export default OfferEmptyComponent;
