import React, { useRef, useState } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';

import { Drawer, Box } from '@material-ui/core';
import { AUTHORIZED_ROUTES } from 'view/constants';
import useStyles from 'view/molecules/package-drawer/drawer.style';
import { getSelectedRoutingCode } from 'auth/login/login.service';

import OfferDetailsDrawer from './offer-details-drawer';
import RouteMap from './route-map';
import { groupVolumesByCoordinates, getAddressCoordinates } from './utils';

function OfferRouteMap() {
  const history = useHistory();
  const classes = useStyles();
  const { offerDetail } = history.location?.state || {};
  const { offerId } = useParams();
  const refs = {};
  const routingCode = getSelectedRoutingCode();
  const [mapLoaded, setMapLoaded] = useState(false);

  if (!offerDetail) {
    return (
      <Redirect
        to={`/${routingCode}${AUTHORIZED_ROUTES.OFFER.DETAIL.replace(
          ':offerId',
          offerId
        )}`}
      />
    );
  }

  const { offerRoute, volumes: offerVolumes } = offerDetail;

  const volumesGrouped = groupVolumesByCoordinates(offerVolumes);

  const route = {
    geometry: offerRoute.geometry,
    points: []
  };

  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const baseRef = useRef(null);
  refs[routingCode] = { point: baseRef };

  const populateMapRef = displayId => {
    /* eslint-disable-next-line react-hooks/rules-of-hooks */
    const ref = useRef(null);
    refs[displayId] = { point: ref };
  };

  offerRoute.points.forEach(point => {
    const firstPointVolume = volumesGrouped[point.groupingKey]
      ? volumesGrouped[point.groupingKey][0]
      : null;
    const { latitude, longitude } = firstPointVolume
      ? getAddressCoordinates(firstPointVolume.address)
      : {};

    populateMapRef(point.displayId);

    route.points.push({
      ...point,
      latitude,
      longitude
    });
  });

  return (
    <Drawer
      data-testid="offer-route-drawer"
      open
      anchor="left"
      variant="temporary"
      className={classes.divDrawer}
    >
      <Box
        data-testid="offer-route-map"
        component="div"
        overflow="auto"
        display="flex"
        height="100%"
      >
        <Box maxWidth={480} width={480} minWidth={480} overflow="auto">
          <OfferDetailsDrawer
            offerDetails={offerDetail}
            offerRoute={offerRoute}
            offerVolumes={offerVolumes}
            refs={refs}
            mapLoaded={mapLoaded}
          />
        </Box>
        <Box width="100%" height="100%" data-testid="route-map-component">
          <RouteMap
            route={route}
            refs={refs}
            updateMapLoaded={() => setMapLoaded(true)}
          />
        </Box>
      </Box>
    </Drawer>
  );
}
export default OfferRouteMap;
