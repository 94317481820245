import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import DrawerDisputeProtocol from './drawer-dispute-protocol.component';
import getDisputeProtocol from './drawer-dispute-protocol.service';

export default function DrawerDisputeProtocolContainer({ disputeId }) {
  const [disputeProtocol, setDisputeProtocol] = useState('');

  useEffect(() => {
    getDisputeProtocol(disputeId).then(currentDisputeProtocol => {
      setDisputeProtocol(currentDisputeProtocol?.protocol?.content);
    });
  }, [disputeId]);

  return (
    <Box>
      {disputeProtocol && (
        <DrawerDisputeProtocol disputeProtocol={disputeProtocol} />
      )}
    </Box>
  );
}

DrawerDisputeProtocolContainer.propTypes = {
  disputeId: PropTypes.number.isRequired
};
