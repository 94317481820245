import 'moment/locale/pt-br';
import {
  update,
  progress,
  withAlert,
  delivererWithIconV2,
  chevronRight,
  missedEventsBoxV2InStreet,
  deliveryDeadlineV2,
  packageV2,
  toV2,
  statusUpdatedV2,
  statusV2
} from 'shared/configurable-table/configuration/leve-v2';

export const DELIVERERS_HEADER = 'Entregadores';
export const DELIVERERS = 'entregadores';
export const PACOTES_ATRASADOS = `${DELIVERERS}/atrasados`;
export const PACOTES_INSUCESSOS = `${DELIVERERS}/insucessos`;
export const PACOTES_PARA_HOJE = `${DELIVERERS}/para-hoje`;

export const TITLE_VIEW = 'Na rua';

export const VIEW_CONFIG = {
  [DELIVERERS]: {
    subView: 'entregadores'
  },
  [PACOTES_ATRASADOS]: {
    subView: 'com pacotes atrasados'
  },
  [PACOTES_INSUCESSOS]: {
    subView: 'com insucessos'
  },
  [PACOTES_PARA_HOJE]: {
    subView: 'com pacotes para hoje'
  }
};

export const CONFIGURABLE_TABLE_CONFIGURATION_NA_RUA_DELIVERERS = {
  delivererWithIconV2,
  update,
  progress,
  withAlert,
  chevronRight
};

export const CONFIGURABLE_TABLE_CONFIGURATION_NA_RUA = {
  missedEventsBoxV2InStreet,
  deliveryDeadlineV2,
  packageV2,
  toV2,
  statusUpdatedV2,
  statusV2,
  chevronRight
};
