import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import PropTypes from 'prop-types';
import ConfirmDialogComponent from 'shared/confirm-dialog';
import { DRAWER_UPDATE_ADDRESS, TEXT } from './drawer-update-address.constants';
import { PostalAddressForm, CoordinatesAddressForm } from './address-forms';
import { CurrentAddressInfo } from '../drawer-detail/drawer-detail.component';

export default function DrawerUpdateAddressComponent({
  isLoading,
  onChangeAddress,
  setDrawerFullScreen,
  drawerFullScreen,
  onChangePostalAddress,
  onChangeCoordinates,
  postalAddress = {},
  coordinates = {},
  barcode,
  isEnableSubmit,
  isConfirming,
  isCancel,
  onConfirmationCancel,
  onConfirmationSubmit,
  packageInfo,
  dialogTitle,
  dialogDescription
}) {
  return (
    <Box data-testid="drawer-update-address-component">
      <ConfirmDialogComponent
        open={isConfirming || (isCancel && isEnableSubmit)}
        title={dialogTitle}
        description={dialogDescription}
      >
        <Button
          color="primary"
          variant="outlined"
          onClick={onConfirmationCancel}
          data-testid="confirmation-dialog-cancel-btn"
        >
          {isCancel
            ? TEXT.CANCEL_LOCATION_CANCEL
            : TEXT.CONFIRM_LOCATION_CANCEL}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onConfirmationSubmit}
          data-testid="confirmation-dialog-submit-btn"
        >
          {isCancel
            ? TEXT.CANCEL_LOCATION_CONFIRM
            : TEXT.CONFIRM_LOCATION_SUBMIT}
        </Button>
      </ConfirmDialogComponent>
      <Box mx={3.5} pt={2.5} my={5}>
        <Typography variant="h4">
          <strong>{DRAWER_UPDATE_ADDRESS.title}</strong>
        </Typography>
        <Box mt={1}>
          <Typography variant="subtitle1">
            {`${TEXT.HELP_OF_PKG}${barcode}`}
          </Typography>
        </Box>

        <CurrentAddressInfo packageInfo={packageInfo} />
      </Box>
      <Divider />
      <Box mt={4}>
        <PostalAddressForm
          postalAddress={postalAddress}
          onChangePostalAddress={onChangePostalAddress}
        />
      </Box>
      <Box mb={3.5}>
        <Divider />
        <CoordinatesAddressForm
          coordinates={coordinates}
          onChangeCoordinates={onChangeCoordinates}
        />
        {!drawerFullScreen && (
          <Box mx={3.5} mt={2}>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => {
                setDrawerFullScreen(_full => !_full);
              }}
              data-testid="btn-show-map"
              disabled={drawerFullScreen}
            >
              <Box display="flex" mr={1}>
                <VisibilityIcon />
              </Box>
              {TEXT.SHOW_MAP}
            </Button>
          </Box>
        )}
      </Box>
      <Divider />
      <Box m={3}>
        <Alert severity="info">
          <AlertTitle>{DRAWER_UPDATE_ADDRESS.alert.title}</AlertTitle>
          {DRAWER_UPDATE_ADDRESS.alert.description}
        </Alert>
        <Box mt={5}>
          <Button
            data-testid="drawer-update-address-submit-btn"
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            disabled={isLoading || !isEnableSubmit}
            onClick={onChangeAddress}
          >
            {!isLoading ? (
              DRAWER_UPDATE_ADDRESS.change_address
            ) : (
              <CircularProgress size={20} />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

DrawerUpdateAddressComponent.defaultProps = {
  isLoading: false,
  isEnableSubmit: false,
  isConfirming: false,
  isCancel: false,
  dialogTitle: '',
  dialogDescription: ''
};

DrawerUpdateAddressComponent.propTypes = {
  postalAddress: PropTypes.shape().isRequired,
  coordinates: PropTypes.shape().isRequired,
  barcode: PropTypes.string.isRequired,
  onChangePostalAddress: PropTypes.func.isRequired,
  onChangeCoordinates: PropTypes.func.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
  setDrawerFullScreen: PropTypes.func.isRequired,
  drawerFullScreen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isEnableSubmit: PropTypes.bool,
  isConfirming: PropTypes.bool,
  isCancel: PropTypes.bool,
  onConfirmationSubmit: PropTypes.func.isRequired,
  onConfirmationCancel: PropTypes.func.isRequired,
  packageInfo: PropTypes.shape().isRequired,
  dialogTitle: PropTypes.string,
  dialogDescription: PropTypes.string
};
