import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { colors } from '@loggi/mar';
import 'moment/locale/pt-br';
import {
  Typography,
  Box,
  Grid,
  List,
  Divider,
  ListItem
} from '@material-ui/core';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import StatusColorDescription from 'shared/status-color-description';
import MissedEventsBox from 'shared/missed-events-box';
import CheckedBy from 'shared/checked-by';
import { TEXT } from '../drawer.constants';
import useStyles from '../drawer.style';

export const dateFormatter = fullDate => {
  return moment(fullDate)
    .locale('pt-BR')
    .format('DD MMM, HH:mm');
};
export default function DrawerPackageHistory({
  history,
  missedPromisedDateEvents,
  destinationChangeHistory,
  lastMilePackageChangeAddress
}) {
  const classes = useStyles();

  const showUserOrLoggi = item => {
    if (item.userFullName) {
      return `${TEXT.USER_ADDRESS_ADJUSTMENT} ${item.userFullName}`;
    }
    return TEXT.ADDRESS_ADJUSTMENT;
  };

  const packageAddresItem = item => (
    <ListItem
      disableGutters
      key={`${item.created || item.lastMilePackageDestination.created}`}
      style={{
        backgroundColor: colors.smoke[50]
      }}
    >
      <Box my={1} pl={8} mr={8} color="text.secondary">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Typography variant="body2">
            {item.lastMilePackageDestination ? (
              <em>{`${dateFormatter(
                item.lastMilePackageDestination.created
              )} • ${showUserOrLoggi(item)}`}</em>
            ) : (
              <em>{`${dateFormatter(item.created)} • ${
                TEXT.ADDRESS_ADJUSTMENT_OLD
              }`}</em>
            )}
          </Typography>
        </Grid>
      </Box>
    </ListItem>
  );

  const packageStatusItem = item => {
    return (
      <ListItem
        disableGutters
        key={`${item.date}${item.statusCode}`}
        classes={{ root: classes.item }}
      >
        <Box m={3.5}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid>
              <Box pt={4}>
                <StatusColorDescription
                  status={item.statusCode}
                  withoutDescription
                  leftPadding={0}
                />
              </Box>
            </Grid>
            <Grid>
              <Box pl={2.5} pb={1}>
                <Typography className={classes.storm} variant="body2">
                  <em>{dateFormatter(item.date)}</em>
                </Typography>
              </Box>

              <Box pl={2.5}>
                <Typography variant="subtitle1">
                  {item.statusDisplay}
                </Typography>
              </Box>

              <Box pl={2.5} pt={1}>
                <Typography className={classes.storm} variant="body2">
                  <em>
                    {item.checkedBy
                      ? `${TEXT.CHECKED_BY}${CheckedBy({
                          name: item.checkedBy,
                          email: item.checkedByEmail
                        })}`
                      : ''}
                  </em>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ListItem>
    );
  };

  const faltaItem = falta => (
    <ListItem disableGutters classes={{ root: classes.item }} key="faltas">
      <Grid
        container
        className={classes.faltaBackground}
        alignItems="flex-start"
      >
        <Box ml={3.5} mt={1.3}>
          <StopRoundedIcon color="error" />
        </Box>
        <Box>
          {falta.map(item => {
            return (
              <Box py={1.5} pl={2} key={item.expected_date}>
                <Typography
                  variant="body2"
                  className={classes.bolder}
                  color="error"
                >
                  {`${dateFormatter(item.expected_date)} • ${TEXT.FALTA}`}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Grid>
    </ListItem>
  );

  const createListHistory = () => {
    const changeHistory =
      destinationChangeHistory || lastMilePackageChangeAddress;

    const allItems = history.concat(
      missedPromisedDateEvents || [],
      changeHistory || []
    );

    return allItems.sort((a, b) =>
      moment(
        b.date ||
          b.expected_date ||
          b.created ||
          b.lastMilePackageDestination.created
      ).diff(
        moment(
          a.date ||
            a.expected_date ||
            a.created ||
            a.lastMilePackageDestination.created
        )
      )
    );
  };

  const sortingHistoryWithFalta = () => {
    let previousFalta = [];

    const sortedArray = createListHistory();

    // This if's are use to agroup FALTA status, and render it:
    // 1: When there's one status task (represented: item.date)
    // 2: When is the end of array sortedArray
    // This logic was necessary because the icon just can be render
    // once in each block of FALTAS

    return sortedArray.map((item, index) => {
      if (item.expected_date) {
        previousFalta.push(item);
      }
      if (item.date && previousFalta.length === 0) {
        return packageStatusItem(item);
      }
      if (item.date && previousFalta.length > 0) {
        const dataFalta = previousFalta;
        previousFalta = [];
        return (
          <React.Fragment key="falta-with-status">
            {faltaItem(dataFalta)} {packageStatusItem(item)}
          </React.Fragment>
        );
      }
      if (item.expected_date && sortedArray.length - 1 === index) {
        return faltaItem(previousFalta);
      }
      if (item.created || item.lastMilePackageDestination) {
        return packageAddresItem(item);
      }
      return '';
    });
  };

  return (
    <Box data-testid="drawer-history">
      <Box mx={3.5} pt={2.5} my={5}>
        <Typography variant="h4" className={classes.bolder}>
          {TEXT.PACKAGE_HISTORY}
        </Typography>
        {missedPromisedDateEvents && (
          <Box display="flex" direction="row" alignItems="center">
            <MissedEventsBox count={missedPromisedDateEvents.length} />
            <Box ml={1.5}>
              <Typography variant="h4">{TEXT.FALTA}</Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Divider />
      <List disablePadding>{sortingHistoryWithFalta()}</List>
    </Box>
  );
}

DrawerPackageHistory.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      statusDisplay: PropTypes.string,
      statusCode: PropTypes.number,
      date: PropTypes.string,
      checkedBy: PropTypes.string
    })
  ),
  missedPromisedDateEvents: PropTypes.arrayOf(
    PropTypes.shape({
      expected_date: PropTypes.string,
      new_expected_date: PropTypes.string
    })
  ),
  destinationChangeHistory: PropTypes.arrayOf(PropTypes.shape({})),
  lastMilePackageChangeAddress: PropTypes.arrayOf(PropTypes.shape({}))
};

DrawerPackageHistory.defaultProps = {
  history: [],
  missedPromisedDateEvents: null,
  destinationChangeHistory: null,
  lastMilePackageChangeAddress: null
};
