import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Typography } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/pt-br';

import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';

import { DRAWER, ERROR } from '../notifications.constants';
import NotificationComponent from './notification/notification.component';
import { markNotificationsAsRead } from '../notifications.service';

export default function NotificationListContainer({
  notifications,
  handleGetNotifications
}) {
  const { enqueueSnackbar } = useSnackbar();

  const groupedNotifications = {
    day: {
      title: DRAWER.GROUP_TITLE.TODAY,
      notifications: []
    },
    week: {
      title: DRAWER.GROUP_TITLE.WEEK,
      notifications: []
    },
    month: {
      title: DRAWER.GROUP_TITLE.MONTH,
      notifications: []
    }
  };

  const isSameOrAfterNow = (receivedAt, period) =>
    moment(receivedAt).isSameOrAfter(moment(), period);

  notifications.sort((a, b) => moment(b.createdAt).diff(a.createdAt));

  notifications.forEach(notification => {
    let pushTo = 'month';
    if (isSameOrAfterNow(notification.createdAt, 'day')) {
      pushTo = 'day';
    } else if (isSameOrAfterNow(notification.createdAt, 'week')) {
      pushTo = 'week';
    }
    groupedNotifications[pushTo].notifications.push(notification);
  });

  const handleMarkNotificationsAsRead = notificationList => {
    const onlyUnreadNotificationIds = [];
    notificationList
      ?.filter(({ readAt }) => !readAt)
      .forEach(notification => {
        if (notification.ids) {
          onlyUnreadNotificationIds.push(...notification.ids);
        } else {
          onlyUnreadNotificationIds.push(notification.id);
        }
      });

    if (onlyUnreadNotificationIds?.length)
      markNotificationsAsRead(onlyUnreadNotificationIds)
        .catch(error => {
          if (error.response.status !== 409)
            showSnackbar({
              message: ERROR.MARK_NOTIFICATIONS_AS_READ,
              variant: 'error',
              withButton: false,
              enqueueSnackbar
            });
        })
        .finally(handleGetNotifications);
  };

  return Object.keys(groupedNotifications).map(period => {
    const periodNotificationList = groupedNotifications[period].notifications;

    const hasUnreadNotification = periodNotificationList.some(
      ({ readAt }) => !readAt
    );

    return (
      periodNotificationList.length > 0 && (
        <Box marginBottom={1} key={period}>
          <Box marginBottom={2.2} marginLeft={4}>
            <Typography variant="body2" color="textSecondary">
              <Box component="span" fontWeight="fontWeightMedium">
                {groupedNotifications[period].title}
              </Box>
            </Typography>
          </Box>
          {periodNotificationList.map(notification => (
            <NotificationComponent
              key={notification.id}
              notification={notification}
              handleMarkNotificationsAsRead={handleMarkNotificationsAsRead}
            />
          ))}
          {hasUnreadNotification && (
            <Box mt={1} mb={4.4} ml={4}>
              <Button
                data-testid="btn-mark-as-read"
                variant="outlined"
                color="primary"
                size="small"
                onClick={() =>
                  handleMarkNotificationsAsRead(periodNotificationList)
                }
              >
                {DRAWER.BUTTON.MARK_AS_READ}
              </Button>
            </Box>
          )}
        </Box>
      )
    );
  });
}

NotificationListContainer.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      userId: PropTypes.string,
      intention: PropTypes.string,
      body: PropTypes.string,
      priority: PropTypes.string,
      createdAt: PropTypes.string,
      receivedAt: PropTypes.string,
      readAt: PropTypes.string
    })
  ).isRequired
};
