import api from 'infra/api/leve';
import { URL_API_V1_LAST_MILE_PREFIX } from 'infra/service/constants';

const createCapacityReserveService = async ({
  startDateTime,
  endDateTime,
  modalQuantity
}) => {
  return api.post(`${URL_API_V1_LAST_MILE_PREFIX}/offers/anticipated`, {
    startDateTime,
    endDateTime,
    modalQuantity
  });
};

export default createCapacityReserveService;
