import api from 'infra/api/leve';

/*
**required**
'unitLoadInfos': filter that defines the custody of packages
and where to look for data in the database.

unitLoadInfos: {
process: 'return',
destination: 'loggi'
}

'pagination': returns the current page and how many items
at a time.

pagination: {
numberPages: 1,
numberItems: 1,
paginationParams: {
itemsPerPage: 15,
page: 1
}
}

'orderBySorting': parameter that allows to change the
order of api return by the designated column and in
ascending or descending order.

orderBySorting: {
orderByColumn: 'nome',
isAscending: true
}
*/

const filterPackagesService = (
  unitLoadInfos,
  pagination,
  orderBySorting,
  filters,
  useElasticsearch,
  term
) => {
  return api.post('/api/v1/last_mile/packages/filter', {
    unitLoadInfos,
    pagination,
    orderBySorting,
    filters,
    use_elasticsearch: useElasticsearch,
    term
  });
};

export default filterPackagesService;
