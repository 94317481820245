import getPosition from 'app/geolocation';
import movePackageList from 'app/move-package-list';

export const DELIVERY_ASSIGNMENT = 'deliverer_assignment';

const changeDelivererService = async (delivererId, packageIds) => {
  const { latitude, longitude } = await getPosition();
  return movePackageList(
    DELIVERY_ASSIGNMENT,
    delivererId,
    packageIds.map(packageId => {
      return {
        packageId,
        location: {
          lat: latitude,
          lng: longitude
        },
        notes: null
      };
    }),
    false,
    latitude,
    longitude,
    null,
    delivererId
  );
};

export default changeDelivererService;
