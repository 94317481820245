import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Divider, Grid, Typography } from '@material-ui/core';

import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { formatNumber } from 'shared/formatters';
import { CARD_AGGREGATION } from 'tracking/arrivals/arrivals.constants';
import getTripTransferStops, {
  getArrivalsAggregation
} from 'tracking/arrivals/arrivals.service';
import { vehicleTypeMap } from 'tracking/arrivals/transfers/arrivals-transfers.configuration';
import { fetchPackageAggregatedData } from 'tracking/card-aggregation/card-aggregation.service';
import { AGG_FIELDS, VIEWS } from 'tracking/tracking.constants';
import { TEXT_CARD } from '../today/today.constants';
import useStyles from './cards.style';
import LoadingSkeleton from './loading-cards';

export default function ArrivalsCard({
  sumTotalPackages,
  withPickup,
  internalCard,
  sumTotalVehicles
}) {
  const [pickupData, setPickupData] = useState({
    data: [],
    total: 0
  });
  const [transfersData, setTransfersData] = useState({
    data: [],
    total: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [totalPackages, setTotalPackages] = useState(0);
  const [totalVehicles, setTotalVehicle] = useState(0);

  const classes = useStyles();

  const enableUseTMSVaiChegarCard = IsFSActiveForCurrentBase(
    'enableUseTMSVaiChegarCard'
  );

  useEffect(() => {
    async function fetchData() {
      if (enableUseTMSVaiChegarCard) {
        getTripTransferStops().then(currentTripTransferStops => {
          const countPackages = currentTripTransferStops.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.totalPackages,
            0
          );
          const countVehicles = currentTripTransferStops.length;
          const resultTransfers = {
            total: countPackages,
            drivers: [
              {
                transportType: 'VEHICLE_TYPE_TRUCK',
                value: countVehicles
              }
            ]
          };
          const resultPickups = {
            total: 0,
            drivers: []
          };
          setTransfersData(resultTransfers);
          setPickupData(resultPickups);
          setIsLoading(false);
        });
      } else {
        const promises = [getArrivalsAggregation(CARD_AGGREGATION, '')];
        if (withPickup) {
          promises.push(
            fetchPackageAggregatedData({
              aggregatedField: AGG_FIELDS.PICKUP_VIEW,
              view: VIEWS.PICKUP
            })
          );
        }
        const [resultTransfers, resultPickups] = await Promise.all(promises);
        setTransfersData(resultTransfers);
        if (withPickup) {
          setPickupData(resultPickups);
        }
        setIsLoading(false);
      }
    }
    fetchData();
  }, [withPickup, enableUseTMSVaiChegarCard]);

  useEffect(() => {
    const countPackages = transfersData.total + pickupData.total;
    setTotalPackages(countPackages);
    sumTotalPackages(countPackages);
  }, [pickupData.total, transfersData.total, sumTotalPackages]);

  useEffect(() => {
    const pickupVehicles =
      pickupData.drivers?.reduce((total, driver) => {
        return total + driver.value;
      }, 0) || 0;
    const transfersVehicles =
      transfersData.drivers?.reduce((total, driver) => {
        return total + driver.value;
      }, 0) || 0;
    const countVehicles = pickupVehicles + transfersVehicles;
    setTotalVehicle(countVehicles);
    sumTotalVehicles(countVehicles);
  }, [pickupData.drivers, transfersData.drivers, sumTotalVehicles]);

  const mergeAndOrderVehicles = () => {
    const transfersVehicleTypes =
      transfersData?.drivers.map(item => {
        return {
          transportType: vehicleTypeMap(item.transportType),
          value: item.value
        };
      }) || [];
    const transportTypes = transfersVehicleTypes.concat(
      pickupData?.drivers || []
    );
    return transportTypes.sort((a, b) => {
      if (a.value > b.value) {
        return 1;
      }
      if (a.values < b.value) {
        return -1;
      }
      return 0;
    });
  };

  return (
    <>
      {!isLoading && (
        <Grid container spacing={7}>
          <Grid item>
            <Typography className={classes.smoke} variant="body1">
              <em>{TEXT_CARD.VAI_CHEGAR.CARD_SUBTITLE_PKG}</em>
            </Typography>
            <br />
            <Grid container spacing={7} alignItems="center">
              <Grid item>
                <Typography variant="h2">
                  {formatNumber(totalPackages)}
                </Typography>
              </Grid>
              {!internalCard && transfersData.total > 0 && (
                <Grid item>
                  <Typography variant="subtitle2">
                    {formatNumber(transfersData.total)}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    <em>{TEXT_CARD.VAI_CHEGAR.CARD_TRANSFERS_SUBTITLE}</em>
                  </Typography>
                </Grid>
              )}
              {!internalCard && pickupData.total > 0 && (
                <Grid item>
                  <Typography variant="subtitle2">
                    {formatNumber(pickupData.total)}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    <em>{TEXT_CARD.VAI_CHEGAR.CARD_PICKUPS_SUBTITLE}</em>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <Grid container spacing={7} alignItems="center">
              <Grid item>
                <Typography className={classes.smoke} variant="body1">
                  <em>{TEXT_CARD.VAI_CHEGAR.CARD_SUBTITLE_VEHICLES}</em>
                </Typography>
                <br />
                <Typography variant="h2" align="center">
                  {totalVehicles}
                </Typography>
              </Grid>
              {mergeAndOrderVehicles(
                pickupData.drivers,
                transfersData.dirvers
              ).map(driver => {
                return (
                  <Grid item key={driver.transportType}>
                    <br />
                    <Box
                      pt={4}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Typography variant="subtitle2">
                        <em>{driver.value}</em>
                      </Typography>
                    </Box>
                    <Typography color="textSecondary" variant="body2">
                      <em>{driver.transportType}</em>
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
      {isLoading && <LoadingSkeleton />}
    </>
  );
}

ArrivalsCard.propTypes = {
  sumTotalPackages: PropTypes.func.isRequired,
  sumTotalVehicles: PropTypes.func,
  withPickup: PropTypes.bool,
  internalCard: PropTypes.bool
};

ArrivalsCard.defaultProps = {
  withPickup: true,
  internalCard: false,
  sumTotalVehicles: () => {}
};
