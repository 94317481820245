// https://docs.amplify.aws/gen1/javascript/prev/build-a-backend/auth/auth-events/#listen-to-and-log-auth-events
const HUB = {
  AUTH_CHANNEL: 'auth',
  TOKEN_FAILURE_MESSAGE: {
    EVENT: 'tokenRefresh_failure',
    DATA: 'NotAuthorizedException'
  }
};

export default HUB;
