import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  CircularProgress,
  Typography,
  Button,
  Grid
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { Can, IsFSActiveForCurrentBase } from 'auth/access-control';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import {
  DrawerContent,
  DrawerHeader,
  CustomDrawer,
  DrawerActions
} from 'shared/drawer';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import { getSelectedRoutingCode } from 'auth/login/login.service';
import ConfirmDialog from 'shared/confirm-dialog';
import { DEMAND_TYPE } from 'shared/filter/filter.constants';
import {
  FINISHED,
  CANCELLED,
  PICKED_UP,
  DROPPED,
  WAITING_PICKUP
} from 'offer/cells-component/status-cell/status-cell.constants';
import { AUTHORIZED_ROUTES } from 'view/constants';
import ButtonPopoverAssignOfferComponent from '../button-delivery-allocation';

import TEXT, { confirmDialogTypeMessages } from './drawer.constants';
import { buildPickupOriginAddress } from './drawer.service';
import STATUS_MAPPER from '../cells-component/status-cell/status-cell.constants';
import TRANSPORT_TYPE_MAPPER from '../cells-component/transport-type-cell/transport-type.constants';
import {
  buildPeriodText,
  buildAcceptedTime,
  moneyFormat
} from '../offer.utils';

export function buildPackageCountText(detail) {
  const defaultPackageText = detail.packageCount || detail.expectedPackageCount;
  const packageCount =
    detail.volumes?.length > 0 ? detail.volumes?.length : defaultPackageText;
  return packageCount <= 1
    ? `${packageCount} pacote`
    : `${packageCount} pacotes`;
}

export default function DrawerComponent({
  handleClosingDrawer,
  loading,
  pickupOfferDetail,
  callbackCancelOffer,
  callbackDeallocateOffer,
  offerId
}) {
  const history = useHistory();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogType, setConfirmDialogType] = useState('cancel');

  const enableCancelOffer = IsFSActiveForCurrentBase('enableCancelOffer');

  if (loading) {
    return (
      <CustomDrawer>
        <DrawerHeader
          title={TEXT.TITLE}
          status={{
            type: 'dot',
            label: '',
            dotColor: colors.smoke[900]
          }}
          showDivider={false}
          handleClosingDrawer={handleClosingDrawer}
        />
        <DrawerContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress
              justify="center"
              data-testid="loading-offer-drawer"
            />
          </Box>
        </DrawerContent>
      </CustomDrawer>
    );
  }

  const periodText = buildPeriodText(pickupOfferDetail);
  const packageCountText = buildPackageCountText(pickupOfferDetail);
  const pickupTransportType =
    TRANSPORT_TYPE_MAPPER[pickupOfferDetail.pickupTransportType]?.text;
  const driverTransportType =
    TRANSPORT_TYPE_MAPPER[pickupOfferDetail.driverTransportType]?.text;

  const formatSeconds = seconds => {
    if (seconds >= 3600) {
      return moment()
        .startOf('day')
        .seconds(seconds)
        .format('H[h]m');
    }
    return moment()
      .startOf('day')
      .seconds(seconds)
      .format('m[ min]');
  };

  const metersToKm = meters => {
    const km = meters / 1000;
    return `${km.toFixed(2)} km`;
  };

  const isPossibleToCancelPickup = () => {
    const statusOffer = pickupOfferDetail.statusDisplay;
    const statusNotCanceled = [FINISHED, CANCELLED, DROPPED, PICKED_UP];
    return !statusNotCanceled.includes(statusOffer);
  };

  const goToRouteMap = () => {
    const rc = getSelectedRoutingCode();
    history.push(
      `/${rc}${AUTHORIZED_ROUTES.OFFER.ROUTE_MAP.replace(':offerId', offerId)}`,
      {
        offerDetail: pickupOfferDetail
      }
    );
  };

  const goToInStreet = () => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${AUTHORIZED_ROUTES.TRACKING.IN_STREET}`);
  };

  const ButtonOfferComponent = () =>
    pickupOfferDetail.statusDisplay === FINISHED ? (
      <Button variant="outlined" color="primary" onClick={goToInStreet}>
        {TEXT.GO_TO_NA_RUA}
      </Button>
    ) : (
      <ButtonPopoverAssignOfferComponent offerId={offerId} />
    );

  return (
    <>
      <ConfirmDialog
        open={isConfirmDialogOpen}
        title={confirmDialogTypeMessages[confirmDialogType].title}
        description={confirmDialogTypeMessages[confirmDialogType].description}
      >
        <Button
          color="secondary"
          size="large"
          onClick={() => setIsConfirmDialogOpen(false)}
        >
          {confirmDialogTypeMessages[confirmDialogType].returnButton}
        </Button>
        <Button
          data-testid="buttonCanceled"
          color="secondary"
          variant="contained"
          onClick={() => {
            setIsConfirmDialogOpen(false);
            if (confirmDialogType === 'cancel') {
              callbackCancelOffer();
            } else {
              callbackDeallocateOffer();
            }
          }}
        >
          {confirmDialogTypeMessages[confirmDialogType].confirmButton}
        </Button>
      </ConfirmDialog>
      <CustomDrawer>
        <DrawerHeader
          title={
            pickupOfferDetail.demandType === DEMAND_TYPE.DELIVERY
              ? TEXT.TITLE_FOR_DELIVERY
              : TEXT.TITLE_FOR_PICKUP
          }
          status={{
            type: 'dot',
            label: pickupOfferDetail.statusDisplay,
            dotColor:
              STATUS_MAPPER[pickupOfferDetail.statusDisplay]?.style?.color
          }}
          subtitle={periodText}
          colorText={colors.root[0]}
          showDivider
          handleClosingDrawer={handleClosingDrawer}
        >
          <Box pt={2} display="flex">
            <Can
              actions={[
                ACTIONS.DRAWER_OFFER_COMPENSATION_VALUE,
                ACTIONS.DRAWER_OFFER_DRIVER_PHONE
              ]}
            >
              {!!pickupOfferDetail.compensationValue && (
                <Typography variant="h6">
                  <Box fontWeight={700} component="span">
                    {moneyFormat(pickupOfferDetail.compensationValue)}
                  </Box>
                </Typography>
              )}
            </Can>
            {!pickupOfferDetail.compensationValue &&
              !!pickupOfferDetail.regionName && (
                <Typography variant="h6">
                  <Box fontWeight={700} component="span">
                    {pickupOfferDetail.regionName}
                  </Box>
                </Typography>
              )}
            <Box
              ml={2}
              px={0.5}
              display="flex"
              alignItems="center"
              style={{ backgroundColor: colors.smoke[50] }}
            >
              #{pickupOfferDetail.bagSeal || offerId}
            </Box>
          </Box>
          <Box pt={3}>
            <Typography variant="subtitle2">
              {TEXT.GOOD_FOR} {pickupTransportType} • {packageCountText}
            </Typography>
          </Box>
          <Box>
            <Typography color="textSecondary">
              {buildAcceptedTime(pickupOfferDetail)}
            </Typography>
          </Box>
          {pickupOfferDetail.statusDisplay !== CANCELLED && (
            <Grid>
              <Box mt={3}>
                <ButtonOfferComponent />
              </Box>
            </Grid>
          )}
        </DrawerHeader>

        <DrawerContent showDivider>
          {!!pickupOfferDetail.driverId &&
            Number(pickupOfferDetail.driverId) !== 0 && (
              <Box pb={3} pt={3} display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box mr={1.5}>
                    <Avatar
                      src={pickupOfferDetail.driverPhoto}
                      style={{ height: '3em', width: '3em' }}
                    />
                  </Box>
                  <Box>
                    <Box mb={1}>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        style={{ fontWeight: 700 }}
                        data-testid="driverName"
                      >
                        {pickupOfferDetail.driverName}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        #{pickupOfferDetail.driverId}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Está de {driverTransportType}
                        <Can
                          actions={[
                            ACTIONS.DRAWER_OFFER_COMPENSATION_VALUE,
                            ACTIONS.DRAWER_OFFER_DRIVER_PHONE
                          ]}
                        >
                          • {pickupOfferDetail.driverMobilePhone}
                        </Can>
                      </Typography>
                    </Box>
                    {pickupOfferDetail.driverLegFromPickup && (
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          {TEXT.ARRIVES_IN}{' '}
                          <span>
                            {formatSeconds(
                              pickupOfferDetail.driverLegFromPickup.duration
                            )}
                          </span>{' '}
                          •
                          <span>
                            {` ${metersToKm(
                              pickupOfferDetail.driverLegFromPickup.distance
                            )} ${TEXT.DISTANCE}`}
                          </span>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box mt={2.5}>
                  {pickupOfferDetail.statusDisplay === WAITING_PICKUP && (
                    <Button
                      data-testid="btn-assign-offer-to-deliverer"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setIsConfirmDialogOpen(true);
                        setConfirmDialogType('deallocate');
                      }}
                      size="small"
                    >
                      {TEXT.DEALLOCATE_DRIVER_BUTTON}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          {(!pickupOfferDetail.driverId ||
            Number(pickupOfferDetail.driverId) === 0) && (
            <Box
              pb={3}
              pt={3}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Box mr={1.5}>
                <Avatar
                  style={{ height: '3em', width: '3em', color: '#EAEEF4' }}
                >
                  {' '}
                </Avatar>
              </Box>
              <Box>
                <Box mb={1}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    style={{ fontWeight: 700 }}
                    data-testid="availableFor"
                  >
                    {TEXT.AVAILABLE_FOR_ANYONE_WHO_IS_BY} {pickupTransportType}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    {TEXT.AWAITING_CONFIRMATION}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </DrawerContent>
        <DrawerContent showDivider>
          <Box pb={3} pt={3}>
            <Typography variant="body2" style={{ fontWeight: 700 }}>
              {pickupOfferDetail.companyName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {buildPickupOriginAddress(pickupOfferDetail)}
            </Typography>
            <Box pt={1}>
              <Typography variant="body2" color="textSecondary">
                {pickupOfferDetail.pickupOriginName} •{' '}
                {pickupOfferDetail.pickupOriginPhone}
              </Typography>
            </Box>
          </Box>
        </DrawerContent>
        {pickupOfferDetail.offerRoute && (
          <DrawerActions
            showDivider
            actions={[
              {
                label: TEXT.ROUTE_ON_MAP,
                callback: () => goToRouteMap()
              }
            ]}
          />
        )}
        {enableCancelOffer && isPossibleToCancelPickup() && (
          <DrawerActions
            showDivider
            actions={[
              {
                label: TEXT.CANCEL_DELIVERY_BOTTON,
                callback: () => {
                  setIsConfirmDialogOpen(true);
                  setConfirmDialogType('cancel');
                }
              }
            ]}
          />
        )}
      </CustomDrawer>
    </>
  );
}

DrawerComponent.propTypes = {
  handleClosingDrawer: PropTypes.func.isRequired,
  pickupOfferDetail: PropTypes.shape({
    companyName: PropTypes.string,
    shipperName: PropTypes.string,
    shipperPhone: PropTypes.string,
    driverId: PropTypes.number,
    driverMobilePhone: PropTypes.string,
    driverName: PropTypes.string,
    driverPhoto: PropTypes.string,
    statusDisplay: PropTypes.string,
    pickupTransportType: PropTypes.string,
    driverTransportType: PropTypes.string,
    pickupOriginName: PropTypes.string,
    pickupOriginPhone: PropTypes.string,
    driverLegFromPickup: PropTypes.shape({
      distance: PropTypes.number,
      duration: PropTypes.number
    }),
    demandType: PropTypes.string,
    compensationValue: PropTypes.number,
    bagSeal: PropTypes.string,
    offerRoute: PropTypes.shape({
      geometry: PropTypes.string,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          externalKey: PropTypes.string,
          displayId: PropTypes.string,
          legDistance: PropTypes.number,
          position: PropTypes.number
        })
      )
    }),
    regionName: PropTypes.string
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  callbackCancelOffer: PropTypes.func.isRequired,
  callbackDeallocateOffer: PropTypes.func.isRequired,
  offerId: PropTypes.string
};

DrawerComponent.defaultProps = {
  offerId: '0'
};
