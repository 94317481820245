import api from 'infra/api/leve';
import { getSelectedBase } from 'profile/profile.service';
import { getCompanyType } from 'auth/access-control/access-control.service';
import { QUERY_FIELD } from './base-collection.constants';

const urlAggregated = '/api/v2/last_mile/packages/aggregated';
const urlCompanies = `/api/v1/last_mile/getCompanies`;

const getQueryField = () => {
  const base = getSelectedBase();

  const viewFilterBy =
    base.last_mile_company.company_type === 'LAST_MILE_COMPANY_TYPE_LOGGI'
      ? QUERY_FIELD.AGENCIA
      : QUERY_FIELD.LEVE;

  return [base, viewFilterBy];
};

export const getAggregationsDeadline = async filters => {
  const [base, viewFilterBy] = getQueryField();

  return api
    .post(urlAggregated, {
      view: 'base',
      routing_code: base.distribution_center.routing_code,
      viewFilterBy,
      aggregatedField: 'base_by_deadline',
      company_filters: filters.senders,
      citiesFilter: filters?.cities,
      circlesFilter: filters?.circles,
      lastMileCompanyType: getCompanyType(true)
    })
    .then(_data => _data.data);
};

export const getAggregationsAttention = async filters => {
  const [base, viewFilterBy] = getQueryField();

  return api
    .post(urlAggregated, {
      view: 'base',
      routing_code: base.distribution_center.routing_code,
      viewFilterBy,
      aggregatedField: 'base_by_attention',
      company_filters: filters.senders,
      citiesFilter: filters?.cities,
      circlesFilter: filters?.circles,
      lastMileCompanyType: getCompanyType(true)
    })
    .then(_data => _data.data);
};

export const getAggregationsPosition = async filters => {
  const [base, viewFilterBy] = getQueryField();

  return api
    .post(urlAggregated, {
      view: 'base',
      routing_code: base.distribution_center.routing_code,
      viewFilterBy,
      aggregatedField: 'base_by_position',
      company_filters: filters.senders,
      citiesFilter: filters?.cities,
      circlesFilter: filters?.circles,
      lastMileCompanyType: getCompanyType(true)
    })
    .then(_data => _data.data);
};

export const getCompanies = async () => {
  return api
    .get(urlCompanies, {
      aggregatedField: 'company'
    })
    .then(res => {
      return res.data;
    });
};
