import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { TEXT } from './circles-drawer.constants';

const CirclesDeleteConfirmationDialog = ({ open, onConfirm, onCancel }) => {
  return (
    <Dialog open={open} data-testid="dialog-title">
      <DialogTitle>
        <Box pt={1}>
          <Typography variant="h6">
            <em>{TEXT.DELETE_DIALOG_TITLE}</em>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          data-testid="dialog-subtitle"
        >
          <Typography variant="body1">{TEXT.DELETE_DIALOG_SUBTITLE}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row-reverse' }}
          my={1.5}
        >
          <Box>
            <Button
              fullWidth
              data-testid="dialog-confirm"
              size="large"
              variant="contained"
              color="primary"
              onClick={onConfirm}
            >
              {TEXT.DELETE_DIALOG_CONFIRM}
            </Button>
          </Box>
          <Box pt={{ xs: 2.5, sm: 0 }} mr={{ xs: 0, sm: 1 }}>
            <Button
              fullWidth
              data-testid="dialog-back"
              size="large"
              variant="outlined"
              color="primary"
              onClick={onCancel}
            >
              {TEXT.DELETE_DIALOG_BACK}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

CirclesDeleteConfirmationDialog.defaultProps = {
  open: true
};

CirclesDeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default CirclesDeleteConfirmationDialog;
