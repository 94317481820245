import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';
import MomentUtils from '@date-io/moment';
import {
  LocalizationProvider,
  StaticDateRangePicker
} from '@material-ui/pickers';
import { Divider, Popover, Button, Box } from '@material-ui/core';
import {
  useStyles,
  anchorOrigin,
  transformOrigin
} from './date-range-picker.styles';
import { TEXTS, INITIAL_DATE_RANGE, TIME } from './date-range-picker.constants';

moment.locale('pt-BR');

const tratementDateToElastic = (date, time) => {
  return moment
    .utc(date)
    .locale('pt-BR')
    .set(time)
    .format();
};

function DateRangePickerComponent({
  handleTrackingService,
  initialStartDate,
  initialEndDate
}) {
  const [anchor, setAnchor] = useState(null);
  const classes = useStyles();
  const treatment = date =>
    date
      .locale('pt-BR')
      .format('DD MMM')
      .toLowerCase();

  const [startDateRange, setStartDateRange] = useState(
    treatment(
      initialStartDate
        ? moment.utc(initialStartDate)
        : moment().subtract(60, 'days')
    )
  );
  const [endDateRange, setEndDateRange] = useState(
    treatment(initialEndDate ? moment.utc(initialEndDate) : moment())
  );

  const [selectedDate, setSelectedDate] = useState(
    initialStartDate && initialEndDate
      ? [moment.utc(initialStartDate), moment.utc(initialEndDate)]
      : INITIAL_DATE_RANGE
  );

  const onDataRangeChoose = () => {
    setStartDateRange(treatment(moment(selectedDate[0])));
    setEndDateRange(
      selectedDate[1]
        ? treatment(moment(selectedDate[1]))
        : treatment(moment(selectedDate[0]))
    );

    const start = tratementDateToElastic(selectedDate[0], TIME.START_DAY);
    const end = selectedDate[1]
      ? tratementDateToElastic(selectedDate[1], TIME.END_DAY)
      : tratementDateToElastic(selectedDate[0], TIME.END_DAY);
    handleTrackingService(start, end);
  };

  const handleClick = event => {
    event.preventDefault();
    setAnchor(event.currentTarget);
  };

  const chooseDataRange = () => {
    setAnchor(null);
    onDataRangeChoose();
  };

  const open = Boolean(anchor);

  const clearCalendar = () => {
    setSelectedDate([moment().subtract(60, 'days'), moment()]);
  };

  return (
    <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentUtils}>
      <Button
        variant="outlined"
        onClick={handleClick}
        data-testid="btn-data-range"
      >
        {startDateRange}
        {' - '}
        {endDateRange}
      </Button>
      <Popover
        open={open}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        className={classes.popover}
      >
        <Box p={3.5}>
          <StaticDateRangePicker
            open={open}
            displayStaticWrapperAs="desktop"
            disableFuture
            value={selectedDate}
            onChange={date => setSelectedDate(date)}
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" px={3.5} py={2.5}>
          <Button onClick={clearCalendar} color="primary">
            {TEXTS.CLEAR}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={chooseDataRange}
            data-testid="btn-apply-filters"
          >
            {TEXTS.APPLY_FILTERS}
          </Button>
        </Box>
      </Popover>
    </LocalizationProvider>
  );
}

DateRangePickerComponent.propTypes = {
  handleTrackingService: PropTypes.func.isRequired,
  initialEndDate: PropTypes.string,
  initialStartDate: PropTypes.string
};

DateRangePickerComponent.defaultProps = {
  initialStartDate: null,
  initialEndDate: null
};

export default DateRangePickerComponent;
export { tratementDateToElastic };
