import api from 'infra/api/leve';
import { parseRequestError } from 'app/httpMessage';

export const CUSTOM_ERROR_MESSAGES = {
  404: 'Pacote não encontrado',
  500: 'Falha ao consultar os pacotes, tente novamente mais tarde'
};

const packageDetail = (packageId, loggikey) => {
  return api
    .get(`api/v2/last_mile/package/detail/`, {
      params: {
        package_id: packageId,
        loggi_key: loggikey
      }
    })
    .then(r => r.data)
    .catch(parseRequestError(CUSTOM_ERROR_MESSAGES));
};

export default packageDetail;
