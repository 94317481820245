import * as Sentry from '@sentry/browser';

/**
 * Returns a enhanced function that handles errors on operations
 * that use Dexie by logging the error on Sentry and returning a
 * more human-friendly error.
 *
 * @param {string} dbName
 * @param {func} fn - operation
 * @returns {func} - enhanced operation
 */
function handleDexieErrors(dbName, fn) {
  const enhancedFunction = async (...args) => {
    return fn(...args).catch(error => {
      Sentry.addBreadcrumb({
        category: 'db',
        message: `${dbName} ${error.name}`,
        level: 'fatal',
        type: 'error'
      });
      Sentry.captureException(error);
      throw new Error(`Erro no banco de dados ${dbName}. ${error.message}`);
    });
  };

  return enhancedFunction;
}

export default handleDexieErrors;
