import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { AUTHORIZED_ROUTES } from 'view/constants';
import { SUMMARY } from 'tracking/tracking.constants';
import { useStyles } from './empty-list.styles';

export const EmptyList = ({ children, text, buttonPath, buttonLabel }) => {
  const history = useHistory();

  const classes = useStyles();
  const onGoHomeClick = () => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${buttonPath}`);
  };
  return (
    <>
      <Box className={classes.iconWrapper} data-testid="empty-title">
        {children}
      </Box>
      {text && (
        <Typography
          className={classes.emptyText}
          variant="h4"
          data-testid="empty-content"
        >
          {text}
        </Typography>
      )}
      <Button
        className={classes.button}
        size="large"
        variant="outlined"
        onClick={onGoHomeClick}
      >
        {buttonLabel}
      </Button>
    </>
  );
};

EmptyList.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  buttonPath: PropTypes.string,
  buttonLabel: PropTypes.string
};

EmptyList.defaultProps = {
  buttonPath: AUTHORIZED_ROUTES.INFORMATION.TODAY,
  buttonLabel: SUMMARY.GO_TO_TODAY
};

export default EmptyList;
