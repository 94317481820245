import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { Box, Chip } from '@material-ui/core';

import ConfigurableTable from 'shared/configurable-table';
import FilterBar from 'shared/filter/filter.bar';
import { FILTERS } from 'shared/filter/filter.constants';
import FilterComponent from 'shared/filter';
import Pagination from 'shared/pagination';
import SelectComponent from 'shared/select';
import {
  Can,
  CanFunction,
  IsFSActiveForCurrentBase
} from 'auth/access-control';
import ConfigurableTableCollapsible from 'tracking/configurable-table-collapsible';
import CUSTODY_MAP, {
  CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW,
  CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP_AND_KEYWORDS,
  CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP_AND_KEYWORDS_AND_DELIVERER,
  CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW,
  CONFIGURABLE_TABLE_MERGE_BASE_WITH_BTN_GROUP_AND_DEAD_LINE_NEW_AND_KEYWORDS,
  CONFIGURABLE_TABLE_MERGE_BASE_WITH_BTN_GROUP_AND_DEAD_LINE_NEW_AND_KEYWORDS_AND_DELIVERER,
  getLayout
} from 'tracking/tracking.configuration';
import { KEY_CUSTODY } from 'tracking/tracking.constants';

import BaseContext from 'tracking/in-base/base.context';

import { SWITCHES, SWITCHES_REALTIME } from 'view/constants';
import { getCompanyType } from 'auth/access-control/access-control.service';

import FilterListIcon from '@material-ui/icons/FilterList';
import DrawerFilterContainer from 'shared/filter/drawer/drawer-filter.container';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';

import {
  getBaseListColumns,
  getBaseListColumnsDeliveryDeadlineNew,
  getBaseListColumnsLoading,
  getBaseListColumnsLoadingDeliveryDeadlineNew,
  ORDER_BY_LIST,
  ORDER_BY_LIST_DELIVERY_DEADLINE_NEW
} from './base-list.configuration';
import { TEXT } from './base-list.constants';

export default function BaseListComponent({
  onOrderChange,
  onViewChange,
  orderBy,
  loading,
  packages,
  changePagination,
  currentPage,
  numberOfPages,
  setCurrentPage,
  resetBaseListData,
  selectViewOptions,
  selectedView,
  handleClickCollapsible,
  isCollapsitable,
  callFetchGroupByUnitLoad,
  shouldUseActions
}) {
  const { custody } = useParams();
  const content = CUSTODY_MAP[custody];
  const renderState = getLayout(custody);

  const enableShowNewFilterComponentInBase = IsFSActiveForCurrentBase(
    SWITCHES.enableShowNewFilterComponentInBase
  );

  const {
    onApplyChanges,
    filters,
    checkedItemsBase,
    setCheckedItemsBase
  } = useContext(BaseContext);

  const enableAwaitingResolutionInNaBase =
    getCompanyType() === 'LEVE' &&
    custody === KEY_CUSTODY.NA_BASE_AGUARDANDO_TRATATIVA;

  const fsConfigTableNaBaseWithDeliverer = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsRealtimeEnableConfigTableNaBaseWithDeliverer
  );

  const configColumns = () => {
    if (shouldUseActions && enableAwaitingResolutionInNaBase) {
      return fsConfigTableNaBaseWithDeliverer
        ? CONFIGURABLE_TABLE_MERGE_BASE_WITH_BTN_GROUP_AND_DEAD_LINE_NEW_AND_KEYWORDS_AND_DELIVERER
        : CONFIGURABLE_TABLE_MERGE_BASE_WITH_BTN_GROUP_AND_DEAD_LINE_NEW_AND_KEYWORDS;
    }

    if (shouldUseActions) {
      return fsConfigTableNaBaseWithDeliverer
        ? CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP_AND_KEYWORDS_AND_DELIVERER
        : CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP_AND_KEYWORDS;
    }

    if (enableAwaitingResolutionInNaBase) {
      return CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW;
    }

    return content.tableConfigurationCollapsible;
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenDrawerFilter = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawerFilter = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="space-between" p={3} pt={4}>
          {enableShowNewFilterComponentInBase ? (
            <Box display="flex" flexWrap="wrap">
              {FilterBar({
                resetBaseListData,
                isCollapsitable
              })}
            </Box>
          ) : (
            <Box display="flex" flexWrap="wrap">
              {content.isSubView && (
                <Box mr={1}>
                  <SelectComponent
                    isViewSelect
                    onSelectedChange={onViewChange}
                    selected={selectedView}
                    selectOptions={selectViewOptions}
                  />
                </Box>
              )}

              <Can actions={[ACTIONS.FILTER_IN_VIEW_COLLECTION]}>
                <FilterComponent
                  key={FILTERS.SENDER}
                  btnFilter={FILTERS.SENDER}
                  onApplyChanges={item =>
                    onApplyChanges(item, resetBaseListData)
                  }
                  whichFilter={FILTERS.SENDER}
                  baseFilters={filters}
                  checkedItemsBase={checkedItemsBase}
                  setCheckedItemsBase={setCheckedItemsBase}
                />
              </Can>

              <Can actions={[ACTIONS.HAS_FILTER_CITIES_ENABLED]}>
                <CanFunction customDisabledTo="loggiPonto">
                  <FilterComponent
                    key={FILTERS.CITIES}
                    btnFilter={FILTERS.CITIES}
                    onApplyChanges={item =>
                      onApplyChanges(item, resetBaseListData)
                    }
                    whichFilter={FILTERS.CITIES}
                  />
                </CanFunction>
              </Can>

              <Can actions={[ACTIONS.HAS_CIRCLES_ENABLED]}>
                <CanFunction customDisabledTo="loggiPonto">
                  <FilterComponent
                    key={FILTERS.CIRCLES}
                    btnFilter={FILTERS.CIRCLES}
                    onApplyChanges={item =>
                      onApplyChanges(item, resetBaseListData)
                    }
                    whichFilter={FILTERS.CIRCLES}
                  />
                </CanFunction>
              </Can>

              <FilterComponent
                key={FILTERS.STATION}
                btnFilter={FILTERS.STATION}
                onApplyChanges={item => onApplyChanges(item, resetBaseListData)}
                whichFilter={FILTERS.STATION}
              />
              <CanFunction customDisabledTo="loggiPonto">
                <FilterComponent
                  key={FILTERS.FAULTS}
                  btnFilter={FILTERS.FAULTS}
                  onApplyChanges={item =>
                    onApplyChanges(item, resetBaseListData)
                  }
                  whichFilter={FILTERS.FAULTS}
                />
              </CanFunction>

              <FilterComponent
                key={FILTERS.STATUS}
                btnFilter={FILTERS.STATUS}
                onApplyChanges={item => onApplyChanges(item, resetBaseListData)}
                whichFilter={FILTERS.STATUS}
                status={CUSTODY_MAP[renderState].status}
              />
            </Box>
          )}
          <Box display="flex" flexWrap="wrap">
            {enableShowNewFilterComponentInBase && (
              <Box mr={1}>
                <Chip
                  label="Filtros"
                  variant="outlined"
                  color="primary"
                  size="small"
                  icon={<FilterListIcon />}
                  onClick={handleOpenDrawerFilter}
                />
                {isDrawerOpen && (
                  <DrawerFilterContainer
                    handleCloseDrawerFilter={handleCloseDrawerFilter}
                  />
                )}
              </Box>
            )}
            <SelectComponent
              onSelectedChange={onOrderChange}
              selected={orderBy}
              selectOptions={
                enableAwaitingResolutionInNaBase
                  ? ORDER_BY_LIST_DELIVERY_DEADLINE_NEW
                  : ORDER_BY_LIST
              }
              prefixLabel={TEXT.ORDER_BY}
            />
          </Box>
        </Box>
        <Box p={1} minHeight={44}>
          {isCollapsitable && !loading && (
            <ConfigurableTableCollapsible
              data={packages}
              columns={configColumns()}
              columnsChildren={
                enableAwaitingResolutionInNaBase
                  ? CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW
                  : content.tableConfigurationCollapsibleChildren
              }
              callbackNested={handleClickCollapsible}
              callFetchGroupByUnitLoad={callFetchGroupByUnitLoad}
            />
          )}
          {!isCollapsitable &&
            (!loading ? (
              <ConfigurableTable
                data={packages}
                columns={
                  enableAwaitingResolutionInNaBase
                    ? getBaseListColumnsDeliveryDeadlineNew()
                    : getBaseListColumns()
                }
                withPackageDrawer
              />
            ) : (
              <>
                {/* fill config table with fake data to show skeleton on its cell in loading state */}
                <ConfigurableTable
                  data={[{ id: 1 }, { id: 2 }]}
                  columns={
                    enableAwaitingResolutionInNaBase
                      ? getBaseListColumnsLoadingDeliveryDeadlineNew()
                      : getBaseListColumnsLoading()
                  }
                  withPackageDrawer
                />
              </>
            ))}
        </Box>
      </Box>

      <Pagination
        onPageChange={changePagination}
        initialPage={currentPage}
        numberOfPages={numberOfPages}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

const itemSelectViewModel = PropTypes.shape({
  label: PropTypes.string,
  custody: PropTypes.string
});

BaseListComponent.propTypes = {
  onOrderChange: PropTypes.func.isRequired,
  onViewChange: PropTypes.func.isRequired,
  orderBy: PropTypes.shape({
    value: PropTypes.string,
    header: PropTypes.string,
    sortBy: PropTypes.string
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  changePagination: PropTypes.func.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  resetBaseListData: PropTypes.func.isRequired,
  selectViewOptions: PropTypes.arrayOf(itemSelectViewModel).isRequired,
  selectedView: itemSelectViewModel,
  handleClickCollapsible: PropTypes.func.isRequired,
  isCollapsitable: PropTypes.bool.isRequired,
  callFetchGroupByUnitLoad: PropTypes.func.isRequired,
  shouldUseActions: PropTypes.bool.isRequired
};

BaseListComponent.defaultProps = {
  selectedView: undefined
};
