import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useStyles } from 'tracking/tracking.styles';

export default function CustomToggleButton({
  handleAlignment,
  withLargeUnitload,
  titleGroup,
  titlePackages,
  valueGroup,
  valuePackages,
  blueBackground
}) {
  const classes = useStyles();
  return (
    <ToggleButtonGroup
      value={withLargeUnitload ? valueGroup : valuePackages}
      exclusive
      onChange={handleAlignment}
      className={`${blueBackground ? classes.toggle : classes.toggleOfertas}`}
    >
      <ToggleButton
        value={valuePackages}
        aria-label="list packages"
        data-testid="toggle-packages"
      >
        {titlePackages}
      </ToggleButton>
      <ToggleButton
        value={valueGroup}
        aria-label="list unit-load"
        data-testid="toggle-groups"
      >
        {titleGroup}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

CustomToggleButton.propTypes = {
  handleAlignment: PropTypes.func.isRequired,
  withLargeUnitload: PropTypes.bool.isRequired,
  titleGroup: PropTypes.string.isRequired,
  titlePackages: PropTypes.string.isRequired,
  valueGroup: PropTypes.string.isRequired,
  valuePackages: PropTypes.string.isRequired,
  blueBackground: PropTypes.bool
};

CustomToggleButton.defaultProps = {
  blueBackground: false
};
