import React from 'react';
import PropTypes from 'prop-types';
import { set } from 'lodash';
import { useSnackbar } from 'notistack';
import deleteOldDeliverer from 'app/update-deliverer';
import DriversProfileComponent from './drivers-profile.component';
import { TEXTS } from './drivers-profile.constants';
import { copyLink, displaySnackBar } from '../drivers.utils';

import {
  availableForOfferService,
  disableDriverService,
  updateDriverService
} from '../drivers.service';

const DriverProfileContainer = ({
  hasDc,
  open,
  onClose,
  onMigrate,
  onDelete,
  onEdit,
  profile,
  setOpenProfile,
  shouldEdit,
  setShouldEdit
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handlePickupToggle = (id, available) =>
    availableForOfferService(id, available)
      .then(() => set(profile, 'deliverer.availableForOffer', available))
      .then(
        available &&
          displaySnackBar({
            variant: 'success',
            enqueueSnackbar,
            message: hasDc
              ? TEXTS.PICKUP_SNACKBAR_MESSAGE
              : TEXTS.PICKUP_SNACKBAR_MESSAGE_NO_DC
          })
      )
      .catch(displaySnackBar({ enqueueSnackbar }));

  const handleDeliveryClick = async () => {
    Promise.resolve(process.env.REACT_APP_FIREBASE_DRIVER_APP_URL)
      .then(copyLink)
      .then(
        displaySnackBar({
          variant: 'success',
          enqueueSnackbar,
          message: TEXTS.SNACKBAR_MESSAGE
        })
      )
      .catch(displaySnackBar({ enqueueSnackbar }));
  };

  const handleDisableDriver = driver => {
    const { isDriver, id, hasPackage } = driver?.deliverer;
    if (hasPackage) return null;
    const disableDriverFn = isDriver
      ? () => disableDriverService(id)
      : () => deleteOldDeliverer({ userId: id });

    return disableDriverFn()
      .then(onClose)
      .then(onDelete)
      .catch(displaySnackBar({ enqueueSnackbar }));
  };

  const handleUpdateDriver = driver => {
    return updateDriverService(driver)
      .then(onEdit)
      .catch(displaySnackBar({ enqueueSnackbar }));
  };

  return (
    <DriversProfileComponent
      open={open}
      hasDc={hasDc}
      onClose={onClose}
      profile={profile}
      onMigrate={onMigrate}
      onDelete={handleDisableDriver}
      onPickupToggle={handlePickupToggle}
      onDeliveryClick={handleDeliveryClick}
      setOpenProfile={setOpenProfile}
      onEdit={handleUpdateDriver}
      shouldEdit={shouldEdit}
      setShouldEdit={setShouldEdit}
    />
  );
};

DriverProfileContainer.propTypes = {
  hasDc: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  profile: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onMigrate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  setOpenProfile: PropTypes.func.isRequired,
  shouldEdit: PropTypes.bool,
  setShouldEdit: PropTypes.func.isRequired
};

DriverProfileContainer.defaultProps = {
  open: false,
  profile: {},
  shouldEdit: false
};

export default DriverProfileContainer;
