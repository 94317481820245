import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useStyles = makeStyles(({ typography }) => ({
  buttomPosTop: {
    top: typography.pxToRem(0)
  },
  smoke: {
    color: colors.smoke[700]
  }
}));

export default useStyles;
