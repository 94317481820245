import React, { useContext, useEffect, useState } from 'react';

import { colors } from '@loggi/mar';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Skeleton } from '@material-ui/lab';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { Can } from 'auth/access-control';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import { ReactComponent as PessoaBanco } from 'images/pessoa-banco.svg';
import { SummaryContext } from 'tracking/template/summary.context';

import { RoutinesManagementContext } from '../routines-management.context';
import {
  CIRCLES_ACTIONS,
  CIRCLES_CARD,
  EMPTY_CIRCLES_PAGE,
  SUMMARY_TITLE
} from './circles.constants';
import { useStyles } from './circles.styles';

export const CardFrameSkeleton = ({ numberCardFrame }) => {
  return (
    <Grid container>
      {[...Array(numberCardFrame).keys()].map(value => (
        <Grid item key={value}>
          <CardFrame
            title={<Skeleton variant="rect" height={20} width={200} />}
            people={[
              {
                id: value,
                name: <Skeleton variant="rect" height={24} width={100} />
              }
            ]}
            countExtraPeople={0}
            mainCity={<Skeleton variant="rect" height={42} width={30} />}
            countCity={0}
            loading
          />
        </Grid>
      ))}
    </Grid>
  );
};

CardFrameSkeleton.propTypes = {
  numberCardFrame: PropTypes.number.isRequired
};

export const CardFrame = ({
  title,
  people,
  countExtraPeople,
  mainCity,
  countCity,
  lastUpdateTime,
  loading,
  onEditCircleCallback
}) => {
  const [cardElevation, setCardElevation] = useState(0);
  const classes = useStyles();

  const setCardRaised = () => setCardElevation(14);
  const setCardGround = () => setCardElevation(0);
  let peopleMessage = '';
  if (countExtraPeople === 0 && people.length === 1)
    peopleMessage = people[0].name;
  else if (countExtraPeople === 0 && people.length === 2)
    peopleMessage = CIRCLES_CARD.DRIVER_PAIR(people);
  else if (countExtraPeople > 0 && people.length >= 2)
    peopleMessage = CIRCLES_CARD.DRIVERS_LIST(people);

  let extraPeopleMessage = '';
  if (countExtraPeople === 1) extraPeopleMessage = CIRCLES_CARD.EXTRA_DRIVER;
  else if (countExtraPeople > 1)
    extraPeopleMessage = CIRCLES_CARD.EXTRA_DRIVERS(countExtraPeople);

  const cityText =
    countCity === 1 ? mainCity : CIRCLES_CARD.CITIES_ATTENDED(countCity);
  return (
    <Box px={1} py={1}>
      <Paper
        component={Box}
        pl={3}
        pr={2}
        py={2.8}
        border={1}
        borderColor={colors.smoke[100]}
        elevation={cardElevation}
        onMouseOver={setCardRaised}
        onMouseOut={setCardGround}
        onFocus={setCardRaised}
        onBlur={setCardGround}
        onClick={onEditCircleCallback}
        width="362.67px"
        className={classes.pointer}
      >
        <Typography py={1} variant="button" color="textSecondary">
          <Box fontWeight="fontWeightMedium" component="span">
            {!loading && cityText}
          </Box>
        </Typography>

        <Typography py={1} variant="h6">
          {title}
        </Typography>

        <Box py={2} display="flex">
          {people.map(item => (
            <Avatar
              key={item.id}
              component="span"
              className={classes.customAvatar}
            >
              {loading && <Skeleton variant="rect" height={20} width={150} />}
              {!loading && item.name[0]}
            </Avatar>
          ))}
          <Typography variant="body1" style={{ marginLeft: '8px' }}>
            {peopleMessage}
            <em>{extraPeopleMessage}</em>
          </Typography>
        </Box>

        {loading && <Skeleton variant="rect" height={20} width={300} />}
        {!loading && (
          <Box pt={1} display="flex" justifyContent="space-between">
            <Typography variant="button" color="textSecondary">
              <Box fontWeight="fontWeightRegular" component="span">
                {Moment(lastUpdateTime).format(
                  CIRCLES_CARD.LAST_UPDATE_TIME_FORMAT
                )}
              </Box>
            </Typography>
            <ChevronRightIcon color="primary" />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

CardFrame.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      avatarSource: PropTypes.string
    })
  ).isRequired,
  countExtraPeople: PropTypes.number.isRequired,
  mainCity: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  countCity: PropTypes.number.isRequired,
  lastUpdateTime: PropTypes.instanceOf(Moment),
  loading: PropTypes.bool,
  onEditCircleCallback: PropTypes.func
};

CardFrame.defaultProps = {
  loading: false,
  lastUpdateTime: Moment(),
  onEditCircleCallback: () => {},
  mainCity: null
};

export const EmptyPage = () => {
  return (
    <Box paddingX="30%" paddingY="5%" alignItems="center">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box paddingBottom={2}>
            <PessoaBanco />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h5">
              <strong>{EMPTY_CIRCLES_PAGE.NO_DATA}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="body1" color="textSecondary">
              {EMPTY_CIRCLES_PAGE.NO_DATA_DESCRIPTION}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default function CirclesComponent({
  circles,
  cities,
  loading,
  onOpenCircleCreation,
  onEditCircleCallback
}) {
  const { setCardContent } = useContext(RoutinesManagementContext);
  const { setSummary } = useContext(SummaryContext);

  useEffect(() => {
    setSummary(
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <Grid item xs>
          <Typography variant="h4">
            <strong>{SUMMARY_TITLE.TITLE}</strong>
          </Typography>
          <Typography variant="h4">{SUMMARY_TITLE.SUBTITLE}</Typography>
        </Grid>
        <Grid item>
          <Can actions={[ACTIONS.CIRCLES_WRITE]}>
            <Button
              variant="outlined"
              color="default"
              size="medium"
              target="_blank"
              onClick={onOpenCircleCreation}
            >
              {CIRCLES_ACTIONS.CREATE_CIRCLE}
            </Button>
          </Can>
        </Grid>
      </Grid>
    );
  }, [setSummary, onOpenCircleCreation]);

  useEffect(() => {
    if (loading) {
      setCardContent(<CardFrameSkeleton numberCardFrame={2} />);
      return;
    }

    const translateCitiesName = city => {
      const validCities = cities.filter(c => c.id === city);
      if (validCities.length > 0) return validCities[0].label;
      return city;
    };

    if (circles === 0 || circles.length === 0) setCardContent(<EmptyPage />);
    else
      setCardContent(
        <Grid container>
          {circles.map(circle => (
            <Grid key={circle.id}>
              <CardFrame
                title={circle.name}
                people={
                  [...circle.driversData, ...circle.operatorsData].slice(
                    0,
                    2
                  ) || []
                }
                countExtraPeople={Math.max(
                  0,
                  circle.driversData.length + circle.operatorsData.length - 2
                )}
                mainCity={translateCitiesName(circle.cities[0])}
                countCity={circle.cities.length}
                lastUpdateTime={Moment(circle.updated)}
                onEditCircleCallback={() => onEditCircleCallback(circle)}
              />
            </Grid>
          ))}
        </Grid>
      );
  }, [circles, cities, loading, onEditCircleCallback, setCardContent]);

  return <></>;
}

CirclesComponent.propTypes = {
  circles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      cities: PropTypes.arrayOf(PropTypes.string),
      drivers: PropTypes.arrayOf(PropTypes.number),
      driversData: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          avatarSource: PropTypes.string
        })
      ).isRequired,
      operators: PropTypes.arrayOf(PropTypes.string),
      operatorsData: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          avatarSource: PropTypes.string
        })
      ).isRequired,
      updated: PropTypes.string
    }).isRequired
  ).isRequired,
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      count: PropTypes.number
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  onOpenCircleCreation: PropTypes.func,
  onEditCircleCallback: PropTypes.func
};

CirclesComponent.defaultProps = {
  onOpenCircleCreation: () => {},
  onEditCircleCallback: () => {}
};
