import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

export default function DrawerDisputeProtocol({ disputeProtocol }) {
  return (
    <Box data-testid="disputeProtocolComponent" mx={2}>
      <iframe
        title="Dispute Protocol"
        srcDoc={disputeProtocol}
        align="center"
        height="1200px"
        width="450px"
        frameBorder={0}
        scrolling="no"
      />
    </Box>
  );
}

DrawerDisputeProtocol.propTypes = {
  disputeProtocol: PropTypes.string
};

DrawerDisputeProtocol.defaultProps = {
  disputeProtocol: null
};
