import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { CustomDrawer, DrawerContent, DrawerHeader } from 'shared/drawer';
import useStylesDrawer from 'view/molecules/package-drawer/drawer.style';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import { Can } from 'auth/access-control';
import {
  getCirclesInformation,
  updateCircleUL
} from 'information/routines-management/circles/circles.service';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  ListItem,
  Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import ConfirmDialogComponent from 'shared/confirm-dialog';
import TEXT, { DIALOG } from './constants';

export default function DrawerCirclesGroupComponent({
  onBackDetailDrawer,
  onCloseDrawer,
  dataGroup,
  callFetchGroupByUnitLoad
}) {
  const classes = useStylesDrawer();

  const { enqueueSnackbar } = useSnackbar();

  const [circles, setCircles] = useState([]);
  const [loadingCircles, setLoadingCircles] = useState(true);

  const [circleWordFilter, setCircleWordFilter] = useState('');
  const [circleSelected, setCircleSelected] = useState('');

  const filteredCircles = circleWordFilter
    ? circles.filter(circle =>
        circle.name.toLowerCase().includes(circleWordFilter.toLowerCase())
      )
    : circles;

  const fetchCircles = useCallback(() => {
    getCirclesInformation()
      .then(result => {
        if (!result.circles) return;
        setCircles(result.circles);
      })
      .catch(error => {
        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      })
      .finally(() => setLoadingCircles(false));
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchCircles();
  }, [fetchCircles]);

  const onSubmit = ({ removeUl = false }) => {
    const circleId = removeUl ? dataGroup.circleId : circleSelected;

    updateCircleUL(circleId, dataGroup.licensePlate, removeUl)
      .then(() => {
        showSnackbar({
          withButton: false,
          message: removeUl
            ? TEXT.SNACK_BAR_UNDO_SUCESS_DIRECT
            : TEXT.SNACK_BAR_SUCESS_DIRECT_CIRCLE,
          variant: 'success',
          enqueueSnackbar
        });
        onCloseDrawer();
        callFetchGroupByUnitLoad();
      })
      .catch(error => {
        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      });
  };

  const showLabelCircle = circle => {
    /*
     * The field city_enum that will be returned from elasticSearch may not by
     * very cool. Fortunately it follows a pattern,
     * (for more details and examples, see the protos:
     * https://github.com/loggi/xproto/tree/62b279731b1d7b1fee71ef753fc706ca3be2551e/loggi/postalcodetable/v2)
     *
     * soon we can convert
     * for a label that represents the correct spelling of the city name
     *
     * searchResponse:
     *  {
     *    "aggregations" : {
     *     "city" : {
     *       "buckets" : [
     *         {
     *           "key" : "CITY_MG_MONTES_CLAROS",
     *           "doc_count" : 776
     *         },
     *         {
     *           "key" : "CITY_MG_JANAUBA",
     *           "doc_count" : 76
     *         },...
     *     ]}
     *  }
     *
     *  expected cityArray equals:
     *      CITY_MG_MONTES_CLAROS to Montes Claros
     *      CITY_MG_JANAUBA       to Janauba
     */

    if (circle.cities.length === 1) {
      const cityWoldArray = circle.cities[0].substring(8).split('_');
      const cityArrayCapitalize = cityWoldArray.map(i => {
        return i[0].toUpperCase() + i.slice(1).toLowerCase();
      });
      return cityArrayCapitalize.join(' ');
    }

    return `${circle.cities.length} cidades`;
  };

  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <ConfirmDialogComponent
        open={openDialog}
        title={DIALOG.CANCEL_DIRECT.TITLE}
        description={DIALOG.CANCEL_DIRECT.DESCRIPTION}
      >
        <Button
          onClick={() => setOpenDialog(false)}
          color="primary"
          data-testid="confirmation-dialog-cancel-btn"
        >
          {DIALOG.CANCEL_DIRECT.LABEL_BUTTON_LEFT}
        </Button>
        <Button
          onClick={() => onSubmit({ removeUl: true })}
          color="primary"
          variant="contained"
          data-testid="confirmation-dialog-submit-btn"
        >
          {DIALOG.CANCEL_DIRECT.LABEL_BUTTON_RIGHT}
        </Button>
      </ConfirmDialogComponent>

      <CustomDrawer open>
        <DrawerHeader
          open
          title={TEXT.CIRCLE}
          handleClosingDrawer={onBackDetailDrawer}
          subtitle={TEXT.CIRCLE_DESCRIBE}
          headerTitle={TEXT.GROUP_PACKAGES}
          showDivider
        />

        <DrawerContent>
          <Box pt={2.5}>
            <TextField
              disabled={loadingCircles}
              fullWidth
              InputProps={{
                'data-testid': 'search',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                )
              }}
              name="cityWordFilter"
              label="Buscar circulo"
              onChange={e => setCircleWordFilter(e.target.value)}
              value={circleWordFilter}
              variant="outlined"
            />
          </Box>
        </DrawerContent>

        <DrawerContent lessPadding>
          <Divider />
          {filteredCircles.map(circle => (
            <Box
              key={circle.id}
              data-testid={`${circle.id}-item-drawer-group-circle`}
            >
              <ListItem
                disableGutters
                button
                onClick={() => setCircleSelected(circle.id)}
                selected={
                  circle.id === circleSelected ||
                  (circle.id === dataGroup.circleId && !circleSelected)
                }
              >
                <Box
                  key={circle.id}
                  py={1}
                  px={4.5}
                  display="flex"
                  flexGrow={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Grid
                      spacing={2}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs>
                        <Typography variant="subtitle2">
                          <em>{circle.name}</em>
                        </Typography>
                        <Typography variant="subtitle2">
                          {showLabelCircle(circle)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {(circle.id === circleSelected ||
                    (circle.id === dataGroup.circleId && !circleSelected)) && (
                    <ArrowForwardIosIcon
                      className={classes.fontSizeIcon}
                      color="primary"
                      size="small"
                    />
                  )}
                </Box>
              </ListItem>
              <Divider />
            </Box>
          ))}
        </DrawerContent>

        {!dataGroup.circleId && (
          <DrawerContent>
            <Button
              color="primary"
              data-testid="submit-region-page"
              disabled={circleSelected === ''}
              fullWidth
              type="submit"
              variant="outlined"
              onClick={() => onSubmit({ removeUl: false })}
            >
              {TEXT.BTN_CONFIRMATION_DIRECT_CIRCLE}
            </Button>
          </DrawerContent>
        )}

        {dataGroup.circleId && (
          <DrawerContent>
            <Box display="flex">
              <Can actions={[ACTIONS.CIRCLES_WRITE_FOR_OPERATOR]} reverse>
                <Box flexGrow={1} mr={2}>
                  <Button
                    color="primary"
                    data-testid="submit-region-page"
                    fullWidth
                    type="submit"
                    onClick={() => setOpenDialog(true)}
                  >
                    {TEXT.CANCEL_DIRECT}
                  </Button>
                </Box>
              </Can>
              <Box flexGrow={2}>
                <Button
                  color="primary"
                  data-testid="submit-region-page"
                  disabled={
                    circleSelected === '' ||
                    circleSelected === dataGroup.circleId
                  }
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => onSubmit({ removeUl: false })}
                >
                  {TEXT.SAVE}
                </Button>
              </Box>
            </Box>
          </DrawerContent>
        )}
      </CustomDrawer>
    </>
  );
}

DrawerCirclesGroupComponent.propTypes = {
  onBackDetailDrawer: PropTypes.func.isRequired,
  onCloseDrawer: PropTypes.func.isRequired,
  dataGroup: PropTypes.shape({
    licensePlate: PropTypes.string,
    quantityPackages: PropTypes.number,
    circleName: PropTypes.string,
    circleId: PropTypes.string
  }).isRequired,
  callFetchGroupByUnitLoad: PropTypes.func.isRequired
};
