import api from 'infra/api/leve';
import { parseRequestError, RESPONSE_MESSAGES_BY_CODE } from 'app/httpMessage';
import {
  MOVE_PACKAGES_BULK_URL,
  URL_PROXY_V1_PREFIX,
  URL_API_V1_PACKAGE_GROUP_PREFIX,
  URL_API_V1_LAST_MILE_PACKAGES_BARCODE
} from 'infra/service/constants';
import { KEY_CUSTODY } from 'tracking/tracking.constants';
import CUSTODY_MAP from 'tracking/tracking.configuration';

import { BLOCK_PACKAGE_IS_ROUTED } from 'tracking/configurable-table-collapsible/drawer-group/allocated/constans';
import { translateStatusCode, DEFAULT_MESSAGE } from '../httpMessage';
import { handleResponseError } from './drawer-group-packages.utils';
import { getSelectedBase } from '../../profile/profile.service';
import ValidateBarcodeError from './custom-throw';

const appName = 'arco';

export const URL_API_V1_PACKAGE_GROUP_CREATE = `${URL_API_V1_PACKAGE_GROUP_PREFIX}/create`;
export const URL_API_V1_PACKAGE_GROUP_UPDATE = `${URL_API_V1_PACKAGE_GROUP_PREFIX}/update`;

export const CUSTOM_ERROR_MESSAGES = {
  404: 'Saca não encontrada',
  500: 'Falha ao consultar os pacotes, tente novamente mais tarde'
};

export const createGroupUnitload = async ({ selectedRoutingCode }) => {
  try {
    return await api.post(`/proxy/wms/api/v1/get-create-unitload`, {
      prefixLicensePlate: selectedRoutingCode
    });
  } catch (error) {
    throw handleResponseError(error);
  }
};

export const movePackagesToGroupService = async ({
  packages,
  sortingContextLpn,
  destinationLpn
}) => {
  try {
    const packageIds = packages.map(pkg => pkg.packageId);

    await api.post(`${URL_PROXY_V1_PREFIX}/base/sorting_context`, {
      licensePlate: sortingContextLpn
    });

    // This flag indicates that a group of packages are being
    // returned to Loggi. Meaning that these packages were
    // grouped into "to transfer" display in Arco.
    // We are adding this flag to trigger a new event in LW for
    // Packtrack called PackagesWaitingToBeReturnedEvent
    // https://github.com/loggi/loggi-web/pull/38585

    const toTransferMap = CUSTODY_MAP[KEY_CUSTODY.TO_TRANSFER];

    let toTransferFlag = '';
    let distributionCenterId = null;
    if (sortingContextLpn.includes(toTransferMap.sortingContextLicensePlate)) {
      toTransferFlag = toTransferMap?.flag;
      const base = getSelectedBase();
      // eslint-disable-next-line camelcase
      distributionCenterId = base?.distribution_center?.id
        ? // eslint-disable-next-line camelcase
          parseInt(base?.distribution_center?.id, 10)
        : null;
    }
    return await api.post(MOVE_PACKAGES_BULK_URL, {
      packageIds,
      destinationUnitLoadLicensePlate: destinationLpn,
      sortingContextLpn,
      sideEffectParams: {
        applicationName: appName,
        notes: toTransferFlag,
        distributionCenterId
      }
    });
  } catch (error) {
    throw handleResponseError(error);
  }
};

export const addPackageInList = async ({
  barcode,
  newPackagesInfo,
  currentPackagesInfo
}) => {
  const encodedBarcode = barcode ? encodeURIComponent(barcode) : barcode;

  return api
    .get(
      `${URL_API_V1_LAST_MILE_PACKAGES_BARCODE}${encodedBarcode}&should_check_with_nuvem=true`
    )
    .then(async response => {
      const { packages, barcodeReference } = response.data;

      if (barcodeReference !== 'BARCODE_REFERENCE_PACKAGE') {
        throw new ValidateBarcodeError(barcode);
      }

      const newPackagesInfoUpdated = newPackagesInfo;

      packages.forEach(pkg => {
        if (
          !(pkg.packageId in newPackagesInfo) &&
          !(pkg.packageId in currentPackagesInfo)
        ) {
          const packageData = {
            packageId: pkg.packageId,
            barcode: pkg.barcode
          };
          newPackagesInfoUpdated[pkg.packageId] = packageData;
        }
      });

      return newPackagesInfoUpdated;
    })
    .catch(error => {
      if (error?.response?.data?.includes(BLOCK_PACKAGE_IS_ROUTED.error)) {
        throw new Error(BLOCK_PACKAGE_IS_ROUTED.message);
      }

      if (error instanceof ValidateBarcodeError) {
        throw error;
      }

      const responseError = {
        code: error.response ? error.response.status : null,
        barcodeRead: encodedBarcode,
        message: error.response
          ? translateStatusCode(
              error.response.status,
              RESPONSE_MESSAGES_BY_CODE
            )
          : DEFAULT_MESSAGE
      };

      throw responseError;
    });
};

export const getPackagesByUnitLoad = licensePlate =>
  api
    .get(`proxy/last-mile/v1/leve/unit_load/${licensePlate}/packages/detail/`)
    .then(response => response.data)
    .catch(parseRequestError(CUSTOM_ERROR_MESSAGES));

export const getPackageSimplified = id =>
  api
    .get(`proxy/last-mile/v1/leve/package/${id}/grouping/info/`)
    .then(response => response.data)
    .catch(parseRequestError(CUSTOM_ERROR_MESSAGES));

export const createPackageGroup = async ({
  licensePlate,
  tags,
  isOperator
}) => {
  try {
    const response = await api.post(URL_API_V1_PACKAGE_GROUP_CREATE, {
      licensePlate,
      tags,
      markSomeCircleThisUser: isOperator
    });
    return response.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};

export const updatePackageGroup = async ({ id, licensePlate, tags }) => {
  try {
    const response = await api.put(URL_API_V1_PACKAGE_GROUP_UPDATE, {
      id,
      licensePlate,
      tags
    });
    return response.data;
  } catch (error) {
    throw handleResponseError(error);
  }
};
