import React from 'react';
import { Typography, Button, Grid, Box } from '@material-ui/core';
import { TEXT_ERROR } from '../finance.constants';

const CardAggregationError = () => {
  return (
    <Grid container spacing={6} data-testid="card-aggregation-content-error">
      <Grid item>
        <Box mr={5} maxWidth={250}>
          <Typography color="textSecondary" variant="body2">
            <em>{TEXT_ERROR.MSG_ERROR}</em>
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => window.location.reload()}
        >
          {TEXT_ERROR.BTN_ERROR}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CardAggregationError;
