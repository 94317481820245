import validatePasswordRecovery from '@loggi/authentication/src/locales/pt-br/validate-password-recovery.json';

export default {
  ...validatePasswordRecovery,
  headerContent: {
    title: 'Código enviado',
    subTitle: 'Veja o código que chegou no seu e-mail.'
  },
  inputsLabel: {
    verificationCode: 'Digite o código',
    password: 'Digite sua nova senha'
  }
};
