import React from 'react';

import PropTypes from 'prop-types';
import { Avatar, Box, Typography } from '@material-ui/core';
import useStyles from 'view/molecules/package-drawer/drawer.style';
import { TransportType } from 'in-street/collection/deliverers/deliverer_list_packages/constants';
import formatPhone from 'shared/formatters/phone/phone';

function DriverInfoContainer({ infoDriverAllocated }) {
  const classes = useStyles();

  const modal = data =>
    `Está de ${TransportType[data.modal] || 'modal.empty'} • ${formatPhone(
      data?.phone
    )}`;

  const label = 'Distribuído para:';

  return (
    <Box
      ml={2.5}
      my={4}
      display="flex"
      alignItems="start"
      flexDirection="column"
      data-testid="deliverer-allocated-group"
    >
      <Typography>{label}</Typography>
      <Box mt={2} display="flex">
        <Avatar className={classes.roundedImage} />
        <Box
          ml={2}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography>
            <em>{infoDriverAllocated?.driver?.name}</em>
          </Typography>
          <Typography color="textSecondary">
            {modal(infoDriverAllocated?.driver)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

DriverInfoContainer.propTypes = {
  infoDriverAllocated: PropTypes.shape().isRequired
};

export default DriverInfoContainer;
