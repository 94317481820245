import moment from 'moment';
import {
  TEXTS,
  ORDER_STATUS,
  ITINERARY_STATUS_ONGOING,
  ITINERARY_STATUS_CANCELLED
} from './drivers-tracking.constants';

export const isItineraryCancelled = status =>
  status === ORDER_STATUS.CANCELLED.value;

export const formatDate = date => {
  return moment(date)
    .locale('pt-BR')
    .format('DD MMM')
    .toLowerCase();
};

export const formatHour = (date, status) => {
  if (!date || isItineraryCancelled(status)) return '-';
  return moment(date)
    .locale('pt-BR')
    .format('LT');
};

export const formatPackages = (packages, status) => {
  if (!packages || isItineraryCancelled(status)) return '-';
  return (
    {
      1: `${packages} ${TEXTS.PACKAGE}`
    }[packages] || `${packages} ${TEXTS.PACKAGES}`
  );
};

export const getGroupedStatus = statusList => {
  const groupedStatus = status =>
    ({
      ongoing: ITINERARY_STATUS_ONGOING,
      cancelled: ITINERARY_STATUS_CANCELLED
    }[status] || [status]);

  return statusList.reduce(
    (acc, status) => [...acc, ...groupedStatus(status)],
    []
  );
};
