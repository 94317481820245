import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';

export default function LoadingSkeleton() {
  return (
    <Grid container spacing={10}>
      <Grid item>
        <Skeleton variant="rect" height={72} width={72} />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" height={32} width={42} />
        <br />
        <Skeleton variant="rect" height={19} width={100} />
      </Grid>
      <Grid item>
        <Skeleton variant="rect" height={32} width={42} />
        <br />
        <Skeleton variant="rect" height={19} width={100} />
      </Grid>
    </Grid>
  );
}
