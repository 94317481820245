import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PackageDrawer from 'view/molecules/package-drawer';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import { get } from 'tracking/tracking.configuration';

import useStyles from './configurable-table.styles';
import { ALERT_MESSAGE } from './configurable-table.constants';

export default function ConfigurableTable(props) {
  const {
    data,
    columns,
    renderHeader,
    withPackageDrawer,
    onRowSelected,
    systemUnstable
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const hasMissedEvent =
    data
      .map(item =>
        get(item, '_source.missed_promised_date_events.length', null)
      )
      .filter(Boolean).length > 0;
  const hasSyncing =
    data.map(item => item.isSyncing).filter(Boolean).length > 0;

  const newColumns = { ...columns };
  if (!hasMissedEvent) {
    delete newColumns.missedEventsBox;
  }
  if (!hasSyncing) {
    delete newColumns.syncing;
  }

  const keysColumns = Object.keys(newColumns);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const classes = useStyles();
  const [styleSelectRow, setStyleSelectRow] = useState(-1);

  const TableHeadMaker = () => {
    const headerList = Object.entries(newColumns).map(
      ([key, { header, headAlign }]) => {
        return (
          <TableCell
            align={headAlign}
            key={key}
            data-testid={`table-header-${key}`}
          >
            <Typography variant="body2">{header}</Typography>
          </TableCell>
        );
      }
    );
    return (
      <TableHead className={classes.tableHead}>
        <TableRow>{headerList}</TableRow>
      </TableHead>
    );
  };

  const _elementTable = (dataRow, columnsName) => {
    if ('component' in columns[columnsName]) {
      return columns[columnsName].component(dataRow);
    }
    if (columnsName in dataRow) {
      return dataRow[columnsName];
    }
    return '-';
  };

  const _handleOnClickRow = dataRow => {
    if (dataRow.isSyncing && dataRow.isRefuseNotFound) {
      showSnackbar({
        message: ALERT_MESSAGE,
        variant: 'warning',
        enqueueSnackbar
      });
    } else if (withPackageDrawer) {
      const packageId = dataRow.packageId ? dataRow.packageId : dataRow._id;
      const disputeId = dataRow.disputeId ? dataRow.disputeId : null;
      const loggiKey = dataRow._source?.package?.loggiKey || null;
      const newData = { ...dataRow, packageId, disputeId, loggiKey };
      setSelectedPackage(newData);
      setOpenDrawer(true);
      setStyleSelectRow(newData._id ? newData._id : newData.disputeId);
    }
    onRowSelected(dataRow);
  };

  const buildTableCellClassName = (isSyncing, key, isStatusColumn) => {
    const tableCellClassNameArray = [];

    tableCellClassNameArray.push(classes.ellipsis);

    if (withPackageDrawer) tableCellClassNameArray.push(classes.dataRow);
    if (isSyncing) tableCellClassNameArray.push(classes.syncing);
    if (key === styleSelectRow && openDrawer)
      tableCellClassNameArray.push(classes.rowSelected);
    if (isStatusColumn && systemUnstable)
      tableCellClassNameArray.push(classes.unstable);

    return tableCellClassNameArray.join(' ');
  };

  const TableBodyMaker = () => {
    const getTableRows = dataRow => {
      const key =
        dataRow?.id ||
        dataRow?._source?.package.id ||
        dataRow.email ||
        dataRow?.packageId;
      const { isSyncing } = dataRow;
      return (
        <TableRow key={key} hover>
          {keysColumns.map((columnsName, index) => {
            const isStatusColumn = columnsName === 'status';
            const propsStyle = columns[columnsName].propsStyleBody;
            const keyConcat = `${key}${index}`;

            return (
              <TableCell
                className={buildTableCellClassName(
                  isSyncing,
                  key,
                  isStatusColumn
                )}
                component="th"
                scope="row"
                onClick={() => _handleOnClickRow(dataRow)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...propsStyle}
                key={keyConcat}
                data-testid={`${keyConcat}${systemUnstable ? '-unstable' : ''}`}
              >
                {_elementTable(dataRow, columnsName)}
              </TableCell>
            );
          })}
        </TableRow>
      );
    };

    const tableBodyRows = data.map(item => {
      return getTableRows(item);
    });

    return <TableBody>{tableBodyRows}</TableBody>;
  };

  return (
    <>
      {openDrawer && (
        <PackageDrawer
          setOpen={setOpenDrawer}
          selectedPackage={selectedPackage}
        />
      )}
      <TableContainer component={Paper} className={classes.container}>
        <Table size="small">
          {renderHeader && <TableHeadMaker />}
          <TableBodyMaker />
        </Table>
      </TableContainer>
    </>
  );
}

ConfigurableTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  columns: PropTypes.objectOf(
    PropTypes.shape({
      header: PropTypes.string,
      headAlign: PropTypes.string,
      propsStyleBody: PropTypes.shape,
      component: PropTypes.element,
      reorder: PropTypes.string
    })
  ).isRequired,
  renderHeader: PropTypes.bool,
  withPackageDrawer: PropTypes.bool,
  onRowSelected: PropTypes.func,
  systemUnstable: PropTypes.bool
};

ConfigurableTable.defaultProps = {
  renderHeader: true,
  withPackageDrawer: false,
  onRowSelected: () => {
    // do nothing
  },
  systemUnstable: false
};

ConfigurableTable.displayName = 'ConfigurableTable';
