import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecord';
import {
  MAPPER_STATUS,
  STATUS_DEFAULT
} from './status-color-description.constants';

function StatusColorDescription(props) {
  const {
    status,
    statusDescription,
    typographyVariant,
    leftPadding,
    withoutDescription,
    preserveStatusDescription
  } = props;
  const statusMap = MAPPER_STATUS[status] || MAPPER_STATUS[STATUS_DEFAULT];
  const descriptionText = () => {
    if (!preserveStatusDescription() && (status === 89 || status === '89')) {
      return statusMap.text;
    }
    return statusDescription || statusMap.text;
  };
  const withDescription = !withoutDescription;
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <FiberManualRecordRoundedIcon style={statusMap.style} />
      {withDescription && (
        <Box pl={leftPadding}>
          <Typography noWrap variant={typographyVariant}>
            {descriptionText()}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

StatusColorDescription.propTypes = {
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  statusDescription: PropTypes.string,
  preserveStatusDescription: PropTypes.func,
  typographyVariant: PropTypes.string,
  leftPadding: PropTypes.number,
  withoutDescription: PropTypes.bool
};

StatusColorDescription.defaultProps = {
  status: null,
  statusDescription: MAPPER_STATUS[STATUS_DEFAULT].text,
  preserveStatusDescription: () => false,
  typographyVariant: 'h5',
  leftPadding: 2,
  withoutDescription: false
};

export default StatusColorDescription;
