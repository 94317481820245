import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

export default function DrawerProofOfDelivery({ proofOfDelivery }) {
  return (
    <Box>
      <iframe
        title="Proof of Delivery"
        srcDoc={proofOfDelivery}
        align="center"
        height="1000px"
        width="460px"
        frameBorder={0}
        scrolling="no"
      />
    </Box>
  );
}

DrawerProofOfDelivery.propTypes = {
  proofOfDelivery: PropTypes.string
};

DrawerProofOfDelivery.defaultProps = {
  proofOfDelivery: null
};
