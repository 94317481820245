import getLastMilePackagesByBarcode from 'app/get-last-mile-packages';
import movePackageList from 'app/move-package-list';

/**
 * This method intends to temporarily fix a movePackageList issue:
 * front-end have just the "barcode" value available
 * and movePackageList API expects "package_id"
 *
 * In order to retrieve the "package_id" for movePackageList call,
 * we execute getLastMilePackagesByBarcode before it and pass
 * the response forward directly into movePackageList parameters.
 *
 * With the change to use the proxy in MovePackageList we now need to
 * pass the user's Id inside UnitLoadInfo in all cases, so in the case
 * delivery_assignment is also necessary, so a new parameter was added
 * passing on this information.
 */

const getPackageIdMoveList = (
  process,
  destination,
  barcode,
  isPhyisicalEvidence = false,
  latitude = 0,
  longitude = 0,
  notes = '',
  userId = null
) =>
  getLastMilePackagesByBarcode(barcode).then(({ packages }) => {
    const packageInfo = packages.map(pkg => {
      const info = pkg;
      info.notes = notes;
      return info;
    });
    return movePackageList(
      process,
      destination,
      packageInfo,
      isPhyisicalEvidence,
      latitude,
      longitude,
      null,
      userId
    );
  });

export default getPackageIdMoveList;
