import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const CardInformation = ({ children, textResult, textBtn, url }) => {
  const history = useHistory();
  const goToDelivered = () => {
    history.push(url);
  };
  return (
    <>
      <Box mt={20} mb={8} data-testid="card-title">
        {children}
      </Box>
      {textResult && (
        <Typography variant="h4" data-testid="card-content">
          {textResult}
        </Typography>
      )}
      <Box my={8}>
        <Button size="large" variant="outlined" onClick={goToDelivered}>
          {textBtn}
        </Button>
      </Box>
    </>
  );
};

CardInformation.propTypes = {
  textResult: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  textBtn: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default CardInformation;
