import PropTypes from 'prop-types';

const model = {
  disputeId: PropTypes.number,
  deadline: PropTypes.string.isRequired,
  deliverer: PropTypes.string.isRequired,
  disputeResponsible: PropTypes.string.isRequired,
  packageBarcode: PropTypes.string.isRequired,
  situation: PropTypes.string.isRequired,
  disputeResponsibleId: PropTypes.number
};

export default model;
