import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

/**
 * @param children
 * @returns {*}
 * @constructor
 */
const Content = ({ children }) => {
  return (
    <Grid item xs={12}>
      {children}
    </Grid>
  );
};

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Content;
