const TEXT = {
  TITLE: 'Oferta',
  TITLE_FOR_PICKUP: 'Oferta para coletar',
  TITLE_FOR_DELIVERY: 'Oferta para entregar',
  GOOD_FOR: 'Bom para',
  AVAILABLE_FOR_ANYONE_WHO_IS_BY: 'Disponível para quem está de',
  AWAITING_CONFIRMATION: 'Aguardando aceite',
  CANCEL_DELIVER_MENSSAGE:
    'Esta oferta não vai mais ficar disponível para os entregadores.',
  CANCEL_DELIVERY_TITLE: 'Cancelar oferta?',
  CANCEL_DELIVERY_BOTTON: 'Cancelar oferta',
  ROUTE_ON_MAP: 'Visualizar no mapa',
  MESSAGE_SUCCESS_CANCEL_OFFER: 'Pronto. Oferta cancelada.',
  MESSAGE_ERROR_CANCEL_OFFER: 'Opa. Algo deu errado!',
  CANCEL_RETURN_BUTTON: 'Voltar',
  ARRIVES_IN: 'Chega em ',
  DISTANCE: 'de distância',
  GO_TO_NA_RUA: 'Ver na rua',
  DEALLOCATE_DRIVER_BUTTON: 'Desalocar entregador',
  DEALLOCATE_CONFIRM_MESSAGE:
    'Ao desalocar, o entregador não terá mais vinculo com esta oferta e ela voltará a ficar disponível para novo aceite.',
  DEALLOCATE_CONFIRM_TITLE: 'Desalocar o entregador desta oferta',
  DEALLOCATE_CONFIRM_BUTTON: 'Desalocar',
  DEALLOCATE_CONFIRM_BUTTON_RETURN: 'Cancelar',
  MESSAGE_SUCCESS_DEALLOCATED_DRIVER:
    'Tudo certo. O entregador foi desalocado e a oferta está disponível novamente.'
};

export const confirmDialogTypeMessages = {
  cancel: {
    title: TEXT.CANCEL_DELIVERY_TITLE,
    description: TEXT.CANCEL_DELIVER_MENSSAGE,
    confirmButton: TEXT.CANCEL_DELIVERY_BOTTON,
    returnButton: TEXT.CANCEL_RETURN_BUTTON
  },
  deallocate: {
    title: TEXT.DEALLOCATE_CONFIRM_TITLE,
    description: TEXT.DEALLOCATE_CONFIRM_MESSAGE,
    confirmButton: TEXT.DEALLOCATE_CONFIRM_BUTTON,
    returnButton: TEXT.DEALLOCATE_CONFIRM_BUTTON_RETURN
  }
};

export default TEXT;
