import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { useSnackbar } from 'notistack';
import showSnackbar from 'shared/snackbar';
import {
  TEXT,
  HIERARCHICAL_FALLBACK_LEVEL_UNUSED_VALUE
} from 'view/molecules/package-drawer/drawer.constants';
import { getDisputesData } from 'tracking/disputes/disputes.service';
import { getDriverService } from 'users-management/drivers/drivers.service';
import { DISPUTE_DATE_FORMAT } from 'tracking/disputes/disputes.constants';
import DrawerPackageDetail from './drawer-detail.component';
import listExhibition from './drawer-detail.configuration';
import {
  forceCreatePromisedDateService,
  getPackageIsAtRisk
} from './drawer-detail.service';
import updatePackageAddressService from '../drawer-update-address/drawer-update-address.service';

export default function DrawerDetail(props) {
  const {
    packageInfo,
    packageChargeDisplayInfo,
    packageHistory,
    showRefuse,
    showChangeDeliverer,
    showUpdateStatus,
    showChangeAddress,
    updatePackageInfoOnTheMachine,
    areQualityFlagsRemoved,
    disputeId,
    proofOfDelivery,
    disputeProtocol
  } = props;

  const {
    downloadCTE,
    refusePackage,
    changeDeliverer,
    changeAddress
  } = listExhibition(packageInfo);

  const enableChangePackageAddress = IsFSActiveForCurrentBase(
    'enableChangePackageAddress'
  );

  const { enqueueSnackbar } = useSnackbar();
  const [isAtRisk, setIsAtRisk] = useState(false);

  const [showingDispute, setShowingDispute] = useState(false);
  const [disputeData, setDisputeData] = useState();
  const [showingDriverInfo, setShowingDriverInfo] = useState(false);
  const [{ users = [] }, setdriverData] = useState([]);

  const endDate = moment(new Date())
    .add(10, 'days')
    .format(DISPUTE_DATE_FORMAT);
  const startDate = moment(endDate)
    .subtract(40, 'days')
    .format(DISPUTE_DATE_FORMAT);

  const changePackageAddress = changeAddress && enableChangePackageAddress;

  const forceCreatePromisedDate = () => {
    forceCreatePromisedDateService(packageInfo.integrationInfo.package_id)
      .then(() => {
        showSnackbar({
          message: TEXT.SUCCESS_FORCE_CREATE_PROMISED_DATE,
          enqueueSnackbar,
          withButton: true,
          variant: 'success'
        });
      })
      .catch(error => {
        showSnackbar({
          message: error.message,
          enqueueSnackbar,
          withButton: true,
          variant: 'error'
        });
      });
  };

  const removeQualityFlags = () => {
    const qualityFlags = {
      suspiciousGeocoding: false,
      hierarchicalFallbackLevel:
        packageInfo?.loggiAddress.qualityFlags.hierarchicalFallbackLevel
    };

    qualityFlags.hierarchicalFallbackLevel = HIERARCHICAL_FALLBACK_LEVEL_UNUSED_VALUE;

    updatePackageAddressService(
      packageInfo.pack.id,
      {
        ...packageInfo?.loggiAddress,
        qualityFlags
      },
      true
    )
      .then(() => {
        updatePackageInfoOnTheMachine();
      })
      .catch(response => {
        showSnackbar({
          variant: 'error',
          message: response?.message,
          enqueueSnackbar
        });
      });
  };

  useEffect(() => {
    getPackageIsAtRisk(packageInfo.integrationInfo.package_id)
      .then(response => {
        setIsAtRisk(response?.data?.isAtRisk);
      })
      .catch(() => setIsAtRisk(false));
  });

  useEffect(() => {
    if (!showingDispute && disputeId) {
      getDisputesData(startDate, endDate, 1, '', {
        key: disputeId ? 'dispute' : 'package',
        values: [disputeId ? `${disputeId}` : `${packageInfo?.pack.id}`]
      }).then(response => {
        const disputes = response?.disputes;
        if (disputes && Array.isArray(disputes) && disputes.length > 0) {
          setDisputeData(disputes[0]);
          setShowingDispute(true);
        }
      });
    }
  });

  useEffect(() => {
    if (
      !showingDriverInfo &&
      showingDispute &&
      disputeData?.disputeResponsibleId !== '0'
    ) {
      getDriverService(disputeData?.disputeResponsibleId).then(
        currentDriverData => {
          setdriverData(currentDriverData);
          setShowingDriverInfo(true);
        }
      );
    }
  });

  return (
    <DrawerPackageDetail
      packageInfo={packageInfo}
      packageChargeDisplayInfo={packageChargeDisplayInfo}
      packageHistory={packageHistory}
      changeStatus={showUpdateStatus}
      changeAddress={(changePackageAddress && showChangeAddress) || null}
      downloadCTE={downloadCTE}
      refusePackage={(refusePackage && showRefuse) || null}
      changeDeliverer={(changeDeliverer && showChangeDeliverer) || null}
      forceCreatePromisedDate={forceCreatePromisedDate}
      removeQualityFlags={removeQualityFlags}
      areQualityFlagsRemoved={areQualityFlagsRemoved}
      isAtRisk={isAtRisk || false}
      disputeData={disputeData}
      driverInfo={users[0]}
      proofOfDelivery={proofOfDelivery}
      disputeProtocol={disputeProtocol}
    />
  );
}

DrawerDetail.propTypes = {
  packageInfo: PropTypes.shape().isRequired,
  packageChargeDisplayInfo: PropTypes.shape().isRequired,
  packageHistory: PropTypes.func.isRequired,
  showRefuse: PropTypes.func.isRequired,
  showChangeDeliverer: PropTypes.func.isRequired,
  showUpdateStatus: PropTypes.func.isRequired,
  showChangeAddress: PropTypes.func.isRequired,
  updatePackageInfoOnTheMachine: PropTypes.func.isRequired,
  areQualityFlagsRemoved: PropTypes.bool.isRequired,
  disputeId: PropTypes.string,
  proofOfDelivery: PropTypes.func.isRequired,
  disputeProtocol: PropTypes.func.isRequired
};

DrawerDetail.defaultProps = {
  disputeId: null
};
