import React, { useEffect, useState } from 'react';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';

import { userService } from 'users-management/operators/operators.service';
import Detail from 'users-management/operators/detail';
import OperatorList from './operators-list.component';
import OperatorForm from '../form';

function OperatorListContainer() {
  const [users, setUsers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState();
  const [refresh, setRefresh] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});
  const DETAIL = 'detail';
  const FORM = 'form';

  useEffect(() => {
    userService()
      .then(responseUserList => setUsers(responseUserList.users))
      .catch(error => {
        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      })
      .finally(() => setLoading(false));
  }, [enqueueSnackbar, refresh]);

  const formatUserJson = user => {
    return {
      id: user?.lastMileOperator?.userId,
      role: user?.lastMileOperator?.role,
      fullName: user?.fullName,
      mobilePhone: user?.mobileNumber ? user?.mobileNumber : '',
      cpf: user?.lastMileOperator?.cpf ? user?.lastMileOperator?.cpf : '',
      ...user
    };
  };

  const onOpenFormInvitationCallback = () => {
    setState(FORM);
    setSelectedUser({});
  };

  const onOpenFormEditCallback = () => {
    setState(FORM);
  };

  const onCloseFormCallback = () => {
    setState('');
  };

  const onSubmitCallback = () => {
    onCloseFormCallback();
    setRefresh(temp => !temp);
    setLoading(true);
  };

  const handleOpenDetail = userData => {
    const user = {
      ...userData
    };
    setSelectedUser(formatUserJson(user));
    setState(DETAIL);
  };

  const handleCloseDetail = () => {
    setState('');
  };

  const onRevokeCallback = () => {
    setState('');
    setRefresh(temp => !temp);
    setLoading(true);
  };

  return (
    <>
      <OperatorList
        users={users}
        onOpenInvitaion={onOpenFormInvitationCallback}
        onOpenDetail={handleOpenDetail}
        loading={loading}
      />
      <OperatorForm
        open={state === FORM}
        onSubmitCallback={onSubmitCallback}
        handleClosingDrawer={onCloseFormCallback}
        user={selectedUser}
      />
      <Detail
        onRevokeCallback={onRevokeCallback}
        closeDrawer={handleCloseDetail}
        detail={selectedUser}
        isOpen={state === DETAIL}
        callbackEdit={onOpenFormEditCallback}
      />
    </>
  );
}

export default OperatorListContainer;
