import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { BRAZILIAN_STATES } from '../drawer-update-address.constants';

export default function BaseAddressFields({
  fields,
  address,
  onChangeBaseAddress
}) {
  return (
    <>
      <TextField
        fullWidth
        label={fields.neighborhood.text}
        variant="outlined"
        value={address[fields.neighborhood.key] ?? ''}
        onChange={e =>
          onChangeBaseAddress(fields.neighborhood.key, e.target.value)
        }
        inputProps={{ 'data-testid': 'base-address-neighborhood' }}
      />
      <TextField
        fullWidth
        label={fields.city.text}
        variant="outlined"
        value={address[fields.city.key] ?? ''}
        onChange={e => onChangeBaseAddress(fields.city.key, e.target.value)}
        inputProps={{ 'data-testid': 'base-address-city' }}
      />
      <Autocomplete
        options={BRAZILIAN_STATES}
        freeSolo
        fullWidth
        autoSelect
        value={address[fields.state.key] ?? ''}
        onChange={(_, value) => onChangeBaseAddress(fields.state.key, value)}
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            label={fields.state.text}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'base-address-state'
            }}
          />
        )}
      />
      <TextField
        fullWidth
        label={fields.postal_code.text}
        variant="outlined"
        value={address[fields.postal_code.key] ?? ''}
        onChange={e =>
          onChangeBaseAddress(fields.postal_code.key, e.target.value)
        }
        inputProps={{ 'data-testid': 'base-address-postalcode' }}
      />
    </>
  );
}

BaseAddressFields.defaultProps = { address: {} };

BaseAddressFields.propTypes = {
  onChangeBaseAddress: PropTypes.func.isRequired,
  fields: PropTypes.shape().isRequired,
  address: PropTypes.shape()
};
