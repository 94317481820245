import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { Box, Button, Typography, Paper } from '@material-ui/core';
import { DrawerHeader, DrawerContent } from 'shared/drawer';
import { ReactComponent as MapIcon } from 'images/ic-map.svg';
import { FeatureSwitchContext } from 'infra/firebase/feature-switch-provider';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  DRAWER_INITIAL,
  TIPS,
  CONVERT_NUMBER_TO_CATEGORIES
} from './complex-areas.constants';
import { ComplexAreasContext } from './complex-areas.provider';

const DrawerInitialComplexAreasComponent = () => {
  const {
    complexAreas,
    setCurrentComplexArea,
    setOpenCreateUpdateDrawerComplexAreas,
    setCreateAreaEvent,
    setAreaName
  } = useContext(ComplexAreasContext);

  const {
    enableLimitComplexAreasByContext,
    enableAgenciesWithMoreComplexAreas
  } = useContext(FeatureSwitchContext);

  const selectedRoutingCode = getSelectedRoutingCode();

  const routingCodeInAgenciesWithMoreComplexAreas = enableAgenciesWithMoreComplexAreas?.find(
    agency => agency.name.toString() === selectedRoutingCode
  );

  let limitComplexAreas;
  if (
    enableAgenciesWithMoreComplexAreas &&
    routingCodeInAgenciesWithMoreComplexAreas
  ) {
    limitComplexAreas = routingCodeInAgenciesWithMoreComplexAreas.value;
  } else {
    limitComplexAreas = enableLimitComplexAreasByContext;
  }

  const canCreateArea = limitComplexAreas
    ? complexAreas?.length < limitComplexAreas
    : true;

  const handleUpdateComplexArea = complexArea => {
    setCurrentComplexArea(complexArea);
    setOpenCreateUpdateDrawerComplexAreas(true);
    setAreaName(complexArea.label);
  };

  const history = useHistory();

  return (
    <>
      <DrawerHeader
        titleVariant="h5"
        title={DRAWER_INITIAL.TITLE}
        detail={DRAWER_INITIAL.DETAIL}
        colorText="textSecondary"
        showDivider
        variantTextDetail="body1"
        handleClosingDrawer={() => {
          const rc = selectedRoutingCode;
          history.push(`/${rc}/rotinas`);
        }}
      />
      <DrawerContent showDivider={canCreateArea}>
        {complexAreas?.length > 0 ? (
          Object.entries(complexAreas).map(([key, { label, category }]) => (
            <Button
              component={Box}
              elevation={10}
              mb={3}
              fullWidth
              size="large"
              border={1}
              borderColor="grey.100"
              style={{ borderRadius: 16 }}
              key={key}
              data-testid={`${key}-complex-area`}
              onClick={() => handleUpdateComplexArea(complexAreas[key])}
            >
              <Box flexGrow={1}>
                <Typography variant="subtitle2">{label}</Typography>

                {category > 0 && (
                  <Typography variant="body2" color="textSecondary">
                    <Box fontWeight="fontWeightBold">
                      {CONVERT_NUMBER_TO_CATEGORIES[category]}
                    </Box>
                  </Typography>
                )}
              </Box>
              <ChevronRightIcon color="disabled" />
            </Button>
          ))
        ) : (
          <Box data-testid="create-complex-areas">
            <Box mb={1} mt={2}>
              <Box display="flex" mr={1}>
                <MapIcon />
              </Box>
              <Box mb={1} mt={2} />
              <Typography color="textPrimary" variant="subtitle1">
                {DRAWER_INITIAL.CREATE_AREA}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography color="textSecondary" variant="body2">
                {DRAWER_INITIAL.CLICK_BUTTON}
              </Typography>
            </Box>
          </Box>
        )}
        {!canCreateArea && (
          <Box mb={3}>
            <Typography variant="subtitle2">
              <em>{DRAWER_INITIAL.CONTACT_SUPORT_FIRST_PART}</em>
              {DRAWER_INITIAL.CONTACT_SUPORT_SECOND_PART}
            </Typography>
          </Box>
        )}
        {canCreateArea && (
          <Box mt={2} mb={2}>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              data-testid="create-complex-area"
              onClick={() => {
                setCurrentComplexArea(null);
                setOpenCreateUpdateDrawerComplexAreas(true);
                setCreateAreaEvent(true);
              }}
            >
              {DRAWER_INITIAL.BUTTON}
            </Button>
          </Box>
        )}
      </DrawerContent>
      <DrawerContent>
        {canCreateArea && (
          <Box>
            <Typography color="textSecondary" variant="body2">
              <Box fontWeight="fontWeightMedium">{DRAWER_INITIAL.TIPS}</Box>
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              style={{ overflow: 'hidden', overflowX: 'scroll' }}
              maxHeight="200px"
              minWidth="400px"
            >
              {Object.entries(TIPS).map(([key, value]) => (
                <Paper
                  component={Box}
                  minHeight="143px"
                  minWidth="300px"
                  p={2.5}
                  border={1}
                  mt={2}
                  mr={2}
                  mb={4}
                  borderColor="grey.100"
                  style={{ borderRadius: 16 }}
                  key={key}
                >
                  <Typography variant="subtitle2">
                    <em>{key}</em>
                  </Typography>
                  <Box pt={1}>
                    <Typography variant="body2">{value}</Typography>
                  </Box>
                </Paper>
              ))}
            </Box>
          </Box>
        )}
      </DrawerContent>
    </>
  );
};

DrawerInitialComplexAreasComponent.propTypes = {};

export default DrawerInitialComplexAreasComponent;
