import React, { useContext } from 'react';
import BaseContext from 'tracking/in-base/base.context';
import { PropTypes } from 'prop-types';
import DrawerFilterComponent from './drawer-filter.component';
import {
  mockFilterStatus,
  mockFilterStation,
  mockFilterCities,
  mockFilterCircles,
  mockFilterSender,
  mockFilterFaults
} from './__mock__';

function DrawerFilterContainer(props) {
  const { handleCloseDrawerFilter } = props;

  const { filters } = useContext(BaseContext);
  const filterCities = {
    ...mockFilterCities,
    filtersChecked: filters.cities || []
  };
  const filterStatus = {
    ...mockFilterStatus,
    filtersChecked: filters.status || []
  };
  const filterStation = {
    ...mockFilterStation,
    filtersChecked: filters.station || []
  };
  const filterCircles = {
    ...mockFilterCircles,
    filtersChecked: filters.circles || []
  };

  return (
    <DrawerFilterComponent
      dateFilters={[
        filterCities,
        filterStatus,
        filterStation,
        filterCircles,
        mockFilterSender,
        mockFilterFaults
      ]}
      handleCloseDrawerFilter={handleCloseDrawerFilter}
    />
  );
}

DrawerFilterContainer.propTypes = {
  handleCloseDrawerFilter: PropTypes.func.isRequired
};

export default DrawerFilterContainer;
