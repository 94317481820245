export const DAY_ZERO = {
  TEXT:
    'Hoje tá tudo tranquilo, por enquanto é só aguardar a próxima transferência.',
  TEXT_BUTTON: 'Ver na base',
  URL: '/acompanhamento/na-base'
};

export const CONGRATS = {
  TEXT_RESULT: 'pacotes foram finalizados hoje. Bom trabalho!',
  TEXT_BUTTON: 'Ver pacotes finalizados',
  URL: '/acompanhamento/finalizados'
};

export default { DAY_ZERO, CONGRATS };
