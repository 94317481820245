import React, { useState, useEffect } from 'react';

import { getCompanyType } from 'auth/access-control/access-control.service';
import RoutinesComponent from './routines.component';
import getDistributionCenterInfo from './routines.service';

export default function RoutinesContainer() {
  const [routineInfo, setRoutineInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [showCirclesOnboarding, setShowCirclesOnboarding] = useState(false);
  const hasCirclesEnabled = getCompanyType() === 'LEVE';

  useEffect(() => {
    if (
      hasCirclesEnabled &&
      JSON.parse(localStorage.getItem('CIRCLES_ONBOARDING_COMPLETED')) !== true
    ) {
      setShowCirclesOnboarding(true);
      localStorage.setItem(
        'CIRCLES_ONBOARDING_COMPLETED',
        JSON.stringify(true)
      );
    }

    getDistributionCenterInfo().then(info => {
      setRoutineInfo(info);
      setLoading(false);
    });
  }, [hasCirclesEnabled, setLoading]);

  return (
    <RoutinesComponent
      loading={loading}
      routineInfo={routineInfo}
      showCirclesOnboarding={showCirclesOnboarding}
    />
  );
}
