import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { AUTHORIZED_ROUTES } from 'view/constants';
import { SummaryContext } from 'tracking/template/summary.context';
import Drawer from 'finance/drawer';
import { getAllPeriods } from 'finance/finance.service';

import { getSelectedRoutingCode } from 'auth/login/login.service';
import { END_DAY, START_DAY } from './summary-values.constants';
import {
  aggregatedPackageCharge,
  aggregatedPackageChargeThroughProxy
} from './summary-values.service';

import { SummaryValuesHeader, ErrorSummary } from './summary-values.component';
import { SummaryValuesOpenPeriod, OpenSkeleton } from './summary-values-open';
import {
  SummaryValuesClosedPeriod,
  ClosedSkeleton
} from './summary-values-closed';
import {
  SummaryValuesPreviousPeriods,
  PreviousSkeleton
} from './summary-values-previous';

function SummaryValuesContainer() {
  const { setSummary } = useContext(SummaryContext);

  const [periods, setPeriods] = useState({});
  const [loadingPeriods, setLoadingPeriods] = useState(true);
  const [openPeriodInfo, setOpenPeriodInfo] = useState({});
  const [loadingOpenInfo, setLoadingOpenInfo] = useState(true);
  const [closedPeriodInfo, setClosedPeriodInfo] = useState({});
  const [loadingClosedInfo, setLoadingClosedInfo] = useState(true);
  const [firstPrevious, setFirstPrevious] = useState({});
  const [loadingFirstPrevious, setLoadingFirstPrevious] = useState(true);
  const [secondPrevious, setSecondPrevious] = useState({});
  const [loadingSecondPrevious, setLoadingSecondPrevious] = useState(true);
  const [sinceBeginning, setSinceBeginning] = useState({});
  const [loadingSinceBeginning, setLoadingSinceBeginning] = useState(true);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();
  const goToPage = url => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${url}`);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const thereIsOpenPeriod = periods?.openInvoice;
  const thereIsClosedPeriod = periods?.lastClosedInvoice;
  const thereIsFirstPrevious = periods?.allIntervals?.slice(-1).pop();
  const thereIsSecondPrevious = periods?.allIntervals?.slice(-2)[0];
  const endOfPreviousPeriods = thereIsFirstPrevious?.end;

  useEffect(() => {
    setSummary(
      <>
        <SummaryValuesHeader />
        {error && <ErrorSummary />}
      </>
    );
  }, [error, setSummary]);

  useEffect(() => {
    if (loadingPeriods) {
      getAllPeriods()
        .then(data => setPeriods(data))
        .then(() => setLoadingPeriods(false))
        .catch(() => setError(true));
    }
  }, [loadingPeriods, periods]);

  useEffect(() => {
    if (!loadingPeriods && loadingOpenInfo && thereIsOpenPeriod) {
      aggregatedPackageCharge({
        startDate: thereIsOpenPeriod.start,
        endDate: moment()
          .subtract(1, 'days')
          .set(END_DAY)
          .utc()
          .format()
      })
        .then(data => setOpenPeriodInfo(data))
        .then(() => setLoadingOpenInfo(false))
        .catch(() => setError(true));
    }
  }, [loadingOpenInfo, loadingPeriods, thereIsOpenPeriod]);

  useEffect(() => {
    if (!loadingPeriods && loadingClosedInfo && thereIsClosedPeriod) {
      aggregatedPackageChargeThroughProxy({
        startDate: thereIsClosedPeriod.start,
        endDate: thereIsClosedPeriod.end
      })
        .then(data => setClosedPeriodInfo(data))
        .then(() => setLoadingClosedInfo(false))
        .catch(() => setError(true));
    }
  }, [loadingClosedInfo, loadingPeriods, thereIsClosedPeriod]);

  useEffect(() => {
    if (!loadingPeriods && loadingFirstPrevious && thereIsFirstPrevious) {
      aggregatedPackageCharge({
        startDate: thereIsFirstPrevious.start,
        endDate: thereIsFirstPrevious.end
      })
        .then(data => setFirstPrevious(data))
        .then(() => setLoadingFirstPrevious(false))
        .catch(() => setError(true));
    }
  }, [thereIsFirstPrevious, loadingPeriods, loadingFirstPrevious]);

  useEffect(() => {
    if (!loadingPeriods && loadingSecondPrevious && thereIsSecondPrevious) {
      aggregatedPackageCharge({
        startDate: thereIsSecondPrevious.start,
        endDate: thereIsSecondPrevious.end
      })
        .then(data => setSecondPrevious(data))
        .then(() => setLoadingSecondPrevious(false))
        .catch(() => setError(true));
    }
  }, [loadingPeriods, loadingSecondPrevious, thereIsSecondPrevious]);

  useEffect(() => {
    if (!loadingPeriods && loadingSinceBeginning) {
      aggregatedPackageCharge({
        startDate: moment()
          .dayOfYear(1)
          .set(START_DAY)
          .utc()
          .format(),
        endDate: endOfPreviousPeriods
      })
        .then(data => setSinceBeginning(data))
        .then(() => setLoadingSinceBeginning(false))
        .catch(() => setError(true));
    }
  }, [loadingPeriods, loadingSinceBeginning, endOfPreviousPeriods]);

  return (
    <>
      {openDrawer && (
        <Drawer
          valueNF={closedPeriodInfo}
          handleOpenDrawer={handleOpenDrawer}
        />
      )}
      {!error && loadingOpenInfo && <OpenSkeleton />}
      {!error && !loadingPeriods && !loadingOpenInfo && (
        <SummaryValuesOpenPeriod
          openStartDate={periods.openInvoice.start}
          openPeriodInfo={openPeriodInfo}
          redirectOpen={() => goToPage(AUTHORIZED_ROUTES.FINANCE.OPEN)}
        />
      )}
      {!error && loadingClosedInfo && <ClosedSkeleton />}
      {!error && !loadingPeriods && !loadingClosedInfo && (
        <SummaryValuesClosedPeriod
          closedPeriodDate={periods.lastClosedInvoice}
          closedPeriodInfo={closedPeriodInfo}
          handleOpenDrawer={handleOpenDrawer}
          redirectClosed={() => goToPage(AUTHORIZED_ROUTES.FINANCE.CLOSE)}
        />
      )}
      {!error &&
        loadingFirstPrevious &&
        loadingSecondPrevious &&
        loadingSinceBeginning && <PreviousSkeleton />}
      {!error &&
        !loadingPeriods &&
        !loadingFirstPrevious &&
        !loadingSecondPrevious &&
        !loadingSinceBeginning && (
          <SummaryValuesPreviousPeriods
            firstPreviousDate={thereIsFirstPrevious}
            firstPreviousInfo={firstPrevious.periodTotalValue}
            secondPreviousDate={thereIsSecondPrevious}
            secondPreviousInfo={secondPrevious.periodTotalValue}
            sinceBeginningInfo={sinceBeginning.periodTotalValue}
            redirectPrevious={() =>
              goToPage(AUTHORIZED_ROUTES.FINANCE.PREVIOUS)
            }
          />
        )}
    </>
  );
}

export default SummaryValuesContainer;
