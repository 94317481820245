import 'moment/locale/pt-br';
import {
  missedEventsBoxV2InStreet,
  deliveryDeadlineV2,
  packageV2,
  toV2,
  delivererV2,
  statusUpdatedV2,
  statusV2,
  chevronRight
} from 'shared/configurable-table/configuration/leve-v2';

export const PACOTES = 'pacotes';
export const PACOTES_ATRASADOS = `${PACOTES}/atrasados`;
export const PACOTES_INSUCESSOS = `${PACOTES}/insucessos`;
export const PACOTES_PARA_HOJE = `${PACOTES}/para-hoje`;

export const TITLE_VIEW = 'Na rua';

export const VIEW_CONFIG = {
  [PACOTES]: {
    subView: 'Pacotes'
  },
  [PACOTES_ATRASADOS]: {
    subView: 'Atrasados'
  },
  [PACOTES_INSUCESSOS]: {
    subView: 'Insucessos'
  },
  [PACOTES_PARA_HOJE]: {
    subView: 'Para hoje'
  }
};

export const CONFIGURABLE_TABLE_CONFIGURATION_NA_RUA = {
  missedEventsBoxV2InStreet,
  deliveryDeadlineV2,
  packageV2,
  toV2,
  delivererV2,
  statusUpdatedV2,
  statusV2,
  chevronRight
};

export const promisedDate = {
  header: 'Prazo',
  orderBy: 'promisedDate'
};

const deliverer = {
  header: 'Entregador',
  orderBy: 'deliverer'
};

export const city = {
  header: 'Cidade',
  orderBy: 'city'
};

export const updatedAt = {
  header: 'Atualizou em',
  orderBy: 'updatedAt'
};

export const status = {
  header: 'Status',
  orderBy: 'status'
};

export const ORDER_BY_VIEW_LIST_PACKAGES = [
  promisedDate,
  deliverer,
  city,
  updatedAt,
  status
];
