import firebase from 'firebase/app';
import 'firebase/remote-config';
import 'firebase/analytics';
import 'firebase/messaging';
import 'firebase/database';
import REMOTE_CONFIG_DEFAULT from './firebase.constants';
/**
 * This config file is a copy of targets/envios/src/configuration/firebase-init.js and it
 * will be extracted to a lib in the future.
 */
const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const appId = process.env.REACT_APP_FIREBASE_APP_ID;
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

const firebaseConfig = {
  apiKey,
  appId,
  authDomain,
  databaseURL,
  messagingSenderId,
  projectId,
  storageBucket,
  measurementId
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const database = firebase.database();

// Adding the [FCM - Firebase Cloud Messaging]
// messaging function, to the Arco service worker
const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
if (
  process.env.REACT_APP_ENABLE_SERVICE_WORKER === 'true' &&
  firebase.messaging.isSupported()
) {
  navigator.serviceWorker.register(swUrl).then(registration => {
    firebase.messaging().useServiceWorker(registration);
  });
}

const remoteConfig = firebase.remoteConfig();

// We are setting a lower fetch interval to react faster on support situations
// https://firebase.google.com/docs/remote-config/use-config-android#throttling
// As long as we don't introduce less than one hour intervals, we should be safe
// eslint-disable-next-line radix
remoteConfig.settings.minimumFetchIntervalMillis = parseInt(
  process.env.REACT_APP_FIREBASE_CONFIG_CACHE_INTERVAL_MS
);

remoteConfig.defaultConfig = REMOTE_CONFIG_DEFAULT;

export default remoteConfig;
