import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(({ spacing }) => ({
  grid: {
    minHeight: '100vh'
  },
  root: {
    backgroundImage: colors.gradients.sanches,
    color: 'white',
    position: 'relative'
  },
  alignLogo: {
    position: 'absolute',
    height: '200px'
  },
  errors: {
    color: colors.red[500],
    marginTop: spacing(1),
    marginLeft: spacing(1.5)
  },
  textfield: {
    width: '100%'
  },
  button: {
    width: '100%',
    '&:hover': {
      color: colors.blue[500]
    }
  },
  boxSpan: {
    width: '100%',
    textAlign: 'center',
    borderBottom: `2px solid ${colors.smoke[100]}`,
    lineHeight: '0.1em',
    margin: '10px 0 20px',
    borderColor: colors.smoke[100],
    paddingTop: spacing(4),
    '& span': {
      background: colors.smoke[50],
      padding: '0 20px'
    }
  },
  googleIcon: {
    marginRight: spacing(1.5)
  },
  span: {
    color: colors.smoke[700]
  },
  link: {
    paddingLeft: spacing(1),
    color: colors.blue[500],
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  },
  loader: {
    border: '16px solid #f3f3f3' /* Light grey */,
    borderTop: '16px solid #3498db' /* Blue */,
    borderRadius: '50%',
    width: '120px',
    height: '120px',
    margin: '60px auto',
    animation: '$spin 1.5s linear infinite'
  }
}));

export default useStyles;
