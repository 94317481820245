import { useState, useCallback } from 'react';

export const ITEMS_PER_PAGE_THIRTY = 30;
export const ITEMS_PER_PAGE_TEN = 10;

export default function usePagination(
  itemsPerPage = ITEMS_PER_PAGE_THIRTY,
  initialPage
) {
  const [currentPage, setCurrentPage] = useState(initialPage || 1);
  const [numberOfPages, setnumberOfPages] = useState(1);

  const setNumberOfItems = useCallback(
    numberOfItems => {
      setnumberOfPages(Math.ceil(numberOfItems / itemsPerPage));
    },
    [itemsPerPage]
  );

  const changePage = newPage => {
    setCurrentPage(newPage);
  };

  const reset = () => {
    setCurrentPage(1);
    setnumberOfPages(1);
  };

  return [
    { currentPage, numberOfPages },
    changePage,
    setNumberOfItems,
    reset,
    setCurrentPage
  ];
}
