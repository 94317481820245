import React from 'react';
import { Typography } from '@material-ui/core';

export default function VersionLabel() {
  function getTargetVersion() {
    const envVersion = process.env.REACT_APP_ARCO_VERSION || 'dev';
    if (envVersion === 'dev') {
      return 'dev';
    }

    // envVersion is a string that follows our deployment
    // tag, e.g. arco/prod-20000623.01(/beta)
    // the output of this function should be:
    // v01(/beta) • 23/06/2000
    const splitByDash = envVersion.split('-');
    const dateAndVersion = splitByDash[1];

    const date = dateAndVersion.split('.')[0];
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const formattedDate = `${day}/${month}/${year}`;

    const version = dateAndVersion.split('.')[1];
    const formattedVersion = `v${version}`;

    return `${formattedVersion} • ${formattedDate}`;
  }

  return (
    <Typography component="span" color="textSecondary" variant="caption">
      {getTargetVersion()}
    </Typography>
  );
}
