import React from 'react';
import Chip from '@material-ui/core/Chip';
import { Avatar, Box } from '@material-ui/core';
import { get } from 'finance/finance.configuration';
import formatPhone from 'shared/formatters/phone/phone';
import {
  PENDING_LABEL,
  ROLE_OWNER,
  ROLE_ADMIN,
  ROLE_REGULAR,
  ROLE_OPERATOR,
  USER_STATUS_PENDING
} from 'users-management/operators/operators.constants';
import colors from '@loggi/mar';

const constantToStatus = statusConst => {
  if (statusConst === USER_STATUS_PENDING) {
    return PENDING_LABEL;
  }
  return '';
};

export const constantToRole = roleConst => {
  const map = {
    ROLE_OWNER: ROLE_OWNER.LABEL,
    ROLE_ADMIN: ROLE_ADMIN.LABEL,
    ROLE_REGULAR: ROLE_REGULAR.LABEL,
    ROLE_OPERATOR: ROLE_OPERATOR.LABEL,
    ROLE_BS_RO: 'Lider agencia'
  };
  return map[roleConst] || 'Invalido';
};

const constantToRoleNumber = roleConst => {
  const map = {
    ROLE_OWNER: '1',
    ROLE_ADMIN: '2',
    ROLE_REGULAR: '3',
    ROLE_OPERATOR: '7'
  };
  return map[roleConst] || '0';
};

const phone = {
  header: 'Celular',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  orderDirection: 'asc',
  component: item => formatPhone(get(item, 'mobileNumber'))
};

const avatarAndName = {
  header: 'Nome',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  orderDirection: 'asc',
  component: item => {
    return (
      <Box
        component="span"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Avatar
          style={{
            color: colors.palette.text.primary,
            fontWeight: 'bold',
            backgroundColor: colors.palette.common.white,
            border: '1px solid',
            borderColor: colors.palette.grey[500]
          }}
          size="small"
        >
          {get(item, 'fullName')[0].toUpperCase()}
        </Avatar>
        <Box component="span" mr="auto" ml={2}>
          {get(item, 'fullName')}
        </Box>
      </Box>
    );
  }
};

const status = {
  header: '',
  headAlign: 'left',
  propsStyleBody: {
    align: 'left'
  },
  orderDirection: 'asc',
  component: item => {
    const dataStatus = constantToStatus(
      get(item, 'lastMileOperator.userStatus', '')
    );

    if (dataStatus) {
      return (
        <Chip
          style={{ borderRadius: '2px' }}
          size="small"
          label={dataStatus}
          disabled
        />
      );
    }
    const hasCpf = get(item, 'lastMileOperator.cpf', '');
    if (!hasCpf) {
      return (
        <Chip
          style={{ borderRadius: '2px' }}
          size="small"
          label="Cadastro incompleto"
          disabled
        />
      );
    }
    return <></>;
  }
};

const email = {
  header: 'E-mail',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  orderDirection: 'asc',
  component: item => get(item, 'email')
};

const userAccountRole = {
  header: 'Acesso',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  orderDirection: 'asc',
  component: item => {
    const accountRole = constantToRole(get(item, 'lastMileOperator.role', ''));

    // The Notificações label should appear, only on the permission form
    if (ROLE_ADMIN.LABEL === accountRole) {
      return ROLE_REGULAR.LABEL;
    }

    return accountRole;
  }
};

const CONFIGURABLE_TABLE = {
  avatarAndName,
  status,
  userAccountRole,
  email,
  phone
};

export { constantToRoleNumber };

export default CONFIGURABLE_TABLE;
