import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const CredentialsContext = createContext();

function CredentialsProvider({ children }) {
  const [credentials, setCredentials] = useState({});
  const [loading, setLoading] = useState(false);
  const values = { credentials, setCredentials, loading, setLoading };

  return (
    <CredentialsContext.Provider value={values}>
      {children}
    </CredentialsContext.Provider>
  );
}

CredentialsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { CredentialsContext };

export default CredentialsProvider;
