import { SWITCHES } from '../../view/constants';

const REMOTE_CONFIG_DEFAULT = {
  enable_performance_info: false,
  enable_return_screen_goes_to_elastic: false,
  enable_new_tracking_drivers: [],
  disable_button_download_report_for_dcs: [],
  enable_change_package_address: false,
  enable_cancel_offer: [],
  create_capacity_reserve: [],
  enable_new_data_create_invoice: false,
  enable_notifications: [],
  enable_awaiting_resolution_option_by_lmc: [],
  enable_activity_drawer: false,
  enable_filter_package_suspicious_location2: [],
  enable_hierarchical_fallback_level_flag: false,
  enable_allocate_driver: false,
  enable_firebase_cloud_messaging: false,
  enable_driver_creation_by_company_type: false,
  enable_limit_complex_areas_by_context: null,
  enable_agencies_with_more_complex_areas: null,
  enable_complex_areas: false,
  enable_driver_position: false,
  enable_demand_regions: [],
  enable_receive_package_in_view_collection_for_leve: [],
  enable_create_recomendation_message_for_drawer: [],
  enable_show_disputes_page: [],
  enable_update_dispute_responsible_button: [],
  ...Object.assign(
    {},
    ...Object.values(SWITCHES).map(key => ({ [key]: 'false' }))
  )
};

export const TOKEN_FCM = 'tokenFCM';

export default REMOTE_CONFIG_DEFAULT;
