import { RESPONSE_MESSAGES_BY_CODE } from 'app/httpMessage';
import { translateStatusCode, DEFAULT_MESSAGE } from '../httpMessage';

export const handleResponseError = error => {
  if (!error.response)
    return {
      code: null,
      message: DEFAULT_MESSAGE
    };

  const code = error.response.status;
  const message = error.response.data?.errorMsg
    ? error.response.data?.errorMsg
    : translateStatusCode(error.response.status, RESPONSE_MESSAGES_BY_CODE);

  return {
    code,
    message
  };
};

export default handleResponseError;
