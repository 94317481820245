import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { AUTHORIZED_ROUTES } from 'view/constants';
import { SummaryContext } from 'tracking/template/summary.context';
import CUSTODY_MAP from 'tracking/tracking.configuration';
import { useStyles } from 'tracking/tracking.styles';
import { generateCsvLink } from 'tracking/tracking.service';
import BaseContext from 'tracking/in-base/base.context';
import { TEXT } from './base-list.constants';
import BaseHeaderButtons from '../shared/base-header-buttons.component';

function BaseListHeaderComponent({
  setIsCollapsitable,
  isCollapsitable,
  setLoading,
  totalPackages,
  loading,
  setCurrentDrawer
}) {
  let { custody } = useParams();
  custody = custody?.replace('hidden/', '');

  const classes = useStyles();
  const content = CUSTODY_MAP[custody];
  const [downloadCsvLink, setDownloadCsvLink] = useState('');

  const history = useHistory();
  const { setSummary } = useContext(SummaryContext);

  const {
    setBackBaseListDeadline,
    setBackBaseListPosition,
    setBackBaseListAttention
  } = useContext(BaseContext);

  const { view, viewFilterBy } = content;
  useEffect(() => {
    (async () => {
      const csvLink = await generateCsvLink({ view, viewFilterBy });
      setDownloadCsvLink(csvLink);
    })();
  }, [view, viewFilterBy]);

  useEffect(() => {
    const handleBackCollections = () => {
      setBackBaseListDeadline(true);
      setBackBaseListPosition(true);
      setBackBaseListAttention(true);
      const rc = getSelectedRoutingCode();
      history.push(`/${rc}${AUTHORIZED_ROUTES.INFORMATION.BASE}`);
    };

    setSummary(
      <>
        <Grid
          container
          direction="row"
          alignItems="center"
          display="flex"
          justify="space-between"
        >
          <Grid item xs>
            {content.isSubView && (
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center" mr={1}>
                  <IconButton
                    size="small"
                    edge="start"
                    onClick={handleBackCollections}
                  >
                    <Box
                      color="primary.contrastText"
                      display="flex"
                      alignItems="center"
                    >
                      <ArrowBackRoundedIcon data-testid="back-base-button" />
                    </Box>
                  </IconButton>
                </Box>
                <Typography variant="h4">{`${content.title}`}</Typography>
                <Box ml={1}>
                  <Typography variant="h4">
                    <strong>{` / ${content.subView}`}</strong>
                  </Typography>
                </Box>
              </Box>
            )}
            {!content.isSubView && (
              <Typography variant="h4">
                <strong>{TEXT.BASE}</strong>
              </Typography>
            )}
            <Box mt={2}>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width="150px"
                  className={classes.skeleton}
                  height="42px"
                />
              ) : (
                <Typography variant="h4">{totalPackages} pacotes</Typography>
              )}
            </Box>
          </Grid>
          <Grid item>
            <BaseHeaderButtons
              downloadCsvLink={downloadCsvLink}
              setCurrentDrawer={setCurrentDrawer}
              showToggleButton={content.isSubView}
              isCollapsitable={isCollapsitable}
              setIsCollapsitable={setIsCollapsitable}
              setLoading={setLoading}
            />
          </Grid>
        </Grid>
      </>
    );
  }, [
    setSummary,
    content,
    history,
    isCollapsitable,
    totalPackages,
    setIsCollapsitable,
    setLoading,
    loading,
    classes.skeleton,
    downloadCsvLink,
    setBackBaseListDeadline,
    setBackBaseListPosition,
    setBackBaseListAttention,
    setCurrentDrawer
  ]);

  return null;
}

BaseListHeaderComponent.propTypes = {
  setIsCollapsitable: PropTypes.func.isRequired,
  isCollapsitable: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  totalPackages: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  setCurrentDrawer: PropTypes.func.isRequired
};

export default BaseListHeaderComponent;
