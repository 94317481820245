import React from 'react';
import { Typography, Grid, TextField, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import getPackageIdMoveList from 'app/get-package-id-move-list';

import showSnackbar from 'shared/snackbar';
import { Can } from 'auth/access-control';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { KEY_CUSTODY, TOGGLE, SUMMARY } from 'tracking/tracking.constants';
import ToggleButton from 'tracking/toggle-button/toggle-button.component';
import { playSuccess, playError } from 'tracking/sound/index';
import { getPosition } from 'tracking/card-receive-package/card-receive-package.service';
import DrawerGroupPackagesContainer from 'tracking/drawer-group-packages';
import CUSTODY_MAP from 'tracking/tracking.configuration';
import { PROCESS, DESTINATION, MESSAGE_STATE } from './constants';

import { useStyles } from './to-transfer.styles';

export function ReceiveComponent({ barcode, setBarcode }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isPhyisicalEvidence = false;

  const readBarcode = async currentBarcode => {
    try {
      const { latitude, longitude } = getPosition();

      await getPackageIdMoveList(
        PROCESS,
        DESTINATION,
        currentBarcode,
        isPhyisicalEvidence,
        latitude,
        longitude,
        `Pacote ${currentBarcode} incluído na lista de devolução para à Loggi`
      );

      showSnackbar({
        variant: 'success',
        message: MESSAGE_STATE,
        enqueueSnackbar
      });
      setBarcode('');
      playSuccess();
    } catch (error) {
      showSnackbar({
        message: error.message,
        variant: 'error',
        enqueueSnackbar
      });
      playError();
      setBarcode('');
    }
  };

  const onSubmit = event => {
    event.preventDefault();
    readBarcode(barcode)
      .then(() => {})
      .catch(() => {});
  };

  return (
    <form onSubmit={onSubmit}>
      <TextField
        variant="outlined"
        placeholder="Bipe pacotes e sacas que vão ser transferidos"
        required
        onChange={event => setBarcode(event.target.value)}
        inputProps={{
          minLength: 3,
          className: classes.input,
          'data-testid': 'receive-to-transfer-packages-component'
        }}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            return;
          }
          setBarcode(event.target.value);
        }}
        autoFocus
        value={barcode}
      />
    </form>
  );
}

export function SummaryComponent({
  setIsForUnitLoad,
  isForUnitLoad,
  setLoading,
  totalPackages,
  openDrawer,
  setOpenDrawer
}) {
  const custody = KEY_CUSTODY.TO_TRANSFER;
  const content = CUSTODY_MAP[custody];
  const destinationContext = content.sortingContextLicensePlate[0];
  const routingCode = getSelectedRoutingCode();

  return (
    <>
      {openDrawer && (
        <Can actions={[ACTIONS.TO_TRANSFER_READ]}>
          <DrawerGroupPackagesContainer
            routingCode={routingCode}
            destinationContext={destinationContext}
            handleClosingDrawer={() => {
              setOpenDrawer(false);
              setIsForUnitLoad(() => true);
              setLoading(() => true);
            }}
          />
        </Can>
      )}
      <Grid
        container
        direction="row"
        alignItems="center"
        display="flex"
        justify="space-between"
      >
        <Grid item xs>
          <Typography variant="h4">
            <strong>Para transferir</strong>
          </Typography>
          <Typography variant="h4">
            {totalPackages} Pacote{totalPackages > 1 ? 's' : ''}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="default"
                size="medium"
                target="_blank"
                onClick={() => setOpenDrawer(true)}
                data-testid="agrupar-call-to-action"
              >
                {SUMMARY.GROUPING}
              </Button>
            </Grid>
            <Grid item>
              <ToggleButton
                handleAlignment={() => {
                  setIsForUnitLoad(localIsForUnitLoad => !localIsForUnitLoad);
                  setLoading(true);
                }}
                withLargeUnitload={isForUnitLoad}
                titleGroup={TOGGLE.TITLE_GROUPS[KEY_CUSTODY.NA_BASE]}
                titlePackages={TOGGLE.TITLE_PACKAGES}
                valueGroup={TOGGLE.VALUE_GROUPS}
                valuePackages={TOGGLE.VALUE_PACKAGES}
                blueBackground
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

ReceiveComponent.defaultName = 'ReceiveComponent';
ReceiveComponent.propTypes = {
  barcode: PropTypes.string,
  setBarcode: PropTypes.func.isRequired
};
ReceiveComponent.defaultProps = {
  barcode: ''
};

SummaryComponent.defaultName = 'SummaryComponent';
SummaryComponent.propTypes = {
  isForUnitLoad: PropTypes.bool.isRequired,
  setIsForUnitLoad: PropTypes.func.isRequired,
  totalPackages: PropTypes.number,
  setLoading: PropTypes.func.isRequired,
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired
};
SummaryComponent.defaultProps = {
  totalPackages: 0
};
