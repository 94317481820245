import moment from 'moment';
import 'moment/locale/pt-br';
import { ACTIONS_BY_PERIOD } from './cells-component/deadline-cell/deadline-cell.constants';

function getTotalFor(name, aggregationResult) {
  return (
    aggregationResult.groupItemResponse?.find(i => i.name === name)?.total || 0
  );
}

function buildAcceptedTime(detail) {
  return detail.allocatedTime
    ? moment(detail.allocatedTime)
        .locale('pt-BR')
        .format('[Aceita às ]HH:mm')
    : '';
}

function buildPeriodText(detail) {
  const startDate = moment(detail.scheduledStartDateTime).locale('pt-BR');
  const endDate = moment(detail.scheduledEndDateTime).locale('pt-BR');

  const periodObject = Object.values(ACTIONS_BY_PERIOD).find(actionObj =>
    actionObj.isBetweenRange(startDate, endDate)
  );

  return periodObject.formatDetailText(startDate, endDate);
}

function buildDeadlineText(deadlineTime) {
  const startDate = moment(new Date()).locale('pt-BR');
  const endDate = moment(deadlineTime).locale('pt-BR');

  const periodObject = Object.values(ACTIONS_BY_PERIOD).find(actionObj =>
    actionObj.isBetweenRange(startDate, endDate)
  );

  return periodObject.formatDeadlineText(endDate);
}

function moneyFormat(number) {
  return Intl.NumberFormat('pt', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(number);
}

export {
  getTotalFor,
  buildAcceptedTime,
  buildPeriodText,
  buildDeadlineText,
  moneyFormat
};
