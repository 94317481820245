export const TEXT = {
  PROFILE: {
    BEFORE_NAME: 'Fala,',
    AFTER_NAME: '! 👋',
    LOGOUT: 'Sair da plataforma',
    CANCEL: 'Cancelar',
    SEE_OTHER_BASE: 'Veja outra base',
    PLACEHOLDER: 'Busque por localização',
    HELPER_LIST: 'Busque por sigla, região ou nome da base.'
  }
};
export const FLOWS = {
  PROFILE_INITIAL: 'base',
  CHANGE_BASE: 'change'
};
export const LIMIT_ROWS = 20;
export const PROFILE_SUBMENU = [
  {
    name: 'Ver outra base',
    flows: FLOWS.CHANGE_BASE
  }
];

export const INFO_DC = [
  {
    last_mile_company: {
      id: '2',
      identification: 'leve_sp_0',
      name: 'Leve SP LTDA'
    },

    distribution_center: {
      id: '3',
      name: 'V. Mariana / SP',
      slug: 'dc_leve_sp_0',
      abbrev: 'LSP',
      routing_code: 'LSP'
    }
  },

  {
    last_mile_company: {
      id: '1',
      identification: 'loggi_tower_0',
      name: 'Loggi Tecnologia'
    },

    distribution_center: {
      id: '1000',
      name: 'Alameda Santos - Loggi / SP',
      slug: 'dc_loggi_tower_0',
      abbrev: 'LT0',
      routing_code: 'LT0'
    }
  },
  {
    last_mile_company: {
      id: '12',
      identification: 'xpto_0',
      name: 'XPTO',
      routing_code: 'XPT'
    },

    distribution_center: {
      id: '123',
      name: 'São José do Rio Preto / SP',
      slug: 'dc_loggi_tower_0',
      abbrev: 'SJR',
      routing_code: 'SJR'
    }
  },
  {
    last_mile_company: {
      id: '13',
      identification: 'xptozk_0',
      name: 'XPTOZK'
    },

    distribution_center: {
      id: '1345',
      name: 'Almirante Tamandaré',
      slug: 'dc_loggi_tower_0',
      abbrev: 'ALT',
      routing_code: 'ALT'
    }
  },

  {
    last_mile_company: {
      id: '14',
      identification: 'xptozkwq_0',
      name: 'XPTOZKWQ'
    },

    distribution_center: {
      id: '14567',
      name: 'Ferraz de Vasconcelos / SP',
      slug: 'dc_loggi_tower_0',
      abbrev: 'FRV'
    }
  },
  {
    last_mile_company: {
      id: '15',
      identification: 'carrier_tower_1',
      name: 'Carrier'
    }
  }
];
export const ERRORS = {
  MORE_THAN_ONE_CHILD:
    'Profile component must receive only one child of Profile type',
  LESS_THAN_ONE_CHILD: 'Profile component must receive a Profile as child'
};

export default { TEXT, PROFILE_SUBMENU, INFO_DC, FLOWS, ERRORS };
