import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Box, Grid } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { TEXTS } from './drivers-profile.constants';
import useStyles from '../drivers.styles';

const EditDriverButton = ({ handleEditForm }) => {
  const styles = useStyles();

  return (
    <Grid>
      <Box
        component="a"
        onClick={handleEditForm}
        display="flex"
        justifyContent="space-between"
        className={styles.boxButton}
      >
        <Box component="span">
          <Typography variant="body1">{TEXTS.EDIT_REGISTER_BUTTON}</Typography>
        </Box>
        <ArrowForwardIosIcon color="primary" size="small" />
      </Box>
    </Grid>
  );
};

EditDriverButton.propTypes = {
  handleEditForm: PropTypes.func.isRequired
};

export default EditDriverButton;
