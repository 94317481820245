/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Typography, Paper, Grid, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { colors } from '@loggi/mar';
import formatNumber from 'shared/formatters/number';
import PropTypes from 'prop-types';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { TEXT } from './base-collection.constants';

export const CardFrameSkeleton = ({ title, numberCardFrame }) => {
  return (
    <CardBase title={title}>
      <Grid container>
        {[...Array(numberCardFrame).keys()].map(value => (
          <Grid item key={value}>
            <CardFrame
              title={<Skeleton variant="rect" height={24} width={100} />}
              packageNumber={<Skeleton variant="rect" height={42} width={30} />}
              url={<Skeleton variant="rect" height={21} width={90} />}
            />
          </Grid>
        ))}
      </Grid>
    </CardBase>
  );
};

CardFrameSkeleton.propTypes = {
  title: PropTypes.string.isRequired,
  numberCardFrame: PropTypes.number.isRequired
};

export const CardFrame = ({ title, packageNumber, url }) => {
  const [cardElevation, setCardElevation] = useState(0);

  const history = useHistory();
  const goToPage = urlTarget => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${urlTarget}`);
  };

  const setCardRaised = () => setCardElevation(14);
  const setCardGround = () => setCardElevation(0);

  return (
    <Box px={1} pb={1}>
      <Paper
        component={Box}
        pl={4}
        py={2.8}
        border={1}
        borderColor={colors.smoke[100]}
        elevation={cardElevation}
        onMouseOver={setCardRaised}
        onMouseOut={setCardGround}
        onFocus={setCardRaised}
        onBlur={setCardGround}
        onClick={() => goToPage(url)}
        width="264px"
      >
        <Typography variant="h4">
          <Box fontWeight="fontWeightMedium" component="span">
            {typeof packageNumber === 'number'
              ? formatNumber(packageNumber)
              : packageNumber}
          </Box>
        </Typography>

        <Typography variant="body1">{title}</Typography>

        <Box fontWeight="fontWeightMedium" pt={2}>
          {typeof url === 'string' ? (
            <Link
              component="button"
              onClick={() => goToPage(url)}
              style={{ fontWeight: 600, fontFamily: 'Open Sans' }}
            >
              {TEXT.CARD_FRAME_BUTTON}
            </Link>
          ) : (
            url
          )}
        </Box>
      </Paper>
    </Box>
  );
};

CardFrame.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  packageNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.node])
    .isRequired,
  url: PropTypes.oneOfType([PropTypes.number, PropTypes.node]).isRequired
};

export const CardBase = ({ title, children }) => {
  return (
    <Box pb={4}>
      <Box display="flex" pl={1} pb={3}>
        <Typography variant="h6">
          <em>{title}</em>
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

CardBase.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
