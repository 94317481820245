import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  DialogActions,
  IconButton,
  Drawer
} from '@material-ui/core';
import HeaderDialog from 'shared/onboarding-dialog/header-dialog.component';
import { ReactComponent as CloseIcon } from 'images/close-icon.svg';
import ContentDialog from 'shared/onboarding-dialog/content-dialog.component';
import SkipHeader from 'shared/onboarding-dialog/skip-header.component';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import PropTypes from 'prop-types';
import {
  BACK,
  SKIP,
  CONTINUE,
  LETS_GO
} from './onboarding-drawer.configuration';

function FirstScreenComponent({ screen, callback, onClose }) {
  const ComponentImageSvg = screen.component;

  return (
    <>
      <HeaderDialog>
        <IconButton
          onClick={onClose}
          data-testid={`first-screen-close-${screen.title}`}
        >
          <CloseIcon color="default" />
        </IconButton>
      </HeaderDialog>
      <Box component="div" display="flex" flexDirection="column">
        <ContentDialog
          title={screen.title}
          subtitle={screen.subtitle}
          description={screen.description}
        >
          <ComponentImageSvg />
        </ContentDialog>
        <Box mt={11} display="flex" ml={3} flexDirection="column" py={3}>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => callback()}
              fullWidth
              data-testid={`go-pages-screen-${screen.title}`}
            >
              {LETS_GO}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </>
  );
}

FirstScreenComponent.propTypes = {
  screen: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    component: PropTypes.elementType.isRequired
  }).isRequired,
  callback: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

function PagesScreenComponent({
  pages,
  callback,
  actionAfterFinishOnboarding,
  onClose,
  setViewAllOnboarding
}) {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [lastPage, setLastPage] = useState(false);

  const handleGoNextPage = () => {
    if (lastPage) {
      actionAfterFinishOnboarding();
      return;
    }
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handleGoPreviousPage = () => {
    if (currentPageIndex === 0) {
      callback();
    } else {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  const ComponentImageSvg = pages[currentPageIndex].component;

  useEffect(() => {
    if (currentPageIndex === pages.length - 1) {
      setLastPage(true);
      setViewAllOnboarding();
    } else {
      setLastPage(false);
    }
  }, [currentPageIndex, pages.length, setViewAllOnboarding]);

  return (
    <>
      <HeaderDialog>
        <SkipHeader
          title={SKIP}
          handleClick={() => {
            setViewAllOnboarding();
            onClose();
          }}
        >
          <Box fontSize={8}>
            {pages.map((page, index) => {
              return (
                <FiberManualRecordRoundedIcon
                  key={page.title}
                  color={index === currentPageIndex ? 'primary' : 'disabled'}
                  fontSize="inherit"
                />
              );
            })}
          </Box>
        </SkipHeader>
      </HeaderDialog>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        data-testid="onboarding-pages-screen"
      >
        <Box mb={3}>
          <ContentDialog
            title={pages[currentPageIndex].title}
            description={pages[currentPageIndex].description}
          >
            <Box mb={5}>
              <ComponentImageSvg />
            </Box>
          </ContentDialog>
        </Box>
        <Box mt={10} display="flex" ml={3} flexDirection="column" py={5}>
          <DialogActions>
            <Button
              color="primary"
              size="medium"
              onClick={() => handleGoPreviousPage()}
              data-testid={`btn-pages-${BACK}`}
            >
              {BACK}
            </Button>
            <Button
              color="primary"
              variant={lastPage ? 'contained' : 'outlined'}
              size="large"
              onClick={() => handleGoNextPage()}
              fullWidth
            >
              {lastPage
                ? pages[currentPageIndex].labelLastBtnConfirm
                : CONTINUE}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </>
  );
}

PagesScreenComponent.defaultProps = {
  setViewAllOnboarding: () => {}
};

PagesScreenComponent.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      description: PropTypes.string.isRequired,
      component: PropTypes.elementType.isRequired,
      labelLastBtnConfirm: PropTypes.string
    })
  ).isRequired,
  callback: PropTypes.func.isRequired,
  actionAfterFinishOnboarding: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setViewAllOnboarding: PropTypes.func
};

export default function CirclesOnboardingDrawer(props) {
  const {
    configurations,
    onClose,
    actionAfterFinishOnboarding,
    setViewAllOnboarding
  } = props;

  const [showWhatScreen, setShowWhatScreen] = useState('FIRST_SCREEN');

  return (
    <Drawer
      open
      anchor="right"
      onClose={onClose}
      data-testid={`drawer-onboarding-shared-${
        configurations.KEY_LOCAL_STORAGE
      }`}
    >
      <Box component="div" display="flex" height="100%">
        <Box
          maxWidth={480}
          width={480}
          minWidth={480}
          role="presentation"
          component="div"
          mr={2}
          ml={2}
        >
          {showWhatScreen === 'FIRST_SCREEN' ? (
            <FirstScreenComponent
              screen={configurations.FIRST_SCREEN}
              onClose={onClose}
              callback={() => setShowWhatScreen('PAGES_SCREEN')}
            />
          ) : (
            <PagesScreenComponent
              pages={configurations.PAGES}
              callback={() => setShowWhatScreen('FIRST_SCREEN')}
              actionAfterFinishOnboarding={actionAfterFinishOnboarding}
              onClose={onClose}
              setViewAllOnboarding={setViewAllOnboarding}
            />
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

CirclesOnboardingDrawer.defaultProps = {
  setViewAllOnboarding: () => {}
};

CirclesOnboardingDrawer.propTypes = {
  configurations: PropTypes.shape({
    FIRST_SCREEN: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
        .isRequired
    }),
    PAGES: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        component: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
          .isRequired
      })
    ),
    KEY_LOCAL_STORAGE: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  actionAfterFinishOnboarding: PropTypes.func.isRequired,
  setViewAllOnboarding: PropTypes.func
};
