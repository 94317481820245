import React from 'react';
import 'moment/locale/pt-br';
import MissedEventsBox from 'shared/missed-events-box';
import { colors } from '@loggi/mar';
import { get } from 'tracking/tracking.configuration';
import { Avatar, Box, Typography } from '@material-ui/core';
import StatusColorDescription from 'shared/status-color-description';
import moment from 'moment';
import { Icon } from '@loggi/loggi-icons';

const missedEventsComponentV2 = item => {
  const missedCount = get(item, 'faults', null);
  return missedCount ? <MissedEventsBox count={missedCount} /> : null;
};

export const missedEventsBoxV2InStreet = {
  propsStyleBody: { align: 'left' },
  component: item => missedEventsComponentV2(item)
};

export const statusV2 = {
  header: 'Status',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => (
    <StatusColorDescription
      status={get(item, 'status.code', null)}
      statusDescription={get(item, 'status.text', null)}
      typographyVariant="body2"
      preserveStatusDescription={() => true}
    />
  )
};

export const delivererV2 = {
  header: 'Entregador',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, 'deliverer.name', '-')
};

export const delivererV2ViewDeliverers = {
  header: 'Entregador',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, 'name', '-')
};

export const delivererWithIconV2 = {
  header: 'Entregador',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  orderDirection: 'asc',

  component: item => (
    <Box
      component="span"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Avatar
        style={{
          color: colors.smoke[900],
          fontWeight: 'bold',
          backgroundColor: colors.root[0],
          border: '1px solid',
          borderColor: colors.smoke[500]
        }}
        size="small"
      >
        {get(item, 'name', '-')[0].toUpperCase()}
      </Avatar>
      <Box component="span" mr="auto" ml={2}>
        {get(item, 'name', '-')}
      </Box>
    </Box>
  )
};

const packageComponent = item => (
  <Box>
    <Box>{get(item, 'sender', null)}</Box>
    <Typography style={{ color: colors.root[500] }} variant="caption">
      {get(item, 'barcode', '-')}
    </Typography>
  </Box>
);

export const packageV2 = {
  header: 'Pacote',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => packageComponent(item)
};

const noWrap = string => (
  <Typography variant="body2" noWrap>
    {string}
  </Typography>
);

const noWrapDate = (item, field, format) => {
  const promisedDatetime = get(item, field, null);
  const strDate =
    promisedDatetime && promisedDatetime !== 'null'
      ? moment(promisedDatetime)
          .locale('pt-BR')
          .format(format)
      : '-';

  return noWrap(strDate);
};

export const noWrapDateRelativeTime = (item, field) => {
  moment.updateLocale('pt-br', {
    relativeTime: {
      future: 'em %s',
      past: '%s atrás',
      s: 'alguns segundos',
      ss: '%d segundos',
      m: 'um minuto',
      mm: '%d minutos',
      h: '1 hora',
      hh: '%d horas',
      d: 'um dia',
      dd: '%d dias',
      w: 'uma semana',
      ww: '%d semanas',
      M: 'um mês',
      MM: '%d meses',
      y: 'um ano',
      yy: '%d anos'
    }
  });
  const promisedDatetime = get(item, field, null);
  const strDate =
    promisedDatetime && promisedDatetime !== 'null'
      ? moment(promisedDatetime)
          .locale('pt-BR')
          .fromNow()
      : '-';

  return noWrap(strDate);
};

export const deliveryDeadlineV2 = {
  header: 'Entrega',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => noWrapDate(item, 'promisedDate', '[Até] DD MMM')
};

export const statusUpdatedV2 = {
  header: 'Atualizou em',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => noWrapDate(item, 'updatedAt', 'DD MMM [às] HH:mm')
};

const toComponent = item => (
  <Box
    maxWidth={210}
    style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}
  >
    <Box>{get(item, 'destination.recipient.name', null)}</Box>
    <Typography
      style={{
        color: colors.root[500]
      }}
      variant="caption"
    >
      {get(item, 'destination.address.street', '-')}
      {', '}
      {get(item, 'destination.address.number', '-')}
      {', '}
      {get(item, 'destination.address.neighborhood', '-')}
    </Typography>
  </Box>
);

export const toV2 = {
  header: 'Para',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => toComponent(item)
};

export const update = {
  header: 'Atualização',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => noWrapDateRelativeTime(item, 'updatedAt')
};

export const progress = {
  header: 'Progresso',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => {
    const progressTotal = get(item, 'totalPackages', null);
    const progressFinished = get(item, 'deliveredPackages', null);

    let percentage = get(item, 'percentageProgress', null);
    percentage = percentage > 0 ? percentage : null;

    return (
      <ProgressComponent
        percentage={percentage}
        progressFinished={progressFinished}
        progressTotal={progressTotal}
      />
    );
  }
};

// eslint-disable-next-line react/prop-types
const ProgressComponent = ({ percentage, progressFinished, progressTotal }) => {
  return (
    <Box display="flex" alignItens="center">
      <Box
        width="8px"
        height="42px"
        style={{ backgroundColor: colors.smoke[100] }}
        alignItems="end"
        display="flex"
        mr={1}
        borderRadius={15}
      >
        <Box
          borderRadius={15}
          height={(42 * percentage) / 100}
          style={{ backgroundColor: colors.green[500] }}
          width="100%"
        />
      </Box>
      <Box>
        {percentage ? (
          <Typography variant="body2">{percentage}%</Typography>
        ) : (
          <Typography color="textSecondary" variant="body2">
            Não iniciado
          </Typography>
        )}
        <Typography variant="caption" style={{ color: colors.smoke[700] }}>
          Entregou {progressFinished} de {progressTotal}
        </Typography>
      </Box>
    </Box>
  );
};

export const chevronRight = {
  header: '',
  headAlign: 'left',
  propsStyleBody: { align: 'center', padding: 'none' },
  component: () => (
    <Icon name="ChevronRight" size="medium" color={colors.blue[500]} />
  )
};

export const withAlert = {
  header: 'Com alerta',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => {
    let delayed = get(item, 'delayedPackages', null);
    delayed = delayed > 0 ? delayed : null;

    let insuccess = get(item, 'unsuccessfulPackages', null);
    insuccess = insuccess > 0 ? insuccess : null;

    return <WithAlertComponent delayed={delayed} insuccess={insuccess} />;
  }
};

// eslint-disable-next-line react/prop-types
const WithAlertComponent = ({ delayed, insuccess }) => {
  return (
    <>
      {delayed && (
        <Box>
          {delayed}
          {` `} {delayed > 1 ? 'atrasados' : 'atrasado'}
        </Box>
      )}
      {insuccess && (
        <Box>
          {insuccess}
          {` `} {insuccess > 1 ? 'insucessos' : 'insucesso'}
        </Box>
      )}
    </>
  );
};
