import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import { DrawerContent, DrawerHeader, CustomDrawer } from 'shared/drawer';
import { PACKAGE_DIRECTION_RETURN } from 'view/molecules/package-drawer/drawer.constants';
import {
  HEADER_TEXT,
  CONTENT_TEXT,
  VIEWS_DRAWER
} from '../drawer-group-packages.constants';
import KeywordInput from './keyword-input.component';

export default function DrawerGroupPackagesKeyword({
  currentPackagesInfo,
  newPackagesInfo,
  loading,
  keywords,
  setKeywords,
  movePackagesToGroup,
  setShowViewDrawer,
  setOpenCloseConfirmationDialog,
  updateKeywords
}) {
  const isEdit = Object.keys(currentPackagesInfo).length > 0;

  const chooseAddressInfo = packageInfo => {
    const isReturnDirection =
      packageInfo?.direction &&
      packageInfo.direction === PACKAGE_DIRECTION_RETURN;

    if (isReturnDirection) {
      const { postalAddress } = packageInfo.loggiAddress || {};
      return postalAddress?.locality ?? '';
    }
    return packageInfo?.destination?.city ?? '';
  };

  return (
    <CustomDrawer>
      <DrawerHeader
        title={HEADER_TEXT.title}
        handleClosingDrawer={
          isEdit
            ? () => setOpenCloseConfirmationDialog(true)
            : () => setShowViewDrawer(VIEWS_DRAWER.PACKAGES)
        }
        showDivider
        open={!isEdit}
      >
        <Box pt={0.5}>
          <Typography variant="h5">
            {HEADER_TEXT.added_packages_subtitle(
              Object.keys(isEdit ? currentPackagesInfo : newPackagesInfo).length
            )}
          </Typography>
        </Box>
        <Box pt={1.5}>
          <Typography color="textSecondary" variant="subtitle2">
            {HEADER_TEXT.regions(
              Object.values(isEdit ? currentPackagesInfo : newPackagesInfo)
                .map(pkg => chooseAddressInfo(pkg))
                .filter(addressInfo => addressInfo !== '')
            )}
          </Typography>
        </Box>
      </DrawerHeader>
      <DrawerContent>
        <Box pt={2}>
          <Typography variant="subtitle1">
            <strong>{CONTENT_TEXT.keywords}</strong>
          </Typography>
        </Box>
        <Box pt={0.5}>
          <Typography color="textSecondary" variant="body1">
            {CONTENT_TEXT.keywords_subtitle}
          </Typography>
        </Box>
        <Box pt={2.5}>
          <KeywordInput keywords={keywords} setKeywords={setKeywords} />
          <Box pl={2}>
            <Typography color="textSecondary" variant="caption">
              {CONTENT_TEXT.keywords_caption}
            </Typography>
          </Box>
        </Box>
      </DrawerContent>
      <DrawerContent>
        <Box
          bottom={0}
          display="flex"
          justifyContent="center"
          padding="40px 5px"
          position="fixed"
          width="424px"
          height="auto"
          bgcolor="white"
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            disabled={(!isEdit && newPackagesInfo.length === 0) || loading}
            data-testid="btn-move-packages-to-group"
            onClick={
              isEdit
                ? () => updateKeywords()
                : () => movePackagesToGroup(newPackagesInfo)
            }
          >
            {isEdit ? CONTENT_TEXT.save : CONTENT_TEXT.group_packages}
          </Button>
        </Box>
      </DrawerContent>
    </CustomDrawer>
  );
}

DrawerGroupPackagesKeyword.propTypes = {
  currentPackagesInfo: PropTypes.shape().isRequired,
  newPackagesInfo: PropTypes.objectOf(
    PropTypes.shape({
      packageId: PropTypes.string,
      barcode: PropTypes.string
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
  setKeywords: PropTypes.func.isRequired,
  movePackagesToGroup: PropTypes.func.isRequired,
  setShowViewDrawer: PropTypes.func.isRequired,
  setOpenCloseConfirmationDialog: PropTypes.func.isRequired,
  updateKeywords: PropTypes.func.isRequired
};

DrawerGroupPackagesKeyword.defaultProps = {
  keywords: []
};
