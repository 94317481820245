export const DRAWER_UPDATE_STATUS = {
  title: 'Alterar status',
  subtitle: 'do pacote',
  receiver_name: 'Nome do recebedor',
  receiver_document: 'Documento',
  options: {
    RECIPIENT_UNAVAILABLE: {
      text: 'Ausente',
      code: '12'
    },
    REFUSED: {
      text: 'Recusado',
      code: '11'
    },
    INVALID_DESTINATION: {
      text: 'Endereço errado',
      code: '13'
    },
    AWAITING_RESOLUTION: {
      text: 'Aguardando tratativa',
      code: '28'
    }
  },
  confirm: 'Salvar',
  base: ['receiving:accepted', 'return:leve', 'REC', 'COL']
};

export const STATUS_DELIVERED = '2';
export const STATUS_RETURNED = '9';

export const DRAWER_STATUS_LIST_OPTIONS = Object.values(
  DRAWER_UPDATE_STATUS.options
);
