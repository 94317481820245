import { colors } from '@loggi/mar';

const MAPPER_STATUS = {
  Uninformed: {
    style: {
      borderRadius: '11px',
      backgroundColor: '#ffe0e0',
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'inválido'
  },
  'Dispute Pending': {
    style: {
      borderRadius: '11px',
      backgroundColor: colors.smoke[200],
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'pendente'
  },
  'Dispute Solved': {
    style: {
      borderRadius: '11px',
      backgroundColor: '#ccf6e9',
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'resolvido'
  },
  'Dispute Expired': {
    style: {
      borderRadius: '11px',
      backgroundColor: '#ffe0e0',
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'expirado'
  },
  'Dispute Solved After Expired': {
    style: {
      borderRadius: '11px',
      backgroundColor: '#ffe0e0',
      fontWeight: 700,
      color: colors.smoke[900]
    },
    text: 'Resolvida após expirado'
  }
};

const TITLE = {
  finished: 'Finalizados / ',
  disputes: 'Com pendência'
};

const TEXT = {
  NONE_PACKAGE: 'Nenhum pacote',
  PACKAGE: 'pacote'
};

const DISPUTE_DATE_FORMAT = 'YYYY-MM-DD';

const ERROR_TEXT = 'Erro ao buscar disputas';

const HIGHLIGHT_TEXTS = {
  expired: {
    title: 'O prazo para confirmar essa entrega expirou',
    subtitle: `A confirmação não foi enviada até $deadline e por isso o pacote foi extraviado. Tem algo errado? Fale com o suporte na Central de Ajuda.`
  },
  pendingWithResponsible: {
    title: 'Aguardando confirmação da entrega',
    subtitle: `As instruções estão disponíveis no aplicativo do entregador $responsible. O prazo para confirmar a entrega é `,
    deadline: `até $deadline.`
  },
  pedingWithoutResponsible: {
    title: 'Essa entrega precisa ser confirmada',
    subtitle: `Um entregador precisa confirmar a entrega com o destinatário `,
    deadline: `até $deadline.`
  }
};

const DRIVER_LIST_TEXTS = {
  formLabel: 'Selecione um entregador',
  cancelButton: 'Cancelar',
  confirmButton: 'Confirmar'
};

const UPDATE_RESPONSIBLE_TEXTS = {
  cancelButton: 'Cancelar',
  confirmButton: 'Confirmar',
  buttonWithResponsible: 'Alterar entregador',
  buttonWithoutResponsible: 'Alocar entregador',
  labelSuccessDriverAllocation: 'Entregador alocado com sucesso!',
  labelErrorDriverAllocation: 'Erro na alocação do entregador!',
  labelErrorGetDrivers: 'Erro na busca de entregadores'
};

const DISPUTE_DELIVERER_FILTER = 'quem entregou';

const DISPUTE_BUTTON_FILTERS = [
  {
    id: 'to_expire',
    label: 'perto de expirar',
    keyFilter: 'range_date',
    keyValues: []
  },
  {
    id: 'to_allocate',
    label: 'para alocar',
    keyFilter: 'without_dispute_responsible',
    keyValues: ['true']
  },
  {
    id: 'pending',
    label: 'pendentes',
    keyFilter: 'status',
    keyValues: ['pending']
  }
];

export {
  TITLE,
  TEXT,
  ERROR_TEXT,
  MAPPER_STATUS,
  DISPUTE_DATE_FORMAT,
  HIGHLIGHT_TEXTS,
  DRIVER_LIST_TEXTS,
  UPDATE_RESPONSIBLE_TEXTS,
  DISPUTE_DELIVERER_FILTER,
  DISPUTE_BUTTON_FILTERS
};
