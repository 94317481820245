import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as IconClock } from 'images/ic-clock.svg';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import MissedEventsBox from 'shared/missed-events-box';
import CheckedBy from 'shared/checked-by';

import { Box, Chip, IconButton, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FILTERS } from 'shared/filter/filter.constants';
import StatusColorDescription from 'shared/status-color-description';
import {
  STATUS_RECIPIENT_DENIED,
  STATUS_CODE_PICKUP,
  STATUS_CODE_GOING_TO_DELIVER,
  STATUS_RECIPIENT_UNAVAILABLE,
  STATUS_RECIPIENT_WRONG_ADDRESS,
  STATUS_NO_ATTEMPT,
  STATUS_DELIVERED,
  STATUS_PACKAGE_DAMAGED,
  STATUS_PACKAGE_LOST,
  STATUS_PACKAGE_STOLEN,
  STATUS_CONFIRMED,
  STATUS_AWAITING_RESOLUTION,
  STATUS_READY_TO_RESEND,
  STATUS_READY_TO_RETURN,
  STATUS_CUSTOMER_PICKUP
} from 'shared/status-color-description/status-color-description.constants';
import { getCompanyType } from 'auth/access-control/access-control.service';
import { TEXT as TEXT_BASE } from 'tracking/in-base/base-collection/base-collection.constants';
import { KEY_CUSTODY, VIEWS } from './tracking.constants';
import CellContainer from './configurable-table-collapsible/delivererAllocation/CellContainer';

const useStyles = makeStyles({
  chip: {
    margin: '4px',
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    height: '1.25rem'
  },
  typography: {
    fontSize: '0.75rem'
  }
});

/**
 * This is a native implementation of the function `get` from `lodash` lib
 * and this code is from https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
 */
export const get = (obj, path, defaultValue) => {
  const travel = regexp =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

const missedEventsComponent = item => {
  const missedCount = get(item, 'fields.faults[0]', null);
  return missedCount ? <MissedEventsBox count={missedCount} /> : null;
};

export const missedEventsBox = {
  propsStyleBody: { align: 'left' },
  component: item => missedEventsComponent(item)
};

const statusComponent = (item, field, preserveStatusDescription) => (
  <StatusColorDescription
    status={get(item, '_source.status.code', null)}
    statusDescription={get(item, `fields.${field}[0]`, null)}
    preserveStatusDescription={() => preserveStatusDescription}
    typographyVariant="body2"
  />
);

const collapsibleComponent = open => (
  <IconButton>
    {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
  </IconButton>
);

const noWrap = string => (
  <Typography variant="body2" noWrap>
    {string}
  </Typography>
);

const noWrapDate = (item, field, format) => {
  const promisedDatetime = get(item, field, null);
  const strDate = promisedDatetime
    ? moment(promisedDatetime)
        .locale('pt-BR')
        .format(format)
    : '-';
  return noWrap(strDate);
};

const formatCity = cityEnum =>
  cityEnum
    ?.substring(8)
    .toLowerCase()
    .split('_')
    .map(item => item.charAt(0).toUpperCase() + item.substring(1))
    .join(' ');

const formatStation = station => {
  if (station.includes('COL')) return 'Recebimento';
  if (station.includes('SEP')) return 'Processamento';
  return '-';
};

export const isPackageOfGroupDetached = ul => {
  return ul.startsWith(getSelectedRoutingCode()) && ul.endsWith('01');
};

export const isPackageOfToTransferGroup = ul => {
  return ul.includes('return:loggi') || ul.includes('receiving:rejected');
};

export const toTransferGroupLabel = ul => {
  if (ul.includes('return:loggi')) return 'Pacotes avulsos';
  if (ul.includes('receiving:rejected')) return 'Pacotes recusados';
  return ul;
};

export const formatUnitLoadLabel = ul => {
  if (isPackageOfToTransferGroup(ul)) return toTransferGroupLabel(ul);
  if (isPackageOfGroupDetached(ul)) return 'Pacotes avulsos';
  return ul;
};

const syncingComponent = item =>
  item.isSyncing && <IconClock title="Atualizando" />;

const choosePromisedDateField = () => {
  const lastMilePromisedDate =
    'last_last_mile_promised_date_annotation.annotation.last_mile_promised_date.promised_date.promised_date';
  const promisedDate = 'last_promised_date.promised_date';
  return getCompanyType() === 'LEVE' ? lastMilePromisedDate : promisedDate;
};

const choosePromisedDateFieldOnGroupByUnitLoad = () => {
  const lastMilePromisedDate = 'lastMilePromisedDate';
  const promisedDate = 'promisedDate';
  return getCompanyType() === 'LEVE' ? lastMilePromisedDate : promisedDate;
};

const KeywordChip = ({ word }) => {
  const classes = useStyles();

  return (
    <Chip
      key={word}
      label={
        <Typography
          color="textPrimary"
          variant="body1"
          className={classes.typography}
        >
          {word}
        </Typography>
      }
      className={classes.chip}
      size="small"
    />
  );
};

KeywordChip.propTypes = {
  word: PropTypes.string.isRequired
};

const keywordComponent = item => {
  return item.packageGroupTags && item.packageGroupTags.length > 0 ? (
    <Box display="flex" flexWrap="wrap">
      {item.packageGroupTags.map(word => (
        <KeywordChip key={word} word={word} />
      ))}
    </Box>
  ) : (
    <Typography>-</Typography>
  );
};

const syncing = {
  propsStyleBody: { align: 'left' },
  headStyle: { width: '1px' },
  component: item => syncingComponent(item)
};

export const from = {
  header: 'Quem enviou',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, '_source.company.name', '-')
};

export const status = {
  header: 'Status',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'status_string',
  orderDirection: 'asc',
  component: item => statusComponent(item, 'status_string', false)
};

export const statusV2 = {
  header: 'Status',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'status_string_v2',
  orderDirection: 'asc',
  component: item => statusComponent(item, 'status_string_v2', true)
};

const score = {
  header: 'Relevância',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: '_score',
  orderDirection: 'desc',
  component: item => get(item, '_score', '-')
};

const deliverer = {
  header: 'Entregador',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'last_breadcrumbs_sorting_record.user_details.full_name.raw',
  orderDirection: 'asc',
  component: item => {
    const email = get(
      item,
      '_source.last_breadcrumbs_sorting_record.user_details.email',
      '-'
    );
    const fullName = get(
      item,
      '_source.last_breadcrumbs_sorting_record.user_details.full_name',
      '-'
    );

    return CheckedBy({ name: fullName, email });
  }
};

export const unit = {
  header: 'Unidade',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => {
    const ul = get(item, '_source.unit_load.license_plate', '-');
    return getCompanyType() === 'LEVE' ? formatUnitLoadLabel(ul) : ul;
  },
  sortBy: '_source.unit_load.license_plate',
  orderDirection: 'asc'
};

const bag = {
  header: 'Saca',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, '_source.current_bag.seal', '-')
};

export const receivedAt = {
  header: 'Chegou em',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy:
    'last_last_mile_promised_date_annotation.annotation.last_mile_promised_date.promised_date.trigger_date',
  orderDirection: 'asc',
  component: item =>
    noWrapDate(
      item,
      '_source.last_last_mile_promised_date_annotation.annotation.last_mile_promised_date.promised_date.trigger_date',
      'DD MMM [às] HH:mm'
    )
};

// Note: it is clearly a duplication from 'receivedAt' declared above.
// But this one below is for use in 'finished' screen, instead in 'delivered' screen, like above.
const received = { ...receivedAt, header: 'Chegou' };

export const deliveryDeadline = {
  header: 'Prazo',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: choosePromisedDateField(),
  orderDirection: 'asc',
  component: item =>
    noWrapDate(item, `_source.${choosePromisedDateField()}`, '[até] DD MMM')
};

export const deliveryDeadlineNew = {
  header: 'Prazo',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy:
    'last_awaiting_resolution_promised_date_annotation.annotation.awaiting_resolution_promised_date.promised_date.promised_date',
  orderDirection: 'asc',
  component: item =>
    noWrapDate(
      item,
      '_source.last_awaiting_resolution_promised_date_annotation.annotation.awaiting_resolution_promised_date.promised_date.promised_date',
      '[até] DD MMM'
    )
};

const promisedDateUL = {
  header: 'Prazo',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: choosePromisedDateField(),
  secondOrderBy: 'quantityPackages',
  orderDirection: 'asc',
  component: item =>
    noWrapDate(
      item,
      `${choosePromisedDateFieldOnGroupByUnitLoad()}`,
      '[até] DD MMM'
    )
};

export const promisedDateULNew = {
  header: 'Prazo',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy:
    'last_awaiting_resolution_promised_date_annotation.annotation.awaiting_resolution_promised_date.promised_date.promised_date',
  secondOrderBy: 'quantityPackages',
  orderDirection: 'asc',
  component: item =>
    noWrapDate(item, 'awaitingResolutionPromisedDate', '[até] DD MMM')
};

const statusUpdated = {
  header: 'Atualizou em',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'status.updated',
  orderDirection: 'desc',
  component: item =>
    noWrapDate(item, '_source.status.updated', 'DD MMM [às] HH:mm')
};

const deliveredAt = {
  header: 'Entregue em',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'delivery.task.completed_at',
  orderDirection: 'desc',
  component: item =>
    noWrapDate(item, '_source.delivery.task.completed_at', 'DD MMM [às] HH:mm')
};

const finishedAt = {
  header: 'Finalizou',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'finished_status_date',
  orderDirection: 'desc',
  component: item =>
    noWrapDate(item, 'fields.finished_status_date[0]', 'DD MMM [às] HH:mm')
};

export const zipcode = {
  header: 'CEP',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'destination.zip.raw',
  orderDirection: 'asc',
  component: item => {
    const direction = get(item, '_source.last_package_direction.direction', '');
    if (direction === 'PACKAGE_DIRECTION_RETURN') {
      return noWrap('-');
    }
    const cep = get(item, '_source.destination.zip', '');
    return noWrap(`${cep.slice(0, 5)}-${cep.slice(5)}`);
  }
};

export const region = {
  header: 'Região',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy: 'region_label',
  orderDirection: 'asc',
  component: item => {
    const direction = get(item, '_source.last_package_direction.direction', '');
    if (direction === 'PACKAGE_DIRECTION_RETURN') {
      return '-';
    }
    return get(item, 'fields.region_label[0]', '');
  }
};

export const city = {
  header: 'Cidade',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  sortBy:
    '_source.last_service_type_annotation.annotation.service_type.city_enum',
  orderDirection: 'asc',
  component: item => {
    return formatCity(
      get(
        item,
        '_source.last_service_type_annotation.annotation.service_type.city_enum',
        ''
      )
    );
  }
};

const barcode = {
  header: 'Pacote',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => {
    let _barcode = get(item, '_source.package_integration_info.barcode', '-');
    if (_barcode.length > 18) {
      _barcode = `${_barcode.substring(0, 8)}...${_barcode.substring(
        _barcode.length - 8,
        _barcode.length
      )}`;
    }
    return _barcode;
  }
};

export const to = {
  header: 'Para',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, '_source.recipient.name', '-')
};

const collapsible = {
  header: '',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  headStyle: { width: '1px' },
  component: open => collapsibleComponent(open)
};

export const amountULSort = {
  header: 'Quantidade de pacotes',
  sortBy: 'quantityPackages',
  secondOrderBy: choosePromisedDateField()
};

export const amountULSortNew = {
  header: 'Quantidade de pacotes',
  sortBy: 'quantityPackages',
  secondOrderBy:
    'last_awaiting_resolution_promised_date_annotation.annotation.awaiting_resolution_promised_date.promised_date.promised_date'
};

const amountUL = {
  header: 'Pacotes',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, 'quantityPackages', '-')
};

const circleName = {
  header: 'Círculo',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, 'circleName', '-')
};

const actionsBtnGroup = {
  header: '',
  headAlign: 'left',
  propsStyleBody: { align: 'left' }
};

const station = {
  header: 'Estação',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => formatStation(get(item, 'sortingContext', '-'))
};

const unitLoad = {
  header: 'Grupo',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => {
    const ul = get(item, 'licensePlate', '-');
    return getCompanyType() === 'LEVE' ? formatUnitLoadLabel(ul) : ul;
  }
};

const userDetailsUL = {
  header: 'Grupo',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, 'updatedBy', '-')
};

const updateStatusUL = {
  header: 'Atualizado em',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => noWrapDate(item, 'statusUpdated', 'DD MMM [às] HH:mm')
};

const keyword = {
  header: 'Palavra-chave',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => keywordComponent(item)
};

const delivererAllocation = {
  header: 'Entregador',
  component: item => {
    const ul = get(item, 'licensePlate', '-');
    return <CellContainer licensePlate={ul} />;
  }
};

export const CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE = {
  collapsible,
  amountUL,
  station,
  unitLoad,
  promisedDateUL
};

export const CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP = {
  collapsible,
  amountUL,
  station,
  unitLoad,
  circleName,
  promisedDateUL,
  actionsBtnGroup
};

export const CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP_AND_KEYWORDS = {
  collapsible,
  amountUL,
  station,
  keyword,
  unitLoad,
  circleName,
  promisedDateUL,
  actionsBtnGroup
};

export const CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP_AND_KEYWORDS_AND_DELIVERER = {
  collapsible,
  amountUL,
  station,
  keyword,
  unitLoad,
  circleName,
  promisedDateUL,
  delivererAllocation,
  actionsBtnGroup
};

export const CONFIGURABLE_TABLE_MERGE_BASE_WITH_BTN_GROUP_AND_DEAD_LINE_NEW = {
  collapsible,
  amountUL,
  station,
  unitLoad,
  circleName,
  promisedDateULNew,
  actionsBtnGroup
};

export const CONFIGURABLE_TABLE_MERGE_BASE_WITH_BTN_GROUP_AND_DEAD_LINE_NEW_AND_KEYWORDS = {
  collapsible,
  amountUL,
  station,
  unitLoad,
  keyword,
  circleName,
  promisedDateULNew,
  actionsBtnGroup
};

export const CONFIGURABLE_TABLE_MERGE_BASE_WITH_BTN_GROUP_AND_DEAD_LINE_NEW_AND_KEYWORDS_AND_DELIVERER = {
  collapsible,
  amountUL,
  station,
  unitLoad,
  keyword,
  circleName,
  promisedDateULNew,
  delivererAllocation,
  actionsBtnGroup
};

export const CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW = {
  collapsible,
  amountUL,
  station,
  unitLoad,
  promisedDateULNew
};

export const CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE = {
  missedEventsBox,
  syncing,
  deliveryDeadline,
  from,
  to,
  city,
  zipcode,
  status
};

export const CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW = {
  missedEventsBox,
  syncing,
  deliveryDeadlineNew,
  from,
  to,
  city,
  zipcode,
  status
};

export const CONFIGURABLE_TABLE_CONFIGURATION_NA_BASE = {
  missedEventsBox,
  syncing,
  deliveryDeadline,
  bag,
  from,
  to,
  city,
  zipcode,
  status
};

export const CONFIGURABLE_TABLE_CONFIGURATION_NA_BASE_WITH_DELIVERY_DEAD_LINE_NEW = {
  missedEventsBox,
  syncing,
  deliveryDeadlineNew,
  bag,
  from,
  to,
  city,
  zipcode,
  status
};

export const CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_RUA = {
  collapsible,
  amountUL,
  userDetailsUL,
  promisedDateUL,
  updateStatusUL
};

export const CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_RUA = {
  missedEventsBox,
  syncing,
  deliveryDeadline,
  to,
  city,
  statusUpdated,
  status
};

export const CONFIGURABLE_TABLE_CONFIGURATION_NA_RUA = {
  missedEventsBox,
  syncing,
  deliveryDeadline,
  deliverer,
  to,
  city,
  statusUpdated,
  status
};

export const CONFIGURABLE_TABLE_CONFIGURATION_ENTREGUE = {
  missedEventsBox,
  syncing,
  receivedAt,
  deliveredAt,
  deliverer,
  region,
  zipcode,
  barcode,
  status
};

export const CONFIGURABLE_TABLE_CONFIGURATION_RESULTADO = {
  missedEventsBox,
  syncing,
  deliveryDeadline,
  from,
  region,
  zipcode,
  deliverer,
  to,
  statusUpdated,
  status
};

export const CONFIGURABLE_TABLE_CONFIGURATION_FINALIZADOS = {
  missedEventsBox,
  syncing,
  received,
  finishedAt,
  deliverer,
  region,
  zipcode,
  barcode,
  status
};

export const CONFIGURABLE_TABLE_CONFIGURATION_TO_TRANSFER = {
  deliveryDeadline,
  unit,
  from,
  to,
  zipcode,
  status
};

export const CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_TO_TRANSFER = {
  collapsible,
  amountUL,
  unitLoad,
  actionsBtnGroup
};

export const CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_TO_TRANSFER = {
  from,
  to,
  zipcode,
  status
};

const CUSTODY_MAP = {
  [KEY_CUSTODY.TRANSFERIR]: {
    view: VIEWS.TRANSFERIR,
    licensePlate: ['*return*loggi', '*receiving*rejected'],
    sortingContextLicensePlate: []
  },
  [KEY_CUSTODY.NA_RUA]: {
    slug: KEY_CUSTODY.NA_RUA,
    title: 'Na rua',
    licensePlate: [
      '*delivery*in_progress',
      '*delivery*recipient_refused',
      '*delivery*invalid_destination',
      '*delivery*recipient_unavailable'
    ],
    sortingContextLicensePlate: ['DELIVERY'],
    status: [
      STATUS_RECIPIENT_DENIED,
      STATUS_RECIPIENT_UNAVAILABLE,
      STATUS_RECIPIENT_WRONG_ADDRESS,
      STATUS_NO_ATTEMPT,
      STATUS_CODE_GOING_TO_DELIVER,
      STATUS_CODE_PICKUP,
      STATUS_CUSTOMER_PICKUP
    ],
    orderBy: {
      default: [deliveryDeadline, deliverer, city, statusUpdated, status],
      ul: [promisedDateUL, amountULSort]
    },
    isDatePickerDisplayed: false,
    emptyDetail:
      'Por enquanto ta tudo tranquilo, não tem nenhum pacote na rua.',
    tableConfiguration: CONFIGURABLE_TABLE_CONFIGURATION_NA_RUA,
    view: VIEWS.NA_RUA,
    arrayFilters: [
      FILTERS.DELIVERER,
      FILTERS.STATUS,
      FILTERS.UPDATE,
      FILTERS.FAULTS,
      FILTERS.CITIES,
      FILTERS.CIRCLES
    ],
    tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_RUA,
    tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_RUA
  },
  [KEY_CUSTODY.NA_BASE]: {
    slug: KEY_CUSTODY.NA_BASE,
    title: 'Na base',
    licensePlate: ['*return*leve', '*receiving*accepted'],
    sortingContextLicensePlate: ['REC', 'COL', 'SEP'],
    status: [
      STATUS_CONFIRMED,
      STATUS_NO_ATTEMPT,
      STATUS_AWAITING_RESOLUTION,
      STATUS_READY_TO_RESEND,
      STATUS_READY_TO_RETURN
    ],
    orderBy: {
      default: [deliveryDeadline, city, zipcode, status],
      ul: [promisedDateUL, amountULSort]
    },
    isDatePickerDisplayed: false,
    emptyDetail:
      'Assim que pacotes ou sacas chegarem na sua base, bipe para aparecerem aqui.',
    tableConfiguration: CONFIGURABLE_TABLE_CONFIGURATION_NA_BASE,
    tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
    tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE,
    view: VIEWS.NA_BASE,
    arrayFilters: [
      FILTERS.STATUS,
      FILTERS.FAULTS,
      FILTERS.ALERTS,
      FILTERS.CITIES
    ]
  },
  [KEY_CUSTODY.RESULTADO]: {
    slug: KEY_CUSTODY.RESULTADO,
    title: 'Busca por',
    licensePlate: [],
    sortingContextLicensePlate: [],
    status: [
      STATUS_CONFIRMED,
      STATUS_DELIVERED,
      STATUS_PACKAGE_DAMAGED,
      STATUS_PACKAGE_LOST,
      STATUS_PACKAGE_STOLEN,
      STATUS_CUSTOMER_PICKUP
    ],
    orderBy: {
      default: [
        score,
        deliveryDeadline,
        region,
        zipcode,
        deliverer,
        statusUpdated,
        status
      ]
    },
    isDatePickerDisplayed: false,
    emptyDetail: 'Não encontramos nenhum pacote para o termo pesquisado.',
    tableConfiguration: CONFIGURABLE_TABLE_CONFIGURATION_RESULTADO,
    view: '',
    arrayFilters: [FILTERS.STATUS, FILTERS.DELIVERER]
  },
  [KEY_CUSTODY.FINALIZADOS]: {
    slug: KEY_CUSTODY.FINALIZADOS,
    title: 'Finalizados',
    licensePlate: ['*delivery*delivered'],
    sortingContextLicensePlate: ['DELIVERED'],
    dateFilter: 'last_confirmed_task.task.completed_at',
    status: [
      STATUS_DELIVERED,
      STATUS_PACKAGE_DAMAGED,
      STATUS_PACKAGE_LOST,
      STATUS_PACKAGE_STOLEN
    ],
    orderBy: {
      default: [received, finishedAt, deliverer, region, zipcode, status]
    },
    isDatePickerDisplayed: true,
    emptyDetail: 'Não encontramos nenhum pacote durante o periodo pesquisado.',
    tableConfiguration: CONFIGURABLE_TABLE_CONFIGURATION_FINALIZADOS,
    view: VIEWS.FINALIZADOS,
    arrayFilters: [
      FILTERS.STATUS,
      FILTERS.DELIVERER_INCLUDING_INACTIVE,
      FILTERS.FAULTS
    ]
  }
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_GRUPOS] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_GRUPOS,
  viewFilterBy: choosePromisedDateField(),
  isSubView: false,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_PACOTES] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_PACOTES,
  viewFilterBy: choosePromisedDateField(),
  isSubView: false,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_HOJE] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_HOJE,
  view: VIEWS.NA_BASE_HOJE,
  viewFilterBy: choosePromisedDateField(),
  emptyDetail: TEXT_BASE.EMPTY_DETAIL_COLLECTION,
  isSubView: true,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleWithBtnActionGroup: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE,
  subView: TEXT_BASE.TODAY
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_AMANHA] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_AMANHA,
  view: VIEWS.NA_BASE_AMANHA,
  viewFilterBy: choosePromisedDateField(),
  emptyDetail: TEXT_BASE.EMPTY_DETAIL_COLLECTION,
  isSubView: true,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleWithBtnActionGroup: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE,
  subView: TEXT_BASE.TOMORROW
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_ATRASADO] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_ATRASADO,
  view: VIEWS.NA_BASE_ATRASADO,
  viewFilterBy: choosePromisedDateField(),
  emptyDetail: TEXT_BASE.EMPTY_DETAIL_COLLECTION,
  isSubView: true,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleWithBtnActionGroup: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE,
  subView: TEXT_BASE.DELAYED
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_DEPOIS] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_DEPOIS,
  view: VIEWS.NA_BASE_DEPOIS,
  viewFilterBy: choosePromisedDateField(),
  emptyDetail: TEXT_BASE.EMPTY_DETAIL_COLLECTION,
  isSubView: true,
  subView: TEXT_BASE.AFTER_TOMORROW,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleWithBtnActionGroup: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_COM_FALTA] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_COM_FALTA,
  view: VIEWS.NA_BASE_COM_FALTA,
  arrayFilters: [FILTERS.STATUS],
  emptyDetail: TEXT_BASE.EMPTY_DETAIL_COLLECTION,
  isSubView: true,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleWithBtnActionGroup: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE,
  subView: TEXT_BASE.WITH_FAULTS
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_PROBLEMA_LOCALIZACAO] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_PROBLEMA_LOCALIZACAO,
  view: VIEWS.NA_BASE_PROBLEMA_LOCALIZACAO,
  arrayFilters: [FILTERS.STATUS],
  emptyDetail: TEXT_BASE.EMPTY_DETAIL_COLLECTION,
  isSubView: true,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleWithBtnActionGroup: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE,
  subView: TEXT_BASE.LOCATION_PROBLEM
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_SEM_PRAZO] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_SEM_PRAZO,
  view: VIEWS.NA_BASE_SEM_PRAZO,
  viewFilterBy: choosePromisedDateField(),
  arrayFilters: [FILTERS.STATUS],
  emptyDetail: TEXT_BASE.EMPTY_DETAIL_COLLECTION,
  isSubView: true,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleWithBtnActionGroup: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE,
  subView: TEXT_BASE.NO_DEADLINE
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_EM_RISCO] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_EM_RISCO,
  view: VIEWS.NA_BASE_EM_RISCO,
  arrayFilters: [FILTERS.STATUS],
  emptyDetail: TEXT_BASE.EMPTY_DETAIL_COLLECTION,
  isSubView: true,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleWithBtnActionGroup: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE,
  subView: TEXT_BASE.AT_RISK
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_AGUARDANDO_TRATATIVA] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_AGUARDANDO_TRATATIVA,
  view: VIEWS.NA_BASE_AGUARDANDO_TRATATIVA,
  viewFilterBy: choosePromisedDateField(),
  arrayFilters: [FILTERS.STATUS],
  emptyDetail: TEXT_BASE.EMPTY_DETAIL_COLLECTION,
  isSubView: true,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleWithBtnActionGroup: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE_WITH_BTN_GROUP,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE,
  subView: TEXT_BASE.AWAITING_RESOLUTION
};

CUSTODY_MAP[KEY_CUSTODY.NA_BASE_RECEBIMENTO] = {
  ...CUSTODY_MAP[KEY_CUSTODY.NA_BASE],
  slug: KEY_CUSTODY.NA_BASE_RECEBIMENTO,
  view: VIEWS.NA_BASE_RECEBIMENTO,
  viewFilterBy: choosePromisedDateField(),
  arrayFilters: [FILTERS.STATUS],
  emptyDetail: TEXT_BASE.EMPTY_DETAIL_COLLECTION,
  isSubView: true,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_NA_BASE,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_NA_BASE,
  subView: TEXT_BASE.RECEIVE
};

CUSTODY_MAP[KEY_CUSTODY.TO_TRANSFER] = {
  slug: KEY_CUSTODY.TO_TRANSFER,
  title: 'Para transferir',
  licensePlate: ['*return*loggi', '*receiving*rejected'],
  sortingContextLicensePlate: ['DIS'],
  status: [
    STATUS_CONFIRMED,
    STATUS_NO_ATTEMPT,
    STATUS_AWAITING_RESOLUTION,
    STATUS_READY_TO_RESEND,
    STATUS_READY_TO_RETURN
  ],
  orderBy: {
    default: [unit, deliveryDeadline, zipcode, status],
    ul: [promisedDateUL, amountULSort]
  },
  isDatePickerDisplayed: false,
  emptyDetail: 'Bipe pacotes e sacas que vão ser transferidos',
  tableConfiguration: CONFIGURABLE_TABLE_CONFIGURATION_TO_TRANSFER,
  tableConfigurationCollapsible: CONFIGURABLE_TABLE_COLLAPSIBLE_CONFIGURATION_TO_TRANSFER,
  tableConfigurationCollapsibleChildren: CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_TO_TRANSFER,
  view: VIEWS.TO_TRANSFER,
  arrayFilters: [
    FILTERS.STATUS,
    FILTERS.FAULTS,
    FILTERS.ALERTS,
    FILTERS.CITIES
  ],
  flag: 'return-to-transfer'
};

export function getLayout(custody) {
  return CUSTODY_MAP[custody]
    ? CUSTODY_MAP[custody].slug
    : CUSTODY_MAP[KEY_CUSTODY.NA_RUA].slug;
}

export function useCustodyMap(overriddenCustody) {
  const { custody } = useParams();
  if (overriddenCustody) {
    return { ...CUSTODY_MAP[overriddenCustody], custody: overriddenCustody };
  }
  return { ...CUSTODY_MAP[custody], custody };
}

export default CUSTODY_MAP;
