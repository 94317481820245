import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const commonStylesEllipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 160
};

export default makeStyles(() => ({
  tableHead: {
    whiteSpace: 'nowrap'
  },
  tableCell: {
    pointerEvents: 'none',
    cursor: 'default'
  },
  dataRow: {
    pointerEvents: 'auto',
    cursor: 'pointer'
  },
  rowSelected: {
    backgroundColor: colors.blue[50]
  },
  syncing: {
    color: colors.smoke[500]
  },
  unstable: {
    opacity: 0.5
  },
  ellipsis: {
    ...commonStylesEllipsis,
    '& div': {
      ...commonStylesEllipsis
    }
  }
}));
