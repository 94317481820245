import React from 'react';
import PropTypes from 'prop-types';

import { Paper, Box } from '@material-ui/core';
import ConfigurableTable, {
  ConfigurableTableSkeleton
} from 'shared/configurable-table';
import AlertStatusHeader, {
  isPlatformStatusDegraded
} from 'shared/alert-status-header';
import arrivalsModel from './arrivals-transfers.model';
import configurableTableData from './arrivals-transfers.configuration';
import { TEXT_ALERT_STATUS_HEADER } from '../arrivals.constants';

function ArrivalsTransferComponent({
  tripTransferStops,
  loadingTable,
  handleOpenDrawer
}) {
  return (
    <Paper
      component={Box}
      elevation={10}
      flexGrow={1}
      marginBottom={3}
      style={{ borderRadius: 10 }}
    >
      <AlertStatusHeader
        textPrimary={TEXT_ALERT_STATUS_HEADER.COMPONENT.PRIMARY}
        textSecondary={TEXT_ALERT_STATUS_HEADER.COMPONENT.SECONDARY}
      />
      <Box minHeight={44} p={5.5}>
        {!loadingTable && (
          <ConfigurableTable
            data={tripTransferStops}
            columns={configurableTableData}
            onRowSelected={handleOpenDrawer}
            systemUnstable={isPlatformStatusDegraded()}
          />
        )}
        {loadingTable && <ConfigurableTableSkeleton />}
      </Box>
    </Paper>
  );
}

ArrivalsTransferComponent.propTypes = {
  tripTransferStops: PropTypes.arrayOf(PropTypes.shape(arrivalsModel))
    .isRequired,
  loadingTable: PropTypes.bool.isRequired,
  handleOpenDrawer: PropTypes.func.isRequired
};

export default ArrivalsTransferComponent;
