import React, { useState, useEffect } from 'react';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import PropTypes from 'prop-types';

import { getSelectedBase } from 'profile/profile.service';
import { SWITCHES } from 'view/constants';
import arrivalsModel from '../transfers/arrivals-transfers.model';
import DrawerComponent from './drawer.component';
import {
  getArrivalsAggregation,
  getArrivalsAggregationMulti
} from '../arrivals.service';
import { AGGREGATION_SELECT, AGGREGATION_SELECT_XDS } from './drawer.constants';

export default function DrawerContainer({
  handleClosingDrawer,
  selectedUnitLoad
}) {
  const [aggregationResponse, setAggregationResponse] = useState([]);
  const [aggregationType, setAggregationType] = useState(
    AGGREGATION_SELECT[0].sortBy
  );
  const [optionSelected, setOptionSelected] = useState(AGGREGATION_SELECT[0]);
  const [loading, setLoading] = useState(false);
  const enableUseTMSArrivalsAggregation = IsFSActiveForCurrentBase(
    SWITCHES.enableUseTMSArrivalsAggregation
  );

  useEffect(() => {
    setLoading(true);
    if (enableUseTMSArrivalsAggregation) {
      getArrivalsAggregationMulti(
        aggregationType,
        selectedUnitLoad.origin.routing_code,
        selectedUnitLoad.destination.routing_code,
        selectedUnitLoad.vehicleLicensePlate
      ).then(response => {
        setAggregationResponse(response.data);
        setLoading(false);
      });
    } else {
      getArrivalsAggregation(
        aggregationType,
        selectedUnitLoad.unitLoadLicensePlate
      ).then(response => {
        setAggregationResponse(response.data);
        setLoading(false);
      });
    }
  }, [selectedUnitLoad, aggregationType, enableUseTMSArrivalsAggregation]);

  const onSelectChange = item => {
    setAggregationType(item.sortBy);
    setOptionSelected(item);
  };

  const selectOptionByDcCapabilities = () => {
    const dc = getSelectedBase().distribution_center;
    if (dc.separation_center || dc.roteirization_center) {
      return AGGREGATION_SELECT_XDS;
    }
    return AGGREGATION_SELECT;
  };

  return (
    <DrawerComponent
      handleClosingDrawer={handleClosingDrawer}
      selectedUnitLoad={selectedUnitLoad}
      aggregationResponse={aggregationResponse}
      optionSelected={optionSelected}
      onSelectChange={onSelectChange}
      loading={loading}
      selectOptions={selectOptionByDcCapabilities()}
    />
  );
}

DrawerContainer.propTypes = {
  handleClosingDrawer: PropTypes.func.isRequired,
  selectedUnitLoad: PropTypes.shape(arrivalsModel).isRequired
};
