export const DEMAND_REGIONS_INITIAL_DRAWER = {
  TITLE: 'Regiões',
  DETAIL:
    'Atualize a divisão das regiões ou consulte as regiões para entrega atuais.',
  UPDATE_REGIONS: 'Atualizar regiões',
  NUMBER_REGIONS: 'regiões atuais',
  SAME_POSITIONS: 'Iguais para carro e moto • atualizado em ',
  VIEW_DETAILS: 'Ver detalhes',
  ADJUST_LAYOUT_FIRST_PART: 'Lembre de ajustar o layout da base',
  ADJUST_LAYOUT_SECOND_PART: 'ao fazer uma atualização de regiões.',
  ADJUST_LAYOUT_BUTTON: 'Ajustar layout',
  ADJUST_LAYOUT_LINK:
    'https://loggidev.atlassian.net/servicedesk/customer/portal/26/create/2522?src=2097283130',
  SIMULATION_IN_PROGRESS:
    'Simulação em andamento. Vamos te notificar quando estiver pronta. Fique atento à área de notificações',
  SIMULATION_AVAILABLE_TITLE: 'Simulação disponível',
  SIMULATION_AVAILABLE_SUBTITLE:
    'Confira como ficou a nova divisão de regiões. Depois, atualize a divisão atual para garantir melhores rotas.',
  VIEW_SIMULATION_BUTTON: 'Ver simulação'
};

export const DEMAND_REGIONS_SIMULATION_DRAWER = {
  TITLE: 'Atualizar regiões',
  DETAIL: 'Ajuste as informações para simular uma nova divisão de regiões.',
  NUMBER_REGIONS: 'Quantidade de regiões',
  NUMBER_REGIONS_DETAIL: 'As quantidades de carro e moto devem ser iguais.',
  SIMULATION_BUTTON: 'Simular',
  NOTIFICATION_SEND_SIMULATION:
    'Tudo certo! Sua simulação está sendo preparada.'
};

export const UPDATE_DEMAND_REGIONS = {
  NOTIFICATION_SUCCESS: 'Pronto! Agora é preciso ajustar o layout da base.',
  NOTIFICATION_ERROR: 'Algo deu errado! Tente aplicar as mudanças novamente.'
};

export const DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER = {
  TITLE: ' regiões • iguais para carro e moto',
  STATUS: 'Simulação',
  DETAIL:
    'Aplique as mudanças para começar a operar com essa nova configuração.',
  APPLY_NOW: 'Aplicar agora',
  NEW_SIMULATION_TEXT:
    'A divisão das regiões não ficou boa? Faça uma nova simulação.',
  NEW_SIMULATION_BUTTON: 'Nova simulação',
  REGIONS: 'Regiões',
  DIALOG_TITLE: 'O processamento está vazio?',
  DIALOG_SUBTITLE:
    'A nova divisão vai ser aplicada imediatamente. Verifique se não há pacotes nas unidades de processamento.',
  DIALOG_CONFIRM: 'Sim, continuar',
  DIALOG_BACK: 'Vou conferir'
};

export const DEMAND_REGIONS_DETAILS_DRAWER = {
  TITLE: ' regiões, iguais para carro e moto',
  DETAIL:
    'Confira as regiões e suas atuais unidades de separação, de acordo com o tipo de veículo.'
};
