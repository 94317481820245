import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';

import AlertStatusHeader from 'shared/alert-status-header';
import { formatNumber } from 'shared/formatters';
import { Can } from 'auth/access-control';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import { TEXT_CARD } from 'information/today/today.constants';
import { useRouteMatch } from 'react-router-dom';
import { useStyles } from './card-receive-package.styles';
import {
  PLACEHOLDER,
  TEXT_ALERT_STATUS_HEADER
} from './card-receive-package.constants';

function CardReceivePackage(props) {
  const { onRead, loading, data, loadingData, systemUnstable } = props;
  const [barcode, setBarcode] = useState('');
  const [scanned, setScanned] = useState(false);
  const [isMobile] = useState(window.navigator.userAgent.includes('Mobile'));
  const { path } = useRouteMatch();

  const hasShowInViewColletion =
    ['colecoes', 'para-transferir'].filter(pathPart => path.includes(pathPart))
      .length > 0;

  const _onRead = event => {
    event.preventDefault();
    if (barcode === '') {
      return;
    }
    onRead(barcode, isMobile ? scanned : false);
    setBarcode('');
    setScanned(false);
  };

  const classes = useStyles();

  const loadingSkeleton = () => {
    return (
      <Grid container item spacing={6} xs={7}>
        <Grid item>
          <Skeleton variant="rect" height={72} width={72} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={72} width={72} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={72} width={72} />
        </Grid>
      </Grid>
    );
  };
  return (
    <form onSubmit={_onRead}>
      <Box>
        {!hasShowInViewColletion && (
          <AlertStatusHeader
            textPrimary={TEXT_ALERT_STATUS_HEADER.PRIMARY}
            textSecondary={TEXT_ALERT_STATUS_HEADER.SECONDARY}
          />
        )}
        <Box pt={3.5} px={3.5} minHeight={44}>
          <Box>
            <Grid container direction="row">
              <Grid
                container
                item
                direction="row"
                spacing={2}
                alignItems="center"
                justify="flex-start"
              >
                {hasShowInViewColletion && (
                  <Grid xs item>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mx={1.5}
                    >
                      <Can actions={[ACTIONS.RECEIVE_READ]}>
                        <TextField
                          variant="outlined"
                          placeholder={PLACEHOLDER}
                          required
                          onChange={event => setBarcode(event.target.value)}
                          value={barcode}
                          inputProps={{
                            minLength: 3,
                            className: classes.input
                          }}
                          onKeyPress={event => {
                            if (event.key === 'Enter') {
                              return;
                            }
                            setScanned(true);
                            setBarcode(event.target.value);
                          }}
                          autoFocus
                        />
                      </Can>
                    </Box>
                  </Grid>
                )}

                {!hasShowInViewColletion && (
                  <>
                    {!loadingData && (
                      <Grid
                        container
                        item
                        direction="row"
                        alignItems="center"
                        justify="space-around"
                        spacing={6}
                        xs={9}
                        data-testid={`card-receive-values-grid-system-${
                          systemUnstable ? 'unstable' : 'stable'
                        }`}
                      >
                        {data.map(({ value, id }) => (
                          <Grid item key={id}>
                            <Box
                              display="flex"
                              color={
                                systemUnstable
                                  ? 'text.disabled'
                                  : 'text.primary'
                              }
                            >
                              <Typography>
                                <strong>{formatNumber(value)}</strong>
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              color={
                                systemUnstable
                                  ? 'text.disabled'
                                  : 'text.secondary'
                              }
                            >
                              <Typography variant="body2">
                                <em>{TEXT_CARD.NA_BASE.CARD_BODY[id]}</em>
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    {loadingData && loadingSkeleton()}
                    {loading && (
                      <Box px={1.5} pt={5}>
                        <CircularProgress size={35} />
                      </Box>
                    )}
                  </>
                )}
              </Grid>

              {!hasShowInViewColletion && (
                <Grid item>
                  <Can actions={[ACTIONS.RECEIVE_READ]}>
                    <TextField
                      variant="outlined"
                      placeholder={PLACEHOLDER}
                      required
                      onChange={event => setBarcode(event.target.value)}
                      value={barcode}
                      inputProps={{ minLength: 3, className: classes.input }}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          return;
                        }
                        setScanned(true);
                        setBarcode(event.target.value);
                      }}
                      autoFocus
                    />
                  </Can>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

CardReceivePackage.defaultName = 'CardReceivePackage';

CardReceivePackage.propTypes = {
  onRead: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingData: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number
    }),
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number
    }),
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number
    })
  ),
  systemUnstable: PropTypes.bool
};

CardReceivePackage.defaultProps = {
  loading: false,
  loadingData: false,
  data: [],
  systemUnstable: false
};

export default CardReceivePackage;
