class ValidateBarcodeError extends Error {
  constructor(barcode) {
    super(
      `${barcode} não e barcode de um pacote. Beep o barcode de um pacote.`
    );
    this.name = 'ValidateBarcodeError';
  }
}

export default ValidateBarcodeError;
