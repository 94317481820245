import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Paper,
  Typography,
  Grid,
  TextField,
  Tab,
  Tabs
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { SummaryContext } from 'tracking/template/summary.context';
import Pagination, { usePagination } from 'shared/pagination';
import FilterComponent from 'shared/filter';
import { FILTERS, DEMAND_TYPE } from 'shared/filter/filter.constants';
import { formatNumber } from 'shared/formatters';
import ConfigurableTableError from 'shared/configurable-table/configurable-table-error.component';
import { ConfigurableTableSkeleton } from 'shared/configurable-table';
import { getSelectedBase } from 'profile/profile.service';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getRegionNames } from 'information/routines-management/routines/complex-areas/complex-areas.service';

import { CustomDrawer, DrawerHeader } from 'shared/drawer';
import { ReactComponent as DrawerErrorIcon } from 'view/atoms/illustrations/drawer-error/drawer-error.svg';
import DrawerContainer from './drawer/drawer.container';
import { useStyles } from '../tracking/tracking.styles';
import {
  ACCORDION_CONFIG_OFFER,
  ACCORDION_CONFIG_DETAILS_OFFER
} from './offer.configuration';
import {
  TEXT,
  TEXT_TABLE,
  DRAWER_ERROR_GET_REGIONS_NAMES_TEXT,
  DRAWER_ERROR_GET_REGIONS_NAMES_BTN_RETRY
} from './offer.constants';
import offersService from './offer.service';
import AccordionComponent from './accordion';
import OfferAggregation from './aggregation';
import CapacityReserveComponent from './capacity-reserve/capacity-reserve.component';
import OfferEmptyComponent from './offer.empty.component';

function getDefaultDemandType() {
  const base = getSelectedBase();
  const hasDc = Boolean(base.distribution_center);

  if (!hasDc) {
    return null;
  }

  // eslint-disable-next-line camelcase
  const distributionCenter = base?.distribution_center;
  // eslint-disable-next-line camelcase
  const hasCapability = distributionCenter?.collection_center || false;
  if (hasCapability) {
    return DEMAND_TYPE.PICKUP;
  }

  return DEMAND_TYPE.DELIVERY;
}

function OfferContainer() {
  const base = getSelectedBase();
  const hasDc = Boolean(base.distribution_center);
  const shouldShowDemandTypeToggleButton =
    getDefaultDemandType() === DEMAND_TYPE.PICKUP;

  // For carriers (or any base without DC), a preselected status
  // filter will be used, one that excludes 'CANCELLED'
  const preselection = {
    status: !hasDc ? ['SCHEDULED', 'ACTIVE', 'PICKED_UP', 'FINISHED'] : [],
    demand_type: getDefaultDemandType(),
    capacity_reserve: false
  };

  const { offerId: offerIdParam } = useParams();

  const [dataResponse, setDataResponse] = useState({});
  const [loadingTable, setLoadingTable] = useState(true);
  const [subtitle, setSubtitle] = useState('');
  const [error, setError] = useState(false);
  const [count, setCount] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(!!offerIdParam);
  const [
    isCapacityReserveDrawerOpen,
    setIsCapacityReserveDrawerOpen
  ] = useState(false);
  const [offerId, setOfferId] = useState(offerIdParam || 0);
  const [valueTab, setValueTab] = useState(FILTERS.INSTANTANEOUS);
  const [filters, setFilters] = useState(preselection);
  const [offerChangedState, setOfferChangedState] = useState(false);
  const [demandType, setDemandType] = useState(getDefaultDemandType());
  const [bag, setBag] = useState('');

  const [dataRegionsNames, setDataRegionsNames] = useState([]);
  const [errorGetRegionsNames, setErrorGetRegionsNames] = useState(false);

  const [
    { currentPage, numberOfPages },
    changePage,
    setNumberOfItems,
    resetPagination,
    setCurrentPage
  ] = usePagination();

  const classes = useStyles();

  const { setSummary } = useContext(SummaryContext);

  const createCapacityReserveFS = IsFSActiveForCurrentBase(
    'createCapacityReserve'
  );
  const anticipatedOfferListFS = IsFSActiveForCurrentBase(
    'anticipatedOfferList'
  );
  const regionOfferFilterFS = IsFSActiveForCurrentBase('regionOfferFilter');

  const tabs = [FILTERS.INSTANTANEOUS, FILTERS.ANTICIPATED];

  const onApplyChanges = items => {
    setLoadingTable(true);
    if (items.remove) {
      setFilters(current => {
        const currentState = current;
        delete currentState[items.remove];
        return { ...currentState };
      });
    } else {
      setFilters(currentState => {
        return { ...currentState, ...items };
      });
    }

    resetPagination();
  };

  const handleToggleChangeDemandType = (_, newAlignment) => {
    const value = newAlignment || DEMAND_TYPE.PICKUP;
    onApplyChanges({
      demand_type: value
    });
    setDemandType(value);
  };

  // if there are offers or some filter was applied, it can have a offer.
  // if there is any filter applyed we should consider as it has an offer to be listed.
  const hasOffers =
    count > 0 ||
    filters.status?.length > 0 ||
    filters.timeIntervals?.length > 0;

  useEffect(() => {
    switch (count) {
      case 0:
        setSubtitle(`Nenhum ${TEXT.SUBTITLE}`);
        break;
      case 1:
        setSubtitle(`${count} ${TEXT.SUBTITLE}`);
        break;
      default:
        setSubtitle(`${formatNumber(count)} ${TEXT.SUBTITLE}s`);
        break;
    }
    setSummary(
      <Grid
        container
        direction="row"
        alignItems="center"
        display="flex"
        justify="space-between"
      >
        <Grid item xs>
          <Typography variant="h4">
            <strong>{TEXT.TITLE}</strong>
          </Typography>
          <Grid container>
            {loadingTable && (
              <Box pt={0.5} pb={0.5}>
                <Skeleton
                  variant="rect"
                  width="150px"
                  className={classes.skeleton}
                  height="35px"
                />
              </Box>
            )}
            {!loadingTable && <Typography variant="h4">{subtitle}</Typography>}
          </Grid>
        </Grid>
        {shouldShowDemandTypeToggleButton && (
          <Grid>
            <ToggleButtonGroup
              value={demandType}
              exclusive
              onChange={handleToggleChangeDemandType}
              className={`${classes.toggleListOffer}`}
            >
              <ToggleButton
                value={DEMAND_TYPE.PICKUP}
                aria-label="list unit-load"
                data-testid="toggle-pickup"
              >
                {FILTERS.TO_PICKUP}
              </ToggleButton>
              <ToggleButton
                value={DEMAND_TYPE.DELIVERY}
                aria-label="list packages"
                data-testid="toggle-delivery"
              >
                {FILTERS.TO_DELIVERY}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
      </Grid>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, hasOffers, loadingTable, setSummary, subtitle]);

  const onError = () => {
    setError(true);
    setLoadingTable(false);
  };

  const changePagination = page => {
    changePage(page);
    if (page !== currentPage) {
      setLoadingTable(true);
    }
  };

  useEffect(() => {
    setNumberOfItems(count);
  }, [count, setNumberOfItems]);

  const handleBagFilter = event => {
    setBag(event.target.value);
  };

  useEffect(() => {
    if (offerChangedState) {
      setOpenDrawer(false);
    }
    setLoadingTable(true);
    offersService(currentPage, filters)
      .then(dataSchedules => {
        setDataResponse(dataSchedules?.schedules || []);
        setCount(dataSchedules?.schedulesCount || 0);
        setLoadingTable(false);
      })
      .catch(() => {
        onError();
        setCount(0);
      });
  }, [currentPage, filters, offerChangedState]);

  const showAnticipatedOfferRegion = IsFSActiveForCurrentBase(
    'showAnticipatedOfferRegion'
  );

  const fetchRegions = useCallback(async () => {
    getRegionNames()
      .then(response => {
        setDataRegionsNames(response);
        setErrorGetRegionsNames(false);
      })
      .catch(() => {
        setErrorGetRegionsNames(true);
      });
  }, []);

  useEffect(() => {
    if (showAnticipatedOfferRegion) fetchRegions();
  }, [fetchRegions, showAnticipatedOfferRegion]);

  const handleOpenDrawer = clickedOfferId => {
    setOpenDrawer(true);
    setIsCapacityReserveDrawerOpen(false);
    setOfferId(clickedOfferId);
  };

  const handleTabChange = (_, valueFilterTab) => {
    if (valueFilterTab === FILTERS.ANTICIPATED) {
      onApplyChanges({
        capacity_reserve: true
      });
      setValueTab(valueFilterTab);
    } else {
      onApplyChanges({
        capacity_reserve: false
      });
      setValueTab(valueFilterTab);
    }
  };

  const isDelivery = valueDemandType => {
    return valueDemandType === DEMAND_TYPE.DELIVERY;
  };

  const shouldShowTabs = isDelivery(demandType);

  return (
    <>
      {openDrawer && (
        <DrawerContainer
          handleClosingDrawer={() => setOpenDrawer(false)}
          offerId={offerId}
          setOfferChangedState={setOfferChangedState}
        />
      )}
      {isCapacityReserveDrawerOpen && !errorGetRegionsNames && (
        <CapacityReserveComponent
          handleClosingDrawer={() => setIsCapacityReserveDrawerOpen(false)}
          setOfferChangedState={setOfferChangedState}
          redirectAnticipatedOfferList={_ => {
            handleTabChange(_, FILTERS.ANTICIPATED);
            setDemandType(DEMAND_TYPE.DELIVERY);
          }}
          demandRegions={dataRegionsNames}
        />
      )}

      {isCapacityReserveDrawerOpen && errorGetRegionsNames && (
        <CustomDrawer>
          <DrawerHeader
            handleClosingDrawer={() => setIsCapacityReserveDrawerOpen(false)}
          >
            <Grid container justify="center" alignItems="center">
              <Box width={310}>
                <Box align="center">
                  <DrawerErrorIcon />
                </Box>
                <Box pt={3.5} color="text.disabled">
                  <Typography align="center" variant="body2">
                    {DRAWER_ERROR_GET_REGIONS_NAMES_TEXT}
                  </Typography>
                </Box>
                <Box pt={3.5} align="center">
                  <Button
                    data-testid="btn-retry"
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => fetchRegions()}
                  >
                    {DRAWER_ERROR_GET_REGIONS_NAMES_BTN_RETRY}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </DrawerHeader>
        </CustomDrawer>
      )}

      {!error && (
        <>
          {hasOffers && <OfferAggregation filters={filters} />}
          <Paper
            className={classes.paper}
            component={Box}
            elevation={10}
            flexGrow={1}
            minHeight={44}
            p={2}
            pd={3}
            marginBottom={3}
          >
            {shouldShowTabs && anticipatedOfferListFS && (
              <Box>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={valueTab}
                  onChange={handleTabChange}
                  className={classes.tabOffer}
                >
                  {tabs.map(tab => (
                    <Tab label={tab} value={tab} key={tab} data-testid={tab} />
                  ))}
                </Tabs>
              </Box>
            )}

            {hasOffers && (
              <>
                <Box p={2}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    display="flex"
                    justify="space-between"
                  >
                    <Grid item xs>
                      <FilterComponent
                        btnFilter={FILTERS.STATUS_OFFER}
                        onApplyChanges={onApplyChanges}
                        whichFilter={FILTERS.STATUS_OFFER}
                        preselectedItems={preselection.status}
                      />
                      <FilterComponent
                        btnFilter={FILTERS.RANGE_OFFER}
                        onApplyChanges={onApplyChanges}
                        whichFilter={FILTERS.RANGE_OFFER}
                      />
                      {regionOfferFilterFS && (
                        <FilterComponent
                          btnFilter={FILTERS.REGION}
                          onApplyChanges={onApplyChanges}
                          whichFilter={FILTERS.REGION_OFFER}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <form
                        onSubmit={event => {
                          event.preventDefault();
                          setFilters(currentState => {
                            return { ...currentState, ...{ bag_seal: bag } };
                          });
                        }}
                      >
                        <TextField
                          variant="outlined"
                          className={classes.inputFilter}
                          size="small"
                          label="Saca"
                          onChange={handleBagFilter}
                          InputProps={{
                            'data-testid': 'text-field-search',
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon color="primary" />
                              </InputAdornment>
                            )
                          }}
                        />
                      </form>
                    </Grid>
                    {createCapacityReserveFS &&
                      anticipatedOfferListFS &&
                      valueTab === FILTERS.ANTICIPATED && (
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            data-testid="button-create-capacity-reserve"
                            onClick={() => {
                              setIsCapacityReserveDrawerOpen(true);
                              setOpenDrawer(false);
                            }}
                          >
                            {TEXT.CAPACITY_RESERVE_BUTTON}
                          </Button>
                        </Grid>
                      )}
                  </Grid>
                </Box>

                {!loadingTable && hasOffers && (
                  <AccordionComponent
                    data={dataResponse}
                    summaryConfig={ACCORDION_CONFIG_OFFER(demandType, valueTab)}
                    detailsConfig={ACCORDION_CONFIG_DETAILS_OFFER}
                    handleOpenDrawer={handleOpenDrawer}
                  />
                )}
              </>
            )}
            {!hasOffers && !loadingTable && (
              <OfferEmptyComponent
                valueTab={valueTab}
                programmingOffer={() => {
                  setIsCapacityReserveDrawerOpen(true);
                  setOpenDrawer(false);
                }}
                demandType={demandType}
              />
            )}
            {loadingTable && <ConfigurableTableSkeleton />}
          </Paper>
        </>
      )}
      {hasOffers && !error && (
        <Pagination
          onPageChange={changePagination}
          initialPage={currentPage}
          numberOfPages={numberOfPages}
          setCurrentPage={setCurrentPage}
        />
      )}
      {!loadingTable && error && (
        <ConfigurableTableError TEXT_TABLE={TEXT_TABLE} />
      )}
    </>
  );
}

export default OfferContainer;
