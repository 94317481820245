import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { RANGE_FILTER_KEY } from 'offer/offer.constants';

import OfferAggregationComponent from './offer-aggregation.component';
import { offerAggregationService } from '../offer.service';
import { getTotalFor } from '../offer.utils';

const RANGE_FILTERS = [
  RANGE_FILTER_KEY.SCHEDULING_LATE,
  RANGE_FILTER_KEY.SCHEDULING_FOR_NOW,
  RANGE_FILTER_KEY.SCHEDULING_FOR_TODAY
];

const DRIVER_ACCEPT_FILTERS = [
  RANGE_FILTER_KEY.ACTIVE_WAITING_ACCEPTANCE,
  RANGE_FILTER_KEY.ACTIVE_WAITING_PICKUP
];

function filteredParameters(filters) {
  const status = filters.status || [];

  // RANGE_FILTER could be influenced by what user selected on filter,
  // but DRIVER_ACCEPT_FILTERS not and this should be always present
  const selectedRangeFilters = filters.timeIntervals?.length
    ? RANGE_FILTERS.filter(x => filters.timeIntervals.includes(x))
    : RANGE_FILTERS;
  const timeIntervals = [...selectedRangeFilters, ...DRIVER_ACCEPT_FILTERS];

  return [status, timeIntervals];
}

export default function OfferAggregationContainer({ filters }) {
  const [loading, setLoading] = useState(true);
  const [lateTotal, setLateTotal] = useState(0);
  const [forNowTotal, setForNowTotal] = useState(0);
  const [forTodayTotal, setForTodayTotal] = useState(0);
  const [waitingAcceptanceTotal, setWaitingAcceptanceTotal] = useState(0);
  const [waitingPickupTotal, setWaitingPickupTotal] = useState(0);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const aggregationResult = await offerAggregationService(
        ...filteredParameters(filters)
      );
      setLateTotal(
        getTotalFor(RANGE_FILTER_KEY.SCHEDULING_LATE, aggregationResult)
      );
      setForNowTotal(
        getTotalFor(RANGE_FILTER_KEY.SCHEDULING_FOR_NOW, aggregationResult)
      );
      setForTodayTotal(
        getTotalFor(RANGE_FILTER_KEY.SCHEDULING_FOR_TODAY, aggregationResult)
      );
      setWaitingAcceptanceTotal(
        getTotalFor(
          RANGE_FILTER_KEY.ACTIVE_WAITING_ACCEPTANCE,
          aggregationResult
        )
      );
      setWaitingPickupTotal(
        getTotalFor(RANGE_FILTER_KEY.ACTIVE_WAITING_PICKUP, aggregationResult)
      );
      setLoading(false);
    })();
  }, [filters]);

  return (
    <>
      {!loading && (
        <OfferAggregationComponent
          lateTotal={lateTotal}
          forNowTotal={forNowTotal}
          forTodayTotal={forTodayTotal}
          waitingAcceptanceTotal={waitingAcceptanceTotal}
          waitingPickupTotal={waitingPickupTotal}
        />
      )}
      {loading && <OfferAggregationComponent.SkeletonVersion />}
    </>
  );
}

OfferAggregationContainer.propTypes = {
  filters: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string),
    timeIntervals: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};
