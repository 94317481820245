import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import styleMinimalist from '@loggi/google-maps';
import { colors } from '@loggi/mar';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import getDistributionCenterInfo from 'information/routines-management/routines/routines.service';
import PropTypes from 'prop-types';
import DriverPositionIcon from 'offer/offer-route-map/icons/driver-position.svg';
/*
  IMPORTANT NOTE!:
  .css files must be avoided! This file was created exclusively for the use of Google Maps,
  as the library does not allow the use of useStyles.
*/
import './markerStyle.css';

let previousSelectedMarker = null;
let currentSelectedMarker = null;
let previousRef = null;
let markers = [];
let googleMapsAPI;

export const changeMarkerStyle = (marker, ref) => {
  if (!marker || !ref) return;
  previousSelectedMarker = currentSelectedMarker;
  if (previousSelectedMarker) {
    const previousLabel = previousSelectedMarker.getLabel();
    previousLabel.className = 'marker-label';
    previousSelectedMarker.setLabel(previousLabel);
  }

  const label = marker.getLabel();
  label.className = 'marker-label active';
  marker.setLabel(label);
  currentSelectedMarker = marker;

  if (ref?.current) {
    if (previousRef) {
      // eslint-disable-next-line prefer-destructuring
      previousRef.style.backgroundColor = colors.root[0];
    }

    const currentRef = ref.current;
    currentRef.scrollIntoView();
    // eslint-disable-next-line prefer-destructuring
    currentRef.style.backgroundColor = colors.blue[50];
    previousRef = currentRef;
  }
};

export const addDriverMarker = (lat, lng) => {
  if (!googleMapsAPI?.map || !googleMapsAPI?.maps) {
    return;
  }

  const driverPositionMarker = markers.filter(
    marker => marker.markerId === 'driverPosition'
  );
  const coordinates = new googleMapsAPI.maps.LatLng(lat, lng);

  if (driverPositionMarker.length > 0) {
    driverPositionMarker[0].setPosition(coordinates);
  } else {
    const marker = new googleMapsAPI.maps.Marker({
      position: coordinates,
      map: googleMapsAPI.map,
      icon: DriverPositionIcon,
      markerId: 'driverPosition'
    });
    markers.push(marker);
  }
};

function RouteMap({ route, refs, updateMapLoaded }) {
  const GOOGLE_MAPS_API_KEY = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  const [baseCoordinates, setBaseCoordinates] = useState(null);
  const routingCode = getSelectedRoutingCode();

  useEffect(() => {
    getDistributionCenterInfo().then(info => {
      setBaseCoordinates({
        lat: info?.dcInfo?.address?.coordinates?.latitude || 0,
        lng: info?.dcInfo?.address?.coordinates?.longitude || 0
      });
    });
  }, []);

  const clearAllMarkers = () => {
    markers.forEach(marker => {
      if (marker?.setMap) marker.setMap(null);
    });
    markers = [];
  };

  const drawPolyline = () => {
    const decodedPath = googleMapsAPI.maps.geometry.encoding.decodePath(
      route.geometry
    );
    const path = new googleMapsAPI.maps.Polyline({
      path: decodedPath,
      strokeColor: '#079BD3',
      strokeWeight: 4
    });
    path.setMap(googleMapsAPI.map);
  };

  const setMarkerClick = ({ marker, point }) => {
    marker.addListener('click', () => {
      const key = point ? point.displayId : routingCode;
      changeMarkerStyle(marker, refs[key].point);
    });
  };

  const createMarker = (text, coordinate) => {
    const marker = new googleMapsAPI.maps.Marker({
      position: coordinate,
      map: googleMapsAPI.map,
      label: {
        text,
        color: colors.smoke[900],
        className: 'marker-label'
      }
    });

    if (!refs[text]) {
      refs[text] = {}; // eslint-disable-line no-param-reassign
    }
    refs[text].marker = marker; // eslint-disable-line no-param-reassign

    return marker;
  };

  const setBoundsAndRecenter = addedMarkers => {
    const bounds = new googleMapsAPI.maps.LatLngBounds();

    addedMarkers.forEach(marker => {
      bounds.extend(marker.getPosition());
    });

    googleMapsAPI.map.setCenter(bounds.getCenter());
    googleMapsAPI.map.fitBounds(bounds);
    googleMapsAPI.map.setZoom(googleMapsAPI.map.getZoom() - 1);

    if (addedMarkers.length <= 2) {
      googleMapsAPI.map.setZoom(16);
      googleMapsAPI.map.fitBounds(bounds);
    }
  };

  const setMarkersOnGoogleMaps = () => {
    clearAllMarkers();
    if (!googleMapsAPI?.map || !googleMapsAPI?.maps) return;

    const newMarkers = [];

    const baseCoordinate = new googleMapsAPI.maps.LatLng(
      baseCoordinates.lat,
      baseCoordinates.lng
    );
    const baseMarker = createMarker(routingCode, baseCoordinate);
    setMarkerClick({ marker: baseMarker });
    newMarkers.push(baseMarker);

    route.points.forEach(point => {
      const latitude = point.latitude || baseCoordinates.lat;
      const longitude = point.longitude || baseCoordinates.lng;

      const coordinate = new googleMapsAPI.maps.LatLng(latitude, longitude);
      const marker = createMarker(point.displayId, coordinate);
      setMarkerClick({ marker, point });
      newMarkers.push(marker);
    });
    markers = newMarkers;

    drawPolyline();
    setBoundsAndRecenter(newMarkers);
  };

  const onLoadGoogleMaps = (map, maps) => {
    googleMapsAPI = { map, maps };
    setMarkersOnGoogleMaps();
    updateMapLoaded();
  };

  useEffect(() => {
    if (baseCoordinates) {
      setMarkersOnGoogleMaps();
    }
  }, [baseCoordinates]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {baseCoordinates && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: GOOGLE_MAPS_API_KEY
          }}
          center={baseCoordinates}
          defaultZoom={16}
          options={{
            styles: styleMinimalist
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => onLoadGoogleMaps(map, maps)}
        />
      )}
    </>
  );
}

export default React.memo(RouteMap);

RouteMap.propTypes = {
  route: PropTypes.shape({
    geometry: PropTypes.string,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        displayId: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number
      })
    )
  }).isRequired,
  refs: PropTypes.shape(
    PropTypes.shape({
      current: PropTypes.shape({})
    })
  ),
  updateMapLoaded: PropTypes.func.isRequired
};

RouteMap.defaultProps = {
  refs: {}
};
