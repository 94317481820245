import React from 'react';
import { Box, Divider, Paper, Typography, Avatar } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import useStyles from 'view/molecules/package-drawer/drawer.style';
import { Skeleton } from '@material-ui/lab';
import { INFOS } from './constants';

export default function DelivererInfoComponent({
  companyType,
  data,
  loading,
  errorFetchDelivererInfo
}) {
  const classes = useStyles();

  return (
    <Paper
      component={Box}
      elevation={10}
      flexGrow={1}
      mb={2}
      minHeight={44}
      p={5}
    >
      {errorFetchDelivererInfo ? (
        <Typography color="textSecondary">
          Eita! Deu algo ruim aqui e não conseguimos carregar as informações.
        </Typography>
      ) : (
        <Box display="flex">
          {loading ? (
            <Skeleton variant="rect" width={72} height={72} animation="wave" />
          ) : (
            <>
              <Box mr={4} display="flex" alignItems="center">
                <Avatar className={classes.roundedImage} />
                <Box ml={2}>
                  <Typography>
                    <em>{data?.deliverer?.name}</em>
                  </Typography>
                  <Typography color="textSecondary">
                    {INFOS.modal(data?.deliverer)}
                  </Typography>
                </Box>
              </Box>
              <Box ml={5} display="flex" alignItems="center">
                <Box>
                  <Typography>
                    <em>{INFOS.UPDATE_LABEL}</em>
                  </Typography>
                  <Typography color="textSecondary">
                    <em>{INFOS.updateFormat(data)}</em>
                  </Typography>
                </Box>
              </Box>
              {companyType === 'LEVE' && (
                <Box mx={5}>
                  <Divider orientation="vertical" />
                </Box>
              )}
            </>
          )}
          {/*
          NOTE: The following code has been removed as part of the rollout V2
          This code may be needed again in the near future.
          Please refer to PR #11538 for the original implementation:
          https://github.com/loggi/ui/pull/11538/files
        */}
          <Box display="flex" alignItems="center">
            {loading ? (
              <Box ml={10}>
                <Skeleton
                  variant="rect"
                  width={240}
                  height={40}
                  animation="wave"
                />
              </Box>
            ) : (
              companyType === 'LEVE' && (
                <Box>
                  <Typography>
                    <em>{INFOS.CIRCLES_LABEL}</em>
                  </Typography>
                  <Typography color="textSecondary">
                    <em>
                      {data?.circles ? INFOS.circlesFormat(data?.circles) : '-'}
                    </em>
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
}

DelivererInfoComponent.propTypes = {
  companyType: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  errorFetchDelivererInfo: PropTypes.bool.isRequired
};
