import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { database } from 'infra/firebase/firebase.init';
import { IsFSActiveWithoutEmail } from 'auth/access-control/access-control.utils';

export default function FsRealtimeBool(fsName) {
  const [valueFS, setValueFS] = useState(false);

  const FEATURE_SWITCHES = 'feature_switches';

  const featureSwitchesRef = database.ref(`${FEATURE_SWITCHES}/${fsName}`);

  useEffect(() => {
    featureSwitchesRef.on(
      'value',
      snapshot => {
        setValueFS(snapshot?.val() || false);
      },
      () => {
        setValueFS(false);
      }
    );
  }, [featureSwitchesRef]);
  return valueFS;
}

FsRealtimeBool.propTypes = {
  fsName: PropTypes.string.isRequired
};

export function useFsRealtimeGeneral(fsName) {
  const [valueFS, setValueFS] = useState(false);

  const FEATURE_SWITCHES = 'feature_switches';

  const featureSwitchesRef = database.ref(`${FEATURE_SWITCHES}/${fsName}`);

  useEffect(() => {
    featureSwitchesRef.on(
      'value',
      snapshot => {
        if (snapshot?.val()) setValueFS(snapshot?.val() || false);
        else setValueFS(false);
      },
      () => {
        setValueFS(false);
      }
    );
  }, [featureSwitchesRef]);

  return IsFSActiveWithoutEmail(valueFS || false) || false;
}
