import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';

import ConfirmDialog from 'shared/confirm-dialog';

import OPERATOR_MODEL from 'users-management/operators/operators.model';

import OperatorDetail from './operators-detail.component';
import {
  operatorRevokeService,
  operatorResendService
} from '../operators.service';
import TEXT from './operators-detail.constants';

export default function OperatorDetailContainer({
  detail,
  onRevokeCallback,
  closeDrawer,
  isOpen,
  callbackEdit
}) {
  const { REVOKE_DIALOG, RESEND_DIALOG } = TEXT;
  const [DIALOG, setDialog] = useState(REVOKE_DIALOG);

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const callbackRevoke = () => {
    setDialog(REVOKE_DIALOG);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    const isRevoke = DIALOG.TITLE === REVOKE_DIALOG.TITLE;
    const service = isRevoke ? operatorRevokeService : operatorResendService;
    setLoading(true);
    service(detail.lastMileOperator.userId)
      .then(() => {
        setLoading(false);
        setOpen(false);
        showSnackbar({
          message: DIALOG.SUCCESS,
          variant: 'success',
          enqueueSnackbar
        });
        onRevokeCallback();
      })
      .catch(error => {
        setLoading(false);

        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      });
  };

  const callbackResend = () => {
    setDialog(RESEND_DIALOG);
    setOpen(true);
  };

  return (
    <>
      <OperatorDetail
        detail={detail}
        callbackEdit={callbackEdit}
        callbackResend={callbackResend}
        callbackRevoke={callbackRevoke}
        closeDrawer={closeDrawer}
        isOpen={isOpen}
      />

      <ConfirmDialog
        open={open}
        title={DIALOG.TITLE}
        description={DIALOG.DESCRIPTION(detail.email)}
      >
        <Button
          color="primary"
          disabled={loading}
          fullWidth
          onClick={handleClose}
          size="large"
          variant="outlined"
        >
          {DIALOG.CANCEL_BUTTON}
        </Button>
        <Button
          color="primary"
          disabled={loading}
          fullWidth
          onClick={handleConfirm}
          size="large"
          variant="contained"
        >
          {DIALOG.CONFIRM_BUTTON}
        </Button>
      </ConfirmDialog>
    </>
  );
}

OperatorDetailContainer.propTypes = {
  detail: OPERATOR_MODEL.isRequired,
  onRevokeCallback: PropTypes.func,
  closeDrawer: PropTypes.func,
  isOpen: PropTypes.bool,
  callbackEdit: PropTypes.func
};

OperatorDetailContainer.defaultProps = {
  closeDrawer: () => {},
  onRevokeCallback: () => {},
  isOpen: false,
  callbackEdit: () => {}
};
