import React from 'react';

import { useRouteMatch, Redirect, Switch, Route } from 'react-router-dom';

import { AUTHORIZED_ROUTES } from 'view/constants';

import Routines from 'information/routines-management/routines';
import Circles from 'information/routines-management/circles';
import RoutinesManagementProvider from 'information/routines-management/routines-management.provider';

export default function RoutinesManagementContainer() {
  const { path, url } = useRouteMatch();

  const CircleContainerWithDrawerOpen = () => <Circles shouldOpenDrawer />;

  return (
    <RoutinesManagementProvider>
      <Switch>
        <Route
          path={`${path}${AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.BASE}`}
          component={Routines}
        />
        <Route
          path={`${path}${AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.CIRCLES}`}
          component={Circles}
        />
        <Route
          path={`${path}${
            AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.CREATE_CIRCLES
          }`}
          component={CircleContainerWithDrawerOpen}
        />
        <Redirect
          to={{
            pathname: `${url}${AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.BASE}`
          }}
        />
      </Switch>
    </RoutinesManagementProvider>
  );
}
