import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { TEXTS } from '../drivers-list.constants';
import emptyState from '../../img/empty-state.png';

const EmptyState = () => (
  <Box
    width="100%"
    py={18}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    justifyItems="center"
  >
    <Box mb={3}>
      <img alt="empty state" src={emptyState} />
    </Box>
    <Box px={10}>
      <Typography align="center" variant="subtitle2">
        {TEXTS.EMPTY_STATE}
      </Typography>
    </Box>
  </Box>
);

export default EmptyState;
