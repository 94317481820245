import api from 'infra/api/leve';
import { URL_PROXY_DRIVERS_V2_PREFIX } from './constants';

/**
 * Wrapped GET request o Leve's API for a deliverers list
 * List all the deliverers of that Company
 *
 * @returns {Promisse} Axios response object containing the following information
 * {
 *   // `data` is the response that was provided by the server
 *   delivererList: [{
 *            id: Int,
 *            name: String
 *    }],
 *
 *   // `status` is the HTTP status code from the server response
 *   status: 200,
 *
 *   // `statusText` is the HTTP status message from the server response
 *   statusText: 'OK',
 * }
 *
 */

export default (withCount = false, includeInactive = false) => {
  return api.get(`${URL_PROXY_DRIVERS_V2_PREFIX}/drivers-access`, {
    params: {
      with_count: withCount,
      include_inactive: includeInactive
    }
  });
};
