import { Machine, assign, sendParent } from 'xstate';
import drawerMovePackageService from '../drawer-move-package.service';
import {
  DESTINATION_REFUSED,
  PROCESS_RECEIVING,
  TEXT
} from '../drawer.constants';

export const STATES = {
  SELECTING: 'selecting',
  SUBMITTING: 'submittingRefuse'
};

export const ACTIONS = {
  SELECT_OPTION: 'selectOption',
  BACK: 'backToDetail',
  CHANGE_NOTES: 'changeNotes',
  SUBMIT: 'submitRefuse',
  SET_NOTIFICATION: 'setNotification',
  RELOAD_PAGE: 'reloadPage'
};

const refuseMachine = Machine({
  id: 'refuse',
  initial: 'selecting',
  context: {
    selectedItem: null,
    packageId: null,
    notes: '',
    isExpectNotes: false,
    statusCodeOverwrite: null,
    barcode: null,
    destination: DESTINATION_REFUSED
  },
  states: {
    [STATES.SELECTING]: {
      on: {
        [ACTIONS.SELECT_OPTION]: {
          actions: assign({
            selectedItem: (_, event) => event.value.text,
            destination: (_, event) =>
              event.value.destinationIsNull ? null : DESTINATION_REFUSED,
            statusCodeOverwrite: (_, event) =>
              event.value.statusCodeOverwrite || null,
            isExpectNotes: (_, event) => event.value.isExpectNotes || false,
            notes: (context, event) =>
              !event.value.isExpectNotes
                ? event.value.notes || ''
                : context.notes
          })
        },
        [ACTIONS.CHANGE_NOTES]: {
          actions: assign({ notes: (_, event) => event.value })
        },
        [ACTIONS.SUBMIT]: STATES.SUBMITTING,
        [ACTIONS.BACK]: {
          actions: sendParent(ACTIONS.BACK, { to: 'drawer' })
        }
      }
    },
    [STATES.SUBMITTING]: {
      invoke: {
        id: 'refusePackage',
        src: context =>
          drawerMovePackageService({
            process: PROCESS_RECEIVING,
            destination: context.destination,
            packageId: context.packageId,
            notes: context.notes,
            statusCodeOverwrite: context.statusCodeOverwrite
          }),
        onDone: {
          actions: [
            sendParent({
              type: ACTIONS.SET_NOTIFICATION,
              data: {
                message: TEXT.REFUSED_SUCCESS,
                error: false
              },
              to: 'drawer'
            }),
            sendParent(ACTIONS.RELOAD_PAGE, { to: 'drawer' })
          ]
        },
        onError: {
          target: STATES.SELECTING,
          actions: [
            sendParent((_, event) => ({
              type: ACTIONS.SET_NOTIFICATION,
              data: {
                message: event.data.message,
                error: true
              },
              to: 'drawer'
            }))
          ]
        }
      }
    }
  }
});

export default refuseMachine;
