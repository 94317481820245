import InputMask from 'inputmask-core';

function getLastInvalidLength(instance, end) {
  let length = 0;
  const string = instance.getValue().slice(0, end);

  string.split('').reduceRight((check, char) => {
    if (check) {
      return true;
    }

    if (instance.input(char)) {
      return true;
    }

    length += 1;
    return false;
  }, false);

  if (length === instance.getValue().length) {
    return 0;
  }

  return length;
}

export default function mask(value, pattern, options = {}) {
  if (!value) {
    return '';
  }

  const instance = new InputMask({
    ...options,
    value,
    pattern,
    placeholderChar: ' '
  });

  let last;
  // manually insert each character in the mask instance
  // and the last index to check if it was a valid char or not
  instance
    .getRawValue()
    .split('')
    .forEach(s => {
      last = instance.input(s);
    });

  // get the index of the last input typed
  const { end } = instance.selection;

  // if nothing was inserted, or if the first number inserted was invalid
  if (value.length === 1 && !end) {
    return '';
  }

  // if the mask was fully completed
  if (value.length >= instance.getValue().length && last) {
    return instance.getValue();
  }

  // check if the last characters of the mask is a valid input or not
  const lastInvalidLength = getLastInvalidLength(instance, end);
  return instance.getValue().slice(0, end - lastInvalidLength);
}
