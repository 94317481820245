import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import ConfirmDialogComponent from 'shared/confirm-dialog';

import { TEXTS } from './drivers-profile.constants';
import useStyles from '../drivers.styles';

const DriversProfileDeleteComponent = ({ onDelete, profile }) => {
  const { hasPackage } = profile?.deliverer || {};
  const styles = useStyles();
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    onDelete(profile);
    setOpen(false);
  };

  return (
    <>
      <ConfirmDialogComponent
        open={isOpen}
        title={TEXTS.DELETE_REGISTER_TITLE}
        description={TEXTS.DELETE_REGISTER_DESCRIBE}
      >
        <Button onClick={handleClose} color="primary">
          {TEXTS.DELETE_REGISTER_BACK}
        </Button>
        <Button
          className={styles.button}
          onClick={handleDelete}
          variant="contained"
        >
          {TEXTS.DELETE_REGISTER_BUTTON}
        </Button>
      </ConfirmDialogComponent>
      <Button
        disabled={hasPackage}
        fullWidth
        color="primary"
        variant="outlined"
        onClick={handleOpen}
      >
        {TEXTS.DELETE_REGISTER_BUTTON}
      </Button>
    </>
  );
};

DriversProfileDeleteComponent.propTypes = {
  onDelete: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    deliverer: PropTypes.shape({
      hasPackage: PropTypes.bool
    })
  }).isRequired
};

export default DriversProfileDeleteComponent;
