import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useStyles = makeStyles(() => ({
  hoverBox: {
    transition: '0.5s',
    '&:hover': {
      backgroundColor: colors.blue[50],
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
      borderRadius: 8
    }
  }
}));

export default useStyles;
