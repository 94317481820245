import React, { useEffect, useState } from 'react';

import { ReactComponent as IconValor } from 'images/ic-cadeado-fechado.svg';
import { ReactComponent as IconOpenLock } from 'images/ic-cadeado-aberto.svg';
import { ReactComponent as IconCloseLockGrey } from 'images/ic-cadeado-fechado-grey.svg';

import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import CardIcon from 'shared/card-icon';
import CardAggregation from './card-aggregation.component';
import CardAggregationError from './card-aggregation.error.component';
import { KEY_PERIOD } from '../finance.constants';

import cardAggService from './card-aggregation.service';

function CardAggregationContainer({
  filters,
  selectedPeriod,
  onErrorContanier
}) {
  const [responseAgg, setResponseAgg] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { period } = useParams();

  const defineIcone = () => {
    const mapPeriod = {
      [KEY_PERIOD.OPEN]: {
        icon: IconOpenLock,
        variant: 'primary'
      },
      [KEY_PERIOD.CLOSE]: {
        icon: IconValor,
        variant: 'success'
      },
      [KEY_PERIOD.PREVIOUS]: {
        icon: IconCloseLockGrey,
        variant: 'secondary'
      }
    };

    return mapPeriod[period];
  };

  const onError = () => {
    setError(true);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedPeriod.start !== '') {
      setLoading(true);
      cardAggService(filters, selectedPeriod, period)
        .then(cleanData => {
          setLoading(false);
          setResponseAgg(cleanData);
        })
        .catch(onError);
    }
  }, [filters, onErrorContanier, selectedPeriod, period]);

  useEffect(() => {
    setError(onErrorContanier);
  }, [onErrorContanier]);

  return (
    <>
      <CardIcon
        variant={defineIcone().variant}
        icon={defineIcone().icon}
        size="normal"
        alignItems="center"
      >
        {!error && (
          <CardAggregation
            loading={loading}
            localTotalValue={responseAgg.localTotalValue}
            atendidaTotalValue={responseAgg.atendidaTotalValue}
            deliveryTotalValue={responseAgg.deliveryTotalValue}
            pickupTotalValue={responseAgg.pickupTotalValue}
            transhipmentTotalValue={responseAgg.transhipmentTotalValue}
            dropoffTotalValue={responseAgg.dropoffTotalValue}
            periodTotalAmount={responseAgg.periodTotalAmount}
          />
        )}
        {error && <CardAggregationError />}
      </CardIcon>
    </>
  );
}

CardAggregationContainer.propTypes = {
  filters: PropTypes.shape({
    deliverers: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.arrayOf(PropTypes.string),
    updates: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  selectedPeriod: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string
  }).isRequired,
  onErrorContanier: PropTypes.bool
};

CardAggregationContainer.defaultProps = {
  onErrorContanier: false
};

export default CardAggregationContainer;
