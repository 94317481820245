import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { Box } from '@material-ui/core';
import getPackage from 'app/get-package';
import { TEXT } from 'view/molecules/package-drawer/drawer.constants';
import showSnackbar from 'shared/snackbar';
import DrawerError from 'view/molecules/package-drawer/drawer-error/drawer-error.component';
import ActivityInfoComponent from './activity-info.component';

export default function ActivityInfoContainer({
  isPackageHistoryEnabled,
  onHistory,
  packageCharge,
  onError,
  loading,
  setLoading
}) {
  const [packageInfo, setPackageInfo] = useState(null);
  const [showDrawerError, setShowDrawerError] = useState(false);
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const { enqueueSnackbar } = useSnackbar();

  const getPackageInfo = useCallback(async () => {
    const result = await getPackage(
      packageCharge.packageCharge.id,
      authenticatedUser?.email,
      packageCharge.packageCharge.taskId
    ).catch(error => {
      if (error.status === 404) {
        onError();
        showSnackbar({
          variant: 'error',
          message: TEXT.MESSAGE_NOTFOUND_PKG_LSD,
          enqueueSnackbar
        });
        return;
      }
      setShowDrawerError(true);
    });
    setPackageInfo(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageCharge.packageCharge.id]);

  const handleGetPackageInfo = async () => {
    setLoading(true);
    setShowDrawerError(false);
    await getPackageInfo();
    setLoading(false);
  };

  useEffect(() => {
    (async function onPackageChargeUpdate() {
      await handleGetPackageInfo();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageCharge.packageCharge.id]);
  return (
    <Box>
      {showDrawerError && !loading && (
        <DrawerError reloadDrawer={handleGetPackageInfo} />
      )}
      {packageInfo && !showDrawerError && !loading && (
        <ActivityInfoComponent
          isPackageHistoryEnabled={isPackageHistoryEnabled}
          onHistory={onHistory}
          packageCharge={packageCharge}
          packageInfo={packageInfo}
        />
      )}
    </Box>
  );
}

ActivityInfoContainer.propTypes = {
  isPackageHistoryEnabled: PropTypes.bool.isRequired,
  onHistory: PropTypes.func.isRequired,
  packageCharge: PropTypes.shape().isRequired,
  onError: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired
};
