import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Box, Typography, Grid, Button } from '@material-ui/core';

import { ReactComponent as ICLock } from 'images/ic-lock.svg';
import { useStyles as useStylesIcon } from 'shared/card-icon/card-icon.styles';

function ConfigurableTableError({ TEXT_TABLE }) {
  const classesIcon = useStylesIcon();

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      data-testid="empty-content"
      style={{ padding: '0px' }}
    >
      <Grid item xs={5}>
        <Box
          className={clsx(
            classesIcon.iconStyle,
            classesIcon.variantsecondary,
            classesIcon.sizelarge
          )}
          mx="auto"
          my={1}
        >
          <ICLock data-testid="ic-base-empty" />
        </Box>
        <Typography color="textSecondary" align="center">
          {TEXT_TABLE.MSG_ERROR}
        </Typography>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => window.location.reload()}
          >
            {TEXT_TABLE.BTN_ERROR}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

ConfigurableTableError.propTypes = {
  TEXT_TABLE: PropTypes.shape({
    MSG_ERROR: PropTypes.string,
    BTN_ERROR: PropTypes.string
  })
};

ConfigurableTableError.defaultProps = {
  TEXT_TABLE: {
    MSG_ERROR:
      'Eita! Deu algo ruim aqui e não conseguimos carregar as informações.',
    BTN_ERROR: 'Carregar novamente'
  }
};

export default ConfigurableTableError;
