import api from 'infra/api/leve';
import {
  GET_SORTING_CONTEXT,
  UPDATE_SORTING_CONTEXT,
  CREATE_SIMULATE_DEMAND_REGIONS
} from 'infra/service/constants';

export const getSortingContext = async licensePlate => {
  return api
    .get(`${GET_SORTING_CONTEXT}/${licensePlate} SEP`)
    .then(response => response);
};

export const getSimulation = async licensePlate => {
  return api
    .get(`${GET_SORTING_CONTEXT}/${licensePlate} SIMULATION SEP`)
    .then(response => response);
};

export const updateSortingContext = async routingCode => {
  return api
    .put(`${UPDATE_SORTING_CONTEXT}/${routingCode}`, {
      routing_code: routingCode
    })
    .then(response => response);
};

export const simulateDemandRegions = async (
  routingCode,
  numPositionsCar,
  numPositionsMoto
) => {
  return api
    .post(`${CREATE_SIMULATE_DEMAND_REGIONS}`, {
      routing_code: routingCode,
      num_positions_car: numPositionsCar,
      num_positions_moto: numPositionsMoto
    })
    .then(response => response);
};
