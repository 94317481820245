import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import useStyles from './configurable-table.styles';

function TableHeadMaker({ columns }) {
  const classes = useStyles();

  const headerList = Object.entries(columns).map(
    ([key, { header, headAlign, headStyle }]) => {
      return (
        <TableCell
          align={headAlign}
          key={key}
          data-testid={`table-header-${key}-collapsible`}
          style={headStyle}
        >
          <Typography variant="body2">{header}</Typography>
        </TableCell>
      );
    }
  );
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>{headerList}</TableRow>
    </TableHead>
  );
}

TableHeadMaker.propTypes = {
  columns: PropTypes.objectOf(
    PropTypes.shape({
      header: PropTypes.string,
      headAlign: PropTypes.string,
      propsStyleBody: PropTypes.shape,
      component: PropTypes.element
    })
  ).isRequired
};

export default TableHeadMaker;
