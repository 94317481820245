import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useStyles } from './alert-status.styles';

const AlertStatus = props => {
  const { type, customIcon, children, colors } = props;
  const classes = useStyles();
  return (
    <Alert
      icon={customIcon}
      className={clsx(classes.alertContainer, classes[colors])}
      variant="filled"
      severity={type}
    >
      <Typography variant="body2">{children}</Typography>
    </Alert>
  );
};
AlertStatus.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  customIcon: PropTypes.element,
  colors: PropTypes.string
};
AlertStatus.defaultProps = {
  type: 'warning',
  customIcon: null,
  colors: ''
};
export default AlertStatus;
