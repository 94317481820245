import { getSimulation } from './demand-regions.service';

export const groupByDemandRegionLabel = sortingContext => {
  return sortingContext?.decisions?.reduce((group, decision) => {
    if (decision.demandRegion) {
      const { label } = decision.demandRegion;
      group[label] = group[label] ?? []; // eslint-disable-line no-param-reassign
      group[label].push(decision.name);
    }
    return group;
  }, {});
};

export const filterDecisionsByDemandRegions = sortingContext => {
  return sortingContext?.decisions?.reduce((group, decision) => {
    const label = `${decision.name}: ${decision.demandRegion?.label}`;
    if (decision.demandRegion) {
      group[label] = decision?.demandRegion?.consensusPoints; // eslint-disable-line no-param-reassign
    }
    return group;
  }, {});
};

export const getNumberDecisions = sortingContext => {
  return Math.floor(
    Object.keys(filterDecisionsByDemandRegions(sortingContext)).length / 2
  );
};

export const getSimulationContext = async routingCode => {
  try {
    const response = await getSimulation(routingCode);
    return response?.data?.sortingContext;
  } catch (error) {
    return null;
  }
};

export const getLabels = sortingContext => {
  const labels = [
    ...new Set(
      sortingContext?.decisions?.map(decision => decision?.demandRegion?.label)
    )
  ];

  return labels.filter(Boolean);
};

export const colorsMap = [
  '#2196F3',
  '#FFC107',
  '#F44336',
  '#4CAF50',
  '#9C27B0',
  '#FF9800',
  '#3F51B5',
  '#00BCD4',
  '#FF5722',
  '#CDDC39',
  '#E91E63',
  '#673AB7',
  '#FFEB3B',
  '#009688',
  '#03A9F4',
  '#8BC34A',
  '#1565C0',
  '#FF8F00',
  '#C62828',
  '#2E7D32',
  '#6A1B9A',
  '#EF6C00',
  '#283593',
  '#00838F',
  '#D84315',
  '#9E9D24',
  '#AD1457',
  '#4527A0',
  '#F9A825',
  '#00695C',
  '#0277BD',
  '#558B2F',
  '#64B5F6',
  '#FFD54F',
  '#E57373',
  '#81C784',
  '#BA68C8',
  '#FFB74D',
  '#7986CB',
  '#4DD0E1',
  '#FF8A65',
  '#DCE775',
  '#F06292',
  '#9575CD',
  '#FFF176',
  '#4DB6AC',
  '#4FC3F7',
  '#AED581'
];
