import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useStyles from './confirm-dialog.component.styles';

export default function ConfirmDialogComponent({
  open,
  title,
  description,
  icon,
  children,
  descriptionStylized
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      data-testid="confirm-dialog"
      fullWidth
      className={classes.dialogBorder}
    >
      <Box p={3.5}>
        <DialogTitle className={classes.dialogWithoutPadding}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h4">
              {icon}
              <em>{title}</em>
            </Typography>
          </Box>
        </DialogTitle>
        <Box mb={3.5}>
          <DialogContent className={classes.dialogWithoutPadding}>
            {descriptionStylized || (
              <Typography variant="body1">{description}</Typography>
            )}
          </DialogContent>
        </Box>
        <DialogActions className={classes.dialogWithoutPadding}>
          {children}
        </DialogActions>
      </Box>
    </Dialog>
  );
}

ConfirmDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  descriptionStylized: PropTypes.node
};

ConfirmDialogComponent.defaultProps = {
  icon: null,
  descriptionStylized: null
};
