import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/pt-br';
import {
  Typography,
  Box,
  Divider,
  Grid,
  Chip,
  Link,
  ListItemText,
  List,
  ListItem
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { SWITCHES } from 'view/constants';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { TEXT } from '../drawer.constants';
import useStyles from '../drawer.style';
import { formatValue } from '../../finance.configuration';
import PassoAPassoLevesPDF from './passo_a_passo_leves.pdf';

export default function DrawerFinanceComponent(props) {
  const { valueNF } = props;

  const classes = useStyles();

  function sumTotal() {
    const delivery = valueNF.deliveryTotalValue || 0.0;
    const pickup = valueNF.pickupTotalValue || 0.0;
    const transhipment = valueNF.transhipmentTotalValue || 0.0;
    const dropOff = valueNF.dropoffTotalValue || 0.0;

    return delivery + pickup + transhipment + dropOff;
  }

  const enableShowDropOffValueInfo = IsFSActiveForCurrentBase(
    SWITCHES.enableShowDropOffValueInfo
  );

  const HeaderInfoUpdated = () => (
    <Box mx={3.5} mt={5} mb={3.5}>
      <Box color={colors.smoke[700]}>
        <Typography variant="body2">{TEXT.NF}</Typography>
      </Box>
      <Box display="flex" my={3} justifyContent="space-between">
        <Typography variant="h4" className={classes.bolder}>
          <em>
            {TEXT.VALUE_DOLLAR} {formatValue(sumTotal())}
          </em>
        </Typography>
      </Box>
      <Box my={3}>
        <Typography variant="body1">{TEXT.INFO_BOX_TEXT_UPDATED}</Typography>
      </Box>
    </Box>
  );

  const ChargeSummaryUpdated = () => (
    <Box mx={3.5} my={3.5}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="body2">{TEXT.DELIVERY}</Typography>
        <Typography variant="body2">
          {formatValue(valueNF.deliveryTotalValue)}
        </Typography>
      </Box>
      {valueNF.pickupTotalValue > 0 && (
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
          data-testid="pickup-box"
        >
          <Typography variant="body2">{TEXT.PICKUP}</Typography>
          <Typography variant="body2">
            {formatValue(valueNF.pickupTotalValue)}
          </Typography>
        </Box>
      )}
      {enableShowDropOffValueInfo && valueNF.dropoffTotalValue > 0 && (
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
          data-testid="counter-box"
        >
          <Typography variant="body2">{TEXT.COUNTER}</Typography>
          <Typography variant="body2">
            {formatValue(valueNF.dropoffTotalValue)}
          </Typography>
        </Box>
      )}
      {valueNF.transhipmentTotalValue > 0 && (
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
          data-testid="transhipment-box"
        >
          <Typography variant="body2">{TEXT.TRANSHIPMENT}</Typography>
          <Typography variant="body2">
            {formatValue(valueNF.transhipmentTotalValue)}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2">
          <em>{TEXT.TOTAL}</em>
        </Typography>
        <Typography variant="body2">
          <em>{formatValue(sumTotal())}</em>
        </Typography>
      </Box>
    </Box>
  );

  const SendNote = () => (
    <Box mx={3.5} my={3.5} display="flex" flexDirection="column">
      <Box mb={1}>
        <Typography variant="body2">
          <em>{TEXT.DATA_TO_SEND_NOTES}</em>
        </Typography>
      </Box>

      <Box color={colors.smoke[700]} display="flex" flexDirection="row">
        <Box mr={3}>
          <Typography variant="body2">{TEXT.TEXT_TO_HELP_SEND_NOTE}</Typography>
        </Box>

        <Chip
          display="flex"
          label={TEXT.CONSULT_LIST}
          variant="outlined"
          color="primary"
          data-testid="invoice"
          onClick={() => {
            window.open(TEXT.CONSULT_LIST_LINK, '_blank');
          }}
        />
      </Box>
    </Box>
  );

  const ContactUpdated = () => (
    <Box mx={3.5} my={3.5}>
      <Grid container direction="column" my={1.5}>
        <Box mb={1}>
          <Typography variant="body2">
            <em>{TEXT.HOW_TO_SEND_YOUR_NF}</em>
          </Typography>
        </Box>
        <Box color={colors.smoke[700]} display="flex" flexDirection="row">
          <Box mr={3}>
            <Typography variant="body2">
              {TEXT.TEXT_HELP_CENTER}
              <Link
                href={PassoAPassoLevesPDF}
                download="Passo a passo leves"
                target="_blank"
                rel="noreferrer"
              >
                <strong>{TEXT.TEXT_CONSULTE}</strong>
              </Link>
            </Typography>
          </Box>

          <Chip
            display="flex"
            label="Acessar formulário"
            variant="outlined"
            color="primary"
            onClick={() => {
              window.open(TEXT.LINK_PIPEFY, '_blank');
            }}
          />
        </Box>
      </Grid>
    </Box>
  );

  const ReportProblem = () => (
    <List>
      <ListItem
        button
        data-testid="report-problem"
        onClick={() => window.open(TEXT.REPORT_PROBLEM_LINK, '_blank')}
        classes={{ root: classes.item }}
        disableGutters
      >
        <Grid container alignItems="center" justify="flex-end">
          <Grid className={classes.listItemLabel}>
            <Box ml={3.5} my={2.5}>
              <ListItemText
                primary={
                  <Typography variant="body1">{TEXT.REPORT_PROBLEM}</Typography>
                }
              />
            </Box>
          </Grid>
          <Grid>
            <Box
              ml={2}
              mr={3.5}
              my={2.5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ArrowForwardIosIcon
                className={classes.fontSizeIcon}
                color="primary"
                size="small"
              />
            </Box>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );

  const Help = () => (
    <Box
      mx={3.5}
      my={3.5}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" flexDirection="row">
        <Typography variant="body1">
          <em>{TEXT.HAVE_QUESTIONS_FOOTER}</em>
        </Typography>
        <Typography variant="body1">&nbsp;</Typography>
        <Typography variant="body1">{TEXT.UNDERSTAND_FOOTER}</Typography>
      </Box>
      <Chip
        label={TEXT.MORE_ABOUT}
        variant="outlined"
        color="primary"
        data-testid="help"
        onClick={() => {
          window.open(TEXT.HELP_LINK, '_blank');
        }}
      />
    </Box>
  );

  return (
    <Box>
      <HeaderInfoUpdated />
      <Divider />
      <ChargeSummaryUpdated />
      <Divider />
      <>
        <SendNote />
        <Divider />
      </>
      <ContactUpdated />
      <Divider />
      <ReportProblem />
      <Divider />
      <Help />
    </Box>
  );
}

DrawerFinanceComponent.propTypes = {
  valueNF: PropTypes.shape({
    periodTotalValue: PropTypes.number.isRequired,
    deliveryTotalValue: PropTypes.number.isRequired,
    pickupTotalValue: PropTypes.number.isRequired,
    transhipmentTotalValue: PropTypes.number.isRequired,
    dropoffTotalValue: PropTypes.number.isRequired
  }).isRequired
};
