import { getCompanyType } from 'auth/access-control/access-control.service';
import { EMAILS, LOGGI_SUPPORT, COMPANY_TYPE } from './checked-by.constant';

const CheckedBy = props => {
  const { name, email } = props;

  if (getCompanyType() === COMPANY_TYPE.LEVE) {
    if (!email) return '-';

    const serverEmail = email.substring(email.lastIndexOf('@') + 1);

    return EMAILS.includes(serverEmail) ? LOGGI_SUPPORT : name;
  }

  return name;
};

export default CheckedBy;
