import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as MegaphoneIcon } from 'images/ic-megaphone.svg';
import { ReactComponent as PriorityIcon } from 'images/ic-priority.svg';

import { Box } from '@material-ui/core';
import palette from '@loggi/mar/src/palette';

export default function NotificationTypeBox({ priority }) {
  const priorityProperties = {
    LOW: {
      backgroundColor: palette.primary.light,
      icon: <PriorityIcon stroke={palette.primary.main} />
    },
    HIGH: {
      backgroundColor: palette.error.light,
      icon: <PriorityIcon stroke={palette.error.main} />
    },
    NORMAL: {
      backgroundColor: palette.info.light,
      icon: <MegaphoneIcon fill={palette.info.main} />
    }
  };

  return (
    <Box
      width={40}
      minWidth={40}
      height={40}
      borderRadius={4}
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginRight={2}
      bgcolor={priorityProperties[priority].backgroundColor}
      data-testid="box-priority"
    >
      {priorityProperties[priority].icon}
    </Box>
  );
}

NotificationTypeBox.propTypes = {
  priority: PropTypes.string.isRequired
};
