import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';

import { useAmplifyAuth } from '@loggi/authentication-lib';
import { colors } from '@loggi/mar';
import { SWITCHES } from 'view/constants';

import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { Hidden } from '@material-ui/core';
import MenuResponsiveComponent from './responsive/menu-responsive.component';
import MenuComponent from './menu.component';

const MenuContainer = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const historyPush = path => {
    history.push(path);
  };
  const {
    state: { authenticatedUser, loading, newPasswordRequired }
  } = useAmplifyAuth();

  const isAnyUserAuthenticated = authenticatedUser;
  const isOldLayout =
    pathname.includes('/transferir') ||
    pathname.includes('distribuir') ||
    pathname.includes('sua-equipe') ||
    pathname.includes('teste-coletas');

  const enableMenuResponsive = IsFSActiveForCurrentBase(
    SWITCHES.enableMenuResponsive
  );

  // the useAmplifyAuth hook has a loading state for amplify.
  // We have to wait its loading state to finish to check
  // if the user is either logged with AWS Cognito or Leve's jwt
  return (
    !loading &&
    !newPasswordRequired &&
    isAnyUserAuthenticated && (
      <>
        {enableMenuResponsive && (
          <>
            <Hidden smUp data-testid="should-show-in-mobile-menu-component">
              <MenuResponsiveComponent />
            </Hidden>
            <Hidden
              xsDown
              data-testid="should-show-in-desktop-profile-component"
            >
              <MenuComponent historyPush={historyPush} />
            </Hidden>
          </>
        )}

        {!enableMenuResponsive && <MenuComponent historyPush={historyPush} />}

        {/* On old pages we need the AppBar to place a background on menu */
        isOldLayout && (
          <AppBar
            data-testid="old-menu-arco-appbar"
            position="static"
            component="div"
            style={{
              backgroundImage: colors.gradients.sanches,
              width: '100%',
              height: '110px',
              marginBottom: '28px',
              zIndex: '-1'
            }}
          />
        )}
      </>
    )
  );
};

export default MenuContainer;
