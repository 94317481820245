import api from 'infra/api/leve';
import {
  URL_API_V1_LAST_MILE_PREFIX,
  URL_API_V1_LAST_TRANSFER_IN_UNIT_LOAD_URL
} from 'infra/service/constants';

const getTripTransferStops = async () => {
  return api
    .get(`${URL_API_V1_LAST_MILE_PREFIX}/tripTransferStops`)
    .then(response => response.data.tripTransferStops);
};

export const getArrivalsAggregationMulti = async (
  type,
  destinationRoutingCode,
  originRoutingCode,
  vehicleLicensePlate
) => {
  const unitLoad = await api.get(
    `${URL_API_V1_LAST_TRANSFER_IN_UNIT_LOAD_URL}`,
    {
      params: {
        distribution_center_routing_code: destinationRoutingCode,
        expedition_center_routing_code: originRoutingCode,
        vehicle_license_plate: vehicleLicensePlate
      }
    }
  );

  return api
    .get(`${URL_API_V1_LAST_MILE_PREFIX}/aggregate`, {
      params: { type, unit_load: unitLoad.data.unitLoadLicensePlate }
    })
    .then(response => response.data);
};

export const getArrivalsAggregation = async (type, unitLoad) => {
  return api
    .get(`${URL_API_V1_LAST_MILE_PREFIX}/aggregate`, {
      params: { type, unit_load: unitLoad }
    })
    .then(response => response.data);
};

export default getTripTransferStops;
