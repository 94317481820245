import React from 'react';
import PropTypes from 'prop-types';

import theme, { colors } from '@loggi/mar';
import { Box, Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useSummaryStyles = makeStyles({
  root: {
    backgroundImage: colors.gradients.sanches
  }
});

/**
 * This is an extra padding used to free some space on the top of the page.
 * @type {string} - px css unit
 */
const HEADER_EXTRA_PADDING = theme.typography.pxToRem(76);

/**
 * The Summary is the component responsible of presenting the elements
 * of the upper region of the template
 * @param children
 * @returns {*}
 * @constructor
 */
const Summary = ({ children }) => {
  const { palette } = useTheme();
  const style = useSummaryStyles();
  return (
    <Box className={style.root} py={5.5} pt={HEADER_EXTRA_PADDING}>
      <Container component={Box} color={palette.common.white} py={5.5}>
        {children}
      </Container>
    </Box>
  );
};

Summary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Summary;
