import moment from 'moment';

const getDayOfWeek = day => {
  return moment()
    .day(day)
    .locale('pt-BR')
    .format('ddd')
    .toLowerCase();
};

function openingHoursTreatment(openingHours) {
  return openingHours.map((item, index) => {
    let days;
    const range = item.weekdays.length;
    if (range > 2) {
      days = `${getDayOfWeek(item.weekdays[0])} a ${getDayOfWeek(
        item.weekdays.slice(-1)[0]
      )},`;
    } else if (range === 2) {
      days = `${getDayOfWeek(item.weekdays[0])} e ${getDayOfWeek(
        item.weekdays[1]
      )},`;
    } else {
      days = `${getDayOfWeek(item.weekdays[0])},`;
    }
    const hours = `das ${item.open_at} às ${item.close_at}`;
    const mountWorkingHour = `${index === 0 ? 'De ' : ''}${days} ${hours}.`;

    return mountWorkingHour.charAt(0).toUpperCase() + mountWorkingHour.slice(1);
  });
}

export default openingHoursTreatment;
