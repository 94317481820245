import PropTypes from 'prop-types';

const model = {
  id: PropTypes.string.isRequired,
  expectedArrivalTime: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  origin: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
    .isRequired,
  destination: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  vehicleType: PropTypes.string,
  vehicleLicensePlate: PropTypes.string,
  tripType: PropTypes.string.isRequired,
  totalPackages: PropTypes.number.isRequired,
  totalBags: PropTypes.number.isRequired,
  unitLoadLicensePlate: PropTypes.string,
  drivers: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired })
  ),
  transportCompany: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  deltaArrivalTimeMinutes: PropTypes.number.isRequired
};

export default model;
