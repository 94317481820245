import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const ComplexAreasContext = React.createContext();

export const ComplexAreasProvider = ({ children, value }) => {
  // The current complex areas of base
  const [complexAreas, setComplexAreas] = useState(value?.complexAreas || []);

  // The current complex area
  const [currentComplexArea, setCurrentComplexArea] = useState(
    value?.currentComplexArea || null
  );

  const setValue = () => {
    setComplexAreas([]);
  };

  const [
    openCreateUpdateDrawerComplexAreas,
    setOpenCreateUpdateDrawerComplexAreas
  ] = useState(value?.openCreateUpdateDrawerComplexAreas || false);

  const [cancelAction, setCancelAction] = useState(false);

  const [createAreaEvent, setCreateAreaEvent] = useState(false);

  const [isError, setIsError] = useState(false);

  const [polygons] = useState([]);

  const [areaName, setAreaName] = useState('');

  const [category, setCategory] = useState(0);

  const contextValues = {
    complexAreas,
    setComplexAreas,
    openCreateUpdateDrawerComplexAreas,
    setOpenCreateUpdateDrawerComplexAreas,
    currentComplexArea,
    setCurrentComplexArea,
    cancelAction,
    setCancelAction,
    createAreaEvent,
    setCreateAreaEvent,
    isError,
    setIsError,
    polygons,
    setValue,
    areaName,
    setAreaName,
    category,
    setCategory
  };

  return (
    <ComplexAreasContext.Provider value={contextValues}>
      {children}
    </ComplexAreasContext.Provider>
  );
};

ComplexAreasProvider.defaultProps = {
  value: null
};

ComplexAreasProvider.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.shape({
    complexAreas: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        consensusPoints: PropTypes.arrayOf(
          PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number
          })
        ),
        category: PropTypes.number
      })
    ),
    openCreateUpdateDrawerComplexAreas: PropTypes.bool,
    setOpenCreateUpdateDrawerComplexAreas: PropTypes.func,
    currentComplexArea: PropTypes.shape({
      name: PropTypes.string,
      consensusPoints: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number
        })
      ),
      category: PropTypes.number
    }),
    setCurrentComplexArea: PropTypes.func
  })
};
