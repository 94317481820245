import api from 'infra/api/leve';
import { URL_PROXY_V1_PREFIX } from 'infra/service/constants';

function getPeriods() {
  return api.get('/api/v1/last_mile/package_charge/period').then(_data => {
    return _data.data;
  });
}

function aggregatedPackageCharge({ startDate, endDate }) {
  return api
    .post('/api/v1/last_mile/package_charge/aggregated', {
      startDate,
      endDate
    })
    .then(_data => {
      return { ..._data.data, startDate, endDate };
    });
}

function aggregatedPackageChargeThroughProxy({ startDate, endDate }) {
  return api
    .post(`${URL_PROXY_V1_PREFIX}/package_charge/values`, {
      startDate,
      endDate
    })
    .then(_data => {
      return { ..._data.data, startDate, endDate };
    });
}

export {
  getPeriods,
  aggregatedPackageCharge,
  aggregatedPackageChargeThroughProxy
};
