import api from 'infra/api/leve';
/**
 * @returns {Promise} Axios response object containing the following information
 *
{
  "pack": {
    "id": "152",
    "tracking_key": "634051f",
    "company_id": "1278",
    "slo": 1,
    "width": 10,
    "height": 15,
    "length": 20,
    "weight": 50,
    "created": "2020-02-12T00:54:55.792Z",
    "updated": "2020-02-12T00:54:55.809Z"
  },
  "company": {
    "id": "1278",
    "name": "Company 278",
    "cnpj": "61032897000120",
    "city": "Smithchester",
    "state": "SP",
    "pos": {
      "wkb": "AQEAAAAAAAAAAIBFwAAAAAAAADfA",
      "srid": 4326
    }
  },
  "recipient": {
    "id": "152",
    "name": "Samuel Phillips",
    "email": "psmith@hotmail.com",
    "phone": "55119123456140",
    "created": "2020-02-12T00:54:55.765Z",
    "updated": "2020-02-12T00:54:55.765Z"
  },
  "destination": {
    "id": "152",
    "address_st": "Caitlyn Underpass",
    "address_number": "176",
    "city": "Martinfurt",
    "state": "MT",
    "zip": "61703",
    "vicinity": "Tamara Salas",
    "created": "2020-02-12T00:54:55.343Z",
    "updated": "2020-02-12T00:54:55.343Z",
    "pos": {
      "wkb": "AQEAAAAAAAAAAAAAAAAAAAAAAAAA",
      "srid": 4326
    }
  },
  "integrationInfo": {
    "id": "148",
    "package_id": "152",
    "company_id": "1278",
    "serie_nf": "25805048",
    "num_nf": "304",
    "barcode": "20",
    "nfe_key": "88",
    "valor_total": "None",
    "all_data": "{}"
  },
  "unitLoad": {
    "id": "11",
    "license_plate": "loggi_tower_1:1000:1:delivery:invalid_destination",
    "name": "invalid_destination",
    "created": "2020-02-20T15:43:41.884Z",
    "updated": "2020-02-20T15:43:41.884Z"
  },
  "status": {
    "category": "not_available",
    "package_id": "152",
    "created": "2020-02-12T00:54:55.802Z",
    "updated": "2020-02-12T00:54:55.802Z"
  },
  "statusDisplay": "Não disponível",
  "custody": {
    "id": "1",
    "first_name": "thalisses",
    "last_name": "jenn",
    "full_name": "thalisses jenn",
    "email": "thalisses@loggi.com",
    "created": "2020-01-10T00:00:17.500Z",
    "updated": "2020-02-12T00:00:58.233Z",
    "mobile_1": "11912340000",
    "user_type": "PersonalCustomer"
  }
}
 */

export default (id, userEmail, taskId) => {
  return api.get(`proxy/last-mile/v1/leve/package/${id}/detail/`, {
    headers: { useremail: userEmail },
    params: {
      task_id: taskId
    }
  });
};
