import { makeStyles, fade } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(
  ({ spacing, palette, breakpoints, transitions, typography }) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      left: 0,
      right: 0,
      position: 'absolute',
      zIndex: 1000,
      color: colors.root[0],
      marginLeft: spacing(5),
      marginRight: spacing(5)
    },
    maxWidth: {
      maxWidth: '1280px',
      paddingLeft: '2.75rem',
      paddingRight: '2.75rem',
      paddingTop: spacing(3.5)
    },
    colorWhite: {
      color: colors.root[0]
    },
    hover: {
      transition: '0.5s',
      '&:hover': {
        backgroundColor: colors.root[0],
        color: colors.blue[500]
      }
    },
    logo: {
      cursor: 'pointer',
      width: spacing(3),
      height: spacing(3),
      minWidth: spacing(3),
      minHeight: spacing(3)
    },
    person: {
      maxWidth: spacing(3.25),
      maxHeight: spacing(3.25)
    },
    divDropDown: {
      '& div': {
        backgroundColor: colors.root[0],
        marginTop: spacing(1),
        borderRadius: spacing(2.875)
      }
    },
    ulDropDownLink: {
      '& ul': {
        color: colors.blue[500],
        paddingTop: spacing(0),
        paddingBottom: spacing(0)
      }
    },
    hoverItemMenu: {
      '&:hover': {
        backgroundColor: colors.blue[50]
      }
    },
    notHoverItemMenu: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    styleButton: {
      backgroundColor: colors.root[0],
      color: colors.blue[500]
    },
    liDropDownLink: {
      '& li': {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        paddingTop: spacing(1.5),
        paddingBottom: spacing(1.5),
        '&:first-child': {
          borderTopLeftRadius: spacing(2.875),
          borderTopRightRadius: spacing(2.875)
        },
        '&:last-child': {
          borderBottomLeftRadius: spacing(2.875),
          borderBottomRightRadius: spacing(2.875)
        }
      }
    },
    liLogout: {
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.blue[500],
      padding: spacing(0)
    },
    ulDropDownProfile: {
      '& ul': {
        padding: spacing(0),
        width: spacing(39.5)
      }
    },
    noWrap: {
      whiteSpace: 'nowrap'
    },
    boxShadow: {
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.08)'
    },
    search: {
      marginRight: spacing(2),
      display: 'flex',
      height: '48px',
      position: 'relative',
      borderRadius: spacing(2.875),
      border: `1px solid ${palette.common.white}`,
      backgroundColor: fade(palette.common.white, 0.0),
      '&:hover': {
        '& ::placeholder': {
          color: colors.blue[500]
        }
      },
      marginLeft: 0,
      width: '100%',
      [breakpoints.up('sm')]: {
        marginLeft: spacing(1),
        width: 'auto'
      }
    },
    searchActive: {
      backgroundColor: palette.common.white,
      '&:hover': {
        backgroundColor: palette.common.white
      },
      '& .MuiSvgIcon-root': {
        color: colors.blue[500]
      },
      '& .MuiInputBase-root': {
        color: palette.text.primary,
        '& ::placeholder': {
          color: palette.text.secondary,
          opacity: 1
        }
      }
    },
    searchIcon: {
      padding: spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    searchFontWeight: {
      '& ::placeholder': {
        fontWeight: 600
      }
    },
    inputRoot: {
      color: 'inherit'
    },
    inputInput: {
      padding: spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${spacing(4)}px)`,
      transition: transitions.create('width'),
      width: '100%',
      lineHeight: '1.5',
      [breakpoints.up('sm')]: {
        width: typography.pxToRem(60),
        height: typography.pxToRem(22),
        '&:focus': {
          width: typography.pxToRem(250)
        },
        '&::placeholder': {
          color: palette.common.white,
          opacity: 1,
          fontSize: typography.pxToRem(15)
        }
      }
    },
    roundButton: {
      marginRight: spacing(2),
      height: '48px',
      width: '48px',
      border: `1px solid ${palette.common.white}`,
      backgroundColor: fade(palette.common.white, 0.0),
      '&:hover': {
        backgroundColor: fade(palette.common.white, 0.5)
      },
      color: palette.common.white
    }
  })
);

export default useStyles;
