export const TEXT = {
  RETURNING: 'Transferir pacotes',
  RETURNED: 'Pacote(s) para transferir à Loggi',
  DESCRIPTION: 'Bipe os pacotes que vão ser transferidos para a Loggi.',
  NO_PACKAGES: 'Sem pacotes para transferir'
};

export const PROCESS = 'return';
export const DESTINATION = 'loggi';

export const UNIT_LOAD_INFOS = [
  {
    process: PROCESS,
    destination: DESTINATION
  },
  {
    process: 'receiving',
    destination: 'rejected'
  }
];

export const MESSAGE_STATE = 'Pronto! Pacote adicionado à lista.';
