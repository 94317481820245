import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@material-ui/core';

export default function DrawerContent({ children, showDivider, lessPadding }) {
  return (
    <>
      <Box px={lessPadding ? 0 : 3.5}>
        <Box py={2.5}>{children}</Box>
      </Box>
      {showDivider && <Divider data-testid="drawer-content-divider" />}
    </>
  );
}

DrawerContent.propTypes = {
  children: PropTypes.node.isRequired,
  showDivider: PropTypes.bool,
  lessPadding: PropTypes.bool
};

DrawerContent.defaultProps = {
  showDivider: false,
  lessPadding: false
};
