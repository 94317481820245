import React, { useState } from 'react';
import {
  FormControlLabel,
  RadioGroup,
  ListItemText,
  ListItem,
  Avatar,
  Radio,
  ListItemIcon,
  FormControl,
  List
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import { ReactComponent as BlueCheckIcon } from 'images/ic-check-blue.svg';
import useStyles from 'view/molecules/package-drawer/drawer.style';

export const DisputeDriversList = ({ users, handleSelectDriver }) => {
  const classes = useStyles();
  const [currentDriver, setCurrentDriver] = useState(null);

  const handleChange = event => {
    setCurrentDriver(event.target.value);
    handleSelectDriver(event.target.value);
  };

  const parseDriverFullName = driverName => {
    return driverName.length > 30
      ? `${driverName.substring(0, 30)}...`
      : `${driverName}`;
  };

  return (
    <List
      className={classes.list}
      data-testid="dispute-driver-list-form"
      style={{ maxHeight: '250px', overflow: 'scroll' }}
    >
      <FormControl component="fieldset" fullWidth>
        <RadioGroup
          aria-label="dispute-driver-responsible"
          name="dispute-driver-responsible"
          onChange={handleChange}
          value={`${currentDriver}`}
        >
          {users.map(driver => {
            const isCurrentDriver = currentDriver === driver.deliverer.id;
            return (
              <ListItem
                key={`list-item-${driver.deliverer.id}`}
                button
                onClick={() => {
                  setCurrentDriver(driver.deliverer.id);
                  handleSelectDriver(driver.deliverer.id);
                }}
                style={{
                  borderLeft: `4px ${
                    isCurrentDriver ? colors.blue[500] : colors.root[0]
                  } solid`,
                  backgroundColor: isCurrentDriver
                    ? colors.blue[50]
                    : colors.root[0]
                }}
              >
                <ListItemIcon>
                  <FormControlLabel
                    value={`${driver.deliverer.id}`}
                    control={
                      <Radio
                        inputProps={{
                          'data-testid': `radio-button-${driver.deliverer.id}`
                        }}
                        checkedIcon={<BlueCheckIcon />}
                      />
                    }
                  />
                </ListItemIcon>
                <ListItemIcon key={`list-avatar-${driver.deliverer.id}`}>
                  <Avatar
                    alt={`Driver n°${driver.deliverer.id}`}
                    src={driver.deliverer.profilePictureThumbnailUrl}
                  />
                </ListItemIcon>
                <ListItemText
                  id={`label-${driver.deliverer.id}`}
                  primary={parseDriverFullName(driver.fullName)}
                />
              </ListItem>
            );
          })}
        </RadioGroup>
      </FormControl>
    </List>
  );
};

DisputeDriversList.propTypes = {
  users: PropTypes.shape().isRequired,
  handleSelectDriver: PropTypes.func.isRequired
};

export default DisputeDriversList;
