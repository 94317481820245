import { Machine, assign, sendParent } from 'xstate';
import { TEXT } from 'view/molecules/package-drawer/drawer.constants';
import getPositionService from 'app/geolocation';
import updateStatusPackageService from './drawer-update-status.service';

export const STATES = {
  SELECTING: 'selecting',
  SUBMITTING: 'submittingUpdateStatus'
};

export const ACTIONS = {
  SELECT_OPTION: 'selectOption',
  BACK: 'backToDetail',
  CHANGE_NAME: 'changeName',
  CHANGE_DOCUMENT: 'changeDocument',
  SUBMIT: 'submitUpdateStatus',
  RELOAD_PAGE: 'reloadPage',
  SET_NOTIFICATION: 'setNotification'
};

const updateStatusMachine = Machine({
  id: 'updateStatus',
  initial: 'selecting',
  context: {
    selectedItem: null,
    packageId: null,
    newPackageStatus: null,
    recipientName: '',
    recipientDocument: '',
    isDelivered: false
  },
  states: {
    [STATES.SELECTING]: {
      on: {
        [ACTIONS.SELECT_OPTION]: {
          actions: assign({
            selectedItem: (_, event) => event.value.text,
            isDelivered: (_, event) => event.value.isDelivered || false,
            newPackageStatus: (_, event) => event.value.code
          })
        },
        [ACTIONS.CHANGE_NAME]: {
          actions: assign({ recipientName: (_, event) => event.value })
        },
        [ACTIONS.CHANGE_DOCUMENT]: {
          actions: assign({ recipientDocument: (_, event) => event.value })
        },
        [ACTIONS.SUBMIT]: STATES.SUBMITTING,
        [ACTIONS.BACK]: {
          actions: sendParent(ACTIONS.BACK, { to: 'drawer' })
        }
      }
    },
    [STATES.SUBMITTING]: {
      invoke: {
        id: 'updateStatusPackage',
        src: context => {
          return getPositionService().then(({ latitude, longitude }) =>
            updateStatusPackageService({
              packageId: context.packageId,
              newPackageStatus: context.newPackageStatus,
              longitude,
              latitude,
              recipientName: context.recipientName,
              recipientDocument: context.recipientDocument
            })
          );
        },
        onDone: {
          actions: [
            sendParent({
              type: ACTIONS.SET_NOTIFICATION,
              data: {
                message: TEXT.STATUS_UPDATED,
                error: false
              },
              to: 'drawer'
            }),
            sendParent(ACTIONS.RELOAD_PAGE, { to: 'drawer' })
          ]
        },
        onError: {
          target: STATES.SELECTING,
          actions: [
            sendParent((_, event) => ({
              type: ACTIONS.SET_NOTIFICATION,
              data: {
                message: event.data.message,
                error: true
              },
              to: 'drawer'
            }))
          ]
        }
      }
    }
  }
});

export default updateStatusMachine;
