import getPackageService from 'infra/service/get-package';

import { parseRequestError } from '../httpMessage';

export const CUSTOM_ERROR_MESSAGES = {
  404: 'Pacote não encontrado',
  500: 'Falha ao consultar os pacotes, tente novamente mais tarde'
};

const getPackage = (id, userEmail, taskId = 0) =>
  getPackageService(id, userEmail, taskId)
    .then(response => response.data)
    .catch(parseRequestError(CUSTOM_ERROR_MESSAGES));

export default getPackage;
