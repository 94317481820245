const TEXT_PACKAGES = {
  TITLE: 'Pacotes',
  LABEL: 'Na rua',
  LABEL_NO_TRY: 'Sem tentativa',
  LABEL_ADVANCED: 'Adiantados',
  LABEL_DELAYED: 'Atrasados',
  LABEL_FAILURE: 'Insucessos',
  LABEL_TO_DAY: 'Para hoje'
};

const TEXT_DELIVERES = {
  TITLE: 'Entregadores',
  LABEL: 'Na rua',
  LABEL_NO_TRY: 'Sem tentativa',
  LABEL_ADVANCED: 'Adiantados',
  LABEL_DELAYED: 'Com pacotes atrasados',
  LABEL_FAILURE: 'Com pacotes de insucessos',
  LABEL_TO_DAY: 'Com pacotes para hoje'
};

export const PATH_AGGREGATION_PACKAGES = {
  ROOT: 'pacotes',
  DELAYED: 'pacotes/atrasados',
  FAILURE: 'pacotes/insucessos',
  TO_DAY: 'pacotes/para-hoje'
};

export const PATH_AGGREGATION_DELIVERERS = {
  ROOT: 'entregadores',
  DELAYED: 'entregadores/atrasados',
  FAILURE: 'entregadores/insucessos',
  TO_DAY: 'entregadores/para-hoje'
};

export const PATH_AGGREGATION_DELIVERER = 'acompanhamento/na-rua/em-grupos';

export default TEXT_PACKAGES;

export const dataLabelsPackages = {
  title: TEXT_PACKAGES.TITLE,
  label: TEXT_PACKAGES.LABEL,
  no_try: TEXT_PACKAGES.LABEL_NO_TRY,
  advanced: TEXT_PACKAGES.LABEL_ADVANCED,
  failure: TEXT_PACKAGES.LABEL_FAILURE,
  to_day: TEXT_PACKAGES.LABEL_TO_DAY,
  delayed: TEXT_PACKAGES.LABEL_DELAYED
};

export const dataLabelsDeliveres = {
  title: TEXT_DELIVERES.TITLE,
  label: TEXT_DELIVERES.LABEL,
  no_try: TEXT_DELIVERES.LABEL_NO_TRY,
  advanced: TEXT_DELIVERES.LABEL_ADVANCED,
  failure: TEXT_DELIVERES.LABEL_FAILURE,
  to_day: TEXT_DELIVERES.LABEL_TO_DAY,
  delayed: TEXT_DELIVERES.LABEL_DELAYED
};
