import React from 'react';
import PropTypes from 'prop-types';

import DrawerInfoComponet from './drawer-info.component';

export default function DrawerInfoContainer(props) {
  const { valueNF } = props;

  return <DrawerInfoComponet valueNF={valueNF} />;
}

DrawerInfoContainer.propTypes = {
  valueNF: PropTypes.shape({
    periodTotalValue: PropTypes.number.isRequired,
    deliveryTotalValue: PropTypes.number.isRequired,
    pickupTotalValue: PropTypes.number.isRequired,
    transhipmentTotalValue: PropTypes.number.isRequired,
    dropoffTotalValue: PropTypes.number.isRequired
  }).isRequired
};
