import api from 'infra/api/leve';
import {
  GET_DEFINED_REGIONS,
  UPDATE_DEFINED_REGIONS,
  GET_REGIONS_NAMES
} from 'infra/service/constants';
import { getSelectedBase } from 'profile/profile.service';

export const getComplexAreas = async routingCode => {
  return api
    .get(`${GET_DEFINED_REGIONS}/${routingCode}`)
    .then(response => response);
};

export const getRegionNames = async () => {
  // eslint-disable-next-line camelcase
  const routingCode = getSelectedBase()?.distribution_center?.routing_code;

  return api
    .get(`${GET_REGIONS_NAMES}?routing-code=${routingCode}`)
    .then(response => response.data.demandRegions || []);
};

export const updateComplexArea = async (complexArea, routingCode) => {
  return api
    .post(`${UPDATE_DEFINED_REGIONS}`, {
      defined_regions: complexArea,
      routing_code: routingCode
    })
    .then(response => response);
};
