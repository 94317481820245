import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import styleDefault from 'view/molecules/package-drawer/drawer-update-address/google-map/style-map';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecord';
import getDistributionCenterInfo from 'information/routines-management/routines/routines.service';
import { DemandRegionsContext } from './demand-regions.provider';
import {
  colorsMap,
  filterDecisionsByDemandRegions
} from './demand-regions.utils';

const DemandRegionsMapComponent = () => {
  const GOOGLE_MAPS_API_KEY = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  const [defaultZoom] = useState(15);

  const [baseCoordinates, setBaseCoordinates] = useState({});

  const [currentSortingContext, setCurrentSortingContext] = useState([]);

  const {
    sortingContext,
    simulationSortingContext,
    openSimulationDrawerDemandRegions
  } = useContext(DemandRegionsContext);

  const [decisions, setDecisions] = useState({});

  const onLoadGoogleMaps = () => {
    setDecisions(filterDecisionsByDemandRegions(currentSortingContext));
  };

  useEffect(() => {
    if (openSimulationDrawerDemandRegions) {
      setCurrentSortingContext(simulationSortingContext);
    } else {
      setCurrentSortingContext(sortingContext);
    }
    getDistributionCenterInfo().then(info => {
      setBaseCoordinates({
        lat: info?.dcInfo?.address?.coordinates?.latitude || 0,
        lng: info?.dcInfo?.address?.coordinates?.longitude || 0
      });
    });

    setDecisions(filterDecisionsByDemandRegions(currentSortingContext));
  }, [
    setDecisions,
    setCurrentSortingContext,
    simulationSortingContext,
    currentSortingContext,
    openSimulationDrawerDemandRegions,
    sortingContext
  ]);

  return (
    <>
      {baseCoordinates && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          center={baseCoordinates}
          defaultZoom={defaultZoom}
          yesIWantToUseGoogleMapApiInternals
          options={{
            styles: styleDefault
          }}
          onGoogleApiLoaded={() => onLoadGoogleMaps()}
        >
          {decisions &&
            Object.entries(decisions).map((decision, index) => {
              const label = decision[0];
              const consensusPoints = decision[1];
              return consensusPoints.map(point => {
                return (
                  <Marker
                    key={label}
                    data-testid={`label: ${label}`}
                    label={label}
                    lat={point.lat}
                    lng={point.lng}
                    color={colorsMap[index % colorsMap.length]}
                  />
                );
              });
            })}
        </GoogleMapReact>
      )}
    </>
  );
};

const Marker = ({ color, label }) => {
  return (
    <Tooltip title={label} placement="left" arrow>
      <FiberManualRecordRoundedIcon style={{ color }} fontSize="small" />
    </Tooltip>
  );
};

Marker.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

DemandRegionsMapComponent.propTypes = {};

export default DemandRegionsMapComponent;
