import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import { formatNumber } from 'shared/formatters';
import {
  PATH_AGGREGATION_DELIVERERS,
  PATH_AGGREGATION_PACKAGES
} from './constants';
import useStyles from './card-aggregation.styles';

export default function CardAggregationComponent({
  dataResponse,
  configPackages,
  handleNextPage,
  dataLabels
}) {
  const usePath = configPackages
    ? PATH_AGGREGATION_PACKAGES
    : PATH_AGGREGATION_DELIVERERS;

  const classes = useStyles();

  const countCardsZero = () => {
    const array = [
      dataResponse?.totalForTodayPackages,
      dataResponse?.totalDelayedPackages,
      dataResponse?.totalUnsuccessfulPackages
    ];

    return array.filter(item => item > 0).length;
  };

  const getSizeMD = () => {
    const MapSize = {
      1: 2,
      2: 5,
      3: 7
    };
    return MapSize[countCardsZero()];
  };

  const getSizeSM = () => {
    const MapSize = {
      1: 12,
      2: 6,
      3: 4
    };
    return MapSize[countCardsZero()];
  };

  const maxWidthItemRight = '212px';

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box mt={3}>
          <Typography variant="h6">
            <em>{dataLabels.title}</em>
          </Typography>
        </Box>
      </Grid>
      {/* Group Left */}
      <Grid item xs={12} sm={12} md={configPackages ? 5 : 3}>
        <Box
          display="flex"
          flexDirection="row"
          border={1}
          borderColor={colors.smoke[100]}
          borderRadius={8}
        >
          <Grid
            container
            className={classes.hoverBox}
            onClick={() => handleNextPage(usePath.ROOT)}
          >
            <Grid item xs={configPackages ? 6 : 12}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                p={3}
              >
                <Box mb={10}>
                  <Typography variant="h2">
                    <em>{formatNumber(dataResponse.totalInStreetPackages)}</em>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">
                    {dataLabels.label}
                  </Typography>
                  <ChevronRightIcon color="primary" />
                </Box>
              </Box>
            </Grid>
            {configPackages && (
              <Grid item xs={6}>
                <Box
                  borderLeft={1}
                  borderColor={colors.smoke[100]}
                  flexGrow={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box
                    display="flex"
                    flexGrow={1}
                    p={3}
                    borderBottom={1}
                    style={{ borderColor: colors.smoke[100] }}
                    flexDirection="column"
                  >
                    <Box>
                      <Typography variant="h5">
                        <em>
                          {formatNumber(dataResponse.totalWithoutTriesPackages)}
                        </em>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {dataLabels.no_try}
                      </Typography>
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="column" flexGrow={1} p={3}>
                    <Box>
                      <Typography variant="h5">
                        <em>
                          {formatNumber(
                            dataResponse.totalAheadOfSchedulePackages
                          )}
                        </em>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {dataLabels.advanced}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>

      {/* Group Right - Atrasados | Insucessos | Para hoje */}
      <Grid item xs={12} sm={12} md={getSizeMD()}>
        <Box
          display="flex"
          flexGrow={1}
          border={1}
          borderColor={colors.smoke[100]}
          borderRadius={8}
          justifyContent="space-between"
          style={{
            minWidth: maxWidthItemRight
          }}
        >
          <Grid container>
            {dataResponse.totalDelayedPackages > 0 && (
              <Grid
                item
                xs={12}
                sm={getSizeSM()}
                onClick={() => handleNextPage(usePath.DELAYED)}
              >
                <Box className={classes.hoverBox} p={3} flexGrow={1}>
                  <Box mb={configPackages ? 10 : 7}>
                    <Typography variant="h2">
                      <em>{formatNumber(dataResponse.totalDelayedPackages)}</em>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Box maxWidth="130px">
                      <Typography variant="subtitle2">
                        {dataLabels.delayed}
                      </Typography>
                    </Box>
                    <ChevronRightIcon color="primary" />
                  </Box>
                </Box>
              </Grid>
            )}
            {dataResponse.totalUnsuccessfulPackages > 0 && (
              <Grid
                item
                xs={12}
                sm={getSizeSM()}
                onClick={() => handleNextPage(usePath.FAILURE)}
              >
                <Box
                  className={classes.hoverBox}
                  p={3}
                  borderLeft={1}
                  borderRight={1}
                  style={{ borderColor: colors.smoke[100] }}
                >
                  <Box mb={configPackages ? 10 : 7}>
                    <Typography variant="h2">
                      <em>
                        {formatNumber(dataResponse.totalUnsuccessfulPackages)}
                      </em>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Box maxWidth="110px">
                      <Typography variant="subtitle2">
                        {dataLabels.failure}
                      </Typography>
                    </Box>
                    <ChevronRightIcon color="primary" />
                  </Box>
                </Box>
              </Grid>
            )}
            {dataResponse?.totalForTodayPackages > 0 && (
              <Grid
                item
                xs={12}
                sm={getSizeSM()}
                onClick={() => handleNextPage(usePath.TO_DAY)}
              >
                <Box className={classes.hoverBox} p={3}>
                  <Box mb={configPackages ? 10 : 7}>
                    <Typography variant="h2">
                      <em>
                        {formatNumber(dataResponse.totalForTodayPackages)}
                      </em>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Box maxWidth="130px">
                      <Typography variant="subtitle2">
                        {dataLabels.to_day}
                      </Typography>
                    </Box>
                    <ChevronRightIcon color="primary" />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

CardAggregationComponent.propTypes = {
  dataResponse: PropTypes.shape({
    title: PropTypes.string,
    totalInStreetPackages: PropTypes.number,
    totalWithoutTriesPackages: PropTypes.number,
    totalAheadOfSchedulePackages: PropTypes.number,
    totalDelayedPackages: PropTypes.number,
    totalUnsuccessfulPackages: PropTypes.number,
    totalForTodayPackages: PropTypes.number
  }).isRequired,
  handleNextPage: PropTypes.func.isRequired,
  configPackages: PropTypes.bool,
  dataLabels: PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    no_try: PropTypes.string,
    advanced: PropTypes.string,
    failure: PropTypes.string,
    to_day: PropTypes.string,
    delayed: PropTypes.string
  }).isRequired
};

CardAggregationComponent.defaultProps = {
  configPackages: true
};
