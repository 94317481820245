import React, { useState } from 'react';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, Grid, Link, Button } from '@material-ui/core';
import { ReactComponent as Logo } from 'images/logo.svg';

import SignupComponent from './signup.component';
import signupService from './signup.service';
import { TEXT, LINK_URL_ARCO, BUTTON } from './signup.constants';
import { useStyles } from './signup.styles';

function SignupContainer() {
  const classes = useStyles();

  const { token } = useParams();
  const [isSubmited, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const onSignup = formData => {
    setLoading(true);
    signupService({
      ...formData,
      token
    })
      .then(() => {
        setLoading(false);
        setIsSubmitted(true);
        setEmail(formData.email);
      })
      .catch(error => {
        setLoading(false);
        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      });
  };

  return (
    <>
      {!isSubmited && <SignupComponent onSignup={onSignup} loading={loading} />}
      {!!isSubmited && (
        <Grid container className={classes.grid} data-testid="sucess_signup">
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.root}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.alignLogo}
            >
              <Link
                href={LINK_URL_ARCO}
                target="_blank"
                color="inherit"
                style={{ width: '430px', textAlign: 'left' }}
              >
                <Logo style={{ width: '100px', height: '100px' }} />
              </Link>
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.grid}
            >
              <Box style={{ maxWidth: '430px' }}>
                <Box marginBottom={3}>
                  <Typography variant="h2" component="span">
                    {TEXT.WELCOME.TITLE}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1" component="span">
                    {TEXT.WELCOME.CONTAINER}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.grid}
            >
              <Grid item style={{ maxWidth: '430px' }}>
                <Box marginBottom={3.5}>
                  <Typography variant="h4" component="h1">
                    {TEXT.VERIFY_EMAIL}
                  </Typography>
                </Box>
                <Box marginBottom={3.5}>
                  <Typography component="h1">
                    {TEXT.INFO_START}
                    <strong>{` ${email} `}</strong>
                    {TEXT.INFO_END}
                  </Typography>
                </Box>

                <Box mt={3.5} textAlign="center">
                  <Button
                    className={classes.button}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => history.push('/')}
                    data-testid="go-login-signup"
                  >
                    {BUTTON.GO_LOGIN}
                  </Button>
                </Box>

                <Box marginTop={5}>
                  <Typography
                    variant="body2"
                    component="span"
                    align="center"
                    className={classes.span}
                  >
                    {TEXT.NO_RECEIVED_EMAIL}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    align="center"
                    className={classes.link}
                    onClick={() => {
                      window.location.reload();
                    }}
                    data-testid="try-again-signup"
                  >
                    {TEXT.TRY_AGAIN}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default SignupContainer;
