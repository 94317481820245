import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle
} from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { ReactComponent as EditionModeIcon } from 'images/ic-modo-edicao.svg';
import { TOOLTIP, SNACKBAR_NOTIFICATION } from './complex-areas.constants';
import { enqueueNotification } from './drawer-create-update-complex-area.component';

let listener = null;

const getCurrentPolygon = polygons => {
  return polygons.find(polygon => polygon.selected);
};

const fixPolygonVertices = polygon => {
  const vertices = polygon
    .getPath()
    .getArray()
    .map(coordinates => {
      return {
        lat: coordinates.lat(),
        lng: coordinates.lng()
      };
    });
  vertices.push(vertices[0]);
  polygon.setPath(vertices);
};

export const addPolygon = (
  polygon,
  polygons,
  enqueueSnackbar,
  drawingManager
) => {
  fixPolygonVertices(polygon);

  if (polygon.getPath().getArray().length < 4) {
    const message = SNACKBAR_NOTIFICATION.INVALID_POLYGON_ERROR;
    enqueueNotification({
      message,
      variant: 'error',
      withButton: false,
      enqueueSnackbar
    });
    // clear invalid polygon
    polygon.setMap(null);
    return;
  }

  polygons.push({
    name: '',
    category: 0,
    selected: true,
    polygonProps: polygon
  });
  polygon.setEditable(true);
  drawingManager.setMap(null);
};

const EditionModeControl = forwardRef((props, ref) => {
  const { maps, map, polygons, drawingManager, enqueueSnackbar } = props;
  const [disable, setDisable] = useState(true);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [showEditionModeTooltipText, setShowEditionModeTooltipText] = useState(
    false
  );
  const buttonRef = useRef();

  const getEditionIcon = () => {
    const [childElement] = buttonRef.current.children;
    const [editionIcon] = childElement.children;
    return editionIcon;
  };

  useImperativeHandle(ref, () => ({
    openTooltip: () => setOpenTooltip(true),
    closeTooltip: () => setOpenTooltip(false),
    showEditionModeTooltipText: () => setShowEditionModeTooltipText(true),
    hideEditionModeTooltipText: () => setShowEditionModeTooltipText(false),
    handleShowButton: () => {
      buttonRef.current.style.visibility = 'visible';
    },
    handleHideButton: () => {
      buttonRef.current.style.visibility = 'hidden';
      buttonRef.current.style.backgroundColor = colors.root['0'];
      const icon = getEditionIcon();
      icon.style.color = colors.smoke['900'];
    },
    enableButton: () => setDisable(false)
  }));

  const handleMouseEnter = () => {
    setOpenTooltip(false);
  };

  const handleClick = () => {
    setDisable(!disable);
  };

  useEffect(() => {
    const currentPolygon = getCurrentPolygon(polygons);
    if (disable) {
      if (currentPolygon) {
        currentPolygon.polygonProps.setEditable(false);
      }
      maps.event.removeListener(listener || null);
      drawingManager.setMap(null);
    } else if (currentPolygon) {
      drawingManager.setMap(null);
      currentPolygon.polygonProps.setEditable(true);
    } else {
      drawingManager.setMap(map);
      listener = maps.event.addListener(
        drawingManager,
        'polygoncomplete',
        polygon => {
          addPolygon(polygon, polygons, enqueueSnackbar, drawingManager);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disable]);

  return (
    <Tooltip
      title={
        showEditionModeTooltipText
          ? TOOLTIP.EDITION_MODE_MESSAGE
          : TOOLTIP.CREATION_MODE_MESSAGE
      }
      placement="left"
      open={openTooltip}
      disableHoverListener
      disableTouchListener
      arrow
    >
      <button
        ref={buttonRef}
        onMouseEnter={handleMouseEnter}
        className="MuiButtonBase-root MuiButton-text"
        tabIndex="0"
        type="button"
        data-testid="enable-edition-mode"
        style={{
          backgroundColor: `${
            disable ? colors.root['0'] : colors.smoke['900']
          }`,
          borderRadius: '8px',
          width: '48px',
          height: '48px',
          visibility: 'hidden'
        }}
        onClick={handleClick}
      >
        <span className="MuiButton-label">
          <EditionModeIcon
            className="MuiSvgIcon-root"
            style={{
              color: `${disable ? colors.smoke['900'] : colors.root['0']}`
            }}
          />
        </span>
        <span className="MuiTouchRipple-root" />
      </button>
    </Tooltip>
  );
});

EditionModeControl.propTypes = {
  maps: PropTypes.shape({
    event: PropTypes.shape({
      addListener: PropTypes.func,
      removeListener: PropTypes.func
    })
  }).isRequired,
  map: PropTypes.shape({}).isRequired,
  drawingManager: PropTypes.shape({
    setMap: PropTypes.func
  }).isRequired,
  polygons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default EditionModeControl;
