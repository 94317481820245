import api from 'infra/api/leve';
import { URL_PROXY_V1_PREFIX } from 'infra/service/constants';

import { KEY_PERIOD } from '../finance.constants';

const LIMIT_DATE = new Date('2020-11-15T23:59:59-03:00');

function goDirectlyToLoggiweb(filters, selectedPeriod, period) {
  if (filters.deliverers && filters.deliverers.length > 0) {
    // always go to elastic when a filter is used
    return false;
  }

  if (period === KEY_PERIOD.OPEN) {
    return false;
  }
  if (period === KEY_PERIOD.CLOSE) {
    return true;
  }
  if (period === KEY_PERIOD.PREVIOUS) {
    // assume intervals before 'November 16th' are correct,
    // given past closing invoices at the moment
    const dateStart = new Date(selectedPeriod.start);
    return dateStart > LIMIT_DATE;
  }
  return false;
}

export default async (filters, selectedPeriod, period) => {
  const delivererFilters = filters.deliverers;
  const chargeTypeFilters = filters.chargeType;

  const startDate = selectedPeriod.start;
  const endDate = selectedPeriod.end;

  let url;
  if (goDirectlyToLoggiweb(filters, selectedPeriod, period)) {
    url = `${URL_PROXY_V1_PREFIX}/package_charge/values`;
  } else {
    url = '/api/v1/last_mile/package_charge/aggregated';
  }

  return api
    .post(url, {
      startDate,
      endDate,
      delivererFilters,
      chargeTypeFilters
    })
    .then(async _data => {
      return { ..._data.data, startDate, endDate };
    });
};
