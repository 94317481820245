import { ReactComponent as OnBoardingSvgPermissaoDistribuir } from 'images/onboarding-permissao-distribuir.svg';
import { ReactComponent as OnBoardingSvgPermissaoMaoCheck } from 'images/onboarding-permissao-mao-check.svg';
import { ReactComponent as OnBoardingSvgPermissaoComputador } from 'images/computador.svg';
import { ReactComponent as OnBoardingSvgPermissaoAgencia2 } from 'images/agencia2.svg';

export const BACK = 'Voltar';
export const SKIP = 'Pular';
export const CONTINUE = 'Continuar';
export const LETS_GO = 'Vamos lá!';

export const ONBOARDING_PERMISSIONAMENTO = {
  FIRST_SCREEN: {
    title: 'Este é o sistema de gestão de pacotes da Loggi',
    subtitle: 'Novidade',
    description:
      'O lugar para acompanhar os pacotes que estão sob responsabilidade do seu círculo.',
    component: OnBoardingSvgPermissaoComputador
  },
  PAGES: [
    {
      title: 'Consulte os pacotes direcionados para o círculo',
      subtitle: '',
      description:
        'Em Na base, você consulta os pacotes direcionados que já estão com o círculo ou que vão chegar.',
      component: OnBoardingSvgPermissaoDistribuir
    },
    {
      title: 'Distribua pacotes para seus entregadores',
      subtitle: '',
      description:
        'Use a opção Distribuir para registrar os pacotes distribuídos aos entregadores.',
      component: OnBoardingSvgPermissaoMaoCheck
    },
    {
      title: 'Verifique quais pacotes já estão em rota',
      subtitle: '',
      description:
        'Em Na rua, você consulta os pacotes que já foram distribuídos e estão a caminho da entrega.',
      component: OnBoardingSvgPermissaoAgencia2,
      labelLastBtnConfirm: 'Explorar sistema'
    }
  ],
  KEY_LOCAL_STORAGE: 'ONBOARDING_PERMISSIONAMENTO'
};
