import React from 'react';
import { useRouteMatch, Redirect, Switch, Route } from 'react-router-dom';
import { CanFunction } from 'auth/access-control';
import { AUTHORIZED_ROUTES } from 'view/constants';
import BaseCollection from './base-collection';
import BaseListContainer from './base-list';
import {
  AUTHORIZED_ROUTES_BASE,
  HIDDEN_AUTHORIZED_ROUTES_BASE
} from './base.constants';
import BaseContainerProvider from './base.provider';

const BaseListContainerWithForcedGroup = () => (
  <BaseListContainer shouldForceGroup />
);

export default function BaseContainer() {
  const { path, url } = useRouteMatch();

  return (
    <BaseContainerProvider>
      <Switch>
        <Route
          path={`${path}${AUTHORIZED_ROUTES.INFORMATION.COLLECTION}`}
          component={BaseCollection}
        />
        <Route
          path={`/:routingCode/:custody${
            AUTHORIZED_ROUTES_BASE.PARAMS_VALIDATION_IN_BASE_LIST_SUB_VIEW
          }`}
          component={BaseListContainer}
        />
        <Route
          path={`/:routingCode/:custody${
            HIDDEN_AUTHORIZED_ROUTES_BASE.PARAMS_VALIDATION_IN_BASE_LIST
          }`}
          component={BaseListContainer}
        />
        <Route
          path={`/:routingCode/:custody${
            AUTHORIZED_ROUTES_BASE.PARAMS_VALIDATION_IN_BASE_GROUP
          }`}
          component={BaseListContainerWithForcedGroup}
        />
        <Route
          path={`/:routingCode/:custody${
            AUTHORIZED_ROUTES_BASE.PARAMS_VALIDATION_IN_BASE_LIST
          }`}
          component={BaseListContainer}
        />

        {/* when loggiPonto, redirect direct to /:routingCode/na-base/pacotes */}
        {CanFunction({ customDisabledTo: 'loggiPonto' }) ? (
          <Redirect
            to={{
              pathname: `${url}${AUTHORIZED_ROUTES.INFORMATION.COLLECTION}`
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: `${url}${AUTHORIZED_ROUTES.INFORMATION.PACKAGES}`
            }}
          />
        )}
      </Switch>
    </BaseContainerProvider>
  );
}
