const styleDefault = [
  {
    featureType: 'all',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eff5f7'
      }
    ]
  },
  {
    featureType: 'all',
    elementType: 'all',
    stylers: [
      {
        saturation: '-28'
      },
      {
        lightness: '-1'
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a8986'
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        saturation: '35'
      },
      {
        lightness: 37.599999999999994
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#eff5f7'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c2decd'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        saturation: '-40'
      },
      {
        lightness: '27'
      },
      {
        color: '#cfcfcf'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        hue: '#00c9ff'
      },
      {
        saturation: '-100'
      },
      {
        lightness: '44'
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'all',
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: '48'
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [
      {
        hue: '#00ceff'
      },
      {
        saturation: -100
      },
      {
        lightness: '43'
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'all',
    stylers: [
      {
        saturation: '11'
      },
      {
        lightness: '40'
      },
      {
        hue: '#00e1ff'
      }
    ]
  },
  {
    featureType: 'transit.station.rail',
    elementType: 'all',
    stylers: [
      {
        saturation: '-19'
      },
      {
        lightness: '0'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        saturation: '23'
      },
      {
        lightness: '69'
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#c0ece8'
      }
    ]
  }
];

export default styleDefault;
