const TRANSPORT_TYPE_MAPPER = {
  TRANSPORT_TYPE_MOTORCYCLE: {
    text: 'moto'
  },
  TRANSPORT_TYPE_BICYCLE: {
    text: 'não motorizado'
  },
  TRANSPORT_TYPE_CAR: {
    text: 'carro'
  },
  TRANSPORT_TYPE_VAN: {
    text: 'van'
  }
};

export default TRANSPORT_TYPE_MAPPER;
