import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { componentsLocales } from '@loggi/components';
import locales from 'locales';

/**
 * i18next configuration
 */
i18n.use(initReactI18next).init({
  resources: {
    'pt-BR': {
      ...locales.ptBr,
      ...componentsLocales['pt-BR']
    }
  },
  lng: 'pt-BR',
  fallbackLng: 'pt-BR'
});

export default i18n;
