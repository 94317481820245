/* eslint-disable camelcase */
import api, { generateLevePath } from 'infra/api/leve';
import db from 'infra/storage/async-elasticsearch-database';
import moment from 'moment';
import { getCompanyType } from 'auth/access-control/access-control.service';
import { URL_API_V1_LAST_MILE_PREFIX } from 'infra/service/constants';
import { DATE_FILTER } from 'tracking/tracking.constants';
import CUSTODY_MAP from './tracking.configuration';

const checkLocalDatabase = async data => {
  const packages = await db.getPackages();
  const packagesMap = {};

  const oldPackages = packages.filter(
    //                                      ms    s    m    h
    item => new Date() - item.timestamp >= 1000 * 60 * 60 * 24
  );

  oldPackages.forEach(async item => {
    await db.deletePackage(item.packageId);
  });

  packages.map(item => {
    packagesMap[item.packageId] = item;
    return item;
  });

  data.hits.hits.forEach(async item => {
    const packageId = item._source.package.id;

    // early return for a non-cached package listed on fresh data.
    if (!packagesMap[packageId]) {
      return;
    }

    const { sortingRecordId, destinationId, taskId } = packagesMap[packageId];

    const validateSortingRecordItems =
      item._source?.last_breadcrumbs_sorting_record?.id >= sortingRecordId;

    const validateTaskIdItems =
      !sortingRecordId &&
      !destinationId &&
      item._source?.last_breadcrumbs_task?.task?.id >= taskId;

    const validateDestinationIdItems =
      !packagesMap[packageId].sortingRecordId &&
      !packagesMap[packageId].taskId &&
      item._source?.destination?.id >= destinationId;

    if (
      validateSortingRecordItems ||
      validateTaskIdItems ||
      validateDestinationIdItems
    )
      await db.deletePackage(packageId);
  });

  return data;
};

export const buildParamsRequest = ({
  custody,
  page,
  itemsPerPage,
  orderBy,
  secondOrderBy,
  orderDirection,
  filters,
  aggregatedField,
  searchArgument = null,
  startDate = null,
  endDate = null,
  lastMileCompanyType = null
}) => {
  const { dateFilter = null, view, viewFilterBy } = CUSTODY_MAP[custody];
  let deliverersFilter;
  let unitLoadFilter;
  let unitLoadLicensePlateFilter;

  const {
    senders: companyFilters,
    faults: faultsFilter,
    qualityFlags: qualityFlagsFilter,
    status: statusFilters,
    region: regionLabelFilter,
    cities: citiesFilter,
    circles: circlesFilter,
    station: stationFilter
  } = filters || {};

  if (filters?.deliverers && filters.deliverers.length > 0) {
    deliverersFilter = filters.deliverers;
  }

  const updatesFilter = { endDate, startDate };
  let dateFilterUpdates = null;
  if (filters?.updates && filters.updates.length > 0) {
    const { updates } = filters;
    const [value] = updates;
    // the both lines above its equal to this one below.
    // const value = filters.updates[0];
    updatesFilter.endDate = value;
    // we force set startDate, because Leve ignores a range date if either of start or end is null.
    updatesFilter.startDate = moment()
      .subtract(60, 'days')
      .format();

    // we need to force filterBy sorting_record, because all the status updated from flecha creates a new sorting_record
    dateFilterUpdates = DATE_FILTER.SORTING_RECORD_CREATED;
  }

  if (
    filters?.unitLoad &&
    filters.licensePlate &&
    filters.unitLoad === 'LICENSE_PLATE'
  ) {
    unitLoadFilter = filters.unitLoad;
    unitLoadLicensePlateFilter = filters.licensePlate;
  }

  return {
    faultsFilter,
    qualityFlagsFilter,
    statusFilters,
    citiesFilter,
    circlesFilter,
    stationFilter,
    page,
    itemsPerPage,
    orderBy,
    secondOrderBy,
    dateFilter: dateFilter || dateFilterUpdates,
    orderDirection,
    searchArgument,
    startDate: updatesFilter.startDate,
    endDate: updatesFilter.endDate,
    view,
    viewFilterBy,
    deliverersFilter,
    regionLabelFilter,
    unitLoadFilter,
    unitLoadLicensePlateFilter,
    aggregatedField,
    companyFilters,
    lastMileCompanyType: lastMileCompanyType || getCompanyType()
  };
};

export const fetchGroupByUnitLoad = async (
  custody,
  page,
  itemsPerPage,
  orderBy,
  secondOrderBy,
  filters,
  aggregatedField = 'unit_load'
) => {
  const params = buildParamsRequest({
    custody,
    page,
    itemsPerPage,
    orderBy,
    secondOrderBy,
    filters,
    aggregatedField
  });

  return api
    .post(`${URL_API_V1_LAST_MILE_PREFIX}/unit_load`, params)
    .then(data => {
      return data.data;
    });
};

const trackingService = ({
  custody,
  page,
  itemsPerPage,
  orderBy,
  orderDirection,
  filters,
  searchArgument = null,
  startDate = null,
  endDate = null,
  lastMileCompanyType = null
}) => {
  const params = buildParamsRequest({
    custody,
    page,
    itemsPerPage,
    orderBy,
    orderDirection,
    filters,
    searchArgument,
    startDate,
    endDate,
    lastMileCompanyType
  });

  return api
    .post('/api/v2/last_mile/packages/filter_search', params)
    .then(response => checkLocalDatabase(response.data))
    .then(async _data => {
      const packages = await db.getPackages();
      const data = { ..._data };

      packages.forEach(packageIndexDB => {
        const indexResponsePackage = data.hits.hits.findIndex(
          obj => `${obj._source.package.id}` === `${packageIndexDB.packageId}`
        );
        if (indexResponsePackage >= 0) {
          data.hits.hits[indexResponsePackage].isSyncing = true;
          if (packageIndexDB.licensePlate === ':') {
            data.hits.hits[indexResponsePackage].isRefuseNotFound = true;
          }
        }
      });
      return data;
    });
};

export const fetchPackagesByBarcode = async () => {
  const packagesFromCache = await db.getPackages();
  const packageIds = packagesFromCache.map(
    packageIndexDB => packageIndexDB.packageId
  );
  // In this case, we are setting the itemsPerPage,
  // because we want to fetch all the cached packages
  return api
    .post('/api/v2/last_mile/packages/filter_search', {
      page: 1,
      itemsPerPage: packageIds.length,
      searchField: 'package.id',
      // avoid elastic error of too_many_clause_count
      // https://sentry.io/organizations/loggi/issues/?environment=production&project=1524595&query=is%3Aunresolved+too_many_clauses&statsPeriod=14d
      searchArgument: packageIds.splice(0, 800).join(' ')
    })
    .then(response => checkLocalDatabase(response.data));
};

export const generateCsvLink = async ({ view, viewFilterBy } = {}) => {
  const timezone = moment().format('ZZ');
  const path = `/api/v1/last_mile/[last_mile_company.identification]:[distribution_center.id]/packages/report?timezone=${timezone}&view=${view ||
    'base'}&viewFilterBy=${viewFilterBy || ''}`;
  return generateLevePath(path);
};

export default trackingService;
