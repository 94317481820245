import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Skeleton } from '@material-ui/lab';
import { fetchTripsCheck } from './service';

const CellContainer = ({ licensePlate }) => {
  const [deliverer, setDeliverer] = useState('-');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await fetchTripsCheck(licensePlate)
        .then(response => {
          // eslint-disable-next-line camelcase
          if (response?.is_allocated) {
            setDeliverer(response?.driver?.name);
          }
          setLoading(false);
        })
        .catch(error => {
          if (error?.response?.status !== 404) Sentry.captureException(error);
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensePlate]);

  return (
    <>
      {loading ? (
        <Skeleton
          style={{ borderRadius: 8 }}
          variant="rect"
          height={24}
          maxWidth={80}
        />
      ) : (
        deliverer
      )}
    </>
  );
};

CellContainer.propTypes = {
  licensePlate: PropTypes.string.isRequired
};

export default CellContainer;
