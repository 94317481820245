/**
 * Normalize `value` only to numberin type `string`. An empty string
 * is returned for `null` and `undefined` values.
 * @since 1.5.0
 * @category Function
 * @param {number} value The value to convert.
 * @returns {string} Returns the converted string.
 * @example
 *
 * normalizeNumber()
 * // => ''
 *
 * normalizeNumber(null)
 * // => ''
 *
 * normalizeNumber(1234)
 * // => '1234'
 *
 * normalizeNumber('1234')
 * // => '1234'
 */

export default function normalizeNumber(value) {
  if (typeof value === 'number') {
    return String(value);
  }

  if (typeof value === 'string') {
    return value.replace(/\D+/g, '');
  }

  return '';
}
