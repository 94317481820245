import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Grid,
  TextField,
  Fab,
  Divider,
  InputAdornment,
  IconButton,
  List,
  ListSubheader,
  ListItem,
  Collapse
} from '@material-ui/core';
import {
  Add,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Remove
} from '@material-ui/icons';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/pt-br';
import { colors } from '@loggi/mar';
import { DrawerContent, DrawerHeader, CustomDrawer } from 'shared/drawer';
import showSnackbar from 'shared/snackbar';

import { IsFSActiveForCurrentBase } from 'auth/access-control';
import {
  DRAWER_HEADER_TEXT,
  TIME_WINDOW_TEXT,
  MODAL_TEXT,
  MODAL_MAPPER,
  BUTTON_TEXT,
  DATE_WINDOW_TEXT,
  REGION_TEXT
} from './capacity-reserve.constants';
import createCapacityReserveService from './capacity-reserve.service';
import { useStyles } from './capacity-reserve.style';

moment.locale('pt-BR');

const DRAWER_STATUS = {
  type: 'component',
  component: () => {
    return (
      <>
        <Typography noWrap variant="body1" color="textSecondary">
          {DRAWER_HEADER_TEXT.STATUS}
        </Typography>
      </>
    );
  }
};

const RegionRow = ({
  classes,
  region,
  selectedRegionId,
  setSelectedRegionId,
  setSelectedRegionName,
  isLast
}) => {
  return (
    <ListItem
      button
      divider={!isLast}
      selected={selectedRegionId === region.id}
      key={region.id}
      onClick={() => {
        setSelectedRegionId(region.id);
        setSelectedRegionName(region.label);
      }}
      className={`
  ${classes.item}
  ${
    selectedRegionId === region.id
      ? classes.itemSelected
      : classes.itemNotSelected
  }`}
    >
      <Grid container alignItems="center" justify="space-between">
        <Typography variant="body1">{region.label}</Typography>
        <Box mr={3.5} hidden={selectedRegionId !== region.id}>
          <CheckRoundedIcon id={region.id} color="primary" />
        </Box>
      </Grid>
    </ListItem>
  );
};

RegionRow.defaultProps = {
  selectedRegionId: null
};

RegionRow.propTypes = {
  classes: PropTypes.shape({
    item: PropTypes.string.isRequired,
    itemSelected: PropTypes.string.isRequired,
    itemNotSelected: PropTypes.string.isRequired
  }).isRequired,
  region: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired,
  selectedRegionId: PropTypes.string,
  setSelectedRegionId: PropTypes.func.isRequired,
  setSelectedRegionName: PropTypes.func.isRequired,
  isLast: PropTypes.bool.isRequired
};

export default function CapacityReserveComponent({
  handleClosingDrawer,
  setOfferChangedState,
  redirectAnticipatedOfferList,
  demandRegions
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const currentHour = moment().add(1, 'days');
  currentHour.minutes(0).seconds(0);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().add(1, 'days'));
  const [timeStart, setTimeStart] = useState(currentHour.format('HH:mm'));
  const [timeEnd, setTimeEnd] = useState(
    currentHour.add(1, 'hours').format('HH:mm')
  );
  const [modals, setModals] = useState({});
  const [totalModal, setTotalModal] = useState(0);
  const [regionFilter, setRegionFilter] = useState('');
  const [filteredDemandRegions, setFilteredDemandRegions] = useState([]);
  const [selectedRegionId, setSelectedRegionId] = useState();
  const [shouldShowAllRegions, setShouldShowAllRegions] = useState(false);
  const [selectedRegionName, setSelectedRegionName] = useState();

  const anticipatedOfferListFS = IsFSActiveForCurrentBase(
    'anticipatedOfferList'
  );

  const showAnticipatedOfferRegion = IsFSActiveForCurrentBase(
    'showAnticipatedOfferRegion'
  );

  const extraRegionsLength =
    filteredDemandRegions.length > 5 ? filteredDemandRegions.length - 5 : 0;

  let extraRegionsLabel = '';

  if (shouldShowAllRegions) {
    extraRegionsLabel = REGION_TEXT.SHOW_LESS;
  } else if (extraRegionsLength === 1) {
    extraRegionsLabel = REGION_TEXT.SHOW_EXTRA_REGION;
  } else if (extraRegionsLength >= 1) {
    extraRegionsLabel = REGION_TEXT.SHOW_ALL_REGIONS(extraRegionsLength);
  }

  useEffect(() => {
    setFilteredDemandRegions(
      demandRegions.filter(
        region =>
          !regionFilter ||
          region.label.toLowerCase().includes(regionFilter.toLowerCase())
      )
    );
  }, [demandRegions, regionFilter]);

  useEffect(() => {
    const aux = {};
    MODAL_MAPPER.forEach(modal => {
      aux[modal.id] = 0;
      return modal;
    });
    setModals(aux);
  }, []);

  const handleRemoveModal = modalId => {
    if (modals[modalId] > 0) {
      const aux = modals;
      setModals({});
      aux[modalId] -= 1;

      setModals(aux);
      setTotalModal(totalModal - 1);
    }
  };

  const handleAddModal = modalId => {
    const aux = modals;
    setModals({});
    aux[modalId] += 1;

    setModals(aux);
    setTotalModal(totalModal + 1);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);

    const startTime = `${date.format('YYYY-MM-DD')} ${timeStart}`;
    const endTime = `${date.format('YYYY-MM-DD')} ${timeEnd}`;
    const startDateTime = moment(startTime, 'YYYY-MM-DD HH:mm')
      .utc()
      .format();
    const endDateTime = moment(endTime, 'YYYY-MM-DD HH:mm')
      .utc()
      .format();
    const modalQuantity = [];

    Object.keys(modals).forEach(modalId => {
      if (modals[modalId] > 0) {
        const modal = MODAL_MAPPER.find(
          element => element.id === Number.parseInt(modalId, 10)
        );
        modalQuantity.push({
          quantity: modals[modalId],
          transport_type: modal.value,
          region_name: selectedRegionName
        });
      }

      return modals[modalId];
    });

    createCapacityReserveService({
      startDateTime,
      endDateTime,
      modalQuantity
    })
      .then(() => {
        setOfferChangedState(_ => !_);
        showSnackbar({
          withButton: false,
          variant: 'success',
          message: DRAWER_HEADER_TEXT.SUCCESS,
          enqueueSnackbar
        });
        handleClosingDrawer();
        if (anticipatedOfferListFS) {
          redirectAnticipatedOfferList();
        }
      })
      .catch(error =>
        showSnackbar({
          withButton: false,
          variant: 'error',
          message: error?.message,
          enqueueSnackbar
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentUtils}>
      <CustomDrawer>
        <DrawerHeader
          title={DRAWER_HEADER_TEXT.TITLE}
          subtitle={DRAWER_HEADER_TEXT.SUBTITLE}
          status={DRAWER_STATUS}
          colorText={colors.root[0]}
          handleClosingDrawer={handleClosingDrawer}
        />

        <DrawerContent showDivider>
          <Box>
            <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
              {DATE_WINDOW_TEXT.TITLE}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">{DATE_WINDOW_TEXT.SUBTITLE}</Typography>
          </Box>
          <Box pt={2}>
            <TextField
              type="date"
              variant="outlined"
              fullWidth
              label="Data"
              onChange={event => setDate(moment(event.target.value))}
              defaultValue={date.format('YYYY-MM-DD')}
              data-testid="reserveDate"
            />
          </Box>
          <Box pt={2}>
            <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
              {TIME_WINDOW_TEXT.TITLE}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">{TIME_WINDOW_TEXT.SUBTITLE}</Typography>
          </Box>
          <Box pt={2} display="flex">
            <Box pr={2}>
              <TextField
                type="time"
                variant="outlined"
                label="Início do período"
                defaultValue={timeStart}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 900 // 15 min
                }}
                onChange={event => setTimeStart(event.target.value)}
                data-testid="reserveTimeStart"
                style={{ width: '203px' }}
              />
            </Box>
            <Box>
              <TextField
                type="time"
                variant="outlined"
                label="Fim do período"
                defaultValue={timeEnd}
                inputProps={{
                  step: 900 // 15 min
                }}
                onChange={event => setTimeEnd(event.target.value)}
                data-testid="reserveTimeEnd"
                style={{ width: '203px' }}
              />
            </Box>
          </Box>
        </DrawerContent>
        {showAnticipatedOfferRegion && (
          <DrawerContent lessPadding showDivider>
            <Box px={3.5}>
              <Typography variant="subtitle2">
                <em>{REGION_TEXT.TITLE}</em>
              </Typography>
            </Box>
            <Box px={3.5}>
              <Typography variant="body2">{REGION_TEXT.SUBTITLE}</Typography>
            </Box>
            <Box pt={1}>
              <List disablePadding>
                <ListSubheader className={classes.listSubHeader}>
                  <Box py={1} px={1.5}>
                    <TextField
                      fullWidth
                      onChange={e => setRegionFilter(e.target.value)}
                      placeholder={REGION_TEXT.SEARCH_PLACEHOLDER}
                      value={regionFilter}
                      variant="outlined"
                      InputProps={{
                        'data-testid': 'user-filter-input',
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchRoundedIcon color="disabled" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <Box hidden={!regionFilter}>
                            <InputAdornment position="end">
                              <IconButton onClick={() => setRegionFilter('')}>
                                <CancelRoundedIcon
                                  color="primary"
                                  data-testid="icon-restore-search"
                                />
                              </IconButton>
                            </InputAdornment>
                          </Box>
                        )
                      }}
                    />
                  </Box>
                </ListSubheader>
                {filteredDemandRegions
                  .slice(0, 5)
                  .map((region, index, array) => (
                    <RegionRow
                      classes={classes}
                      key={region.id}
                      region={region}
                      selectedRegionId={selectedRegionId}
                      setSelectedRegionId={setSelectedRegionId}
                      isLast={
                        !shouldShowAllRegions && index === array.length - 1
                      }
                      setSelectedRegionName={setSelectedRegionName}
                    />
                  ))}
                <Collapse in={shouldShowAllRegions}>
                  {filteredDemandRegions
                    .slice(5)
                    .map((region, index, array) => (
                      <RegionRow
                        classes={classes}
                        key={region.id}
                        region={region}
                        selectedRegionId={selectedRegionId}
                        setSelectedRegionId={setSelectedRegionId}
                        isLast={index === array.length - 1}
                        setSelectedRegionName={setSelectedRegionName}
                      />
                    ))}
                </Collapse>
              </List>
              {extraRegionsLength !== 0 && (
                <Box pt={1} px={3.5}>
                  <Button
                    className={classes.collapseButton}
                    color="primary"
                    endIcon={
                      shouldShowAllRegions ? (
                        <KeyboardArrowUp color="primary" />
                      ) : (
                        <KeyboardArrowDown color="primary" />
                      )
                    }
                  >
                    <Typography
                      variant="button"
                      color="primary"
                      onClick={() => {
                        setShouldShowAllRegions(show => !show);
                      }}
                    >
                      <em>{extraRegionsLabel}</em>
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
          </DrawerContent>
        )}
        <DrawerContent showDivider>
          <Box>
            <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
              {MODAL_TEXT.TITLE}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">{MODAL_TEXT.SUBTITLE}</Typography>
          </Box>
          {Object.keys(modals).map(modalId => {
            const modal = MODAL_MAPPER.find(
              element => element.id === Number.parseInt(modalId, 10)
            );
            return (
              <Box pt={2} key={`modal_${modalId}`}>
                <Grid container>
                  <Grid item xs={2}>
                    <modal.icon />
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      paddingLeft: '8px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {modal.text}
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Fab
                      disabled={modals[modalId] < 1}
                      className={classes.fab}
                      onClick={() => handleRemoveModal(modalId)}
                      data-testid={`modal_${modalId}_remove`}
                    >
                      <Remove className={classes.icon} />
                    </Fab>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{
                      paddingLeft: '8px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {modals[modalId]}
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Fab
                      className={classes.fab}
                      onClick={() => handleAddModal(modalId)}
                      data-testid={`modal_${modalId}_add`}
                    >
                      <Add className={classes.icon} />
                    </Fab>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
          <Box py={2}>
            <Grid container justify="flex-end">
              <Typography variant="body2">
                {MODAL_TEXT.TOTAL(totalModal)}
              </Typography>
            </Grid>
          </Box>
          <Divider />
          <Box p={5}>
            {loading ? (
              <CircularProgress data-testid="loading" justify="center" />
            ) : (
              <Button
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                disabled={
                  totalModal === 0 ||
                  !date ||
                  !timeStart ||
                  !timeEnd ||
                  (!selectedRegionName && showAnticipatedOfferRegion)
                }
              >
                {BUTTON_TEXT}
              </Button>
            )}
          </Box>
        </DrawerContent>
      </CustomDrawer>
    </LocalizationProvider>
  );
}

CapacityReserveComponent.propTypes = {
  handleClosingDrawer: PropTypes.func.isRequired,
  setOfferChangedState: PropTypes.func.isRequired,
  redirectAnticipatedOfferList: PropTypes.func,
  demandRegions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired
};

CapacityReserveComponent.defaultProps = {
  redirectAnticipatedOfferList: () => {}
};
