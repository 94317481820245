import { makeStyles } from '@material-ui/core/styles';

import ListItemStyles from 'shared/styles/list.material.styles';

const useStyles = makeStyles(({ typography, palette, spacing }) => ({
  list: {
    width: '300px',
    ...ListItemStyles({ typography, palette, spacing }),
    padding: `${spacing(2)}px 0px` // overriding listItem
  },
  popover: {
    '& .MuiPopover-paper': {
      borderRadius: typography.pxToRem(12)
    },
    marginTop: spacing(1.5)
  }
}));

export default useStyles;
