import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useStyles = makeStyles(({ typography }) =>
  createStyles({
    bolder: {
      fontWeight: 700
    },
    fontSizeIcon: {
      fontSize: typography.pxToRem(14)
    },
    root: {
      borderColor: colors.storm[100]
    },
    item: {
      padding: 0,
      margin: 0
    },
    storm: {
      color: colors.storm[500]
    },
    listItemLabel: {
      flexGrow: 1
    },
    faltaBackground: {
      backgroundColor: colors.red[50]
    },
    helpSmoke: {
      backgroundColor: colors.root[0]
    },
    icon: {
      color: colors.smoke[500]
    }
  })
);

export default useStyles;
