import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as IconOverdue } from 'images/ic-overdue.svg';

import moment from 'moment';
import 'moment/locale/pt-br';

import { ACTIONS_BY_PERIOD } from './deadline-cell.constants';
import {
  FINISHED,
  CANCELLED,
  DROPPED
} from '../status-cell/status-cell.constants';

function isAllFinishedStatus(displayStatuses) {
  const finishedStatus = [FINISHED, CANCELLED, DROPPED];
  return displayStatuses.reduce(
    (acc, status) => acc && finishedStatus.indexOf(status) !== -1,
    true
  );
}

export default function DeadlineCell({
  startDateTime,
  endDateTime,
  statusList
}) {
  const startDate = moment(startDateTime).locale('pt-BR');
  const endDate = moment(endDateTime).locale('pt-BR');

  const allFinishedStatus = isAllFinishedStatus(statusList);

  const periodObject = Object.values(ACTIONS_BY_PERIOD).find(actionObj =>
    actionObj.isBetweenRange(startDate, endDate)
  );
  const displayText = periodObject.formatText(
    startDate,
    endDate,
    allFinishedStatus
  );
  const showOverdueIcon = periodObject.showOverdueIcon && !allFinishedStatus;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {showOverdueIcon && (
        <Box mr={1} display="flex" alignItems="center">
          <IconOverdue data-testid="overdue-icon" />
        </Box>
      )}
      <Typography noWrap data-testid="deadline-text" variant="body2">
        {displayText}
      </Typography>
    </Box>
  );
}

DeadlineCell.propTypes = {
  startDateTime: PropTypes.string.isRequired,
  endDateTime: PropTypes.string.isRequired,
  statusList: PropTypes.arrayOf(PropTypes.string).isRequired
};
