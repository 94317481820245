const FILTER_MAP = {
  'filter#in_progress': 'Sem tentativa',
  'filter#recipient_refused': 'Recusado',
  'filter#invalid_destination': 'Endereço errado',
  'filter#recipient_unavailable': 'Ausente'
};

const ERROR_LABEL = {
  TEXT: 'Ops... Ocorreu algum erro ao buscar as informações. ',
  BUTTON: 'Tente novamente'
};

const NOT_UPDATED_IN_LAST_HOUR = 'Não atualizados na última hora';

export { FILTER_MAP, ERROR_LABEL, NOT_UPDATED_IN_LAST_HOUR };
