import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ConfirmDialogComponent from 'shared/confirm-dialog';
import { TEXTS as RETURN_TEXTS } from 'users-management/drivers/drivers.constants';
import { TEXTS } from './drivers-form.constants';

export const useDialog = () => {
  const [dialogOpen, setOpen] = useState(false);
  const [name, setFirstName] = useState(null);
  const [dialogText, setDialogText] = useState(null);

  const showDialogWithName = useCallback((fullName, message) => {
    setOpen(true);
    setFirstName(fullName.split(' ')[0]);
    setDialogText(message);
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
    setFirstName(null);
    setDialogText(null);
  }, []);

  return {
    showDialogWithName,
    closeDialog,
    dialogOpen,
    dialogText,
    name
  };
};

const DriversFormDialogComponent = props => {
  const { onClose, name, dialogText, open } = props;
  const description = (
    <>
      <em>{name}</em>{' '}
      <span>
        {dialogText === RETURN_TEXTS.SMS_WAS_SENT
          ? TEXTS.DIALOG.description_sms
          : TEXTS.DIALOG.description_email}
      </span>
    </>
  );
  return name ? (
    <ConfirmDialogComponent
      title={TEXTS.DIALOG.title}
      description={description}
      open={open}
    >
      <Button onClick={onClose} variant="contained" color="primary">
        {TEXTS.DIALOG.ok}
      </Button>
    </ConfirmDialogComponent>
  ) : (
    <></>
  );
};

DriversFormDialogComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  name: PropTypes.string,
  dialogText: PropTypes.string
};

DriversFormDialogComponent.defaultProps = {
  name: '',
  dialogText: ''
};

export default DriversFormDialogComponent;
