const defaultOptionPage = {
  numberPages: 1,
  numberItems: 1,
  paginationParams: {
    itemsPerPage: 15,
    page: 1
  }
};

export const defaultPagination = {
  numberPages: 1,
  numberItems: 0,
  itemsPerPage: 15,
  page: 1
};

export default defaultOptionPage;
