import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
    height: '56px',
    padding: '0px 20px'
  }
}));

export default useStyles;
