import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { ReactComponent as DrawerErrorIcon } from 'view/atoms/illustrations/drawer-error/drawer-error.svg';
import PropTypes from 'prop-types';

export default function RetryComponent({ callbackRetry }) {
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box align="center">
        <DrawerErrorIcon />
      </Box>
      <Box maxWidth={310} pt={3.5} color="text.disabled">
        <Typography align="center" variant="body2">
          Eita! Deu algo ruim na hora de carregar as informações
        </Typography>
      </Box>
      <Box pt={3.5} align="center">
        <Button
          data-testid="btn-retry"
          variant="outlined"
          color="primary"
          size="small"
          onClick={callbackRetry}
        >
          Tentar de novo
        </Button>
      </Box>
    </Box>
  );
}

RetryComponent.propTypes = {
  callbackRetry: PropTypes.func.isRequired
};
