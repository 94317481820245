import React, { useState, useEffect } from 'react';

import { getLoggedDCId } from 'auth/login/login.service';
import Performance from 'performance/performance.component';
import performanceService from 'performance/performance.service';

function PerfomanceContainer() {
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchPerfomance() {
      setData(
        await performanceService({
          distributionCenterId: getLoggedDCId()
        })
      );
    }
    fetchPerfomance();
  }, []);

  return <Performance data={data} />;
}

export default PerfomanceContainer;
