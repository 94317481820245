import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  List,
  ListItem,
  TextField,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  ListSubheader,
  CircularProgress
} from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { ReactComponent as FetchDeliverersIcon } from 'images/ic-entregador-empty.svg';
import { CustomDrawer, DrawerHeader, DrawerContent } from 'shared/drawer';
import { useStyles } from 'view/molecules/package-drawer/drawer-change-deliverer/drawer-change-deliverer.style';
import TEXT from './constants';

export default function DrawerDeliverersGroupComponent({
  onCloseDrawer,
  dataGroup,
  deliverers,
  onConfirmChange,
  loadingDelivererAssignment
}) {
  const classes = useStyles();
  const [delivererFilter, setDelivererFilter] = useState('');
  const [selectedDelivererId, setSelectedDelivererId] = useState();
  const [filteredDeliverers, setFilteredDeliverers] = useState([]);

  useEffect(() => {
    setFilteredDeliverers(
      deliverers.filter(
        deliverer =>
          deliverer.isDriver &&
          (!delivererFilter ||
            deliverer.fullName
              .toLowerCase()
              .includes(delivererFilter.toLowerCase()))
      )
    );
  }, [deliverers, delivererFilter]);

  return (
    <>
      <CustomDrawer open>
        <DrawerHeader showDivider handleClosingDrawer={onCloseDrawer}>
          <Typography variant="h4">
            <strong>{TEXT.ASSOCIATE_DELIVERER}</strong>
          </Typography>

          <Typography variant="h5" className={classes.bolder}>
            {`${TEXT.GROUP} #${dataGroup.licensePlate}`}
          </Typography>

          <Typography variant="subtitle1">{`${dataGroup.quantityPackages} ${
            TEXT.PACKAGES_ADDED
          }`}</Typography>
        </DrawerHeader>
        <DrawerContent lessPadding>
          <Box>
            <Box maxHeight="calc(100vh - 191px)" className={classes.list}>
              <List disablePadding>
                <ListSubheader className={classes.listSubHeader}>
                  <Box p={3.5} pt={1}>
                    <TextField
                      fullWidth
                      onChange={e => setDelivererFilter(e.target.value)}
                      placeholder={TEXT.SEARCH_DELIVERER}
                      value={delivererFilter}
                      variant="outlined"
                      InputProps={{
                        'data-testid': 'user-filter-input',
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchRoundedIcon color="disabled" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <Box hidden={!delivererFilter}>
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setDelivererFilter('')}
                              >
                                <CancelRoundedIcon
                                  color="primary"
                                  data-testid="icon-restore-search"
                                />
                              </IconButton>
                            </InputAdornment>
                          </Box>
                        )
                      }}
                    />
                  </Box>
                </ListSubheader>
                {filteredDeliverers.map(deliverer => (
                  <ListItem
                    button
                    divider
                    selected={selectedDelivererId === deliverer.id}
                    key={deliverer.id}
                    onClick={() => setSelectedDelivererId(deliverer.id)}
                    className={`
              ${classes.item}
              ${
                selectedDelivererId === deliverer.id
                  ? classes.itemSelected
                  : classes.itemNotSelected
              }`}
                  >
                    <Grid container alignItems="center" justify="space-between">
                      <Typography variant="body1">
                        {deliverer.fullName}
                      </Typography>
                      <Box
                        mr={3.5}
                        hidden={selectedDelivererId !== deliverer.id}
                      >
                        <CheckRoundedIcon id={deliverer.id} color="primary" />
                      </Box>
                    </Grid>
                  </ListItem>
                ))}
              </List>
              {!filteredDeliverers.length && (
                <Box
                  px={3.5}
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box py={3.5}>
                    <FetchDeliverersIcon />
                  </Box>
                  <Box pb={3.5} px={6}>
                    <Typography
                      variant="body2"
                      align="center"
                      color="textSecondary"
                    >
                      {TEXT.ERROR_MESSAGE_SEARCH}
                      <Box component="span" fontWeight={700}>
                        {delivererFilter}
                      </Box>
                      {TEXT.ERROR_MESSAGE_SEARCH_WHERE}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Box maxWidth={480} minWidth={360}>
              <Box p={2} minWidth={360} display="flex">
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="primary"
                  disabled={!selectedDelivererId || loadingDelivererAssignment}
                  onClick={() => onConfirmChange(selectedDelivererId)}
                  data-testid="continue-button"
                >
                  {TEXT.BUTTON_CONTINUE}
                  {loadingDelivererAssignment && (
                    <Box ml={2}>
                      <CircularProgress justify="center" />
                    </Box>
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </DrawerContent>
      </CustomDrawer>
    </>
  );
}

DrawerDeliverersGroupComponent.propTypes = {
  onCloseDrawer: PropTypes.func.isRequired,
  dataGroup: PropTypes.shape({
    licensePlate: PropTypes.string,
    quantityPackages: PropTypes.number,
    circleName: PropTypes.string,
    circleId: PropTypes.string
  }).isRequired,
  deliverers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
      mobileNumber: PropTypes.string,
      isDriver: PropTypes.bool,
      isActive: PropTypes.bool
    })
  ).isRequired,
  onConfirmChange: PropTypes.func.isRequired,
  loadingDelivererAssignment: PropTypes.bool.isRequired
};
