import React, { useContext, useEffect, useState } from 'react';
import { SummaryContext } from 'tracking/template/summary.context';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { getCompanyType } from 'auth/access-control/access-control.service';
import { Box, Paper, useTheme, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Pagination, { usePagination } from 'shared/pagination';
import ConfigurableTable from 'shared/configurable-table';
import ConfigurableTableError from 'shared/configurable-table/configurable-table-error.component';
import SelectComponent from 'shared/select';
import { CONFIGURABLE_TABLE_CONFIGURATION_NA_RUA } from '../configuration';
import ORDER_BY_VIEW_DELIVERER_PACKAGES from './configuration';
import HeaderSummaryComponent from './header-summary.component';
import DelivererInfoComponent from './deliverer-info.component';
import FilterElements from './filters/deliverer-filter-elements.container';
import DownloadCsvContainer from '../download';
import { fetchDelivererInfo } from './service';
import { fetchPackages } from '../../packages/service';

export default function DelivererListPackagesContainer() {
  const [filters, setFilters] = useState({});

  const [dataForFilter, setDataForFilter] = useState([]);
  const [packages, setPackages] = useState([]);
  const [totalPackages, setTotalPackages] = useState([]);
  const [delivererInfo, setDelivererInfo] = useState([]);
  const [checkedItemsBase, setCheckedItemsBase] = useState({});

  const [loadingDelivererInfo, setLoadingDelivererInfo] = useState(true);
  const [loadingPackages, setLoadingPackages] = useState(true);

  const [errorFetch, setErrorFetch] = useState(false);
  const [errorFetchDelivererInfo, setErrorFetchDelivererInfo] = useState(false);

  const [orderBy, setOrderBy] = useState(ORDER_BY_VIEW_DELIVERER_PACKAGES[0]);

  const { setSummary } = useContext(SummaryContext);

  let companyType = getCompanyType();
  if (companyType === null || !companyType) {
    companyType = '';
  }

  const history = useHistory();
  const { id: delivererCode } = useParams();

  // Necessary logic, so that when on the screen,
  // it is possible to return to the specific list view
  // Ex: /na-rua/entregador/:id?subView=insucessos
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subView = queryParams.get('subView');

  const [
    pagination,
    changePage,
    setNumberOfItems,
    setCurrentPage,
    resetPagination
  ] = usePagination();

  const changePagination = page => {
    changePage(page);
    setLoadingPackages(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchDelivererInfo({
        delivererCode
      })
        .then(response => {
          setDelivererInfo(response);
          setLoadingDelivererInfo(false);
        })
        .catch(() => {
          setErrorFetchDelivererInfo(true);
          setLoadingDelivererInfo(false);
        });
    };
    setLoadingDelivererInfo(true);
    setErrorFetchDelivererInfo(false);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchPackages({
        page: pagination.currentPage,
        filters,
        delivererCode,
        orderBy: orderBy?.orderBy
      })
        .then(response => {
          setPackages(response?.packages);
          setNumberOfItems(response?.totalPackages);
          setTotalPackages(response?.totalPackages);
          setDataForFilter(response?.filterOptions);
          setLoadingPackages(false);
        })
        .catch(() => {
          setErrorFetch(true);
          setLoadingPackages(false);
        });
    };
    setLoadingPackages(true);
    setErrorFetch(false);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.currentPage, filters, orderBy]);

  useEffect(() => {
    if (!loadingDelivererInfo)
      setSummary(
        <HeaderSummaryComponent
          loading={loadingDelivererInfo}
          history={history}
          data={delivererInfo?.progress}
          errorFetchDelivererInfo={errorFetchDelivererInfo}
          subView={subView}
        />
      );
    else
      setSummary(
        <Skeleton variant="rect" height={40} width={240} animation="wave" />
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDelivererInfo]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const onOrderChange = item => {
    setOrderBy(item);
    resetPagination();
    setLoadingPackages(true);
  };

  return (
    <>
      <DelivererInfoComponent
        loading={loadingDelivererInfo}
        companyType={companyType}
        data={delivererInfo}
        errorFetchDelivererInfo={errorFetchDelivererInfo}
      />

      <Paper component={Box} elevation={10} flexGrow={1} mb={2} minHeight={44}>
        <Box
          px={3}
          pt={4}
          data-testid="in-street-collection"
          display="flex"
          justifyContent="space-between"
        >
          {loadingPackages ? (
            <Skeleton variant="rect" height={32} width={80} animation="wave" />
          ) : (
            <FilterElements
              loading={loadingPackages}
              setFilters={setFilters}
              dataForFilter={dataForFilter}
              checkedItemsBase={checkedItemsBase}
              setCheckedItemsBase={setCheckedItemsBase}
            />
          )}
          {loadingPackages ? (
            <Skeleton variant="rect" height={34} width={100} animation="wave" />
          ) : (
            <Box display="flex">
              <DownloadCsvContainer delivererCode={delivererCode} />
              <Box ml={1}>
                <SelectComponent
                  onSelectedChange={onOrderChange}
                  selected={orderBy}
                  selectOptions={ORDER_BY_VIEW_DELIVERER_PACKAGES}
                  prefixLabel="ordenar por"
                />
              </Box>
            </Box>
          )}
        </Box>

        <Box px={isSmallScreen ? 0 : 3} py={3}>
          {loadingPackages ? (
            <Skeleton variant="rect" height={240} animation="wave" />
          ) : (
            !errorFetch &&
            packages &&
            packages.length > 0 &&
            totalPackages > 0 && (
              <ConfigurableTable
                data={packages}
                columns={CONFIGURABLE_TABLE_CONFIGURATION_NA_RUA}
                withPackageDrawer
              />
            )
          )}
        </Box>

        {errorFetch && <ConfigurableTableError />}

        <Box pb={1} mr={3}>
          {loadingPackages ? (
            <Skeleton variant="rect" height={8} animation="wave" />
          ) : (
            !errorFetch && (
              <Pagination
                onPageChange={changePagination}
                initialPage={pagination.currentPage}
                numberOfPages={pagination.numberOfPages}
                setCurrentPage={setCurrentPage}
              />
            )
          )}
        </Box>
      </Paper>
    </>
  );
}
