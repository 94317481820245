import theme, { pxToRem } from '@loggi/mar';

const chipStyle = {
  MuiChip: {
    ...theme.overrides.MuiChip,
    root: {
      ...theme.overrides.MuiChip.root,
      padding: '8px 12px 8px 12px'
    },
    sizeSmall: {
      ...theme.overrides.MuiChip.sizeSmall,
      height: pxToRem(40)
    },
    labelSmall: {
      ...theme.overrides.MuiChip.labelSmall,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(21)
    },
    iconSmall: {
      ...theme.overrides.MuiChip.iconSmall,
      width: pxToRem(24),
      height: pxToRem(24)
    }
  }
};

export default chipStyle;
