import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const toggle = spacing => ({
  marginRight: spacing(2),
  borderRadius: spacing(3),
  border: 'solid',
  borderWidth: 'thin',
  padding: spacing(0.25),
  '& button': {
    border: spacing(0),
    borderRadius: '24px !important',
    color: colors.root[0],
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    paddingTop: spacing(1),
    paddingBottom: spacing(1)
  },
  '& .MuiToggleButton-root.Mui-selected': {
    color: colors.blue[500],
    backgroundColor: colors.root[0]
  }
});

export const useStyles = makeStyles(({ spacing, typography }) => ({
  /* This customization of the paper component should be removed
     when the theme customizes it accordingly */
  paper: {
    borderRadius: 10
  },
  skeleton: {
    borderRadius: typography.pxToRem(8)
  },
  toggle: toggle(spacing),
  toggleOfertas: {
    ...toggle(spacing),
    borderRadius: spacing(100),
    color: colors.root[0],
    backgroundColor: colors.blue[500],
    borderColor: colors.blue[500],
    '& .MuiToggleButton-root.Mui-selected': {
      color: colors.blue[500],
      backgroundColor: colors.root[0],
      marginRight: spacing(0.5)
    },
    '& .MuiToggleButton-root.Mui-selected:not': {
      marginLeft: 0
    },
    '& .MuiButtonBase-root': {
      color: colors.root[0],
      backgroundColor: colors.blue[500]
    }
  },
  toggleListOffer: {
    ...toggle(spacing),
    borderRadius: spacing(100),
    color: 'inherit',
    backgroundColor: 'inherit',
    borderColor: colors.root[0],
    '& .MuiToggleButton-root.Mui-selected': {
      color: colors.blue[500],
      backgroundColor: colors.root[0],
      marginRight: spacing(0.5)
    },
    '& .MuiToggleButton-root.Mui-selected:not': {
      marginLeft: 0,
      color: 'inherit'
    },
    '& .MuiButtonBase-root': {
      color: colors.root[0],
      backgroundColor: 'inherit'
    }
  },
  inputFilter: {
    textAlign: 'center',
    backgroundColor: colors.root[0],
    borderColor: colors.blue[500],
    '& .MuiInputLabel-outlined': {
      color: colors.blue[500]
    },
    '& .MuiInputLabel-outlined:hover': {
      borderColor: colors.blue[500]
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: spacing(3)
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.blue[500]
    }
  },
  tabOffer: {
    borderBottom: `1px solid ${colors.smoke[200]}`
  },
  chip: {
    margin: spacing(0.5),
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.smoke[50]
  }
}));

export default useStyles;
