import React from 'react';

import { Drawer, Box, Fab } from '@material-ui/core';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import PropTypes from 'prop-types';
import DrawerInfoContainer from './drawer-info/index';

import useStyles from './drawer.style';

export default function DrawerContainer({ valueNF, handleOpenDrawer }) {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open
      data-testid="drawer-finance"
      variant="persistent"
    >
      <Box
        maxWidth={480}
        width={480}
        role="presentation"
        data-testid="backgound-drawer"
      >
        <Box mx={3.5} py={5}>
          <Fab
            className={classes.root}
            size="small"
            data-testid="closed-drawer-finance"
            onClick={() => handleOpenDrawer()}
          >
            <CloseRoundedIcon color="primary" />
          </Fab>
        </Box>
        <DrawerInfoContainer valueNF={valueNF} />
      </Box>
    </Drawer>
  );
}

DrawerContainer.propTypes = {
  handleOpenDrawer: PropTypes.func.isRequired,
  valueNF: PropTypes.shape({
    periodTotalValue: PropTypes.number.isRequired,
    deliveryTotalValue: PropTypes.number.isRequired,
    pickupTotalValue: PropTypes.number.isRequired,
    transhipmentTotalValue: PropTypes.number.isRequired
  }).isRequired
};
