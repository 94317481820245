import { DEFAULT_MESSAGE } from 'app/httpMessage';

const INTENTION = {
  DELAYED: 'base.offer.delayed',
  NO_PICKUP: 'base.offer.no_pickup',
  ROUTINES: 'routines.notification'
};

const DRAWER = {
  TITLE: 'Notificações',
  EMPTY_NOTIFICATIONS:
    'Sem notificações por enquanto. Assim que tivermos novidades, as notificações vão aparecer por aqui.',
  BUTTON: {
    RETRY: 'Tentar de novo',
    MARK_AS_READ: 'Marcar como lidas'
  },
  GROUP_TITLE: {
    TODAY: 'Hoje',
    WEEK: 'Na semana',
    MONTH: 'No mês'
  },
  NOTIFICATION_TITLE: {
    [INTENTION.DELAYED]: 'Oferta com atraso',
    [INTENTION.NO_PICKUP]: 'Oferta com atraso',
    [INTENTION.ROUTINES]: 'Rotinas'
  }
};

const DIALOG = {
  TITLE: 'Receba notificações',
  CONTENT: 'Ative as notificações e receba avisos importantes para a sua base.',
  BUTTON: {
    ACCEPT: 'Ativar notificações',
    DENY: 'Agora não'
  }
};

const ERROR = {
  GETTING_NOTIFICATIONS:
    'Deu algum problema e não conseguimos carregar as notificações.',
  MARK_NOTIFICATIONS_AS_READ: DEFAULT_MESSAGE
};

const PERMISSION = {
  GRANTED: 'granted',
  DEFAULT: 'default',
  DENIED: 'denied'
};

const LOCAL_STORAGE_KEY = {
  POSTPONED_DIALOG: 'postponed_dialog'
};

export { DRAWER, DIALOG, ERROR, INTENTION, PERMISSION, LOCAL_STORAGE_KEY };
