const TEXT = {
  WILL_ARRIVE: 'Vai chegar',
  FROM: 'De',
  SCHEDULED_TO: 'Previsto para',
  PACKAGES: 'pacotes',
  FROM_TRANSPORT_COMPANY: ' da '
};

export const AGGREGATION_SELECT = [
  {
    header: 'Quem enviou',
    sortBy: 'company'
  },
  { header: 'Região', sortBy: 'demand_region' },
  { header: 'Tamanho', sortBy: 'gross_weight' },
  {
    header: 'Cidades',
    sortBy: 'city'
  },
  { header: 'CEP', sortBy: 'zipcode' }
];

export const AGGREGATION_SELECT_XDS = [
  {
    header: 'Quem enviou',
    sortBy: 'company'
  },
  { header: 'Tamanho', sortBy: 'gross_weight' }
];
export const SELECTED_LABEL = 'Pacotes por';
export const INTERNAL_SELECT_LABEL = 'Ver por';

export default TEXT;
