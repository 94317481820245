import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ReactComponent as NotFoundIcon } from 'view/atoms/illustrations/not-found-404/notFound404.svg';
import TEXTS from './constants';

export default function NotFound404Template({ redirect }) {
  return (
    <Container maxWidth="sm" fixed>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Box my={2}>
            <NotFoundIcon />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Typography align="center" variant="h4">
            {TEXTS.COULD_NOT_FOUND_TITLE}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography align="center" variant="subtitle1">
            {TEXTS.COULD_NOT_FOUND_SUBTITLE}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={redirect}
            data-testid="redirect-button"
          >
            {TEXTS.REDIRECT_BUTTON}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

NotFound404Template.propTypes = {
  redirect: PropTypes.func.isRequired
};
