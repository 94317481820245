import { Button, Switch, Tab, Tabs } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles({
  customAvatar: {
    fontFamily: 'Open Sans',
    fontSize: '20px',
    marginRight: '-8px',
    width: 40,
    height: 40,
    border: `2px solid white`,
    color: colors.blue[300],
    backgroundColor: colors.blue[50]
  }
});

export const EditButton = withStyles({
  root: {
    minWidth: 0,
    padding: 0
  }
})(Button);

export const CustomSwitch = withStyles(theme => ({
  root: {
    width: 44,
    height: 32,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 4,
    color: theme.palette.common.white,
    '& + .MuiSwitch-track': {
      backgroundColor: theme.palette.grey[200],
      opacity: 1
    },
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.grey[100],
      opacity: 1
    },
    '&.Mui-disabled': {
      color: theme.palette.common.white
    }
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: 'none'
  },
  track: {
    borderRadius: 100,
    opacity: 1,
    backgroundColor: colors.smoke[100]
  },
  checked: {}
}))(Switch);

export const CustomTabs = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${colors.smoke[200]}`
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    height: 4,
    borderRadius: 100
  }
}))(Tabs);

export const CustomTab = withStyles({
  root: {
    minWidth: 70
  }
})(Tab);
