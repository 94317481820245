export const TEXT = {
  TITLE: 'Ofertas',
  SUBTITLE: 'agendamento',
  CAPACITY_RESERVE_BUTTON: 'Programar oferta'
};

export const TEXT_TABLE = {
  MSG_ERROR:
    'Eita! Deu algo ruim aqui e não conseguimos carregar os agendamentos.',
  BTN_ERROR: 'Carregar novamente'
};

export const TEXT_NO_DRIVER_ACCEPTED = 'ninguém aceitou';

export const PICKUPED_PACKAGES = 'pacotes coletados';
export const PICKUPED_PACKAGE = 'pacote coletado';

export const RANGE_SELECTOR_TEXT_TABLE = {
  TITLE: 'Prazo',
  LATE: 'Com atraso',
  FOR_NOW: 'Para agora',
  FOR_TODAY: 'Para hoje',
  FOR_LATER: 'Para depois',
  FINISHED: 'Finalizados'
};

export const RANGE_FILTER_KEY = {
  SCHEDULING_LATE: 'SCHEDULING_LATE',
  SCHEDULING_FOR_NOW: 'SCHEDULING_FOR_NOW',
  SCHEDULING_FOR_TODAY: 'SCHEDULING_FOR_TODAY',
  ACTIVE_WAITING_ACCEPTANCE: 'ACTIVE_WAITING_ACCEPTANCE',
  ACTIVE_WAITING_PICKUP: 'ACTIVE_WAITING_PICKUP'
};

export const TEXT_CARD = {
  CARD_TITLE: 'Ofertas',
  CARD_LINK: 'Ver ofertas',

  SCHEDULES: 'Agendamentos',
  [RANGE_FILTER_KEY.SCHEDULING_LATE]: 'Com atraso',
  [RANGE_FILTER_KEY.SCHEDULING_FOR_NOW]: 'Para agora',
  [RANGE_FILTER_KEY.SCHEDULING_FOR_TODAY]: 'Para hoje',

  ACTIVE: 'Ativas',
  [RANGE_FILTER_KEY.ACTIVE_WAITING_ACCEPTANCE]: 'Aguardando aceite',
  [RANGE_FILTER_KEY.ACTIVE_WAITING_PICKUP]: 'Aguardando coleta'
};

const listReducer = list =>
  list.reduce((prev, item) => {
    const _prev = prev;
    if (item in prev) _prev[item] += 1;
    else _prev[item] = 1;
    return _prev;
  }, {});

export const listMapper = (list, ITEM_MAPPER) =>
  Object.entries(listReducer(list)).map(([key, value]) => {
    const objMap = ITEM_MAPPER[key] || {};
    objMap.total = value;
    return objMap;
  });

export const DRAWER_ERROR_GET_REGIONS_NAMES_TEXT =
  'Eita! Deu algum ruim aqui na hora de carregar as informações das Regiões.';

export const DRAWER_ERROR_GET_REGIONS_NAMES_BTN_RETRY = 'Tentar de novo';
