export const URL_PROXY_V1_PREFIX =
  '/proxy/last-mile/v1/[last_mile_company.identification]:[distribution_center.id]';

export const URL_PROXY_V2_PREFIX =
  '/proxy/last-mile/v2/[last_mile_company.identification]:[distribution_center.id]';

export const URL_PROXY_V3_PREFIX =
  '/proxy/last-mile/v3/[last_mile_company.identification]:[distribution_center.id]';

export const URL_API_V1_LAST_MILE_PREFIX =
  '/api/v1/last_mile/[last_mile_company.identification]:[distribution_center.id]';

export const URL_API_V2_LAST_MILE_PREFIX =
  '/api/v2/last_mile/[last_mile_company.identification]:[distribution_center.id]';

export const URL_API_V1_LAST_TRANSFER_IN_UNIT_LOAD_URL =
  '/proxy/last-mile/v1/leve/last_transfer/unit_load';

export const GET_UNIT_LOAD_URL = '/proxy/wms/api/v1/unit-load';

export const MOVE_PACKAGES_URL = '/proxy/wms/api/v1/move-package';

export const MOVE_PACKAGES_BULK_URL = '/proxy/wms/api/v1/move-packages-bulk';

export const GET_RECEIVE_INFO_URL = '/proxy/wms/api/v1/get-receive-info';

export const GET_LOGGERS = '/proxy/last-mile/v3/loggers';

export const PROMISED_DATE_URL =
  '/proxy/promiseland/api/v1/[last_mile_company.identification]:[distribution_center.id]';

export const GRAPHQL_URL = '/graphql';

export const GET_DEFINED_REGIONS = '/proxy/wms/api/v1/defined-regions';

export const GET_REGIONS_NAMES = '/proxy/last-mile/v1/get-demand-regions';

export const UPDATE_DEFINED_REGIONS =
  '/proxy/wms/api/v1/update-defined-regions';

export const GET_DRIVER_POSITION = '/proxy/drivers/position/';

export const GET_SORTING_CONTEXT = 'api/v1/get_sorting_context';

export const UPDATE_SORTING_CONTEXT = 'api/v1/update_sorting_context';

export const CREATE_SIMULATE_DEMAND_REGIONS =
  'api/v1/create_simulation_demand_regions';

export const URL_API_V1_AT_RISK_PREFIX =
  '/api/v1/at_risk/[last_mile_company.identification]:[distribution_center.id]';

export const URL_API_V1_DISPUTES_PREFIX =
  '/api/v1/disputes/[last_mile_company.identification]:[distribution_center.id]';

export const URL_API_V1_CIRCLE_PREFIX =
  '/api/v1/[last_mile_company.identification]:[distribution_center.id]/circle';

export const URL_PROXY_DRIVERS_V2_PREFIX =
  '/drivers/last-mile/v2/[last_mile_company.identification]:[distribution_center.id]';

export const URL_API_V1_PACKAGE_GROUP_PREFIX =
  '/api/v1/last_mile/[last_mile_company.identification]:[distribution_center.id]/package-group';

export const URL_API_V1_LAST_MILE_PACKAGES_BARCODE =
  '/api/v1/last_mile/packages?barcode=';
