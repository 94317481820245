import mask from './mask';
import normalizeNumber from './normalize-number';
import {
  PATTERN_OLD_PHONE,
  PATTERN_OLD_PHONE_WITH_COUNTRY_CODE,
  PATTERN_NEW_PHONE,
  PATTERN_NEW_PHONE_WITH_COUNTRY_CODE,
  NEW_PHONE_SIZE,
  OLD_PHONE_WITH_COUNTRY_CODE_SIZE,
  NEW_PHONE_WITH_COUNTRY_CODE_SIZE
} from './constants';

export default function phone(value) {
  if (!value) {
    return '';
  }
  const valueFormated = normalizeNumber(value).trim();
  const valueLength = valueFormated.length;
  const isLevePhone =
    valueLength === OLD_PHONE_WITH_COUNTRY_CODE_SIZE &&
    valueFormated.slice(-1) === '0';

  if (valueLength < NEW_PHONE_SIZE) {
    return mask(valueFormated, PATTERN_OLD_PHONE);
  }
  if (isLevePhone) {
    return mask(valueFormated.slice(0, -1), PATTERN_NEW_PHONE);
  }
  if (valueLength === OLD_PHONE_WITH_COUNTRY_CODE_SIZE) {
    return mask(
      valueFormated.slice(0, OLD_PHONE_WITH_COUNTRY_CODE_SIZE),
      PATTERN_OLD_PHONE_WITH_COUNTRY_CODE
    );
  }
  if (valueLength >= NEW_PHONE_WITH_COUNTRY_CODE_SIZE) {
    return mask(
      valueFormated.slice(0, NEW_PHONE_WITH_COUNTRY_CODE_SIZE),
      PATTERN_NEW_PHONE_WITH_COUNTRY_CODE
    );
  }

  return mask(valueFormated, PATTERN_NEW_PHONE);
}
