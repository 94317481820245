const TEXT = {
  TITLE: 'Vai chegar',
  PACKAGES: ' pacotes em ',
  VEHICLE: ' veículos'
};

const CALENDAR_FORMAT = {
  sameDay: '[Hoje, ]HH:mm',
  nextDay: '[Amanhã, ]HH:mm',
  nextWeek: 'DD MMM[, ]HH:mm',
  sameElse: 'DD MMM[, ]HH:mm'
};

const TEXT_ALERT_STATUS_HEADER = {
  CARD: {
    PRIMARY: 'Indicadores temporariamente indisponíveis',
    SECONDARY:
      'As transferências e coletas podem continuar normalmente. Vamos atualizar em breve.'
  },
  COMPONENT: {
    PRIMARY: 'Algumas informações da lista podem estar desatualizadas',
    SECONDARY: 'Continue usando a plataforma. Vamos atualizar em breve.'
  }
};

export const CARD_AGGREGATION = 'card_transfers';

export { TEXT, CALENDAR_FORMAT, TEXT_ALERT_STATUS_HEADER };
