import { useState } from 'react';

const useFilterChecked = context => {
  const [checkedItems, setChecked] = useState(context);
  const [lastSavedCheckedCount, setLastSavedCheckedCount] = useState(0);

  const toogleCheckedItem = (key, value, setCheckedBase, checkedItemsBase) => {
    const _checkedItems = Object.values(checkedItemsBase).length
      ? checkedItemsBase
      : checkedItems;

    // get array of checked items for a key
    const _currentChecked = (_checkedItems && _checkedItems[key]) || [];

    const arrayChecked = [..._currentChecked];
    const statustIndex = _currentChecked.indexOf(value);

    if (statustIndex === -1) {
      arrayChecked.push(value);
    } else {
      arrayChecked.splice(statustIndex, 1);
    }

    setChecked(_checked => {
      return {
        ..._checked,
        [key]: arrayChecked
      };
    });
    if (setCheckedBase) {
      setCheckedBase(_checkedBase => {
        return {
          ..._checkedBase,
          [key]: arrayChecked
        };
      });
    }
  };

  const setCheckedItems = (key, valueArray) => {
    setChecked(_checked => {
      return {
        ..._checked,
        [key]: valueArray
      };
    });
  };

  const resetCheckedItems = () => {
    Object.keys(checkedItems).forEach(key => {
      setChecked(_checked => {
        return {
          ..._checked,
          [key]: []
        };
      });
    });
  };

  const updateSavedCheckedCount = count => {
    setLastSavedCheckedCount(count);
  };

  const getSavedCheckedCount = () => {
    return lastSavedCheckedCount;
  };

  return {
    checkedItems,
    toogleCheckedItem,
    resetCheckedItems,
    setCheckedItems,
    updateSavedCheckedCount,
    getSavedCheckedCount
  };
};

export default useFilterChecked;
