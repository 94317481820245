export const mockFilterStatus = {
  title: 'Status',
  key: 'status',
  items: [
    {
      text: 'Aguardando tratativa',
      value: '28'
    },
    {
      text: 'Aguardando reenvio',
      value: '29'
    },
    {
      text: 'Aguardando devolução',
      value: '30'
    },
    {
      text: 'Conferido',
      value: '84'
    },
    {
      text: 'Sem tentativa',
      value: '89'
    }
  ],
  useDoubleColumns: false
};

export const mockFilterStation = {
  title: 'Estação',
  key: 'station',
  items: [
    {
      text: 'Recebimento',
      value: 'COL'
    },
    {
      text: 'Processamento',
      value: 'SEP'
    }
  ],
  useDoubleColumns: true
};

export const mockFilterCities = {
  title: 'Cidades',
  key: 'cities',
  items: [
    {
      value: 'CITY_MG_ABADIA_DOS_DOURADOS',
      text: 'Abadia Dos Dourados'
    },
    {
      value: 'CITY_MG_ARAPUA',
      text: 'Arapua'
    }
  ],
  useDoubleColumns: true,
  showExpandMore: true,
  maxItensVisibleDefault: 6
};

export const mockFilterCircles = {
  title: 'Círculo',
  key: 'circles',
  items: [
    {
      value: '1',
      text: 'Circulo 1'
    },
    {
      value: '2',
      text: 'Circulo 2'
    }
  ],
  useDoubleColumns: false,
  showExpandMore: true,
  labelExpandMore: 'Mostrar mais',
  maxItensVisibleDefault: 5
};

export const mockFilterSender = {
  title: 'Quem enviou',
  key: 'senders',
  items: [
    {
      text: 'boticario produtos de beleza lta'
    },
    {
      text: 'GO COMERCIO DE ARTIGO ELETRONICOS E ACESSORIOS LTDA'
    }
  ],
  useDoubleColumns: false,
  showExpandMore: true,
  labelExpandMore: 'Mostrar mais',
  maxItensVisibleDefault: 5
};

export const mockFilterFaults = {
  title: 'Faltas',
  key: 'faults',
  items: [
    {
      text: 'Pacotes com faltas',
      value: 'faults'
    },
    {
      text: 'Pacotes sem faltas',
      value: 'no_faults'
    }
  ],
  useDoubleColumns: true
};
