import { makeStyles, fade } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  list: {
    overflowX: 'hidden'
  },
  item: {
    paddingLeft: spacing(3.5),
    paddingRight: 0,
    paddingTop: '20px',
    paddingBottom: '20px',
    margin: 0,
    display: 'flex',
    alignItems: 'center'
  },
  itemSelected: {
    borderLeft: `3px solid ${colors.blue[500]}`
  },
  itemNotSelected: {
    borderLeft: '3px solid transparent',
    '&:hover': {
      backgroundColor: colors.blue[100]
    }
  },
  footer: {
    borderTop: `1px solid ${colors.smoke[100]}`,
    backgroundColor: colors.root[0],
    right: 0,
    bottom: 0,
    position: 'fixed'
  },
  footerShadow: {
    boxShadow: `0px -2px 5px ${fade(palette.text.primary, 0.1)}`
  },
  listSubHeader: {
    backgroundColor: colors.root[0]
  }
}));

export default useStyles;
