import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useSnackbar } from 'notistack';
import showSnackbar from 'shared/snackbar';
import { AUTHORIZED_ROUTES } from 'view/constants';
import ConfigurableTable from 'view/molecules/configurable-table';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import DialogDistribuirRedirectContainer from 'shared/confirm-dialog/distribuir-redirect';
import { TEXT, ColumnsConfig } from './constants';

export function ChooseDelivererPage({ getDeliverers }) {
  const [deliverers, setDeliverers] = useState([]);
  const [loading, setLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const onSuccess = responseDeliverers => {
    setLoading(false);
    setDeliverers(responseDeliverers);
  };

  const onError = e => {
    setLoading(false);
    showSnackbar({
      message: e.message,
      variant: 'error',
      enqueueSnackbar
    });
  };

  const redirectClick = item => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${AUTHORIZED_ROUTES.MOVE.DELIVERERS}/${item.id}`, item);
  };

  useEffect(() => {
    getDeliverers(true)
      .then(onSuccess)
      .catch(onError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDeliverers]);

  const addPackageButton = item => {
    if (!item.isDriver) {
      return <></>;
    }
    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => {
          redirectClick(item);
        }}
      >
        {TEXT.BUTTON_TEXT}
      </Button>
    );
  };
  const mobileMask = item => {
    // TODO put an mask to format cellphone data.
    return <Typography>{item.mobileNumber}</Typography>;
  };

  return (
    <Container>
      <DialogDistribuirRedirectContainer />
      <Grid container direction="column">
        <Grid item xs={12}>
          <Box p={2}>
            <Typography variant="h3" gutterBottom>
              {TEXT.TITLE}
            </Typography>
          </Box>
          <Box pl={2}>
            <Typography variant="h4">{TEXT.STAFF}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={2}>
            <Typography color="textSecondary">{TEXT.DESCRIPTION}</Typography>
          </Box>
        </Grid>
        {loading && (
          <Grid container justify="center">
            <Box p={2}>
              <CircularProgress justify="center" />
            </Box>
          </Grid>
        )}
        {!loading && deliverers.length === 0 && (
          <Grid item xs={12}>
            <Box p={2} mt={3}>
              <Typography>{TEXT.NO_DELIVERERS}</Typography>
            </Box>
          </Grid>
        )}
        {!loading && deliverers.length > 0 && (
          <Grid item xs={12}>
            <ConfigurableTable
              data={deliverers}
              columns={ColumnsConfig(addPackageButton, mobileMask)}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
ChooseDelivererPage.propTypes = {
  getDeliverers: PropTypes.func.isRequired
};

ChooseDelivererPage.displayName = 'ChooseDelivererScreen';

export default withRouter(ChooseDelivererPage);
