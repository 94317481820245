import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';

import Rating from '@material-ui/lab/Rating';
import { Typography, Box, Grid, Container } from '@material-ui/core';

import { ReactComponent as Star } from 'images/star.svg';
import { ReactComponent as StarEmpty } from 'images/star-empty.svg';
import MissedBoxEvents from 'shared/missed-events-box';
import useStyles from 'performance/performance.styles';
import { AVERAGE_LABEL, PERIOD_LABEL } from 'performance/performance.constants';

export function Performance({ data }) {
  const classes = useStyles();

  const dateFormatter = date =>
    moment(date)
      .locale('pt-BR')
      .format('DD MMM');

  return (
    <>
      {data && (
        <Container className={classes.container}>
          <Grid container direction="row" alignItems="center">
            <Grid container item xs>
              <MissedBoxEvents
                count={data.missedEventsAverage}
                variant={data.missedEventsAverage > 99 ? 'rectangle' : 'square'}
              />
              <Box ml={1} display="flex" alignItems="center">
                <Typography
                  variant="caption"
                  className={classes.performanceInfo}
                >
                  <em>{AVERAGE_LABEL}</em>
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs justify="flex-end">
              <Box mr={1.5} display="flex" alignItems="center">
                <Typography
                  variant="caption"
                  className={classes.performanceInfo}
                >
                  <em>{`${PERIOD_LABEL} ${dateFormatter(
                    data.startDate
                  )} - ${dateFormatter(data.endDate)}`}</em>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Rating
                  defaultValue={data.stars}
                  icon={<Star className={classes.starStyle} />}
                  emptyIcon={<StarEmpty className={classes.starStyle} />}
                  readOnly
                />
              </Box>
              <Box ml={0.25} display="flex" alignItems="center">
                <Typography variant="body2" className={classes.earningsInfo}>
                  <em>{data.bonus ? `${data.bonus} %` : ''}</em>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}

Performance.propTypes = {
  data: PropTypes.shape({
    missedEventsAverage: PropTypes.number,
    stars: PropTypes.number,
    bonus: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string
  })
};

Performance.defaultProps = {
  data: null
};

export default Performance;
