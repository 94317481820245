import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const BORDER = `1px solid ${colors.smoke[500]}`;

export default makeStyles(({ spacing }) => ({
  root: {
    borderCollapse: 'separate',
    '& tr.MuiTableRow-root.expanded': {
      '& > th': {
        borderTop: BORDER,
        '&:first-child': {
          borderTopLeftRadius: spacing(2),
          borderLeft: BORDER
        },
        '&:last-child': {
          borderTopRightRadius: spacing(2),
          borderRight: BORDER
        },
        borderBottomWidth: 0
      }
    },
    '& tr.MuiTableRow-root.rowCollapsible': {
      '&.closed > .MuiTableCell-root': {
        borderColor: '#EAEEF4',
        height: 0,
        borderBottomWidth: 0
      },
      '& > .MuiTableCell-root': {
        paddingTop: 0,
        paddingBottom: 0,
        borderLeft: BORDER,
        borderRight: BORDER,
        borderBottom: BORDER,
        borderRadius: spacing(0, 0, 2, 2)
      }
    }
  },
  tableHead: {
    whiteSpace: 'nowrap'
  },
  tableCell: {
    pointerEvents: 'none',
    cursor: 'default'
  },
  dataRow: {
    pointerEvents: 'auto',
    cursor: 'pointer'
  },
  rowSelected: {
    backgroundColor: colors.blue[50]
  },
  syncing: {
    color: colors.smoke[500]
  }
}));
