import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons/';
import { CustomDrawer, DrawerHeader, DrawerContent } from 'shared/drawer';

import { ROLES, TEXT } from './circles-drawer.constants';
import {
  CustomSwitch,
  CustomTab,
  CustomTabs,
  useStyles
} from './circles-drawer.styles';

const DRAWER_STATUS = {
  type: 'component',
  component: () => {
    return (
      <>
        <Typography noWrap variant="body1" color="textSecondary">
          {TEXT.ADD}
        </Typography>
      </>
    );
  }
};

const TAB = {
  OPERATORS: 0,
  DRIVERS: 1
};

const tabs = ['Para base', 'Para rua'];

export default function CirclesUsersDrawerComponent({
  submitCallback,
  changeCallback,
  closeCallback,
  open,
  loading,
  users,
  selectedDrivers,
  selectedOperators
}) {
  const [currentTab, setCurrentTab] = useState(TAB.OPERATORS);
  const [shouldShowAllDrivers, setShouldShowAllDrivers] = useState(false);
  const [userFilter, setUserFilter] = useState('');

  const classes = useStyles();

  const handleChange = (wasChecked, user, userType) => {
    changeCallback(wasChecked, user, userType);
  };

  const handleSubmit = e => {
    e.preventDefault();
    submitCallback();
  };

  const filteredDrivers = userFilter
    ? users.drivers.filter(driver =>
        driver.fullName.toLowerCase().includes(userFilter.toLowerCase())
      )
    : users.drivers;

  const extraDriversLength =
    filteredDrivers.length > 5 ? filteredDrivers.length - 5 : 0;

  let extraDriversLabel = '';
  if (shouldShowAllDrivers) {
    extraDriversLabel = TEXT.SHOW_LESS;
  } else if (extraDriversLength === 1) {
    extraDriversLabel = TEXT.SHOW_EXTRA_PEOPLE;
  } else if (extraDriversLength >= 1) {
    extraDriversLabel = TEXT.SHOW_ALL_PEOPLE(extraDriversLength);
  }

  return (
    <form noValidate onSubmit={handleSubmit}>
      <CustomDrawer open={open}>
        <DrawerHeader
          handleClosingDrawer={closeCallback}
          open
          showDivider={false}
          title={TEXT.PEOPLE_TITLE}
          status={DRAWER_STATUS}
          detail={TEXT.PEOPLE_SUBTITLE}
          variantTextDetail="subtitle1"
          colorText="textPrimary"
        />
        <DrawerContent>
          <Box>
            <Box>
              <CustomTabs
                indicatorColor="primary"
                textColor="primary"
                value={currentTab}
                onChange={(_, value) => {
                  setCurrentTab(value);
                }}
              >
                {tabs.map(tab => (
                  <CustomTab label={tab} key={tab} />
                ))}
              </CustomTabs>
            </Box>
            {currentTab === TAB.DRIVERS && (
              <Box pt={3}>
                <TextField
                  fullWidth
                  inputProps={{ 'data-testid': 'userFilterInput' }}
                  label={TEXT.SEARCH_USERS}
                  name="userFilter"
                  onChange={e => setUserFilter(e.target.value)}
                  value={userFilter}
                  variant="outlined"
                />
              </Box>
            )}
            <Box pt={3}>
              {currentTab === TAB.OPERATORS &&
                users.operators.map(user => (
                  <Box key={user.lastMileOperator.cognitoSub}>
                    <Box py={2}>
                      <Grid
                        spacing={2}
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        key={user.lastMileOperator.cognitoSub}
                      >
                        <Grid item>
                          <Avatar
                            key={user.fullName}
                            component="span"
                            className={classes.customAvatar}
                          >
                            <em>{user.fullName[0]}</em>
                          </Avatar>
                        </Grid>

                        <Grid item xs>
                          <Typography variant="subtitle2">
                            <em>{user.fullName}</em>
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {ROLES[user.lastMileOperator.role]}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <CustomSwitch
                            name={user.lastMileOperator.cognitoSub}
                            checked={selectedOperators.includes(
                              user.lastMileOperator.cognitoSub
                            )}
                            data-testid="core-operators-user-switch"
                            onChange={e => {
                              handleChange(
                                e.target.checked,
                                user.lastMileOperator.cognitoSub,
                                'operator'
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider variant="middle" />
                  </Box>
                ))}
              {currentTab === TAB.DRIVERS &&
                filteredDrivers
                  .slice(0, 5)
                  .map(user => (
                    <DriverItemList
                      key={user.deliverer.id}
                      user={user}
                      handleChange={handleChange}
                      selectedDrivers={selectedDrivers}
                    />
                  ))}
            </Box>
            <Collapse in={shouldShowAllDrivers}>
              {currentTab === TAB.DRIVERS &&
                filteredDrivers
                  .slice(5)
                  .map(user => (
                    <DriverItemList
                      key={user.deliverer.id}
                      user={user}
                      handleChange={handleChange}
                      selectedDrivers={selectedDrivers}
                    />
                  ))}
            </Collapse>
            {currentTab === TAB.DRIVERS && extraDriversLength !== 0 && (
              <Box pt={1}>
                <Button
                  className={classes.collapseButton}
                  color="primary"
                  endIcon={
                    shouldShowAllDrivers ? (
                      <KeyboardArrowUp color="primary" />
                    ) : (
                      <KeyboardArrowDown color="primary" />
                    )
                  }
                  onClick={() => {
                    setShouldShowAllDrivers(show => !show);
                  }}
                >
                  <Typography variant="button" color="primary">
                    <em>{extraDriversLabel}</em>
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>
        </DrawerContent>
        <DrawerContent>
          <Button
            color="primary"
            data-testid="submit-users-page"
            disabled={loading}
            fullWidth
            type="submit"
            variant="contained"
          >
            {loading ? TEXT.SUBMIT_LOADING : TEXT.SUBMIT_BUTTON}
          </Button>
        </DrawerContent>
      </CustomDrawer>
    </form>
  );
}

CirclesUsersDrawerComponent.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  changeCallback: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  selectedDrivers: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedOperators: PropTypes.arrayOf(PropTypes.string).isRequired,
  users: PropTypes.shape({
    drivers: PropTypes.arrayOf(
      PropTypes.shape({
        deliverer: PropTypes.shape({
          id: PropTypes.string,
          profilePictureThumbnailUrl: PropTypes.string
        }),
        fullName: PropTypes.string,
        avatarSource: PropTypes.string
      })
    ),
    operators: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string,
        lastMileOperator: PropTypes.shape({
          userId: PropTypes.string,
          role: PropTypes.string
        })
      })
    )
  }).isRequired
};

CirclesUsersDrawerComponent.defaultProps = {
  open: true
};

function DriverItemList({ user, selectedDrivers, handleChange }) {
  const classes = useStyles();

  return (
    <Box>
      <Box py={2}>
        <Grid
          spacing={2}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          key={user.deliverer.id}
        >
          <Grid item>
            <Avatar
              key={user.fullName}
              component="span"
              className={classes.customAvatar}
            >
              <em>{user.fullName[0]}</em>
            </Avatar>
          </Grid>

          <Grid item xs>
            <Typography variant="subtitle2">
              <em>{user.fullName}</em>
            </Typography>
          </Grid>
          <Grid item>
            <CustomSwitch
              name={user.deliverer.id}
              checked={selectedDrivers.includes(
                parseInt(user.deliverer.id, 10)
              )}
              data-testid="core-drivers-user-switch"
              onChange={e => {
                handleChange(
                  e.target.checked,
                  parseInt(user.deliverer.id, 10),
                  'driver'
                );
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider variant="middle" />
    </Box>
  );
}

DriverItemList.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectedDrivers: PropTypes.arrayOf(PropTypes.number).isRequired,
  user: PropTypes.shape({
    deliverer: PropTypes.shape({
      id: PropTypes.string,
      profilePictureThumbnailUrl: PropTypes.string
    }),
    fullName: PropTypes.string,
    avatarSource: PropTypes.string
  }).isRequired
};

CirclesUsersDrawerComponent.defaultProps = {
  open: true
};
