export const TEXTS = {
  CLEAR: 'Limpar',
  APPLY_FILTERS: 'Aplicar datas'
};

export const INITIAL_DATE_RANGE = [null, null];

export const TIME = {
  START_DAY: { hour: '00', minute: '00', second: '00' },
  END_DAY: { hour: 23, minute: 59, second: 59 }
};
