import React from 'react';
import { Box, Typography } from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { formatNumber } from 'shared/formatters';
import TEXT_PACKAGES from './constants';
import useStyles from './card-aggregation.styles';

function CardComponent({ countPackages, handleNextPage }) {
  const classes = useStyles();

  return (
    <>
      <Box mt={3} mb={3}>
        <Typography variant="h6">
          <em>Entregadores</em>
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        maxHeight={218}
        maxWidth={232}
        width={232}
        p={3}
        border={1}
        borderColor={colors.smoke[100]}
        borderRadius={8}
        className={classes.hoverBox}
        onClick={() => handleNextPage('na-rua/em-grupos')}
      >
        <Box mb={10}>
          <Typography variant="h2">
            <em>{formatNumber(countPackages)}</em>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">{TEXT_PACKAGES.LABEL}</Typography>
          <ChevronRightIcon color="primary" />
        </Box>
      </Box>
    </>
  );
}

CardComponent.propTypes = {
  countPackages: PropTypes.number.isRequired,
  handleNextPage: PropTypes.func.isRequired
};

export default CardComponent;
