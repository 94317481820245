import React, { useCallback, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { usePagination, ITEMS_PER_PAGE_THIRTY } from 'shared/pagination';
import showSnackbar from 'shared/snackbar';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import trackingService, {
  fetchGroupByUnitLoad
} from 'tracking/tracking.service';
import { SUMMARY, KEY_CUSTODY } from 'tracking/tracking.constants';
import DrawerGroupContainer from 'tracking/configurable-table-collapsible/drawer-group/drawer-group.container';
import DrawerGroupPackagesContainer from 'tracking/drawer-group-packages/drawer-group-packages.container';
import { SEPARATION_CONTEXT } from 'tracking/drawer-group-packages/drawer-group-packages.constants';
import { getCompanyType } from 'auth/access-control/access-control.service';

import BaseContext from 'tracking/in-base/base.context';
import {
  TEXT_ATTENTION,
  TEXT_DEADLINE,
  TEXT_POSITION
} from 'tracking/in-base/base-collection/base-collection.constants';

import BaseListHeaderComponent from './base-list-header.component';
import {
  ORDER_BY_LIST,
  ORDER_BY_LIST_DELIVERY_DEADLINE_NEW,
  VIEW_TYPES
} from './base-list.configuration';
import BaseListComponent from './base-list.component';

const DRAWERS = {
  GROUPING: 'GROUPING',
  DETAIL: 'DETAIL'
};

function BaseListContainer({ shouldForceGroup }) {
  const [loading, setLoading] = useState(true);
  const [totalPackages, setTotalPackages] = useState(0);
  const [isCollapsitable, setIsCollapsitable] = useState(shouldForceGroup);
  const [packages, setPackages] = useState([]);
  let { custody } = useParams();
  custody = custody?.replace('hidden/', '');
  const { enqueueSnackbar } = useSnackbar();
  const [currentDrawer, setCurrentDrawer] = useState('');
  const [lastCreatedUnitLoadData, setLastCreatedUnitLoadData] = useState({});
  const {
    filters,
    aggregationDeadline,
    aggregationAttention,
    aggregationPosition
  } = useContext(BaseContext);

  const history = useHistory();

  const enableCreateAwaitingResolutionPromisedDateInBaseTable =
    getCompanyType() === 'LEVE';

  let ORDER_BY_TABLE = ORDER_BY_LIST[0];
  if (enableCreateAwaitingResolutionPromisedDateInBaseTable) {
    [ORDER_BY_TABLE] = ORDER_BY_LIST_DELIVERY_DEADLINE_NEW;
  }
  const [orderBy, setOrderBy] = useState(ORDER_BY_TABLE);

  const [
    pagination,
    changePage,
    setNumberOfItems,
    resetPagination,
    setCurrentPage
  ] = usePagination();

  const resetBaseListData = () => {
    setLoading(true);
  };

  const changePagination = page => {
    changePage(page);
    setLoading(true);
  };

  const onOrderChange = item => {
    setOrderBy(item);
    resetPagination();
    setLoading(true);
  };

  const routingCode = getSelectedRoutingCode();

  const onViewChange = view => {
    resetPagination();
    setLoading(true);

    history.push(`/${routingCode}/${view}`);
  };

  const onCloseGroupPackagesDrawer = (
    licensePlate,
    quantityPackages,
    keywords
  ) => {
    if (licensePlate) {
      setLastCreatedUnitLoadData({
        quantityPackages,
        licensePlate,
        keywords
      });
      setCurrentDrawer(DRAWERS.DETAIL);
    } else setCurrentDrawer('');
  };

  const selectView = (isAttentionView, isDeadLineView) => {
    if (isAttentionView) {
      return ['attention', TEXT_ATTENTION, aggregationAttention];
    }
    if (isDeadLineView) {
      return ['deadline', TEXT_DEADLINE, aggregationDeadline];
    }
    return ['position', TEXT_POSITION, aggregationPosition];
  };

  const getSelectViewListOptions = () => {
    const isAttentionView = VIEW_TYPES.attention.some(attentionItem => {
      if (attentionItem.custody === KEY_CUSTODY.NA_BASE_AGUARDANDO_TRATATIVA) {
        return false;
      }
      return attentionItem.custody === custody;
    });

    const isDeadLineView = VIEW_TYPES.deadline.some(
      deadlineItem => deadlineItem.custody === custody
    );

    const [viewType, viewTitle, aggregation] = selectView(
      isAttentionView,
      isDeadLineView
    );

    if (!aggregation) return [];

    const getAggregationWithPackages = aggregation
      .filter(({ value }) => value > 0)
      .map(({ label }) => viewTitle[label]);

    return VIEW_TYPES[viewType].filter(({ label }) =>
      getAggregationWithPackages.includes(label)
    );
  };

  const selectViewOptions = getSelectViewListOptions();

  const selectedView = selectViewOptions.find(
    viewItem => viewItem.custody === custody
  );

  const callTrackingService = useCallback(() => {
    const _filters = { ...filters };
    if (custody === KEY_CUSTODY.NA_BASE_AGUARDANDO_TRATATIVA) {
      _filters.status = [];
    }
    return trackingService({
      custody,
      page: pagination.currentPage,
      itemsPerPage: ITEMS_PER_PAGE_THIRTY,
      orderBy: orderBy.sortBy,
      orderDirection: orderBy.orderDirection,
      filters: _filters
    })
      .then(response => {
        const { value } = response.hits.total;
        setNumberOfItems(value);
        setPackages(response.hits.hits);
        setTotalPackages(value);
      })
      .catch(() => {
        showSnackbar({
          message: SUMMARY.EMPTY_PACKAGE_DEFAULT,
          enqueueSnackbar,
          withButton: true,
          variant: 'error'
        });
      })
      .finally(() => setLoading(false));
  }, [
    enqueueSnackbar,
    filters,
    orderBy.orderDirection,
    orderBy.sortBy,
    pagination.currentPage,
    setNumberOfItems,
    custody
  ]);

  const handleClickCollapsible = (licensePlate, page) => {
    return trackingService({
      custody,
      page,
      itemsPerPage: ITEMS_PER_PAGE_THIRTY,
      orderBy: orderBy.sortBy,
      orderDirection: orderBy.orderDirection,
      filters: {
        ...filters,
        licensePlate,
        unitLoad: 'LICENSE_PLATE'
      }
    })
      .then(response => response.hits)
      .catch(() => {
        showSnackbar({
          message: SUMMARY.EMPTY_PACKAGE_DEFAULT,
          variant: 'error',
          enqueueSnackbar
        });
      });
  };

  const callFetchGroupByUnitLoad = useCallback(() => {
    return fetchGroupByUnitLoad(
      custody,
      pagination.currentPage,
      ITEMS_PER_PAGE_THIRTY,
      orderBy.sortBy,
      orderBy.secondOrderBy,
      filters
    )
      .then(cleanData => {
        setNumberOfItems(cleanData.totalUnitLoads);
        setTotalPackages(cleanData.totalPackages);
        setPackages(cleanData.data);
        setLoading(false);
      })
      .catch(() => {
        showSnackbar({
          message: SUMMARY.EMPTY_PACKAGE_DEFAULT,
          variant: 'error',
          enqueueSnackbar
        });
      })
      .finally(() => setLoading(false));
  }, [
    enqueueSnackbar,
    orderBy.secondOrderBy,
    orderBy.sortBy,
    pagination.currentPage,
    setNumberOfItems,
    filters,
    custody
  ]);

  useEffect(() => {
    if (loading) {
      return isCollapsitable
        ? callFetchGroupByUnitLoad()
        : callTrackingService();
    }
    return null;
  }, [loading, isCollapsitable, callFetchGroupByUnitLoad, callTrackingService]);

  return (
    <>
      <BaseListHeaderComponent
        setIsCollapsitable={setIsCollapsitable}
        isCollapsitable={isCollapsitable}
        setLoading={setLoading}
        totalPackages={totalPackages}
        loading={loading}
        setCurrentDrawer={setCurrentDrawer}
      />
      <BaseListComponent
        onOrderChange={onOrderChange}
        onViewChange={onViewChange}
        orderBy={orderBy}
        loading={loading}
        packages={packages}
        changePagination={changePagination}
        currentPage={pagination.currentPage}
        numberOfPages={pagination.numberOfPages}
        setCurrentPage={setCurrentPage}
        resetBaseListData={resetBaseListData}
        selectViewOptions={selectViewOptions}
        selectedView={selectedView}
        handleClickCollapsible={handleClickCollapsible}
        isCollapsitable={isCollapsitable}
        callFetchGroupByUnitLoad={callFetchGroupByUnitLoad}
        shouldUseActions={shouldForceGroup}
      />
      {currentDrawer === DRAWERS.GROUPING && (
        <DrawerGroupPackagesContainer
          routingCode={routingCode}
          destinationContext={SEPARATION_CONTEXT}
          handleClosingDrawer={onCloseGroupPackagesDrawer}
        />
      )}
      {currentDrawer === DRAWERS.DETAIL && (
        <DrawerGroupContainer
          onCloseDrawer={() => setCurrentDrawer('')}
          dataGroup={lastCreatedUnitLoadData}
          callFetchGroupByUnitLoad={callFetchGroupByUnitLoad}
        />
      )}
    </>
  );
}

BaseListContainer.propTypes = {
  shouldForceGroup: PropTypes.bool
};

BaseListContainer.defaultProps = {
  shouldForceGroup: false
};

export default BaseListContainer;
