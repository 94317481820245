import {
  promisedDate,
  city,
  updatedAt,
  status
} from '../../packages/configuration';

const ORDER_BY_VIEW_DELIVERER_PACKAGES = [
  promisedDate,
  city,
  updatedAt,
  status
];

export default ORDER_BY_VIEW_DELIVERER_PACKAGES;
