import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import {
  AmplifyAuthProvider,
  AmplifyConfigure
} from '@loggi/authentication-lib';

import * as serviceWorker from 'infra/browser/serviceWorker';
import 'infra/service/sentry-init';
import awsmobile from 'aws-config';

import { CredentialsProvider } from 'auth/login';
import { FeatureSwitchProvider } from 'infra/firebase/feature-switch-provider';

import App from './view';
import 'infra/firebase/firebase.init';
import 'i18n-config';

AmplifyConfigure(awsmobile);

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <AmplifyAuthProvider>
    <BrowserRouter>
      <FeatureSwitchProvider>
        <CredentialsProvider>
          <App />
        </CredentialsProvider>
      </FeatureSwitchProvider>
    </BrowserRouter>
  </AmplifyAuthProvider>,
  document.getElementById('root')
);

// ========================================
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
