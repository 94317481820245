function hotjarIdentifyScript(userSub, userInfo) {
  const hasWindow = typeof window !== 'undefined';

  if (hasWindow && window.hj) {
    return window.hj('identify', userSub, userInfo);
  }

  throw Error('Hotjar is not available! Is Hotjar initialized?');
}
function transformCognitoInfoToMap(
  authenticatedUser,
  lmcType,
  lmc,
  dc,
  userRole = null
) {
  return {
    email: authenticatedUser?.email,
    lastMileCompanyType: lmcType,
    lastMileCompany: lmc,
    distributionCenter: dc,
    userRole
  };
}

export { hotjarIdentifyScript, transformCognitoInfoToMap };
