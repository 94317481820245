import React, { Children } from 'react';
import PropTypes from 'prop-types';

// @MATERIAL-UI
import {
  Box,
  Container,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core';

import Content from './content.component';
import Summary from './summary.component';
import { ERRORS } from './template.constants';

/**
 * This method receives the children param from a component and filter them
 * based on the component Class provided
 * @param children
 * @param Component
 * @returns {React.ReactChild[]}
 */
const filterChildren = (children, Component) =>
  Children.toArray(children).filter(({ type }) => type === Component);

/**
 * This component filter its child nodes, using only supported ones and
 * placing them into specific regions of the template.
 * @param children - Only a single <Summary /> and one or
 * more <Content /> child nodes
 * @returns {*}
 * @constructor
 */
const Template = ({ children }) => {
  const summary = filterChildren(children, Summary);
  const content = filterChildren(children, Content);

  if (summary.length === 0) throw new Error(ERRORS.NO_SUMMARY);
  if (summary.length > 1) throw new Error(ERRORS.MULTIPLE_SUMMARIES);
  if (content.length === 0) throw new Error(ERRORS.NO_CONTENT);
  if (content.length > 1) throw new Error(ERRORS.MULTIPLE_CONTENTS);

  Children.forEach(children, ({ type }) => {
    if (type !== Content && type !== Summary) {
      throw new Error(ERRORS.UNSUPPORTED_CHILD);
    }
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box data-testid="layout-arco-2">
      {summary}
      <Container
        component={Box}
        mt={-5.5}
        style={isSmallScreen ? { padding: 0 } : {}}
      >
        <Grid container justify="center">
          {content}
        </Grid>
      </Container>
    </Box>
  );
};

Template.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Template;
