import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ReactComponent as IconOffer } from 'images/ic-offer.svg';
import CardIcon from 'shared/card-icon';

import { TEXT_CARD } from 'offer/offer.constants';

export default function OfferAggregationComponent({
  lateTotal,
  forNowTotal,
  forTodayTotal,
  waitingAcceptanceTotal,
  waitingPickupTotal
}) {
  return (
    <CardIcon variant="primary" icon={IconOffer}>
      <Grid container spacing={10}>
        <Grid item>
          <Typography data-testid="late-total">
            <strong>{lateTotal}</strong>
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <em>{TEXT_CARD.SCHEDULING_LATE}</em>
          </Typography>
        </Grid>
        <Grid item>
          <Typography data-testid="for-now-total">
            <strong>{forNowTotal}</strong>
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <em>{TEXT_CARD.SCHEDULING_FOR_NOW}</em>
          </Typography>
        </Grid>
        <Grid item>
          <Typography data-testid="for-today-total">
            <strong>{forTodayTotal}</strong>
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <em>{TEXT_CARD.SCHEDULING_FOR_TODAY}</em>
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <Typography data-testid="waiting-acceptance-total">
            <strong>{waitingAcceptanceTotal}</strong>
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <em>{TEXT_CARD.ACTIVE_WAITING_ACCEPTANCE}</em>
          </Typography>
        </Grid>
        <Grid item>
          <Typography data-testid="waiting-pickup-total">
            <strong>{waitingPickupTotal}</strong>
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <em>{TEXT_CARD.ACTIVE_WAITING_PICKUP}</em>
          </Typography>
        </Grid>
      </Grid>
    </CardIcon>
  );
}

OfferAggregationComponent.SkeletonVersion = () => {
  return (
    <CardIcon variant="primary" icon={IconOffer}>
      <Grid container spacing={10}>
        <Grid item>
          <Skeleton variant="rect" height={48} width={100} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={48} width={100} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={48} width={100} />
        </Grid>
        <Grid item xs={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={48} width={100} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" height={48} width={100} />
        </Grid>
      </Grid>
    </CardIcon>
  );
};

OfferAggregationComponent.propTypes = {
  lateTotal: PropTypes.number.isRequired,
  forNowTotal: PropTypes.number.isRequired,
  forTodayTotal: PropTypes.number.isRequired,
  waitingAcceptanceTotal: PropTypes.number.isRequired,
  waitingPickupTotal: PropTypes.number.isRequired
};
