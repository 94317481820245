import api from 'infra/api/leve';
import { getCompanyType } from 'auth/access-control/access-control.service';
import { getSelectedBase } from 'profile/profile.service';
import { translateStatusCode, DEFAULT_MESSAGE } from '../httpMessage';

export const CUSTOM_ERROR_MESSAGES = {
  404: 'Eita, não encontramos cidades. Tente de novo.',
  500: 'Eita, algo deu errado. Tente de novo daqui a pouco ou atualize a página. [000-Error-500]'
};

const getAggregationsCities = async () => {
  const base = getSelectedBase();

  return api
    .post('/api/v2/last_mile/packages/aggregated', {
      view: 'annotation_for_base',
      routing_code: base.distribution_center.routing_code,
      viewFilterBy:
        'last_last_mile_promised_date_annotation.annotation.last_mile_promised_date.promised_date.promised_date',
      aggregatedField: 'city_enum',
      lastMileCompanyType: getCompanyType(),
      searchArgument: ''
    })
    .then(_data => _data.data?.data || [])
    .catch(error => {
      throw new Error(
        error.response
          ? translateStatusCode(error.response.status, CUSTOM_ERROR_MESSAGES)
          : DEFAULT_MESSAGE
      );
    });
};

export default getAggregationsCities;
