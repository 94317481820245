import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import showSnackbar from 'shared/snackbar';

import offerDetailService, {
  serviceCancelOffer,
  deallocateDriver
} from './drawer.service';
import DrawerComponent from './drawer.component';
import TEXT from './drawer.constants';

export default function DrawerContainer({
  handleClosingDrawer,
  offerId,
  setOfferChangedState
}) {
  const [pickupOfferDetail, setPickupOfferDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    (async () => {
      setPickupOfferDetail(await offerDetailService(offerId));
      setLoading(false);
    })();
  }, [offerId]);

  const callbackServiceCancelOffer = () => {
    const { driverId } = pickupOfferDetail;
    serviceCancelOffer(driverId, offerId)
      .then(() => {
        setOfferChangedState(_ => !_);
        showSnackbar({
          message: TEXT.MESSAGE_SUCCESS_CANCEL_OFFER,
          enqueueSnackbar,
          withButton: true,
          variant: 'success'
        });
      })
      .catch(() => {
        showSnackbar({
          message: TEXT.MESSAGE_ERROR_CANCEL_OFFER,
          enqueueSnackbar,
          withButton: true,
          variant: 'error'
        });
      });
  };

  const callbackServiceDeallocateOffer = () => {
    deallocateDriver(offerId)
      .then(() => {
        setOfferChangedState(_ => !_);
        showSnackbar({
          message: TEXT.MESSAGE_SUCCESS_DEALLOCATED_DRIVER,
          enqueueSnackbar,
          withButton: true,
          variant: 'success'
        });
      })
      .catch(() => {
        showSnackbar({
          message: TEXT.MESSAGE_ERROR_CANCEL_OFFER,
          enqueueSnackbar,
          withButton: true,
          variant: 'error'
        });
      });
  };

  return (
    <DrawerComponent
      handleClosingDrawer={handleClosingDrawer}
      loading={loading}
      pickupOfferDetail={pickupOfferDetail}
      callbackCancelOffer={callbackServiceCancelOffer}
      callbackDeallocateOffer={callbackServiceDeallocateOffer}
      offerId={offerId}
    />
  );
}

DrawerContainer.propTypes = {
  handleClosingDrawer: PropTypes.func.isRequired,
  offerId: PropTypes.string.isRequired,
  setOfferChangedState: PropTypes.func.isRequired
};
