import React, { useState } from 'react';
import { HeaderDrawer } from 'view/molecules/package-drawer/drawer.container';
import PropTypes from 'prop-types';
import { Box, Drawer } from '@material-ui/core';
import ActivityInfoContainer from './activity-info.container';

export default function DrawerContainer({ handleOpenDrawer, packageCharge }) {
  const [isEnabledGoBack, setIsEnabledGoBack] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <Drawer
      anchor="right"
      open
      data-testid="drawer-finance"
      variant="persistent"
    >
      <Box
        maxWidth={480}
        width={480}
        role="presentation"
        data-testid="backgound-drawer"
        overflow="auto"
      >
        <HeaderDrawer
          isEnableClose={!isEnabledGoBack}
          hideIcon={false}
          isLoading={loading}
          isEnableGoBack={isEnabledGoBack}
          onClose={() => handleOpenDrawer()}
          onGoBack={() => setIsEnabledGoBack(false)}
        />
        <ActivityInfoContainer
          isPackageHistoryEnabled={isEnabledGoBack}
          onHistory={() => setIsEnabledGoBack(true)}
          packageCharge={packageCharge}
          onError={() => handleOpenDrawer()}
          loading={loading}
          setLoading={setLoading}
        />
      </Box>
    </Drawer>
  );
}

DrawerContainer.propTypes = {
  handleOpenDrawer: PropTypes.func.isRequired,
  packageCharge: PropTypes.shape().isRequired
};
