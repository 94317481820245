import api from 'infra/api/leve';
import { URL_API_V1_LAST_MILE_PREFIX } from 'infra/service/constants';
import { ITEMS_PER_PAGE_THIRTY } from 'shared/pagination';

function currentTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const offersService = async (pageNumber, filters = {}) => {
  return api
    .post(`${URL_API_V1_LAST_MILE_PREFIX}/offers/list`, {
      pageNumber,
      itemsPerPage: ITEMS_PER_PAGE_THIRTY,
      timezone: currentTimezone(),
      ...filters
    })
    .then(_data => {
      return _data.data;
    });
};

export default offersService;

export const offerAggregationService = async (status, timeIntervals) => {
  const _data = await api.post(
    `${URL_API_V1_LAST_MILE_PREFIX}/offers/summary`,
    {
      status,
      timeIntervals,
      timezone: currentTimezone()
    }
  );
  return _data.data;
};
