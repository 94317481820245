import React from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import { colors } from '@loggi/mar';

import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { DEMAND_REGIONS_INITIAL_DRAWER } from '../demand-regions.constants';

const SimulationInProgress = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={8}
      p={1}
      data-testid="simulation-in-progress"
      bgcolor={colors.blue[50]}
    >
      <IconButton variant="outlined">
        <WatchLaterIcon color="primary" />
      </IconButton>
      <Typography>
        {DEMAND_REGIONS_INITIAL_DRAWER.SIMULATION_IN_PROGRESS}
      </Typography>
    </Box>
  );
};

export default SimulationInProgress;
