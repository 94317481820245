import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { RANGE_FILTER_KEY } from 'offer/offer.constants';

import { getTotalFor } from '../offer.utils';
import { offerAggregationService } from '../offer.service';
import OfferTodayCardComponent from './offer-today-card.component';

const TIME_INTERVALS = [
  RANGE_FILTER_KEY.SCHEDULING_LATE,
  RANGE_FILTER_KEY.SCHEDULING_FOR_NOW,
  RANGE_FILTER_KEY.SCHEDULING_FOR_TODAY,
  RANGE_FILTER_KEY.ACTIVE_WAITING_ACCEPTANCE,
  RANGE_FILTER_KEY.ACTIVE_WAITING_PICKUP
];

export default function OfferTodayCardContainer({ goToOfferViewPressed }) {
  const [loading, setLoading] = useState(true);
  const [scheduleTotal, setScheduleTotal] = useState(0);
  const [lateTotal, setLateTotal] = useState(0);
  const [forNowTotal, setForNowTotal] = useState(0);
  const [activeTotal, setActiveTotal] = useState(0);
  const [waitingAcceptanceTotal, setWaitingAcceptanceTotal] = useState(0);
  const [waitingPickupTotal, setWaitingPickupTotal] = useState(0);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const aggregationResult = await offerAggregationService(
        [],
        TIME_INTERVALS
      );

      const late = getTotalFor(
        RANGE_FILTER_KEY.SCHEDULING_LATE,
        aggregationResult
      );
      const now = getTotalFor(
        RANGE_FILTER_KEY.SCHEDULING_FOR_NOW,
        aggregationResult
      );
      setLateTotal(late);
      setForNowTotal(now);
      setScheduleTotal(late + now);

      const acceptance = getTotalFor(
        RANGE_FILTER_KEY.ACTIVE_WAITING_ACCEPTANCE,
        aggregationResult
      );
      const pickup = getTotalFor(
        RANGE_FILTER_KEY.ACTIVE_WAITING_PICKUP,
        aggregationResult
      );

      setWaitingAcceptanceTotal(acceptance);
      setWaitingPickupTotal(pickup);
      setActiveTotal(acceptance + pickup);

      setLoading(false);
    })();
  }, []);

  return (
    <>
      {!loading && (
        <OfferTodayCardComponent
          goToOfferViewPressed={goToOfferViewPressed}
          scheduleTotal={scheduleTotal}
          lateTotal={lateTotal}
          forNowTotal={forNowTotal}
          activeTotal={activeTotal}
          waitingAcceptanceTotal={waitingAcceptanceTotal}
          waitingPickupTotal={waitingPickupTotal}
        />
      )}
      {loading && (
        <OfferTodayCardComponent.SkeletonVersion
          goToOfferViewPressed={goToOfferViewPressed}
        />
      )}
    </>
  );
}

OfferTodayCardContainer.propTypes = {
  goToOfferViewPressed: PropTypes.func.isRequired
};
