import React from 'react';
import moment from 'moment';
import { get } from 'tracking/tracking.configuration';

import DeltaArrivalTimeMinutes from 'tracking/arrivals/delta-arrival-time';
import { CALENDAR_FORMAT } from '../arrivals.constants';

export const formatDate = item => {
  return moment(item)
    .locale('pt-BR')
    .calendar(null, CALENDAR_FORMAT);
};

export const sumDeltaTime = (expectedArrivalTime, delta) => {
  const sum = moment(expectedArrivalTime).add(delta, 'm');
  return formatDate(sum);
};

export const vehicleTypeMap = vehicleType => {
  const map = {
    VEHICLE_TYPE_INVALID: 'Desconhecido',
    VEHICLE_TYPE_VAN: 'Van',
    VEHICLE_TYPE_TOCO: 'Toco',
    VEHICLE_TYPE_TRUCK: 'Caminhão'
  };
  return map[vehicleType] || map.VEHICLE_TYPE_INVALID;
};

export const formatVehicle = (
  vehicleType,
  vehicleLicensePlate,
  vehicleLowerCase = false
) => {
  const vehicle = vehicleTypeMap(vehicleType);
  return `${
    vehicleLowerCase ? vehicle.toLowerCase() : vehicle
  } • ${vehicleLicensePlate || ''}`;
};

const expectedArrivalTimeFormated = {
  header: 'Chegada estimada',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => (
    <DeltaArrivalTimeMinutes
      expectedArrivalTime={item?.expectedArrivalTime}
      deltaArrivalTime={item?.deltaArrivalTimeMinutes}
    />
  )
};

const vehicle = {
  header: 'Veículo',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => formatVehicle(item?.vehicleType, item?.vehicleLicensePlate)
};

// On hold until we validate the viability
// const singlePackages = {
//     header: 'Pacotes avulsos',
//     headAlign: 'left',
//     propsStyleBody: { align: 'left' },
//     component: item => get(item, 'singlePackages', '-')
// };

const numBags = {
  header: 'Grupos',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, 'totalBags', '-')
};

const numPackages = {
  header: 'Pacotes totais',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, 'totalPackages', '-')
};

const originDCName = {
  header: 'Origem',
  headAlign: 'left',
  propsStyleBody: { align: 'left' },
  component: item => get(item, 'origin.name', '-')
};

export default {
  expectedArrivalTimeFormated,
  vehicle,
  numBags,
  numPackages,
  originDCName
};
