import React from 'react';
import { Box, Grid, Paper } from '@material-ui/core';

import PropTypes from 'prop-types';

import AlertStatusHeader from 'shared/alert-status-header';

import { useStyles } from './card-icon.styles';

const CardIcon = ({
  icon: Icon,
  children,
  variant,
  size,
  alignItems,
  showAlertStatusHeaderWhenUnstable,
  alertStatusHeaderText
}) => {
  const classes = useStyles();
  const classSize = classes[`size${size}`];
  const classVariant = classes[`variant${variant}`];

  return (
    <Paper
      className={classes.paper}
      component={Box}
      marginBottom={5}
      flexGrow={1}
      elevation={10}
    >
      {showAlertStatusHeaderWhenUnstable && (
        <AlertStatusHeader
          textPrimary={alertStatusHeaderText.PRIMARY}
          textSecondary={alertStatusHeaderText.SECONDARY}
        />
      )}
      <Box p={5.5} minHeight={44}>
        <Grid
          container
          spacing={3}
          data-testid="card-icon"
          alignItems={alignItems}
        >
          <Grid item>
            <Box
              className={`${classes.iconStyle} ${classSize} ${classVariant}`}
            >
              <Icon />
            </Box>
          </Grid>

          <Grid item xs>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

CardIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
  alignItems: PropTypes.string,
  showAlertStatusHeaderWhenUnstable: PropTypes.bool,
  alertStatusHeaderText: PropTypes.shape({
    PRIMARY: PropTypes.string,
    SECONDARY: PropTypes.string
  })
};

CardIcon.defaultProps = {
  variant: 'default',
  size: 'normal',
  alignItems: 'flex-start',
  showAlertStatusHeaderWhenUnstable: false,
  alertStatusHeaderText: {
    PRIMARY: '',
    SECONDARY: ''
  }
};

export default CardIcon;
