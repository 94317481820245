import { Machine, assign, sendParent } from 'xstate';
import getDeliverers from 'app/get-deliverers';
import changeDelivererServer from './drawer-change-deliverer.service';
import { URL_GTM_GA_CHANGE_DELIVERER_DONE } from './drawer-change-deliverer.constants';

export const STATES = {
  FETCHING: 'fetchingDeliverers',
  FETCH_FAILURE: 'failureFetch',
  SELECTING: 'selectingDeliverer',
  SUBMITTING: 'submittingMovePackage',
  FEEDBACK_DIALOG: 'feedbackDialog'
};

export const ACTIONS = {
  SELECT_DELIVERER: 'selectDeliverer',
  RELOAD_FETCH: 'reloadGetDeliverers',
  RESTORE_LIST: 'restoreListDeliverers',
  SEARCH_INPUT: 'searchInputValue',
  UNDERSTOOD: 'understoodDialog',
  CONTINUE_BUTTON: 'continueButton',
  SET_NOTIFICATION: 'setNotification',
  RELOAD_PAGE: 'reloadPage',
  HIDE_ICON: 'hideIcon',
  GOOGLE_ANALITYCS: 'googleAnalitycs'
};

const changeDelivererMachine = Machine(
  {
    id: 'changeDeliverer',
    initial: 'fetchingDeliverers',
    context: {
      packageId: null,
      deliverers: null,
      deliverersDisplayed: null,
      barcode: null,
      selectedDelivererId: null,
      selectedDelivererFullName: null,
      searchValue: '',
      openDialog: false
    },
    states: {
      [STATES.FETCHING]: {
        invoke: {
          id: 'getDeliverers',
          src: () => getDeliverers(),
          onDone: {
            target: STATES.SELECTING,
            actions: [
              assign({ deliverers: (_, event) => event.data }),
              assign({ deliverersDisplayed: (_, event) => event.data })
            ]
          },
          onError: STATES.FETCH_FAILURE
        }
      },
      [STATES.FETCH_FAILURE]: {
        on: {
          [ACTIONS.RELOAD_FETCH]: STATES.FETCHING
        }
      },
      [STATES.SELECTING]: {
        entry: 'sortDeliverers',
        on: {
          [ACTIONS.SELECT_DELIVERER]: {
            actions: assign({
              selectedDelivererId: (_, event) => event.id,
              selectedDelivererFullName: (_, event) => event.fullName
            })
          },
          [ACTIONS.SEARCH_INPUT]: {
            actions: [
              assign({ searchValue: (_, event) => event.value }),
              'searchDeliverer'
            ]
          },
          [ACTIONS.RESTORE_LIST]: {
            actions: assign({
              deliverersDisplayed: context => context.deliverers,
              searchValue: ''
            })
          },
          [ACTIONS.CONTINUE_BUTTON]: STATES.SUBMITTING
        }
      },
      [STATES.SUBMITTING]: {
        entry: sendParent({
          type: ACTIONS.HIDE_ICON,
          value: true,
          to: 'drawer'
        }),
        exit: sendParent({
          type: ACTIONS.HIDE_ICON,
          value: false,
          to: 'drawer'
        }),
        invoke: {
          id: 'movePackageList',
          src: context =>
            changeDelivererServer({
              delivererId: context.selectedDelivererId,
              packageId: context.packageId
            }),
          onDone: {
            target: STATES.FEEDBACK_DIALOG,
            actions: [
              sendParent(() => ({
                type: ACTIONS.GOOGLE_ANALITYCS,
                data: {
                  url: URL_GTM_GA_CHANGE_DELIVERER_DONE
                },
                to: 'drawer'
              })),
              assign({ openDialog: true })
            ]
          },
          onError: {
            target: STATES.SELECTING,
            actions: [
              sendParent((_, event) => ({
                type: ACTIONS.SET_NOTIFICATION,
                data: {
                  message: event.data.message,
                  error: true
                },
                to: 'drawer'
              }))
            ]
          }
        }
      },
      [STATES.FEEDBACK_DIALOG]: {
        on: {
          [ACTIONS.UNDERSTOOD]: {
            actions: [
              assign({ openDialog: false }),
              sendParent(ACTIONS.RELOAD_PAGE, { to: 'drawer' })
            ]
          }
        }
      }
    }
  },
  {
    actions: {
      searchDeliverer: assign({
        deliverersDisplayed: context =>
          context.deliverers.filter(({ fullName }) =>
            fullName.toLowerCase().includes(context.searchValue.toLowerCase())
          )
      }),
      sortDeliverers: assign({
        deliverersDisplayed: context =>
          context.deliverers.sort((primaryDeliverer, NextDeliverer) =>
            primaryDeliverer.fullName
              .toLowerCase()
              .localeCompare(NextDeliverer.fullName.toLowerCase())
          )
      })
    }
  }
);
export default changeDelivererMachine;
