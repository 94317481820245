import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment-timezone';
import { TIME } from './date-range-picker.constants';

moment.locale('pt-BR');

const DateRangePickerUtilStartDate = date => {
  if (!date) return null;
  return moment(`${date.substring(0, 10)}T23:59:59`)
    .tz('America/Sao_Paulo')
    .set(TIME.START_DAY)
    .format();
};

const DateRangePickerUtilEndDate = date => {
  if (!date) return null;
  return moment(`${date.substring(0, 10)}T23:59:59`)
    .tz('America/Sao_Paulo')
    .set(TIME.END_DAY)
    .format();
};

export { DateRangePickerUtilStartDate, DateRangePickerUtilEndDate };
