import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import NotFound404Template from 'view/templates/not-found-404';
import { getSelectedRoutingCode } from 'auth/login/login.service';

function NotFound404({ history }) {
  const redirect = () => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}/hoje`);
  };
  return <NotFound404Template redirect={redirect} />;
}

NotFound404.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(NotFound404);
