import React from 'react';
import { Box, Typography } from '@material-ui/core/';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import PropTypes from 'prop-types';
import { MAPPER_STATUS } from 'shared/status-color-description/status-color-description.constants';
import { formatNumber } from 'shared/formatters';

export default function InformationBox({ chartData }) {
  const width = chartData.data.map(item => {
    return {
      percent: `${((item.value / chartData.total) * 100).toFixed()}%`,
      ...item
    };
  });
  return (
    <>
      {width.map(item => (
        <Box height={80} width="100%" key={item.id}>
          <Box mx={0.5}>
            <Typography color="textSecondary" variant="caption">
              <em>{item.label}</em>
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box
              key={item}
              borderRadius={15}
              height="8%"
              bgcolor={
                MAPPER_STATUS[item.id]?.style?.color ||
                MAPPER_STATUS['0']?.style?.color
              }
              mx={0.5}
              p={0.5}
              width={item.percent}
            />
            {item.hasMissedDate && (
              <StopRoundedIcon color="error" data-testid="missed-event-box" />
            )}
            <Typography variant="body2">
              <em>{formatNumber(item.value)}</em>
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
}

InformationBox.propTypes = {
  chartData: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.number,
        hasMissedDate: PropTypes.bool
      })
    )
  }).isRequired
};
