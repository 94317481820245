import React, { useState, useEffect, useContext } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import moment from 'moment';

import { useSnackbar } from 'notistack';
import showSnackbar from 'shared/snackbar';

import SummaryContext from 'tracking/template/summary.context';
import DateRangePicker, {
  tratementDateToElastic
} from 'tracking/date-range-picker';
import { TIME } from 'tracking/date-range-picker/date-range-picker.constants';

import getItinerariesService from './drivers-tracking.service';
import DriversTrackingListComponent from './drivers-tracking.component';

import { TEXTS } from './drivers-tracking.constants';
import { getGroupedStatus } from './drivers-tracking.utils';

const DriversTrackingContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setSummary } = useContext(SummaryContext);

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState();
  const [{ itineraries = [], pagination = {} }, setData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(
    tratementDateToElastic(moment().subtract(1, 'days'), TIME.START_DAY)
  );
  const [endDate, setEndDate] = useState(
    tratementDateToElastic(moment(), TIME.END_DAY)
  );

  useEffect(() => {
    getItinerariesService(currentPage, status, startDate, endDate)
      .then(setData)
      .catch(error =>
        showSnackbar({
          withButton: false,
          variant: 'error',
          message: error.message,
          enqueueSnackbar
        })
      )
      .finally(() => setLoading(false));
  }, [enqueueSnackbar, currentPage, status, startDate, endDate]);

  const changeDate = (startDateParam, endDateParam) => {
    setCurrentPage(1);
    setStartDate(startDateParam);
    setEndDate(endDateParam);
  };

  useEffect(() => {
    setSummary(
      () =>
        !loading && (
          <Typography variant="h4">
            <em>{TEXTS.SUMMARY_TITLE}</em>
            <Box mt={1}>
              {TEXTS.SUMMARY_SUBTITLE}
              <Box component="span" ml={2}>
                <DateRangePicker
                  handleTrackingService={changeDate}
                  initialStartDate={startDate}
                  initialEndDate={endDate}
                />
              </Box>
            </Box>
          </Typography>
        )
    );
  }, [setSummary, loading, startDate, endDate]);

  if (loading) {
    return (
      <Grid container justify="center" data-testid="drivers-tracking-loader">
        <CircularProgress justify="center" data-testid="circle-progress" />
      </Grid>
    );
  }

  const handleFilter = ({ status: statusFilter }) => {
    setCurrentPage(1);
    setStatus(getGroupedStatus(statusFilter));
  };

  const handlePagination = page => {
    setCurrentPage(page);
  };

  return (
    <DriversTrackingListComponent
      itineraries={itineraries}
      pagination={pagination}
      onFilter={handleFilter}
      onPageChange={handlePagination}
    />
  );
};

export default DriversTrackingContainer;
