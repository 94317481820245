import React from 'react';
import PropTypes from 'prop-types';
import { Box, DialogTitle } from '@material-ui/core';

export default function HeaderDialog({ children }) {
  return (
    <DialogTitle>
      <Box
        pt={2}
        mb={5}
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
        alignItems={{ xs: 'center', sm: 'flex-start' }}
      >
        {children}
      </Box>
    </DialogTitle>
  );
}

HeaderDialog.propTypes = {
  children: PropTypes.element.isRequired
};
