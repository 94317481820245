import React from 'react';
import { useRouteMatch, Redirect, Switch, Route } from 'react-router-dom';
import { AUTHORIZED_ROUTES } from 'view/constants';
import InStreetCollectionContainer from './collection';

import InStreetDeliverersListContainer from './collection/deliverers';
import DelivererListPackagesContainer from './collection/deliverers/deliverer_list_packages';
import InStreetPackagesListContainer from './collection/packages';

export default function InStreetRoutes() {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}${AUTHORIZED_ROUTES.INFORMATION.COLLECTION}/pacotes`}
        component={InStreetPackagesListContainer}
      />
      <Route
        path={`${path}${AUTHORIZED_ROUTES.INFORMATION.COLLECTION}/entregadores`}
        component={InStreetDeliverersListContainer}
      />

      <Route
        path={`${path}/entregador/:id`}
        component={DelivererListPackagesContainer}
      />

      <Route
        path={`${path}${AUTHORIZED_ROUTES.INFORMATION.COLLECTION}`}
        component={InStreetCollectionContainer}
      />

      <Redirect
        to={{
          pathname: `${url}${AUTHORIZED_ROUTES.INFORMATION.COLLECTION}`
        }}
      />
    </Switch>
  );
}
