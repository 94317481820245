import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(({ spacing }) => ({
  fab: {
    '&.MuiFab-root': {
      width: '22px',
      height: '22px',
      minHeight: '22px'
    }
  },
  icon: {
    '&.MuiSvgIcon-root': {
      width: '10px',
      height: '10px'
    }
  },
  item: {
    paddingLeft: spacing(3.5),
    paddingRight: 0,
    paddingTop: '20px',
    paddingBottom: '20px',
    margin: 0,
    display: 'flex',
    alignItems: 'center'
  },
  itemSelected: {
    borderLeft: `3px solid ${colors.blue[500]}`
  },
  itemNotSelected: {
    borderLeft: '3px solid transparent',
    '&:hover': {
      backgroundColor: colors.blue[100]
    }
  },
  listSubHeader: {
    backgroundColor: colors.root[0]
  },
  collapseButton: {
    padding: 0,
    minHeight: 0,
    minWidth: 0
  }
}));

export default useStyles;
