const CREATE = {
  TITLE: 'Convidar pessoa',
  DETAIL:
    'Informe os dados de quem vai utilizar o Arco e o aplicativo de tarefas na base.',
  SUBMIT_BUTTON: 'Convidar',
  SUBMIT_LOADING: 'Enviando...',
  SUCCESS: email =>
    `Tudo certo! ${email} receberá um e-mail para realizar o cadastro.`
};

const UPDATE = {
  TITLE: 'Editar pessoa',
  DETAIL: '',
  SUBMIT_BUTTON: 'Salvar',
  SUBMIT_LOADING: 'Salvando...',
  SUCCESS: 'Tudo certo! Edição salva'
};

const FULLNAME_LABEL = 'Nome completo';
const FULLNAME_ERROR = 'Digite o nome completo.';
const EMAIL_LABEL = 'E-mail';
const EMAIL_ERROR = 'Digite um e-mail válido.';
const ROLE_LABEL = 'Níveis de acesso';

export default {
  CREATE,
  UPDATE,
  EMAIL_ERROR,
  EMAIL_LABEL,
  FULLNAME_ERROR,
  FULLNAME_LABEL,
  ROLE_LABEL
};
