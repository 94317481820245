import React, { Children, useState } from 'react';
import PropTypes from 'prop-types';
import { Popper, Box, Typography, Hidden } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useStyles from 'profile/profile.style';
import { FLOWS, ERRORS } from 'profile/profile.constants';
import PersonIcon from '@material-ui/icons/Person';

const ProfileComponent = ({ children, dcAbbrev, setProfileState }) => {
  const classes = useStyles();

  if (Children.toArray(children).length > 1)
    throw new Error(ERRORS.MORE_THAN_ONE_CHILD);
  if (Children.toArray(children).length === 0)
    throw new Error(ERRORS.LESS_THAN_ONE_CHILD);

  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [changeButton, setChangeButton] = useState('null');

  const handleClose = () => {
    setAnchorElProfile(false);
    setChangeButton(null);
  };

  let myTimer;
  const handleAwait = () => {
    myTimer = setTimeout(() => {
      handleClose();
    }, 50);
  };

  const handleClick = (event, newChangeButton) => {
    setAnchorElProfile(anchorElProfile ? null : event.currentTarget);
    setChangeButton(newChangeButton);
    setProfileState(FLOWS.PROFILE_INITIAL);
  };

  const open = Boolean(anchorElProfile);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <Hidden xsDown data-testid="should-show-in-desktop-profile-component">
        <ToggleButtonGroup
          className={classes.root}
          exclusive
          value={changeButton}
          onChange={handleClick}
          onMouseLeave={handleAwait}
        >
          <ToggleButton
            data-testid="profile"
            className={classes.root}
            value="changeButton"
          >
            <Typography component="span" variant="body1">
              <Box
                width="60px"
                data-testid="code"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {!!dcAbbrev && <em>{dcAbbrev}</em>}
                {!dcAbbrev && <PersonIcon />}
                {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Box>
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Hidden>
      <Hidden smUp data-testid="should-show-in-mobile-profile-component">
        <ToggleButtonGroup
          exclusive
          value={changeButton}
          onChange={handleClick}
          onMouseLeave={handleAwait}
        >
          <ToggleButton
            data-testid="profile"
            className={classes.rootMobile}
            value="changeButton"
          >
            <Typography component="span" variant="body1">
              <Box data-testid="code" display="flex" alignItems="center">
                {!!dcAbbrev && <em>{dcAbbrev}</em>}
                {!dcAbbrev && <PersonIcon />}
              </Box>
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Hidden>
      <Popper
        id={id}
        data-testid="menu-profile"
        placement="bottom-end"
        anchorEl={anchorElProfile}
        open={open}
        onMouseEnter={() => clearTimeout(myTimer)}
        onMouseLeave={handleClose}
      >
        <Box
          bgcolor="background.paper"
          boxShadow={8}
          borderRadius={20}
          width="300px"
          mt={2}
        >
          {children}
        </Box>
      </Popper>
    </>
  );
};

export default ProfileComponent;

ProfileComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  dcAbbrev: PropTypes.string,
  setProfileState: PropTypes.func.isRequired
};

ProfileComponent.defaultProps = {
  dcAbbrev: ''
};
