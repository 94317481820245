import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer } from '@material-ui/core';
import ERRORS from './drawer.constants';
import DrawerHeader from './drawer-header.component';
import DrawerContent from './drawer-content.component';
import DrawerActions from './drawer-actions.component';
import LoadingComponent from './loading-retry/loading.component';
import RetryComponent from './loading-retry/retry.component';

const filterChildren = (children, Component) =>
  Children.toArray(children).filter(({ type }) => type === Component);

export default function CustomDrawer({
  open,
  children,
  loading,
  callbackRetry,
  error
}) {
  const header = filterChildren(children, DrawerHeader);
  const content = filterChildren(children, DrawerContent);
  const actions = filterChildren(children, DrawerActions);

  if (header.length === 0) throw new Error(ERRORS.NO_HEADER);
  if (header.length > 1) throw new Error(ERRORS.MULTIPLE_HEADER);

  const possibleTypes = [DrawerContent, DrawerHeader, DrawerActions];

  Children.forEach(children, data => {
    if (data && possibleTypes.indexOf(data.type) < 0) {
      throw new Error(ERRORS.UNSUPPORTED_CHILD);
    }
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      data-testid="drawer"
      variant="persistent"
    >
      <Box maxWidth={480} minWidth={360} role="presentation">
        {header}
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            {error ? (
              <RetryComponent callbackRetry={callbackRetry} />
            ) : (
              <>
                {!!content && content}
                {!!actions && actions}
              </>
            )}
          </>
        )}
      </Box>
    </Drawer>
  );
}

CustomDrawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  callbackRetry: PropTypes.func,
  error: PropTypes.bool
};

CustomDrawer.defaultProps = {
  open: true,
  loading: false,
  callbackRetry: () => {},
  error: false
};
