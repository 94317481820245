import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';

function AlertStatusHeader({ textPrimary, textSecondary }) {
  return (
    <Box
      data-testid="alert-status-header-packages"
      elevation={10}
      flexGrow={1}
      minHeight={44}
      px={5.5}
      py={1.9}
      border={1}
      borderTop={0}
      borderLeft={0}
      borderRight={0}
      borderColor="grey.200"
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" mr={1}>
            <ErrorIcon />
          </Box>
          <span>
            <em>{textPrimary} • </em>
            {textSecondary}
          </span>
        </Box>
      </Box>
    </Box>
  );
}

AlertStatusHeader.propTypes = {
  textPrimary: PropTypes.string,
  textSecondary: PropTypes.string
};

AlertStatusHeader.defaultProps = {
  textPrimary: '',
  textSecondary: ''
};

export default AlertStatusHeader;
