import React from 'react';
import { Box, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  ADDRESS_TYPES,
  DRAWER_UPDATE_ADDRESS
} from '../drawer-update-address.constants';
import BaseAddressFieldsForm from './base-address-fields-form';
import LABELS from './constants';

export default function PostalAddressForm({
  postalAddress: { addressLines = [], ...address },
  onChangePostalAddress
}) {
  const {
    address_types: {
      [ADDRESS_TYPES.loggi]: { fields }
    }
  } = DRAWER_UPDATE_ADDRESS;

  const handleChangeAddressLines = (index, value) => {
    const newAddressLines = [...addressLines];
    newAddressLines[index] = value;
    onChangePostalAddress('addressLines', newAddressLines);
  };

  return (
    <Box display="flex" flexWrap="wrap" gridGap={16} mx={3.5} mb={3.5}>
      <TextField
        key="line-0"
        fullWidth
        label={`${fields.line.text} ${LABELS.LINE_1}`}
        variant="outlined"
        inputProps={{ 'data-testid': 'postal-address-line-1' }}
        value={addressLines[0]}
        onChange={event => handleChangeAddressLines(0, event.target.value)}
      />
      <TextField
        key="line-1"
        fullWidth
        label={`${fields.line.text} ${LABELS.LINE_2}`}
        variant="outlined"
        inputProps={{ 'data-testid': 'postal-address-line-2' }}
        value={addressLines[1]}
        onChange={event => handleChangeAddressLines(1, event.target.value)}
      />

      {addressLines
        .filter((_, index) => index > 1)
        .map((line, index) => (
          <TextField
            // eslint-disable-next-line react/no-array-index-key
            key={`line-${index + 3}`}
            fullWidth
            label={`${fields.line.text} ${index + 3}`}
            variant="outlined"
            inputProps={{ 'data-testid': `postal-address-line-${index + 3}` }}
            value={line}
            onChange={event =>
              handleChangeAddressLines(index + 2, event.target.value)
            }
          />
        ))}
      <BaseAddressFieldsForm
        fields={fields}
        address={address}
        onChangeBaseAddress={onChangePostalAddress}
      />
    </Box>
  );
}

PostalAddressForm.propTypes = {
  onChangePostalAddress: PropTypes.func.isRequired,
  postalAddress: PropTypes.shape().isRequired
};
