import React from 'react';
import { Can } from 'auth/access-control';
import { Box } from '@material-ui/core';
import FilterComponent from 'shared/filter';
import { PropTypes } from 'prop-types';
import { FILTERS } from 'shared/filter/filter.constants';
import { ACTIONS } from 'auth/access-control/access-control.rules';

export default function FilterElements({
  dataForFilter,
  setFilters,
  checkedItemsBase,
  setCheckedItemsBase
}) {
  const onApplyChanges = (items, resetBaseListData) => {
    if (items.remove || Object.values(items)[0] === undefined) {
      setFilters({});
      setCheckedItemsBase({});
    } else {
      setFilters(currentState => {
        return { ...currentState, ...items };
      });
    }

    if (resetBaseListData) {
      resetBaseListData();
    }
  };

  return (
    <Box>
      {dataForFilter && (
        <Box data-testid="filter-elemets">
          <FilterComponent
            btnFilter="Com alerta"
            onApplyChanges={onApplyChanges}
            whichFilter={FILTERS.WITH_ALERT}
            dataForFilter={dataForFilter}
            checkedItemsBase={checkedItemsBase}
            setCheckedItemsBase={setCheckedItemsBase}
            preselectedItems={checkedItemsBase.withAlert}
          />
          {/* LEVE */}
          <Can actions={[ACTIONS.IN_STREET_LEVE]}>
            <FilterComponent
              btnFilter="Círculos"
              onApplyChanges={onApplyChanges}
              whichFilter={FILTERS.IN_STREET_CIRCLE}
              dataForFilter={dataForFilter}
              checkedItemsBase={checkedItemsBase}
              setCheckedItemsBase={setCheckedItemsBase}
              preselectedItems={checkedItemsBase.circleIds}
            />
            <FilterComponent
              btnFilter="Cidades"
              onApplyChanges={onApplyChanges}
              whichFilter={FILTERS.IN_STREET_CITY}
              dataForFilter={dataForFilter}
              checkedItemsBase={checkedItemsBase}
              setCheckedItemsBase={setCheckedItemsBase}
              preselectedItems={checkedItemsBase.cities}
            />
            <FilterComponent
              btnFilter="Entregador"
              onApplyChanges={onApplyChanges}
              whichFilter={FILTERS.IN_STREET_DELIVERER}
              dataForFilter={dataForFilter}
              checkedItemsBase={checkedItemsBase}
              setCheckedItemsBase={setCheckedItemsBase}
              preselectedItems={checkedItemsBase.delivererCodes}
            />
          </Can>
        </Box>
      )}
    </Box>
  );
}

FilterElements.propTypes = {
  dataForFilter: PropTypes.shape({
    deliverers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    statuses: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        code: PropTypes.number
      })
    ),
    hasFaults: PropTypes.arrayOf(PropTypes.bool),
    cities: PropTypes.arrayOf(PropTypes.string),
    circles: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  checkedItemsBase: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
  setCheckedItemsBase: PropTypes.func.isRequired
};
