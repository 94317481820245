import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CheckIcon from '@material-ui/icons/Check';
import { dateFormat } from 'finance/finance.configuration';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Popover
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useStyles from './previous-list.styles';
import { PREVIOUS_PERIODS } from '../finance.constants';

/* eslint-disable react/prop-types */
/**
 * FIXME:
 * 'selected.start' is missing in props validation  react/prop-types
 * 'selected.start' is missing in props validation  react/prop-types
 * 'selected.end' is missing in props validation    react/prop-types
 * 'selected.start' is missing in props validation  react/prop-types
 * 'selected.start' is missing in props validation  react/prop-types
 */

function PreviousListComponent({
  allPeriods,
  selected,
  onPreviousPeriodChange
}) {
  const [anchor, setAnchor] = useState(null);

  const classes = useStyles();

  const handleClose = () => {
    setAnchor(null);
  };

  const handleClick = event => {
    event.preventDefault();
    setAnchor(event.currentTarget);
  };

  const choosePreviousList = item => {
    setAnchor(null);
    onPreviousPeriodChange(item);
  };

  const open = Boolean(anchor);

  return (
    <Box mr={2} component="span">
      <Button
        variant="outlined"
        size="small"
        onClick={handleClick}
        data-testid="previous-list-button"
      >
        {selected.start !== '' &&
          `${dateFormat(selected.start)} à 
          ${dateFormat(selected.end, true)}`}
        {!open && <ArrowDropDownIcon />}
        {open && <ArrowDropUpIcon />}
      </Button>
      <Popover
        data-testid="popover-wrapper-previous-list"
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        className={classes.popover}
      >
        <List className={classes.list}>
          <ListSubheader disableSticky>{PREVIOUS_PERIODS}</ListSubheader>
          {allPeriods.map(item => {
            return (
              <ListItem
                key={item.start}
                dense
                button
                divider
                onClick={() => choosePreviousList(item)}
                selected={selected.start === item.start}
              >
                <ListItemText
                  primary={`
                    ${dateFormat(item.start, false, 'DD MMM[,] YYYY')} à 
                    ${dateFormat(item.end, true, 'DD MMM[,] YYYY')}
                  `}
                />
                {selected.start === item.start && (
                  <ListItemSecondaryAction>
                    <CheckIcon color="primary" fontSize="small" />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </Box>
  );
}
const previousListItemModel = PropTypes.shape({
  value: PropTypes.string,
  header: PropTypes.string
});
PreviousListComponent.propTypes = {
  onPreviousPeriodChange: PropTypes.func.isRequired,
  selected: previousListItemModel.isRequired,
  allPeriods: PropTypes.arrayOf(previousListItemModel).isRequired
};

export default PreviousListComponent;
