import moment from 'moment';
import 'moment/locale/pt-br';

export const TEXT = {
  OVERDUE: 'de atraso',
  NOW_UNTIL: 'Agora, até',
  NOW_FROM: 'Agora,',
  UNTIL: 'até',
  TODAY_FROM: 'Hoje,',
  TOMORROW_FROM: 'Amanhã,',
  TO_GO: 'Retirar',
  DELIVER: 'Entregar',
  TODAY_FROM_LOWER: 'hoje,',
  TOMORROW_FROM_LOWER: 'amanhã,'
};

export const PERIOD = {
  OVERDUE: 'OVERDUE',
  ALMOST_OVERDUE: 'ALMOST_OVERDUE',
  STARTED: 'STARTED',
  ALMOST_STARTED: 'ALMOST_STARTED',
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
  AFTER_TOMORROW: 'AFTER_TOMORROW'
};

function formatWithStartAndEndTime(prefix, startDate, endDate) {
  return `${prefix} ${startDate.format('HH:mm')} ${TEXT.UNTIL} ${endDate.format(
    'HH:mm'
  )}`;
}

function formatEndTime(prefix, endDate) {
  return `${prefix} ${TEXT.UNTIL} ${endDate.format('HH:mm')}`;
}

export const ACTIONS_BY_PERIOD = {
  [PERIOD.OVERDUE]: {
    isBetweenRange: (startDate, endDate) => {
      const now = moment();
      return startDate <= now && endDate <= now;
    },
    formatText: (startDate, endDate, allFinishedStatus) => {
      let resultText;
      if (allFinishedStatus) {
        const todayInstant = moment().startOf('day');
        const tomorrowInstant = moment()
          .startOf('day')
          .add(1, 'day');
        if (endDate > todayInstant && endDate < tomorrowInstant) {
          resultText = `${TEXT.TODAY_FROM} ${TEXT.UNTIL} ${endDate.format(
            'HH:mm'
          )}`;
        } else {
          resultText = `${startDate.format('DD MMM')}, ${
            TEXT.UNTIL
          } ${endDate.format('HH:mm')}`;
        }
      } else {
        const overdueDuration = moment.duration(moment().diff(endDate));
        const durationText = overdueDuration.locale('pt-BR').humanize();
        resultText = `${durationText} ${TEXT.OVERDUE}`;
      }
      return resultText;
    },
    showOverdueIcon: true,
    formatDetailText: (startDate, endDate) => {
      let resultText;
      const todayInstant = moment().startOf('day');
      const tomorrowInstant = moment()
        .startOf('day')
        .add(1, 'day');
      if (endDate > todayInstant && endDate < tomorrowInstant) {
        resultText = formatWithStartAndEndTime(
          TEXT.TODAY_FROM_LOWER,
          startDate,
          endDate
        );
      } else {
        resultText = formatWithStartAndEndTime(
          `${startDate.format('DD MMM')},`,
          startDate,
          endDate
        );
      }

      return `${TEXT.TO_GO} ${resultText}`;
    },
    formatDeadlineText: endDate => {
      let resultText;
      const todayInstant = moment().startOf('day');
      const tomorrowInstant = moment()
        .startOf('day')
        .add(1, 'day');
      if (endDate > todayInstant && endDate < tomorrowInstant) {
        resultText = formatEndTime(TEXT.TODAY_FROM_LOWER, endDate);
      } else {
        resultText = formatEndTime(`${endDate.format('DD MMM')},`, endDate);
      }

      return `${TEXT.DELIVER} ${resultText}`;
    }
  },
  [PERIOD.ALMOST_OVERDUE]: {
    isBetweenRange: (startDate, endDate) => {
      const now = moment();
      return (
        startDate <= now && endDate > now && endDate <= now.add(30, 'minutes')
      );
    },
    formatText: (_, endDate) => {
      return `${TEXT.NOW_UNTIL} ${endDate.format('HH:mm')}`;
    },
    showOverdueIcon: true,
    formatDetailText: (startDate, endDate) => {
      return formatWithStartAndEndTime(
        `${TEXT.TO_GO} ${TEXT.TODAY_FROM_LOWER}`,
        startDate,
        endDate
      );
    },
    formatDeadlineText: endDate => {
      return formatEndTime(`${TEXT.DELIVER} ${TEXT.TODAY_FROM_LOWER}`, endDate);
    }
  },
  [PERIOD.STARTED]: {
    isBetweenRange: (startDate, endDate) => {
      const now = moment();
      return startDate <= now && endDate > now.add(30, 'minutes');
    },
    formatText: (_, endDate) => {
      return `${TEXT.NOW_UNTIL} ${endDate.format('HH:mm')}`;
    },
    formatDetailText: (startDate, endDate) => {
      return formatWithStartAndEndTime(
        `${TEXT.TO_GO} ${TEXT.TODAY_FROM_LOWER}`,
        startDate,
        endDate
      );
    },
    formatDeadlineText: endDate => {
      return formatEndTime(`${TEXT.DELIVER} ${TEXT.TODAY_FROM_LOWER}`, endDate);
    }
  },
  [PERIOD.ALMOST_STARTED]: {
    isBetweenRange: startDate => {
      const now = moment();
      return startDate > now && startDate <= now.add(20, 'minutes');
    },
    formatText: (startDate, endDate) => {
      return formatWithStartAndEndTime(TEXT.NOW_FROM, startDate, endDate);
    },
    formatDetailText: (startDate, endDate) => {
      return formatWithStartAndEndTime(
        `${TEXT.TO_GO} ${TEXT.TODAY_FROM_LOWER}`,
        startDate,
        endDate
      );
    },
    formatDeadlineText: endDate => {
      return formatEndTime(`${TEXT.DELIVER} ${TEXT.TODAY_FROM_LOWER}`, endDate);
    }
  },
  [PERIOD.TODAY]: {
    isBetweenRange: startDate => {
      const now = moment();
      const tomorrowDay = moment()
        .startOf('day')
        .add(1, 'day');
      return startDate > now.add(20, 'minutes') && startDate < tomorrowDay;
    },
    formatText: (startDate, endDate) => {
      return formatWithStartAndEndTime(TEXT.TODAY_FROM, startDate, endDate);
    },
    formatDetailText: (startDate, endDate) => {
      return formatWithStartAndEndTime(
        `${TEXT.TO_GO} ${TEXT.TODAY_FROM_LOWER}`,
        startDate,
        endDate
      );
    },
    formatDeadlineText: endDate => {
      return formatEndTime(`${TEXT.DELIVER} ${TEXT.TODAY_FROM_LOWER}`, endDate);
    }
  },
  [PERIOD.TOMORROW]: {
    isBetweenRange: startDate => {
      const tomorrowDay = moment()
        .startOf('day')
        .add(1, 'day');
      const afterTomorrowDay = moment()
        .startOf('day')
        .add(2, 'day');
      return startDate >= tomorrowDay && startDate < afterTomorrowDay;
    },
    formatText: (startDate, endDate) => {
      return formatWithStartAndEndTime(TEXT.TOMORROW_FROM, startDate, endDate);
    },
    formatDetailText: (startDate, endDate) => {
      return formatWithStartAndEndTime(
        `${TEXT.TO_GO} ${TEXT.TOMORROW_FROM_LOWER}`,
        startDate,
        endDate
      );
    },
    formatDeadlineText: endDate => {
      return formatEndTime(
        `${TEXT.DELIVER} ${TEXT.TOMORROW_FROM_LOWER}`,
        endDate
      );
    }
  },
  [PERIOD.AFTER_TOMORROW]: {
    isBetweenRange: startDate => {
      const afterTomorrowDay = moment()
        .startOf('day')
        .add(2, 'day');
      return startDate >= afterTomorrowDay;
    },
    formatText: (startDate, endDate) => {
      return formatWithStartAndEndTime(
        `${startDate.format('DD MMM')},`,
        startDate,
        endDate
      );
    },
    formatDetailText: (startDate, endDate) => {
      return formatWithStartAndEndTime(
        `${TEXT.TO_GO} ${startDate.format('DD MMM')},`,
        startDate,
        endDate
      );
    },
    formatDeadlineText: endDate => {
      return formatEndTime(
        `${TEXT.DELIVER} ${endDate.format('DD MMM')},`,
        endDate
      );
    }
  }
};
