import React, { useState } from 'react';
import PropTypes from 'prop-types';

import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';

import { operatorCreateOrUpdateService } from 'users-management/operators/operators.service';

import OperatorFormComponent from './operators-form.component';
import OperatorModel from './operators.model';

import TEXT from './operators-form.constants';

export default function OperatorFormContainer({
  handleClosingDrawer,
  onSubmitCallback,
  open,
  user
}) {
  const isEdit = !!user?.id;

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = formData => {
    setLoading(true);

    const service = operatorCreateOrUpdateService(formData);
    service
      .then(() => {
        setLoading(false);

        showSnackbar({
          message: isEdit
            ? TEXT.UPDATE.SUCCESS
            : TEXT.CREATE.SUCCESS(formData.email),
          variant: 'success',
          enqueueSnackbar
        });
        onSubmitCallback();
      })
      .catch(error => {
        setLoading(false);

        showSnackbar({
          message: error.message,
          variant: 'error',
          enqueueSnackbar
        });
      });
    return service;
  };

  return (
    <OperatorFormComponent
      handleClosingDrawer={handleClosingDrawer}
      callback={onSubmit}
      loading={loading}
      open={open}
      user={user}
    />
  );
}

OperatorFormContainer.propTypes = {
  handleClosingDrawer: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  open: PropTypes.bool,
  user: PropTypes.shape(OperatorModel)
};

OperatorFormContainer.defaultProps = {
  handleClosingDrawer: () => {},
  onSubmitCallback: () => {},
  open: true,
  user: {}
};
