import { colors } from '@loggi/mar';
import { makeStyles, fade } from '@material-ui/core/styles';
import ListItemStyles from 'shared/styles/list.material.styles';

const useStyles = makeStyles(({ typography, palette, spacing }) => ({
  list: {
    width: '480px',
    paddingTop: `${spacing(2)}px`,
    ...ListItemStyles({ typography, palette, spacing })
  },
  popover: {
    '& .MuiPopover-paper': {
      borderRadius: typography.pxToRem(12)
    }
  },
  footer: {
    borderTop: `1px solid ${colors.smoke[50]}`,
    padding: spacing(2.5, 3.5)
  },
  footerShadow: {
    boxShadow: `0px -2px 5px ${fade(palette.text.primary, 0.1)}`
  },
  filterWrapper: {
    maxHeight: '450px',
    overflowX: 'hidden'
  },
  activeFilter: {
    // same style for hover primary button
    border: `1px solid ${palette.primary.main}`,
    backgroundColor: fade(palette.primary.main, 0.04)
  }
}));

export default useStyles;
