import createNewPassword from './create-new-password';
import recoveryPasswordForm from './recovery-password-form';
import validatePasswordRecovery from './validate-password-recovery';
import impersonationForm from './impersonation-form';

export default {
  createNewPassword,
  recoveryPasswordForm,
  validatePasswordRecovery,
  impersonationForm
};
