import PropTypes from 'prop-types';

const OPERATOR_MODEL = PropTypes.shape({
  fullName: PropTypes.string,
  mobileNumber: PropTypes.string,
  email: PropTypes.string,
  lastMileOperator: PropTypes.shape({
    role: PropTypes.string,
    userStatus: PropTypes.string,
    userId: PropTypes.string,
    cpf: PropTypes.string
  })
});

export default OPERATOR_MODEL;
