import api, { generateLevePath } from 'infra/api/leve';
import { getSelectedBase } from 'profile/profile.service';

export function getAllPeriods() {
  return api
    .get('/api/v1/last_mile/package_charge/period')
    .then(_data => _data.data);
}

export default async (
  selectPeriod,
  page,
  itemsPerPage,
  filters,
  orderBy,
  orderDirection,
  enableV2
) => {
  const delivererFilters = filters.deliverers;
  const chargeTypeFilters = filters.chargeType;

  const startDate = selectPeriod.start;
  const endDate = selectPeriod.end;

  return api
    .post(`/api/${enableV2 ? 'v2' : 'v1'}/last_mile/package_charge/filter`, {
      startDate,
      endDate,
      page,
      itemsPerPage,
      delivererFilters,
      orderBy,
      orderDirection,
      chargeTypeFilters
    })
    .then(async _data => _data.data);
};

function getDcName() {
  const base = getSelectedBase();
  // eslint-disable-next-line camelcase
  return base?.distribution_center?.name || '';
}

export const generateCsvLink = async selectedPeriod => {
  const dcName = getDcName();
  const startDate = selectedPeriod.start;
  const endDate = selectedPeriod.end;
  const queryStrategy = 'SCROLL';

  const path = `/api/v1/last_mile/[last_mile_company.identification]:[distribution_center.id]/package_charge/financial_report?dcName=${encodeURIComponent(
    dcName
  )}&startDate=${startDate}&endDate=${endDate}&queryStrategy=${queryStrategy}`;
  return generateLevePath(path);
};
