import getDeliverersService from 'infra/service/get-deliverers';
import { translateStatusCode, DEFAULT_MESSAGE } from '../httpMessage';

export const CUSTOM_ERROR_MESSAGES = {
  404: 'Eita, não encontramos ninguém. Tente de novo.',
  500: 'Eita, algo deu errado. Tente de novo daqui a pouco ou atualize a página. [001-Error-500]'
};
/**
 * Returns a list of the deliverers as a Promise.
 *
 * Use it like this:
 *  getDeliverers().then(data) => {...})
 *
 */

const getDeliverers = (withCount = false, includeInactive = false) =>
  getDeliverersService(withCount, includeInactive)
    .then(response => response.data.deliverer || [])
    .catch(error => {
      throw new Error(
        error.response
          ? translateStatusCode(error.response.status, CUSTOM_ERROR_MESSAGES)
          : DEFAULT_MESSAGE
      );
    });

export default getDeliverers;
