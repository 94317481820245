import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Typography } from '@material-ui/core';
import useStyles from './missed-events-box.styles';

function MissedEventsBox({ count, variant }) {
  const classes = useStyles();

  return (
    count && (
      <Typography
        variant="body2"
        className={clsx(classes.missEventsBox, classes[variant])}
      >
        {Math.round(count)}
      </Typography>
    )
  );
}

MissedEventsBox.propTypes = {
  count: PropTypes.number.isRequired,
  variant: PropTypes.string
};

MissedEventsBox.defaultProps = {
  variant: 'square'
};

export default MissedEventsBox;
