import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const DemandRegionsContext = React.createContext();

export const DemandRegionProvider = ({ children, value }) => {
  // The current context
  const [sortingContext, setSortingContext] = useState(
    value?.sortingContext || null
  );

  // The simulation of context
  const [simulationSortingContext, setSimulationSortingContext] = useState(
    value?.simulationSortingContext || null
  );

  const [
    openSimulationDrawerDemandRegions,
    setOpenSimulationDrawerDemandRegions
  ] = useState(value?.openSimulationDrawerDemandRegions || false);

  const contextValues = {
    sortingContext,
    setSortingContext,
    simulationSortingContext,
    setSimulationSortingContext,
    openSimulationDrawerDemandRegions,
    setOpenSimulationDrawerDemandRegions
  };

  return (
    <DemandRegionsContext.Provider value={contextValues}>
      {children}
    </DemandRegionsContext.Provider>
  );
};

DemandRegionProvider.defaultProps = {
  value: null
};

DemandRegionProvider.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.shape({
    openSimulationDrawerDemandRegions: PropTypes.bool,
    setOpenSimulationDrawerDemandRegions: PropTypes.func,
    sortingContext: PropTypes.shape({
      licensePlate: PropTypes.string,
      lastUpdated: PropTypes.string,
      decisions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired
        })
      )
    }),
    simulationSortingContext: PropTypes.shape({
      licensePlate: PropTypes.string,
      decisions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired
        })
      )
    })
  })
};
