import React from 'react';
import { Avatar, Box, Divider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { dateFormatter } from 'shared/formatters/date';
import { formatMoneyProto } from 'shared/formatters/money';
import formatPhone from 'shared/formatters/phone/phone';
import DrawerPackageHistory from 'view/molecules/package-drawer/drawer-history/drawer-history.component';
import TRANSPORT_TYPE_MAPPER from 'offer/cells-component/transport-type-cell/transport-type.constants';
import {
  PackageDetail,
  PackageHistoryItem
} from 'view/molecules/package-drawer/drawer-detail/drawer-detail.component';
import { EFFECTIVE_AT, DATE_TIME_FORMAT } from './activity-info.constants';
import useStyles from '../drawer.style';
import {
  getChargeTypeLabel,
  getChargeTypeLabelPast
} from '../../finance.configuration';
import { CHARGE_TYPE } from '../../finance.constants';

export default function ActivityInfoComponent({
  isPackageHistoryEnabled,
  onHistory,
  packageCharge,
  packageInfo
}) {
  const classes = useStyles();

  const getTransportType = () =>
    TRANSPORT_TYPE_MAPPER[packageInfo.lastMileUser.deliverer.transportType]
      ?.text;

  const getActivityType = getChargeTypeLabel(
    packageCharge.packageCharge.chargeType
  );

  const getActivityTypePast = getChargeTypeLabelPast(
    packageCharge.packageCharge.chargeType
  );

  const canShowDelivererDetail = () =>
    packageCharge.packageCharge.chargeType !==
      CHARGE_TYPE.TRANSHIPMENT_PAYMENT && packageInfo?.lastMileUser?.deliverer;

  const HeaderInfo = () => (
    <Box mx={3.5} mt={7} mb={6.5}>
      <Box>
        <Typography variant="body1" data-testid="activity-drawer-charge-type">
          {getActivityType}
        </Typography>
        <Box my={2.5}>
          <Typography
            variant="h4"
            className={classes.bolder}
            data-testid="activity-drawer-value"
          >
            {`${formatMoneyProto(packageCharge.packageCharge.totalValue)}`}
          </Typography>
        </Box>
        <Typography variant="h5" data-testid="activity-drawer-complete-at">
          {`${getActivityTypePast} em ${dateFormatter(
            packageCharge.task.completed_at,
            DATE_TIME_FORMAT
          )}`}
        </Typography>
        <Typography variant="h5" data-testid="activity-drawer-effective-at">
          {`${EFFECTIVE_AT} ${dateFormatter(
            packageCharge.packageCharge.effectiveDateTime,
            DATE_TIME_FORMAT
          )}`}
        </Typography>
      </Box>
    </Box>
  );

  const DelivererDetail = () => (
    <Box
      py={5}
      display="flex"
      flexDirection="row"
      alignItems="center"
      mx={3.5}
      data-testid="activity-drawer-driver"
    >
      <Box mr={1.5} data-testid="activity-drawer-driver-pic">
        <Avatar
          src={packageInfo.lastMileUser.deliverer.profilePictureThumbnailUrl}
          style={{ height: '3em', width: '3em' }}
        />
      </Box>
      <Box>
        <Box mb={1}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.bolder}
            data-testid="activity-drawer-driver-name"
          >
            {packageInfo.lastMileUser.fullName}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            color="textSecondary"
            data-testid="activity-drawer-driver-transport-type-and-phone-number"
            style={{ textTransform: 'capitalize' }}
          >
            {getTransportType()} •{' '}
            {formatPhone(packageInfo.lastMileUser.mobileNumber)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box data-testid="activity-drawer">
      {!isPackageHistoryEnabled && (
        <>
          <HeaderInfo />
          <Divider />
          {canShowDelivererDetail() && <DelivererDetail />}
          <Divider />
          <PackageDetail packageInfo={packageInfo} />
          <Divider />
          <PackageHistoryItem
            packageHistory={onHistory}
            packageInfo={packageInfo}
          />
        </>
      )}
      {isPackageHistoryEnabled && (
        <DrawerPackageHistory
          history={packageInfo?.history}
          missedPromisedDateEvents={packageInfo?.missedPromisedDateEvents}
          destinationChangeHistory={packageInfo?.destinationChangeHistory}
        />
      )}
    </Box>
  );
}

ActivityInfoComponent.propTypes = {
  isPackageHistoryEnabled: PropTypes.bool.isRequired,
  onHistory: PropTypes.func.isRequired,
  packageCharge: PropTypes.shape().isRequired,
  packageInfo: PropTypes.shape().isRequired
};
