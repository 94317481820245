import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import PackageDrawer from 'view/molecules/package-drawer';
import useStyles from './styles';

function ConfigurableTable(props) {
  const {
    data,
    columns,
    renderHeader,
    renderFooter,
    pagination,
    paginationParams,
    onChangePage,
    onClickRow,
    onReorder,
    withPackageDrawer
  } = props;
  const keysColumns = Object.keys(columns);
  const [currentSelectedItem, setCurrentSelectedItem] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [order, setOrder] = useState(null); // true: Asc // false: Desc // null: no order
  const classes = useStyles();
  const { page, itemsPerPage } = paginationParams;
  const [styleSelectRow, setStyleSelectRow] = useState(-1);

  // The backend application always returns one more item than the expected, so we can
  // control the next page button, for this reason, we need to calculate our total of items
  // based on the current number of the page.
  let count = Number(pagination.numberItems);
  let slicedData = data;
  if (count === -1) {
    slicedData = data.slice(0, itemsPerPage);
    count =
      data.length <= itemsPerPage
        ? page * itemsPerPage
        : page * itemsPerPage + 1;
  }

  const TableFooterMaker = () => {
    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            // loggi-web pagination API is 1 based,
            // we need to force compatibility here
            // as MUI pagination API is 0 based.
            page={page - 1}
            rowsPerPage={itemsPerPage}
            count={count}
            onChangePage={onChangePage}
            labelDisplayedRows={() => `Página ${page}`}
            // Disables itemsPerPage number selection
            rowsPerPageOptions={[]}
            nextIconButtonProps={{
              'data-testid': 'next-page-arrow'
            }}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const handleClickHeader = key => {
    const transitions = {
      true: false,
      false: null,
      null: true
    };
    let column = key;
    const sameColumn = key === currentSelectedItem;
    const newOrder = sameColumn ? transitions[order] : true;
    if (!sameColumn) {
      setCurrentSelectedItem(key);
    } else {
      // clean the column if no reorder selected
      column = newOrder !== null ? key : null;
    }
    setOrder(newOrder);
    onReorder(column, newOrder);
  };

  const resolveActiveClass = (key, position) =>
    order === position && currentSelectedItem === key ? classes.active : null;

  const TableHeadMaker = () => {
    const headerList = Object.entries(columns).map(
      ([key, { header, headAlign, reorder }]) => {
        const clickable = reorder !== undefined && slicedData.length > 0;
        return (
          <TableCell
            align={headAlign}
            key={key}
            data-testid={key}
            onClick={() => clickable && handleClickHeader(reorder)}
          >
            <ButtonBase
              disableRipple
              className={!clickable ? classes.tableCell : ''}
            >
              <Box
                className={
                  order !== null && currentSelectedItem === reorder
                    ? classes.active
                    : null
                }
              >
                {header}
              </Box>
              <Box className={classes.holderAction}>
                {reorder && (
                  <ArrowDropUp
                    fontSize="small"
                    data-testid="orderby-up"
                    className={`${classes.arrowUp} ${resolveActiveClass(
                      reorder,
                      true
                    )}`}
                  />
                )}
                {reorder && (
                  <ArrowDropDown
                    fontSize="small"
                    data-testid={`orderby-down-${key}`}
                    className={`${classes.arrowDown} ${resolveActiveClass(
                      reorder,
                      false
                    )}`}
                  />
                )}
              </Box>
            </ButtonBase>
          </TableCell>
        );
      }
    );
    return (
      <TableHead>
        <TableRow>{headerList}</TableRow>
      </TableHead>
    );
  };

  const TableBodyMaker = () => {
    const _elementTable = (dataRow, columnsName) => {
      if (columnsName in dataRow) {
        return dataRow[columnsName];
      }
      if ('component' in columns[columnsName]) {
        return columns[columnsName].component(dataRow);
      }
      return '-';
    };
    const _handleOnClickRow = dataRow => {
      if (withPackageDrawer) {
        setSelectedPackage({ packageId: dataRow?.packageId || dataRow?._id });
        setOpenDrawer(true);
        setStyleSelectRow(dataRow?.packageId || dataRow._id);
      }
      onClickRow(dataRow);
    };

    const getTableRows = dataRow => {
      const key =
        dataRow.id ||
        dataRow.dataPackage ||
        dataRow?.packageId ||
        dataRow._id ||
        dataRow.mobileNumber;
      return (
        <TableRow key={key} hover>
          {keysColumns.map((columnsName, index) => {
            const propsStyle = columns[columnsName].propsStyleBody;
            const keyConcat = `${key}${index}`;
            return (
              <TableCell
                className={`
                  ${withPackageDrawer ? classes.dataRow : ''}
                  ${
                    key === styleSelectRow && openDrawer
                      ? classes.rowSelected
                      : ''
                  }
                `}
                component="th"
                scope="row"
                onClick={() => _handleOnClickRow(dataRow)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...propsStyle}
                key={keyConcat}
                data-testid={keyConcat}
              >
                {_elementTable(dataRow, columnsName)}
              </TableCell>
            );
          })}
        </TableRow>
      );
    };

    const tableBodyRows = slicedData.map(item => {
      return getTableRows(item);
    });

    return <TableBody>{tableBodyRows}</TableBody>;
  };

  return (
    <Paper>
      {openDrawer && (
        <PackageDrawer
          setOpen={setOpenDrawer}
          selectedPackage={selectedPackage}
        />
      )}
      <Table>
        {renderHeader && <TableHeadMaker />}
        <TableBodyMaker />
        {renderFooter && <TableFooterMaker />}
      </Table>
    </Paper>
  );
}

ConfigurableTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  columns: PropTypes.objectOf(
    PropTypes.shape({
      header: PropTypes.string,
      headAlign: PropTypes.string,
      propsStyleBody: PropTypes.shape,
      component: PropTypes.element,
      reorder: PropTypes.string
    })
  ).isRequired,
  renderHeader: PropTypes.bool,
  renderFooter: PropTypes.bool,
  pagination: PropTypes.shape({
    numberPages: PropTypes.number,
    numberItems: PropTypes.number
  }),
  paginationParams: PropTypes.shape({
    page: PropTypes.number,
    itemsPerPage: PropTypes.number
  }),
  onChangePage: PropTypes.func,
  onClickRow: PropTypes.func,
  onReorder: PropTypes.func,
  withPackageDrawer: PropTypes.bool
};

ConfigurableTable.defaultProps = {
  renderHeader: true,
  renderFooter: false,
  paginationParams: {
    page: 1,
    itemsPerPage: 1
  },
  pagination: {
    numberItems: 0
  },
  onClickRow: () => {},
  onChangePage: null,
  onReorder: () => {},
  withPackageDrawer: false
};

ConfigurableTable.displayName = 'GenericTable';

export default ConfigurableTable;
