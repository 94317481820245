import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';

export default function SkipHeader({ title, children, handleClick }) {
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Button
          color="primary"
          onClick={handleClick}
          data-testid="onboarding-skip"
        >
          {title}
        </Button>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
}

SkipHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  handleClick: PropTypes.func.isRequired
};
