const TEXT_HAVE_ACCESS_TO = 'Tem acesso a';

const EDIT = 'Editar';
const RESEND_EMAIL = 'Reenviar convite';
const REVOKE_ACCESS = 'Remover pessoa';

const REVOKE_DIALOG = {
  TITLE: 'Remover pessoa?',
  DESCRIPTION: () =>
    'Ao remover a pessoa, ela perderá todos os acessos as ferramentas para a base.',
  CANCEL_BUTTON: 'Não, voltar',
  CONFIRM_BUTTON: 'Sim, remover',
  SUCCESS: 'Tudo certo! Acessos removidos'
};

const RESEND_DIALOG = {
  TITLE: 'Reenviar convite?',
  DESCRIPTION: email =>
    `Ao reenviar, ${email} receberá um email com o convite para realizar seu cadastro.`,
  CANCEL_BUTTON: 'Não, voltar',
  CONFIRM_BUTTON: 'Sim, reenviar',
  SUCCESS: 'Tudo certo! Enviamos o convite por e-mail.'
};

export default {
  EDIT,
  RESEND_EMAIL,
  REVOKE_ACCESS,
  RESEND_DIALOG,
  REVOKE_DIALOG,
  TEXT_HAVE_ACCESS_TO
};
