import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_TO_TRANSFER } from 'tracking/tracking.configuration';

const getToTransferColumnsLoading = () => {
  const response = {};
  const columns = CONFIGURABLE_TABLE_COLLAPSIBLE_CHILDREN_CONFIGURATION_TO_TRANSFER;

  Object.keys(columns).forEach(index => {
    response[index] = {
      ...columns[index],
      component: () => <Skeleton variant="rect" width="30px" height="30px" />
    };
  });
  return response;
};

export default getToTransferColumnsLoading; // TODO: lint forced to be default remove ASAP
