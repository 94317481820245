import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  ListItem,
  List,
  ListSubheader,
  ListItemText,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Typography
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import useStyles from 'profile/profile.style';
import { TEXT, FLOWS, LIMIT_ROWS } from 'profile/profile.constants';

const ProfileChangeBase = ({
  setProfileState,
  onChooseBase,
  dcName,
  lmcName,
  dataDisplayed,
  searchValue,
  onRestoreList,
  onChangeInputSearch
}) => {
  const classes = useStyles();
  const validateSelectItem = item => {
    if (item.distribution_center) {
      return item.distribution_center.name === dcName;
    }

    return item.last_mile_company.name === lmcName;
  };
  return (
    <Box className={classes.list}>
      <Box>
        <Box height="25px" mb={1}>
          <List>
            <ListSubheader disableSticky>
              {TEXT.PROFILE.SEE_OTHER_BASE}
            </ListSubheader>
          </List>
        </Box>

        <ListItem divider>
          <Box
            width="300px"
            borderRadius={8}
            display="flex"
            justifyContent="center"
            mt={1}
          >
            <TextField
              fullWidth
              id="input"
              size="small"
              variant="outlined"
              autoComplete="off"
              autoFocus
              value={searchValue}
              placeholder={TEXT.PROFILE.PLACEHOLDER}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon size="small" color="disabled" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <Box hidden={!searchValue}>
                    <InputAdornment position="end">
                      <IconButton onClick={onRestoreList}>
                        <CancelRoundedIcon
                          color="primary"
                          data-testid="icon-restore-search"
                        />
                      </IconButton>
                    </InputAdornment>
                  </Box>
                )
              }}
              onChange={onChangeInputSearch}
            />
          </Box>
        </ListItem>
        <Box maxHeight="350px" overflow="auto">
          {dataDisplayed.length < LIMIT_ROWS &&
            dataDisplayed.map(item => (
              <ListItem
                // eslint-disable-next-line camelcase
                key={`${item.distribution_center?.routing_code || ''}-${
                  item.last_mile_company.identification
                }`}
                divider
                button
                selected={validateSelectItem(item)}
                onClick={() => onChooseBase(item)}
              >
                <Grid container alignItems="center" justify="space-between">
                  <ListItemText>
                    <Box
                      display="flex"
                      alignItems="center"
                      height="50px"
                      fontWeight={400}
                    >
                      {item.distribution_center?.name ||
                        item.last_mile_company?.name}
                    </Box>
                  </ListItemText>
                  {validateSelectItem(item) && (
                    <CheckIcon color="primary" fontSize="small" />
                  )}
                </Grid>
              </ListItem>
            ))}
          {dataDisplayed.length >= LIMIT_ROWS && (
            <ListItem divider button>
              <Grid container alignItems="center" justify="space-between">
                <ListItemText>
                  <Typography color="textSecondary" variant="body2">
                    {TEXT.PROFILE.HELPER_LIST}
                  </Typography>
                </ListItemText>
              </Grid>
            </ListItem>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50px"
        >
          <Button
            onClick={() => setProfileState(FLOWS.PROFILE_INITIAL)}
            variant="text"
            color="primary"
          >
            {TEXT.PROFILE.CANCEL}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileChangeBase;

ProfileChangeBase.propTypes = {
  setProfileState: PropTypes.func.isRequired,
  onChooseBase: PropTypes.func.isRequired,
  dcName: PropTypes.string,
  lmcName: PropTypes.string,
  dataDisplayed: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ).isRequired,
  searchValue: PropTypes.string,
  onRestoreList: PropTypes.func.isRequired,
  onChangeInputSearch: PropTypes.func.isRequired
};

ProfileChangeBase.defaultProps = {
  dcName: '',
  lmcName: '',
  searchValue: ''
};
