import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RoomRounded } from '@material-ui/icons';
import GoogleMapReact from 'google-map-react';
import { Tooltip, Box, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { useService } from '@xstate/react';
import { interpret, Interpreter } from 'xstate';
import AddressAutocomplete from '@loggi/components/src/one/address-autocomplete';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import {
  TEXT,
  ZOOM,
  MAP_CHANGE_ADDRESS_PIN_TOOLTIP_SHOW_DELAY_MS
} from './google-map.constants';
import styleDefault from './style-map';
import updateAddressMachine, {
  ACTIONS,
  STATES
} from '../drawer-update-address.machine';
import drawerMachine from '../../drawer.machine';

export default function GoogleMapComponent({ updateAddressMachineRef }) {
  const GOOGLE_MAPS_API_KEY = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

  const [searchBarCollapsed, setSearchBarCollapsed] = useState(true);

  const [current, send] = useService(updateAddressMachineRef);
  const {
    packageInfo: {
      loggiAddress: {
        coordinates: { latitude, longitude }
      }
    }
  } = current.context;

  const isConfirming = [
    STATES.DIALOG_CONFIRM_WITHOUT_CHANGE_MAP,
    STATES.DIALOG_CONFIRM_WITHOUT_CHANGE_POSTAL_ADDRESS
  ].includes(current.value);

  const [centerPin, setCenterPin] = useState({
    lat: latitude,
    lng: longitude
  });
  const [isDrag, setIsDrag] = useState(false);

  const [value, setValue] = useState({
    description: '',
    place_id: ''
  });

  const [showPinTooltip, setShowPinTooltip] = React.useState(false);

  useEffect(() => {
    if (!showPinTooltip) {
      setTimeout(() => {
        setShowPinTooltip(true);
      }, MAP_CHANGE_ADDRESS_PIN_TOOLTIP_SHOW_DELAY_MS);
    }
  });

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (value?.description && value?.place_id) {
      getGeocode({ placeId: value.place_id })
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          send(ACTIONS.EDIT_COORDINATES_ADDRESS, {
            value: {
              latitude: lat,
              longitude: lng
            }
          });
        });
    }
  }, [value, send]);

  useEffect(() => {
    if (current.context.coordinates) {
      const { coordinates } = current.context;
      setCenterPin({
        lat: Number(coordinates.latitude),
        lng: Number(coordinates.longitude)
      });
    }
  }, [current.context]);

  function handlerOnDrag(event) {
    setIsDrag(true);
    const _valueLat = event.center.lat();
    const _valueLng = event.center.lng();
    setCenterPin({
      lat: _valueLat.toFixed(7),
      lng: _valueLng.toFixed(7)
    });
  }

  function handlerOnDragEnd(event) {
    setIsDrag(false);

    const _valueLat = event.center.lat();
    const _valueLng = event.center.lng();

    setCenterPin({
      lat: _valueLat.toFixed(7),
      lng: _valueLng.toFixed(7)
    });

    send(ACTIONS.EDIT_COORDINATES_ADDRESS, {
      value: {
        latitude: _valueLat.toFixed(7),
        longitude: _valueLng.toFixed(7)
      }
    });
  }

  function TitleTooltip() {
    return (
      <Box
        maxWidth={200}
        alignItems="center"
        alignContent="center"
        display="flex"
        flexDirection="column"
      >
        {isDrag ? (
          <>
            <Typography
              align="center"
              data-testid="map-change-address-pin-tooltip-lat"
            >{`${TEXT.PIN_LAT} ${centerPin.lat}`}</Typography>
            <Typography
              align="center"
              data-testid="map-change-address-pin-tooltip-long"
            >{`${TEXT.PIN_LONG} ${centerPin.lng}`}</Typography>
          </>
        ) : (
          <Typography
            align="center"
            data-testid="map-change-address-pin-tooltip-help"
          >
            <em>{TEXT.PIN_HELP_TITLE}</em>
            <br />
            {TEXT.PIN_HELP_TEXT}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        center={centerPin}
        zoom={ZOOM}
        options={{ styles: styleDefault }}
        onDrag={handlerOnDrag}
        onDragEnd={handlerOnDragEnd}
      >
        <Box
          height="8px"
          width="20px"
          position="absolute"
          borderRadius="50%"
          lat={centerPin.lat}
          lng={centerPin.lng}
          style={{
            backgroundColor: colors.smoke[300],
            top: 2,
            left: -10
          }}
        />

        {!isConfirming && (
          <Tooltip
            lat={centerPin.lat}
            lng={centerPin.lng}
            open={showPinTooltip}
            placement="top"
            arrow
            title={<TitleTooltip />}
          >
            <RoomRounded
              fontSize="large"
              color={isDrag ? '' : 'primary'}
              style={{
                position: 'absolute',
                top: isDrag ? -40 : -30,
                left: -18
              }}
            />
          </Tooltip>
        )}
      </GoogleMapReact>
      <Box
        data-testid="box-map-search-bar"
        title={TEXT.SEARCH_TOOLTIP}
        onFocus={() => setSearchBarCollapsed(false)}
        onBlur={() => setSearchBarCollapsed(true)}
        style={{
          width: `${searchBarCollapsed ? '200px' : '400px'}`,
          top: 10,
          right: 70,
          position: 'absolute',
          zIndex: 1000,
          transition: 'width 300ms'
        }}
      >
        <AddressAutocomplete
          label={TEXT.SEARCH_LABEL}
          value={value}
          setValue={setValue}
        />
      </Box>
    </>
  );
}

// this function is use at tests only
export const makeUpdateAddressMachineRef = (context = {}) => {
  return interpret(
    updateAddressMachine.withContext({
      ...updateAddressMachine.context,
      ...context
    }),
    {
      parent: interpret(drawerMachine).start()
    }
  );
};

GoogleMapComponent.propTypes = {
  updateAddressMachineRef: PropTypes.instanceOf(Interpreter).isRequired
};
