export const TEXT = {
  TITLE: 'Rotinas',
  SUBTITLE: 'Informações da sua operação',
  ADDRESS: 'Endereço',
  WORKING_HOURS: 'Funcionamento',
  BASE: 'Base',
  ROUTING_CODE: 'sigla de roteirização',
  START_OF_OPERATION: 'início da operação',
  AVAILABLE_SPACE: 'espaço disponível',
  SOMETHING_WRONG: 'Tem algo errado?',
  CONTACT_SUPPORT:
    'Fale com o suporte para atualizar os dados da sua operação.',
  NO_DATA: 'Não há dados',
  NEW: 'Novo!',
  SERVICE_AREA: 'Área de atendimento',
  COMPLEX_AREA: 'Cadastre e gerencie áreas de entrega complexa',
  COMPLEX_AREAS_CREATED: 'áreas de entrega complexa cadastradas',
  REGISTER: 'Cadastrar',
  CHANGE: 'Alterar',
  MEET: 'Conheça',
  UPDATED: 'atualizou em ',
  DEMAND_REGIONS: 'regiões de separação para entrega',
  CREATE_DEMAND_REGIONS:
    'Cadastre e gerencie as regiões de separação para entrega.',
  MESSAGE_ERROR_NOTIFICATION:
    'Algo deu errado! Atualize a página e tente novamente.'
};

export const AGENCY = 'AGENCY';
