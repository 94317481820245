import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ typography, spacing }) => ({
  root: {
    padding: 0
  },
  listItem: {
    padding: spacing(2, 3.5)
  },
  listItemIcon: {
    fontSize: typography.pxToRem(14)
  }
}));

export default useStyles;
