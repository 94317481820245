import React from 'react';

import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { CustomDrawer, DrawerHeader, DrawerContent } from 'shared/drawer';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import { Can } from 'auth/access-control';

import { TEXT } from './circles-drawer.constants';
import { EditButton, useStyles } from './circles-drawer.styles';

const DRAWER_STATUS = {
  type: 'component',
  component: () => {
    return (
      <>
        <Typography noWrap variant="body1" color="textSecondary">
          {TEXT.CIRCLE_TITLE}
        </Typography>
      </>
    );
  }
};

export default function CircleDetailDrawerComponent({
  closeCallback,
  editRegionCallback,
  editDriversCallback,
  open,
  circle
}) {
  const classes = useStyles();

  const people = circle ? [...circle.driversData, ...circle.operatorsData] : [];

  let peopleMessage = '';
  if (people.length === 1) peopleMessage = TEXT.DRIVER;
  else if (people.length > 1) peopleMessage = TEXT.DRIVERS(people.length);

  const TITLE = circle?.name;
  return (
    <CustomDrawer open={open}>
      <DrawerHeader
        handleClosingDrawer={closeCallback}
        showDivider
        title={TITLE}
        status={DRAWER_STATUS}
        detail={TEXT.EDIT_CITIES_SUBTITLE(TITLE)}
        variantTextDetail="subtitle1"
        colorText="textPrimary"
      >
        <Box pt={3}>
          <Can actions={[ACTIONS.CIRCLES_WRITE]}>
            <EditButton color="primary">
              <Typography
                variant="button"
                color="primary"
                data-testid="edit-region-button"
                onClick={() => {
                  editRegionCallback();
                }}
              >
                {TEXT.EDIT_CIRCLE}
              </Typography>
            </EditButton>
          </Can>
        </Box>
      </DrawerHeader>
      <DrawerContent>
        <Grid container direction="column">
          <Grid item>
            <Box py={0.5} display="flex">
              <AvatarGroup max={4}>
                {people.map(person => (
                  <Avatar
                    key={person.id}
                    component="span"
                    className={classes.customAvatar}
                  >
                    <em>{person.name[0]}</em>
                  </Avatar>
                ))}
              </AvatarGroup>
            </Box>
          </Grid>
          <Grid item>
            <Box py={0.5}>
              <Typography variant="subtitle1" color="textPrimary">
                <em>{peopleMessage}</em>
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box py={0.5}>
              <Typography variant="body1" color="textSecondary">
                {TEXT.EDIT_DRIVERS_SUBTITLE}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box pt={3}>
              <Can actions={[ACTIONS.CIRCLES_WRITE]}>
                <EditButton color="primary">
                  <Typography
                    variant="button"
                    color="primary"
                    data-testid="edit-drivers-button"
                    onClick={() => {
                      editDriversCallback();
                    }}
                  >
                    {TEXT.EDIT_PEOPLE}
                  </Typography>
                </EditButton>
              </Can>
            </Box>
          </Grid>
        </Grid>
      </DrawerContent>
    </CustomDrawer>
  );
}

CircleDetailDrawerComponent.propTypes = {
  closeCallback: PropTypes.func.isRequired,
  editRegionCallback: PropTypes.func.isRequired,
  editDriversCallback: PropTypes.func.isRequired,
  open: PropTypes.bool,
  circle: PropTypes.shape({
    name: PropTypes.string,
    cities: PropTypes.arrayOf(PropTypes.string),
    drivers: PropTypes.arrayOf(PropTypes.number),
    driversData: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        avatarSource: PropTypes.string
      })
    ),
    operators: PropTypes.arrayOf(PropTypes.string),
    operatorsData: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        avatarSource: PropTypes.string
      })
    )
  })
};

CircleDetailDrawerComponent.defaultProps = {
  open: false,
  circle: undefined
};
