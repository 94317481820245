import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import showSnackbar from 'shared/snackbar';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { Box, Paper, Typography, Grid, Button, Chip } from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { getCompanyType } from 'auth/access-control/access-control.service';
import { AUTHORIZED_ROUTES } from 'view/constants';
import { ReactComponent as IconBase } from 'images/ic-barcode.svg';
import clsx from 'clsx';
import { useStyles as useStylesIcon } from 'shared/card-icon/card-icon.styles';
import { SummaryContext } from 'tracking/template/summary.context';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { FeatureSwitchContext } from 'infra/firebase/feature-switch-provider';
import { formatDate } from 'finance/finance.configuration';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getSortingContext } from './demand-regions/demand-regions.service';
import { DemandRegionsContext } from './demand-regions/demand-regions.provider';
import { getComplexAreas } from './complex-areas/complex-areas.service';
import ComplexAreasOnBoardingDialog from './complex-areas/complex-areas-on-boarding-dialog';
import CirclesOnboardingDrawer from '../circles/circles-onboarding-drawer';
import DemandRegionsDrawer from './demand-regions/drawer/demand-regions.drawer';
import openingHoursTreatment from './routines.configuration';
import { TEXT, AGENCY } from './routines.constants';
import {
  getNumberDecisions,
  getSimulationContext
} from './demand-regions/demand-regions.utils';
import { RoutinesManagementContext } from '../routines-management.context';

const InfoBlockMedium = ({ header, subHeader }) => (
  <>
    <Box>
      <Typography variant="subtitle1">
        <em>{header}</em>
      </Typography>
    </Box>
    <Box pt={1}>
      <Typography color="textSecondary" variant="body2">
        <em>{subHeader}</em>
      </Typography>
    </Box>
  </>
);

const CardPaper = ({ header, subHeader, children }) => {
  const [cardElevation, setCardElevation] = useState(0);

  const setCardRaised = () => setCardElevation(14);
  const setCardGround = () => setCardElevation(0);
  return (
    <Paper
      component={Box}
      elevation={cardElevation}
      onMouseOver={setCardRaised}
      onMouseOut={setCardGround}
      onFocus={setCardRaised}
      onBlur={setCardGround}
      flexGrow={1}
      minHeight={223}
      p={4}
      marginBottom={3}
    >
      {children}
      <Typography color="textSecondary" variant="body2">
        <em>{header}</em>
      </Typography>
      <Box pt={1}>
        <Typography variant="h6">{subHeader}</Typography>
      </Box>
    </Paper>
  );
};

const CardPaperDemandRegions = ({
  header,
  subHeader,
  showTagNew,
  withoutComplexAreas,
  handleOpen,
  lastUpdated,
  textId
}) => {
  const [cardElevation, setCardElevation] = useState(0);

  const setCardRaised = () => setCardElevation(14);
  const setCardGround = () => setCardElevation(0);

  return (
    <Paper
      component={Box}
      flexGrow={1}
      minHeight={223}
      p={2}
      marginBottom={3}
      data-testid={`routine-${textId}`}
      onClick={() => {
        handleOpen();
      }}
      elevation={cardElevation}
      onMouseOver={setCardRaised}
      onMouseOut={setCardGround}
      onFocus={setCardRaised}
      onBlur={setCardGround}
    >
      <Box ml={2}>
        <Box
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography color="textSecondary" variant="body2">
              <em>{header}</em>
            </Typography>
          </Box>
          {showTagNew && (
            <Box mr={2.5}>
              <Chip
                style={{
                  backgroundColor: colors.blue[100],
                  color: colors.root[900]
                }}
                size="small"
                label={TEXT.NEW}
              />
            </Box>
          )}
        </Box>
        <Box pt={1}>
          <Typography variant="h6">{subHeader}</Typography>
        </Box>
        <Box display="flex" mt={5}>
          {withoutComplexAreas ? (
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              data-testid={`handle-open-${textId}`}
              onClick={() => {
                handleOpen();
              }}
            >
              {TEXT.MEET}
            </Button>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color="textSecondary" variant="body2">
                {TEXT.UPDATED}
                {formatDate(lastUpdated, 'DD MMM [às] HH:mm')}
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="text"
                  size="medium"
                  data-testid={`handle-open-${textId}`}
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  <ChevronRightIcon color="primary" />
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

const RoutineBodySkeleton = () => (
  <>
    <Grid container spacing={5}>
      <Grid item xs>
        <CardPaper>
          <Skeleton
            variant="rect"
            height={30}
            width={150}
            data-testid="routine-skeleton"
          />
        </CardPaper>
      </Grid>
      <Grid item xs>
        <CardPaper>
          <Skeleton variant="rect" height={30} width={150} />
        </CardPaper>
      </Grid>
    </Grid>
  </>
);

export default function RoutineComponent({
  loading,
  routineInfo,
  showCirclesOnboarding
}) {
  const history = useHistory();

  const { setSummary } = useContext(SummaryContext);

  const { enableComplexAreas } = useContext(FeatureSwitchContext);

  const { setCardContent } = useContext(RoutinesManagementContext);

  const [openOnBoarding, setOpenOnBoarding] = useState(false);

  const [openCirclesOnboarding, setOpenCirclesOnboarding] = useState(false);

  const [openDrawerDemandRegions, setOpenDrawerDemandRegions] = useState(false);

  const [complexAreas, setComplexAreas] = useState([]);

  const {
    sortingContext,
    setSortingContext,
    setSimulationSortingContext
  } = useContext(DemandRegionsContext);

  const [lastUpdatedComplexAreas, setLastUpdatedComplexAreas] = useState(null);

  const [lastUpdatedDemandRegions, setLastUpdatedDemandRegions] = useState(
    null
  );

  const classesIcon = useStylesIcon();

  const companyType = getCompanyType();

  const showDemandRegions = IsFSActiveForCurrentBase('enableDemandRegions');

  const StyledIconBase = () => (
    <>
      <Box
        className={clsx(
          classesIcon.iconStyle,
          classesIcon.variantsecondary,
          classesIcon.sizenormal
        )}
      >
        <IconBase />
      </Box>
    </>
  );

  const RoutineTabSkeleton = () => (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <StyledIconBase />
        </Grid>
        <Grid item xs>
          <Grid container spacing={5}>
            <Grid item>
              <Skeleton variant="rect" height={53} width={226} />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" height={53} width={226} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const handleOpenComplexAreas = () => {
    if (complexAreas.length > 0) {
      const rc = getSelectedRoutingCode();
      history.push(`/${rc}${AUTHORIZED_ROUTES.ROUTINES.COMPLEX_AREAS}`);
    } else {
      setOpenOnBoarding(true);
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSummary(
      <>
        <Grid container direction="row">
          <Grid item xs>
            <Typography variant="h4">
              <strong>{TEXT.TITLE}</strong>
            </Typography>
            <Typography variant="h4">{TEXT.SUBTITLE}</Typography>
          </Grid>
        </Grid>
      </>
    );
    const rc = getSelectedRoutingCode();
    if (companyType === AGENCY && enableComplexAreas) {
      getComplexAreas(rc)
        .then(response => {
          setComplexAreas(response.data.definedRegions || []);
          setLastUpdatedComplexAreas(response.data.lastUpdated || null);
        })
        .catch(() => {
          setComplexAreas(null);
          setLastUpdatedDemandRegions(null);
        });
    }

    if (companyType === AGENCY && showDemandRegions) {
      getSortingContext(rc)
        .then(response => {
          setSortingContext(response?.data?.sortingContext || null);
          setLastUpdatedDemandRegions(
            response.data.sortingContext.lastUpdated || null
          );
        })
        .catch(() => {
          showSnackbar({
            message: TEXT.MESSAGE_ERROR_NOTIFICATION,
            enqueueSnackbar,
            withButton: true,
            variant: 'error'
          });
        });

      getSimulationContext(rc)
        .then(response => {
          setSimulationSortingContext(response || null);
        })
        .catch(() => {
          setSimulationSortingContext(null);
        });
    }
  }, [
    setSummary,
    companyType,
    enableComplexAreas,
    showDemandRegions,
    setSortingContext,
    setSimulationSortingContext,
    enqueueSnackbar
  ]);

  useEffect(() => {
    setCardContent(
      <>
        {loading && <RoutineTabSkeleton />}
        {!loading && (
          <Grid
            container
            spacing={3}
            data-testid="card-icon"
            alignItems="flex-start"
          >
            <Grid item>
              <StyledIconBase />
            </Grid>
            <Grid item xs>
              <Box pt={0.5} pb={5.5}>
                <Typography variant="h6">
                  <em>{`${TEXT.BASE} ${routineInfo.dcInfo.dc.name} / ${
                    routineInfo.dcInfo.address.coordinatesAdministrativeArea
                  }`}</em>
                </Typography>
              </Box>
              <Grid container spacing={5}>
                <Grid item>
                  <InfoBlockMedium
                    header={routineInfo.dcInfo.dc.routing_code}
                    subHeader={TEXT.ROUTING_CODE}
                  />
                </Grid>
                <Grid item>
                  <InfoBlockMedium
                    header={formatDate(
                      routineInfo.last_mile_company.created,
                      'MMM[, ]YYYY'
                    )}
                    subHeader={TEXT.START_OF_OPERATION}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, setCardContent]);

  useEffect(() => {
    if (showCirclesOnboarding) setOpenCirclesOnboarding(showCirclesOnboarding);
  }, [showCirclesOnboarding]);

  return (
    <>
      {loading && <RoutineBodySkeleton />}
      {!loading && (
        <>
          <Grid container spacing={5}>
            <Grid item xs>
              <CardPaper
                header={TEXT.ADDRESS}
                subHeader={
                  routineInfo.dcInfo.address.postalAddress.addressLines[0]
                }
              />
            </Grid>
            <Grid item xs>
              <CardPaper
                header={TEXT.WORKING_HOURS}
                subHeader={
                  routineInfo.dcInfo.dc.working_time
                    ? openingHoursTreatment(
                        JSON.parse(routineInfo.dcInfo.dc.working_time)
                      ).join(' ')
                    : TEXT.NO_DATA
                }
              />
            </Grid>
            {companyType === AGENCY && enableComplexAreas && (
              <Grid item xs>
                <CardPaperDemandRegions
                  header={TEXT.SERVICE_AREA}
                  subHeader={
                    complexAreas.length > 0
                      ? `${complexAreas.length} ${TEXT.COMPLEX_AREAS_CREATED}`
                      : TEXT.COMPLEX_AREA
                  }
                  withoutComplexAreas={complexAreas.length === 0}
                  showTagNew={complexAreas.length === 0}
                  handleOpen={handleOpenComplexAreas}
                  lastUpdated={lastUpdatedComplexAreas}
                  textId="complex-areas"
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={5}>
            {companyType === AGENCY && showDemandRegions && (
              <Grid item xs={4}>
                <CardPaperDemandRegions
                  header={TEXT.SERVICE_AREA}
                  subHeader={
                    sortingContext?.decisions?.length > 0
                      ? `${getNumberDecisions(sortingContext)} ${
                          TEXT.DEMAND_REGIONS
                        }`
                      : TEXT.CREATE_DEMAND_REGIONS
                  }
                  handleOpen={() => setOpenDrawerDemandRegions(true)}
                  lastUpdated={lastUpdatedDemandRegions}
                  textId="demand-regions"
                  withoutComplexAreas={sortingContext?.decisions?.length === 0}
                  showTagNew
                />
              </Grid>
            )}
          </Grid>
          <Box pt={2.5}>
            <Alert variant="filled" severity="info">
              <em>{TEXT.SOMETHING_WRONG}</em> {TEXT.CONTACT_SUPPORT}
            </Alert>
          </Box>
          {openOnBoarding && (
            <ComplexAreasOnBoardingDialog
              open
              onClose={() => setOpenOnBoarding(false)}
            />
          )}
          {openCirclesOnboarding && (
            <CirclesOnboardingDrawer
              open
              onClose={() => setOpenCirclesOnboarding(false)}
            />
          )}
          {openDrawerDemandRegions && (
            <DemandRegionsDrawer
              open
              onClose={() => setOpenDrawerDemandRegions(false)}
            />
          )}
        </>
      )}
    </>
  );
}

RoutineComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  routineInfo: PropTypes.shape({
    dcInfo: PropTypes.shape({
      dc: PropTypes.shape({
        working_time: PropTypes.string,
        routing_code: PropTypes.string,
        name: PropTypes.string
      }),
      address: PropTypes.shape({
        coordinatesAdministrativeArea: PropTypes.string,
        postalAddress: PropTypes.shape({
          addressLines: PropTypes.arrayOf(PropTypes.string)
        })
      })
    }),
    last_mile_company: PropTypes.shape({
      created: PropTypes.string
    })
  }).isRequired,
  showCirclesOnboarding: PropTypes.bool
};

RoutineComponent.defaultProps = {
  showCirclesOnboarding: false
};

InfoBlockMedium.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired
};

CardPaper.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  children: PropTypes.node
};

CardPaper.defaultProps = {
  header: null,
  subHeader: null,
  children: null
};

CardPaperDemandRegions.defaultProps = {
  withoutComplexAreas: false
};

CardPaperDemandRegions.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  withoutComplexAreas: PropTypes.bool,
  showTagNew: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired
};
