import React, { useContext } from 'react';
import { Box, Drawer } from '@material-ui/core';
import useStyles from './complex-areas.style';
import { ComplexAreasContext } from './complex-areas.provider';
import ComplexAreasMapComponent from './complex-areas-map.component';
import DrawerInitialComplexAreasComponent from './drawer-initial-complex-areas.component';
import DrawerCreateUpdateComplexAreaComponent from './drawer-create-update-complex-area.component';

const ComplexAreasComponent = () => {
  const { complexAreas, openCreateUpdateDrawerComplexAreas } = useContext(
    ComplexAreasContext
  );

  const classes = useStyles();

  return (
    <Drawer
      data-testid="drawer"
      open
      anchor="left"
      variant="temporary"
      className={classes.divDrawer}
    >
      <Box
        data-testid="complex-areas-component"
        component="div"
        overflow="auto"
        display="flex"
        height="100%"
      >
        <Box
          maxWidth={480}
          width={480}
          minWidth={480}
          role="presentation"
          component="div"
          overflow="auto"
        >
          {openCreateUpdateDrawerComplexAreas ? (
            <DrawerCreateUpdateComplexAreaComponent />
          ) : (
            <DrawerInitialComplexAreasComponent />
          )}
        </Box>
        <Box
          width="100%"
          height="100%"
          data-testid="component-google-map-react"
        >
          <ComplexAreasMapComponent complexAreas={complexAreas} />
        </Box>
      </Box>
    </Drawer>
  );
};

ComplexAreasComponent.propTypes = {};

export default ComplexAreasComponent;
