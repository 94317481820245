export const TEXT_PACKAGE_NOT_FOUND = 'Pacote não veio';

export const DRAWER_REFUSE = {
  title: 'Recusar pacote',
  subtitle: 'Por que vai recusar este pacote?',
  options: {
    DAMAGED: {
      text: 'Está danificado',
      notes: 'Está danificado'
    },
    FROM_ANOTHER_PLACE: {
      text: 'É de outra região de entrega',
      notes: 'É de outra região de entrega'
    },
    PACKAGE_NOT_FOUND: {
      text: TEXT_PACKAGE_NOT_FOUND,
      destinationIsNull: true,
      notes: 'Pacote não veio'
    },
    ANOTHER_REASON: {
      text: 'Outro motivo',
      isExpectNotes: true
    }
  },
  confirm: 'Recusar',
  placeHolderNotes: 'O que aconteceu?'
};

export const DRAWER_REFUSE_LIST_OPTIONS = Object.values(DRAWER_REFUSE.options);

export default DRAWER_REFUSE;
