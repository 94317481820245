import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as GoogleIcon } from 'images/ic-google.svg';

import { CredentialsContext } from './credentials.context';
import { TEXT, BUTTON } from './login.constants';
import { useStyles } from './login.styles';

const GoogleSignIn = ({ federatedSignIn }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Typography className={classes.boxSpan}>
        <span>{TEXT.OR}</span>
      </Typography>
      <Box boxShadow={15} borderRadius={23} mt={5}>
        <Button
          variant="text"
          color="default"
          fullWidth
          onClick={() => {
            federatedSignIn('Google');
          }}
        >
          <GoogleIcon className={classes.googleIcon} /> {BUTTON.GOOGLE}
        </Button>
      </Box>
    </Grid>
  );
};

GoogleSignIn.propTypes = {
  federatedSignIn: PropTypes.func
};

GoogleSignIn.defaultProps = {
  federatedSignIn: () => {}
};

export default function Login({
  handleLogin,
  federatedSignIn,
  forgotPassword
}) {
  const { credentials, loading, setCredentials } = useContext(
    CredentialsContext
  );
  const [errors, setErrors] = useState({ value: null, hasErrors: false });

  const onBlurEmail = () => {
    const regexEmailValidation = /\S+@\S+\.\S+/;

    if (
      credentials.username !== '' &&
      !regexEmailValidation.test(credentials.username)
    ) {
      setErrors({
        value: TEXT.INVALID_EMAIL,
        hasErrors: true
      });
    } else {
      setErrors({
        value: null,
        hasErrors: false
      });
    }
  };

  const onSubmitForm = event => {
    if (event) event.preventDefault();
    handleLogin(credentials.username, credentials.password);
  };

  const handleTyping = (value, field) => {
    setCredentials(_credentials => {
      return {
        ..._credentials,
        [field]: value
      };
    });
  };

  const validateDisableSubmit = () => {
    return (
      credentials.username === '' ||
      credentials.password === '' ||
      loading ||
      errors.hasErrors
    );
  };

  const classes = useStyles();
  return (
    <Grid container className={classes.grid}>
      <Grid item xs={12} sm={12} md={6} lg={6} className={classes.root}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.alignLogo}
        >
          <Link
            href="http://www.loggi.com"
            target="_blank"
            color="inherit"
            style={{ width: '430px', textAlign: 'left' }}
          >
            <Logo style={{ width: '100px', height: '100px' }} />
          </Link>
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.grid}
        >
          <Box style={{ maxWidth: '430px' }}>
            <Box marginBottom={3}>
              <Typography variant="h2" component="span">
                {TEXT.WELCOME.TITLE}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" component="span">
                {TEXT.WELCOME.CONTAINER}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid style={{ maxWidth: '430px' }}>
            <Box marginBottom={6}>
              <Typography variant="h4" component="h1">
                {TEXT.TITLE_FORM}
              </Typography>
            </Box>
            <Box>
              <form>
                <Box marginBottom={2}>
                  <TextField
                    className={classes.textfield}
                    defaultValue={credentials?.username}
                    variant="outlined"
                    label={TEXT.LABEL_EMAIL}
                    onBlur={() => onBlurEmail()}
                    onChange={e => handleTyping(e.target.value, 'username')}
                    disabled={loading}
                    inputProps={{ 'data-testid': 'emailInput' }}
                    error={errors.hasErrors}
                  />
                  {errors.hasErrors && (
                    <Typography className={classes.errors}>
                      {errors.value}
                    </Typography>
                  )}
                </Box>
                <Box marginBottom={2}>
                  <TextField
                    className={classes.textfield}
                    defaultValue={credentials?.password}
                    variant="outlined"
                    label={TEXT.LABEL_PASSWORD}
                    type="password"
                    inputProps={{ 'data-testid': 'passwordInput' }}
                    onChange={e => handleTyping(e.target.value, 'password')}
                    disabled={loading}
                  />
                </Box>
                <Grid container justify="flex-end">
                  <Typography
                    variant="body2"
                    component="span"
                    onClick={forgotPassword}
                    align="center"
                    className={classes.link}
                    data-testid="forgot-pass"
                  >
                    {TEXT.FORGOT_PASSWORD}
                  </Typography>
                </Grid>
                <Box mt={3.5} textAlign="center">
                  <Button
                    className={classes.button}
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    onClick={e => onSubmitForm(e)}
                    data-testid="submit-login"
                    disabled={validateDisableSubmit()}
                  >
                    {loading ? BUTTON.LOADING : BUTTON.SUBMIT}
                  </Button>
                  <GoogleSignIn federatedSignIn={federatedSignIn} />
                </Box>
              </form>
            </Box>
            <Box marginTop={5}>
              <Typography
                variant="body2"
                component="span"
                align="center"
                className={classes.span}
              >
                {TEXT.NOT_REGISTERED}
                <Link
                  href="https://loggi.com/leve/form.html"
                  variant="body2"
                  className={classes.link}
                >
                  {TEXT.SCHELDULE_CHAT}
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Login.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  federatedSignIn: PropTypes.func,
  forgotPassword: PropTypes.func
};
Login.defaultProps = {
  federatedSignIn: () => {},
  forgotPassword: () => {}
};
