export const BUTTON = {
  CONFIRM_PACKAGES_OUT_OF_COVERAGE: 'Entendi'
};

export const TEXT = {
  PACKAGE_IS_OUT_OF_COVERAGE: {
    title: 'O pacote é de outra região de entrega',
    subtitle: 'Para continuar, você deve transferir este pacote para a Loggi.'
  },
  PACKAGES_ARE_OUT_OF_COVERAGE: {
    title: 'Alguns pacotes da saca não são da sua região de entrega',
    subtitle: 'Para continuar, você deve transferir estes pacotes para a Loggi.'
  }
};

export const STATUS_ERROR_CODE = {
  PACKAGES_OUT_OF_COVERAGE: 425
};

export default { BUTTON, TEXT, STATUS_ERROR_CODE };
