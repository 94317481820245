// verify the meaning of action in this doc:
// https://docs.google.com/spreadsheets/d/1_CU0ffqbCrU-CEPeMIli5zLGatU7RuVfs_v0V4qfk7A/

import { KEY_CUSTODY } from 'tracking/tracking.constants';

export const ACTIONS = {
  TRACKING_READ: 'tracking:read',
  ASSIGNMENT_READ: 'assignment:read',
  RETURN_LOGGI_READ: 'return-loggi:read',
  ASSIGNMENT_MENU_READ: 'assignment:menu:read',
  COMPLETE_MOVEMENT_MENU_READ: 'complete_movement_menu:read',
  DELIVERER_READ: 'deliverer:read',
  RECEIVE_READ: 'receive:read',
  MISSED_PROMISED_DATE_READ: 'missed-promised-date:read',
  FINANCE_READ: 'finance:read',
  MENU_FINANCE_READ: 'menufinance:read',
  HELP_REDIRECT_LEVE: 'help-leve:read',
  DRAWER_ACTIONS: 'drawer-actions:write',
  GROUP_UNIT_LOAD: {
    [KEY_CUSTODY.NA_BASE]: 'na-base-group-unit-load:read',
    [KEY_CUSTODY.NA_RUA]: 'na-rua-group-unit-load:read'
  },
  SEARCH_PACKAGES_READ: 'search-packages:read',
  CARRIER_MENU: 'carrier-menu:read',
  REFUSE_PACKAGE_WRITE: 'refuse-package:write',
  USER_MANAGEMENT_READ: 'user-management:read',
  USER_MANAGEMENT_BASE_WRITE: 'user-management-base:write',
  BTN_CALCULATE_PROMISED_DATE: 'calculate:promised-dated',
  TOGGLE_NEW_BASE: 'toggle_new_base',
  TRACKING_OPERATOR: 'menu-tracking-operator:read',
  ROUTINES_READ: 'routines:read',
  OFFER_READ: 'offer:read',
  ARRIVALS_READ: 'arrivals:read',
  FINISHED_READ: 'finished:read',
  CIRCLES_WRITE: 'circles:write',
  CIRCLES_WRITE_FOR_OPERATOR: 'circles:write-for-operator',
  TO_TRANSFER_READ: 'to-transfer:read',
  ONBOARDING_PERMISSIONAMENTO: 'onboarding-permissionamento:read',
  DRAWER_OFFER_COMPENSATION_VALUE: 'drawer-offer-compensation-value:read',
  DRAWER_OFFER_DRIVER_PHONE: 'drawer-offer-driver-phone:read',
  IN_STREET_LEVE: 'in-street-leve:read',
  IN_STREET_AGENCY: 'in-street-agency:read',
  FILTER_IN_VIEW_COLLECTION: 'FilterInViewCollection:read',
  HAS_CIRCLES_ENABLED: 'hasCirclesEnabled:read',
  HAS_FILTER_CITIES_ENABLED: 'hasFilterCitiesEnabled:read'
};
export const BASE_ANALYST = 'BASE_ANALYST';

const basicPermissions = [
  ACTIONS.TRACKING_READ,
  ACTIONS.ASSIGNMENT_READ,
  ACTIONS.RETURN_LOGGI_READ,
  ACTIONS.COMPLETE_MOVEMENT_MENU_READ,
  ACTIONS.DELIVERER_READ,
  ACTIONS.RECEIVE_READ,
  ACTIONS.MISSED_PROMISED_DATE_READ,
  ACTIONS.HELP_REDIRECT_LEVE,
  ACTIONS.DRAWER_ACTIONS,
  ACTIONS.SEARCH_PACKAGES_READ,
  ACTIONS.USER_MANAGEMENT_READ,
  ACTIONS.GROUP_UNIT_LOAD[KEY_CUSTODY.NA_RUA],
  ACTIONS.ROUTINES_READ,
  ACTIONS.OFFER_READ,
  ACTIONS.ARRIVALS_READ,
  ACTIONS.FINISHED_READ,
  ACTIONS.TO_TRANSFER_READ,
  ACTIONS.REFUSE_PACKAGE_WRITE,
  ACTIONS.TOGGLE_NEW_BASE,
  ACTIONS.DRAWER_OFFER_COMPENSATION_VALUE,
  ACTIONS.DRAWER_OFFER_DRIVER_PHONE,
  ACTIONS.IN_STREET_LEVE,
  ACTIONS.HAS_CIRCLES_ENABLED,
  ACTIONS.HAS_FILTER_CITIES_ENABLED
];

const basicAgencyPermissions = [
  ACTIONS.TRACKING_READ,
  ACTIONS.GROUP_UNIT_LOAD[KEY_CUSTODY.NA_BASE],
  ACTIONS.GROUP_UNIT_LOAD[KEY_CUSTODY.NA_RUA],
  ACTIONS.SEARCH_PACKAGES_READ,
  ACTIONS.USER_MANAGEMENT_READ,
  ACTIONS.TOGGLE_NEW_BASE,
  ACTIONS.ROUTINES_READ,
  ACTIONS.REFUSE_PACKAGE_WRITE,
  ACTIONS.OFFER_READ,
  ACTIONS.ARRIVALS_READ,
  ACTIONS.FINISHED_READ,
  ACTIONS.DRAWER_OFFER_COMPENSATION_VALUE,
  ACTIONS.DRAWER_OFFER_DRIVER_PHONE,
  ACTIONS.IN_STREET_AGENCY,
  ACTIONS.FILTER_IN_VIEW_COLLECTION
];

const basicCarrierPermissions = [
  ACTIONS.DELIVERER_READ,
  ACTIONS.SEARCH_PACKAGES_READ,
  ACTIONS.CARRIER_MENU,
  ACTIONS.USER_MANAGEMENT_READ,
  ACTIONS.ROUTINES_READ,
  ACTIONS.OFFER_READ,
  ACTIONS.ARRIVALS_READ,
  ACTIONS.FINISHED_READ
];

export const rules = {
  LEVE: {
    // cognito:group
    BASE_ANALYST: [
      ...basicPermissions,
      ACTIONS.FINANCE_READ,
      ACTIONS.MENU_FINANCE_READ,
      ACTIONS.USER_MANAGEMENT_BASE_WRITE,
      ACTIONS.BTN_CALCULATE_PROMISED_DATE,
      ACTIONS.CIRCLES_WRITE
    ],

    // custom:access
    OWNER: [
      ...basicPermissions,
      ACTIONS.FINANCE_READ,
      ACTIONS.MENU_FINANCE_READ,
      ACTIONS.USER_MANAGEMENT_BASE_WRITE,
      ACTIONS.BTN_CALCULATE_PROMISED_DATE,
      ACTIONS.CIRCLES_WRITE,
      ACTIONS.TO_TRANSFER_READ
    ],
    ADMIN: [
      ...basicPermissions,
      ACTIONS.BTN_CALCULATE_PROMISED_DATE,
      ACTIONS.CIRCLES_WRITE
    ],
    REGULAR: [
      ...basicPermissions,
      ACTIONS.BTN_CALCULATE_PROMISED_DATE,
      ACTIONS.CIRCLES_WRITE
    ],
    OPERATOR: [
      ACTIONS.TRACKING_OPERATOR,
      ACTIONS.ASSIGNMENT_READ,
      ACTIONS.ASSIGNMENT_MENU_READ,
      ACTIONS.SEARCH_PACKAGES_READ,
      ACTIONS.GROUP_UNIT_LOAD[KEY_CUSTODY.NA_RUA],
      ACTIONS.DRAWER_ACTIONS,
      ACTIONS.ONBOARDING_PERMISSIONAMENTO,
      ACTIONS.CIRCLES_WRITE_FOR_OPERATOR
    ],
    // custom:access - Agency
    BS_RO: [...basicPermissions]
  },
  AGENCY: {
    // cognito:group
    BASE_ANALYST: [
      ...basicAgencyPermissions,
      ACTIONS.USER_MANAGEMENT_BASE_WRITE
    ],
    // custom:access
    OWNER: [...basicAgencyPermissions],
    ADMIN: [...basicAgencyPermissions],
    REGULAR: [...basicAgencyPermissions],
    OPERATOR: [...basicAgencyPermissions],
    // custom:access - Agency
    BS_RO: [...basicAgencyPermissions, ACTIONS.REFUSE_PACKAGE_WRITE]
  },
  CARRIER: {
    // custom:access
    OWNER: [
      ...basicCarrierPermissions,
      ACTIONS.USER_MANAGEMENT_BASE_WRITE,
      ACTIONS.CIRCLES_WRITE
    ],
    ADMIN: [...basicCarrierPermissions],
    REGULAR: [...basicCarrierPermissions],
    OPERATOR: [...basicCarrierPermissions],
    // cognito:group
    BASE_ANALYST: [
      ...basicCarrierPermissions,
      ACTIONS.USER_MANAGEMENT_BASE_WRITE
    ]
  }
};
