import api from 'infra/api/leve';

import moment from 'moment';

import { DATE_FILTER } from 'tracking/tracking.constants';

export const fetchPackageAggregatedData = ({
  filters = {},
  aggregatedField,
  startDate = '',
  endDate = '',
  view,
  dateFilter = ''
}) => {
  const deliverersFilter = filters.deliverers || [];
  const statusFilters = filters.status || [];
  const citiesFilter = filters.cities || [];
  const circlesFilter = filters.circles || [];
  const faultsFilter = filters.faults || [];
  const updatesFilter = { endDate, startDate };
  const qualityFlagsFilter = filters.qualityFlags || [];

  let dateFilterUpdates = '';

  if (filters.updates) {
    updatesFilter.endDate = filters.updates[0] || endDate;
    updatesFilter.startDate = filters.updates[0]
      ? moment()
          .subtract(60, 'days')
          .format()
      : startDate;
    dateFilterUpdates = DATE_FILTER.SORTING_RECORD_CREATED;
  }

  return api
    .post('/api/v2/last_mile/packages/aggregated', {
      aggregatedField,
      startDate: updatesFilter.startDate,
      endDate: updatesFilter.endDate,
      dateFilter: dateFilter || dateFilterUpdates,
      statusFilters,
      citiesFilter,
      circlesFilter,
      view,
      deliverersFilter,
      faultsFilter,
      qualityFlagsFilter
    })
    .then(response => response.data);
};

export default fetchPackageAggregatedData;
