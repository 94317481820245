export const PHONE = {
  MASK: '(99) 99999-9999',
  ERROR: 'Digite um número de celular válido.',
  LABEL: 'Celular'
};

export const CPF = {
  MASK: '999.999.999-99',
  ERROR: 'Digite um CPF válido.',
  LABEL: 'CPF'
};
