import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { interpret, Interpreter } from 'xstate';
import { useService } from '@xstate/react';
import { Box } from '@material-ui/core';
import { AUTHORIZED_ROUTES } from 'view/constants';

import { getSelectedRoutingCode } from 'auth/login/login.service';
import drawerMachine from '../drawer.machine';
import changeDelivererMachine, {
  ACTIONS,
  STATES
} from './drawer-change-deliverer.machine';
import {
  ChangeDelivererSelecting,
  ChangeDelivererFetching,
  ChangeDelivererFetchFailure,
  ChangeDelivererDialog
} from './drawer-change-deliverer.component';

export default function DrawerChangeDeliverer({ changeDelivererMachineRef }) {
  const [current, send] = useService(changeDelivererMachineRef);
  const {
    barcode,
    selectedDelivererId,
    selectedDelivererFullName,
    deliverersDisplayed,
    searchValue,
    openDialog
  } = current.context;

  const isFlowFetching = current.value === STATES.FETCHING;
  const isFlowFetchFailure = current.value === STATES.FETCH_FAILURE;
  const isFlowSelecting = current.value === STATES.SELECTING;
  const isFlowSubmitting = current.value === STATES.SUBMITTING;
  const isFlowFeedbackDialog = current.value === STATES.FEEDBACK_DIALOG;

  const onChangeInputSearch = event =>
    send(ACTIONS.SEARCH_INPUT, { value: event.target.value });

  const onSelectDeliverer = deliverer =>
    send(ACTIONS.SELECT_DELIVERER, {
      id: deliverer.id,
      fullName: deliverer.fullName
    });

  const onRestoreList = () => send(ACTIONS.RESTORE_LIST);

  const reloadFetch = () => send(ACTIONS.RELOAD_FETCH);

  const onConfirmChange = () => send(ACTIONS.CONTINUE_BUTTON);

  const dialogUnderstood = () => send(ACTIONS.UNDERSTOOD);

  const history = useHistory();
  const dialogSeeAtStreet = () => {
    dialogUnderstood();
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${AUTHORIZED_ROUTES.TRACKING.IN_STREET}`);
  };

  return (
    <Box data-testid="drawer-change-deliverer.container">
      {isFlowSelecting && (
        <ChangeDelivererSelecting
          deliverersDisplayed={deliverersDisplayed}
          barcode={barcode}
          selectedDelivererId={selectedDelivererId}
          onChangeInputSearch={onChangeInputSearch}
          onSelectDeliverer={onSelectDeliverer}
          onRestoreList={onRestoreList}
          searchValue={searchValue}
          onConfirmChange={onConfirmChange}
        />
      )}
      {(isFlowFetching || isFlowSubmitting) && <ChangeDelivererFetching />}
      {isFlowFetchFailure && (
        <ChangeDelivererFetchFailure reloadFetch={reloadFetch} />
      )}
      {isFlowFeedbackDialog && (
        <ChangeDelivererDialog
          dialogSeeAtStreet={dialogSeeAtStreet}
          dialogUnderstood={dialogUnderstood}
          delivererFullName={selectedDelivererFullName}
          openDialog={openDialog}
        />
      )}
    </Box>
  );
}

// this function is use at storybook and tests only
export const makeChangeDelivererMachineRef = (context = {}) => {
  const service = interpret(changeDelivererMachine, {
    context: changeDelivererMachine.withContext({
      ...changeDelivererMachine.context,
      ...context
    }),
    parent: interpret(drawerMachine).start()
  });
  service.start();
  return service;
};

DrawerChangeDeliverer.propTypes = {
  changeDelivererMachineRef: PropTypes.instanceOf(Interpreter).isRequired
};
