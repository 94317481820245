import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { ReactComponent as LogoLoggi } from 'images/logo-small.svg';
import IconButton from '@material-ui/core/IconButton';
import { ProfileContainer } from 'profile';
import { colors } from '@loggi/mar';
import ButtonNotificationsComponent from 'information/notifications/button/button.component';
import { Icon } from '@loggi/loggi-icons';
import { useStyles } from './menu-responsive.styles';
import { HELP_LEVE_URL } from '../menu.constants';
import DrawerMenuComponent from './drawer-menu.component';

const MenuResponsiveComponent = () => {
  const classes = useStyles();
  const [openDrawerMenu, setOpenDrawerMenu] = useState(false);

  return (
    <>
      <Box
        ml={2.5}
        mr={2}
        left={0}
        right={0}
        display="flex"
        flexDirection="column"
        data-testid="menu-responsive"
        position="absolute"
        zIndex={1000}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          justifyItems="center"
          mt={3.5}
        >
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => setOpenDrawerMenu(true)}>
              <Icon name="Menu01" size="medium" color={colors.root[0]} />
            </IconButton>
            <LogoLoggi className={classes.logo} data-testid="logo" />
          </Box>
          <Box pr={3} display="flex" alignItems="center" justifyItems="center">
            <ButtonNotificationsComponent />

            <Box
              display="flex"
              justifyContent="center"
              mr={2}
              onClick={() => {
                window.open(HELP_LEVE_URL, '_blank');
              }}
              data-testid="menu-mobile-help-button"
            >
              <Icon name="CircleQuestion" size="small" color={colors.root[0]} />
            </Box>
            <ProfileContainer style={{ marginLeft: '10px' }} />
          </Box>
        </Box>
      </Box>
      {openDrawerMenu && (
        <DrawerMenuComponent
          openDrawerMenu={openDrawerMenu}
          handleCloseDrawer={() => setOpenDrawerMenu(false)}
        />
      )}
    </>
  );
};

export default MenuResponsiveComponent;
