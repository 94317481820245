import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Paper } from '@material-ui/core';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useStyles
} from './accordion.styles';

function GetSummaryRow({
  dataRow,
  classes,
  keysColumns,
  summaryConfig,
  getTableCellContent
}) {
  const [open, setOpen] = useState(false);

  const handleChange = () => {
    setOpen(!open);
  };

  return (
    <AccordionSummary
      aria-controls="panel1c-content"
      id="panel1c-header"
      key={dataRow.id}
      onClick={handleChange}
    >
      {keysColumns.map((columnsName, index) => {
        const keyConcat = `${dataRow.id}${index}`;
        // The first position (index 0) represents the collapsible icon,
        // that is, it should have no padding.
        const paddingAxis = index ? 2 : 0;
        return (
          <Box
            display="flex"
            alignItems="center"
            key={keyConcat}
            data-testid={`${keyConcat}-collapsible`}
            className={classes.column}
            px={paddingAxis}
            style={summaryConfig[columnsName].cellStyle}
          >
            {getTableCellContent(dataRow, columnsName, summaryConfig, open)}
          </Box>
        );
      })}
    </AccordionSummary>
  );
}

GetSummaryRow.propTypes = {
  dataRow: PropTypes.shape({ id: PropTypes.string }).isRequired,
  keysColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  summaryConfig: PropTypes.objectOf(
    PropTypes.shape({
      header: PropTypes.string,
      cellStyle: PropTypes.shape,
      component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    })
  ).isRequired,
  getTableCellContent: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired
};

function AccordionComponent({
  data,
  summaryConfig,
  detailsConfig,
  handleOpenDrawer
}) {
  const classes = useStyles();

  const keysColumns = Object.keys(summaryConfig);
  const keysColumnsDetails = Object.keys(detailsConfig);

  const getTableCellContent = (dataRow, columnsName, config, open) => {
    if (columnsName === 'collapsible') {
      return config[columnsName].component(open);
    }
    return config[columnsName].component(dataRow);
  };

  const handleOrderClicked = offerId => {
    handleOpenDrawer(offerId);
  };

  return (
    <>
      {/* header part */}
      <Paper
        display="flex"
        component={Box}
        className={classes.header}
        p={2}
        minHeight={48}
      >
        {Object.entries(summaryConfig).map(([key, { header, cellStyle }]) => {
          // The first position (index 0) represents the collapsible icon,
          // but in the header, it is only an empty string. 1.5 padding is
          // the space that it occupies.
          const paddingAxis = header ? 2 : 1.5;
          return (
            <Box
              display="flex"
              alignItems="center"
              key={key}
              data-testid={`${key}-header`}
              className={classes.column}
              style={cellStyle}
              px={paddingAxis}
            >
              <Typography variant="body2">{header}</Typography>
            </Box>
          );
        })}
      </Paper>

      {/* body part */}
      {data.map(dataItem => {
        return (
          <Accordion key={dataItem.id}>
            {/* summary rows part */}

            <GetSummaryRow
              dataRow={dataItem}
              classes={classes}
              keysColumns={keysColumns}
              summaryConfig={summaryConfig}
              getTableCellContent={getTableCellContent}
            />

            {/* details part (inner summary rows info) */}
            <AccordionDetails className={classes.details}>
              <Box
                display="flex"
                flexDirection="column"
                style={{ flexBasis: '100%' }}
              >
                {/* header for detail part */}
                <Paper
                  display="flex"
                  component={Box}
                  className={classes.headerDetails}
                  p={2}
                  minHeight={48}
                >
                  {Object.entries(detailsConfig).map(([key, { header }]) => {
                    return (
                      <Box
                        display="flex"
                        justifyContent="left"
                        key={key}
                        data-testid={`${key}-detail-header`}
                        className={classes.column}
                        px={2}
                      >
                        <Typography variant="body2">{header}</Typography>
                      </Box>
                    );
                  })}
                </Paper>
                {/* content for detail part */}
                <Box
                  display="flex"
                  flexDirection="row"
                  style={{ flexBasis: '100%' }}
                >
                  {/* rendering each column at a time */}
                  {keysColumnsDetails.map((columnsName, index) => {
                    const keyConcat = `${columnsName}${index}`;
                    return (
                      <Box key={keyConcat} className={classes.column}>
                        {dataItem.pickupOfferList.map(offer => (
                          <Box
                            display="flex"
                            alignItems="center"
                            key={offer.id}
                            data-testid={`${offer.id}-collapsible`}
                            className={classes.column}
                            py={2.5}
                            px={2}
                            onClick={() => handleOrderClicked(offer.id)}
                          >
                            {getTableCellContent(
                              { pickup: dataItem, offer },
                              columnsName,
                              detailsConfig
                            )}
                          </Box>
                        ))}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}

AccordionComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  summaryConfig: PropTypes.objectOf(
    PropTypes.shape({
      header: PropTypes.string,
      cellStyle: PropTypes.shape,
      component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    })
  ).isRequired,
  detailsConfig: PropTypes.objectOf(
    PropTypes.shape({
      component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    })
  ).isRequired,
  handleOpenDrawer: PropTypes.func.isRequired
};

export default AccordionComponent;
