import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export default makeStyles(({ palette, typography }) => ({
  textPrimary: {
    background: palette.common.white,
    border: 0,
    color: colors.blue[500],
    fontSize: typography.pxToRem(12),
    margin: `0 0 0 ${typography.pxToRem(14)}`,
    boxShadow: '0 5px 8px 0 rgba(139,159,183,0.2)'
  },
  selected: {
    boxShadow: '0 0'
  },
  disabled: {
    color: colors.smoke[300]
  },
  gridLayout: {
    marginBottom: typography.pxToRem(40)
  }
}));
