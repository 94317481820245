import React from 'react';
import PropTypes from 'prop-types';
import { useService } from '@xstate/react';
import { interpret, Interpreter } from 'xstate';
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  Button,
  CircularProgress,
  TextField
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import StatusColorDescription from 'shared/status-color-description';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import updateStatusMachine, {
  ACTIONS,
  STATES
} from './drawer-update-status.machine';
import { useStyles } from './drawer-update-status.styles';
import {
  DRAWER_UPDATE_STATUS,
  DRAWER_STATUS_LIST_OPTIONS,
  STATUS_DELIVERED,
  STATUS_RETURNED
} from './drawer-update-status.constants';
import drawerMachine from '../drawer.machine';
import { PACKAGE_DIRECTION_RETURN } from '../drawer.constants';

const PACKAGE_STATUS_CODE_INVALID_DESTINATION = 13;
const PACKAGE_STATUS_CODE_DELIVERY_FAILURE = [
  PACKAGE_STATUS_CODE_INVALID_DESTINATION
];

export function statusOptionsToDisplayForPackagesOnTheBase(
  statusOption,
  isReturnDirection,
  enableAwaitingResolutionOptionByLmc,
  packageHistoryHasAtLeastOneDeliveryAttempt
) {
  if (!isReturnDirection) {
    // should only display options delivery and awaiting resolution (based on delivery attempt history)
    return (
      statusOption === DRAWER_UPDATE_STATUS.options.AWAITING_RESOLUTION &&
      packageHistoryHasAtLeastOneDeliveryAttempt &&
      enableAwaitingResolutionOptionByLmc
    );
  }

  return false;
}

export function statusOptionsToHideForPackagesOnTheStreet(
  statusOption,
  isReturnDirection
) {
  if (isReturnDirection) {
    // should display all list status options except delivery and awaiting resolution
    return (
      statusOption !== STATUS_DELIVERED &&
      statusOption !== DRAWER_UPDATE_STATUS.options.AWAITING_RESOLUTION
    );
  }
  // should display all list status options except returned and awaiting resolution
  return (
    statusOption !== STATUS_RETURNED &&
    statusOption !== DRAWER_UPDATE_STATUS.options.AWAITING_RESOLUTION
  );
}

export default function DrawerUpdateStatusComponent({
  updateStatusMachineRef
}) {
  const [current, send] = useService(updateStatusMachineRef);
  const {
    packageId,
    selectedItem,
    recipientName,
    recipientDocument,
    isDelivered,
    packageInfo
  } = current.context;
  const classes = useStyles();
  const isFlowSelecting = current.value === STATES.SELECTING;
  const isFlowSubmitting = current.value === STATES.SUBMITTING;
  const { status, history } = packageInfo;
  // eslint-disable-next-line camelcase
  const unitLoadProcessDestination = packageInfo.unitLoad?.license_plate
    .split(':')
    .slice(-2)
    .join(':');
  // eslint-disable-next-line camelcase
  const sortingContextOperation = packageInfo.sortingContext?.license_plate
    .split(' ')
    .slice(-1)
    .join();
  const enableAwaitingResolutionOptionByLmc = IsFSActiveForCurrentBase(
    'enableAwaitingResolutionOptionByLmc'
  );
  const packageHistoryHasAtLeastOneDeliveryAttempt = history?.some(item =>
    PACKAGE_STATUS_CODE_DELIVERY_FAILURE.includes(item.statusCode)
  );
  const isReturnDirection =
    packageInfo.direction && packageInfo.direction === PACKAGE_DIRECTION_RETURN;
  const isPackageInTheBase =
    DRAWER_UPDATE_STATUS.base.includes(unitLoadProcessDestination) ||
    DRAWER_UPDATE_STATUS.base.includes(sortingContextOperation);

  const statusList = DRAWER_STATUS_LIST_OPTIONS.filter(statusOption => {
    if (isPackageInTheBase) {
      return statusOptionsToDisplayForPackagesOnTheBase(
        statusOption,
        isReturnDirection,
        enableAwaitingResolutionOptionByLmc,
        packageHistoryHasAtLeastOneDeliveryAttempt
      );
    }

    return statusOptionsToHideForPackagesOnTheStreet(
      statusOption,
      isReturnDirection
    );
  });

  const handleListOption = item => {
    send(ACTIONS.SELECT_OPTION, { value: item });
  };

  const setReceiverName = event =>
    send(ACTIONS.CHANGE_NAME, {
      value: event.target.value
    });

  const setReceiverDocument = event =>
    send(ACTIONS.CHANGE_DOCUMENT, {
      value: event.target.value
    });

  return (
    <Box className={classes.root} data-testid="drawer-update-status-component">
      <Box mx={3.5}>
        <StatusColorDescription
          status={status.code}
          statusDescription={packageInfo.statusDisplay}
          leftPadding={2}
          typographyVariant="body1"
        />
      </Box>
      <Box mx={3.5} my={5}>
        <Typography variant="h4">
          <strong>{DRAWER_UPDATE_STATUS.title}</strong>
        </Typography>
        <Box mt={1.5}>
          <Typography variant="h5">
            {DRAWER_UPDATE_STATUS.subtitle} {`#${packageId}`}
          </Typography>
        </Box>
      </Box>
      <Box my={5}>
        <Box>
          <List disablePadding>
            {statusList.map(item => (
              <ListItem
                button
                divider
                selected={selectedItem === item.text}
                onClick={() => handleListOption(item)}
                key={item.text}
                className={`
                  ${classes.item}
                  ${
                    selectedItem === item.text
                      ? classes.itemSelected
                      : classes.itemNotSelected
                  }
                  `}
              >
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid>
                    <Box pr={2.5}>
                      <StatusColorDescription
                        status={item.code}
                        withoutDescription
                        leftPadding={0}
                      />
                    </Box>
                  </Grid>

                  <Grid className={classes.flexGrowItem}>
                    <Typography variant="body1">{item.text}</Typography>
                  </Grid>

                  <Grid>
                    <Box p={0} mr={3.5} hidden={selectedItem !== item.text}>
                      <CheckIcon id={item.text} color="primary" />
                    </Box>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
          {isDelivered && (
            <Box
              display="flex"
              flexDirection="row"
              ml={2}
              alignContent="flex-start"
              mb={2}
            >
              <Box mx={2} alignContent="center">
                <TextField
                  id="receiver-name"
                  required
                  type="text"
                  inputProps={{ 'data-testid': 'receiver-name' }}
                  label={DRAWER_UPDATE_STATUS.receiver_name}
                  margin="dense"
                  value={recipientName}
                  onChange={setReceiverName}
                />
              </Box>
              <Box mx={2} alignContent="center">
                <TextField
                  id="receiver-document"
                  type="text"
                  required
                  inputProps={{
                    'data-testid': 'receiver-document',
                    maxLength: 32
                  }}
                  label={DRAWER_UPDATE_STATUS.receiver_document}
                  margin="dense"
                  value={recipientDocument}
                  onChange={setReceiverDocument}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box mx={3.5} my={3.5}>
        {isFlowSelecting && (
          <Button
            fullWidth
            size="large"
            variant="outlined"
            color="primary"
            disabled={
              !selectedItem ||
              (isDelivered && !recipientName && !recipientDocument)
            }
            data-testid="btn-save"
            onClick={() => send(ACTIONS.SUBMIT)}
          >
            {DRAWER_UPDATE_STATUS.confirm}
          </Button>
        )}
        {isFlowSubmitting && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress justify="center" />
          </Box>
        )}
      </Box>
    </Box>
  );
}

// this function is use at storybook and tests only
export const makeUpdateStatusMachineRef = (context = {}) => {
  const service = interpret(
    updateStatusMachine.withContext({
      ...updateStatusMachine.context,
      ...context
    }),
    {
      parent: interpret(drawerMachine).start()
    }
  );
  service.start();
  return service;
};

DrawerUpdateStatusComponent.propTypes = {
  updateStatusMachineRef: PropTypes.instanceOf(Interpreter).isRequired
};
