import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { colors } from '@loggi/mar';
import { DrawerContent, DrawerHeader } from 'shared/drawer';
import { Box, Typography, Button, Divider } from '@material-ui/core';
import 'moment/locale/pt-br';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import { DEMAND_TYPE } from 'shared/filter/filter.constants';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import getDriverPosition from 'infra/service/get-driver-position';
import { WAITING_PICKUP } from 'offer/cells-component/status-cell/status-cell.constants';
import TRANSPORT_TYPE_MAPPER from '../cells-component/transport-type-cell/transport-type.constants';
import {
  buildPickupOriginAddress,
  buildLoggiAddress
} from '../drawer/drawer.service';
import { buildPeriodText, buildDeadlineText } from '../offer.utils';
import TEXT from '../drawer/drawer.constants';
import { TEXT as deadlineTexts } from '../cells-component/deadline-cell/deadline-cell.constants';
import { mapVolumesByExternalIds } from './utils';
import { changeMarkerStyle, addDriverMarker } from './route-map';

function totalLegDistance(offerRoute) {
  let sum = 0;
  offerRoute.points.forEach(point => {
    sum += point.legDistance;
  });
  return (sum / 1000).toFixed(2);
}

export const text = {
  updateDriver: 'Atualizar entregador',
  updateDriverError: 'Erro ao buscar posição do entregador',
  updateDriverSuccess: 'Posição do entregador atualizada'
};

function OfferDetailsDrawer({
  offerDetails,
  offerRoute,
  offerVolumes,
  refs,
  mapLoaded
}) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const routingCode = getSelectedRoutingCode();
  const enableDriverPosition = IsFSActiveForCurrentBase('enableDriverPosition');

  const pickupTransportType =
    TRANSPORT_TYPE_MAPPER[offerDetails.pickupTransportType]?.text;

  const periodText = buildPeriodText(offerDetails, deadlineTexts.TO_GO);
  const titleMessage =
    offerDetails.demandType === DEMAND_TYPE.DELIVERY
      ? 'Para entregar'
      : 'Para coletar';

  const displayIdsExternalKeys = [];
  const volumesByExternalIds = mapVolumesByExternalIds(offerVolumes);

  const shouldGetDriverPosition =
    !!offerDetails.driverId &&
    Number(offerDetails.driverId) !== 0 &&
    offerDetails.statusDisplay === WAITING_PICKUP &&
    enableDriverPosition;

  offerRoute.points.forEach(point => {
    point.cargoVisualIdentifications.forEach(cargo => {
      displayIdsExternalKeys.push({
        displayId: cargo.displayId,
        externalKey: cargo.externalKey
      });
    });
  });

  const getDriverPositionInfo = () => {
    if (!shouldGetDriverPosition) return;
    getDriverPosition(offerDetails.driverId)
      .then(response => {
        if (response.data?.driverPosition?.gpsInfo?.positionGeoPoint) {
          const {
            lat,
            lon
          } = response.data.driverPosition.gpsInfo.positionGeoPoint;
          addDriverMarker(lat, lon);
          showSnackbar({
            message: text.updateDriverSuccess,
            variant: 'error',
            enqueueSnackbar
          });
        }
      })
      .catch(() => {
        showSnackbar({
          message: text.updateDriverError,
          variant: 'error',
          enqueueSnackbar
        });
      });
  };

  useEffect(() => {
    if (mapLoaded) getDriverPositionInfo();
  }, [mapLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <DrawerHeader
        title={`${TEXT.GOOD_FOR} ${pickupTransportType}`}
        status={{
          type: 'component',
          component: () => (
            <Typography variant="body1" color="textSecondary">
              {titleMessage}
            </Typography>
          )
        }}
        subtitle={`${
          offerDetails.pickupOriginAddress?.postalAddress?.sublocality
        }, ${offerDetails.pickupOriginAddress?.postalAddress?.locality}`}
        detail={`${offerRoute.points.length} paradas • ${totalLegDistance(
          offerRoute
        )} km de distância \\n`}
        colorText={colors.root[0]}
        showDivider={false}
        handleClosingDrawer={() => history.goBack()}
      />
      {shouldGetDriverPosition && (
        <Box m={3} mt={-2}>
          <Button
            data-testid="btn-driver-position"
            variant="outlined"
            color="primary"
            onClick={() => {
              getDriverPositionInfo();
            }}
            size="small"
          >
            {text.updateDriver}
          </Button>
        </Box>
      )}
      <Divider data-testid="driver-position-divider" />

      <DrawerContent>
        <Typography variant="subtitle1">{periodText}</Typography>
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() =>
            changeMarkerStyle(refs[routingCode].marker, refs[routingCode].point)
          }
          data-testid="box-marker-ref"
        >
          <Box py={3} px={1} display="flex" ref={refs[routingCode].point}>
            <Box>
              <Box bgcolor={colors.smoke[100]} p={1} px={1.5}>
                {routingCode}
              </Box>
            </Box>
            <Box pl={2} display="flex" flexDirection="column">
              <Typography variant="body2">
                <Box component="span" fontWeight={700}>
                  {offerDetails.companyName}
                </Box>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {buildPickupOriginAddress(offerDetails)}
              </Typography>
              <Box pt={1}>
                <Typography variant="body2" color="textSecondary">
                  {offerDetails.pickupOriginName} •{' '}
                  {offerDetails.pickupOriginPhone}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </DrawerContent>

      <DrawerContent>
        <Typography variant="subtitle1">
          {buildDeadlineText(offerVolumes[0]?.deadlineDeliveryTime)}
        </Typography>
        {displayIdsExternalKeys.map(({ displayId, externalKey }) => (
          <Box
            key={displayId}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              changeMarkerStyle(refs[displayId]?.marker, refs[displayId]?.point)
            }
          >
            <Box
              py={3}
              px={1}
              display="flex"
              key={displayId}
              data-testid="offer-drawer-volumes"
            >
              <Box
                bgcolor={colors.smoke[100]}
                py={1}
                px={1.5}
                height="fit-content"
              >
                {displayId}
              </Box>
              <Box display="flex" flexDirection="column">
                <Box
                  pl={2}
                  mb={2}
                  display="flex"
                  flexDirection="column"
                  key={volumesByExternalIds[externalKey].volumeId}
                >
                  <Typography variant="body2">
                    <Box component="span" fontWeight={700}>
                      {volumesByExternalIds[externalKey].companyName}
                    </Box>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    # {volumesByExternalIds[externalKey].barcode}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {buildLoggiAddress(
                      volumesByExternalIds[externalKey].address
                    )}
                  </Typography>
                  <Box pt={1}>
                    <Typography variant="body2" color="textSecondary">
                      {volumesByExternalIds[externalKey].contact?.name} •{' '}
                      {volumesByExternalIds[externalKey].contact?.phone}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </DrawerContent>
    </Box>
  );
}

export default OfferDetailsDrawer;

OfferDetailsDrawer.propTypes = {
  offerDetails: PropTypes.shape({
    companyName: PropTypes.string,
    shipperName: PropTypes.string,
    shipperPhone: PropTypes.string,
    driverId: PropTypes.number,
    driverMobilePhone: PropTypes.string,
    driverName: PropTypes.string,
    driverPhoto: PropTypes.string,
    statusDisplay: PropTypes.string,
    pickupTransportType: PropTypes.string,
    driverTransportType: PropTypes.string,
    pickupOriginName: PropTypes.string,
    pickupOriginPhone: PropTypes.string,
    driverLegFromPickup: PropTypes.shape({
      distance: PropTypes.number,
      duration: PropTypes.number
    }),
    demandType: PropTypes.string,
    pickupOriginAddress: PropTypes.shape({})
  }).isRequired,
  offerRoute: PropTypes.shape({
    geometry: PropTypes.string,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        displayId: PropTypes.string,
        externalKey: PropTypes.string
      })
    )
  }).isRequired,
  offerVolumes: PropTypes.arrayOf(
    PropTypes.shape({
      barcode: PropTypes.string,
      companyName: PropTypes.string,
      deadlineDeliveryTime: PropTypes.string,
      externalKey: PropTypes.string
    })
  ).isRequired,
  refs: PropTypes.shape(
    PropTypes.shape({
      current: PropTypes.shape({})
    })
  ),
  mapLoaded: PropTypes.bool.isRequired
};

OfferDetailsDrawer.defaultProps = {
  refs: {}
};
