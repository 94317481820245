export const PROCESSES = {
  DELIVERY_ASSIGNMENT: 'deliverer_assignment',
  DELIVERY: 'delivery'
};

export const DESTINATIONS = {
  IN_PROGRESS: 'in_progress',
  INVALID_DESTINATION: 'invalid_destination',
  RECIPIENT_REFUSED: 'recipient_refused',
  RECIPIENT_UNAVAILABLE: 'recipient_unavailable'
};
