import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ typography }) => ({
  /* TODO:
       This customization of the paper component should be removed
       when the theme customizes it accordingly */
  paper: {
    borderRadius: 10
  },
  skeleton: {
    borderRadius: typography.pxToRem(8)
  },
  gridMt: {
    marginTop: '40px'
  }
}));

export default useStyles;
