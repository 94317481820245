import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Can } from 'auth/access-control';
import TemplateContainer from 'tracking/template/template.container';

const CanRouteTemplate = ({
  component: Component,
  actions,
  excludeFS,
  includeFS,
  withNewLayout,
  ...rest
}) => {
  return (
    <Route
      exact
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={props => {
        if (withNewLayout) {
          return (
            <Can actions={actions} excludeFS={excludeFS} includeFS={includeFS}>
              <TemplateContainer>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Component {...props} />
              </TemplateContainer>
            </Can>
          );
        }

        return (
          <Can actions={actions} excludeFS={excludeFS} includeFS={includeFS}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...props} />
          </Can>
        );
      }}
    />
  );
};

CanRouteTemplate.propTypes = {
  component: PropTypes.instanceOf(Function),
  withNewLayout: PropTypes.bool,
  excludeFS: PropTypes.string,
  includeFS: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.string)
};

CanRouteTemplate.defaultProps = {
  withNewLayout: false,
  component: null,
  actions: [],
  excludeFS: '',
  includeFS: ''
};

export default CanRouteTemplate;
