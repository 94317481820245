export const PLACEHOLDER = 'Bipe para receber pacotes e sacas';

export const SUCCESS_BAG = barcode => `Pronto! A saca #${barcode} foi bipada.`;
export const SUCCESS_PACKAGE = barcode =>
  `Pronto! O pacote #${barcode} foi bipado.`;
export const BARCODE_NOT_FOUND = barcode =>
  `O código #${barcode} não é valido. Tente outra vez.`;
export const PROCESS = 'receiving';
export const DESTINATION = 'accepted';

export const BARCODE_REFERENCE_PACKAGE = 'BARCODE_REFERENCE_PACKAGE';
export const BARCODE_REFERENCE_BAG = 'BARCODE_REFERENCE_BAG';

export const CUSTOM_ERROR_MESSAGES = {
  500: 'Eita, algo deu errado. Tente de novo daqui a pouco ou atualize a página. [005-Error-500]',
  403: 'Você precisa falar com o administrador para acessar esse serviço.'
};

export const PACKAGE_ORIGIN_REDISPATCH = 'PACKAGE_ORIGIN_REDISPATCH';

export const RECEIVE_REDISPATCH_ERROR_CODE = 428;

export const TEXT_ALERT_STATUS_HEADER = {
  PRIMARY: 'Indicadores temporariamente indisponíveis',
  SECONDARY:
    'Continue recebendo os pacotes normalmente. Vamos atualizar em breve.'
};
