import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import showSnackbar from 'shared/snackbar';

import { DrawerHeader, DrawerContent } from 'shared/drawer';

import { getSelectedRoutingCode } from 'auth/login/login.service';

import { updateSortingContext } from '../demand-regions.service';
import { DemandRegionsContext } from '../demand-regions.provider';
import {
  DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER,
  DEMAND_REGIONS_INITIAL_DRAWER,
  UPDATE_DEMAND_REGIONS
} from '../demand-regions.constants';

import ConfirmationApplicationSimulationDialog from '../demand-regions-confirmation-dialog';
import { getNumberDecisions } from '../demand-regions.utils';

import DecisionGrouped from './demand-regions-decisions-grouped';
import InitialSimulationDrawer from './demand-regions-initial-simulation.drawer';

const DemandRegionsSimulationDetailsDrawer = ({ onClose }) => {
  const { simulationSortingContext, sortingContext } = useContext(
    DemandRegionsContext
  );
  const { enqueueSnackbar } = useSnackbar();

  const numberDecisions = getNumberDecisions(sortingContext);

  const [
    openConfirmationApplicationSimulationDialog,
    setOpenConfirmationApplicationSimulationDialog
  ] = useState(false);

  const [
    simulationDemandRegionsDrawerOpen,
    setSimulationDemandRegionsDrawerOpen
  ] = useState(false);

  const selectedRoutingCode = getSelectedRoutingCode();

  const onBack = () => {
    setOpenConfirmationApplicationSimulationDialog(false);
    onClose();
  };

  const handleUpdateSortingContext = () => {
    updateSortingContext(selectedRoutingCode)
      .then(() => {
        showSnackbar({
          message: UPDATE_DEMAND_REGIONS.NOTIFICATION_SUCCESS,
          enqueueSnackbar,
          withButton: true,
          variant: 'success',
          messageButton: DEMAND_REGIONS_INITIAL_DRAWER.ADJUST_LAYOUT_BUTTON,
          link: DEMAND_REGIONS_INITIAL_DRAWER.ADJUST_LAYOUT_LINK
        });
      })
      .catch(() => {
        showSnackbar({
          message: UPDATE_DEMAND_REGIONS.NOTIFICATION_ERROR,
          enqueueSnackbar,
          withButton: false,
          variant: 'error'
        });
      });

    onBack();
  };

  return (
    <>
      {simulationDemandRegionsDrawerOpen ? (
        <InitialSimulationDrawer
          numPositions={numberDecisions}
          onBack={() => setSimulationDemandRegionsDrawerOpen(false)}
          enqueueSnackbar={enqueueSnackbar}
        />
      ) : (
        <>
          <DrawerHeader
            titleVariant="h5"
            title={`${getNumberDecisions(simulationSortingContext)}${
              DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER.TITLE
            }`}
            status={{
              type: 'component',
              component: () => (
                <Typography variant="body1" color="textSecondary">
                  {DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER.STATUS}
                </Typography>
              )
            }}
            detail={DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER.DETAIL}
            colorText="textPrimary"
            showDivider
            variantTextDetail="body1"
            handleClosingDrawer={onClose}
            open
          >
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                data-testid="button-apply-simulation"
                onClick={() =>
                  setOpenConfirmationApplicationSimulationDialog(true)
                }
              >
                {DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER.APPLY_NOW}
              </Button>
            </Box>
          </DrawerHeader>
          <DrawerContent showDivider>
            <Box mt={2} mb={2} data-testid="new-simulation-text">
              <Typography color="textPrimary" variant="subtitle1">
                {DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER.NEW_SIMULATION_TEXT}
              </Typography>

              <Button
                variant="text"
                color="primary"
                size="medium"
                data-testid="new-simulation-button"
                endIcon={<ChevronRightIcon color="primary" />}
                onClick={() => setSimulationDemandRegionsDrawerOpen(true)}
              >
                {DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER.NEW_SIMULATION_BUTTON}
              </Button>
            </Box>
          </DrawerContent>
          <DrawerContent>
            <Box mt={2} mb={2} data-testid="regions">
              <Typography color="textPrimary" variant="subtitle1">
                <Box fontWeight="fontWeightMedium" mb={3}>
                  {DEMAND_REGIONS_SIMULATION_DETAILS_DRAWER.REGIONS}
                </Box>
              </Typography>
              <DecisionGrouped sortingContext={simulationSortingContext} />
            </Box>
          </DrawerContent>
        </>
      )}
      {openConfirmationApplicationSimulationDialog && (
        <ConfirmationApplicationSimulationDialog
          open
          onConfirm={() => handleUpdateSortingContext()}
          onCancel={() => setOpenConfirmationApplicationSimulationDialog(false)}
        />
      )}
    </>
  );
};

DemandRegionsSimulationDetailsDrawer.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default DemandRegionsSimulationDetailsDrawer;
