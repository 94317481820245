import React, { useEffect } from 'react';
import { useService } from '@xstate/react';
import PropTypes from 'prop-types';
import { interpret, Interpreter } from 'xstate';
import drawerMachine from '../drawer.machine';
import DrawerUpdateAddressComponent from './drawer-update-address.component';
import updateAddressMachine, {
  ACTIONS,
  STATES
} from './drawer-update-address.machine';

export default function DrawerUpdateAddress({
  updateAddressMachineRef,
  setDrawerFullScreen,
  drawerFullScreen
}) {
  const [current, send] = useService(updateAddressMachineRef);

  const {
    packageInfo,
    postalAddress,
    coordinates,
    isEnableSubmit,
    dialogTitle,
    dialogDescription,
    isCancel
  } = current.context;

  const { loggiAddress = {}, integrationInfo } = packageInfo;

  const isLoading = current.value === STATES.SUBMITTING;
  const isConfirming = [
    STATES.DIALOG_CONFIRM_WITHOUT_CHANGE_MAP,
    STATES.DIALOG_CONFIRM_WITHOUT_CHANGE_POSTAL_ADDRESS
  ].some(current.matches);

  useEffect(() => {
    if (isCancel && !isEnableSubmit) {
      send(ACTIONS.CONFIRM);
    }
  }, [isCancel, isEnableSubmit, send]);

  const handleChangeAddress = () => {
    send(ACTIONS.SUBMIT);
  };

  const handleChangePostalAddress = (key, value) => {
    const newPostalAddress = {
      ...(postalAddress ?? loggiAddress.postalAddress),
      [key]: value
    };

    if (!value) {
      delete newPostalAddress[key];
    }

    send(ACTIONS.EDIT_POSTAL_ADDRESS, {
      value: newPostalAddress
    });
  };

  const handleChangeCoordinates = (key, value) => {
    const newCoordinates = {
      ...(coordinates ?? loggiAddress.coordinates),
      [key]: value
    };

    if (!value) {
      delete newCoordinates[key];
    }

    send(ACTIONS.EDIT_COORDINATES_ADDRESS, {
      value: newCoordinates
    });
  };

  const handleConfirmationSubmit = () => send(ACTIONS.CONFIRM);

  const handleConfirmationCancel = () => {
    send(ACTIONS.CANCEL);
    setDrawerFullScreen(true);
  };

  return (
    <DrawerUpdateAddressComponent
      isLoading={isLoading}
      onChangeAddress={handleChangeAddress}
      setDrawerFullScreen={setDrawerFullScreen}
      drawerFullScreen={drawerFullScreen}
      postalAddress={postalAddress ?? loggiAddress.postalAddress}
      coordinates={coordinates ?? loggiAddress.coordinates}
      barcode={integrationInfo?.barcode}
      onChangePostalAddress={handleChangePostalAddress}
      onChangeCoordinates={handleChangeCoordinates}
      isEnableSubmit={isEnableSubmit}
      isConfirming={isConfirming}
      isCancel={isCancel}
      onConfirmationCancel={handleConfirmationCancel}
      onConfirmationSubmit={handleConfirmationSubmit}
      packageInfo={packageInfo}
      dialogTitle={dialogTitle}
      dialogDescription={dialogDescription}
    />
  );
}

// this function is use at tests only
export const makeUpdateAddressMachineRef = (context = {}) => {
  const service = interpret(
    updateAddressMachine.withContext({
      ...updateAddressMachine.context,
      ...context
    }),
    {
      parent: interpret(drawerMachine).start()
    }
  );
  service.start();
  return service;
};

DrawerUpdateAddress.propTypes = {
  updateAddressMachineRef: PropTypes.instanceOf(Interpreter).isRequired,
  setDrawerFullScreen: PropTypes.func.isRequired,
  drawerFullScreen: PropTypes.bool.isRequired
};
