import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import { getLoggedLMCCompanyType } from 'auth/login/login.service';
import DriversFormContainer from './form/drivers-form.container';
import DriversProfileContainer from './profile/drivers-profile.container';
import DriversListContainer from './list/drivers-list.container';

import { hasDistributionCenter } from './drivers.service';

import { COMPANY_TYPE } from './drivers.constants';

function DriversManagement() {
  const hasDc = hasDistributionCenter();
  const [openProfile, setOpenProfile] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [profile, setProfile] = useState({});
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [cltForm, setCltForm] = useState(false);
  const [shouldEdit, setShouldEdit] = useState(false);

  const handleClose = () => {
    setOpenProfile(false);
    setOpenForm(false);
    setShouldEdit(false);
    setProfile({});
  };
  const handleOpenProfile = user => {
    setOpenForm(false);
    setOpenProfile(true);
    setShouldEdit(false);
    setProfile(user);
  };
  const handleOpenForm = clearProfile => {
    const companyType = getLoggedLMCCompanyType();

    if (companyType === COMPANY_TYPE.COMPANY_TYPE_LOGGI) {
      setCltForm(true);
      return;
    }
    if (clearProfile) {
      setProfile({});
    }
    setOpenProfile(false);
    setOpenForm(true);
  };

  const reFetchDrivers = () => setShouldUpdate(_ => !_);

  const handleSubmit = () => reFetchDrivers();
  const handleDelete = () => reFetchDrivers();

  return (
    <Box data-testid="driver-management">
      <DriversProfileContainer
        open={openProfile}
        profile={profile}
        setOpenProfile={setOpenProfile}
        hasDc={hasDc}
        onClose={handleClose}
        onDelete={handleDelete}
        onEdit={handleSubmit}
        onMigrate={() => handleOpenForm(false)}
        shouldEdit={shouldEdit}
        setShouldEdit={setShouldEdit}
      />
      <DriversFormContainer
        open={openForm}
        profile={profile}
        setProfile={setProfile}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cltForm={cltForm}
        setCltForm={setCltForm}
        setOpenForm={setOpenForm}
      />
      <DriversListContainer
        hasDc={hasDc}
        shouldUpdate={shouldUpdate}
        onSelectDriver={handleOpenProfile}
        onNewRegister={() => handleOpenForm(true)}
        shouldEdit={shouldEdit}
      />
    </Box>
  );
}

export default DriversManagement;
