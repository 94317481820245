import React, { useEffect, useState } from 'react';

import { database } from 'infra/firebase/firebase.init';
import AlertStatusComponent from '@loggi/components/src/arco/alert-status/alert-status.component';

export default function AlertPlatformDegradedComponent() {
  const [showAlert, setShowAlert] = useState(false);

  const PLATFORM_DEGRADED_ALERT_KEY = 'systems_operational_degraded';

  const starCountRef = database.ref(PLATFORM_DEGRADED_ALERT_KEY);

  useEffect(() => {
    starCountRef.on(
      'value',
      snapshot => {
        setShowAlert(snapshot.val());
      },
      () => {
        setShowAlert(false);
      }
    );
  }, [starCountRef]);

  return (
    showAlert && (
      <AlertStatusComponent colors="defaultAlertColors">
        {showAlert}
      </AlertStatusComponent>
    )
  );
}
