import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  paper: {
    borderRadius: 10
  },
  iconStyle: {
    backgroundColor: colors.root[0],
    borderRadius: '50%',
    position: 'relative',
    '& svg': {
      position: 'absolute',
      height: '33.33%', // 16px
      width: '33.33%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  sizenormal: {
    height: spacing(5),
    width: spacing(5)
  },
  sizelarge: {
    height: spacing(14),
    width: spacing(14)
  },
  variantsuccess: {
    backgroundColor: colors.green[100]
  },
  varianterror: {
    backgroundColor: palette.error.light
  },
  variantprimary: {
    backgroundColor: colors.blue[100]
  },
  variantdefault: {
    backgroundColor: colors.root[0]
  },
  variantsecondary: {
    backgroundColor: colors.smoke[100]
  }
}));

export default useStyles;
