import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecord';

import { listMapper } from 'offer/offer.constants';
import STATUS_MAPPER from './status-cell.constants';

function formatMessage(allStatus) {
  const statusCounter = allStatus
    .map(item => {
      return { status: item, count: 1 };
    })
    .reduce((acc, status) => {
      acc[status.status] = (acc[status.status] || 0) + status.count;
      return acc;
    }, {});
  if (Object.keys(statusCounter).length === 1) {
    const statusKey = Object.keys(statusCounter)[0];
    return statusCounter[statusKey] === 1 ? statusKey : `${statusKey}s`;
  }
  return `${allStatus.length} ofertas`;
}

function StatusCell({ status, showDot }) {
  const mappedStatus = listMapper(status, STATUS_MAPPER);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {showDot && (
        <FiberManualRecordRoundedIcon style={mappedStatus[0].style} />
      )}
      <Box pl={1} pr={2}>
        <Typography noWrap variant="body2">
          {formatMessage(status)}
        </Typography>
      </Box>
    </Box>
  );
}

StatusCell.propTypes = {
  status: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  showDot: PropTypes.bool
};

StatusCell.defaultProps = {
  status: [],
  showDot: true
};

export default StatusCell;
