import api from 'infra/api/leve';
import { URL_PROXY_V1_PREFIX } from 'infra/service/constants';

import { constantToRoleNumber } from 'users-management/operators/list/operators.configuration';
import { parseRequestError } from 'app/httpMessage';

const userService = () => {
  return api
    .get(`${URL_PROXY_V1_PREFIX}/users/management`)
    .then(response => {
      if (response.data.users) {
        return response.data;
      }
      return { users: [] };
    })
    .catch(parseRequestError());
};

const operatorCreateOrUpdateService = requestData => {
  let request;

  const newRequestData = {
    ...requestData,
    role: constantToRoleNumber(requestData.role)
  };

  // if has id we are updating a existing user
  if (requestData.id) {
    request = api.put(
      `${URL_PROXY_V1_PREFIX}/users/management/${requestData.id}`,
      newRequestData
    );
  } else {
    request = api.post(
      `${URL_PROXY_V1_PREFIX}/users/management`,
      newRequestData
    );
  }

  return request.catch(
    parseRequestError({
      409: 'CPF já está em uso',
      412: 'CPF inválido'
    })
  );
};

const operatorRevokeService = userId =>
  api
    .delete(`${URL_PROXY_V1_PREFIX}/users/management/${userId}`)
    .catch(parseRequestError());

const operatorResendService = userId =>
  api
    .post(`${URL_PROXY_V1_PREFIX}/users/management/${userId}/resend-invite`)
    .catch(parseRequestError());

export {
  userService,
  operatorCreateOrUpdateService,
  operatorRevokeService,
  operatorResendService
};
