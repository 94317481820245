export const DRAWER_INITIAL = {
  TITLE: 'Áreas de entrega complexa',
  DETAIL:
    'Cadastre ou edite as áreas que têm alguma complexidade na entrega, como áreas perigosas, comerciais, afastadas e com condomínios.',
  CREATE_AREA: 'Comece criando uma área',
  CLICK_BUTTON:
    'Clique no botão abaixo para cadastrar sua primeira área de entrega  complexa.',
  BUTTON: 'Criar nova área',
  TIPS: 'Dicas para cadastrar áreas',
  CONTACT_SUPORT_FIRST_PART: 'O limite de áreas foi atingido',
  CONTACT_SUPORT_SECOND_PART:
    ', mas você pode excluir uma área para cadastrar uma nova.'
};

export const DIALOG_CREATE_COMPLEX_AREA = {
  TITLE: 'Tudo certo com a área cadastrada?',
  SUBTITLE:
    'Vamos usar essas informações na próxima atualização das regiões da sua agência.',
  ALL_RIGHT: 'Tudo certo',
  CHECK: 'Vou conferir'
};

export const DIALOG_DELETE_COMPLEX_AREA = {
  TITLE: 'Você quer excluir a área?',
  SUBTITLE:
    'Depois de excluir, é importante atualizar a região da sua base para remover essa área na roteirização.',
  CANCEL: 'Cancelar',
  DELETE: 'Quero excluir'
};

export const DIALOG_UPDATE_COMPLEX_AREA = {
  TITLE: 'Tudo certo com a área cadastrada?',
  SUBTITLE:
    'Vamos usar essas informações na próxima atualização das regiões da sua agência.',
  CHECK: 'Vou conferir',
  ALL_RIGHT: 'Tudo certo'
};

export const DIALOG_RETURN_COMPLEX_AREA = {
  TITLE: 'Tem certeza que quer sair?',
  SUBTITLE: 'Ao sair, a área desenhada não vai ser salva.',
  CONTINUE: 'Continuar edição',
  RETURN: 'Quero sair'
};

export const SNACKBAR_NOTIFICATION = {
  ERROR_MESSAGE: 'Não foi possível salvar as alterações. Tente novamente.',
  COMPLEX_AREA_EMPTY_ERROR: 'Ficou faltando desenhar a área no mapa',
  BUTTON_OPEN_JIRA_TICKET: 'Abrir chamado',
  SUCCESS_MESSAGE:
    'Pronto! Ao finalizar o cadastro ou edição de todas as áreas, abra um chamado no Jira para atualizar as regiões.',
  INVALID_POLYGON_ERROR:
    'Desenhe apenas formas fechadas, ligando o último ponto ao primeiro.'
};

export const DRAWER_CREATE_OR_UPDATE = {
  TITLE: 'Nova área',
  DETAIL: 'Desenhe pontos no mapa considerando uma das 4 categorias.',
  NAME: 'Nome',
  NAME_AREA_PLACEHOLDER: 'Informe o nome da área',
  CATEGORIES: 'Sobre as categorias',
  CATEGORY: 'Categoria',
  DELETE_AREA: 'Excluir área',
  SAVE_AREA: 'Salvar alterações',
  CREATE_AREA: 'Cadastrar',
  NAME_EMPTY_ERROR: 'Preencha o nome da área.',
  LABEL_INPUT: 'Nome da área'
};

const LESS_IS_MORE = 'Menos é mais';
const CLOSED_FORMS = 'Formas fechadas';
const SIMPLE_STROKES = 'Traços simples';

export const TIPS = {
  [LESS_IS_MORE]:
    'Cadastre apenas as áreas mais complexas, aquelas que realmente vão impactar a criação de rotas.',
  [CLOSED_FORMS]:
    'Use o lápis para marcar pontos no mapa até construir uma forma fechada, ligando o último ponto ao primeiro.',
  [SIMPLE_STROKES]:
    'Desenhe a área aproximada, sem contornar cada rua no detalhe.'
};

const REMOTE = 'Afastada';
const COMMERCIAL = 'Comercial';
const CONDOMINUMS = 'Condomínios';
const DANGEROUS = 'Perigosa';
const OTHER = 'Outra';

export const CATEGORIES_NAMES = {
  [REMOTE]: 'Afastada',
  [COMMERCIAL]: 'Comercial',
  [CONDOMINUMS]: 'Condomínios',
  [DANGEROUS]: 'Perigosa',
  [OTHER]: 'Outra'
};

export const CONVERT_CATEGORIES_TO_NUMBER = {
  [REMOTE]: 1,
  [COMMERCIAL]: 2,
  [CONDOMINUMS]: 3,
  [DANGEROUS]: 4,
  [OTHER]: 5
};

export const CONVERT_NUMBER_TO_CATEGORIES = {
  1: REMOTE,
  2: COMMERCIAL,
  3: CONDOMINUMS,
  4: DANGEROUS,
  5: OTHER
};

export const CATEGORIES_DESCRIPTION = {
  [REMOTE]:
    'Distante do centro da cidade ou em regiões rurais, com dificuldade de acesso.',
  [COMMERCIAL]:
    'Com fluxo intenso de veículos e/ou pessoas, como áreas comerciais, shoppings e galerias.',
  [CONDOMINUMS]:
    'Espaços privados que exigem um cadastro para entrar no local da entrega.',
  [DANGEROUS]:
    'Com alto índice de roubo ou que oferece algum outro risco para o entregador e o pacote.'
};

export const ONBOARDING = {
  TITLE_FIRST_SCREEN: 'Rotas mais eficientes para áreas de entrega complexa',
  TITLE_SECOND_SCREEN: 'Cadastre as áreas que exigem mais atenção da operação ',
  TITLE_THIRD_SCREEN: 'Ganhe velocidade na separação de pacotes',
  DESCRIPTION_FIRST_SCREEN:
    'Entenda como a novidade pode melhorar sua operação.',
  DESCRIPTION_SECOND_SCREEN:
    'Desenhe no mapa áreas de entrega afastadas, comerciais, perigosas e com condomínios.',
  DESCRIPTION_THIRD_SCREEN:
    'Vamos considerar as características locais para melhorar as recomendações durante a separação.',
  CONTINUE: 'Continuar',
  BACK: 'Voltar',
  CREATE_AREA: 'Cadastrar áreas',
  LETS_GO: 'Vamos lá!',
  SKIP: 'Pular',
  NOVELTY: 'Novidade',
  FIRST_SCREEN: 'first-screen',
  SECOND_SCREEN: 'second-screen',
  THIRD_SCREEN: 'third-screen'
};

export const TOOLTIP = {
  CREATION_MODE_MESSAGE: 'Use o lápis para desenhar.',
  EDITION_MODE_MESSAGE: 'Use o lápis para ajustar.'
};
