import React, { useContext, useEffect, useState } from 'react';
import { SummaryContext } from 'tracking/template/summary.context';
import { Grid, Typography, Box, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { PropTypes } from 'prop-types';
import CardAggregationContainer from 'in-street/card-aggregations/card-aggregation.container';
import { formatNumber } from 'shared/formatters';
import { FILTERS } from 'shared/filter/filter.constants';
import ConfigurableTableError from 'shared/configurable-table/configurable-table-error.component';
import FilterComponent from 'shared/filter';
import { Can, IsFSActiveForCurrentBase } from 'auth/access-control';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import { SWITCHES } from 'view/constants';
import { fetchInStreetSummary } from './service';

function FilterElements({
  dataForFilter,
  setFilters,
  checkedItemsBase,
  setCheckedItemsBase
}) {
  const onApplyChanges = (items, resetBaseListData) => {
    if (items.remove || Object.values(items)[0] === undefined) {
      setFilters({});
      setCheckedItemsBase({});
    } else {
      setFilters(currentState => {
        return { ...currentState, ...items };
      });
    }

    if (resetBaseListData) {
      resetBaseListData();
    }
  };

  return (
    <Box>
      {dataForFilter && (
        <Box data-testid="filter-elemets">
          {/* LEVE */}
          <Can actions={[ACTIONS.IN_STREET_LEVE]}>
            <FilterComponent
              btnFilter="Cidades"
              onApplyChanges={onApplyChanges}
              whichFilter={FILTERS.IN_STREET_CITY}
              dataForFilter={dataForFilter}
              checkedItemsBase={checkedItemsBase}
              setCheckedItemsBase={setCheckedItemsBase}
              preselectedItems={checkedItemsBase.cities}
            />
            <FilterComponent
              btnFilter="Circulos"
              onApplyChanges={onApplyChanges}
              whichFilter={FILTERS.IN_STREET_CIRCLE}
              dataForFilter={dataForFilter}
              checkedItemsBase={checkedItemsBase}
              setCheckedItemsBase={setCheckedItemsBase}
              preselectedItems={checkedItemsBase.circleIds}
            />
          </Can>

          {/* AGENCIA */}
          <Can actions={[ACTIONS.IN_STREET_AGENCY]}>
            <FilterComponent
              btnFilter="Quem enviou"
              onApplyChanges={onApplyChanges}
              whichFilter={FILTERS.IN_STREET_SENDER}
              dataForFilter={dataForFilter}
              checkedItemsBase={checkedItemsBase}
              setCheckedItemsBase={setCheckedItemsBase}
              preselectedItems={checkedItemsBase.senders}
            />
          </Can>
        </Box>
      )}
    </Box>
  );
}

FilterElements.propTypes = {
  dataForFilter: PropTypes.shape({
    deliverers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    statuses: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        code: PropTypes.number
      })
    ),
    hasFaults: PropTypes.arrayOf(PropTypes.bool),
    cities: PropTypes.arrayOf(PropTypes.string),
    circles: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  checkedItemsBase: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
  setCheckedItemsBase: PropTypes.func.isRequired
};

export default function InStreetCollectionContainer() {
  const { setSummary } = useContext(SummaryContext);
  const [loading, setLoading] = useState(true);
  const [dataResponse, setDataResponse] = useState();

  const [errorFetch, setErrorFetch] = useState(false);
  const [filters, setFilters] = useState({});
  const [dataForFilter, setDataForFilter] = useState();
  const [checkedItemsBase, setCheckedItemsBase] = useState({});

  const TITLE_SUMMARY = 'Na rua';

  const enableNewInStreetNewV2 = IsFSActiveForCurrentBase(
    SWITCHES.enableNewInStreetNewV2
  );

  useEffect(() => {
    const fetchData = async () => {
      await fetchInStreetSummary({
        filters
      })
        .then(response => {
          setDataResponse({ ...response });
          setLoading(false);
          setDataForFilter(response);
        })
        .catch(() => {
          setErrorFetch(true);
          setLoading(false);
        });
    };

    setErrorFetch(false);
    setLoading(true);
    fetchData();
  }, [filters]);

  useEffect(() => {
    setSummary(
      <Grid
        container
        direction="row"
        alignItems="center"
        display="flex"
        justify="space-between"
      >
        <Grid item xs>
          <Box px={3}>
            <Typography variant="h4">
              {loading ? (
                <Skeleton width="20%" animation="wave" />
              ) : (
                <strong>{TITLE_SUMMARY}</strong>
              )}
            </Typography>
            <Box>
              <Typography variant="h4">
                {loading ? (
                  <Skeleton width="10%" animation="wave" />
                ) : (
                  !errorFetch && (
                    <Box mt={2}>
                      <Typography variant="h4">
                        {formatNumber(dataResponse?.totalInStreetPackages)}
                        {' pacotes'}
                      </Typography>
                    </Box>
                  )
                )}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }, [dataResponse, loading, setSummary, errorFetch]);

  return (
    <Paper component={Box} elevation={10} flexGrow={1} mb={6} minHeight={44}>
      <Box px={5} py={3} data-testid="in-street-collection">
        <Box mb={2}>
          {loading ? (
            <Skeleton variant="rect" height={44} animation="wave" />
          ) : (
            dataForFilter && (
              <FilterElements
                loading={loading}
                setFilters={setFilters}
                dataForFilter={dataForFilter}
                checkedItemsBase={checkedItemsBase}
                setCheckedItemsBase={setCheckedItemsBase}
              />
            )
          )}
        </Box>

        {loading ? (
          <Skeleton variant="rect" height={80} animation="wave" />
        ) : (
          !errorFetch &&
          (enableNewInStreetNewV2 ? (
            <CardAggregationContainer
              dataResponse={dataResponse}
              configGroupPackages
              configPackages={false}
            />
          ) : (
            <CardAggregationContainer dataResponse={dataResponse} />
          ))
        )}

        {loading ? (
          <Skeleton variant="rect" height={80} animation="wave" />
        ) : (
          !errorFetch && (
            <CardAggregationContainer
              dataResponse={dataResponse}
              configGroupPackages
            />
          )
        )}
      </Box>
      {errorFetch && <ConfigurableTableError />}
    </Paper>
  );
}
