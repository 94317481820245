import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';

import { ReactComponent as LateIcon } from 'images/triangle-late-arrivals.svg';
import { ReactComponent as AdvancedIcon } from 'images/triangle-advanced-arrivals.svg';
import { formatDate } from '../transfers/arrivals-transfers.configuration';
import useStyles from './delta-arrival-time.styles';
import TEXT from './delta-arrival-time.constants';

function DeltaArrivalTimeMinutesComponent({
  expectedArrivalTime,
  deltaArrivalTime,
  toDrawer
}) {
  const classes = useStyles();
  const now = moment();
  const isSameDay = now.isSame(moment(expectedArrivalTime), 'day');
  const isAdvanced = deltaArrivalTime < 0;
  const isInTime = deltaArrivalTime === 0;

  const howDelayed = () => {
    const delta = Math.abs(deltaArrivalTime);
    if (delta >= 60 && delta < 180) {
      return TEXT.MORE_THAN_ONE_HOUR;
    }
    if (delta >= 180) {
      return TEXT.MORE_THAN_THREE_HOURS;
    }
    return '';
  };

  const sumDeltaTime = () => {
    const sum = moment(expectedArrivalTime).add(deltaArrivalTime, 'm');
    return formatDate(sum);
  };

  if (!isSameDay || isInTime) {
    return toDrawer ? '' : sumDeltaTime();
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box pr={0.5}>{isAdvanced ? <AdvancedIcon /> : <LateIcon />}</Box>
        <Typography variant="caption" className={classes.bold}>
          {isAdvanced ? TEXT.ADVANCED : TEXT.LATE}
        </Typography>
        <Typography variant="caption" className={classes.bold}>
          {toDrawer
            ? `${TEXT.PLANNED_FOR}${formatDate(
                expectedArrivalTime
              )}`.toLocaleLowerCase()
            : howDelayed()}
        </Typography>
      </Box>
      {!toDrawer && (
        <Box>
          <Typography variant="body2">{sumDeltaTime()}</Typography>
        </Box>
      )}
    </>
  );
}

DeltaArrivalTimeMinutesComponent.propTypes = {
  expectedArrivalTime: PropTypes.string.isRequired,
  deltaArrivalTime: PropTypes.number.isRequired,
  toDrawer: PropTypes.bool
};

DeltaArrivalTimeMinutesComponent.defaultProps = {
  toDrawer: false
};

export default DeltaArrivalTimeMinutesComponent;
