export const PENDING_LABEL = 'Pendente';

export const USER_STATUS_PENDING = 'USER_STATUS_PENDING';
export const INCOMPLETE_REGISTER = 'Cadastro incompleto';

export const ROLE_OWNER = {
  VALUE: 'ROLE_OWNER',
  LABEL: 'Avançado',
  DESCRIPTION:
    'Tem todas as permissões. Além disso, cadastra pessoas para operar pacotes na base, acessa informações financeiras e recebe e-mails.'
};

export const ROLE_ADMIN = {
  VALUE: 'ROLE_ADMIN',
  LABEL: 'Notificações',
  DESCRIPTION: ''
};

export const ROLE_REGULAR = {
  VALUE: 'ROLE_REGULAR',
  LABEL: 'Intermediário',
  DESCRIPTION:
    'Tem visão total dos pacotes do Leve. Consulta e movimenta todos os pacotes, além de cadastrar pessoas para entregar.'
};

export const ROLE_OPERATOR = {
  VALUE: 'ROLE_OPERATOR',
  LABEL: 'Básico',
  DESCRIPTION:
    'Tem visão parcial dos pacotes do Leve. Apenas consulta e distribui pacotes direcionados para uma parte da operação.'
};

export const ROLES = {
  ROLE_OWNER,
  ROLE_ADMIN,
  ROLE_REGULAR,
  ROLE_OPERATOR
};

export const STATUS_MAP = {
  USER_STATUS_PENDING: PENDING_LABEL
};
