import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ typography }) => ({
  buttomPos: {
    bottom: typography.pxToRem(4)
  },
  buttomPosTop: {
    top: typography.pxToRem(3)
  }
}));

export default useStyles;
