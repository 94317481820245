export const ERRORS = {
  MULTIPLE_SUMMARIES:
    'Template component must receive only one child of Summary type',
  NO_SUMMARY: 'Template component must receive a Summary as child',
  MULTIPLE_CONTENTS:
    'Template component must receive only one child of Content type',
  NO_CONTENT: 'Template component must receive a Content as child',
  UNSUPPORTED_CHILD: 'An unsupported child was used in Template'
};

export default {
  ERRORS
};
