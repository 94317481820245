import theme, { colors } from '@loggi/mar';

export const transformOrigin = {
  vertical: 'top',
  horizontal: 'left'
};

const endBorderStyle = {
  borderTopRightRadius: '50%',
  borderBottomRightRadius: '50%'
};

const startBorderStyle = {
  borderTopLeftRadius: '50%',
  borderBottomLeftRadius: '50%'
};

const datePickerStyle = {
  MuiPickersDateRangeDay: {
    rangeIntervalDay: {
      '&:first-child $rangeIntervalDayPreview': {
        borderLeftColor: 'transparent'
      },
      '&:last-child $rangeIntervalDayPreview': {
        borderRightColor: 'transparent'
      }
    },
    rangeIntervalDayHighlight: {
      borderRadius: 0,
      color: theme.palette.primary.contrastText,
      backgroundColor: colors.blue[100],
      '&:first-child': startBorderStyle,
      '&:last-child': endBorderStyle
    },
    dayOutsideRangeInterval: {
      '&:hover': {
        border: `1px solid ${colors.blue[500]}`
      }
    },
    dayInsideRangeInterval: {
      color: theme.palette.getContrastText(theme.palette.primary.light, 0.6)
    },
    rangeIntervalPreview: {
      border: '2px solid transparent'
    },
    rangeIntervalDayPreview: {
      borderRadius: 0,
      border: `2px solid trasparent`,
      backgroundColor: colors.blue[50],
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',

      '&$rangeIntervalDayPreviewStart': {
        borderLeftColor: 'transparent',
        ...startBorderStyle
      },
      '&$rangeIntervalDayPreviewEnd': {
        borderRightColor: 'transparent',
        ...endBorderStyle
      }
    }
  },
  MuiPickersDay: {
    day: {
      borderRadius: '50%',
      padding: 0,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightLight,
      '&:hover': {
        backgroundColor: 'trasparent'
      },
      '&:focus': {
        '&$daySelected': {
          willChange: 'background-color',
          backgroundColor: colors.blue[500]
        }
      }
    },
    today: {
      '&:not($daySelected)': {
        border: `1px solid ${colors.blue[500]}`
      }
    },
    daySelected: {
      backgroundColor: colors.blue[500],
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightMedium,
      '&:hover': {
        willChange: 'background-color',
        backgroundColor: colors.blue[500]
      }
    }
  },
  MuiPickersArrowSwitcher: {
    iconButton: {
      zIndex: 1,
      backgroundColor: theme.palette.background.paper,
      color: colors.blue[500]
    }
  },
  MuiPickersDesktopDateRangeCalendar: {
    rangeCalendarContainer: {
      '&:not(:last-child)': {
        borderRight: `0px solid transparent`,
        paddingRight: '48px'
      }
    },
    calendar: {
      minWidth: 290,
      minHeight: 260
    }
  },
  MuiCalendar: {
    week: {
      margin: `2px 0 transparent`
    }
  }
};

export default datePickerStyle;
