import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useStyles = makeStyles(({ typography }) => ({
  performanceInfo: {
    color: colors.storm[500],
    letterSpacing: 0,
    lineHeight: 'initial'
  },
  earningsInfo: {
    letterSpacing: 0,
    color: colors.blue[900],
    lineHeight: 'initial'
  },
  starStyle: {
    marginRight: typography.pxToRem(6)
  },
  container: {
    paddingTop: typography.pxToRem(12),
    paddingBottom: typography.pxToRem(12)
  }
}));

export default useStyles;
