import api from 'infra/api/leve';

/**
 *
 * @param {string} lastMileCompany
 * @param {number} distributionCenterId
 * @param {string} startDate
 * @param {string} endDate
 * @returns {Object} Axios' response object containing the following information
 *
 * {
 *  "missedEventsTotal": 42,
 *  "missedEventsAverage": 42.17000000178813934,
 *  "stars": 3.0,
 *  "startDate": "2020-06-16T03:00:00.000Z",
 *  "endDate": "2020-06-30T03:00:00.000Z"
 * }
 */

const getLMCPerformance = ({
  lastMileCompany = null,
  distributionCenterId,
  startDate = null,
  endDate = null
}) => {
  return api
    .post('proxy/last-mile/v1/leve/performance', {
      lastMileCompany,
      distributionCenterId,
      startDate,
      endDate
    })
    .then(response => response.data);
};

export default getLMCPerformance;
