import api from 'infra/api/leve';
import { URL_PROXY_V3_PREFIX } from 'infra/service/constants';
import { parseRequestError } from 'app/httpMessage';

export const ITINERARY_LIST = '/itineraries';

const getItineraries = (page = 1, status, startDate, endDate) => {
  const params = {
    start_date: startDate,
    end_date: endDate,
    itinerary_status: status,
    page
  };
  return api
    .get(`${URL_PROXY_V3_PREFIX}${ITINERARY_LIST}`, { params })
    .then(({ data }) => data)
    .catch(parseRequestError());
};

export default getItineraries;
