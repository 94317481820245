const ZOOM = 18;

const MAP_CHANGE_ADDRESS_PIN_TOOLTIP_SHOW_DELAY_MS = 300;

const TEXT = {
  PIN_HELP_TITLE: 'É por aqui?',
  PIN_HELP_TEXT: 'Ajuste a localização',
  PIN_LAT: 'Lat:',
  PIN_LONG: 'Long:',
  SEARCH_LABEL: 'Buscar no mapa',
  SEARCH_TOOLTIP: 'Digite o nome da rua ou CEP'
};

export { ZOOM, TEXT, MAP_CHANGE_ADDRESS_PIN_TOOLTIP_SHOW_DELAY_MS };
