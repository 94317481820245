import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CustomDrawer, DrawerContent, DrawerHeader } from 'shared/drawer';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';
import showSnackbar from 'shared/snackbar';
import { getLoggersService } from '../drivers.service';
import { TEXTS } from './drivers-form.constants';

const CltsFormComponent = ({
  setProfile,
  profile,
  cltForm,
  setCltForm,
  loading,
  setOpenForm
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    NEW_REGISTER: { TITLE, SUBTITLE },
    CONTINUE,
    EMAIL_SEARCH,
    NO_OPTIONS_TEXT,
    LOADING_TEXT
  } = TEXTS;
  const [{ users = [] }, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onError = useCallback(
    ({ message }) => {
      showSnackbar({
        variant: 'error',
        message,
        enqueueSnackbar
      });
    },
    [enqueueSnackbar]
  );

  const handleSubmit = event => {
    event.preventDefault();
    setCltForm(false);
    setOpenForm(true);
  };

  useEffect(() => {
    setIsLoading(true);
    getLoggersService()
      .then(({ corporateUsers }) => setData({ users: corporateUsers }))
      .catch(onError)
      .finally(() => setIsLoading(false));
  }, [onError, setCltForm, setData]);

  return (
    <CustomDrawer open={cltForm}>
      <DrawerHeader
        title={TITLE}
        detail={SUBTITLE}
        showDivider={false}
        handleClosingDrawer={() => setCltForm(false)}
      />
      <DrawerContent showDivider>
        <Box display="flex" position="relative">
          <Autocomplete
            id="combo-box-email"
            loading={isLoading}
            loadingText={LOADING_TEXT}
            options={users}
            noOptionsText={NO_OPTIONS_TEXT}
            getOptionLabel={option => option.email}
            onChange={(_, selectedOption) => setProfile(selectedOption || {})}
            closeIcon={null}
            forcePopupIcon={false}
            data-testid="autocomplete-search"
            renderInput={params => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                fullWidth
                variant="outlined"
                placeholder={EMAIL_SEARCH}
                data-testid="input-search"
              />
            )}
            fullWidth
          />
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            right={0}
            px={2}
            style={{ pointerEvents: 'none' }}
          >
            <SearchIcon />
          </Box>
        </Box>
      </DrawerContent>
      <DrawerContent>
        <Button
          color="primary"
          data-testid="form-continue"
          disabled={loading || !profile.email}
          fullWidth
          type="submit"
          variant="outlined"
          onClick={handleSubmit}
        >
          {CONTINUE}
        </Button>
      </DrawerContent>
    </CustomDrawer>
  );
};

CltsFormComponent.propTypes = {
  setProfile: PropTypes.func.isRequired,
  cltForm: PropTypes.bool.isRequired,
  setCltForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  profile: PropTypes.shape().isRequired,
  setOpenForm: PropTypes.func.isRequired
};

export default CltsFormComponent;
