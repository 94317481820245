import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import {
  emailIsValid,
  nameIsValid,
  isCpfValid,
  phoneIsValid
} from 'shared/validators';
import formatPhone from 'shared/formatters/phone/phone';
import formatCpf from 'shared/formatters/cpf';
import { CustomDrawer, DrawerContent, DrawerHeader } from 'shared/drawer';
import TermsOfUsePdf from '../files/terms_of_use.pdf';
import { TEXTS, LOGGI_EMAIL_DOMAIN } from './drivers-form.constants';

const DriversFormComponent = ({
  loading,
  profile,
  onClose,
  open,
  onSubmit,
  transportTypes
}) => {
  const { cpf, email, fullName, mobile: mobileNumber } = profile;
  const initialState = user => ({
    fullName: user?.fullName || '',
    cpf: user?.cpf || '',
    email: user?.email || '',
    mobileNumber: formatPhone(user?.mobileNumber || ''),
    transportType: '',
    terms: false
  });
  const [formData, setFormData] = useState(initialState({}));
  const [formErrors, setFormErrors] = useState({});
  const [isCLT, setIsCLT] = useState(false);
  const hasOldProfile = profile?.deliverer && !profile.deliverer.isDriver;
  const { TITLE, SUBTITLE, BUTTON } = hasOldProfile
    ? TEXTS.COMPLETE_REGISTER
    : TEXTS.NEW_REGISTER;

  useEffect(() => {
    if (profile || hasOldProfile) {
      setFormData(initialState({ cpf, email, fullName, mobileNumber }));
      setIsCLT(email?.endsWith(LOGGI_EMAIL_DOMAIN));
      setFormErrors({
        fullName: !nameIsValid(fullName),
        email: !emailIsValid(email),
        mobileNumber: !phoneIsValid(mobileNumber),
        cpf: !isCpfValid(cpf)
      });
    } else {
      setFormData(initialState({}));
    }
  }, [cpf, email, fullName, mobileNumber, profile, hasOldProfile]);

  const handleClose = () => {
    setFormData(initialState());
    onClose();
  };

  const handleSubmit = e => {
    e.preventDefault();

    return onSubmit(formData).then(() => setFormData(initialState()));
  };

  const handleTyping = (value, field, format) => {
    setFormData(_form => {
      return {
        ..._form,
        [field]: format ? format(value) : value
      };
    });
  };

  const validateField = (validation, field) => {
    if (formData[field]) {
      setFormErrors(_errors => ({
        ..._errors,
        [field]: !validation(formData[field])
      }));
    }
  };

  const getErrorMessage = (field, key) =>
    formData[key] && formErrors[key] && field.texts.error;
  const errors = Object.values(formErrors);
  const hasErrors = !errors.length || Object.values(formErrors).some(Boolean);
  const isFilled = Object.values(formData).every(Boolean);
  const firstEmptyOption = {};
  const options = [firstEmptyOption].concat(transportTypes).map(option => (
    <option id={`opt-${option.name}`} key={option.name || 0} value={option.id}>
      {option.name}
    </option>
  ));

  const inputs = {
    email: {
      validate: emailIsValid,
      texts: TEXTS.EMAIL,
      type: 'email',
      disabled: isCLT && !!email
    },
    fullName: {
      validate: nameIsValid,
      texts: TEXTS.NAME,
      maxLength: 64,
      disabled: isCLT && !!fullName
    },
    cpf: {
      validate: isCpfValid,
      format: formatCpf,
      texts: TEXTS.CPF,
      maxLength: 14
    },
    mobileNumber: {
      validate: phoneIsValid,
      format: formatPhone,
      texts: TEXTS.MOBILE_PHONE,
      maxLength: 15
    },
    transportType: {
      validate: Boolean,
      texts: TEXTS.TRANSPORT_TYPE,
      isSelect: true,
      options
    }
  };

  const inputFactory = ([key, input], idx) => (
    <Box key={key} pt={idx && 2.5}>
      <TextField
        select={input.isSelect}
        SelectProps={{
          displayEmpty: true,
          native: true
        }}
        error={Boolean(formData[key] && formErrors[key])}
        helperText={getErrorMessage(input, key)}
        fullWidth
        InputLabelProps={formData[key] ? { shrink: true } : {}}
        inputProps={{ 'data-testid': key, maxLength: input.maxLength }}
        label={input.texts.label}
        name={key}
        onBlur={() => validateField(input.validate, key)}
        onChange={e => handleTyping(e.target.value, key, input.format)}
        value={formData[key]}
        variant="outlined"
        type={input.type}
        disabled={input?.disabled}
      >
        {input.options}
      </TextField>
    </Box>
  );

  return (
    <form noValidate onSubmit={handleSubmit}>
      <CustomDrawer open={open}>
        <DrawerHeader
          title={TITLE}
          detail={SUBTITLE}
          showDivider={false}
          handleClosingDrawer={handleClose}
        />
        <DrawerContent>
          {Object.entries(inputs).map(inputFactory)}
        </DrawerContent>
        <DrawerContent>
          <Box display="flex">
            <Checkbox
              inputProps={{ 'data-testid': 'terms' }}
              name="terms"
              onChange={({ target }) => {
                handleTyping(target.checked, 'terms');
              }}
              checked={formData.terms || false}
              variant="outlined"
            />
            <Typography variant="body2" color="textPrimary">
              {TEXTS.TERMS[0]}
              <Link target="_blank" href={TermsOfUsePdf}>
                {TEXTS.TERMS_LINK}
              </Link>
              {TEXTS.TERMS[1]}
            </Typography>
          </Box>
        </DrawerContent>
        <DrawerContent>
          <Button
            color="primary"
            data-testid="form-submit"
            disabled={hasErrors || !isFilled || loading}
            fullWidth
            type="submit"
            variant="contained"
          >
            {BUTTON}
          </Button>
        </DrawerContent>
      </CustomDrawer>
    </form>
  );
};

DriversFormComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  profile: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  transportTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
};

export default DriversFormComponent;
