import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
  bulletCancelled: {
    backgroundColor: palette.info.dark
  },
  bulletFinished: {
    backgroundColor: palette.success.main
  },
  bulletOngoing: {
    backgroundColor: palette.info.main
  }
}));

export default useStyles;
