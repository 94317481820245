/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProfileChangeBase from './profile-change-base.component';

const ProfileChangeBaseContainer = ({
  dcName,
  lmcName,
  setProfileState,
  onChooseBase,
  data
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [dataDisplayed, setDataDisplayed] = useState(data);

  useEffect(() => {
    const valueFilter = (distribution_center, last_mile_company) => {
      const isNameOnSearch = distribution_center?.name
        .toLowerCase()
        .includes(searchValue.toLowerCase());
      const isCodeOnSearch = distribution_center?.routing_code
        ?.toLowerCase()
        .includes(searchValue.toLowerCase());
      const isLMCNameOnSearch = last_mile_company?.name
        .toLowerCase()
        .includes(searchValue.toLowerCase());
      const isLMCIdentificationOnSearch = last_mile_company?.identification
        .toLowerCase()
        .includes(searchValue.toLowerCase());
      return (
        isNameOnSearch ||
        isCodeOnSearch ||
        isLMCNameOnSearch ||
        isLMCIdentificationOnSearch
      );
    };

    if (searchValue.trim().length > 0) {
      setDataDisplayed(
        data.filter(({ distribution_center, last_mile_company }) =>
          valueFilter(distribution_center, last_mile_company)
        )
      );
    } else {
      setDataDisplayed(data);
    }
  }, [searchValue, data]);

  const onChangeInputSearch = event => {
    setSearchValue(event.target.value);
  };

  const onRestoreList = () => {
    setSearchValue('');
    setDataDisplayed(data);
  };

  return (
    <ProfileChangeBase
      setProfileState={setProfileState}
      onChooseBase={onChooseBase}
      dcName={dcName}
      lmcName={lmcName}
      dataDisplayed={dataDisplayed}
      searchValue={searchValue}
      onRestoreList={onRestoreList}
      onChangeInputSearch={onChangeInputSearch}
    />
  );
};

export default ProfileChangeBaseContainer;

ProfileChangeBaseContainer.propTypes = {
  setProfileState: PropTypes.func.isRequired,
  dcName: PropTypes.string,
  lmcName: PropTypes.string,
  onChooseBase: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ).isRequired
};
ProfileChangeBaseContainer.defaultProps = {
  dcName: '',
  lmcName: ''
};
