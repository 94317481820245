import api from 'infra/api/leve';
import { URL_API_V1_LAST_MILE_PREFIX } from 'infra/service/constants';

const offerDetailService = async offerId => {
  return api
    .get(`${URL_API_V1_LAST_MILE_PREFIX}/offers/${offerId}`)
    .then(_data => {
      return _data.data;
    });
};

export const serviceCancelOffer = async (driverId, offerId) => {
  const _data = await api.put(`${URL_API_V1_LAST_MILE_PREFIX}/offers/cancel`, {
    driver_id: driverId,
    pickup_id: offerId
  });

  return _data.data;
};

export const deallocateDriver = async pickupId => {
  return api.post(`${URL_API_V1_LAST_MILE_PREFIX}/offers/deallocate`, {
    pickupId: parseInt(pickupId, 10)
  });
};

export default offerDetailService;

export function buildLoggiAddress(address) {
  const addr = address.postalAddress;
  let fullAddress = '';
  fullAddress = addr.addressLines.filter(x => !!x).join(' • ');
  fullAddress += addr.sublocality ? ` • ${addr.sublocality}` : '';
  fullAddress += addr.locality ? ` • ${addr.locality}` : '';
  fullAddress += addr.administrativeArea ? ` - ${addr.administrativeArea}` : '';
  fullAddress += addr.postalCode ? ` • ${addr.postalCode}` : '';
  return fullAddress;
}

export function buildPickupOriginAddress(detail) {
  return buildLoggiAddress(detail.pickupOriginAddress);
}
