import getPosition from 'app/geolocation';
import movePackageList from 'app/move-package-list';
import { DELIVERY_ASSIGNMENT } from './drawer-change-deliverer.constants';

const changeDelivererService = ({ delivererId, packageId }) =>
  getPosition().then(({ latitude, longitude }) =>
    movePackageList(
      DELIVERY_ASSIGNMENT,
      delivererId,
      [
        {
          packageId,
          location: {
            lat: latitude,
            lng: longitude
          },
          notes: null
        }
      ],
      false,
      latitude,
      longitude,
      null,
      delivererId
    )
  );

export default changeDelivererService;
