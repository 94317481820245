import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const size = 6;
export const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {
    backgroundColor: colors.blue[100],
    borderRadius: '50%',
    height: spacing(size), // 48px
    width: spacing(size),
    position: 'relative',
    '& svg': {
      color: colors.blue[500],
      position: 'absolute',
      height: '33.33%', // 16px
      width: '33.33%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  wrapperVariant: {
    backgroundColor: colors.green[100]
  },
  wrapperVariantDefault: {
    backgroundColor: colors.root[0]
  }
}));

export default useStyles;
