import React, { useState, useEffect, useCallback } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import showSnackbar from 'shared/snackbar';

import { Grid, Box, CircularProgress, Backdrop } from '@material-ui/core';

import { PROCESSES, DESTINATIONS } from 'app/filter-packages/constants';
import filterPackages from 'app/filter-packages';
import getPackageIdMoveList from 'app/get-package-id-move-list';
import getDeliverers from 'app/get-deliverers';
import DialogDistribuirRedirectContainer from 'shared/confirm-dialog/distribuir-redirect';
import getPosition from 'app/geolocation';
import { playSuccess, playError } from 'tracking/sound/index';

import { defaultPagination } from 'app/changePagination';
import { AUTHORIZED_ROUTES } from 'view/constants';
import DelivererPackageAssociationTemplate from 'view/templates/deliverer-package-association';
import DrawerPackagesOutOfCoverage from 'information/drawer-packages-out-of-coverage';
import { STATUS_ERROR_CODE } from 'information/drawer-packages-out-of-coverage/drawer-packages-out-of-coverage.constants';

import { getSelectedRoutingCode } from 'auth/login/login.service';
import { useStateValue } from 'shared/contexts';
import { SUCCESS_MESSAGE } from './constants';

export const UNIT_LOAD_INFOS = userId => [
  {
    process: PROCESSES.DELIVERY_ASSIGNMENT,
    destination: userId
  },
  {
    process: PROCESSES.DELIVERY,
    destination: DESTINATIONS.IN_PROGRESS,
    userId
  },
  {
    process: PROCESSES.DELIVERY,
    destination: DESTINATIONS.RECIPIENT_UNAVAILABLE,
    userId
  },
  {
    process: PROCESSES.DELIVERY,
    destination: DESTINATIONS.INVALID_DESTINATION,
    userId
  },
  {
    process: PROCESSES.DELIVERY,
    destination: DESTINATIONS.RECIPIENT_REFUSED,
    userId
  }
];

export default function DelivererPackageAssociation() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [deliverer, setDeliverer] = useState(history.location.state);
  const [loadingDeliverer, setLoadingDeliverer] = useState(true);

  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(defaultPagination);

  const [orderBy, setOrderBy] = useState({});
  const { page, itemsPerPage } = pagination;

  const [, dispatch] = useStateValue();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [packagesOutOfCoverage, setPackagesOutOfCoverage] = useState([]);

  const handleClose = () => {
    setOpenDrawer(false);
    setPackagesOutOfCoverage([]);
  };

  const _filterPackages = useCallback(
    updatedPagination => {
      filterPackages(UNIT_LOAD_INFOS(id), updatedPagination, orderBy)
        .then(response => {
          setPagination({
            numberItems: Number(response.pagination.numberItems),
            numberPages: Number(response.pagination.numberPages),
            page: Number(response.pagination.page),
            itemsPerPage: Number(response.pagination.itemsPerPage)
          });
          setPackages(response.packages);
        })
        .catch(responseError => {
          showSnackbar({
            message: responseError.message,
            variant: 'error',
            enqueueSnackbar
          });
        })
        .then(() => setLoading(false));
    },
    [orderBy, id, enqueueSnackbar]
  );

  const changePagination = currentPage => {
    setLoading(true);
    setPagination({
      ...pagination,
      page: currentPage + 1
    });
  };

  const onMovePackageList = (barcode, isPhyisicalEvidence) => {
    setLoading(true);
    getPosition().then(({ latitude, longitude }) => {
      getPackageIdMoveList(
        PROCESSES.DELIVERY_ASSIGNMENT,
        deliverer.id,
        barcode,
        isPhyisicalEvidence,
        latitude,
        longitude,
        '',
        deliverer.id
      )
        .then(() => _filterPackages({ page, itemsPerPage }))
        .then(() => {
          showSnackbar({
            message: SUCCESS_MESSAGE,
            variant: 'success',
            enqueueSnackbar
          });
          playSuccess();
        })
        .catch(responseError => {
          const isThereAtLeastOnePackageOutOfCoverage =
            responseError?.status ===
              STATUS_ERROR_CODE.PACKAGES_OUT_OF_COVERAGE &&
            responseError.data?.length > 0;
          if (isThereAtLeastOnePackageOutOfCoverage) {
            setPackagesOutOfCoverage(responseError.data);
            setOpenDrawer(true);
          } else {
            showSnackbar({
              message: responseError.message,
              variant: 'error',
              enqueueSnackbar
            });
            playError();
          }
        })
        .then(() => {
          setLoading(false);
        });
    });
  };

  const rc = getSelectedRoutingCode();

  const _finishListShare = () =>
    history.push(`/${rc}${AUTHORIZED_ROUTES.MOVE.DELIVERERS}`);

  const changeReorder = (orderByColumn, isAscending) => {
    setOrderBy({
      orderByColumn,
      isAscending
    });
  };

  const onErrorGetDeliverers = responseError => {
    setLoadingDeliverer(false);
    showSnackbar({
      message: responseError.message,
      variant: 'error',
      enqueueSnackbar
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSuccessGetDeliverers = deliverers => {
    const delivererInfo = deliverers.filter(item => item.id === id);
    if (delivererInfo) {
      setDeliverer(delivererInfo[0]);
    }
    setLoadingDeliverer(false);
  };

  useEffect(() => {
    if (!deliverer) {
      getDeliverers()
        .then(onSuccessGetDeliverers)
        .catch(onErrorGetDeliverers);
    } else {
      setLoadingDeliverer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverer, onSuccessGetDeliverers]);

  useEffect(() => {
    if (!loadingDeliverer) {
      _filterPackages({ page, itemsPerPage });
    }
  }, [_filterPackages, itemsPerPage, loadingDeliverer, page]);

  useEffect(() => {
    dispatch({
      type: 'setLoading',
      payload: load => {
        if (load) {
          _filterPackages({ page, itemsPerPage });
        }
      }
    });
  }, [dispatch, _filterPackages, itemsPerPage, page]);

  return (
    <>
      <DialogDistribuirRedirectContainer />
      <Box zIndex="1000" position="absolute">
        <Backdrop open={openDrawer} />
      </Box>

      <DrawerPackagesOutOfCoverage
        open={openDrawer}
        onClose={handleClose}
        packagesInfo={packagesOutOfCoverage}
      />
      {loadingDeliverer && (
        <Grid container justify="center">
          <Box p={2}>
            <CircularProgress justify="center" />
          </Box>
        </Grid>
      )}
      {!loadingDeliverer && deliverer && (
        <DelivererPackageAssociationTemplate
          loading={loading}
          deliverer={deliverer}
          filteredPackages={packages}
          onMovePackageList={onMovePackageList}
          finishListShare={_finishListShare}
          pagination={pagination}
          changePagination={changePagination}
          changeReorder={changeReorder}
        />
      )}
    </>
  );
}

DelivererPackageAssociation.defaulName = 'DelivererPackageAssociation';
