import { COLUMN_TITLE, TEXTS } from '../drivers-tracking.constants';
import {
  formatDate,
  formatHour,
  formatPackages
} from '../drivers-tracking.utils';
import ItineraryStatus from './drivers-tracking-itinerary.component';

const columns = {
  driverFullName: {
    header: COLUMN_TITLE.NAME
  },
  activity_type: {
    header: COLUMN_TITLE.ACTIVITY,
    component: ({ activity }) => TEXTS.ACTIVITY_MAPPER[activity]
  },
  date: {
    header: COLUMN_TITLE.DATE,
    component: ({ createdTime }) => formatDate(createdTime)
  },
  start: {
    header: COLUMN_TITLE.BEGIN,
    component: ({ startedTime, itineraryStatus }) =>
      formatHour(startedTime, itineraryStatus)
  },
  finish: {
    header: COLUMN_TITLE.END,
    component: ({ finishedTime, itineraryStatus }) =>
      formatHour(finishedTime, itineraryStatus)
  },
  estimated: {
    header: COLUMN_TITLE.EXPECTED,
    component: ({ foreseenPackages, itineraryStatus }) =>
      formatPackages(foreseenPackages, itineraryStatus)
  },
  foreseen: {
    header: COLUMN_TITLE.DELIVERED,
    component: ({ successfulPackages, itineraryStatus }) =>
      formatPackages(successfulPackages, itineraryStatus)
  },
  itinerary_status: {
    header: COLUMN_TITLE.STATUS,
    component: ItineraryStatus
  }
};

export default columns;
