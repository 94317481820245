import React, { useContext } from 'react';
import { Box } from '@material-ui/core';

import FilterComponent from 'shared/filter';
import { FILTERS } from 'shared/filter/filter.constants';
import BaseContext from 'tracking/in-base/base.context';
import PropTypes from 'prop-types';

const BaseFilter = ({ filterSender, filterCitiesAttend, filterCircles }) => {
  const {
    onApplyChanges,
    filters,
    checkedItemsBase,
    setCheckedItemsBase
  } = useContext(BaseContext);
  return (
    <Box data-testid="alert-status-header-packages">
      {filterSender && (
        <FilterComponent
          key={FILTERS.SENDER}
          btnFilter={FILTERS.SENDER}
          onApplyChanges={onApplyChanges}
          whichFilter={FILTERS.SENDER}
          baseFilters={filters}
          checkedItemsBase={checkedItemsBase}
          setCheckedItemsBase={setCheckedItemsBase}
        />
      )}
      {filterCitiesAttend && (
        <FilterComponent
          key={FILTERS.CITIES}
          btnFilter={FILTERS.CITIES}
          onApplyChanges={onApplyChanges}
          whichFilter={FILTERS.CITIES}
        />
      )}
      {filterCircles && (
        <FilterComponent
          key={FILTERS.CIRCLES}
          btnFilter={FILTERS.CIRCLES}
          onApplyChanges={onApplyChanges}
          whichFilter={FILTERS.CIRCLES}
        />
      )}
    </Box>
  );
};

BaseFilter.propTypes = {
  filterSender: PropTypes.bool,
  filterCitiesAttend: PropTypes.bool,
  filterCircles: PropTypes.bool
};

BaseFilter.defaultProps = {
  filterSender: false,
  filterCitiesAttend: false,
  filterCircles: false
};

export default BaseFilter;
