import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';
import ListItemStyles from 'shared/styles/list.material.styles';

const useStyles = makeStyles(({ typography, palette, spacing }) => ({
  list: {
    width: '300px',
    ...ListItemStyles({ typography, palette, spacing }),
    padding: `${spacing(2)}px 0px` // overriding listItem
  },
  root: {
    borderColor: colors.root[0],
    '&:not(:first-child)': {
      borderRadius: 300
    },
    '&:first-child': {
      borderRadius: 300
    },
    color: colors.root[0],
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-selected': {
      color: colors.blue[500],
      backgroundColor: colors.root[0],
      '&:hover': {
        backgroundColor: colors.root[0]
      }
    }
  },
  rootMobile: {
    width: '40px',
    height: '40px',
    '&:not(:first-child)': {
      borderRadius: 300
    },
    '&:first-child': {
      borderRadius: 300
    },
    color: colors.root[0],
    backgroundColor: colors.blue[500],
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-selected': {
      color: colors.blue[500],
      backgroundColor: colors.root[0],
      '&:hover': {
        backgroundColor: colors.root[0]
      }
    }
  }
}));

export default useStyles;
