import axios from 'axios';
import { STATUS } from './alert-status.constants';

const getStatusPlatform = platform => {
  return axios
    .get(process.env.REACT_APP_STATUS_PAGE_COMPONENTS_URL)
    .then(response => {
      const selectedPlatform = response.data.components.find(
        component => component.id === platform
      );
      return selectedPlatform?.status || STATUS.OPERATIONAL;
    });
};

export default getStatusPlatform;
