import { Box, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const DriversProfileActivityTypeComponent = ({
  title,
  subtitle,
  buttonText,
  onClick,
  disabled
}) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="subtitle2">
      <Box component="span" fontWeight="fontWeightBold">
        {title}
      </Box>
    </Typography>
    <Typography variant="body2">
      <Box component="span" pt={0.5} color="secondary">
        {subtitle}
      </Box>
    </Typography>
    {buttonText && (
      <Box pt={3.5}>
        <Button
          disabled={disabled}
          size="small"
          variant="outlined"
          color="primary"
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </Box>
    )}
  </Box>
);

DriversProfileActivityTypeComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

DriversProfileActivityTypeComponent.defaultProps = {
  subtitle: null,
  buttonText: null,
  onClick: () => {},
  disabled: false
};

export default DriversProfileActivityTypeComponent;
