import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Drawer, Box, Fab, Typography, Button } from '@material-ui/core';

import { ReactComponent as IconEmptyNotifications } from 'images/illustra-empty-notification.svg';
import { ReactComponent as IconErrorNotifications } from 'images/illustra-error-notification.svg';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { DRAWER, ERROR } from './notifications.constants';
import NotificationListContainer from './list/notification-list.container';
import { markNotificationsAsSeen } from './notifications.service';

export default function DrawerNotificationsComponent({
  notifications,
  handleCloseDrawerNotifications,
  handleGetNotifications
}) {
  useEffect(() => {
    const onlyUnseenNotificationIds = [];
    notifications
      ?.filter(({ seenAt }) => !seenAt)
      .forEach(notification => {
        if (notification.ids) {
          onlyUnseenNotificationIds.push(...notification.ids);
        } else {
          onlyUnseenNotificationIds.push(notification.id);
        }
      });

    if (onlyUnseenNotificationIds?.length)
      markNotificationsAsSeen(onlyUnseenNotificationIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer open anchor="right" variant="persistent">
      <Box maxWidth={480} role="presentation">
        <Box py={5}>
          <Box ml={2.8}>
            <Fab
              size="small"
              onClick={handleCloseDrawerNotifications}
              data-testid="fab-close-drawer-notification"
            >
              <CloseRoundedIcon />
            </Fab>
          </Box>
          <Box my={4} ml={4}>
            <Typography variant="h4">
              <strong>{DRAWER.TITLE}</strong>
            </Typography>
          </Box>
          {notifications?.length > 0 ? (
            <NotificationListContainer
              notifications={notifications}
              handleGetNotifications={handleGetNotifications}
            />
          ) : (
            <Box
              mx={3}
              height={400}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {notifications === null && <IconErrorNotifications />}
              {notifications !== null && <IconEmptyNotifications />}
              <Box mt={4} mb={10}>
                <Typography
                  color="textSecondary"
                  align="center"
                  variant="body1"
                >
                  {notifications === null && ERROR.GETTING_NOTIFICATIONS}
                  {notifications !== null && DRAWER.EMPTY_NOTIFICATIONS}
                </Typography>
              </Box>
              {notifications === null && (
                <Button
                  data-testid="btn-retry"
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleGetNotifications}
                >
                  {DRAWER.BUTTON.RETRY}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

DrawerNotificationsComponent.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      userId: PropTypes.string,
      intention: PropTypes.string,
      body: PropTypes.string,
      priority: PropTypes.string,
      createdAt: PropTypes.string,
      receivedAt: PropTypes.string,
      readAt: PropTypes.string
    })
  ),
  handleCloseDrawerNotifications: PropTypes.func.isRequired,
  handleGetNotifications: PropTypes.func.isRequired
};

DrawerNotificationsComponent.defaultProps = {
  notifications: []
};
