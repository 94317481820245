import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  avatar: {
    color: palette.text.primary,
    fontWeight: typography.fontWeightBold,
    fontSize: typography.h6.fontSize,
    backgroundColor: palette.common.white,
    border: '1px solid',
    borderColor: palette.grey[500]
  },
  incompleteRegister: {
    backgroundColor: palette.grey[100],
    color: palette.grey[700],
    width: 'fit-content',
    marginTop: spacing(0.5)
  },
  profilePicture: {
    width: typography.pxToRem(80),
    height: typography.pxToRem(80),
    borderRadius: '50%'
  },
  button: {
    color: palette.common.white,
    backgroundColor: palette.error.main,
    '&:hover': {
      backgroundColor: palette.error.dark
    }
  },
  boxButton: {
    cursor: 'pointer'
  }
}));

export default useStyles;
