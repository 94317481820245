const TEXT = {
  TITLE: 'Valores',
  TOOLTIP: 'Valores calculados até às 23:59 de ontem',
  SUBTITLE: 'Até ontem',
  OPEN: 'Aberto',
  UNTIL_YESTERDAY: ' até ontem',
  LOCAL_DELIVERY: 'Por entrega local',
  SERVED_DELIVERY: 'Por entrega atendida',
  CLOSE: 'Fechado',
  CLOSED: 'Fechou',
  PREVIOUSLY: 'Anteriores',
  THIS_YEAR: 'Nesse ano',
  ERROR:
    'Eita! Algo deu errado. Não conseguimos atualizar a página. Tente novamente mais tarde.'
};

const END_DAY = { hour: 23, minute: 59, second: 59 };
const START_DAY = { hour: 0, minute: 0, second: 0 };

const BUTTON = {
  OPEN: 'Ver aberto',
  CLOSE: 'Ver fechado',
  PREVIOUSLY: 'Ver anteriores',
  ISSUE_NF: 'Preparar notas'
};

export { TEXT, BUTTON, END_DAY, START_DAY };
