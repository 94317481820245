export const TEXT = {
  ADD: 'Adicionar',
  BASE_CITY_OPERATION_LABEL: 'Atua na cidade base',
  BASE_CITY_OPERATION_DESCRIPTION: 'Onde a transportadora está localizada',
  CORE_SUBTITLE:
    'Dê um nome ao círculo, defina sua região de atuação e adicione pessoas.',
  CORE_SUBTITLE_EDIT: 'Dê um nome ao círculo e defina sua região de atuação',
  CIRCLE_TITLE: 'Círculo',
  CREATE: 'Criar',
  CREATE_CORE_NAME: 'Informe o nome do círculo',
  CREATE_SUCCESS_MESSAGE: name => `Círculo ${name} criado com sucesso.`,
  DELETE: 'Excluir círculo',
  DELETE_DIALOG_BACK: 'Voltar',
  DELETE_DIALOG_CONFIRM: 'Quero Excluir',
  DELETE_DIALOG_TITLE: 'Você quer excluir o círculo?',
  DELETE_DIALOG_SUBTITLE:
    'Ao excluir, não será mais possível direcionar grupos para ele. Além disso, os grupos já direcionados vão precisar ser redirecionados para outro círculo.',
  DELETE_SUCCESS_MESSAGE: 'Tudo certo, círculo excluído!',
  EDIT: 'Editar',
  EDIT_CIRCLE: 'Editar círculo',
  EDIT_PEOPLE: 'Alterar pessoas',
  EDIT_CITIES_SUBTITLE: name => `Cidade atendida • ${name}`,
  EDIT_DRIVERS_SUBTITLE: 'Fazem a operação ou entrega dos pacotes.',
  EDIT_SUCCESS_MESSAGE: name => `Circulo ${name} editado com sucesso.`,
  DRIVER: '1 pessoa',
  DRIVERS: n => `${n} pessoas`,
  PEOPLE_SUBTITLE:
    'Adicione pessoas que possuem cadastro para base ou para rua.',
  PEOPLE_TITLE: 'Pessoas',
  SAVE: 'Salvar',
  SEARCH_USERS: 'Buscar Pessoas',
  SERVED_CITIES_OPERATION_LABEL: 'Atua em cidades atendidas',
  SERVED_CITIES_OPERATION_DESCRIPTION:
    'Cidades que ficam nas redondezas da cidade base',
  SHOW_EXTRA_PEOPLE: 'Mostrar mais 1 pessoa',
  SHOW_ALL_PEOPLE: n => `Mostrar mais ${n} pessoas`,
  SHOW_LESS: 'Mostrar menos',
  SHOW_EXTRA_CITY: 'Mostrar mais 1 cidade',
  SHOW_ALL_CITIES: n => `Mostrar mais ${n} cidades`,
  SUBMIT_BUTTON: 'Continuar',
  SUBMIT_LOADING: 'Carregando...',
  ACTING_REGION: 'Região de atuação'
};

export const ROLES = {
  ROLE_OWNER: 'Avançado',
  ROLE_ADMIN: 'Intermediário',
  ROLE_REGULAR: 'Intermediário',
  ROLE_OPERATOR: 'Básico'
};

export const ACTION_MAP = {
  CREATE: {
    type: 'dot',
    label: 'Criar'
  },
  INCOMPLETE: {
    type: 'dot',
    label: 'Editar'
  }
};

export default { ACTION_MAP, TEXT };
