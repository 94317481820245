const BRAZILIAN_STATES = [
  'Acre',
  'Alagoas',
  'Amapá',
  'Amazonas',
  'Bahia',
  'Ceará',
  'Distrito Federal',
  'Espírito Santo',
  'Goiás',
  'Maranhão',
  'Mato Grosso',
  'Mato Grosso do Sul',
  'Minas Gerais',
  'Paraná',
  'Paraíba',
  'Pará',
  'Pernambuco',
  'Piauí',
  'Rio Grande do Norte',
  'Rio Grande do Sul',
  'Rio de Janeiro',
  'Rondônia',
  'Roraima',
  'Santa Catarina',
  'Sergipe',
  'São Paulo',
  'Tocantins'
];

const BASE_ADDRESS_FIELDS_NAME = {
  neighborhood: 'Bairro',
  city: 'Cidade',
  state: 'Estado',
  postal_code: 'CEP'
};

const BASE_ADDRESS_FIELDS = {
  loggi: {
    neighborhood: {
      text: BASE_ADDRESS_FIELDS_NAME.neighborhood,
      key: 'sublocality'
    },
    city: { text: BASE_ADDRESS_FIELDS_NAME.city, key: 'locality' },
    state: { text: BASE_ADDRESS_FIELDS_NAME.state, key: 'administrativeArea' },
    postal_code: {
      text: BASE_ADDRESS_FIELDS_NAME.postal_code,
      key: 'postalCode'
    }
  }
};

const ADDRESS_TYPES = {
  loggi: 'postalAddress',
  coordinates: 'coordinates'
};

const ADDRESS_FORM_TYPES = [ADDRESS_TYPES.loggi];

const DRAWER_UPDATE_ADDRESS = {
  title: 'Ajustar endereço',
  address_types: {
    [ADDRESS_TYPES.loggi]: {
      title: 'Etiqueta Loggi',
      fields: {
        line: { text: 'Linha', key: 'addressLines' },
        ...BASE_ADDRESS_FIELDS.loggi
      }
    },
    [ADDRESS_TYPES.coordinates]: {
      title: 'Localização no mapa',
      fields: {
        latitude: { text: 'Latitude', key: 'latitude' },
        longitude: { text: 'Longitude', key: 'longitude' }
      }
    }
  },
  alert: {
    title: 'O endereço mudou?',
    description:
      'Você pode imprimir uma nova etiqueta para aumentar as chances de sucesso na entrega.'
  },
  change_address: 'Ajustar endereço'
};

const TEXT = {
  SHOW_MAP: 'Ver mapa',
  HELP_OF_PKG: 'do pacote #',
  CONFIRM_LOCATION_SUBMIT: 'Tudo certo',
  CONFIRM_LOCATION_CANCEL: 'Vou conferir',
  CANCEL_LOCATION_CANCEL: 'Continuar ajuste',
  CANCEL_LOCATION_CONFIRM: 'Quero cancelar'
};

const DIALOG_CASES = {
  CHANGE_ONLY_FORM: 'CHANGE_ONLY_FORM',
  CHANGE_ONLY_MAP: 'CHANGE_ONLY_MAP',
  CANCEL_POSTAL_ADDRESS: 'CANCEL_POSTAL_ADDRESS',
  CANCEL_COORDINATES: 'CANCEL_COORDINATES',
  CANCEL: 'CANCEL'
};

const DIALOG = {
  [DIALOG_CASES.CHANGE_ONLY_FORM]: {
    TITLE: 'A localização do mapa também está certa?',
    DESCRIPTION:
      'Como você atualizou os campos de endereço, é importante conferir se a localização no mapa também está correta.'
  },
  [DIALOG_CASES.CHANGE_ONLY_MAP]: {
    TITLE: 'O endereço também está certo?',
    DESCRIPTION:
      'Como você atualizou a localização no mapa, é importante conferir se o endereço também está correto.'
  },
  [DIALOG_CASES.CANCEL_POSTAL_ADDRESS]: {
    DESCRIPTION:
      'Ao cancelar, os ajustes que você fez nos campos de endereço não vão ser salvos.'
  },
  [DIALOG_CASES.CANCEL_COORDINATES]: {
    DESCRIPTION:
      'Ao cancelar, os ajustes que você fez no mapa não vão ser salvos.'
  },
  [DIALOG_CASES.CANCEL]: {
    TITLE: 'Você quer cancelar o ajuste?',
    DESCRIPTION: 'Ao cancelar, os ajustes que você fez não vão ser salvos.'
  }
};

const PACKAGE_FINALIZER_STATUS_EXCEPTION_MESSAGE =
  'Package is at finalizer status';
const PACKAGE_CORREIOS_CUSTODY_EXCEPTION_MESSAGE =
  'Package is on redispatch custody';
const PACKAGE_EN_ROUTE_EXCEPTION_MESSAGE = 'Package is on driver custody';
const PACKAGE_IS_NOT_FISCALLY_ALLOWED_EXCEPTION_MESSAGE =
  'Update package is not fiscally allowed';
const PACKAGE_IN_DEVOLUTION = 'Package is in a return direction';

const UNABLE_TO_SET_ADDRESS = 'Não é possível ajustar o endereço de um pacote';

const CUSTOM_MESSAGE = {
  [PACKAGE_FINALIZER_STATUS_EXCEPTION_MESSAGE]: `${UNABLE_TO_SET_ADDRESS} com o status finalizador.`,
  [PACKAGE_CORREIOS_CUSTODY_EXCEPTION_MESSAGE]: `${UNABLE_TO_SET_ADDRESS} que esta com os Correios.`,
  [PACKAGE_EN_ROUTE_EXCEPTION_MESSAGE]: `${UNABLE_TO_SET_ADDRESS} em rota de entrega.`,
  [PACKAGE_IS_NOT_FISCALLY_ALLOWED_EXCEPTION_MESSAGE]:
    'Você só pode fazer ajustes de endereço para um mesmo estado.',
  [PACKAGE_IN_DEVOLUTION]: `${UNABLE_TO_SET_ADDRESS} em devolução.`
};

export {
  BRAZILIAN_STATES,
  BASE_ADDRESS_FIELDS,
  ADDRESS_TYPES,
  ADDRESS_FORM_TYPES,
  DRAWER_UPDATE_ADDRESS,
  TEXT,
  DIALOG,
  DIALOG_CASES,
  CUSTOM_MESSAGE,
  UNABLE_TO_SET_ADDRESS,
  PACKAGE_FINALIZER_STATUS_EXCEPTION_MESSAGE,
  PACKAGE_CORREIOS_CUSTODY_EXCEPTION_MESSAGE,
  PACKAGE_EN_ROUTE_EXCEPTION_MESSAGE,
  PACKAGE_IS_NOT_FISCALLY_ALLOWED_EXCEPTION_MESSAGE,
  PACKAGE_IN_DEVOLUTION
};
