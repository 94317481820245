const TEXT = {
  BTN_ASSIGN_DRIVER: 'Alocar entregador',
  BTN_ASSIGN_CANCEL: 'Cancelar',
  BTN_CONFIRM_ASSIGN: 'Confirmar',
  LABEL_NO_DRIVER_AVAILABLE: 'Não existem entregadores disponíveis.',
  LABEL_SUCCESS_DRIVER_ALOCATION: 'Oferta enviada com sucesso!',
  LABEL_ERROR_DRIVER_ALOCATION: 'Erro na alocação!',
  LABEL_TITLE_DIALOG: 'Confirmar alocação?',
  LABEL_DESCRIPTION_DIALOG:
    'Após a alocação ser confirmada não poderá ser desfeita.',
  LABEL_ERROR_GET_DRIVER_LIST: 'Erro na busca de entregadores',
  LABEL_ERROR_ASSIGN_DRIVER: 'Erro na alocação'
};

export default TEXT;
