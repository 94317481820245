import { ReactComponent as MotoIcon } from 'images/ic-reserva-moto.svg';
import { ReactComponent as CarroIcon } from 'images/ic-reserva-carro.svg';
import { ReactComponent as VanIcon } from 'images/ic-reserva-van.svg';

export const DRAWER_HEADER_TEXT = {
  STATUS: 'Ofertas programadas',
  TITLE: 'Programe as ofertas',
  SUBTITLE:
    'Defina a data e hora, região para entrega e veículos para programar uma ou mais ofertas.',
  SUCCESS: 'Reserva criada com sucesso'
};

export const DATE_WINDOW_TEXT = {
  TITLE: 'Data de retirada',
  SUBTITLE: 'Quando os entregadores precisam ir até a base.'
};

export const TIME_WINDOW_TEXT = {
  TITLE: 'Período da retirada',
  SUBTITLE: 'Intervalo combinado para a retirada de pacotes.'
};

export const MODAL_TEXT = {
  TITLE: 'Veículos',
  SUBTITLE:
    'Adicione a quantidade necessária de veículos para retirar os pacotes. Para cada veículo adicionado, uma oferta é programada.',
  TOTAL: n => `${n} Ofertas`
};

export const REGION_TEXT = {
  TITLE: 'Região',
  SUBTITLE: 'Selecione a região para entrega das ofertas.',
  SEARCH_PLACEHOLDER: 'Buscar região',
  SHOW_LESS: 'Mostrar menos',
  SHOW_EXTRA_REGION: 'Mostrar mais 1 região',
  SHOW_ALL_REGIONS: n => `Mostrar mais ${n} regiões`
};

export const BUTTON_TEXT = 'Programar';

/**
 * This mapper needs follow the enum description in
 * https://github.com/loggi/xproto/blob/master/loggi/loggi_web/v1/transport_type.proto#L17
 *
 *  The TransportType values are:
 *
 *  TRANSPORT_TYPE_INVALID = 0;
 *  TRANSPORT_TYPE_MOTORCYCLE = 1;
 *  TRANSPORT_TYPE_BICYCLE = 2;
 *  TRANSPORT_TYPE_VAN = 3;
 *  TRANSPORT_TYPE_CAR = 4;
 */
export const MODAL_MAPPER = [
  { id: 1, value: 1, icon: MotoIcon, text: 'Moto' },
  { id: 2, value: 4, icon: CarroIcon, text: 'Carro' },
  { id: 3, value: 3, icon: VanIcon, text: 'Van' }
];

export const mockDemandRegions = [
  {
    id: '1',
    label: 'Region 1'
  },
  {
    id: '2',
    label: 'Region 2'
  },
  {
    id: '3',
    label: 'Region 3'
  },
  {
    id: '4',
    label: 'Region 4'
  },
  {
    id: '5',
    label: 'Region 5'
  },
  {
    id: '6',
    label: 'Region 6'
  }
];
