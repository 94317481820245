import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Chip, Typography } from '@material-ui/core';
import formatPhone from 'shared/formatters/phone/phone';
import { format as formatCpf } from 'cpf';
import {
  TRANSPORT_TYPE,
  OPERATIONAL_STATUS_TYPE,
  TEXTS
} from '../drivers.constants';
import useStyles from '../drivers.styles';
import DefaultProfilePicture from '../img/avatar.png';

const DriversProfileInfoComponent = ({
  profile: {
    email,
    fullName,
    mobileNumber,
    deliverer: {
      id,
      cpf,
      isDriver,
      profilePictureThumbnailUrl = DefaultProfilePicture,
      transportType,
      operationalStatus
    } = {}
  }
}) => {
  const styles = useStyles();
  return (
    <Box data-testid={`driver-profile-${id}`}>
      <Avatar
        className={styles.profilePicture}
        src={profilePictureThumbnailUrl}
      />
      <Box pt={2}>
        <Typography variant="h3">
          <em>{fullName}</em>
        </Typography>
      </Box>
      {isDriver ? (
        <>
          <Box pt={2.5}>
            <Typography variant="h5">
              De {TRANSPORT_TYPE[transportType]}
            </Typography>
          </Box>
          <Box pt={2.5} display="flex">
            <Typography variant="body1">{email}</Typography>
          </Box>
          <Box py={2.5} display="flex">
            <Typography variant="body1">CPF: {formatCpf(cpf)}</Typography>
            <Typography variant="body1">
              <Box component="span" fontWeight="bold" px={1}>{` • `}</Box>
            </Typography>
            <Typography variant="body1">{formatPhone(mobileNumber)}</Typography>
          </Box>
        </>
      ) : (
        <Box py={2.5} display="flex">
          <Typography variant="body1">{formatPhone(mobileNumber)}</Typography>
          <Box component="span" ml={2}>
            <Chip
              label={TEXTS.INCOMPLETE_REGISTER}
              className={styles.incompleteRegister}
              size="small"
            />
          </Box>
        </Box>
      )}
      {!isDriver ? (
        <Box displey="flex" flexDirection="column">
          <Chip
            label={TEXTS.INCOMPLETE_REGISTER}
            className={styles.incompleteRegister}
            size="small"
          />
        </Box>
      ) : (
        OPERATIONAL_STATUS_TYPE[operationalStatus] && (
          <Box displey="flex" flexDirection="column">
            <Chip
              label={OPERATIONAL_STATUS_TYPE[operationalStatus]}
              className={styles.incompleteRegister}
              size="small"
            />
          </Box>
        )
      )}
    </Box>
  );
};

DriversProfileInfoComponent.propTypes = {
  profile: PropTypes.shape({
    fullName: PropTypes.string,
    mobileNumber: PropTypes.string,
    email: PropTypes.string,
    deliverer: PropTypes.shape({
      id: PropTypes.string,
      cpf: PropTypes.string,
      transportType: PropTypes.string,
      isDriver: PropTypes.bool,
      apiKey: PropTypes.string,
      availableForOffer: PropTypes.bool,
      profilePictureThumbnailUrl: PropTypes.string
    })
  })
};

DriversProfileInfoComponent.defaultProps = {
  profile: {
    fullName: null,
    mobileNumber: null,
    email: null,
    deliverer: {
      id: null,
      cpf: null,
      transportType: null,
      isDriver: null,
      apiKey: null,
      availableForOffer: null,
      profilePictureThumbnailUrl: null
    }
  }
};

export default DriversProfileInfoComponent;
