import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useStyles from '../drivers-tracking.style';
import { TEXTS, ITINERARY_STATUS_MAPPER } from '../drivers-tracking.constants';

const ItineraryStatus = ({ itineraryStatus }) => {
  const styles = useStyles();

  const getStatus = state => ITINERARY_STATUS_MAPPER[state];
  const getStatusText = state => TEXTS.ITINERARY_STATUS[getStatus(state)];
  const getBulletClass = state =>
    ({
      cancelled: 'bulletCancelled',
      finished: 'bulletFinished',
      ongoing: 'bulletOngoing'
    }[getStatus(state)]);

  return (
    <Box display="flex" alignItems="center">
      <Box
        className={styles[getBulletClass(itineraryStatus)]}
        width={12}
        height={12}
        borderRadius="50%"
        mr={2}
      />
      {getStatusText(itineraryStatus)}
    </Box>
  );
};

ItineraryStatus.propTypes = {
  itineraryStatus: PropTypes.string.isRequired
};

export default ItineraryStatus;
