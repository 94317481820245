import { FILTERS } from 'shared/filter/filter.constants';

export const TEXT_CARD = {
  TEXT_SUMMARY: 'Hoje',
  PACKAGE: 'pacote',
  NONE: 'Nenhum',
  OFERTAS: {
    CARD_TITLE: 'Ofertas',
    CARD_LINK: 'Ver ofertas'
  },
  VAI_CHEGAR: {
    CARD_TITLE: 'Vai chegar',
    CARD_SUBTITLE_PKG: 'Pacotes',
    CARD_SUBTITLE_VEHICLES: 'Veículos',
    CARD_TRANSFERS_SUBTITLE: 'Em transferências',
    CARD_PICKUPS_SUBTITLE: 'Em coletas',
    CARD_LINK: 'Ver vai chegar'
  },
  NA_BASE: {
    CARD_TITLE: 'Na base',
    CARD_LINK: 'Ver base',
    CARD_BODY: {
      to_be_delivered: 'Para entregar',
      returning: 'Para transferir',
      awaiting: 'Em tratativa'
    }
  },
  NA_RUA: {
    CARD_TITLE: 'Na rua',
    CARD_LINK: 'Ver rua',
    NO_PACKAGES: 'Esse entregador ainda nao levou nada pra rua.',
    NO_PACKAGES_MORE: 'Tente escolher outros entregadores.'
  },
  ENTREGUE: {
    CARD_TITLE: 'Entregues',
    CARD_LINK: 'Ver entregues',
    CARD_BODY: {
      morning: 'Manhã',
      afternoon: 'Tarde',
      evening: 'Noite'
    }
  },
  FINALIZADOS: {
    CARD_TITLE: 'Finalizados',
    CARD_LINK: 'Ver finalizados',
    CARD_TOTAL: 'Entregues',
    CARD_BODY: {
      morning: 'Entregues de manhã',
      afternoon: 'Entregues a tarde',
      evening: 'Entregues a noite',
      count_lsd_by_distribution_center: 'Perdidos'
    }
  },
  FALTAS: {
    CARD_TITLE: 'Faltas até ontem'
  }
};

export const TEXT_ALERT_STATUS_HEADER = {
  ARRIVALS: {
    PRIMARY: 'Indicadores temporariamente indisponíveis',
    SECONDARY:
      'As transferências e coletas podem continuar normalmente. Vamos atualizar em breve.'
  },
  IN_BASE: {
    PRIMARY: 'Indicadores temporariamente indisponíveis',
    SECONDARY:
      'Continue recebendo os pacotes normalmente. Vamos atualizar em breve.'
  },
  IN_STREET: {
    PRIMARY: 'Indicadores temporariamente indisponíveis',
    SECONDARY:
      'Continue distribuindo os pacotes normalmente. Vamos atualizar em breve.'
  },
  FINISHED: {
    PRIMARY: 'Indicadores temporariamente indisponíveis',
    SECONDARY:
      'As entregas podem continuar normalmente. Vamos atualizar em breve.'
  }
};

export const ARRAY_FILTERS = [FILTERS.DELIVERER, FILTERS.UPDATE];
export default TEXT_CARD;
