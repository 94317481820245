import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useStyles = makeStyles(() => ({
  smoke: {
    color: colors.smoke[700]
  }
}));

export default useStyles;
