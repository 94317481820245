import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Paper, Divider } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import showSnackbar from 'shared/snackbar';
import Pagination, {
  usePagination,
  ITEMS_PER_PAGE_THIRTY
} from 'shared/pagination';
import SelectComponent from 'shared/select';
import ConfigurableTable from 'shared/configurable-table';
import AlertStatusHeader from 'shared/alert-status-header/alert-status-header.container';
import { useStateValue } from 'shared/contexts';
import {
  RESPONSE_MESSAGES_BY_CODE,
  translateStatusCode
} from 'app/httpMessage';
import { AUTHORIZED_ROUTES } from 'view/constants';
import { getSelectedRoutingCode } from 'auth/login/login.service';

import { SummaryContext } from 'tracking/template/summary.context';
import AsyncPackages from 'tracking/async-packages';
import {
  KEY_CUSTODY,
  SUMMARY,
  TEXT_ALERT_STATUS_HEADER
} from 'tracking/tracking.constants';
import CUSTODY_MAP from 'tracking/tracking.configuration';
import trackingService, {
  fetchGroupByUnitLoad
} from 'tracking/tracking.service';
import ConfigurableTableCollapsible from 'tracking/configurable-table-collapsible';
import { VIEWS_DRAWER } from 'tracking/configurable-table-collapsible/drawer-group/constants';

import { SummaryComponent, ReceiveComponent } from './to-transfer.component';

import TEXT from './to-transfer.constants';
import getToTransferColumnsLoading from './to-transfer.configuration';

const ORDER_BY_LIST_PACKAGE =
  CUSTODY_MAP[KEY_CUSTODY.TO_TRANSFER].orderBy.default;
const ORDER_BY_LIST_GROUP = CUSTODY_MAP[KEY_CUSTODY.TO_TRANSFER].orderBy.ul;

function ToTransferContainer() {
  const { itemType } = useParams();
  const history = useHistory();
  const routingCode = getSelectedRoutingCode();

  const defaultIsCollapsitable = itemType === 'grupos';
  const content = CUSTODY_MAP[KEY_CUSTODY.TO_TRANSFER];

  const [, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);
  const [isForUnitLoad, setIsForUnitLoad] = useState(defaultIsCollapsitable);

  const [orderByList, setOrderByList] = useState(ORDER_BY_LIST_PACKAGE);
  const [orderBy, setOrderBy] = useState(ORDER_BY_LIST_PACKAGE[0]);
  const [packages, setPackages] = useState([]);
  const [unitLoads, setUnitLoads] = useState([]);
  const [totalPackages, setTotalPackages] = useState(0);

  const { setSummary } = useContext(SummaryContext);

  const [barcode, setBarcode] = useState('');

  const [
    pagination,
    changePage,
    setNumberOfItems,
    resetPagination,
    setCurrentPage
  ] = usePagination();

  const [openDrawer, setOpenDrawer] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const changePagination = page => {
    changePage(page);
    setLoading(true);
  };

  const onOrderChange = item => {
    setOrderBy(item);
    resetPagination();
    setLoading(true);
  };

  const loadPackages = useCallback(async () => {
    try {
      const response = await trackingService({
        custody: KEY_CUSTODY.TRANSFERIR,
        page: pagination.currentPage,
        itemsPerPage: ITEMS_PER_PAGE_THIRTY,
        orderBy: orderBy.sortBy,
        orderDirection: orderBy.orderDirection
      });

      setNumberOfItems(response.hits.total.value);
      setTotalPackages(response.hits.total.value);
      setPackages(response.hits.hits);
    } catch (error) {
      const message = translateStatusCode(
        error.response.status,
        RESPONSE_MESSAGES_BY_CODE
      );
      showSnackbar({
        message,
        enqueueSnackbar,
        withButton: true,
        variant: 'error'
      });
    }

    setLoading(false);
  }, [
    enqueueSnackbar,
    setLoading,
    setPackages,
    setTotalPackages,
    orderBy.orderDirection,
    orderBy.sortBy,
    pagination.currentPage,
    setNumberOfItems
  ]);

  const loadUnitLoads = useCallback(async () => {
    try {
      const cleanData = await fetchGroupByUnitLoad(
        KEY_CUSTODY.TO_TRANSFER,
        pagination.currentPage,
        ITEMS_PER_PAGE_THIRTY,
        orderBy.sortBy,
        orderBy.secondOrderBy
      );

      setNumberOfItems(cleanData.totalUnitLoads);
      setTotalPackages(cleanData.totalPackages);
      setUnitLoads(cleanData.data);
    } catch (_error) {
      showSnackbar({
        message: SUMMARY.EMPTY_PACKAGE_DEFAULT,
        variant: 'error',
        enqueueSnackbar
      });
    }
    setLoading(false);
  }, [
    enqueueSnackbar,
    orderBy.secondOrderBy,
    orderBy.sortBy,
    pagination.currentPage,
    setLoading,
    setNumberOfItems,
    setTotalPackages
  ]);

  const handleClickUnitLoadCollapsible = async (licensePlate, page) => {
    try {
      const response = await trackingService({
        custody: KEY_CUSTODY.TO_TRANSFER,
        page,
        itemsPerPage: ITEMS_PER_PAGE_THIRTY,
        orderBy: orderBy.sortBy,
        orderDirection: orderBy.orderDirection,
        filters: {
          licensePlate,
          unitLoad: 'LICENSE_PLATE'
        }
      });

      return response.hits;
    } catch (_error) {
      showSnackbar({
        message: SUMMARY.EMPTY_PACKAGE_DEFAULT,
        variant: 'error',
        enqueueSnackbar
      });
      return [];
    }
  };

  // related to summary
  useEffect(() => {
    setSummary(
      SummaryComponent({
        totalPackages,
        isForUnitLoad,
        openDrawer,
        setLoading,
        setIsForUnitLoad,
        setOpenDrawer
      })
    );

    return null;
  }, [
    setSummary,
    totalPackages,
    isForUnitLoad,
    openDrawer,
    setLoading,
    setIsForUnitLoad,
    setOpenDrawer
  ]);

  // related to data
  useEffect(() => {
    const currentOrderByList = isForUnitLoad
      ? ORDER_BY_LIST_GROUP
      : ORDER_BY_LIST_PACKAGE;
    setOrderByList(currentOrderByList);

    if (!currentOrderByList.includes(orderBy)) {
      return setOrderBy(currentOrderByList[0]);
    }

    if (loading) {
      return isForUnitLoad ? loadUnitLoads() : loadPackages();
    }

    return null;
  }, [isForUnitLoad, loading, orderBy, loadUnitLoads, loadPackages]);

  useEffect(() => {
    const pustItemType = isForUnitLoad ? 'grupos' : 'pacotes';
    const url = `/${routingCode}${
      AUTHORIZED_ROUTES.TRACKING.TO_TRANSFER
    }/${pustItemType}`;
    history.push(url);
  }, [isForUnitLoad, history, routingCode]);

  useEffect(() => {
    dispatch({
      type: 'setLoading',
      payload: load => {
        if (load) {
          setLoading(true);
        }
      }
    });
  }, [dispatch, setLoading]);

  return (
    <>
      {!loading && <AsyncPackages custodyParam={KEY_CUSTODY.TO_TRANSFER} />}
      <Paper
        component={Box}
        elevation={10}
        flexGrow={1}
        marginBottom={3}
        data-testid="paper-rendered"
      >
        <AlertStatusHeader
          textPrimary={TEXT_ALERT_STATUS_HEADER.LIST_PACKAGES.PRIMARY}
          textSecondary={TEXT_ALERT_STATUS_HEADER.LIST_PACKAGES.SECONDARY}
        />

        <Box display="flex" justifyContent="space-between" p={4} pt={4}>
          <ReceiveComponent barcode={barcode} setBarcode={setBarcode} />
          <SelectComponent
            onSelectedChange={onOrderChange}
            selected={orderBy}
            selectOptions={orderByList}
            prefixLabel={TEXT.ORDER_BY}
          />
        </Box>

        <Divider />

        <Box p={5} minHeight={44} data-testid="receive-to-transfer-tables">
          {loading && (
            <>
              {/* fill config table with fake data to show skeleton on its cell in loading state */}
              <Box data-testid="to-transfer-loading-table">
                <ConfigurableTable
                  data={[{ id: 1 }, { id: 2 }]}
                  columns={getToTransferColumnsLoading()}
                  withPackageDrawer
                />
              </Box>
            </>
          )}

          {!loading && !isForUnitLoad && (
            <Box data-testid="to-transfer-packages-table">
              <ConfigurableTable
                data={packages}
                columns={content.tableConfiguration}
                withPackageDrawer
              />
            </Box>
          )}

          {!loading && isForUnitLoad && (
            <Box data-testid="to-transfer-groups-table">
              <ConfigurableTableCollapsible
                data={unitLoads}
                columns={content.tableConfigurationCollapsible}
                columnsChildren={content.tableConfigurationCollapsibleChildren}
                callbackNested={handleClickUnitLoadCollapsible}
                callFetchGroupByUnitLoad={loadUnitLoads}
                showViewActionsDrawer={VIEWS_DRAWER.PRINT_LABEL}
              />
            </Box>
          )}
        </Box>
      </Paper>

      <Pagination
        onPageChange={changePagination}
        initialPage={pagination.currentPage}
        numberOfPages={pagination.numberOfPages}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

export default ToTransferContainer;
