import api from 'infra/api/leve';

export default (
  unitLoadInfo,
  packages,
  isPhysicalEvidence,
  latitude,
  longitude,
  statusCodeOverwrite
) => {
  const body = {
    unitLoadInfo,
    packages,
    isPhysicalEvidence,
    latitude,
    longitude,
    statusCodeOverwrite,
    service: 'arco'
  };

  return api.post(
    '/proxy-unretryable/last-mile/v1/leve/move/package_list',
    body
  );
};
