import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { DrawerHeader, DrawerContent } from 'shared/drawer';

import { DemandRegionsContext } from '../demand-regions.provider';
import { DEMAND_REGIONS_DETAILS_DRAWER } from '../demand-regions.constants';
import { getNumberDecisions } from '../demand-regions.utils';
import DecisionGrouped from './demand-regions-decisions-grouped';

const DemandRegionsDetailsDrawer = ({ onClose }) => {
  const { sortingContext } = useContext(DemandRegionsContext);

  const numberDecisions = getNumberDecisions(sortingContext);

  return (
    <>
      <DrawerHeader
        titleVariant="h5"
        title={`${numberDecisions}${DEMAND_REGIONS_DETAILS_DRAWER.TITLE}`}
        detail={DEMAND_REGIONS_DETAILS_DRAWER.DETAIL}
        colorText="textSecondary"
        showDivider
        variantTextDetail="body1"
        handleClosingDrawer={onClose}
        open
      />
      <DrawerContent>
        <DecisionGrouped sortingContext={sortingContext} />
      </DrawerContent>
    </>
  );
};

DemandRegionsDetailsDrawer.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default DemandRegionsDetailsDrawer;
