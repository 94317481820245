import api from 'infra/api/leve';
import { parseRequestError } from 'app/httpMessage';

import {
  PROMISED_DATE_URL,
  URL_API_V1_AT_RISK_PREFIX
} from 'infra/service/constants';
import {
  getLoggedDCId,
  getLoggedLMCId,
  getSelectedRoutingCode
} from 'auth/login/login.service';

export const forceCreatePromisedDateService = packageId => {
  return api
    .post(`${PROMISED_DATE_URL}/last_mile_promised_date`, {
      packageId,
      distributionCenterRoutingCode: getSelectedRoutingCode(),
      distributionCenterId: getLoggedDCId(),
      lastMileCompanyIdentification: getLoggedLMCId()
    })
    .catch(
      parseRequestError({
        '404':
          'Opa, não conseguimos calcular o prazo. Tem algo errado no CEP do pacote. Fale com o suporte.'
      })
    );
};

export const getPackageIsAtRisk = async packageId => {
  return api
    .get(`${URL_API_V1_AT_RISK_PREFIX}/${packageId}`)
    .then(response => response.data);
};

export default forceCreatePromisedDateService;
