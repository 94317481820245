import filterPackagesService from 'infra/service/filter-packages';

import { parseRequestError } from '../httpMessage';

export const CUSTOM_ERROR_MESSAGES = {
  404: 'Nenhum pacote encontrado.',
  500: 'Falha ao consultar os pacotes, tente novamente mais tarde'
};

const treatPackageCep = rawPackages => {
  return rawPackages.map(rawPackage => {
    const { zipCode } = rawPackage.destination;

    const newZipCode = `${zipCode.slice(0, 5)}-${zipCode.slice(5)}`;

    return {
      ...rawPackage,
      destination: { ...rawPackage.destination, zipCode: newZipCode }
    };
  });
};

const filterPackages = (
  unitLoadInfos = [],
  paginationParams = {},
  orderBy = {},
  filterBy = [],
  term = null,
  useElasticsearch = false
) =>
  filterPackagesService(
    unitLoadInfos,
    paginationParams,
    orderBy,
    filterBy,
    useElasticsearch,
    term
  )
    .then(response => {
      const rawPackages = treatPackageCep(response.data.packages);

      const newresponse = {
        ...response,
        data: { ...response.data, packages: rawPackages }
      };

      return newresponse.data;
    })
    .catch(parseRequestError(CUSTOM_ERROR_MESSAGES));

export default filterPackages;
