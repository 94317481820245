import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  avatar: {
    color: palette.text.primary,
    fontWeight: typography.fontWeightBold,
    backgroundColor: palette.common.white,
    border: '1px solid',
    borderColor: palette.grey[500],
    width: '56px',
    height: '56px'
  }
}));

export default useStyles;
