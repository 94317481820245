import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo
} from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { IsFSActiveForCurrentBase } from 'auth/access-control';
import NotificationsContext from './notifications.context';
import { PERMISSION } from './notifications.constants';
import {
  dialogIsPostponed,
  storePostponedDialog,
  fetchNotificationsV2
} from './notifications.service';

export default function NotificationsProvider({ children, value }) {
  const [notifications, setNotifications] = useState([]);
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  const [openDrawerNotifications, setOpenDrawerNotifications] = useState(
    value?.openDrawerNotifications || false
  );
  const isMounted = useRef(false);

  const { pathname } = useLocation();

  const notificationIsSupported = () =>
    // Checks if the browser supports Notification and serviceWorker
    'Notification' in window && 'serviceWorker' in navigator;

  const notificationPermission =
    notificationIsSupported() && Notification.permission;

  const enableNotifications = IsFSActiveForCurrentBase('enableNotifications');

  const handleGetNotifications = useCallback(() => {
    if (enableNotifications) {
      fetchNotificationsV2()
        .then(dataNotifications =>
          setNotifications(dataNotifications.notifications)
        )
        .catch(() => setNotifications(null));
    }
  }, [enableNotifications]);

  const onAcceptDialog = () => {
    setShowNotificationDialog(false);
    Notification.requestPermission();
  };

  const onDenyDialog = () => {
    if (!dialogIsPostponed()) storePostponedDialog();
    setShowNotificationDialog(false);
  };

  useEffect(() => {
    if (isMounted.current && openDrawerNotifications) {
      handleGetNotifications();
    }
  }, [handleGetNotifications, openDrawerNotifications]);

  useEffect(() => {
    // Prevents calling "handleGetNotifications" when mounting the component
    if (notificationPermission !== PERMISSION.GRANTED && isMounted.current) {
      handleGetNotifications();
    } else {
      isMounted.current = true;
    }
  }, [handleGetNotifications, notificationPermission, pathname]);

  useEffect(() => {
    if (notificationPermission === PERMISSION.DEFAULT && !dialogIsPostponed()) {
      setShowNotificationDialog(true);
    }
  }, [handleGetNotifications, notificationPermission]);

  const contextValues = useMemo(
    () => ({
      notifications,
      setNotifications,
      openDrawerNotifications,
      setOpenDrawerNotifications:
        value?.setOpenDrawerNotifications || setOpenDrawerNotifications,
      handleGetNotifications,
      showNotificationDialog,
      notificationPermission,
      onAcceptDialog,
      onDenyDialog
    }),
    [
      notifications,
      setNotifications,
      openDrawerNotifications,
      setOpenDrawerNotifications,
      handleGetNotifications,
      showNotificationDialog,
      notificationPermission,
      value
    ]
  );

  return (
    <NotificationsContext.Provider value={contextValues}>
      {children}
    </NotificationsContext.Provider>
  );
}

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        userId: PropTypes.string,
        intention: PropTypes.string,
        body: PropTypes.string,
        priority: PropTypes.string,
        createdAt: PropTypes.string,
        receivedAt: PropTypes.string,
        readAt: PropTypes.string
      })
    ),
    openDrawerNotifications: PropTypes.bool,
    setOpenDrawerNotifications: PropTypes.func
  })
};

NotificationsProvider.defaultProps = {
  value: null
};
