import api from 'infra/api/leve';
import { URL_API_V1_CIRCLE_PREFIX } from 'infra/service/constants';
import { parseRequestError } from 'app/httpMessage';
import { getDriversService } from 'users-management/drivers/drivers.service';

export const CREATE_CIRCLE_URL = `${URL_API_V1_CIRCLE_PREFIX}/create`;
export const DELETE_CIRCLE_URL = id =>
  `${URL_API_V1_CIRCLE_PREFIX}/delete/${id}`;
export const GET_RELATED_DRIVERS_URL = `${URL_API_V1_CIRCLE_PREFIX}/related_drivers`;
export const LIST_CIRCLES_URL = `${URL_API_V1_CIRCLE_PREFIX}/list`;
export const UPDATE_CIRCLES_URL = `${URL_API_V1_CIRCLE_PREFIX}/update`;
export const UPDATE_CIRCLES_UPDATE_UL_URL = `${URL_API_V1_CIRCLE_PREFIX}/update/unit-load`;

export const CUSTOM_ERROR_MESSAGES = {
  409: 'Esse grupo pertence a dois Círculos.'
};

export const createCircle = circle => {
  const body = {
    name: circle.name,
    cities: circle.cities,
    drivers: circle.selectedDrivers,
    operators: circle.selectedOperators,
    keywords: [],
    unitLoads: []
  };

  return api.post(CREATE_CIRCLE_URL, body).catch(parseRequestError());
};

export const deleteCircle = circleId =>
  api.delete(DELETE_CIRCLE_URL(circleId)).catch(parseRequestError());

export const getCirclesInformation = () =>
  api
    .get(LIST_CIRCLES_URL)
    .then(({ data }) => data)
    .catch(parseRequestError());

export const getAllDriversService = async () => {
  let drivers = [];
  await getDriversService()
    .then(resolve => {
      drivers = resolve.users;
      const pageData = resolve.pagination;
      const numberOfRequests = Math.ceil(
        pageData.numberItems / pageData.itemsPerPage
      );
      const promises = [];
      for (let i = 2; i <= numberOfRequests; i += 1) {
        promises.push(getDriversService(i));
      }
      return Promise.all(promises);
    })
    .then(responses =>
      responses.forEach(response => {
        drivers.push(...response.users);
      })
    )
    .catch(parseRequestError());
  return drivers;
};

export const getRelatedDrivers = () =>
  api
    .get(GET_RELATED_DRIVERS_URL)
    .then(({ data }) => data)
    .catch(parseRequestError());

export const updateCircle = (id, circle) => {
  const body = {
    id,
    name: circle.name,
    cities: circle.cities,
    drivers: circle.selectedDrivers,
    operators: circle.selectedOperators,
    keywords: [],
    unitLoads: circle.unitLoads
  };

  return api.put(UPDATE_CIRCLES_URL, body).catch(parseRequestError());
};

export const updateCircleUL = (circleId, unitLoad, removeUl) => {
  const body = {
    circleId,
    unitLoad,
    removeUl
  };

  return api
    .put(UPDATE_CIRCLES_UPDATE_UL_URL, body)
    .catch(parseRequestError(CUSTOM_ERROR_MESSAGES));
};
