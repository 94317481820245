export const initialState = {
  setLoading: () => {}
};

export const actions = (state, payload) => ({
  setLoading: () => {
    return { ...state, setLoading: payload };
  }
});

export const reducer = (state, action = { type: '', payload: {} }) => {
  return actions(state, action.payload)[action.type]();
};
