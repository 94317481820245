import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    divDrawer: {
      '& .MuiDrawer-paper': {
        width: '100%'
      }
    },
    myAlert: {
      backgroundColor: '#fff'
    },
    messageBox: {
      maxWidth: '386px'
    },
    root: {
      position: 'relative',
      maxWidth: '550px'
    },
    chipCustom: {
      '& .MuiChip-label': { fontSize: 12 }
    }
  })
);

export default useStyles;
