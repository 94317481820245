import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Fab,
  Grid,
  Typography
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import StatusColorDescription from 'shared/status-color-description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  APPLY_FILTERS,
  CLEAN_FILTERS,
  FILTER_TITLE,
  SHOW_LESS
} from './drawer-filter.constants';

export default function DrawerFilterComponent({
  dateFilters,
  handleCloseDrawerFilter
}) {
  const handleSave = () => {};

  const [filterExpand, setFilterExpand] = useState([]);

  const onClickExpandMoreOrLess = filterTitle => {
    if (filterExpand.includes(filterTitle)) {
      setFilterExpand(filterExpand.filter(title => title !== filterTitle));
    } else {
      setFilterExpand([...filterExpand, filterTitle]);
    }
  };

  const renderFilterTemplate = (filterSection, filter) => {
    return (
      <Grid item xs={filterSection?.useDoubleColumns ? 6 : 12}>
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={filterSection?.filtersChecked?.includes(filter.value)}
          />
          {filterSection.title === 'Status' ? (
            <StatusColorDescription
              status={filter.value}
              typographyVariant="body1"
              statusDescription={false}
            />
          ) : (
            <Typography variant="subtitle2">{filter.text}</Typography>
          )}
        </Box>
      </Grid>
    );
  };

  return (
    <Drawer open anchor="right">
      <Box maxWidth={480} width={480}>
        <Box>
          <Box mx={5} my={5}>
            <Fab
              size="small"
              onClick={handleCloseDrawerFilter}
              data-testid="fab-close-drawer-notification"
            >
              <CloseRoundedIcon />
            </Fab>
            <Box
              my={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">
                <em>{FILTER_TITLE}</em>
              </Typography>

              <Button variant="text" color="primary" size="medium">
                {CLEAN_FILTERS}
              </Button>
            </Box>
          </Box>

          <Divider />
          {dateFilters.map(filterSection => (
            <Box>
              <Box mt={5} mx={5} mb={4}>
                <Box mb={2}>
                  <Typography variant="subtitle1">
                    <em>{filterSection.title}</em>
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  {filterSection.items
                    .slice(
                      0,
                      filterSection?.maxItensVisibleDefault
                        ? filterSection?.maxItensVisibleDefault
                        : 5
                    )
                    .map(filter => renderFilterTemplate(filterSection, filter))}
                </Grid>
                <Collapse
                  style={{ paddingTop: '12px' }}
                  in={filterExpand.includes(filterSection.title)}
                >
                  <Grid container spacing={2}>
                    {filterSection.items
                      .slice(
                        filterSection?.maxItensVisibleDefault
                          ? filterSection?.maxItensVisibleDefault
                          : 5
                      )
                      .map(filter =>
                        renderFilterTemplate(filterSection, filter)
                      )}
                  </Grid>
                </Collapse>

                {filterSection?.showExpandMore &&
                  filterSection.items.length >
                    filterSection?.maxItensVisibleDefault && (
                    <Box mt={2}>
                      <Button
                        variant="text"
                        color="primary"
                        size="medium"
                        endIcon={
                          filterExpand.includes(filterSection.title) ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )
                        }
                        onClick={() => {
                          onClickExpandMoreOrLess(filterSection.title);
                        }}
                      >
                        {filterExpand.includes(filterSection.title)
                          ? SHOW_LESS
                          : filterSection?.labelExpandMore}
                      </Button>
                    </Box>
                  )}
              </Box>

              <Divider />
            </Box>
          ))}

          <Box display="flex" justifyContent="center" my={5} px={5}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              onClick={handleSave}
            >
              {APPLY_FILTERS}
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

DrawerFilterComponent.propTypes = {
  dateFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      filters: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
      useDoubleColumns: PropTypes.bool,
      showExpandMore: PropTypes.bool,
      labelExapandMore: PropTypes.string,
      maxItensVisibleDefault: PropTypes.number
    })
  ).isRequired,
  handleCloseDrawerFilter: PropTypes.func.isRequired
};
