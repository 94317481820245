import React from 'react';
import { Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { IsFSActiveForCurrentBase } from 'auth/access-control';
import { colors } from '@loggi/mar';
import { UpdateDisputeResponsible } from './disputes-update-responsible.container';
import { DisputeHighlight } from './disputes-highlight.component';
import { UPDATE_RESPONSIBLE_TEXTS } from './disputes.constants';
import { DisputesDriverComponent } from './disputes-driver.component';

export const DisputeDetail = ({ dispute, driver }) => {
  const updateResponsiblebuttonFS = IsFSActiveForCurrentBase(
    'enableUpdateDisputeResponsibleButton'
  );
  const hasUpateResponsibleSituation =
    dispute?.situation === 'DISPUTE_SITUATION_TYPE_UNASSIGNED';
  const hasDeliveryHighlight =
    dispute?.situation !== 'DISPUTE_SITUATION_TYPE_SOLVED';
  const hasPendingSituation =
    dispute?.situation === 'DISPUTE_SITUATION_TYPE_PENDING';

  return (
    <>
      {hasDeliveryHighlight && (
        <Box bgcolor={colors.blue[50]} my={1} mt={2} p={2} ml={2} margin={2}>
          <DisputeHighlight disputeData={dispute} />
          {updateResponsiblebuttonFS && hasUpateResponsibleSituation && (
            <UpdateDisputeResponsible
              buttonText={UPDATE_RESPONSIBLE_TEXTS.buttonWithoutResponsible}
              dispute={dispute}
            />
          )}
        </Box>
      )}
      <Divider />
      {driver && hasPendingSituation && (
        <DisputesDriverComponent driver={driver} dispute={dispute} />
      )}
    </>
  );
};

DisputeDetail.propTypes = {
  dispute: PropTypes.shape().isRequired,
  driver: PropTypes.shape().isRequired
};

export default DisputeDetail;
