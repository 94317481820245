import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import PaginationUI from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import useStyles from './pagination.styles';

export default function Pagination(props) {
  const { numberOfPages, onPageChange, initialPage, setCurrentPage } = props;

  const classes = useStyles();

  const handleChange = (event, value) => {
    setCurrentPage(value);
    onPageChange(value);
  };

  const render = item => (
    <PaginationItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...item}
      data-testid={`pagination-${item.type}-${item.page}`}
      classes={{
        textPrimary: classes.textPrimary,
        selected: classes.selected,
        disabled: classes.disabled
      }}
    />
  );

  return (
    <Grid
      container
      className={classes.gridLayout}
      alignItems="flex-start"
      justify="flex-end"
      direction="row"
    >
      <PaginationUI
        color="primary"
        count={numberOfPages}
        page={initialPage}
        onChange={handleChange}
        renderItem={render}
      />
    </Grid>
  );
}

Pagination.propTypes = {
  numberOfPages: PropTypes.number,
  onPageChange: PropTypes.func,
  setCurrentPage: PropTypes.func,
  initialPage: PropTypes.number
};

Pagination.defaultProps = {
  numberOfPages: 1,
  onPageChange: () => {},
  setCurrentPage: () => {},
  initialPage: 1
};

Pagination.displayName = 'Pagination';
