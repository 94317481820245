import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export default makeStyles({
  holderAction: {
    position: 'relative',
    display: 'inline-block',
    height: '0.7em',
    width: '1.5em'
  },
  arrowUp: {
    bottom: 0,
    position: 'absolute',
    left: 0
  },
  arrowDown: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  active: {
    color: 'rgb(0, 186, 255)'
  },
  tableCell: {
    pointerEvents: 'none',
    cursor: 'default'
  },
  dataRow: {
    pointerEvents: 'auto',
    cursor: 'pointer'
  },
  rowSelected: {
    backgroundColor: colors.blue[50]
  }
});
