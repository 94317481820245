import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export default makeStyles(() => ({
  tableHead: {
    whiteSpace: 'nowrap'
  },
  tableCell: {
    pointerEvents: 'none',
    cursor: 'default'
  },
  dataRow: {
    pointerEvents: 'auto',
    cursor: 'pointer'
  },
  rowSelected: {
    backgroundColor: colors.blue[50]
  },
  syncing: {
    color: colors.smoke[500]
  }
}));
