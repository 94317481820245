import { CanFunction } from 'auth/access-control';
import { AUTHORIZED_ROUTES } from 'view/constants';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { getCompanyType } from 'auth/access-control/access-control.service';
import FilterComponent from './index';
import { FILTERS } from './filter.constants';
import CUSTODY_MAP, { getLayout } from '../../tracking/tracking.configuration';
import BaseContext from '../../tracking/in-base/base.context';

export default function FilterBar({ resetBaseListData, isCollapsitable }) {
  const history = useHistory();
  const { INFORMATION, TRACKING } = AUTHORIZED_ROUTES;

  const filterBarInBasePackage = [
    FILTERS.CITIES,
    FILTERS.STATUS,
    FILTERS.CIRCLES
  ];
  const filterBarInBaseGroup = [
    FILTERS.CITIES,
    FILTERS.STATUS,
    FILTERS.STATION
  ];

  const hasFilterCitiesEnabled = getCompanyType() === 'LEVE';

  const hasCirclesEnabled = getCompanyType() === 'LEVE';

  const { custody } = useParams();
  const renderState = getLayout(custody);

  const { onApplyChanges } = useContext(BaseContext);

  const customFilterComponent = (filterBarKey, status) => (
    <FilterComponent
      key={filterBarKey}
      btnFilter={filterBarKey}
      onApplyChanges={item => onApplyChanges(item, resetBaseListData)}
      whichFilter={filterBarKey}
      status={status}
    />
  );

  let filterBar;

  const basePath = history.location.pathname;

  switch (true) {
    case basePath.endsWith(INFORMATION.PACKAGES):
    case basePath.endsWith(TRACKING.IN_BASE_TODAY) && !isCollapsitable:
    case basePath.endsWith(TRACKING.IN_BASE_TOMORROW) && !isCollapsitable:
    case basePath.endsWith(TRACKING.IN_BASE_DELAYED) && !isCollapsitable:
    case basePath.endsWith(TRACKING.IN_BASE_AFTER_TOMORROW) && !isCollapsitable:
    case basePath.endsWith(TRACKING.IN_BASE_NO_DEADLINE) && !isCollapsitable:
    case basePath.endsWith(TRACKING.IN_BASE_AT_RISK) && !isCollapsitable:
    case basePath.endsWith(TRACKING.IN_BASE_FAULTED) && !isCollapsitable:
    case basePath.endsWith(TRACKING.IN_BASE_AWAITING_RESOLUTION) &&
      !isCollapsitable:
    case basePath.endsWith(TRACKING.IN_BASE_RECEIVE) && !isCollapsitable:
    case basePath.endsWith(TRACKING.IN_BASE_SUSPICIOUS_GEOCODING) &&
      !isCollapsitable:
      filterBar = filterBarInBasePackage;
      break;
    default:
      filterBar = filterBarInBaseGroup;
      break;
  }

  return (
    <>
      {filterBar.map(filterBarKey => {
        switch (filterBarKey) {
          case FILTERS.CITIES:
            return (
              hasFilterCitiesEnabled && (
                <React.Fragment key={filterBarKey}>
                  <CanFunction customDisabledTo="loggiPonto">
                    {customFilterComponent(filterBarKey)}
                  </CanFunction>
                </React.Fragment>
              )
            );
          case FILTERS.CIRCLES:
            return (
              hasCirclesEnabled && (
                <React.Fragment key={filterBarKey}>
                  <CanFunction customDisabledTo="loggiPonto">
                    {customFilterComponent(filterBarKey)}
                  </CanFunction>
                </React.Fragment>
              )
            );
          case FILTERS.STATION:
            return (
              <React.Fragment key={filterBarKey}>
                {customFilterComponent(filterBarKey)}
              </React.Fragment>
            );
          case FILTERS.STATUS:
            return (
              <React.Fragment key={filterBarKey}>
                {customFilterComponent(
                  filterBarKey,
                  CUSTODY_MAP[renderState].status
                )}
              </React.Fragment>
            );
          default:
            return null;
        }
      })}
    </>
  );
}

FilterBar.propTypes = {
  resetBaseListData: PropTypes.func.isRequired,
  isCollapsitable: PropTypes.bool.isRequired
};
