import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSnackbar } from 'notistack';
import { Tooltip, Box } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import { colors } from '@loggi/mar';
import styleDefault from 'view/molecules/package-drawer/drawer-update-address/google-map/style-map';
import getDistributionCenterInfo from 'information/routines-management/routines/routines.service';
import { ComplexAreasContext } from './complex-areas.provider';
import EditionModeControl from './edition-mode-control.component';

let drawingManager = null;

export const getPolygonCenter = (polygon, maps) => {
  const bounds = new maps.LatLngBounds();
  polygon
    .getPath()
    .getArray()
    .forEach(coordinates => {
      bounds.extend(coordinates);
    });
  return bounds.getCenter();
};
export default function ComplexAreasMapComponent() {
  const GOOGLE_MAPS_API_KEY = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  const [googleMapsAPI, setGoogleMapsAPI] = useState(null);
  const [baseCoordinates, setBaseCoordinates] = useState(null);
  const [centerPin, setCenterPin] = useState({
    lat: 0,
    lng: 0
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [polygonName, setPolygonName] = useState('');
  const [defaultZoom] = useState(15);
  const editionButtonRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const {
    complexAreas,
    currentComplexArea,
    polygons,
    setCancelAction,
    cancelAction,
    createAreaEvent,
    setCreateAreaEvent
  } = useContext(ComplexAreasContext);

  const renderElement = element => {
    const controlDiv = document.createElement('div');
    controlDiv.style.marginRight = '5px';
    controlDiv.style.marginTop = '10px';
    ReactDOM.render(element, controlDiv);
    return controlDiv;
  };

  const addCustomControls = () => {
    // clear old controls
    googleMapsAPI.map.controls[
      googleMapsAPI.maps.ControlPosition.RIGHT_BOTTOM
    ].clear();

    // add edition mode button
    const editionModeElement = renderElement(
      <EditionModeControl
        ref={editionButtonRef}
        maps={googleMapsAPI.maps}
        map={googleMapsAPI.map}
        polygons={polygons}
        drawingManager={drawingManager}
        enqueueSnackbar={enqueueSnackbar}
      />
    );
    googleMapsAPI.map.controls[
      googleMapsAPI.maps.ControlPosition.RIGHT_BOTTOM
    ].push(editionModeElement);
  };

  const addPolygonTooltip = (polygon, name) => {
    polygon.addListener('mouseover', () => {
      const coordinates = getPolygonCenter(polygon, googleMapsAPI.maps);
      setCenterPin({ lat: coordinates.lat(), lng: coordinates.lng() });
      setPolygonName(name);
      setShowTooltip(true);
    });

    polygon.addListener('mouseout', () => {
      setShowTooltip(false);
    });
  };

  const clearPolygons = () => {
    polygons.forEach(polygon => polygon.polygonProps.setMap(null));

    while (polygons.length > 0) {
      polygons.pop();
    }
  };

  const drawPolygons = () => {
    clearPolygons();
    complexAreas.forEach(complexArea => {
      const polygon = new googleMapsAPI.maps.Polygon({
        paths: complexArea.consensusPoints
      });
      addPolygonTooltip(polygon, complexArea.label);
      polygon.setMap(googleMapsAPI.map);
      polygons.push({
        name: complexArea.label,
        category: complexArea.category,
        selected: false,
        polygonProps: polygon
      });
    });
  };

  const initDrawingManager = () => {
    if (drawingManager) drawingManager.setMap(null);

    drawingManager = new googleMapsAPI.maps.drawing.DrawingManager({
      drawingMode: googleMapsAPI.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: {
        strokeColor: colors.blue[500],
        fillColor: colors.blue[100]
      }
    });
  };

  const showButtons = () => {
    editionButtonRef.current.handleShowButton();
  };

  const hideButtons = () => {
    editionButtonRef.current.handleHideButton();
    editionButtonRef.current.closeTooltip();
  };

  useEffect(() => {
    if (!googleMapsAPI) return;

    initDrawingManager();

    if (complexAreas.length > 0) {
      drawPolygons();
    }

    addCustomControls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMapsAPI, complexAreas]);

  useEffect(() => {
    getDistributionCenterInfo().then(info => {
      setBaseCoordinates({
        lat: info?.dcInfo?.address?.coordinates?.latitude || 0,
        lng: info?.dcInfo?.address?.coordinates?.longitude || 0
      });
    });
  }, []);

  useEffect(() => {
    if (!currentComplexArea) return;

    polygons.forEach(_polygon => {
      if (_polygon.name === currentComplexArea.label) {
        const polygon = _polygon;
        polygon.selected = true;
        _polygon.polygonProps.setOptions({
          strokeColor: colors.blue[500],
          fillColor: colors.blue[100]
        });
        _polygon.polygonProps.setEditable(true);
      }
    });

    showButtons();

    editionButtonRef.current.enableButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentComplexArea]);

  useEffect(() => {
    if (cancelAction) {
      initDrawingManager();

      drawPolygons();

      hideButtons();

      setCancelAction(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelAction]);

  useEffect(() => {
    if (createAreaEvent) {
      polygons.forEach(_polygon => {
        const polygon = _polygon;
        polygon.selected = false;
      });
      setCreateAreaEvent(false);
      showButtons();
      editionButtonRef.current.hideEditionModeTooltipText();
      editionButtonRef.current.openTooltip();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAreaEvent]);

  return (
    <>
      {baseCoordinates && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          center={baseCoordinates}
          defaultZoom={defaultZoom}
          options={{
            styles: styleDefault
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => setGoogleMapsAPI({ map, maps })}
        >
          <Tooltip
            lat={centerPin.lat}
            lng={centerPin.lng}
            open={showTooltip}
            placement="top"
            arrow
            title={polygonName}
          >
            <Box
              maxWidth={200}
              alignItems="center"
              alignContent="center"
              display="flex"
              flexDirection="column"
            />
          </Tooltip>
        </GoogleMapReact>
      )}
    </>
  );
}

ComplexAreasMapComponent.propTypes = {};
