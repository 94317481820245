import React, { useContext } from 'react';
import { Box, Drawer } from '@material-ui/core';
import { useHistory } from 'react-router';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { AUTHORIZED_ROUTES } from 'view/constants';
import { DemandRegionsContext } from './demand-regions.provider';
import DemandRegionsMapComponent from './demand-regions.map.component';
import DemandRegionsDetailsDrawer from './drawer/demand-regions-details.drawer';
import DemandRegionsSimulationDetailsDrawer from './drawer/demand-regions-simulation-details.drawer';
import useStyles from './demand-regions.style';

const DemandRegionsComponent = () => {
  const { openSimulationDrawerDemandRegions } = useContext(
    DemandRegionsContext
  );

  const history = useHistory();
  const handleGoRoutinesPage = () => {
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${AUTHORIZED_ROUTES.ROUTINES_MANAGEMENT.PATH}`);
  };

  const classes = useStyles();

  return (
    <Drawer
      data-testid="drawer"
      open
      anchor="left"
      variant="temporary"
      className={classes.divDrawer}
    >
      <Box
        data-testid="demand-regions-component"
        component="div"
        overflow="auto"
        display="flex"
        height="100%"
      >
        <Box
          maxWidth={480}
          width={480}
          minWidth={480}
          role="presentation"
          component="div"
          overflow="auto"
        >
          {openSimulationDrawerDemandRegions ? (
            <DemandRegionsSimulationDetailsDrawer
              onClose={handleGoRoutinesPage}
            />
          ) : (
            <DemandRegionsDetailsDrawer onClose={handleGoRoutinesPage} />
          )}
        </Box>
        <Box
          width="100%"
          height="100%"
          data-testid="component-google-map-react"
        >
          <DemandRegionsMapComponent />
        </Box>
      </Box>
    </Drawer>
  );
};

DemandRegionsComponent.propTypes = {};

export default DemandRegionsComponent;
