import api from 'infra/api/leve';
import moment from 'moment';

import { LOCAL_STORAGE_KEY } from './notifications.constants';

const urlNotification = '/api/v1/last_mile/notification';

export const fetchNotificationsV2 = async () => {
  const endpoint =
    '/api/v2/last_mile/[last_mile_company.identification]:[distribution_center.id]/user_notification';

  return api.get(endpoint).then(_data => _data.data);
};

export async function markNotificationsAsRead(notificationIds) {
  return api
    .post(`${urlNotification}/mark-as-read`, {
      notificationIds
    })
    .then(_data => _data.data);
}

export async function markNotificationsAsSeen(notificationIds) {
  return api
    .post(`${urlNotification}/mark-as-seen`, {
      notificationIds
    })
    .then(_data => _data.data);
}

export const storePostponedDialog = () => {
  const daysToPostpone = 1;
  const postponedDialog = {
    postpone_until: moment().add(daysToPostpone, 'days')
  };

  localStorage.setItem(
    LOCAL_STORAGE_KEY.POSTPONED_DIALOG,
    JSON.stringify(postponedDialog)
  );
};

export const getPostponedDialog = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.POSTPONED_DIALOG));

export const dialogIsPostponed = () => {
  const postponedDialog = getPostponedDialog();
  // eslint-disable-next-line camelcase
  return moment().isBefore(postponedDialog?.postpone_until);
};
