import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  button: {
    marginTop: spacing(10)
  },
  iconWrapper: {
    height: spacing(22), // 176px
    width: spacing(22),
    backgroundColor: 'rgba(255,255,255,0.2)',
    borderRadius: '50%',
    marginTop: spacing(13),
    marginBottom: spacing(6),
    position: 'relative',
    '& svg': {
      width: '36.37%', // 64px
      height: '36.37%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  textDecoration: {
    textDecoration: 'none'
  },
  emptyText: {
    maxWidth: '700px'
  }
}));

export default useStyles;
