import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Divider } from '@material-ui/core';
import { ReactComponent as TrashIcon } from 'images/icon-trash.svg';
import { dateFormatter } from 'shared/formatters/date';
import MissedEventsBox from 'shared/missed-events-box';
import { CONTENT_TEXT } from '../drawer-group-packages.constants';

function CardPackageInfo({
  packageInfo,
  addressInfo,
  promisedDate,
  promisedDateText,
  removePackageFromList,
  hideTrashButton
}) {
  return (
    <Box
      mb={1.5}
      height={90}
      width="100%"
      display="flex"
      borderRadius={10}
      padding="10px 5px"
      borderBottom="1px solid #EAEEF4"
      data-testid="drawer-packageInfo"
    >
      <Box
        width="60%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {packageInfo && (
          <>
            <Typography variant="body2">
              <em>{packageInfo.integrationInfo.barcode}</em>
            </Typography>
            <Typography variant="body2">{packageInfo.company.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {addressInfo()}
            </Typography>
          </>
        )}
      </Box>
      <Box
        display="flex"
        width="auto"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Divider orientation="vertical" />
        <Box
          pl={1.5}
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent={
            packageInfo.missedPromisedDateEvents ? 'space-between' : 'center'
          }
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textSecondary">
              <em>{promisedDateText()}</em>
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <strong>
                {CONTENT_TEXT.deadline} {dateFormatter(promisedDate())}
              </strong>
            </Typography>
          </Box>
          {packageInfo.missedPromisedDateEvents && (
            <MissedEventsBox
              count={packageInfo.missedPromisedDateEvents.length}
            />
          )}
        </Box>
        {!hideTrashButton && (
          <Box position="absolute" right={10}>
            <Button
              onClick={() =>
                removePackageFromList(packageInfo.integrationInfo.package_id)
              }
              data-testid="btn-remove-package"
            >
              <TrashIcon />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

CardPackageInfo.propTypes = {
  packageInfo: PropTypes.shape().isRequired,
  addressInfo: PropTypes.func.isRequired,
  promisedDate: PropTypes.func.isRequired,
  promisedDateText: PropTypes.func.isRequired,
  removePackageFromList: PropTypes.func.isRequired,
  hideTrashButton: PropTypes.bool
};

CardPackageInfo.defaultProps = {
  hideTrashButton: false
};

export default CardPackageInfo;
